import { GENERATE_ACCOUNT_STATEMENT_REQUEST } from 'redux/reducers/AccountStatementReducer';
import {
  CREATE_BUDGET_REQUEST,
  GET_BUDGET_REQUEST,
  UPDATE_BUDGET_REQUEST,
  GET_SINGLE_BUDGET_REQUEST,
  GET_BUDGET_STATS_REQUEST,
  BUDGET_BENEFICIARIES_REQUEST,
  BUDGET_CARD_REQUEST,
  CREATE_NEW_BENEFICIARIES_REQUEST,
  RESET_BLOCK_BUDGET,
  DELETE_BUDGET_REQUEST,
  GET_SINGLE_TRANSACTION_REQUEST,
  GET_TRANSFERABLE_BALANCE_REQUEST,
  CURRENCY_CONVERSION_RATE_REQUEST,
  GET_SELECTED_BUDGET_REQUEST,
  TOPUP_BUDGET_REQUEST,
  WITHDRAW_BUDGET_REQUEST,
  DEACTIVATE_BUDGET_REQUEST,
  ACTIVATE_BUDGET_REQUEST,
  GET_BUDGET_LEDGER_REQUEST,
  GET_SUB_ACCOUNT_REQUEST,
  GET_BALANCES_REQUEST,
  GET_ACCOUNT_SUBACCOUNTS_REQUEST,
  DELETE_BUDGET_BENEFICIARIES_REQUEST,
  ADD_MULTIPLE_BENEFICIARIES_REQUEST,
  WITHDRAW_BUDGET_FUNDS_REQUEST,
  GET_SINGLE_BALANCE_REQUEST,
  UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST,
  DOWNLOAD_BUDGET_AUDIT_REQUEST,
  GET_SUBACCOUNT_MEMBERS_REQUEST,
  ADD_SUBACCOUNT_MEMBERS_REQUEST,
  REMOVE_SUBACCOUNT_MEMBER_REQUEST,
  GET_SINGLE_SUBACCOUNT_REQUEST,
  GET_SUBACCOUNT_TRANSACTIONS_REQUEST,
} from '../reducers/BudgetsReducer';

export const getBudgets = (payload) => ({ type: GET_BUDGET_REQUEST, payload });
export const getSelectedBudget = (payload) => ({
  type: GET_SELECTED_BUDGET_REQUEST,
  payload,
});
export const deleteBudgets = (payload) => ({ type: DELETE_BUDGET_REQUEST, payload });
export const createBudgets = (payload) => ({ type: CREATE_BUDGET_REQUEST, payload });
export const updateBudgets = (payload) => ({ type: UPDATE_BUDGET_REQUEST, payload });
export const getSingleBudgets = (payload) => ({
  type: GET_SINGLE_BUDGET_REQUEST,
  payload,
});
export const getBudgetStats = (payload) => ({ type: GET_BUDGET_STATS_REQUEST, payload });
export const budgetBeneficiariesAPI = (payload) => ({
  type: BUDGET_BENEFICIARIES_REQUEST,
  payload,
});
export const budgetCard = (payload) => ({
  type: BUDGET_CARD_REQUEST,
  payload,
});
export const createNewBeneficiaries = (payload) => ({
  type: CREATE_NEW_BENEFICIARIES_REQUEST,
  payload,
});

export const deleteBudgetBeneficiary = (payload) => ({
  type: DELETE_BUDGET_BENEFICIARIES_REQUEST,
  payload,
});

export const resetBlockBudget = (payload) => ({
  type: RESET_BLOCK_BUDGET,
  payload,
});

export const getSingleTransactions = (payload) => ({
  type: GET_SINGLE_TRANSACTION_REQUEST,
  payload,
});

export const getTransferableBalance = (payload) => ({
  type: GET_TRANSFERABLE_BALANCE_REQUEST,
  payload,
});

export const currencyConversionRate = (payload) => ({
  type: CURRENCY_CONVERSION_RATE_REQUEST,
  payload,
});

export const topUpBudget = (payload) => ({
  type: TOPUP_BUDGET_REQUEST,
  payload,
});

export const withdrawBudget = (payload) => ({
  type: WITHDRAW_BUDGET_REQUEST,
  payload,
});

export const deactivateBudget = (payload) => ({
  type: DEACTIVATE_BUDGET_REQUEST,
  payload,
});

export const activateBudget = (payload) => ({
  type: ACTIVATE_BUDGET_REQUEST,
  payload,
});

export const getBudgetLedger = (payload) => ({
  type: GET_BUDGET_LEDGER_REQUEST,
  payload,
});

export const getSubAccount = (payload) => ({
  type: GET_SUB_ACCOUNT_REQUEST,
  payload,
});

export const getBalances = (payload) => ({
  type: GET_BALANCES_REQUEST,
  payload,
});

export const getAccountSubAccounts = (payload) => ({
  type: GET_ACCOUNT_SUBACCOUNTS_REQUEST,
  payload,
});

export const getSubAccountMembers = (payload) => ({
  type: GET_SUBACCOUNT_MEMBERS_REQUEST,
  payload,
});

export const getSubAccountTransactions = (payload) => ({
  type: GET_SUBACCOUNT_TRANSACTIONS_REQUEST,
  payload,
});

export const addSubAccountMembers = (payload) => ({
  type: ADD_SUBACCOUNT_MEMBERS_REQUEST,
  payload,
});

export const removeSubAccountMember = (payload) => ({
  type: REMOVE_SUBACCOUNT_MEMBER_REQUEST,
  payload,
});

export const getSingleBalance = (payload) => ({
  type: GET_SINGLE_BALANCE_REQUEST,
  payload,
});

export const getSingleSubAccount = (payload) => ({
  type: GET_SINGLE_SUBACCOUNT_REQUEST,
  payload,
});

export const addMultipleBeneficiaryToBudget = (payload) => ({
  type: ADD_MULTIPLE_BENEFICIARIES_REQUEST,
  payload,
});

export const withdrawBudgetFunds = (payload) => ({
  type: WITHDRAW_BUDGET_FUNDS_REQUEST,
  payload,
});

export const updateBudgetBeneficiarySettings = (payload) => ({
  type: UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST,
  payload,
});

export const downloadBudgetAudit = (payload) => ({
  type: DOWNLOAD_BUDGET_AUDIT_REQUEST,
  payload,
});
