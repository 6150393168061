import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import {
  APPROVE_REQUESTS_ERROR,
  APPROVE_REQUESTS_REQUEST,
  APPROVE_REQUESTS_SUCCESS,
  CREATE_REQUESTS_ERROR,
  CREATE_REQUESTS_REQUEST,
  CREATE_REQUESTS_SUCCESS,
  DECLINE_REQUESTS_ERROR,
  DECLINE_REQUESTS_REQUEST,
  DECLINE_REQUESTS_SUCCESS,
  DELETE_MULTIPLE_REQUESTS_ERROR,
  DELETE_MULTIPLE_REQUESTS_REQUEST,
  DELETE_MULTIPLE_REQUESTS_SUCCESS,
  DELETE_REQUESTS_ERROR,
  DELETE_REQUESTS_REQUEST,
  DELETE_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS_ASSET,
  GET_ALL_REQUESTS_ASSET_ERROR,
  GET_ALL_REQUESTS_ASSET_SUCCESS,
  GET_REQUESTS_ERROR,
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  GET_SELECTED_REQUESTS_ERROR,
  GET_SELECTED_REQUESTS_REQUEST,
  GET_SELECTED_REQUESTS_SUCCESS,
  GET_SINGLE_REQUESTS_ERROR,
  GET_SINGLE_REQUESTS_REQUEST,
  GET_SINGLE_REQUESTS_SUCCESS,
  REQUEST_MORE_INFO_ERROR,
  REQUEST_MORE_INFO_REQUEST,
  REQUEST_MORE_INFO_SUCCESS,
  RESET_BLOCK_REQUESTS,
  REVIEW_MULTIPLE_REQUESTS_ERROR,
  REVIEW_MULTIPLE_REQUESTS_REQUEST,
  REVIEW_MULTIPLE_REQUESTS_SUCCESS,
  UPDATE_REQUESTS_ERROR,
  UPDATE_REQUESTS_REQUEST,
  UPDATE_REQUESTS_SUCCESS,
} from '../reducers/RequestsReducer';

async function getRequest({ payload = {} }) {
  return await Axios.get(`/fund-requests`, { params: { status: 'pending', ...payload } });
}
function* handleGetRequest(payload) {
  try {
    const response = yield call(getRequest, payload);
    if (response) {
      yield put({
        type: GET_REQUESTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSelectedRequest({ payload = {} }) {
  return await Axios.get(`/fund-requests`, { params: payload });
}
function* handleGetSelectedRequest(payload) {
  try {
    const response = yield call(getSelectedRequest, payload);
    if (response) {
      yield put({
        type: GET_SELECTED_REQUESTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SELECTED_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createRequest(payload) {
  return await Axios.post('/fund-requests', payload);
}
function* handleCreateRequest({ payload }) {
  try {
    const response = yield call(createRequest, payload);
    if (response) {
      yield put({
        type: CREATE_REQUESTS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REQUESTS,
        blockType: 'createRequest',
      });

      yield put({
        type: GET_REQUESTS_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateRequest(payload) {
  const { code, ...others } = payload;
  return await Axios.put(`/fund-requests/${code}`, others);
}
function* handleUpdateRequest({ payload }) {
  const { showSuccessToast, delay: delayTime, ...restPayload } = payload;
  try {
    const response = yield call(updateRequest, restPayload);
    if (response) {
      yield put({
        type: UPDATE_REQUESTS_SUCCESS,
        data: response.data,
      });
      if (showSuccessToast !== false) toastSuccess(response.message);

      yield delay(delayTime ?? 300);
      yield put({
        type: RESET_BLOCK_REQUESTS,
        blockType: 'updateRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function requestMoreInfo(payload) {
  const { code, ...others } = payload;
  return await Axios.post(`/fund-requests/${code}/more-info`, others);
}
function* handleRequestMoreInfo({ payload }) {
  try {
    const response = yield call(requestMoreInfo, payload);
    if (response) {
      yield put({
        type: REQUEST_MORE_INFO_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REQUESTS,
        blockType: 'requestMoreInfo',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REQUEST_MORE_INFO_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reviewMultipleRequests(payload) {
  return await Axios.post(`/fund-requests/multiple-approval`, payload);
}
function* handleMultipleRequests({ payload }) {
  try {
    const response = yield call(reviewMultipleRequests, payload);
    if (response) {
      yield put({
        type: REVIEW_MULTIPLE_REQUESTS_SUCCESS,
        data: response,
      });

      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REVIEW_MULTIPLE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteMultipleRequests(payload) {
  return await Axios.post(`/fund-requests/mass-delete`, payload);
}
function* handleDeleteMultipleRequests({ payload }) {
  try {
    const response = yield call(deleteMultipleRequests, payload);
    if (response) {
      yield put({
        type: DELETE_MULTIPLE_REQUESTS_SUCCESS,
        data: response,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_MULTIPLE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function approveRequest(payload) {
  const { code, ...rest } = payload;
  return await Axios.post(`/fund-requests/${code}/approve`, rest);
}
function* handleApproveRequest({ payload }) {
  try {
    const response = yield call(approveRequest, payload);
    if (response) {
      yield put({
        type: APPROVE_REQUESTS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REQUESTS,
        blockType: 'approveRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: APPROVE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function declineRequest(payload) {
  return await Axios.post(`/fund-requests/${payload.code}/decline`, {
    note: payload.note,
  });
}
function* handleDeclineRequest({ payload }) {
  try {
    const response = yield call(declineRequest, payload);
    if (response) {
      yield put({
        type: DECLINE_REQUESTS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REQUESTS,
        blockType: 'declineRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DECLINE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteRequest(payload) {
  return await Axios.delete(`/fund-requests/${payload}`);
}
function* handleDeleteRequest({ payload }) {
  try {
    const response = yield call(deleteRequest, payload);
    if (response) {
      yield put({
        type: DELETE_REQUESTS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REQUESTS,
        blockType: 'deleteRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleRequest(params) {
  return await Axios.get(`/fund-requests/${params}`);
}
function* handleSingleRequest({ params }) {
  try {
    const response = yield call(singleRequest, params);
    if (response) {
      yield put({
        type: GET_SINGLE_REQUESTS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_REQUESTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetRequestAsset({ payload }) {
  const assetData = [];

  try {
    for (const item of payload) {
      const getAsset = (item) => {
        const code = item?.code;
        if (code) return Axios.get(`/assets/${code}`);
      };
      const response = yield call(getAsset, item);
      const {
        data: { asset },
      } = response;

      assetData.push({
        ...asset,
      });
    }
    yield put({
      type: GET_ALL_REQUESTS_ASSET_SUCCESS,
      data: assetData,
    });
  } catch (error) {
    yield put({
      type: GET_ALL_REQUESTS_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_REQUESTS_REQUEST, handleGetRequest),
  takeLatest(REVIEW_MULTIPLE_REQUESTS_REQUEST, handleMultipleRequests),
  takeLatest(DELETE_MULTIPLE_REQUESTS_REQUEST, handleDeleteMultipleRequests),
  takeLatest(GET_SELECTED_REQUESTS_REQUEST, handleGetSelectedRequest),
  takeLatest(CREATE_REQUESTS_REQUEST, handleCreateRequest),
  takeLatest(UPDATE_REQUESTS_REQUEST, handleUpdateRequest),
  takeLatest(GET_SINGLE_REQUESTS_REQUEST, handleSingleRequest),
  takeLatest(APPROVE_REQUESTS_REQUEST, handleApproveRequest),
  takeLatest(DECLINE_REQUESTS_REQUEST, handleDeclineRequest),
  takeLatest(DELETE_REQUESTS_REQUEST, handleDeleteRequest),
  takeLatest(GET_ALL_REQUESTS_ASSET, handleGetRequestAsset),
  takeLatest(REQUEST_MORE_INFO_REQUEST, handleRequestMoreInfo),
]);
