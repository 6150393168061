import EmptyState from 'components/UI/EmptyState';
import { useState } from 'react';

import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  CoinHands,
  LayersTwoIcon,
} from 'assets/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import TransactionImage from '../assets/images/emptystate/transaction-image.png';

import { SendFundsButton } from 'components/UI/CustomButton';
import { useMediaQuery } from 'react-responsive';

const childrenData = [
  {
    title: 'Make Single or Batch Payments',
    body: 'Initiate individual or grouped payments to vendors, suppliers, or colleagues.',
    actionTitle: 'View more',
    action: () => {
      console.log('working');
    },
    icon: <CoinHands />,
  },
  {
    title: 'Schedule a Payment',
    body: 'Plan and schedule payments in advance to ensure timeliness and avoid likely penalties.',
    actionTitle: 'View more',
    action: () => {
      console.log('working');
    },
    icon: <CoinHands />,
  },
  {
    title: 'Create Approval Rules',
    body: "Maintain control and compliance with your organization's policies and guidelines.",
    actionTitle: 'View more',
    action: () => {
      console.log('working');
    },
    icon: <CoinHands />,
  },
];

const ExampleEmptyState = () => {
  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div className="actionLink">
          <BankNoteO1Icon /> Single payment
        </div>
        <div className="actionLink">
          <LayersTwoIcon /> Batch payments
        </div>
        <div className="actionLink">
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const mainData = {
    title: 'Review and analyse individual financial transactions with ease.',
    body: 'Manage your financial transactions, make single and batch payments, and set approval rules. See how it works here',
    image: TransactionImage,
    action: () => (
      <CustomPopover
        zIndex="1"
        showPopover={isButtonToggle}
        clickOutside={handleButtonToggle}
        content={<Actions />}
        placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
        id={isTabletOrMobile ? 'invoice-schedule' : ''}
      >
        <SendFundsButton onClick={handleButtonToggle} />
      </CustomPopover>
    ),
  };
  return (
    <div className="container">
      <div className="row">
        <EmptyState main={mainData} childrenData={childrenData} />
      </div>
    </div>
  );
};

export default ExampleEmptyState;
