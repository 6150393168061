import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spiner = ({ className, ...props }) => {
  return <Spin indicator={antIcon} size="large" />;
};

const antIcon = <LoadingOutlined style={{ fontSize: 70 }} spin />;

export default Spiner;

export { antIcon as icon };
