import {
  APPROVE_REQUESTS_REQUEST,
  CREATE_REQUESTS_REQUEST,
  DECLINE_REQUESTS_REQUEST,
  DELETE_MULTIPLE_REQUESTS_REQUEST,
  DELETE_REQUESTS_REQUEST,
  GET_ALL_REQUESTS_ASSET,
  GET_REQUESTS_REQUEST,
  GET_SELECTED_REQUESTS_REQUEST,
  GET_SINGLE_REQUESTS_REQUEST,
  REQUEST_MORE_INFO_REQUEST,
  REVIEW_MULTIPLE_REQUESTS_REQUEST,
  UPDATE_REQUESTS_REQUEST,
} from '../reducers/RequestsReducer';

export const createRequests = (payload) => ({
  type: CREATE_REQUESTS_REQUEST,
  payload,
});
export const updateRequests = (payload) => ({
  type: UPDATE_REQUESTS_REQUEST,
  payload,
});
export const getRequests = (payload) => ({
  type: GET_REQUESTS_REQUEST,
  payload,
});
export const getSelectedRequests = (payload) => ({
  type: GET_SELECTED_REQUESTS_REQUEST,
  payload,
});
export const getSingleRequest = (params) => ({
  type: GET_SINGLE_REQUESTS_REQUEST,
  params,
});
export const deleteRequests = (payload) => ({
  type: DELETE_REQUESTS_REQUEST,
  payload,
});
export const declineRequests = (payload) => ({
  type: DECLINE_REQUESTS_REQUEST,
  payload,
});
export const approveRequests = (payload) => ({
  type: APPROVE_REQUESTS_REQUEST,
  payload,
});

export const requestMoreInfo = (payload) => ({
  type: REQUEST_MORE_INFO_REQUEST,
  payload,
});

export const getRequestAssets = (payload) => ({
  type: GET_ALL_REQUESTS_ASSET,
  payload,
});

export const reviewMultipleRequests = (payload) => ({
  type: REVIEW_MULTIPLE_REQUESTS_REQUEST,
  payload,
});

export const deleteMultipleRequests = (payload) => ({
  type: DELETE_MULTIPLE_REQUESTS_REQUEST,
  payload,
});
