import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import FilterList from './FilterList';
import './styles.less';

const FilterOnchange = ({
  filterData = [],
  inputPlaceholder = 'Search ',
  handleFilterSelect,
  addBeneficiary,
  withOutSearch,
  searchVal,
  setSearchVal,
}) => {
  const handleChange = (value, checked, type, title) => {
    if (type === 'Checkbox') {
      const updateData = filterData.map((list) => {
        if (list.title === title) {
          const fromIndex = list.list.map((object) => object.value).indexOf(value);
          const toIndex = 0;
          const element = list.list.splice(fromIndex, 1)[0];
          list.list.splice(toIndex, 0, element);
        }

        if (list.type === 'slider') return { ...list };
        return {
          ...list,
          list: list.list?.map((list) => {
            if (list.value === value) {
              return {
                ...list,
                isSelected: checked,
              };
            } else {
              return { ...list };
            }
          }),
        };
      });
      //
      handleFilterSelect(updateData, { value, checked });
    }
  };

  return (
    <div className="actions-dialog pb-3 set-height-filter">
      <h3 className="filter-title">Add existing member</h3>
      {!withOutSearch && (
        <div className="search-wrapper pb-2">
          <Input
            placeholder={inputPlaceholder}
            prefix={<SearchOutlined className="searchIcon" />}
            value={searchVal}
            onChange={({ target: { value } }) => setSearchVal(value)}
          />
        </div>
      )}
      {filterData.map((data, i) => (
        <div key={i} className="ps-1 pe-3 pb-1 pt-1">
          <FilterList data={data} handleChange={handleChange} />
        </div>
      ))}
      <button className="add-beneficiary-filter" onClick={addBeneficiary}>
        <PlusOutlined /> &nbsp; Add Member
      </button>
    </div>
  );
};
export default FilterOnchange;
