import { BudgetsStatusType, CurrencyType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData/index.js';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import BudgetModal from 'pages/Budgets/BudgetModal';
import BudgetsCard from 'pages/Budgets/BudgetsCard';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSelectedBudget } from 'redux/actions/BudgetsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const TeamBudgets = () => {
  const { teamsId } = useParams();

  const dispatch = useDispatch();

  const {
    getSelectedBudget: { data: { budgets: budgetsCard = [], meta = {} } = {}, loading },
  } = useSelector(({ budgets }) => budgets);

  const {
    teamOverview: { data: { team: teamData = {} } = {} },
  } = useSelector(({ teams }) => teams);

  const { members = [] } = teamData;
  const { hasMore, perPage } = meta;

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });

  const [filterData, setFilterData] = useState([...CurrencyType, ...BudgetsStatusType]);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isAdvance, setIsAdvance] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredQuery, setFilteredQuery] = useState({});

  const debouncedValue = useDebounce(search, 600);

  const toggleHandler = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleFilter = (query) => {
    dispatch(getSelectedBudget({ team: teamsId, ...query }));
    setFilteredQuery({ team: teamsId, ...query });
    setIsFiltered(!!Object.keys(query).length);
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue)
      dispatch(getSelectedBudget({ team: teamsId, search: debouncedValue }));
    if (!debouncedValue && isFiltered) {
      dispatch(getSelectedBudget({ team: teamsId }));
    }
    if (debouncedValue) setIsFiltered(true);
  }, [debouncedValue]);

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(getSelectedBudget({ perPage, ...filteredQuery }));
        setIsFiltered(true);
      }
    }, 1500);
  };

  const budgetList = () => {
    return (
      <>
        {budgetsCard.length > 0 ? (
          <div className="pt-3">
            <Container className="px-0">
              <InfiniteScroll
                pageStart={0}
                loadMore={() => handleMore(perPage + 50)}
                hasMore={hasMore}
                // loader={<h4></h4>}
              >
                <Row className="budgets-card-wrapper">
                  {budgetsCard.map((budget, index) => (
                    <BudgetsCard budget={budget} key={`budgets-${index}`} />
                  ))}
                </Row>
              </InfiniteScroll>
            </Container>
          </div>
        ) : (
          <div className="tabinnerWrapper">
            {isFiltered ? (
              <NoData
                headerText="You have no team budgets for this filter"
                bodyText="Alter filter to see budgets"
                withButton={false}
              />
            ) : (
              <NoData
                headerText="You have no team budgets yet"
                bodyText="Create a budget, fund it, add members and start your smart spending journey"
                withButton={false}
              />
            )}
          </div>
        )}
      </>
    );
  };

  if (loading && !isFiltered) return <Loading isPage color="#D28B28" />;

  const show =
    canManage && (!!budgetsCard?.length || (isFiltered && !budgetsCard?.length));

  return (
    <div className="budgets-wrapper">
      <PendingOnboardingNotice />

      <TopBar
        addOnClick={toggleHandler}
        showFilter
        setSearchVal={setSearch}
        searchVal={search}
        withOutFilterSearch
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        inputPlaceholder="search for budgets"
        handleFilterApply={handleFilter}
        withDate
        showBarSearch
        addIcon={show}
        exportLoading={true}
      />
      {budgetList()}

      <BudgetModal
        step={step}
        setStep={setStep}
        handleBack={handleBack}
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        setIsAdvance={setIsAdvance}
        isAdvance={isAdvance}
        isTeams={members}
      />
    </div>
  );
};

export default TeamBudgets;
