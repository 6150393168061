import CurrencyFormat from 'react-currency-format';
import './styles.scss';
import { getCurrency } from 'utils/helper';

/**
 * @param {String} type: the type of policy selected
 * @param {String} value: the value of the policy type
 * @param {Function} onHandleAction: callback function to perform either an edit or remove
 * @returns
 */

const PolicyTypeCard = ({ onHandleAction, data, type }) => {
  const CALENDAR_TYPES = {
    weekends: 'Saturday-Sunday',
    weekdays: 'Monday-Friday',
  };

  return (
    <section className="policy-type">
      <div className="d-flex flex-column">
        <h6 className="type">{type}</h6>

        {type?.toLowerCase() === 'receipt policy' && (
          <div>
            <span className="value">
              Provide receipts when transaction is {data?.data?.type?.value}
            </span>{' '}
            {data?.data?.type?.value !== 'all' && (
              <CurrencyFormat
                prefix={getCurrency(data?.currency)}
                value={data?.data?.amount}
                displayType="text"
                thousandSeparator={true}
                className="value"
              />
            )}
          </div>
        )}
        {type?.toLowerCase() === 'spending limits' && (
          <div>
            <CurrencyFormat
              prefix={getCurrency(data?.data?.currency)}
              value={data?.data?.amount}
              displayType="text"
              thousandSeparator={true}
              className="value"
            />{' '}
            <span className="value">{data?.data?.frequency?.value}</span>
          </div>
        )}

        {type?.toLowerCase() === 'calendar limits' && (
          <div>
            <CurrencyFormat
              prefix={getCurrency(data?.currency)}
              value={data?.data?.amount}
              displayType="text"
              thousandSeparator={true}
              className="value"
            />{' '}
            <span className="value">
              {Array.isArray(data?.data?.period?.value)
                ? data?.data?.period?.value.join(', ')
                : CALENDAR_TYPES[data?.data?.period?.value]}
            </span>
          </div>
        )}
      </div>
      <div className="d-flex gap-2 policy-action">
        <span onClick={() => onHandleAction('edit', data)} className="fw-semibold">
          Edit
        </span>
        <span onClick={() => onHandleAction('remove', data)} className="fw-semibold">
          Remove
        </span>
      </div>
    </section>
  );
};

export default PolicyTypeCard;
