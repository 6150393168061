import { Switch } from 'antd';
import { BankNote, Building, ChevronDown, ChevronUp, Globe, Phone } from 'assets/icons';
import { doubleCard } from 'assets/images';
import DeleteDialog from 'components/DeleteDialog';
import PolicyInfo from 'components/PolicyDetailModal/PolicyInfo';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import CustomSelect from 'components/UI/CustomSelect';
import CategorySelect from 'components/UI/CustomSelect/CategorySelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import MultipleSelect from 'components/UI/MultipleSelect';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import useExpenseCategories from 'hooks/useExpenseCategory';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { budgetBeneficiariesAPI } from 'redux/actions/BudgetsAction';
import {
  getMccs,
  getRequestedCards,
  physicalCardRequest,
} from 'redux/actions/CardAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { getPolicies } from 'redux/actions/PoliciesAction';
import { getVendors } from 'redux/actions/VendorsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, sortDays } from 'utils/helper';
import InviteMembers from './InviteMembers';
import './styles.scss';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';

export const cardSettingData = [
  {
    image: <BankNote />,
    label: 'ATM withdrawals',
    value: 'atmWithdrawals',
    isChecked: true,
  },
  {
    image: <Building />,
    label: 'PoS transactions',
    value: 'posTransaction',
    isChecked: true,
  },
  {
    image: <Globe />,
    label: 'Online transactions',
    value: 'onlineTransaction',
    isChecked: true,
  },
  {
    image: <Phone />,
    label: 'Contactless transactions',
    value: 'contactlessTransaction',
    isChecked: true,
  },
];

const RequestPhysicalCard = ({
  beneficiaryList,
  assignBudget,
  loadingBalances,
  loadOptions,
  setPhysicalCardSteps,
  physicalCardSteps,
  toggleHandler,
  card,
  setCard,
}) => {
  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const { permissions, beneficiaryCode } = allPermissions();
  const [cardSetting, setCardSetting] = useState(cardSettingData);
  const [cardIsCreated, setCardIsCreated] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);

  const canViewPolicy = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-view'],
  });

  const canCreateMember = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-create'],
  });

  // const [card, setCard] = useState({
  //   name: '',
  //   category: '',
  //   type: { value: '', label: '' },
  //   amount: '',
  //   currency: '',
  //   assignBudget: '',
  //   cardHolder: '',
  // });
  const [value, setValue] = useState({ vendors: '', members: '' });
  const debouncedValue = useDebounce(value.members, 200);
  const debouncedVendorValue = useDebounce(value.vendors, 200);
  const handleSelectChange = (val, type) => setValue({ ...value, [type]: val });

  const {
    expenseCategories,
    onHandleDeleteCategory,
    onHandleGetSelected,
    onOpenDeleteModal,
    selectedCategory,
    showCategoryDeleteModal,
    loadingDeleteCategory,
  } = useExpenseCategories(setCard, card, 'category');

  const {
    getBeneficiaries: { loading: loadingBeneficiaries },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getBudgetBeneficiaries: { loading: fetchingBudgetBeneficiaries },
    getBalances: { data: balances },
  } = useSelector(({ budgets }) => budgets);

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const {
    getVendor: { data: vendorData },
  } = useSelector(({ vendors }) => vendors);

  const {
    physicalCardRequest: { loading, success },
    getMccs: { data: mccsData, loading: loadingMccs, success: successMccs },
  } = useSelector(({ card }) => card);

  const { getPolicies: { data: { policies = [] } = {} } = {} } = useSelector(
    ({ policies }) => policies,
  );

  useEffect(() => {
    if (!mccsData?.mccs?.length) dispatch(getMccs());
  }, []);

  const generatedMccs = useMemo(() => {
    return mccsData?.mccs?.map(({ code: value, edited_description: label }) => ({
      label,
      value,
    }));
  }, [successMccs]);

  useEffect(() => {
    const mccsList = generatedMccs?.find((option) =>
      option.label.toLowerCase().includes(debouncedVendorValue.toLowerCase()),
    );
    if (!mccsList && debouncedVendorValue) {
      dispatch(getMccs({ search: debouncedVendorValue?.toLowerCase() }));
    }
  }, [debouncedVendorValue]);

  useEffect(() => {
    const beneficiaries = beneficiaryList?.find((option) =>
      option.label.toLowerCase().includes(debouncedValue.toLowerCase()),
    );
    if (!beneficiaries && debouncedValue) {
      if (budgetId) {
        dispatch(
          budgetBeneficiariesAPI({
            budget: budgetId,
            search: debouncedValue?.toLowerCase(),
          }),
        );
      } else {
        dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!vendorData?.vendors?.length) dispatch(getVendors());
    if (!countryData?.length) dispatch(getCountries());
    if (canViewPolicy) dispatch(getPolicies({ type: 'Spending limits' }));
  }, []);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  const handleChange = ({ name, value }) => {
    setCard({
      ...card,
      [name]: value,
    });
  };

  const onMenuCloseBeneficiary = () => {
    if (debouncedValue) dispatch(getBeneficiaries());
  };

  const onMenuCloseVendor = () => {
    if (debouncedVendorValue) dispatch(getMccs());
  };

  const setIsChecked = (value, checked) => {
    const updatedData = cardSetting.map((item) => {
      if (item.value === value) {
        return { ...item, isChecked: checked };
      }
      return item;
    });

    setCardSetting(updatedData);
  };

  const handleContinue = () => {
    if (!card?.assignBudget?.value) return toastError('Please select a budget');
    setPhysicalCardSteps(2);
  };

  const handleCreateCard = () => {
    if (!card?.firstName) return toastError('Please enter a first name');
    if (!card?.lastName) return toastError('Please enter a last name');
    if (!card?.billingAddress) return toastError('Please enter a billing address');
    if (!card?.city) return toastError('Please enter a city');
    if (!card?.country?.value) return toastError('Please select a country');
    if (!card?.state?.value) return toastError('Please select a state');

    const marchants = card?.vendors?.map((item) => item.value);
    const settings = cardSetting.reduce(
      (settings, setting) => {
        settings[setting.value] = setting.isChecked;
        return settings;
      },
      {
        expenseCategory: card?.category?.value ? card?.category?.value : undefined,
        allowedMerchants: marchants,
        spendingLimitPolicy: card?.policy?.code ? card?.policy?.code : undefined,
      },
    );

    const payload = {
      beneficiary: beneficiaryCode ?? card?.beneficiary?.value,
      budget: card?.assignBudget?.value,
      firstName: card?.firstName,
      lastName: card?.lastName,
      address: {
        billingAddress: card?.billingAddress,
        city: card?.city,
        state: card?.state?.value,
        postalCode: card?.postCode,
        country: card?.country?.value,
      },

      settings,
    };
    dispatch(physicalCardRequest(payload));
  };
  useEffect(() => {
    if (card.beneficiary.value) {
      const names = card.beneficiary.label.split(' ');
      setCard({
        ...card,
        country: card?.country,
        billingAddress: card?.billingAddress,
        postCode: card?.postCode,
        city: card?.city,
        state: card?.state,
        firstName: names[0],
        lastName: names.length > 1 && names[1],
      });
    }
  }, [card.beneficiary.value]);

  useEffect(() => {
    if (success) {
      dispatch(getRequestedCards());
      setCardIsCreated(true);
    }
  }, [success]);

  // filter selected type
  const selectedType = policies?.filter(
    (item) =>
      item.types.some((type) => type?.name.toLowerCase() === 'spending limits') &&
      item?.status === 'active',
  );

  const selectedPolicy = selectedType?.find((item) => item?.code === card?.policy?.code);

  const requestCard = (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Request a physical card</h2>

      <Row className="mb-2 mt-4">
        <CustomSelectRadio
          label="Assign Budget *"
          placeholder="Assign a Budget"
          name="assignBudget"
          isDisabled={assignBudget?.value || loadingBalances}
          onChange={(value) => handleChange({ name: 'assignBudget', value: value })}
          value={card.assignBudget}
          isLoading={loadingBalances}
          loadOptions={loadOptions}
        />
      </Row>

      {hasPermission({
        permissions,
        scopes: ['employee-*', 'employee-edit', 'employee-create'],
      }) && (
        <Row className="mb-3">
          <CustomSelect
            label="Card holder"
            placeholder="Member"
            name="beneficiary"
            options={beneficiaryList}
            onChange={(value) => handleChange({ name: 'beneficiary', value })}
            value={card.beneficiary}
            onInputChange={(value) => handleSelectChange(value, 'members')}
            isDisabled={
              ((fetchingBudgetBeneficiaries || loadingBeneficiaries) &&
                !debouncedValue) ||
              !card?.assignBudget?.value
            }
            isLoading={fetchingBudgetBeneficiaries || loadingBeneficiaries}
            onMenuClose={onMenuCloseBeneficiary}
          />

          {canCreateMember && (
            <div className="text-sm text-gray mt-2" style={{ color: '#57534E' }}>
              if you can&apos;t find some of your colleagues,{' '}
              <span
                onClick={() => setPhysicalCardSteps(3)}
                className="cursor"
                style={{
                  color: '#D28B28',
                  textDecoration: 'underline',
                  textDecorationColor: '#D28B28',
                }}
              >
                invite them to Bujeti
              </span>
            </div>
          )}
        </Row>
      )}

      <div className="mt-4 border-top advance__setting--cards">
        <div className="advance__setting-heading">
          <h3 className="d-flex justify-content-between align-items-center">
            Advanced card settings{' '}
            <span className="cursor" onClick={() => setShowAdvance(!showAdvance)}>
              {showAdvance ? <ChevronUp /> : <ChevronDown />}
            </span>
          </h3>
          <p>Selected fields will be applied to all transactions made on this card.</p>
        </div>
        {showAdvance && (
          <>
            <div className="advance__setting--payment">
              <div className="heading">Payment methods</div>
              {cardSetting.map(({ image, value, label, isChecked }) => (
                <section
                  className="d-flex justify-content-between align-items-center mb-3"
                  key={label}
                >
                  <div className="d-flex justify-content-between align-items-center payment-methods">
                    <div className="icon-wrap">
                      <span>{image}</span>
                    </div>
                    <div>{label}</div>
                  </div>

                  <Switch
                    checked={isChecked}
                    onChange={(checked) => setIsChecked(value, checked)}
                  />
                </section>
              ))}
            </div>

            {canViewPolicy && (
              <Row className="mb-3">
                <CustomRoleSelect
                  isClearable
                  label="Spending limit"
                  placeholder="Select existing spending limit"
                  name="policy"
                  options={selectedType.map(
                    ({ name: label, code, description: value }) => ({
                      label,
                      value,
                      code,
                    }),
                  )}
                  onChange={(val) => handleChange({ name: 'policy', value: val })}
                  value={card.policy}
                />

                <div>
                  {selectedPolicy?.maxAmount && (
                    <PolicyInfo
                      title="Amount"
                      value={
                        <CurrencyFormat
                          prefix={getCurrency(selectedPolicy?.currency)}
                          value={selectedPolicy?.maxAmount / 100}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      }
                    />
                  )}
                  {selectedPolicy?.frequency && (
                    <PolicyInfo
                      title="Frequency"
                      value={
                        <div className="d-flex flex-wrap ">
                          <BadgeType
                            value={selectedPolicy?.frequency}
                            isBadge={false}
                            margin
                          />
                        </div>
                      }
                      // value={sortDays(selectPolicy.period).join(', ')}
                    />
                  )}
                  {selectedPolicy?.period?.length && (
                    <PolicyInfo
                      title="Period"
                      value={
                        <div className="d-flex flex-wrap ">
                          {sortDays(selectedPolicy?.period).map((item, index) => (
                            <BadgeType value={item} key={index} isBadge={false} margin />
                          ))}
                        </div>
                      }
                    />
                  )}
                </div>
              </Row>
            )}

            <Row className="mb-3">
              <CategoryDropdown
                label={
                  <div className="advance__setting--label">
                    <div>Expense category</div>
                    <p>
                      All transaction will automatically fit into your chosen category.
                    </p>
                  </div>
                }
                placeholder={`Select specific spending category`}
                onChange={(value) => handleChange({ name: 'category', value: value })}
                value={card.category}
                name="category"
              />
            </Row>
            <Row className="mb-5">
              <MultipleSelect
                label={
                  <div className="advance__setting--label">
                    <div>Merchants or Vendors</div>
                    <p>
                      Selected merchants or vendors&apos; transactions are always
                      permitted.
                    </p>
                  </div>
                }
                name="vendor"
                placeholder={`Select specific vendors (e.g., "Office Depot")`}
                value={card.vendors}
                onChange={(value) => handleChange({ name: 'vendors', value: value })}
                options={generatedMccs}
                onInputChange={(value) => handleSelectChange(value, 'vendors')}
                isDisabled={loadingMccs && !debouncedVendorValue}
                isLoading={loadingMccs}
                onMenuClose={onMenuCloseVendor}
              />
            </Row>
          </>
        )}
      </div>

      <div className="modal-footer mt-3">
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={handleContinue}
        >
          Continue
        </CustomButton>
      </div>
    </div>
  );

  const confirmAddress = (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Confirm your delivery address</h2>

      <Row className="mt-4 mb-3 align-items-center">
        <CustomInput
          label="First name *"
          name="firstName"
          type="text"
          placeholder="Enter first name"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={card.firstName}
          md={6}
        />
        <CustomInput
          label="Last name *"
          name="lastName"
          type="text"
          placeholder="Enter last name"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={card.lastName}
          md={6}
        />
      </Row>

      <Row className="my-3">
        <CustomSelect
          placeholder="Select a country"
          type="text"
          name="country"
          // value={card.country}
          label="Country *"
          options={generateCountry}
          onChange={(value) => {
            handleChange({ name: 'country', value: value });
            getCountryStates(value);
          }}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Billing address *"
          name="billingAddress"
          type="text"
          placeholder="Enter billing address"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={card.billingAddress}
        />
      </Row>
      <Row className="mb-3">
        <CustomInput
          label="City *"
          placeholder="Ikeja"
          name="city"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={card.city}
        />
      </Row>

      <Row className="mb-3 align-items-center">
        <CustomSelect
          placeholder="Select a state"
          type="text"
          name="state"
          value={card.state}
          label="State *"
          isDisabled={loadingState}
          options={generateState}
          onChange={(value) => handleChange({ name: 'state', value: value })}
          md={6}
        />
        <CustomInput
          label="Postcode"
          name="postCode"
          type="text"
          placeholder="100001"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={card.postCode}
          maxLength="20"
          md={6}
        />
      </Row>

      <div className="modal-footer mt-3">
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={handleCreateCard}
          loading={loading}
          disabled={loading}
        >
          Create card
        </CustomButton>
      </div>
    </div>
  );

  const cardCreatedSuccess = (
    <div className="d-flex row information-wrapper">
      <img src={doubleCard} className="h-100 w-100" />
      <div className="d-flex card-wrapper justify-content-center row">
        <h2
          className="card-title w-100 text-center border-hidden"
          style={{ width: 'fit-content !important' }}
        >
          Your card is on its way now
        </h2>
        <p className="text-xs text-center">
          Your Bujeti debit card should arrive soon. Once received, please make sure to
          activate it at any nearby ATM by changing the default PIN.
        </p>
      </div>

      <div className="modal-footer mt-3">
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={toggleHandler}
        >
          Go to my cards
        </CustomButton>
      </div>
    </div>
  );

  return (
    <div>
      {cardIsCreated ? (
        cardCreatedSuccess
      ) : physicalCardSteps === 1 ? (
        requestCard
      ) : physicalCardSteps === 3 ? (
        <InviteMembers setStep={setPhysicalCardSteps} />
      ) : (
        confirmAddress
      )}

      {showCategoryDeleteModal && (
        <DeleteDialog
          title={`Delete ${selectedCategory?.name} Category`}
          subTitle="Are you sure you want to delete this category? This action cannot be undone and all transactions in this category would be categorized as unknown"
          onCancel={onOpenDeleteModal}
          onDelete={onHandleDeleteCategory}
          styles={{ width: 400 }}
          isLoading={loadingDeleteCategory}
        />
      )}
    </div>
  );
};

export default RequestPhysicalCard;
