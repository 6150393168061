import CustomButton from 'components/UI/CustomButton';
import EmptyState from 'components/UI/EmptyState';

import { RecieptCheck } from 'assets/icons';
import InvoiceImage from '../../assets/images/emptystate/invoice-image.png';

import { AddTeamMember } from 'assets/icons/empty-state-icons';
import AllowedTo from 'utils/AllowedTo';

const CollectionEmptyState = ({ onClickHandler }) => {
  const handleSupport = () => {
    window.open('https://www.bujeti.com/fund-collection', '_blank');
  };

  const childrenData = [
    {
      title: 'Create a new invoice',
      body: 'Initiate individual or grouped payments to vendors, suppliers, or colleagues.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <RecieptCheck />,
    },

    {
      title: 'Add a new customer',
      body: 'Plan and schedule payments in advance to ensure timeliness and avoid likely penalties.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <AddTeamMember />,
    },
  ];

  const mainData = {
    title: 'Generate, send, and track invoices seamlessly.',
    body: 'Generate, send, and track invoices seamlessly Create and send invoices to your customers while efficiently managing your customer database by adding new clients for invoicing purposes See how it works here.',
    image: InvoiceImage,
    action: () => (
      <AllowedTo scopes={['invoice-*', 'invoice-create']}>
        <CustomButton className="empty-add-button w-fit" onClick={onClickHandler}>
          Create invoice
        </CustomButton>
      </AllowedTo>
    ),
  };
  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default CollectionEmptyState;
