import { ChevronRight } from 'assets/icons';
import cn from 'classnames';
import React from 'react';

const BusinessKycTabs = ({ tabs, active, onSelect }) => {
  return (
    <div className="kyc_tab-wrapper w-auto">
      <div className="tab-list">
        {tabs.map((tab, index) => (
          <div key={index}>
            <button
              onClick={() => {
                onSelect(tab.key);
              }}
              className={cn(
                'btn tab-items mb-1',
                active === tab.key ? 'tab-items-active' : '',
              )}
            >
              <div className="d-flex flex-row gap-2 align-items-center">
                {tab.icon} {tab.name}
              </div>
              <ChevronRight
                width="18"
                height="18"
                className={cn('ms-auto')}
                stroke={active === tab.key ? '#79716B' : '#D7D3D0'}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessKycTabs;
