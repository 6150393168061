import { CreditCard } from 'assets/icons';

import EmptyState from 'components/UI/EmptyState';
import ExpenseImage from '../../assets/images/emptystate/expense-image.png';

import CustomButton from 'components/UI/CustomButton';

const RequestEmptyStateData = ({ openRequestModal, showAction }) => {
  const handleOpenModal = () => {
    openRequestModal();
  };

  const handleSupport = () => {
    window.open('https://www.bujeti.com/reimbursements', '_blank');
  };

  const buttonName = 'Request funds';

  const childrenData = [
    {
      title: 'Request for funds',
      body: 'Ask for fund to your budgets, to pay a vendor, to top up a card',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreditCard />,
    },

    {
      title: 'Manage your salaries advances',
      body: 'Create vendors, manage and track vendor-related expenses.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreditCard />,
    },
    {
      title: 'Generate expenses reports',
      body: 'Establish policies to govern and control team spending.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreditCard />,
    },
  ];

  //The complete list of your reimbursements will be displayed here. Request your first reimbursement
  const mainData = {
    title: 'Create, manage and track expenses and fund requests.',
    body: `Manage your company's expenses by organising transactions, tracking vendors, and implementing spending policies.`,
    image: ExpenseImage,
    action: () =>
      showAction && (
        <CustomButton className="add-button w-fit" onClick={handleOpenModal}>
          {buttonName}
        </CustomButton>
      ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default RequestEmptyStateData;
