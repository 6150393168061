import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';

const TableGrid = ({
  rows,
  columns,
  onCellsChanged,
  customCellTemplates,
  onSave,
  onCancel,
  loading = false,
  type = '',
  itemNo,
  maxHeight = '75vh',
}) => {
  return (
    <div>
      <div
        className="react-grid-custom-container"
        style={{ maxHeight, overflowY: 'auto' }}
      >
        <ReactGrid
          rows={rows}
          columns={columns}
          onCellsChanged={onCellsChanged}
          customCellTemplates={customCellTemplates}
          stickyTopRows={1}
          stickyBottomRows={1}
          initialFocusLocation={{
            columnId: columns?.[0]?.['columnId'],
            rowId: rows?.[1]?.['rowId'],
          }}
        />
      </div>
      {itemNo !== undefined && (
        <div
          className="d-flex justify-content-between align-items-center px-4 border border-top-0"
          style={{ height: '70px', borderRadius: '0px 0px 10px 10px' }}
        >
          <span className="text-sm">
            {itemNo} {type} added
          </span>
          <div className="batch-footer d-flex gap-2 justify-content-end">
            <button
              disabled={loading}
              onClick={onCancel}
              className="add-button add-action"
            >
              Cancel
            </button>
            <button
              disabled={loading || itemNo === 0}
              onClick={onSave}
              className="add-button dark-button text-white"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default TableGrid;
