import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_POLICIES_ERROR,
  CREATE_POLICIES_REQUEST,
  CREATE_POLICIES_SUCCESS,
  ADD_POLICY_DOCUMENT_ERROR,
  ADD_POLICY_DOCUMENT_REQUEST,
  ADD_POLICY_DOCUMENT_SUCCESS,
  DELETE_POLICY_DOCUMENT_ERROR,
  DELETE_POLICY_DOCUMENT_REQUEST,
  DELETE_POLICY_DOCUMENT_SUCCESS,
  DELETE_POLICY_ERROR,
  DELETE_POLICY_REQUEST,
  DELETE_POLICY_SUCCESS,
  EDIT_POLICY_ERROR,
  EDIT_POLICY_REQUEST,
  EDIT_POLICY_SUCCESS,
  FETCH_SINGLE_POLICY_ERROR,
  FETCH_SINGLE_POLICY_SUCCESS,
  GET_POLICIES_ERROR,
  GET_POLICIES_REQUEST,
  GET_POLICIES_SUCCESS,
  GET_POLICY_DOCUMENTS_ERROR,
  GET_POLICY_DOCUMENTS_REQUEST,
  GET_POLICY_DOCUMENTS_SUCCESS,
  GET_POLICY_TYPES_ERROR,
  GET_POLICY_TYPES_REQUEST,
  GET_POLICY_TYPES_SUCCESS,
  GET_SINGLE_BUDGET_POLICIES_ERROR,
  GET_SINGLE_BUDGET_POLICIES_REQUEST,
  GET_SINGLE_BUDGET_POLICIES_SUCCESS,
  LINK_BUDGET_POLICY_ERROR,
  LINK_BUDGET_POLICY_REQUEST,
  LINK_BUDGET_POLICY_SUCCESS,
  PAUSE_BUDGET_POLICY_ERROR,
  PAUSE_BUDGET_POLICY_REQUEST,
  PAUSE_BUDGET_POLICY_SUCCESS,
  RESET_FLAGS_POLICIES,
  UNLINK_BUDGET_POLICY_ERROR,
  UNLINK_BUDGET_POLICY_REQUEST,
  UNLINK_BUDGET_POLICY_SUCCESS,
  ADD_SINGLE_POLICY_DOCUMENT_SUCCESS,
  ADD_SINGLE_POLICY_DOCUMENT_ERROR,
  ADD_SINGLE_POLICY_DOCUMENT_REQUEST,
  GET_ALL_POLICY_ASSET_SUCCESS,
  GET_ALL_POLICY_ASSET_ERROR,
  GET_ALL_POLICY_ASSET_REQUEST,
  RESET_BLOCK_POLICIES,
} from 'redux/reducers/PoliciesReducer';

import { getSimplifiedError } from 'utils/error';
import { FETCH_SINGLE_POLICY_REQUEST } from '../reducers/PoliciesReducer';

async function createPolicy(payload) {
  return await Axios.post(`/policies`, payload);
}
function* handleCreatePolicy({ payload }) {
  try {
    const response = yield call(createPolicy, payload);
    if (response) {
      yield put({
        type: CREATE_POLICIES_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_POLICIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetPolicyAsset({ payload }) {
  const assetData = [];

  try {
    for (const item of payload) {
      const getAsset = (item) => {
        const code = item?.code;
        if (code) return Axios.get(`/assets/${code}`);
      };

      const response = yield call(getAsset, item);
      if (response) {
        const {
          data: { asset },
        } = response;

        assetData.push({
          ...asset,
        });
      }
    }

    yield put({
      type: GET_ALL_POLICY_ASSET_SUCCESS,
      data: assetData,
    });
  } catch (error) {
    yield put({
      type: GET_ALL_POLICY_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export async function addPolicyDocument(payload) {
  return await Axios.post(`/policies/documents`, payload);
}
function* handleAddPolicyDocument({ payload }) {
  try {
    const response = yield call(addPolicyDocument, payload);
    if (response) {
      yield put({
        type: ADD_POLICY_DOCUMENT_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_POLICIES,
        blockType: 'addPolicyDocument',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_POLICY_DOCUMENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addSinglePolicyDocument({ policyCode, assetCode }) {
  return await Axios.put(`/policies/${policyCode}/documents/${assetCode}`);
}
function* handleAddSinglePolicyDocument({ payload }) {
  try {
    const response = yield call(addSinglePolicyDocument, payload);
    if (response) {
      yield put({
        type: ADD_SINGLE_POLICY_DOCUMENT_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_SINGLE_POLICY_DOCUMENT_ERROR,
      error: getSimplifiedError(error),
    });

    yield delay(300);
    yield put({
      type: RESET_FLAGS_POLICIES,
      blockType: 'addSinglePolicyDocument',
    });
  }
}

async function getPolicyTypes() {
  return await Axios.get(`/policies/types`);
}
function* handleGetPolicyTypes() {
  try {
    const response = yield call(getPolicyTypes);
    if (response) {
      yield put({
        type: GET_POLICY_TYPES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_POLICY_TYPES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getPolicyDocuments() {
  return await Axios.get(`/policies/documents`);
}
function* handleGetPolicyDocuments() {
  try {
    const response = yield call(getPolicyDocuments);
    if (response) {
      yield put({
        type: GET_POLICY_DOCUMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_POLICY_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getPolicies(params) {
  return await Axios.get(`/policies`, { params });
}
function* handleGetPolicies({ payload }) {
  try {
    const response = yield call(getPolicies, payload);
    if (response) {
      yield put({
        type: GET_POLICIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_POLICIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleBudgetPolicies(params) {
  return await Axios.get(`/policies`, { params });
}
function* handleGetSingleBudgetPolicies({ payload }) {
  try {
    const response = yield call(getSingleBudgetPolicies, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_BUDGET_POLICIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_BUDGET_POLICIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSinglePolicy(code) {
  return await Axios.get(`/policies/${code}`);
}
function* handleFetchSinglePolicy({ payload }) {
  try {
    const response = yield call(fetchSinglePolicy, payload);
    if (response) {
      yield put({
        type: FETCH_SINGLE_POLICY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_POLICY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function editPolicy({ payload, code }) {
  return await Axios.put(`/policies/${code}`, payload);
}
function* handleEditPolicy({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(editPolicy, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: EDIT_POLICY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EDIT_POLICY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function pauseSingleBudget({ payload, code, budgetCode }) {
  return await Axios.put(
    `/policies/${code}/budget/${budgetCode}?status=${payload.status}`,
  );
}
function* handlePauseSingleBudget({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    delete payload.budgetCode;
    const response = yield call(pauseSingleBudget, {
      payload,
      code: getPayload.code,
      budgetCode: getPayload.budgetCode,
    });
    if (response) {
      yield put({
        type: PAUSE_BUDGET_POLICY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_POLICIES,
        blockType: 'pauseSingleBudget',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: PAUSE_BUDGET_POLICY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deletePolicy(payload) {
  return await Axios.delete(`/policies/${payload.code}`);
}
function* handleDeletePolicy({ payload }) {
  try {
    const response = yield call(deletePolicy, payload);
    if (response) {
      yield put({
        type: DELETE_POLICY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_POLICY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deletePolicyDocument(payload) {
  return await Axios.delete(`/policies/documents/${payload.code}`);
}
function* handleDeletePolicyDocument({ payload }) {
  try {
    const response = yield call(deletePolicyDocument, payload);
    if (response) {
      yield put({
        type: DELETE_POLICY_DOCUMENT_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_POLICY_DOCUMENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function linkBudgetPolicy(payload) {
  return await Axios.post(`/policies/${payload.code}/budget/${payload.budgetCode}`);
}
function* handleLinkBudgetPolicy({ payload }) {
  try {
    const response = yield call(linkBudgetPolicy, payload);
    if (response) {
      yield put({
        type: LINK_BUDGET_POLICY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_POLICIES,
        blockType: 'linkBudgetPolicy',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: LINK_BUDGET_POLICY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function unlinkBudgetPolicy(payload) {
  return await Axios.delete(`/policies/${payload.code}/budget/${payload.budgetCode}`);
}
function* handleUnlinkBudgetPolicy({ payload }) {
  try {
    const response = yield call(unlinkBudgetPolicy, payload);
    if (response) {
      yield put({
        type: UNLINK_BUDGET_POLICY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_POLICIES,
        blockType: 'linkBudgetPolicy',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UNLINK_BUDGET_POLICY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(CREATE_POLICIES_REQUEST, handleCreatePolicy),
  takeLatest(ADD_POLICY_DOCUMENT_REQUEST, handleAddPolicyDocument),
  takeLatest(ADD_SINGLE_POLICY_DOCUMENT_REQUEST, handleAddSinglePolicyDocument),
  takeLatest(GET_POLICY_TYPES_REQUEST, handleGetPolicyTypes),
  takeLatest(GET_POLICY_DOCUMENTS_REQUEST, handleGetPolicyDocuments),
  takeLatest(GET_POLICIES_REQUEST, handleGetPolicies),
  takeLatest(GET_SINGLE_BUDGET_POLICIES_REQUEST, handleGetSingleBudgetPolicies),
  takeLatest(FETCH_SINGLE_POLICY_REQUEST, handleFetchSinglePolicy),
  takeLatest(EDIT_POLICY_REQUEST, handleEditPolicy),
  takeLatest(PAUSE_BUDGET_POLICY_REQUEST, handlePauseSingleBudget),
  takeLatest(DELETE_POLICY_REQUEST, handleDeletePolicy),
  takeLatest(DELETE_POLICY_DOCUMENT_REQUEST, handleDeletePolicyDocument),
  takeLatest(LINK_BUDGET_POLICY_REQUEST, handleLinkBudgetPolicy),
  takeLatest(UNLINK_BUDGET_POLICY_REQUEST, handleUnlinkBudgetPolicy),
  takeLatest(GET_ALL_POLICY_ASSET_REQUEST, handleGetPolicyAsset),
]);
