import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import ReimbursementModal from 'components/ReimbursementView/ManagerModal';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSelectedReimbursements } from 'redux/actions/ReimbursementsAction';
import { buildReimbursementTableData } from 'utils/helper';
import { columnsReimbursement } from 'utils/mockData';

const TeamReimbursements = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { teamsId } = useParams();

  const [selectReimbursement, setSelectReimbursement] = useState(null);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredQuery, setFilteredQuery] = useState({});

  const debouncedValue = useDebounce(search, 600);

  const {
    getSelectedReimbursement: { data: { meta = {} } = {}, data = {}, loading, success },
  } = useSelector(({ reimbursement }) => reimbursement);
  const { page, total, hasMore, perPage } = meta;
  const { reimbursements = [] } = data;
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (debouncedValue)
      dispatch(getSelectedReimbursements({ team: teamsId, search: debouncedValue }));
    if (!debouncedValue && isFiltered) {
      dispatch(getSelectedReimbursements({ team: teamsId }));
    }
    if (debouncedValue) setIsFiltered(true);
  }, [debouncedValue]);

  const handleRowClick = (row) => {
    setSelectReimbursement(row);
  };

  useEffect(() => {
    if (reimbursements.length > 0 && !isFillData) {
      setIsFillData(true);

      const allReimbursements = reimbursements.map((data) => {
        return {
          value: data.user ? data.user.code : null,
          label: data.user ? `${data.user.firstName} ${data.user.lastName}` : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allReimbursements.filter(
        (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
      );

      setFilterData([...filterData, { title: 'Beneficiary', list: uniqueArray }]);
    }
  }, [reimbursements]);

  const handleFilter = (query) => {
    setIsFiltered(!!Object.keys(query).length);
    const { status: s, currency: c, beneficiary: b } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const user = b ? b.toString() : undefined;
    const team = teamsId;
    dispatch(
      getSelectedReimbursements({
        team,
        status,
        currency,
        user,
      }),
    );

    setFilteredQuery({ team, status, currency, user });
  };

  const handlePreviousPage = (page) => {
    dispatch(getSelectedReimbursements({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getSelectedReimbursements({ perPage, page, ...filteredQuery }));
  };

  if (loading && !isFiltered) return <Loading isPage color="#D28B28" />;

  return (
    <div className="reimbursements-wrapper">
      <PendingOnboardingNotice />

      <TopBar
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        setSearchVal={setSearch}
        searchVal={search}
        handleFilterApply={handleFilter}
        withOutSearch={true}
        showBarSearch={reimbursements?.length || (isFiltered && !reimbursements?.length)}
        showFilter={reimbursements?.length || (isFiltered && !reimbursements?.length)}
      />
      {!reimbursements.length ? (
        <div className="tabinnerWrapper">
          {isFiltered ? (
            <NoData
              headerText="You have no team reimbursements for this filter"
              bodyText="Alter the filter to see your reimbursement"
              withButton={false}
            />
          ) : (
            <NoData
              headerText="No team Reimbursements yet"
              bodyText="Reimbursements requests will be listed here"
              withButton={false}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row>
              <Col xs={12}>
                <Table
                  columns={columnsReimbursement}
                  data={buildReimbursementTableData(reimbursements)}
                  onRowClick={handleRowClick}
                  pagination
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          </Container>

          <ReimbursementModal
            selectReimbursement={selectReimbursement}
            setSelectReimbursement={setSelectReimbursement}
          />
        </>
      )}
    </div>
  );
};

export default TeamReimbursements;
