import { Tooltip } from 'antd';
import {
  AlertCircle,
  CancelICon,
  LoadingSpinner,
  TrashIcon,
  VerifiedIcon,
} from 'assets/icons';
import { ReactComponent as CloudArrowUp } from 'assets/icons/CloudArrowUp.svg';
import cs from 'classnames';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetUploadState,
  retryUploadFile,
  uploadFileCancel,
  uploadFilesRequest,
} from 'redux/actions/ProgressUploadAction';
import { eventEmitter } from 'redux/sagas/ProgressUploadSaga';
import { truncateMiddle } from 'utils/helper';
import { ICONS } from '.';
import Loading from '../Loading';
import { toastError } from '../toast';
import './styles.scss';

const mergeAndRemoveDuplicates = (existingFiles, newFiles) => {
  const fileMap = new Map();

  existingFiles?.forEach((file) => fileMap.set(file.id, file));

  newFiles?.forEach((file) => fileMap.set(file.id, file));

  return Array.from(fileMap.values());
};

const ServerFileUpload = forwardRef(
  (
    {
      label,
      wrapperClass,
      onChange,
      setUploadingFile,
      uploadingFile,
      supportType = 'Supported file types: jpeg, png, pdf. Max file size: 5mb',
      acceptedFile = {
        'image/jpeg': ['.jpeg', '.jpg', '.png'],
        'application/pdf': ['.pdf'],
      },
      externalUpload,
      checkIsDisabled,
      uploadedFile,
      name = 'file',
      multiple = false,
      isRounded,
      containerClass, //
      uploadText = 'Choose a file or drag & drop it here.', //
      setRemoveFile, //
      scan = false, //
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const uploadedfiles = useSelector(({ upload }) => upload);
    const serverUpload = uploadedfiles?.name === name ? uploadedfiles : {};

    const [uploaded, setUploaded] = useState(false);
    const [uploads, setUploads] = useState({
      files: [],
      status: {},
      error: {},
      assetData: [],
    });

    const { files: getFiles = [], error = {}, status = {}, assetData = [] } = uploads;

    const files = mergeAndRemoveDuplicates(getFiles, serverUpload?.files);

    const uploadStatus = { ...status, ...serverUpload?.status };

    useEffect(() => {
      if (serverUpload?.completed) {
        setUploads((prevUploads) => {
          const newFiles = serverUpload?.files?.filter(
            (file) => !prevUploads?.files?.some((f) => f.id === file.id),
          );
          const newAssetData = serverUpload?.assetData?.filter(
            (data) => !prevUploads?.assetData?.some((d) => d.id === data.id),
          );

          return {
            files: [...prevUploads.files, ...newFiles],
            status: { ...prevUploads.status, ...serverUpload?.status },
            assetData: [...prevUploads.assetData, ...newAssetData],
          };
        });
      }

      if (serverUpload?.files?.length === 1 && !multiple) {
        setUploaded(true);
      }
    }, [serverUpload, serverUpload?.completed]);

    useEffect(() => {
      if (onChange) {
        if (uploadingFile) setUploadingFile(false);
        if (multiple) onChange(assetData);
        else onChange(assetData[0]);
      }
    }, [assetData]);

    const onDrop = useCallback((aceptedFile, rejectedFiles, e) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        const errorMessages = rejectedFiles.map((file) => file.errors[0]?.code);
        if (errorMessages.includes('too-many-files')) {
          return toastError('Maximum of 10 files.');
        } else {
          return toastError('File size exceeded.');
        }
      }
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      disabled: uploadingFile,
      maxFiles: multiple ? 10 : 1,
      accept: acceptedFile,
      maxSize: 5 * 1024 * 1024,
      onDrop,
    });

    useEffect(() => {
      if (acceptedFiles.length > 0) {
        dispatch(uploadFilesRequest({ acceptedFiles, name, scan }));
        if (setUploadingFile) setUploadingFile(true);
      }
    }, [acceptedFiles]);
    //

    const handleMultipleRemove = (code) => {
      const { [code]: removedStatus, ...remainingStatus } = uploads?.status;

      const newObj = {
        files: uploads?.files?.filter((file) => file.id !== code),
        assetData: uploads?.assetData?.filter((asset) => asset.id !== code),
        error: {},
        status: remainingStatus,
      };

      setUploads(newObj);

      if (uploaded) {
        setUploaded(false);
      }

      dispatch(resetUploadState({ name }));
    };

    // useEffect(() => {
    //   if (removeFile) handleRemove();
    // }, [removeFile]);

    const uploadedName = uploadedFile ? 'Change file' : undefined;

    const UploadedFileComponents = ({ handleRemove, file }) => {
      const [progress, setProgress] = useState(0);
      const dispatch = useDispatch();
      const status = uploadStatus[file?.id];

      useEffect(() => {
        const handleProgress = ({ file: updatedFile, progress }) => {
          if (updatedFile?.id === file?.id) {
            setProgress(progress);
          }
        };

        eventEmitter.on('progress', handleProgress);

        return () => {
          eventEmitter.off('progress', handleProgress);
        };
      }, [file?.id]);

      const handleCancel = () => {
        dispatch(uploadFileCancel(file, name));
      };

      const handleRetry = () => {
        dispatch(retryUploadFile(file, name));
      };
      const totalFileSize = Math.round(file?.size / 1024);

      const fileSizeProgress = totalFileSize * (progress / 100);
      return (
        <div className="py-3 px-3 rounded-4 d-flex justify-content-between uploaded-file-container">
          {isRounded ? (
            <>
              <div className={'add-more'}>
                {(status === 'uploading' || externalUpload) && (
                  <Loading color="#D28B28" size={20} />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="d-flex w-100 align-items-center">
                <img src={ICONS[file?.type]} alt="icon" style={{ height: 28 }} />
                <div className="ms-3 w-100">
                  <p className="mb-1 p-0 m-0 uploaded-file-container-name text-start">
                    {truncateMiddle(file?.name, 60)}
                  </p>
                  {status === 'uploading' && (
                    <ProgressBar
                      now={progress ?? 0}
                      label={`${progress ?? 0}%`}
                      visuallyHidden
                    />
                  )}
                  <p className="uploaded-file-container-details gap-1 d-flex align-items-center p-0 m-0">
                    {status === 'uploading' && `${fileSizeProgress.toFixed(2)} KB of`}{' '}
                    {totalFileSize} KB •{' '}
                    {status === 'uploaded' ? (
                      <>
                        <VerifiedIcon className="align-text-top" />{' '}
                        <span>
                          Uploaded
                          {/* {format(new Date(item.created_at), 'd/MM/yyyy')} */}
                        </span>
                      </>
                    ) : status === 'uploading' ? (
                      <>
                        <LoadingSpinner /> <span>Uploading...</span>
                      </>
                    ) : ['failure', 'cancelled'].includes(status) ? (
                      <>
                        <AlertCircle className="align-text-top" />{' '}
                        {status === 'cancelled' ? (
                          <span>Cancelled</span>
                        ) : (
                          <span>
                            Failed
                            {error[file?.id]?.message
                              ? `(${error[file?.id]?.message})`
                              : ''}
                          </span>
                        )}
                      </>
                    ) : status === 'success' ? (
                      <>
                        <VerifiedIcon className="align-text-top" /> <span>Completed</span>
                      </>
                    ) : null}
                    <span className="d-flex align-items-center gap-1"></span>{' '}
                  </p>
                </div>
              </div>

              {status === 'failure' && (
                <div onClick={() => handleRetry()}>
                  <span className="upload-error nowrap cursor">Try again</span>
                </div>
              )}

              {status === 'uploading' && (
                <div>
                  <CancelICon style={{ width: '10px' }} onClick={() => handleCancel()} />
                </div>
              )}

              {['success', 'failed', 'cancelled'].includes(status) && (
                <div>
                  <TrashIcon
                    onClick={() => handleRemove()}
                    width="16"
                    height="16"
                    stroke="#79716B"
                  />
                </div>
              )}
            </>
          )}
        </div>
      );
    };

    return (
      <div
        className={cs('fileUpload groupWrapper cursor position-relative', {
          [wrapperClass]: wrapperClass,
        })}
      >
        <div>
          <label className="uploadFileLabel">{label}</label>
          {!uploaded && (
            <div>
              {isRounded ? (
                <Tooltip title="Add more files">
                  <div
                    onClick={uploadingFile ? checkIsDisabled : null}
                    className="add-more position-relative"
                  >
                    <div
                      {...getRootProps({
                        className:
                          'dropzone w-100 h-100 d-flex justify-content-center align-items-center',
                      })}
                    >
                      <input
                        {...getInputProps({ ref })}
                        id={name}
                        name={name}
                        className="position-absolute w-100 h-100"
                        disabled={uploadingFile}
                        style={{
                          zIndex: 1,
                          right: 0,
                          left: 0,
                          inset: 0,
                          opacity: 0,
                        }}
                      />
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 7.3335V16.6668M7.33325 12.0002H16.6666"
                          stroke="#A9A29D"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <div
                  className={cs('upload-container w-100 position-relative', {
                    [containerClass]: containerClass,
                  })}
                  onClick={uploadingFile ? checkIsDisabled : null}
                >
                  <div
                    {...getRootProps({
                      className: 'dropzone w-100 h-100',
                    })}
                  >
                    <input
                      {...getInputProps({ ref })}
                      id={name}
                      className="position-absolute w-100 h-100"
                      name={name}
                      disabled={uploadingFile}
                      style={{
                        zIndex: 1,
                        right: 0,
                        left: 0,
                        inset: 0,
                        opacity: 0,
                      }}
                    />

                    <div className="uploadFileIcon">
                      <CloudArrowUp />
                    </div>
                    <div className="uploadFileTextBorder">
                      <div className="text-sm fw-medium text-black">{uploadText}</div>
                      <div className="text-xs">{supportType}</div>

                      <button className="btn border xs">Browse file</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {!!files?.length &&
          files?.map((file, index) => (
            <div key={index} className="my-2">
              <UploadedFileComponents
                file={file}
                handleRemove={() => handleMultipleRemove(file?.id)}
              />
            </div>
          ))}
      </div>
    );
  },
);
export default ServerFileUpload;
