import classNames from 'classnames';
import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import './styles.scss';

const GroupedInput = ({ label, md = 12, wrapperClass = '', name, xs, ...res }) => {
  return (
    <Form.Group
      as={Col}
      md={md}
      xs={xs}
      className={classNames('groupWrapper form-group position-relative', {
        [wrapperClass]: wrapperClass,
      })}
    >
      {label && <Form.Label id={name}>{label}</Form.Label>}
      <InputGroup>
        <InputGroup.Text>https://</InputGroup.Text>
        <Form.Control name={name} id={name} {...res} />
      </InputGroup>
    </Form.Group>
  );
};

export default GroupedInput;
