import { Skeleton } from 'antd';

const TableLoading = () => {
  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <Skeleton.Button active size={40} style={{ width: '260px' }} />
        <Skeleton.Button active size={40} style={{ width: '100px' }} />
      </div>
      {Array.from({ length: 6 }).map((_, index) => {
        return (
          <Skeleton.Input
            active
            key={index}
            size={40}
            block={true}
            style={{ width: '100%', marginBottom: '15px' }}
          />
        );
      })}
    </div>
  );
};
export default TableLoading;
