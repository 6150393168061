import { useEffect, useState } from 'react';
import { Modal as BootstrapModal, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/UI/CustomButton/index';
import Loading from '../../../components/UI/Loading/index';
import RetryFailedTransactionTable from './RetryFailedTransactionTable';

import {
  bulkRetryTransactions,
  getFailedTransactions,
} from '../../../redux/actions/TransactionsAction';

import { toastError } from 'components/UI/toast';
import NoData from '../../../components/NoData/index';
import './retry.styles.scss';

const RetryTransactionModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const [transactionsData, setTransactionsData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const {
    failedTransactions: { data = {}, loading },
    bulkRetryTransactions: { loading: isBulkRetrying, success: isBulkRetrySuccess },
  } = useSelector(({ transaction }) => transaction);

  useEffect(() => {
    dispatch(getFailedTransactions({ status: ['failed'], perPage: 50 }));
  }, []);

  const closeModal = () => {
    const newData = addFalseState(data);
    setTransactionsData(newData);
    onHide();
  };

  const addFalseState = (data) => {
    return data?.transactions?.map((datum) => {
      return {
        ...datum,
        isChecked: false,
      };
    });
  };

  useEffect(() => {
    if (!loading && data) {
      const newData = addFalseState(data);
      setTransactionsData(newData);
    }
  }, [loading, data]);

  const handleGetSelected = (event, code) => {
    if (code) {
      const checkedData = transactionsData?.map((datum) => {
        if (datum.code === code) return { ...datum, isChecked: event.target.checked };
        return datum;
      });
      setTransactionsData(checkedData);
    }
  };

  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    const checkedData = transactionsData?.map((datum) => {
      return {
        ...datum,
        isChecked: event.target.checked,
      };
    });
    setTransactionsData(checkedData);
  };

  const extractSelectedCode = () => {
    return transactionsData
      ?.filter((datum) => {
        if (datum.isChecked) return datum.code;
      })
      .map((item) => item.code);
  };
  const onHandleBulkRetry = () => {
    const codes = extractSelectedCode();
    if (!codes.length) return toastError("You didn't select any transaction");
    const payload = {
      transactions: codes,
    };
    dispatch(bulkRetryTransactions(payload));
  };

  useEffect(() => {
    if (!isBulkRetrying && isBulkRetrySuccess) {
      dispatch(getFailedTransactions({ status: ['failed'], perPage: 50 }));
    }
  }, [isBulkRetrying, isBulkRetrySuccess]);

  const totalSelected = transactionsData?.filter((datum) => datum.isChecked).length;

  return (
    <>
      <BootstrapModal
        show={show}
        size={'xl'}
        centered
        onHide={closeModal}
        className="retry"
      >
        <Container className="px-0">
          <div className="header">
            <h1>Retry Transactions</h1>
            {transactionsData?.length ? (
              <div className="d-flex justify-items-center align-items-center gap-4">
                <span className="no-selected m-0 p-0">{`${totalSelected} of ${transactionsData?.length} selected`}</span>
                <CustomButton className="action" onClick={onHandleBulkRetry}>
                  {isBulkRetrying ? (
                    <Loading size={24} color="#d28b28" />
                  ) : (
                    'Retry Transactions'
                  )}
                </CustomButton>
              </div>
            ) : null}
          </div>
          <Row className="pt-4">
            {loading && (
              <div className="mt-4 pt-4">
                <Loading size={40} color="#D28B28" />
              </div>
            )}
            {!loading && transactionsData?.length > 0 && (
              <Col xs={12}>
                <RetryFailedTransactionTable
                  data={transactionsData}
                  handleCheckAll={handleCheckAll}
                  getSelected={handleGetSelected}
                  checkAll={checkAll}
                />
              </Col>
            )}
            {!loading && !transactionsData?.length && (
              <div className="pt-4 mt-4">
                <NoData
                  headerText="No failed transactions"
                  bodyText="You currently don't have any failed transactions, use the button below to go back to view all your transactions list"
                  withButton={true}
                  buttonLabel={'Back to transactions'}
                  onClickHandler={closeModal}
                />
              </div>
            )}
          </Row>
        </Container>
      </BootstrapModal>
    </>
  );
};

export default RetryTransactionModal;
