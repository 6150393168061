import { BankIcon, WalletIcon } from 'assets/icons';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import cs from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySubscription, updateSubscription } from 'redux/actions/BillingActions';
import { getBalances } from 'redux/actions/BudgetsAction';
import { getAvailableBalance, groupSourceOptions } from 'utils/helper';
// import './Checkout/checkout.styles.scss';

const options = [
  {
    id: 1,
    name: 'My Bujeti Balance',
    icons: WalletIcon,
    type: 'balance',
  },
  {
    id: 2,
    name: 'Bank Account',
    icons: BankIcon,
    type: 'directdebit',
  },
  // {
  //   id: 3,
  //   name: 'Credit or Debit Card',
  //   icons: [VisaIcon, MastercardIcon, AmexIcon, DinnersClubIcon, DiscoverIcon],
  //   type: 'card',
  // },
];

const ChangeBillingMethod = ({ onClose, code }) => {
  const [active, setActive] = useState('balance');

  const [billing, setBilling] = useState({
    balance: '',
    method: 'balance',
  });

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const dispatch = useDispatch();

  const {
    updateSubscription: { loading, success },
  } = useSelector(({ billing }) => billing);

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (success) {
      handleOnClose();
      dispatch(getCompanySubscription());
    }
  }, [success]);

  const onHandleClick = (type) => {
    if (type === active) return;
    setActive(type);
    setBilling({
      ...billing,
      balance: '',
      method: type,
    });
  };

  const handleChange = ({ name, value }) => {
    setBilling({
      ...billing,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets);
      const available_balance = getAvailableBalance(balances?.balances);
      setBudgetList(() => [...budget, ...available_balance]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, _, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAcctOptions(search, _, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter(
      (item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()) &&
        item?.value?.startsWith('bnk_'),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  const handleSubmit = () => {
    if (!billing.balance) return toastError('Please select a source');

    const isAvailableBalance = billing?.balance?.value?.startsWith('blc_');
    const isAvailableBackAccount = billing?.balance?.value?.startsWith('bnk_');

    const budget =
      isAvailableBalance || isAvailableBackAccount ? undefined : billing?.balance?.value;
    const method =
      billing.method === 'balance' && budget
        ? 'budget'
        : billing.method === 'balance' && !budget && isAvailableBalance
        ? 'balance'
        : 'directdebit';

    const payload = {
      method, // directdebit, budget
      directDebit:
        !budget && isAvailableBackAccount
          ? {
              bankAccount: billing?.balance?.value,
            }
          : undefined,
      budget,
      balance: !budget && isAvailableBalance ? billing?.balance?.value : undefined,
      code,
    };
    dispatch(updateSubscription(payload));
  };

  return (
    <div>
      <div className="mandate-modal-overlay" />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body ">
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="cancel-icon">
              <Cancel onClick={handleOnClose} />
            </div>
          </div>
          <div className="w-100">
            <div className="mt-0 w-100 mandate-wrapper">
              <h4 className="text-start">Edit your payment method</h4>
              <div className="billing-checkout-wrapper p-0">
                <div className="mt-4 billing-address" style={{ padding: 0 }}>
                  <div className="payment-options">
                    {options.map((option) => {
                      const Icon = option.icons;
                      return (
                        <div
                          key={option.id}
                          className={`payment-item ${
                            option.type == active && 'active'
                          } d-flex gap-3`}
                          onClick={() => onHandleClick(option.type)}
                        >
                          <div
                            className={`radio ${
                              option.type === active ? 'checked' : 'unchecked'
                            }`}
                          >
                            {active && (
                              <svg
                                width="10"
                                height="7"
                                viewBox="0 0 10 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.33317 1.5L3.74984 6.08333L1.6665 4"
                                  stroke="white"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                          <div className="w-100">
                            <h6>{option.name}</h6>
                            <div className="d-flex gap-2">
                              <span className="icon-wrapper">
                                <Icon />
                              </span>
                            </div>
                            {option.type == active && (
                              <div className="row mt-2">
                                <CustomSelectRadio
                                  label="From"
                                  placeholderText="Select a source"
                                  name="from"
                                  onChange={(val) =>
                                    handleChange({ name: 'balance', value: val })
                                  }
                                  value={billing.balance}
                                  isLoading={loadingBalances}
                                  loadOptions={
                                    option.type === 'balance'
                                      ? loadOptions
                                      : loadAcctOptions
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="mt-4 d-flex w-100 gap-3">
                <CustomButton
                  fullWidth
                  disabled={loading}
                  className="border-btn"
                  onClick={handleOnClose}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  fullWidth
                  disabled={loading}
                  loading={loading}
                  className={cs('confirm-btn')}
                  onClick={handleSubmit}
                >
                  Update
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangeBillingMethod;
