import React from 'react';
import classNames from 'classnames';

import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';

import dayjs from 'dayjs';
import CustomSelect from 'components/UI/CustomSelect';
import { addDays } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Switch } from 'antd';

import { removeSpaces, recurrenceOption } from 'utils/helper';
import { RefreshIcon } from 'assets/icons';

const Loader = () => {
  return (
    <>
      <section className="mt-4 border-top pb-2">
        <Skeleton.Input
          className="pt-4"
          active
          size={8}
          style={{ borderRadius: '4px' }}
        />
      </section>

      <div className="details-holder w-75">
        <div className="form-group">
          {Array.from({ length: 3 }).map((_, index) => (
            <div className="w-100 d-flex gap-3 mb-3" key={index}>
              <div className="w-100">
                <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
                <Skeleton.Input
                  active
                  style={{ borderRadius: '4px', height: 40 }}
                  block
                />
              </div>

              <div className="w-100">
                <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
                <Skeleton.Input
                  active
                  style={{ borderRadius: '4px', height: 40 }}
                  block
                />
              </div>
            </div>
          ))}
        </div>

        <div className="details-holder padded">
          <section className=" d-flex">
            <div className="d-flex gap-2">
              <Skeleton.Avatar active size={40} />

              <div className="d-flex flex-column">
                <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
                <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
              </div>
            </div>

            <div className="ms-auto">
              <Skeleton.Avatar active size={20} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

const InfoRow = ({
  values,
  getInfoValue,
  currencyOptions,
  isLoading,
  isRecurring,
  setIsRecurring,
  terms,
  termsValue,
  setTermsValue,
}) => {
  const { getCompany } = useSelector(({ companies }) => companies);
  const { data: companyData = {} } = getCompany;
  const dispatch = useDispatch();

  const formattedPhoneNumber = removeSpaces('+' + companyData?.contact_phone).replace(
    /^(\+\d{3})0/,
    '$1',
  );

  const setTerms = (value) => {
    setTermsValue(value);
    const term = value?.value;

    if (!term && term !== 0) {
      return getInfoValue({ target: { name: 'dueDate', value: '' } });
    }

    const dueDate = addDays(new Date(), term);
    getInfoValue({ target: { name: 'dueDate', value: dueDate } });
  };

  const handleSwitch = (value) => {
    setIsRecurring(value);
  };

  const formatOptionLabel = (props) => {
    const { label, details, isDisabled } = props;
    return (
      <div className={classNames('invoice-recurrence-option')}>
        <h1 className={classNames({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={classNames({ ['text-muted ']: isDisabled })}>{details}</span>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <section className="mt-4 border-top pb-2">
            <h2 className="region-header mt-4 pt-2">Invoice details</h2>
            <div className="details-holder details-holder-width">
              <div className="grid-col-2 form-group">
                <CustomInput
                  placeholder={'001'}
                  type="invoiceNumber"
                  name="name"
                  value={values.invoiceNumber}
                  disabled
                  label="Invoice number"
                />
                <div>
                  <label className="order-label" htmlFor="title">
                    Purchase order number <span>(Optional)</span>{' '}
                  </label>
                  <CustomInput
                    id="title"
                    placeholder="Enter a purchase order number"
                    type="text"
                    name="purchaseOrder"
                    value={values.purchaseOrder}
                    onChange={getInfoValue}
                    label=""
                  />
                </div>
              </div>
              {/* Currency - Issue date  */}
              <div className="grid-col-2 mt-3">
                <CustomSelect
                  isDisabled
                  placeholder="Select a currency *"
                  type="text"
                  name="currency"
                  value={values.currency}
                  classNamePrefix="invoice-select"
                  isCurrency
                  label="Currency"
                  options={currencyOptions}
                  onChange={(value) =>
                    getInfoValue({ target: { name: 'currency', value } })
                  }
                />

                <div>
                  <CustomDatePicker
                    id="invoice-date"
                    label="Issue date"
                    onChange={(value) =>
                      getInfoValue({ target: { name: 'issuedDate', value } })
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    disabled
                    dropdownMode="select"
                    placeholderText="DD / MM / YYYY"
                    selected={values.issuedDate && dayjs(values.issuedDate).toDate()}
                  />
                </div>
              </div>

              {/* Terms - Due date */}
              {!isRecurring && (
                <div className="grid-col-2 mt-3">
                  <CustomSelect
                    classNamePrefix="invoice-select"
                    label="Terms"
                    placeholder="Select"
                    name="terms"
                    options={terms}
                    onChange={(value) => setTerms(value)}
                    value={termsValue}
                  />

                  <CustomDatePicker
                    id="invoice-date"
                    label="Due date"
                    onChange={(value) =>
                      getInfoValue({ target: { name: 'dueDate', value } })
                    }
                    peekNextMonth
                    showMonthDropdown
                    minDate={new Date()}
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="DD / MM / YYYY"
                    disabled={termsValue.value === undefined}
                    selected={values.dueDate && dayjs(values.dueDate).toDate()}
                  />
                </div>
              )}

              <div className="mt-3">
                <div className="details-holder padded">
                  <section className=" d-flex">
                    <div className="d-flex gap-2">
                      <div className="recurring-holder">
                        <RefreshIcon isRecurring={isRecurring} />
                      </div>

                      <div>
                        <span className="recurring-text">Make recurring</span>
                        <span className="recurring-text alt">
                          Set up recurring invoice
                        </span>
                      </div>
                    </div>

                    <div className="ms-auto">
                      <Switch
                        checked={isRecurring}
                        onChange={(value) => handleSwitch(value)}
                        className="invoice-switch"
                      />
                    </div>
                  </section>

                  {isRecurring && (
                    <div className="mt-3 fade-in">
                      <CustomDatePicker
                        id="invoice-date"
                        label="Start date"
                        onChange={(value) =>
                          getInfoValue({ target: { name: 'startDate', value } })
                        }
                        peekNextMonth
                        showMonthDropdown
                        minDate={addDays(dayjs(new Date()).toDate(), 1)}
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="DD / MM / YYYY"
                        selected={values.startDate && dayjs(values.startDate).toDate()}
                      />

                      <div className="grid-col-2 mt-3">
                        <CustomSelect
                          classNamePrefix="invoice-select"
                          label="Repeats"
                          placeholder="Select"
                          name="schedule"
                          options={recurrenceOption.slice(1)}
                          formatOptionLabel={formatOptionLabel}
                          onChange={(value) =>
                            getInfoValue({ target: { name: 'repeats', value } })
                          }
                          value={values.repeats}
                        />

                        <CustomDatePicker
                          id="invoice-date"
                          label="End date"
                          onChange={(value) =>
                            getInfoValue({ target: { name: 'expiryDate', value } })
                          }
                          peekNextMonth
                          showMonthDropdown
                          minDate={addDays(dayjs(values.startDate).toDate(), 1)}
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="DD / MM / YYYY"
                          selected={
                            values.expiryDate && dayjs(values.expiryDate).toDate()
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default InfoRow;
