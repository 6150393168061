import {
  CREATE_VENDOR_REQUEST,
  GET_VENDOR_REQUEST,
  UPDATE_VENDOR_REQUEST,
  GET_SINGLE_VENDOR_REQUEST,
  RESET_BLOCK_VENDOR,
  DELETE_VENDOR_REQUEST,
  CREATE_BULK_VENDORS_REQUEST,
  EXPORT_VENDORS_REQUEST,
  VERIFY_VENDOR_INVITE_REQUEST,
  VERIFY_VENDOR_OTP_REQUEST,
  INVITE_VENDOR_REQUEST,
  VENDOR_INFO_REQUEST,
  RESEND_VENDOR_INVITE_REQUEST,
  UPDATE_EXTERNAL_VENDOR_REQUEST,
  MASS_UPDATE_VENDOR_REQUEST,
  REACTIVATE_VENDOR_REQUEST,
} from '../reducers/VendorsReducer';

export const getVendors = (payload) => ({
  type: GET_VENDOR_REQUEST,
  payload,
});

export const createVendors = (payload) => ({ type: CREATE_VENDOR_REQUEST, payload });

export const deleteVendors = (payload) => ({ type: DELETE_VENDOR_REQUEST, payload });

export const updateVendors = (payload) => ({ type: UPDATE_VENDOR_REQUEST, payload });
export const getSingleVendors = (payload) => ({
  type: GET_SINGLE_VENDOR_REQUEST,
  payload,
});

export const resetBlockVendor = (blockType) => ({
  type: RESET_BLOCK_VENDOR,
  blockType,
});

export const createBulkVendors = (payload) => ({
  type: CREATE_BULK_VENDORS_REQUEST,
  payload,
});

export const exportVendors = (payload) => ({
  type: EXPORT_VENDORS_REQUEST,
  payload,
});

export const verifyVendorInvite = (payload) => ({
  type: VERIFY_VENDOR_INVITE_REQUEST,
  payload,
});

export const verifyVendorOtp = (payload) => ({
  type: VERIFY_VENDOR_OTP_REQUEST,
  payload,
});

export const inviteVendor = (payload) => ({
  type: INVITE_VENDOR_REQUEST,
  payload,
});

export const resendVendorInvite = (payload) => ({
  type: RESEND_VENDOR_INVITE_REQUEST,
  payload,
});

export const vendorInfo = (payload) => ({
  type: VENDOR_INFO_REQUEST,
  payload,
});

export const updateExternalVendor = (payload) => ({
  type: UPDATE_EXTERNAL_VENDOR_REQUEST,
  payload,
});

export const massUpdateVendor = (payload) => ({
  type: MASS_UPDATE_VENDOR_REQUEST,
  payload,
});

export const reactivateVendor = (payload) => ({
  type: REACTIVATE_VENDOR_REQUEST,
  payload,
});
