import {
  APPROVE_REIMBURSEMENTS_REQUEST,
  CREATE_REIMBURSEMENTS_REQUEST,
  DECLINE_REIMBURSEMENTS_REQUEST,
  DELETE_REIMBURSEMENTS_REQUEST,
  GET_ALL_REIMBURSEMENTS_ASSET,
  GET_REIMBURSEMENTS_REQUEST,
  GET_SELECTED_REIMBURSEMENTS_REQUEST,
  GET_SINGLE_REIMBURSEMENTS_REQUEST,
  UPDATE_REIMBURSEMENTS_REQUEST,
  REVIEW_REIMBURSEMENTS_REQUEST,
} from '../reducers/ReimbursementsReducer';

export const createReimbursements = (payload) => ({
  type: CREATE_REIMBURSEMENTS_REQUEST,
  payload,
});
export const updateReimbursements = (payload) => ({
  type: UPDATE_REIMBURSEMENTS_REQUEST,
  payload,
});
export const getReimbursements = (payload) => ({
  type: GET_REIMBURSEMENTS_REQUEST,
  payload,
});
export const getSelectedReimbursements = (payload) => ({
  type: GET_SELECTED_REIMBURSEMENTS_REQUEST,
  payload,
});
export const getSingleReimbursement = (params) => ({
  type: GET_SINGLE_REIMBURSEMENTS_REQUEST,
  params,
});
export const deleteReimbursements = (payload) => ({
  type: DELETE_REIMBURSEMENTS_REQUEST,
  payload,
});
export const declineReimbursements = (payload) => ({
  type: DECLINE_REIMBURSEMENTS_REQUEST,
  payload,
});
export const approveReimbursements = (payload) => ({
  type: APPROVE_REIMBURSEMENTS_REQUEST,
  payload,
});

export const getAllReimbursementAsset = (payload) => ({
  type: GET_ALL_REIMBURSEMENTS_ASSET,
  payload,
});

export const reviewMultipleReimbursements = (payload) => ({
  type: REVIEW_REIMBURSEMENTS_REQUEST,
  payload,
});
