import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import RoleParagraphs from 'components/UI/CustomRoleSelect/RoleParagraphTruncateor';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError } from 'components/UI/toast';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createBeneficiaries, getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { getRoles } from 'redux/actions/RolesAction';

const InviteMembers = ({ setStep }) => {
  const dispatch = useDispatch();
  const {
    createBeneficiaries: { loading, success },
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getRoles: { data: rolesData = [] },
  } = useSelector(({ roles }) => roles);

  const { budgets = [] } = budgetData;
  const [budgetsData, setBudgetsData] = useState();

  const { beneficiaries = [] } = data;

  useEffect(() => {
    if (!rolesData?.length) dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (success) {
      dispatch(getBeneficiaries());
      setStep(1);
    }
  }, [success]);

  useEffect(() => {
    if (budgets.length > 0) {
      const budgetsOptions = budgets
        ?.filter(({ status }) => status != 'deleted')
        .map(({ code: value, name: label }) => ({ label, value }));
      setBudgetsData(budgetsOptions);
    }
  }, [budgets]);

  const [form, setForm] = useState({
    name: '',
    lastName: '',
    role: { value: null, label: 'Select a role' },
    link: { value: null, label: 'Select an option' },
    email: '',
    manager: { value: null, label: 'Select a manager' },
    beneficiaries: '',
    budget: [],
  });

  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };
  const handleSubmit = () => {
    if (!form.name) return toastError('Please enter member first name');
    if (!form.lastName) return toastError('Please enter member last name');
    if (!form.role.value) return toastError('Please select the member role');
    if (!form.email) return toastError('Please enter member email');
    // if (!form.budget.length) toastError('Place select assign budget');
    const isValid = !!form.name && !!form.email && !!form.role.value;
    if (isValid) {
      const payload = {
        firstName: form.name,
        lastName: form.lastName,
        role: form.role.code,
        email: form.email,
        manager: form.manager.code,
      };
      if (form.budget.length) {
        payload.budgets = form.budget.map((b) => b.value);
      }

      dispatch(createBeneficiaries(payload));
    }
  };

  const generateRoles = useMemo(() => {
    return rolesData?.map((datum) => {
      return {
        label: datum?.name,
        value: datum?.description ?? <RoleParagraphs datum={datum} />,
        code: datum?.code,
      };
    });
  }, [rolesData]);

  const generateOtherUsers = useMemo(() => {
    return beneficiaries.map(({ user: { code, firstName, lastName, role } }) => ({
      value: `${role?.name}`,
      code,
      label: `${firstName} ${lastName}`,
    }));
  }, [beneficiaries]);

  return (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Create new member</h2>

      <Row className="mb-3 mt-2">
        <CustomInput
          label="Their first name *"
          placeholder="Enter name"
          type="text"
          name="name"
          onChange={handleChange}
          value={form.name}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Their last name *"
          placeholder="Enter name"
          type="text"
          name="lastName"
          onChange={handleChange}
          value={form.lastName}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="What is their email? *"
          placeholder="Enter email"
          type="email"
          name="email"
          onChange={handleChange}
          value={form.email}
        />
      </Row>

      <Row className="mb-3">
        <CustomRoleSelect
          label="What is their role? *"
          options={generateRoles}
          placeholder="Member Roles"
          name="beneficiary"
          onChange={(val) => setForm({ ...form, role: val })}
          value={form.role}
        />
      </Row>

      <Row className="mb-3">
        <CustomRoleSelect
          label="Who is their manager? (Optional)"
          options={generateOtherUsers}
          placeholder="Who is their manager?"
          name="manager"
          onChange={(val) => setForm({ ...form, manager: val })}
          value={form.manager}
        />
      </Row>

      <Row className="mb-3">
        <MultipleSelect
          placeholder="Select budget"
          label="Assign Budget"
          isDisabled={!form.name || !form.lastName}
          name="budget"
          options={budgetsData}
          onChange={(val) => setForm({ ...form, budget: val })}
        />
      </Row>

      <div className="modal-footer mt-3">
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={handleSubmit}
          loading={loading}
          disabled={loading}
        >
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default InviteMembers;
