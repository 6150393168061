import { FileAttachment02Icon } from 'assets/icons';
import { useDispatch } from 'react-redux';
import { getAllPolicyAsset } from 'redux/actions/PoliciesAction';
import './styles.scss';

const FileAttachmentComponent = ({ documents }) => {
  const dispatch = useDispatch();

  return (
    <FileAttachment02Icon
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(getAllPolicyAsset(documents));
      }}
    />
  );
};
export default FileAttachmentComponent;
