import { all, put, takeEvery } from 'redux-saga/effects';
import {
  OPEN_CONNECT_REQUEST,
  OPEN_CONNECT_SUCCESS,
} from 'redux/reducers/MonoAccountLinkingReducer';

function* openMonoConnect(payload) {
  yield put({
    type: OPEN_CONNECT_SUCCESS,
    payload,
  });
}

export default all([takeEvery(OPEN_CONNECT_REQUEST, openMonoConnect)]);
