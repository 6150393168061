import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { allPermissions } from 'utils/AllowedTo';
import {
  budgetBeneficiariesAPI,
  createBudgets,
  getBalances,
  getBudgets,
  getSelectedBudget,
  getSingleBudgets,
  updateBudgets,
} from '../../../redux/actions/BudgetsAction';
import Allocation from './Card/Allocation';
import BudgetFundMethod from './Card/BudgetFundMethod';
import NewBeneficairy from './Card/NewBeneficiary';
import NewBudget from './Card/NewBudget';
import './styles.scss';

const INITIAL_STATE = {
  name: '',
  type: {
    value: 'one-time',
    label: 'Never',
    details: 'One-time budget',
  },
  time: { value: 'monthly', label: 'Monthly' },
  amount: '',
  businessAddress: '',
  beneficiaries: [],
  owners: [],
  currency: '',
  bufferAmount: '',
  exchangeRate: undefined,
  expiryDate: undefined,
};

const INITAIL_CHECKBOX = {
  buffer: false,
  expiration: false,
};

const BudgetModal = ({
  isOpen,
  toggleHandler,
  withoutModal,
  isUpdate,
  setIsOtherPopoverOpen,
  isOtherPopoverOpen,
  budgetCode,
  setIsAdvance,
  isAdvance,
  step,
  setStep,
  isTeams,
  setIsFunding,
  isFunding,
  setIsOpen,
  editExpiry,
}) => {
  if (!isOpen) return <div />;
  const { budgetId } = useParams();

  const [budgetInfo, setBudgetInfo] = useState(INITIAL_STATE);
  const [checkboxVisible, setCheckBoxVisible] = useState(INITAIL_CHECKBOX);

  const [splittingRule, setSplittingRule] = useState(false);

  const [beneficiariesAllocation, setBeneficiariesAllocation] = useState({});
  const [amountSum, setAmountSum] = useState();
  const [fundingStep, setFundingStep] = useState(1);
  const [existingBeneficiaries, setExistingBeneficiaries] = useState([]);

  const { onboardingStatus } = allPermissions();
  const {
    createBudget: { loading, success, data, error },
  } = useSelector(({ budgets }) => budgets);

  const {
    getSingleBudget: { data: getSingleBudgetData },
    updateBudget: { success: updateSuccess, loading: isUpdating },
    topUpBudget: { success: topUpsuccess },
    withdrawBudget: { success: withdrawSuccess },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    if (updateSuccess || withdrawSuccess) {
      dispatch(getSingleBudgets({ id: getSingleBudgetData.code }));
      dispatch(budgetBeneficiariesAPI({ budget: budgetId }));
    }

    if (topUpsuccess) {
      dispatch(getBalances());
      if (budgetId) dispatch(getSingleBudgets({ id: getSingleBudgetData.code }));
      else history.push(`/expenses/budgets/${data?.code}/overview`);
    }
  }, [updateSuccess, topUpsuccess, withdrawSuccess]);

  const { teamsId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (success) {
      if (!['pending', 'rejected'].includes(onboardingStatus)) {
        setBudgetInfo(INITIAL_STATE);
        if (!teamsId) history.push(`/expenses/budgets/${data?.code}/overview`);
        else dispatch(getSelectedBudget({ team: teamsId })), handleClose();
      } else {
        localStorage.setItem('setStep', JSON.stringify(3));
        toggleHandler();
        dispatch(getBudgets());
      }
    }
  }, [success]);

  const [isOtherPopoverOpenwithModal, setIsOtherPopoverOpenwithModal] = useState(false);

  const handleOtherPopover = (val) => {
    if (setIsOtherPopoverOpen) {
      if (withoutModal) setIsOtherPopoverOpen(val);
    }
    setIsOtherPopoverOpenwithModal(val);
  };

  const handleSubmit = () => {
    if (!budgetInfo.name) return toastError('Please enter the budget name');
    if (!budgetInfo.amount) return toastError('Please enter an amount');
    if (+budgetInfo.amount <= 0) return toastError('Please enter a valid amount');
    if (+budgetInfo.amount - amountSum < 0 && isAdvance)
      return toastError('Budget amount exceeded');

    const bufferAmount = Math.ceil(
      (Number(+budgetInfo?.bufferAmount) / 100) * Number(+budgetInfo.amount),
    );

    const owners = budgetInfo?.owners.length
      ? budgetInfo?.owners?.map((item) => {
          if (splittingRule) {
            return {
              amount: item.amount ? item.amount * 100 : 0,
              beneficiary: item?.value,
              isBudgetOwner: true,
            };
          }
          return { beneficiary: item?.value, isBudgetOwner: true };
        })
      : [];

    const beneficiaries = budgetInfo?.beneficiaries.length
      ? budgetInfo?.beneficiaries?.map((item) => {
          if (splittingRule) {
            return {
              amount: item.amount ? item.amount * 100 : 0,
              beneficiary: item?.value,
              isBudgetOwner: false,
            };
          }
          return { beneficiary: item?.value, isBudgetOwner: false };
        })
      : [];

    if (isUpdate) {
      const { amount, name, bufferAmount: currentBufferAmount } = getSingleBudgetData;

      const beneficiariesToAdd = [...owners, ...beneficiaries].map((item) => ({
        ...item,
      }));

      const updatedBeneficiaries = beneficiariesToAdd.map((bnfToadd) => {
        const corresponding = existingBeneficiaries.find(
          (extBnf) => extBnf?.value === bnfToadd?.beneficiary,
        );
        if (corresponding) {
          return { ...bnfToadd, isUpdate: true };
        } else {
          return bnfToadd;
        }
      });

      const beneficiariesToRemove = existingBeneficiaries
        .filter(
          (extBnf) =>
            extBnf && // Exclude null and undefined values
            !beneficiariesToAdd.some(
              (bnfToAdd) => bnfToAdd?.beneficiary === extBnf?.value,
            ),
        )
        .map((item) => item?.value);

      let payload = {
        code: getSingleBudgetData?.code,
        beneficiariesToAdd: updatedBeneficiaries,
        beneficiariesToRemove,
      };

      //update name
      if (name != budgetInfo.name) {
        payload.name = budgetInfo?.name;
      }

      if (bufferAmount > budgetInfo?.amount) {
        return toastError("Buffer amount can't exceed budget goal");
      }

      if (currentBufferAmount !== bufferAmount) {
        payload.bufferAmount = checkboxVisible?.buffer ? Number(bufferAmount) * 100 : 0;
      }

      //topUp Budget
      if (+budgetInfo.amount !== amount) {
        payload.amount = parseInt(budgetInfo.amount, 10) * 100;
      }

      const date = dayjs(budgetInfo?.expiryDate).format('YYYY-MM-DD');
      const timestamp = dayjs(budgetInfo?.expiryDate).format('HH:mm:ss');

      const schedule =
        budgetInfo.type.value !== 'one-time' ? budgetInfo.type.value : undefined;
      const expiryDate = checkboxVisible?.expiration ? { date, timestamp } : undefined;

      if (expiryDate || (schedule && schedule !== 'one-time'))
        payload.scheduleBudget = { schedule, expiryDate };

      dispatch(updateBudgets(payload));
      return;
    }

    const payload = {
      name: budgetInfo.name,
      amount: budgetInfo.amount * 100,
      currency: budgetInfo.currency,
      type: 1,
      beneficiaries: [...owners, ...beneficiaries],
      // owners: budgetInfo.owners,
      rate: budgetInfo.exchangeRate,
      team: teamsId,
    };

    if (budgetInfo?.bufferAmount) payload.bufferAmount = bufferAmount * 100;

    const isNotOneTime = budgetInfo.type.value !== 'one-time';
    if (checkboxVisible?.expiration || isNotOneTime) {
      const date = dayjs(budgetInfo?.expiryDate).format('YYYY-MM-DD');
      const timestamp = dayjs(budgetInfo?.expiryDate).format('HH:mm:ss');

      const schedule = isNotOneTime ? budgetInfo.type.value : undefined;
      const expiryDate = checkboxVisible?.expiration ? { date, timestamp } : undefined;
      payload.scheduleBudget = { schedule, expiryDate };
    }

    if (Number(budgetInfo?.bufferAmount) > Number(budgetInfo?.amount)) {
      return toastError("Buffer Amount can't exceed Budget goal");
    }

    const isAllocated = budgetInfo?.beneficiaries?.find((item) => item.amount > 0);

    for (let i = 0; i < budgetInfo?.beneficiaries?.length; i++) {
      if (beneficiaries[i].amount <= 0 && !isAllocated) {
        return toastError(
          `Enter a budget allocation amount for ${budgetInfo?.beneficiaries[i].label}`,
        );
      }
    }
    dispatch(createBudgets(payload));
  };

  const closeBankTransfer = () => {
    toggleHandler();
    if (budgetId) return dispatch(getSingleBudgets({ id: getSingleBudgetData.code }));
    return history.push(`/expenses/budgets/${data?.code}/overview`);
  };

  const handleClose = () => {
    if (isFunding && step === 4 && fundingStep === 1)
      return setIsFunding(false), setIsOpen(false);
    if (step === 4 && fundingStep === 2) return setFundingStep(1);
    if (isAdvance) return setStep(step - 1);
    if (step > 1 && !isAdvance && fundingStep !== 1) return setStep(step - 2);
    if (step > 1 && !isAdvance && fundingStep === 1) return closeBankTransfer();
    if (!isOtherPopoverOpenwithModal && !isFunding) return toggleHandler();
  };

  const renderCard = () => {
    switch (step) {
      case 1:
        return (
          <NewBudget
            setStep={setStep}
            setIsOtherPopoverOpen={handleOtherPopover}
            budgetInfo={budgetInfo}
            setBudgetInfo={setBudgetInfo}
            handleSubmit={handleSubmit}
            setBeneficiariesAllocation={setBeneficiariesAllocation}
            isUpdate={isUpdate}
            isTeams={isTeams}
            toggleHandler={toggleHandler}
            handleClose={handleClose}
            checkboxVisible={checkboxVisible}
            setCheckBoxVisible={setCheckBoxVisible}
            isUpdating={isUpdating}
            editExpiry={editExpiry}
            setExistingBeneficiaries={setExistingBeneficiaries}
            setSplittingRule={setSplittingRule}
            beneficiariesAllocation={beneficiariesAllocation}
            setAmountSum={setAmountSum}
            splittingRule={splittingRule}
          />
        );
      case 2:
        return (
          <Allocation
            setIsOtherPopoverOpen={handleOtherPopover}
            budgetInfo={budgetInfo}
            setBudgetInfo={setBudgetInfo}
            beneficiariesAllocation={beneficiariesAllocation}
            handleSubmit={handleSubmit}
            setIsAdvance={setIsAdvance}
            setAmountSum={setAmountSum}
            loading={loading}
            splittingRule={splittingRule}
            setSplittingRule={setSplittingRule}
          />
        );
      case 3:
        return <NewBeneficairy setStep={setStep} />;
      case 4:
        return (
          <BudgetFundMethod
            setStep={setStep}
            setIsOtherPopoverOpen={handleOtherPopover}
            budgetInfo={budgetInfo}
            setBudgetInfo={setBudgetInfo}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            closeModal={closeBankTransfer}
            fundingStep={fundingStep}
            isUpdate={isUpdate}
            setFundingStep={setFundingStep}
          />
        );

      default:
    }
  };

  if (withoutModal) return <div className="card-modal-body">{renderCard()}</div>;

  return (
    <div>
      <Modal
        show={isOpen}
        onClose={handleClose}
        modalStyle={{ height: '100vh' }}
        className="budget-modal"
      >
        <div className="content scroll px-md-0 py-md-0 px-3 py-4">
          <div className="card-modal-header">
            <>
              <div className="d-flex align-items-center cursor" onClick={handleClose}>
                {isAdvance || step === 3 || (step === 4 && fundingStep === 2) ? (
                  <>
                    <LeftIcon />
                    <span className="ps-1">
                      {step === 4 && fundingStep > 1 ? 'Choose another way' : 'Back'}
                    </span>
                  </>
                ) : (
                  <>
                    <CloseOutlined />
                    <span className="ps-1">Cancel</span>
                  </>
                )}
              </div>
            </>
          </div>
          {loading && !error ? (
            <div className="d-flex h-100">
              <div className="m-auto">
                <Loading />
              </div>
            </div>
          ) : (
            <div className="card-modal-body">{renderCard()}</div>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default BudgetModal;
