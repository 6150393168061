import { CloseOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as GrayBgCalender } from 'assets/icons/background-calender.svg';
import cs from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomSwitch from 'components/UI/CustomSwitch/inedx';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import Success from 'components/UI/ModalSaving/Success';
import LowBalanceUi from 'components/UI/low-balance-ui';
import { toastError } from 'components/UI/toast';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import numeral from 'numeral';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { searchRecipients } from 'redux/actions/BeneficiariesAction';
import {
  getBalances,
  getBudgetLedger,
  getSubAccountTransactions,
  topUpBudget,
} from 'redux/actions/BudgetsAction';
import {
  createPayments,
  getAllBanks,
  verifyBankAccount,
} from 'redux/actions/PaymentAction';
import { toggleAction } from 'redux/actions/ToggleAction';
import {
  getScheduledTransactions,
  getTransaction,
} from 'redux/actions/TransactionsAction';
import { RESET_FLAGS_BUDGET } from 'redux/reducers/BudgetsReducer';
import { RESET_BLOCK_PAYMENTS } from 'redux/reducers/PaymentReducer';
import { MAXLENGTH } from 'utils';
import {
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
  recurrencePaymentOption,
} from 'utils/helper';
import { calculateFees } from '../../redux/actions/FeesAction';
import Loading from '../UI/Loading/index';
import ItemsDetails from './ItemsDetails';
import './styles.scss';
import ConfirmDialog from 'components/ConfirmDialog';

const MAX_LENGTH = MAXLENGTH?.singlePaymentDescription;

const initailState = {
  budget: { value: undefined, label: 'Select a source' },
  currency: '',
  amount: '',
  recipientName: '',
  bank: '',
  accountNumber: '',
  receipt: undefined,
  description: undefined,
  accountName: undefined,
  vendorAddress: undefined,
  startDate: undefined,
  repeat: '',
  endDate: undefined,
  schedule: false,
};
const createOption = (label) => ({
  label,
  value: label,
});
const BujetiPayModal = ({
  selectedBudget,
  setSelectedBudget,
  recipientCode: recipientData,
  setOpenModal,
  setSelectTransaction,
  vendorData,
}) => {
  const recipientCode =
    typeof recipientData === 'string' ? recipientData : recipientData?.code;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { budgetId, subAccountCode, accountCode } = useParams();
  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    calculateFees: { data: feesData, loading: isFeesLoading, success: isFeesSuccess },
  } = useSelector(({ fees }) => fees);

  const { toggle, payload: dataAction = {} } = useSelector(({ toggle }) => toggle);
  const { payload: { selectedAccount, selectedCategoryData, queryParams = {} } = {} } =
    dataAction || {};
  const {
    searchRecipients: { data: searchRecipient = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const {
    createPayment: { success, loading, error },
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [notifyRecipient, setNotifyRecipient] = useState({
    checked: false,
    email: undefined,
  });
  const [payModal, setPayModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [paymentData, setPaymentData] = useState({
    ...initailState,
  });
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAccountSet, setIsAccountSet] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [budgetCheckModal, setBudgetCheckModal] = useState(false);
  const [payFromBalance, setPayFromBalance] = useState(undefined);
  const [topUpAndPay, setTopUpAndPay] = useState(undefined);
  const [sendFundRequest, setSendFundRequest] = useState(undefined);
  const [receipientName, setReceipientName] = useState();
  const [step, setStep] = useState(1);
  const [createable, setCreateable] = useState({
    isLoading: false,
    options: [],
    value: undefined,
  });

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);

  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    if (error?.includes('Insufficient')) {
      setStep(1);
      dispatch({ type: RESET_BLOCK_PAYMENTS, blockType: 'createPayment' });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getAllBanks());
    return () => {
      if (subAccountCode || accountCode) {
        dispatch({ type: RESET_FLAGS_BUDGET, blockType: 'getSubAccountTransactions' });
      }
    };
  }, []);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  // Get All banks ends here

  const handleDescriptionChange = (value) => {
    setPaymentData({
      ...paymentData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    paymentData?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const isAvailableBalance = paymentData.budget?.value?.startsWith('blc_');
  const isSubaccount = !!selectedAccount?.isSubaccount;
  const isAvailableBankAccount = paymentData.budget?.value?.startsWith('bnk_');
  const hasDirectDebit = paymentData.budget?.isDirectDebit;
  const currency = paymentData?.currency;

  useEffect(() => {
    if (selectedBudget)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        budget: selectedBudget,
      }));
    if (selectedAccount)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        budget: getAvailableBalance([{ ...selectedAccount }])?.[0],
      }));
  }, [selectedBudget, selectedAccount]);

  //ends here

  const handleSelectChange = (val) => setValue(val);

  // Handle Change After Debounce
  useEffect(() => {
    if (!searchRecipient?.recipients?.length) dispatch(searchRecipients());
    if (!banksData) dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    const searched = searchRecipient?.recipients?.filter(
      ({ status }) => status === 'active',
    );
    const opt = searched?.map(({ name: label, code: value, email }) => ({
      label,
      value,
      email,
    }));

    if (opt) setOptions(opt);

    if (searched && recipientCode) {
      const selectedRecipient = searched?.filter(({ code }) => code === recipientCode);

      if (selectedRecipient.length > 0) {
        const { name: label, code: value, email } = selectedRecipient[0];

        if (typeof recipientData === 'object') {
          if (recipientData?.repeat) {
            setStep(2);
            handleSelect(
              {
                label,
                value,
                email,
              },
              {
                amount: recipientData?.amount / 100,
                ...(recipientData?.category?.code && {
                  category: {
                    value: recipientData?.category?.code,
                    label: recipientData?.category?.name,
                  },
                }),
                budget: {
                  value: recipientData?.source?.code,
                  label: recipientData?.source?.name,
                },
              },
            );
          } else {
            setPaymentData({
              ...paymentData,
              ...(recipientData?.category?.code && {
                category: {
                  value: recipientData?.category?.code,
                  label: recipientData?.category?.name,
                },
              }),
              recipientName: {
                value: recipientData?.code,
                label: recipientData?.bank_account?.accountName,
                email: recipientData?.email,
              },
              accountName: recipientData?.bank_account?.accountName,
              accountNumber: recipientData?.bank_account?.number,
              bank: {
                label: recipientData?.bank_account?.bankName,
                value: recipientData?.bank_account?.bankCode,
              },
            });
            setIsAccountSet(true);
          }
        } else {
          handleSelect({
            label,
            value,
            email,
          });
        }
      }
    }
  }, [searchRecipient]);

  useEffect(() => {
    setCreateable({
      ...createable,
      options,
      ...(vendorData && { value: vendorData }),
    });
  }, [options, vendorData?.value]);

  // Handle Createable
  const handleCreate = (inputValue) => {
    if (inputValue.length < 2) return toastError('Name is too short');
    setCreateable({ isLoading: true });
    setTimeout(() => {
      const { options } = createable;
      const newOption = createOption(inputValue);
      setCreateable({
        value: newOption,
        options: [...options, newOption],
        isLoading: false,
      });
      setPaymentData({
        ...paymentData,
        recipientName: newOption,
        accountName: undefined,
        accountNumber: '',
        bank: '',
      });
      setHasAccount(false);
    }, 1000);
  };

  const removeIsSchedule = () => {
    localStorage.removeItem('schedule');
  };

  const getIsScheduled = localStorage.getItem('schedule');

  //goback
  const goBack = () => {
    setStep(step - 1);
  };

  // Toggle action
  const payToggleHandler = () => {
    if (step === 2 && !isLoaded) return goBack();
    if (selectedBudget) {
      setSelectedBudget('');
    } else if (recipientCode) {
      setOpenModal(false);
    } else {
      dispatch(toggleAction());
      removeIsSchedule();
    }

    dispatch({ type: RESET_BLOCK_PAYMENTS, blockType: 'verifyBankAccount' });
    dispatch({ type: RESET_BLOCK_PAYMENTS, blockType: 'createPayment' });
    setIsLoaded(false);
    setPaymentData({
      ...initailState,
    });
    setCreateable({ ...createable, value: undefined });
    if (success) {
      if (setSelectTransaction) setSelectTransaction(null);
      dispatch(getBalances());
      if (budgetId) {
        dispatch(getBudgetLedger({ budget: budgetId }));
      }
      dispatch(getTransaction({ ...queryParams }));
    }
  };

  useEffect(() => {
    if (selectedBudget) {
      setPayModal(!!selectedBudget);
    } else if (recipientData) {
      setPayModal(!!recipientData);
    } else {
      setPayModal(toggle);
    }
  }, [toggle, selectedBudget, recipientCode]);
  //

  useEffect(() => {
    if (success) {
      setIsLoaded(true);
      setPaymentData({
        ...initailState,
      });
      setCreateable({ ...createable, value: undefined });
      if (subAccountCode || accountCode) {
        dispatch(getSubAccountTransactions({ code: subAccountCode ?? accountCode }));
      }
      setStep(1);
    }
  }, [success]);

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    if (name === 'accountNumber')
      return (
        validity.valid &&
        setPaymentData((prevPaymentData) => ({ ...prevPaymentData, [name]: value }))
      );
    return setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [name]: rawValue ? rawValue : value,
    }));
  };
  //

  const handleSelect = (value, recipientPaymentData) => {
    if (!value)
      return (
        setCreateable({ ...createable, value: undefined }),
        setPaymentData((prevPaymentData) => ({
          ...prevPaymentData,
          ...recipientPaymentData,
          bank: '',
          recipientName: value,
          accountNumber: '',
        }))
      );
    const filterBeneficiary = searchRecipient?.recipients?.filter(
      ({ code }) => code === value.value,
    );

    if (filterBeneficiary && filterBeneficiary[0]?.bankAccount?.bankCode) {
      const {
        bankCode,
        number,
        accountName,
        bankName: label = '',
      } = filterBeneficiary[0]?.bankAccount;
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        ...recipientPaymentData,
        recipientName: value,
        accountNumber: number,
        accountName,
        bank: { value: bankCode, label },
      }));
      setCreateable({ ...createable, value });
      setHasAccount(true);
    } else {
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        ...recipientPaymentData,
        bank: '',
        accountName: undefined,
        recipientName: value,
        accountNumber: '',
      }));
      setCreateable({ ...createable, value });
      setHasAccount(false);
    }
  };

  // set vendor address
  useEffect(() => {
    if (paymentData.recipientName?.value) {
      const { recipientName } = paymentData;
      const vendorAddress = searchRecipient?.recipients?.filter(
        ({ code, accountType }) =>
          code === recipientName?.value && accountType === 'vendor',
      );

      if (vendorAddress) {
        const { street = undefined, state = undefined } = vendorAddress[0]?.address || {};
        setPaymentData((prevPaymentData) => ({
          ...prevPaymentData,
          ...(vendorData && { accountName: vendorData?.label }),
          vendorAddress: street && `${street}, ${state}.`,
        }));
      }
    }
  }, [paymentData.recipientName]);

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const paymentAmount = Number(paymentData.amount) * 100;

  const checkBalance = paymentData?.budget?.amount * 100 - paymentAmount < 0;

  const isNotOneTime =
    paymentData.repeat.value && paymentData.repeat.value !== 'one-time';
  // handle next step
  const handleNextStep = () => {
    if (!paymentData?.budget?.value) return toastError('Please select a source of funds');
    if (!paymentData.amount) return toastError('Please enter an amount');
    if (+paymentData.amount <= 0) return toastError('Please enter a valid amount');
    if (!paymentData.recipientName.label)
      return toastError('Please enter recipient name');
    if (!paymentData.bank) return toastError('Please select a bank');
    if (!paymentData.accountNumber) return toastError('Please enter account number');
    if (paymentData.accountNumber.length < 10)
      return toastError('Please enter a valid account number');

    if (!hasDirectDebit && checkBalance && !paymentData.schedule) {
      if (!isAvailableBalance && !paymentData.alternativeSource)
        return toastError('Budget balance is low please topup or change the budget');
      if (
        !paymentData?.alternativeSource ||
        paymentData?.alternativeSource?.amount * 100 - paymentAmount < 0
      )
        return toastError('Available balance is low please topup or use a budget');
    }

    if (paymentData.schedule) {
      if (!paymentData.startDate) return toastError('Please enter a start date');
      if (!paymentData.repeat) return toastError('Please select a repeat pattern');
      if (!paymentData.endDate && isNotOneTime)
        return toastError('Please enter a end date');
    }

    const data = {
      amount: paymentData?.amount * 100,
      currency: paymentData?.currency,
    };
    dispatch(calculateFees(data));
    setStep(2);
  };
  useEffect(() => {
    if (payFromBalance || topUpAndPay || sendFundRequest) handleSubmit();
  }, [payFromBalance, topUpAndPay]);
  // handle submit
  const handleSubmit = () => {
    // if (!paymentData.description) return toastError('Please enter a description');
    if (!payFromBalance || paymentData.impactBudget.isFunded) setPayFromBalance(false);
    if (
      !sendFundRequest &&
      paymentData?.impactBudget?.amount + (paymentData?.impactBudget?.buffer || 0) <
        paymentAmount
    ) {
      if (paymentData.impactBudget.isFunded) {
        setSendFundRequest(true);
        return setBudgetCheckModal(true);
      } else setSendFundRequest(false);
    }

    const startDateObject = dayjs(paymentData.startDate);
    const endDateObject = dayjs(paymentData.endDate);

    const minutes = startDateObject.format('mm');
    const hours = startDateObject.format('HH');
    const dayOfMonth = startDateObject.format('DD');
    const month = startDateObject.format('MMMM').toLowerCase();
    const dayOfWeek = startDateObject.format('dddd').toLowerCase();
    const startDateFormatted = startDateObject.format('YYYY-MM-DD');
    const startTimestamp = startDateObject.format('HH:mm:ss');

    const endDateFormatted = endDateObject.format('YYYY-MM-DD');
    const endTimestamp = endDateObject.format('HH:mm:ss');

    const scheduleTransaction = {
      schedule: isNotOneTime
        ? paymentData.repeat.value
        : {
            minutes,
            hours,
            dayOfMonth,
            month,
            dayOfWeek,
          },
      startDate: {
        date: startDateFormatted,
        timestamp: startTimestamp,
      },
      expiryDate: isNotOneTime
        ? {
            date: endDateFormatted,
            timestamp: endTimestamp,
          }
        : undefined,

      recurring: isNotOneTime,
    };

    let budget =
      isAvailableBalance || isAvailableBankAccount ? undefined : paymentData.budget.value;
    let balance = !budget && isAvailableBalance ? paymentData.budget.value : undefined;
    let impactBudget = paymentData?.impactBudget?.value || undefined;
    if (paymentData.alternativeSource) {
      balance = paymentData.alternativeSource.value;
      impactBudget = budget;
      budget = undefined;
    }

    const payload = {
      amount: Number(paymentAmount.toFixed(2)),
      currency: paymentData.currency,
      directDebit:
        !budget && isAvailableBankAccount
          ? {
              bankAccount: paymentData.budget.value,
            }
          : undefined,
      impactBudget,
      budget,
      balance,
      recipient:
        paymentData.accountName && hasAccount
          ? paymentData.recipientName.value
          : paymentData.accountName,
      bank_account: {
        bankName: paymentData.bank.label,
        bankCode: paymentData.bank.value,
        accountName: paymentData.accountName ?? paymentData.recipientName.label,
        number: paymentData.accountNumber,
        currency: paymentData.currency,
      },
      description: paymentData?.description ?? undefined,
      receipt: paymentData.receipt,
      notifyRecipient: notifyRecipient.checked,
      recipientEmail: paymentData.recipientName.email || notifyRecipient.email,
      scheduleTransaction: paymentData.schedule ? scheduleTransaction : undefined,
    };

    if (paymentData?.category?.value) {
      payload.category = paymentData?.category?.value;
    }
    dispatch(createPayments(payload));
    setPayFromBalance(undefined);
  };
  //

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balance');
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }, type = null) {
    setLoadPage(page);
    let filteredOption = [];
    if (type === 'budget') {
      filteredOption = budgetList.filter((item) => item?.type === 'budget');
    } else
      filteredOption = budgetList.filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()),
      );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAccountOptions(search, loadedOptions, { page }) {
    setLoadPage(page);
    let filteredOption = budgetList.filter(({ type }) => type !== 'budget');
    if (search)
      filteredOption = filteredOption.filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()),
      );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  useEffect(() => {
    if (
      paymentData.accountNumber.length === 10 &&
      paymentData.bank.value &&
      !recipientData
    ) {
      const { accountNumber, bank } = paymentData;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bank.value }));
    }
    if (paymentData.accountNumber.length < 10) {
      setIsAccountSet(false);
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        accountName: undefined,
      }));
    }
  }, [paymentData.accountNumber, paymentData.bank.value]);

  useEffect(() => {
    if (accName?.account_name) {
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        accountName: accName?.account_name,
      }));
      setCreateable({
        ...createable,
        value: { value: accName?.account_name, label: accName?.account_name },
      });
      setIsAccountSet(true);
    }
    if (accountNameError)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        accountName: undefined,
      }));
    if (accountNameLoading)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        accountName: undefined,
      }));
  }, [accName, accountNameError, accountNameLoading]);

  useEffect(() => {
    if (receipientName) {
      setCreateable({
        ...createable,
        value: { value: receipientName, label: receipientName },
      });
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        recipientName: {
          value: receipientName,
          label: receipientName,
        },
        accountName: undefined,
      }));
      setReceipientName();
    }
  }, [receipientName]);

  // auto set expense category for vendor
  useEffect(() => {
    const getVendorCategory = searchRecipient?.recipients?.find(
      ({ code, accountType }) =>
        code === paymentData.recipientName?.value && accountType == 'vendor',
    );
    if (getVendorCategory?.categories.length === 1) {
      const { code: value, name: label } = getVendorCategory?.categories[0]?.category;
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        category: { value, label },
      }));
    }
  }, [paymentData.recipientName?.value]);

  const onToggleRecipient = (event, type) => {
    setNotifyRecipient({
      ...notifyRecipient,
      [type]: event.target.checked,
    });
  };

  useEffect(() => {
    setNotifyRecipient({
      ...notifyRecipient,
      checked: !!paymentData.recipientName?.email,
    });
  }, [paymentData.recipientName?.email]);

  useEffect(() => {
    if (getIsScheduled)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        schedule: !!getIsScheduled,
      }));
    if (!paymentData.schedule) removeIsSchedule();
  }, [getIsScheduled, paymentData.schedule]);

  const visible = accountNameLoading || accountNameError;

  const handleRoute = () => {
    let route = null;
    if (paymentData?.alternativeSource?.value)
      route = `/accounts/${paymentData.alternativeSource.value}`;
    else {
      route =
        (isAvailableBankAccount || isAvailableBalance) && isSubaccount
          ? `/accounts/subaccounts/${paymentData.budget.value}`
          : isAvailableBankAccount || isAvailableBalance
          ? `/accounts/${paymentData.budget.value}`
          : `/expenses/budgets/${paymentData.budget.value}/overview`;
    }

    push({
      pathname: route,
      state: { fund: true },
    });
    dispatch(toggleAction());
  };

  const formatOptionLabel = (props) => {
    const { value, label, details, isDisabled } = props;
    return (
      <div className={cs('format-recurrence-option')}>
        <h1 className={cs({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={cs({ ['text-muted']: isDisabled })}>{details}</span>
      </div>
    );
  };

  useEffect(() => {
    if (paymentData.startDate)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        repeat: recurrencePaymentOption(paymentData.startDate)[0],
      }));
  }, [paymentData.startDate]);

  const handleDateChange = (date, name) => {
    const currentHours = getHours(new Date());
    const currentMinutes = getMinutes(new Date());
    const value = setMinutes(setHours(date, currentHours), currentMinutes + 5);

    if (!paymentData[name]) {
      handleChange({ target: { name: name, value } });
    } else {
      handleChange({ target: { name: name, value: date } });
    }
  };

  const isRepeatPayment = recipientCode && typeof recipientData === 'object';
  const budgetCard = () => (
    <div className="information-wrapper">
      <h2 className="card-title w-100">Send funds</h2>
      <div className="form-normal">
        <Row className="mb-3 mt-3 align-items-center">
          <CustomSelectRadio
            label="Where are you paying from?"
            name="budget"
            placeholder="Select a source"
            onChange={(val) => {
              setPaymentData((prevPaymentData) => ({ ...prevPaymentData, budget: val }));
              if (paymentData.alternativeSource && val.value.startsWith('blc_'))
                setPaymentData((prevPaymentData) => ({
                  ...prevPaymentData,
                  alternativeSource: null,
                }));
            }}
            value={paymentData.budget}
            isLoading={loadingBalances}
            isDisabled={!!selectedBudget || !!selectedAccount || loadingBalances}
            loadOptions={loadOptions}
          />
        </Row>

        {paymentData.budget.type !== 'budget' &&
          paymentData?.budget?.amount === 0 &&
          !hasDirectDebit && (
            <LowBalanceUi
              currency={paymentData.currency}
              amount={paymentData.budget.amount}
              isBalanceLow={true}
              route={handleRoute}
              type={0}
            />
          )}

        {paymentData?.budget?.value &&
          !paymentData.budget.isFunded &&
          paymentData.budget.type === 'budget' && (
            <>
              <LowBalanceUi
                currency={paymentData.currency}
                amount={paymentData.budget.amount}
                isBalanceLow={true}
                route={handleRoute}
                type={1}
              />
              <Row className="mb-3 mt-3 align-items-center">
                <CustomSelectRadio
                  label="Where should the money comes from?"
                  badge="New"
                  name="alternativeSource"
                  placeholder="Select a source"
                  onChange={(val) =>
                    setPaymentData((prevPaymentData) => ({
                      ...prevPaymentData,
                      alternativeSource: val,
                    }))
                  }
                  value={paymentData.alternativeSource}
                  isLoading={loadingBalances}
                  isDisabled={!!selectedBudget || !!selectedAccount || loadingBalances}
                  loadOptions={loadAccountOptions}
                />
              </Row>
              {paymentData?.alternativeSource?.amount === 0 && (
                <LowBalanceUi
                  currency={paymentData.currency}
                  amount={paymentData.alternativeSource.amount}
                  isBalanceLow={true}
                  route={handleRoute}
                  type={0}
                />
              )}
            </>
          )}

        <Row className="mb-3">
          <CustomInput
            label="How much are you paying?"
            isAmount
            placeholder="100,000.00"
            type="text"
            name="amount"
            className="amount"
            onChange={handleChange}
            value={paymentData.amount}
            getTypeVal={(val) =>
              setPaymentData((prevPaymentData) => ({ ...prevPaymentData, currency: val }))
            }
          />
        </Row>

        <Row className="mb-3">
          <CustomSelect
            label="Who are you paying?"
            name="recipientName"
            placeholder="Type their name"
            noOptionsMessage={() => null}
            isDisabled={
              createable.isLoading ||
              (!!recipientCode && paymentData?.recipientName) ||
              isRepeatPayment
            }
            isLoading={createable.isLoading}
            options={createable.options}
            value={
              isRepeatPayment
                ? {
                    value: recipientCode,
                    label: recipientData?.bank_account?.accountName,
                  }
                : createable.value
            }
            onCreateOption={handleCreate}
            onInputChange={handleSelectChange}
            onChange={(val) => handleSelect(val)}
            createable={true}
          />
        </Row>
        {createable.value && !hasAccount && (
          <>
            <Row className="mb-3">
              <CustomSelect
                label="What is their bank?"
                name="bankName"
                placeholder="Select a bank"
                onChange={(val) =>
                  setPaymentData((prevPaymentData) => ({ ...prevPaymentData, bank: val }))
                }
                value={paymentData.bank}
                options={allBanks}
                onMenuClose={onMenuCloseBanks}
                onInputChange={handleGetBankOnChange}
                isDisabled={loadingBanks && !bankValuedebounced}
                isLoading={loadingBanks && !bankValuedebounced}
              />
            </Row>

            <Row className="mb-3">
              <div className={cs('relative', { ['mb-5']: visible })}>
                <CustomInput
                  type="text"
                  label="What is their account number?"
                  placeholder="Enter the number"
                  name="accountNumber"
                  onChange={handleChange}
                  value={paymentData.accountNumber}
                  maxLength="10"
                  pattern="[0-9]*"
                />
                <AccountName
                  // setValue={setReceipientName}
                  visible={visible}
                  accountNameError={accountNameError}
                  accountNameLoading={accountNameLoading}
                />
              </div>
            </Row>
          </>
        )}

        <Row className="mb-3">
          <CategoryDropdown
            label="What are you paying for?"
            placeholder="Select a category"
            onChange={(value) => handleChange({ target: { value, name: 'category' } })}
            value={paymentData.category}
            name="category"
          />
        </Row>

        {paymentData?.budget?.value?.startsWith('blc_') && (
          <Row className="mb-3">
            <CustomSelectRadio
              label="Which budget to impact on? (Optional)"
              badge="New"
              placeholder="Select a budget"
              name="impactBudget"
              onChange={(val) =>
                setPaymentData((prevPaymentData) => ({
                  ...prevPaymentData,
                  impactBudget: val,
                }))
              }
              value={paymentData.impactBudget}
              isLoading={loadingBalances}
              loadOptions={(...args) => loadOptions(...args, 'budget')}
            />
          </Row>
        )}

        <Row className="mb-3 mx-0">
          <div className="payment__schedule-wrapper">
            <div className="d-flex justify-content-between align-items-start w-100">
              <div className="d-flex gap-3 align-items-center">
                <GrayBgCalender />
                <div className="schedule-label">
                  <h6>Schedule</h6>
                  <p>or set up recurring payment</p>
                </div>
              </div>
              <div>
                <CustomSwitch
                  onChange={(value) =>
                    handleChange({
                      target: { name: 'schedule', value: value },
                    })
                  }
                  checked={paymentData?.schedule}
                ></CustomSwitch>
              </div>
            </div>
            {paymentData.schedule && (
              <div className="form-normal w-100 mx-0 px-0">
                <Row className="my-2 mx-0 px-0">
                  <CustomDatePicker
                    wrapperClass="px-0 mx-0"
                    label="Date"
                    onChange={(value) => handleDateChange(value, 'startDate')}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeSelect
                    timeCaption="Time"
                    name="startDate"
                    peekNextMonth
                    minDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Start date"
                    selected={
                      paymentData.startDate && dayjs(paymentData.startDate).toDate()
                    }
                  />
                </Row>

                <Row className="my-2 mx-0 px-0">
                  <CustomSelect
                    label="Repeats"
                    name="repeat"
                    placeholder="Repeats"
                    isDisabled={!paymentData.startDate}
                    options={recurrencePaymentOption(paymentData.startDate)}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(repeat) =>
                      setPaymentData((prevPaymentData) => ({
                        ...prevPaymentData,
                        repeat,
                      }))
                    }
                    value={paymentData.repeat}
                    wrapperClass="px-0 mx-0"
                  />
                </Row>
                {isNotOneTime && (
                  <Row className="my-3 mx-0">
                    <CustomDatePicker
                      label="End date"
                      onChange={(value) => handleDateChange(value, 'endDate')}
                      disabled={paymentData.repeat.value === 'one-time'}
                      timeInputLabel="Time:"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showTimeSelect
                      timeCaption="Time"
                      name="endDate"
                      peekNextMonth
                      minDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="End date"
                      selected={
                        paymentData.endDate && dayjs(paymentData.endDate).toDate()
                      }
                    />
                  </Row>
                )}
              </div>
            )}
          </div>
        </Row>

        <CustomButton
          onClick={handleNextStep}
          fullWidth={true}
          disabled={loading || uploadingFile || (!isAccountSet && !recipientData)}
        >
          Review details
        </CustomButton>
      </div>
    </div>
  );

  const PreviewCard = () => {
    return (
      <div className="information-wrapper">
        <h2 className="card-title w-100 mb-4">Review your transfer details</h2>
        <div className="form-normal">
          <div className="preview-card__transfer mb-3">
            <div className="px-4 pt-1 pb-2 w-100">
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Transfer details</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Edit
                  </span>
                }
              />

              <ItemsDetails
                title="Total amount we'll send"
                value={`${getCurrency(currency)}${numeral(paymentData.amount).format(
                  '0,0.00',
                )}`}
              />
              {isFeesLoading && <Loading color="#D28B28" size={18} />}
              {!isFeesLoading && feesData && (
                <>
                  <ItemsDetails
                    title="Transaction fees"
                    value={
                      <CurrencyFormat
                        prefix={!feesData.fee ? '' : getCurrency(currency)}
                        value={!feesData.fee ? 'Free' : feesData.fee / 100}
                        displayType="text"
                        isNumericString
                        thousandSeparator={true}
                      />
                    }
                  />

                  {!feesData.fee ? null : (
                    <ItemsDetails
                      className="mt-1"
                      title="Amount"
                      value={
                        <span className="fs-5 text-dark">
                          <CurrencyFormat
                            prefix={getCurrency(currency)}
                            value={Number(
                              feesData.fee / 100 + Number(paymentData.amount),
                            ).toFixed(2)}
                            displayType="text"
                            thousandSeparator={true}
                          />
                        </span>
                      }
                    />
                  )}
                </>
              )}

              {paymentData.vendorAddress && (
                <>
                  <hr style={{ backgroundColor: '#EFF0ED' }} />
                  <ItemsDetails
                    className="mb-1"
                    title={<span className="header__transfer">Account Summary</span>}
                    value={
                      <span
                        className="edit-action__transfer cursor"
                        onClick={payToggleHandler}
                      >
                        Change
                      </span>
                    }
                  />
                  <ItemsDetails
                    title="Company"
                    value={paymentData.recipientName?.label}
                  />
                  <ItemsDetails title="Address" value={paymentData.vendorAddress} />
                </>
              )}

              <hr />
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Recipient details</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Change
                  </span>
                }
              />
              <ItemsDetails
                title="Name"
                value={paymentData.accountName || paymentData?.recipientName?.label}
              />
              <ItemsDetails title="Bank name" value={paymentData.bank.label} />
              <ItemsDetails title="Account number" value={paymentData.accountNumber} />
              {paymentData.schedule && (
                <>
                  <hr />
                  <ItemsDetails
                    className="mb-1"
                    title={<span className="header__transfer">Schedule details</span>}
                    value={
                      <span
                        className="edit-action__transfer cursor"
                        onClick={payToggleHandler}
                      >
                        Edit
                      </span>
                    }
                  />
                  <ItemsDetails
                    title="Date"
                    value={dayjs(paymentData.startDate).format('MMMM DD, YYYY, HH:mm:ss')}
                  />
                  <ItemsDetails title="Repeats" value={paymentData.repeat.details} />
                  {isNotOneTime && (
                    <ItemsDetails
                      title="End date"
                      value={dayjs(paymentData.endDate).format('MMMM DD, YYYY, HH:mm:ss')}
                    />
                  )}
                </>
              )}
              <hr />
              <ItemsDetails
                className="mb-1"
                title={<span className="header__transfer">Other</span>}
                value={
                  <span
                    className="edit-action__transfer cursor"
                    onClick={payToggleHandler}
                  >
                    Edit
                  </span>
                }
              />
              {!isAvailableBalance && (
                <ItemsDetails title="Budget" value={paymentData?.budget?.label} />
              )}
              {isAvailableBalance && (
                <ItemsDetails title="Budget" value={paymentData?.impactBudget?.label} />
              )}
              {isAvailableBalance && (
                <ItemsDetails title="Account" value={paymentData?.budget?.label} />
              )}
              {paymentData?.alternativeSource && (
                <ItemsDetails
                  title="Account"
                  value={paymentData?.alternativeSource?.label}
                />
              )}
              {paymentData?.expenseCategory && (
                <ItemsDetails
                  title="Expense category"
                  value={paymentData?.expenseCategory}
                />
              )}
            </div>
          </div>

          <div className="toggle-email mb-2">
            <div className="d-flex align-items-start gap-2 w-100">
              <Checkbox
                onChange={(event) => onToggleRecipient(event, 'checked')}
                // defaultChecked={!!paymentData.recipientName.email}
                checked={notifyRecipient?.checked}
              ></Checkbox>
              <div className="d-flex flex-column w-100">
                Notify recipient by email
                {notifyRecipient?.checked && (
                  <>
                    {paymentData.recipientName.email ? (
                      <span>{paymentData.recipientName.email}</span>
                    ) : (
                      <Row className="w-100">
                        <CustomInput
                          placeholder="you@example.com"
                          type="email"
                          name="email"
                          onChange={(value) =>
                            setNotifyRecipient({
                              ...notifyRecipient,
                              email: value.target.value,
                            })
                          }
                          // value={authData.email}
                        />
                      </Row>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div>
            <Row className="mb-3">
              <CustomTextarea
                rowSize={3}
                label="Description"
                name="description"
                onChange={handleCharChange}
                value={text || paymentData.description}
                placeholder="Description"
                maxLength={MAX_LENGTH}
                showCounter={true}
                charCount={charCount}
              />
            </Row>

            <Row className="mb-4">
              <FileUpload
                label="Receipts or supporting documents"
                placeholder="Upload certificate (PDF, JPEG, PNG, XLSX)"
                name="file"
                onChange={(value) =>
                  setPaymentData({
                    ...paymentData,
                    receipt: value?.map((item) => item.assetCode),
                  })
                }
                setUploadingFile={setUploadingFile}
                multiple
                acceptedFile={{
                  'text/html': ['.xlsx'],
                  'image/jpeg': ['.jpeg', '.jpg', '.png'],
                  'application/pdf': ['.pdf'],
                }}
              />
            </Row>
          </div>

          <CustomButton onClick={handleSubmit} disabled={uploadingFile} fullWidth={true}>
            Confirm and {paymentData.schedule ? 'schedule' : 'pay'}
          </CustomButton>
          <CustomButton withoutBg fullWidth className="mt-3" onClick={() => setStep(1)}>
            Cancel
          </CustomButton>
        </div>
      </div>
    );
  };

  const PageView = () => {
    if (step > 1) return PreviewCard();
    return budgetCard();
  };

  const msg = paymentData.schedule
    ? 'Your payment has been scheduled and will be processed when due...'
    : 'Your payment is being processed...';
  if (budgetCheckModal)
    return (
      <>
        <Modal show={budgetCheckModal} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title={`You will overspend your budget "${paymentData?.impactBudget?.name}"`}
            subTitle={`Are you sure you want to proceed with the payment?`}
            onConfirm={() => {
              setPayFromBalance(true);
              setBudgetCheckModal(false);
            }}
            onCancel={() => {
              setPayFromBalance(false);
              setBudgetCheckModal(!budgetCheckModal);
            }}
            isDeleteDialog={false}
            actionBtnText="Yes, proceed"
          />
        </Modal>
      </>
    );
  return (
    <>
      <Modal show={payModal} portalClass="bujeti-pay-modal">
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={payToggleHandler}>
              {step === 1 || isLoaded ? (
                <CloseOutlined />
              ) : (
                <LeftIcon className="cursor" onClick={payToggleHandler} />
              )}
              <span className="ps-1">{step === 1 || isLoaded ? 'Close' : 'Back'}</span>
            </div>
          </div>
          <div className="card-modal-body">
            {loading ? (
              <Saving />
            ) : isLoaded ? (
              <Success title="Thank you!" message={msg} />
            ) : (
              PageView()
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BujetiPayModal;
