export const CREATE_BILL_REQUEST = 'CREATE_BILL_REQUEST';
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS';
export const CREATE_BILL_ERROR = 'CREATE_BILL_ERROR';

export const FETCH_BILLS_REQUEST = 'FETCH_BILLS_REQUEST';
export const FETCH_BILLS_SUCCESS = 'FETCH_BILLS_SUCCESS';
export const FETCH_BILLS_ERROR = 'FETCH_BILLS_ERROR';

export const FETCH_SINGLE_BILL_REQUEST = 'FETCH_SINGLE_BILL_REQUEST';
export const FETCH_SINGLE_BILL_SUCCESS = 'FETCH_SINGLE_BILL_SUCCESS';
export const FETCH_SINGLE_BILL_ERROR = 'FETCH_SINGLE_BILL_ERROR';

export const UPDATE_BILL_REQUEST = 'UPDATE_BILL_REQUEST';
export const UPDATE_BILL_SUCCESS = 'UPDATE_BILL_SUCCESS';
export const UPDATE_BILL_ERROR = 'UPDATE_BILL_ERROR';

export const PAY_BILL_REQUEST = 'PAY_BILL_REQUEST';
export const PAY_BILL_SUCCESS = 'PAY_BILL_SUCCESS';
export const PAY_BILL_ERROR = 'PAY_BILL_ERROR';

export const DRAFT_BILLS_REQUEST = 'DRAFT_BILLS_REQUEST';
export const DRAFT_BILLS_SUCCESS = 'DRAFT_BILLS_SUCCESS';
export const DRAFT_BILLS_ERROR = 'DRAFT_BILLS_ERROR';

export const FETCH_SCHEDULED_BILLS_REQUEST = 'FETCH_SCHEDULED_BILLS_REQUEST';
export const FETCH_SCHEDULED_BILLS_SUCCESS = 'FETCH_SCHEDULED_BILLS_SUCCESS';
export const FETCH_SCHEDULED_BILLS_ERROR = 'FETCH_SCHEDULED_BILLS_ERROR';

export const FETCH_SINGLE_SCHEDULED_BILL_REQUEST = 'FETCH_SINGLE_SCHEDULED_BILL_REQUEST';
export const FETCH_SINGLE_SCHEDULED_BILL_SUCCESS = 'FETCH_SINGLE_SCHEDULED_BILL_SUCCESS';
export const FETCH_SINGLE_SCHEDULED_BILL_ERROR = 'FETCH_SINGLE_SCHEDULED_BILL_ERROR';

export const DELETE_BILL_REQUEST = 'DELETE_BILL_REQUEST';
export const DELETE_BILL_SUCCESS = 'DELETE_BILL_SUCCESS';
export const DELETE_BILL_ERROR = 'DELETE_BILL_ERROR';

export const DELETE_SCHEDULED_BILL_REQUEST = 'DELETE_SCHEDULED_BILL_REQUEST';
export const DELETE_SCHEDULED_BILL_SUCCESS = 'DELETE_SCHEDULED_BILL_SUCCESS';
export const DELETE_SCHEDULED_BILL_ERROR = 'DELETE_SCHEDULED_BILL_ERROR';

export const UPDATE_SCHEDULED_BILL_REQUEST = 'UPDATE_SCHEDULED_BILL_REQUEST';
export const UPDATE_SCHEDULED_BILL_SUCCESS = 'UPDATE_SCHEDULED_BILL_SUCCESS';
export const UPDATE_SCHEDULED_BILL_ERROR = 'UPDATE_SCHEDULED_BILL_ERROR';

export const FETCH_ARCHIVED_BILLS_REQUEST = 'FETCH_ARCHIVED_BILLS_REQUEST';
export const FETCH_ARCHIVED_BILLS_SUCCESS = 'FETCH_ARCHIVED_BILLS_SUCCESS';
export const FETCH_ARCHIVED_BILLS_ERROR = 'FETCH_ARCHIVED_BILLS_ERROR';

export const MARK_BILL_AS_PAID_REQUEST = 'MARK_BILL_AS_PAID_REQUEST';
export const MARK_BILL_AS_PAID_SUCCESS = 'MARK_BILL_AS_PAID_SUCCESS';
export const MARK_BILL_AS_PAID_ERROR = 'MARK_BILL_AS_PAID_ERROR';

export const ADD_SINGLE_BILL_DOCUMENT_REQUEST = 'ADD_SINGLE_BILL_DOCUMENT_REQUEST';
export const ADD_SINGLE_BILL_DOCUMENT_SUCCESS = 'ADD_SINGLE_BILL_DOCUMENT_SUCCESS';
export const ADD_SINGLE_BILL_DOCUMENT_ERROR = 'ADD_SINGLE_BILL_DOCUMENT_ERROR';

export const SET_BILL_DATA = 'SET_BILL_DATA';
export const UPDATE_BILL_PAYLOAD = 'UPDATE_BILL_PAYLOAD';

export const RESET_FLAGS_BILL = 'RESET_FLAGS_BILL';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  createBill: { ...block },
  updateBill: { ...block },
  updateScheduledBill: { ...block },
  fetchBills: { ...block },
  draftBills: { ...block },
  payBill: { ...block },
  fetchSingleBill: { ...block },
  fetchScheduledBills: { ...block },
  fetchSingleScheduledBill: { ...block },
  deleteBill: { ...block },
  deleteScheduledBill: { ...block },
  archivedBills: { ...block },
  markBillAsPaid: { ...block },
  addSingleBillDocument: { ...block },
  payload: {
    currency: 'NGN',
    terms: 0,
    reference: '',
    balanceDue: 0,
    dueDate: '',
    products: [
      {
        name: '',
        quantity: 1,
        unitPrice: '',
      },
    ],
  },
};

export const BillReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BILL_REQUEST:
      return {
        ...state,
        createBill: {
          ...state.createBill,
          loading: true,
        },
      };

    case CREATE_BILL_SUCCESS:
      return {
        ...state,
        createBill: {
          ...state.createBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case CREATE_BILL_ERROR:
      return {
        ...state,
        createBill: {
          ...state.createBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case UPDATE_BILL_REQUEST:
      return {
        ...state,
        updateBill: {
          ...state.updateBill,
          loading: true,
        },
      };

    case UPDATE_BILL_SUCCESS:
      return {
        ...state,
        updateBill: {
          ...state.updateBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case UPDATE_BILL_ERROR:
      return {
        ...state,
        updateBill: {
          ...state.updateBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case UPDATE_SCHEDULED_BILL_REQUEST:
      return {
        ...state,
        updateScheduledBill: {
          ...state.updateScheduledBill,
          loading: true,
        },
      };

    case UPDATE_SCHEDULED_BILL_SUCCESS:
      return {
        ...state,
        updateScheduledBill: {
          ...state.updateScheduledBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case UPDATE_SCHEDULED_BILL_ERROR:
      return {
        ...state,
        updateScheduledBill: {
          ...state.updateScheduledBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_BILLS_REQUEST:
      return {
        ...state,
        fetchBills: {
          ...state.fetchBills,
          loading: true,
        },
      };

    case FETCH_BILLS_SUCCESS:
      return {
        ...state,
        fetchBills: {
          ...state.fetchBills,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_BILLS_ERROR:
      return {
        ...state,
        fetchBills: {
          ...state.fetchBills,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_SINGLE_BILL_REQUEST:
      return {
        ...state,
        fetchSingleBill: {
          ...state.fetchSingleBill,
          loading: true,
        },
      };

    case FETCH_SINGLE_BILL_SUCCESS:
      return {
        ...state,
        fetchSingleBill: {
          ...state.fetchSingleBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_SINGLE_BILL_ERROR:
      return {
        ...state,
        fetchSingleBill: {
          ...state.fetchSingleBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_SCHEDULED_BILLS_REQUEST:
      return {
        ...state,
        fetchScheduledBills: {
          ...state.fetchScheduledBills,
          loading: true,
        },
      };

    case FETCH_SCHEDULED_BILLS_SUCCESS:
      return {
        ...state,
        fetchScheduledBills: {
          ...state.fetchScheduledBills,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_SCHEDULED_BILLS_ERROR:
      return {
        ...state,
        fetchScheduledBills: {
          ...state.fetchScheduledBills,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case DRAFT_BILLS_REQUEST:
      return {
        ...state,
        draftBills: {
          ...state.draftBills,
          loading: true,
        },
      };

    case DRAFT_BILLS_SUCCESS:
      return {
        ...state,
        draftBills: {
          ...state.draftBills,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case DRAFT_BILLS_ERROR:
      return {
        ...state,
        draftBills: {
          ...state.draftBills,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case PAY_BILL_REQUEST:
      return {
        ...state,
        payBill: {
          ...state.payBill,
          loading: true,
        },
      };

    case PAY_BILL_SUCCESS:
      return {
        ...state,
        payBill: {
          ...state.payBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case PAY_BILL_ERROR:
      return {
        ...state,
        payBill: {
          ...state.payBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case RESET_FLAGS_BILL:
      if (action.blockType === 'payload') {
        return {
          ...state,
          payload: initialState.payload,
        };
      }
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          data: {},
          ...block,
        },
      };

    case FETCH_SINGLE_SCHEDULED_BILL_REQUEST:
      return {
        ...state,
        fetchSingleScheduledBill: {
          ...state.fetchSingleScheduledBill,
          loading: true,
        },
      };

    case FETCH_SINGLE_SCHEDULED_BILL_SUCCESS:
      return {
        ...state,
        fetchSingleScheduledBill: {
          ...state.fetchSingleScheduledBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_SINGLE_SCHEDULED_BILL_ERROR:
      return {
        ...state,
        fetchSingleScheduledBill: {
          ...state.fetchSingleScheduledBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case DELETE_BILL_REQUEST:
      return {
        ...state,
        deleteBill: {
          ...state.deleteBill,
          loading: true,
        },
      };

    case DELETE_BILL_SUCCESS:
      return {
        ...state,
        deleteBill: {
          ...state.deleteBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case DELETE_BILL_ERROR:
      return {
        ...state,
        deleteBill: {
          ...state.deleteBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case DELETE_SCHEDULED_BILL_REQUEST:
      return {
        ...state,
        deleteScheduledBill: {
          ...state.deleteScheduledBill,
          loading: true,
        },
      };

    case DELETE_SCHEDULED_BILL_SUCCESS:
      return {
        ...state,
        deleteScheduledBill: {
          ...state.deleteScheduledBill,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case DELETE_SCHEDULED_BILL_ERROR:
      return {
        ...state,
        deleteScheduledBill: {
          ...state.deleteScheduledBill,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_ARCHIVED_BILLS_REQUEST:
      return {
        ...state,
        archivedBills: {
          ...state.archivedBills,
          loading: true,
        },
      };

    case FETCH_ARCHIVED_BILLS_SUCCESS:
      return {
        ...state,
        archivedBills: {
          ...state.archivedBills,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_ARCHIVED_BILLS_ERROR:
      return {
        ...state,
        archivedBills: {
          ...state.archivedBills,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case MARK_BILL_AS_PAID_REQUEST:
      return {
        ...state,
        markBillAsPaid: {
          ...state.markBillAsPaid,
          loading: true,
        },
      };

    case MARK_BILL_AS_PAID_SUCCESS:
      return {
        ...state,
        markBillAsPaid: {
          ...state.markBillAsPaid,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case MARK_BILL_AS_PAID_ERROR:
      return {
        ...state,
        markBillAsPaid: {
          ...state.markBillAsPaid,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case UPDATE_BILL_PAYLOAD:
      return {
        ...state,
        payload: {
          ...state.payload,
          [action.payload.key]: action.payload.value,
        },
      };

    case SET_BILL_DATA:
      return {
        ...state,
        payload: action.payload,
      };

    case ADD_SINGLE_BILL_DOCUMENT_REQUEST:
      return {
        ...state,
        addSingleBillDocument: { ...state.addSingleBillDocument, loading: true },
      };

    case ADD_SINGLE_BILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        addSingleBillDocument: {
          ...state.addSingleBillDocument,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case ADD_SINGLE_BILL_DOCUMENT_ERROR:
      return {
        ...state,
        addSingleBillDocument: {
          ...state.addSingleBillDocument,
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
