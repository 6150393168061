import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import BankAccounts from './BankAccounts';
import CompanyProfile from './CompanyProfile';
import UserProfile from './UserProfile';

const Profile = () => {
  const [key, setKey] = useState('profile');
  const location = useLocation();
  const dispatch = useDispatch();
  const { permissions, onboardingStatus } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['org-*', 'org-view'],
  });

  useEffect(() => {
    if (location) {
      setKey(location.state?.type);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getBeneficiaryBank());
  }, []);

  return (
    <div className="Profile-wrapper pt-5 pb-5">
      <Container>
        <Row>
          <Col>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="profile" title="Your profile" tabClassName="new-tab">
                <UserProfile />
              </Tab>
              {canManage && (
                <Tab eventKey="company" title="Company" tabClassName="new-tab">
                  <CompanyProfile />
                </Tab>
              )}

              {!['pending', 'rejected'].includes(onboardingStatus) && (
                <Tab eventKey="bank" title="Bank accounts" tabClassName="new-tab">
                  <BankAccounts />
                </Tab>
              )}

              {/*<Tab eventKey="settings" title="Settings" tabClassName="new-tab">
                <Settings />
              </Tab>*/}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Profile;
