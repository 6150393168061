import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';

import { capitalizeFirstLetter } from 'utils/helper';

import { createInvoiceCustomer } from 'redux/actions/InvoiceAction';

import { Radio } from 'antd';
import { XcloseIcon } from 'assets/icons';
import { toastError } from 'components/UI/toast';
import { fetchCategories } from 'redux/actions/CategoryAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { validateObjectData } from 'utils';
import { fetchAllCustomersAction } from '../../../redux/actions/InvoiceAction';

const AddCustomerModal = ({ isOpen, handleClose }) => {
  if (!isOpen) {
    return <div />;
  }

  const { push } = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    type: 'business',
  });

  const [addTin, setAddTin] = useState({
    checked: false,
    tin: undefined,
  });

  const getCategories = () => {
    const { categories } = {};
    return {
      isLoading: false,
      options: [],
      ...(categories && { value: categories.code, label: categories.name }),
    };
  };

  const [expenseCategories, setExpenseCategories] = useState(getCategories());

  const {
    invoiceCustomer: { loading: savingCustomer, success: createSuccess },
  } = useSelector(({ invoices }) => invoices);

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const {
    fetchCategories: { data: categoryData },
  } = useSelector(({ categories }) => categories);

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const generateCountry = useMemo(() => {
    if (countryData.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label: capitalizeFirstLetter(label?.toLowerCase()),
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  useEffect(() => {
    if (data.country) {
      const countryDetails = generateCountry.find(
        (country) =>
          country.value === data?.country?.value ||
          country.label === data?.country?.label,
      );

      if (countryDetails) {
        dispatch(getState(countryDetails?.value));
      }
    }
  }, [data.country]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  //Everything that has to do with categories
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (categoryData) {
      setExpenseCategories({ ...expenseCategories, options: categoryData?.categories });
    }
  }, [categoryData, data]);

  const isIndividual = useMemo(() => data.type === 'individual', [data.type]);

  //ends here

  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    return setData({ ...data, [name]: rawValue ? rawValue : value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setData({
      ...data,
      internationalFormat,
      phoneNumber: {
        countryCode,
        localFormat,
      },
    });
  };

  const onToggleAddTin = (event, type) => {
    setAddTin({
      ...addTin,
      [type]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    const { name, email, firstName, phoneNumber, lastName, postcode } = data;

    if (isIndividual) {
      if (!firstName) return toastError('Please provide first name');
      if (!lastName) return toastError('Please provide last name');
    }

    if (!isIndividual && !name) return toastError('Please provide company name');
    if (!email) return toastError("Please provide the customer's email");

    const addressData = {
      state: data.state?.label,
      street: data.address,
      city: data.city,
      country: data.country?.label,
      countryIso: data.country?.value,
    };

    const isAnyFieldFilled = Object.values(addressData).some((value) => Boolean(value));

    const { isValid, message } = validateObjectData(addressData);

    if (!isValid && isAnyFieldFilled) {
      // Display Toastify message
      return toastError(message);
    }

    const payload = {
      email: data?.email,
      ...(isIndividual
        ? { firstName: data?.firstName, lastName: data?.lastName }
        : { name }),

      customerCode: data?.customerCode,
      taxIdentificationNumber: addTin.tin ? addTin.tin : undefined,
      address: { ...addressData, postalCode: postcode ? postcode : undefined },
      type: data?.type,
    };

    if (phoneNumber?.localFormat) {
      payload.phoneNumber = phoneNumber;
    }

    dispatch(createInvoiceCustomer(payload));
  };

  useEffect(() => {
    if (!savingCustomer && createSuccess) {
      dispatch(fetchAllCustomersAction());
      dispatch({ type: 'RESET_FLAGS_INVOICE', blockType: 'invoiceCustomer' });
      handleClose();

      const isCreate = location.pathname.includes('create');
      if (!isCreate) push('/receivables/customers');
    }
  }, [savingCustomer, createSuccess]);

  const customerForm = () => {
    return (
      <div className="add-customers information-wrapper">
        <div className="customer-modal-header w-100">
          <section>
            <h5>Create new customer</h5>
            <span>
              Add customer details once, and we&apos;ll automatically fill them in for all
              future documents.
            </span>
          </section>

          <span className="ms-auto" style={{ cursor: 'pointer' }} onClick={handleClose}>
            <XcloseIcon stroke="#79716B" width="20" height="20" />
          </span>
        </div>

        <div className="customer-form-holder my-4">
          <span className="label">Customer type</span>
          <Radio.Group value={data.type} name="type" onChange={handleChange}>
            <Radio value="business">Business</Radio>
            <Radio value="individual">Individual</Radio>
          </Radio.Group>

          <section className="mt-4">
            <div className={`form-group mb-3  ${isIndividual ? 'grid-col-2' : ''}`}>
              <>
                {!isIndividual ? (
                  <CustomInput
                    label="Company name"
                    name="name"
                    type="text"
                    placeholder="Bujeti Ltd."
                    onChange={handleChange}
                    value={data.name}
                  />
                ) : (
                  <>
                    <CustomInput
                      label="First name"
                      name="firstName"
                      type="text"
                      placeholder="Berenger"
                      onChange={handleChange}
                      value={data.firstName}
                    />

                    <CustomInput
                      label="Last name"
                      name="lastName"
                      type="text"
                      placeholder="Zantangni"
                      onChange={handleChange}
                      value={data.lastName}
                    />
                  </>
                )}
              </>
            </div>

            <div className="grid-col-2  mb-3">
              <div className="form-group">
                <CustomInput
                  label="Email address"
                  name="email"
                  type="email"
                  placeholder="contact@bujeti.com"
                  onChange={handleChange}
                  value={data.email}
                />
              </div>

              <div className="customer-Phone__input-holder">
                <label className="form-label">Phone number</label>

                <CustomPhoneNumberInput
                  wrapperClass="customer-Phone__input"
                  placeholder="07066560656"
                  onChange={(localFormat, international, countryCode) =>
                    handlePhoneNumberChange(localFormat, international, countryCode)
                  }
                  value={
                    data?.internationalFormat === null
                      ? ''
                      : data?.internationalFormat ||
                        data?.phoneNumber?.internationalFormat
                  }
                />
              </div>
            </div>

            <div className="mb-3 form-group">
              <CustomInput
                label="Address"
                placeholder="Plot 3 Osolo Way"
                name="address"
                value={data?.address}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3 form-group custom">
              <CustomSelect
                classNamePrefix="invoice-select"
                placeholder="Select a country"
                type="text"
                name="country"
                value={data.country}
                label="Country"
                options={generateCountry}
                onChange={(value) => {
                  handleChange({ target: { name: 'country', value } });
                  getCountryStates(value);
                }}
              />
            </div>

            <div className="grid-col-2 mb-3">
              <div className="form-group custom">
                <CustomSelect
                  classNamePrefix="invoice-select"
                  placeholder="Select a state"
                  type="text"
                  name="state"
                  value={data.state}
                  label="State"
                  isLoading={loadingState}
                  isDisabled={loadingState}
                  options={generateState}
                  onChange={(value) => handleChange({ target: { name: 'state', value } })}
                />
              </div>

              <div className="form-group">
                <CustomInput
                  classNamePrefix="invoice-select"
                  label="City"
                  placeholder="Ikeja"
                  name="city"
                  value={data?.city}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="mb-3 form-group">
              <CustomInput
                label="Postcode"
                name="postcode"
                type="text"
                placeholder="100001"
                onChange={handleChange}
                value={data.postcode}
                maxLength="20"
              />
            </div>

            {!isIndividual && (
              <div className="form-group">
                <label className="form-label">
                  Tax Identification Number <span>(Optional)</span>{' '}
                </label>
                <CustomInput
                  placeholder="123456789"
                  type="text"
                  name="tin"
                  onChange={(value) =>
                    setAddTin({
                      ...addTin,
                      tin: value.target.value,
                    })
                  }
                />
              </div>
            )}
          </section>
        </div>

        <div className="customer-modal_footer">
          <CustomButton
            onClick={handleClose}
            disabled={savingCustomer}
            className="custom-button ghost-button"
          >
            Cancel
          </CustomButton>
          <CustomButton
            loading={savingCustomer}
            disabled={savingCustomer}
            className="custom-button primary-button"
            onClick={handleSubmit}
          >
            Create customer
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mandate-modal-overlay" onClick={handleClose} />

      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body invoice">{customerForm()}</div>
      </div>
    </>
  );
};

export default AddCustomerModal;
