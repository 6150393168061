export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const OTP_REQUEST = 'OTP_REQUEST';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_ERROR = 'OTP_ERROR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR';

export const RESEND_VERIFICATION_CODE_REQUEST = 'RESEND_VERIFICATION_CODE_REQUEST';
export const RESEND_VERIFICATION_CODE_SUCCESS = 'RESEND_VERIFICATION_CODE_SUCCESS';
export const RESEND_VERIFICATION_CODE_ERROR = 'RESEND_VERIFICATION_CODE_ERROR';

export const VERIFY_INVITED_USER_REQUEST = 'VERIFY_INVITED_USER_REQUEST';
export const VERIFY_INVITED_USER_SUCCESS = 'VERIFY_INVITED_USER_SUCCESS';
export const VERIFY_INVITED_USER_ERROR = 'VERIFY_INVITED_USER_ERROR';

export const SET_INVITED_USER_PASSWORD_REQUEST = 'SET_INVITED_USER_PASSWORD_REQUEST';
export const SET_INVITED_USER_PASSWORD_SUCCESS = 'SET_INVITED_USER_PASSWORD_SUCCESS';
export const SET_INVITED_USER_PASSWORD_ERROR = 'SET_INVITED_USER_PASSWORD_ERROR';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR';

export const SET_BENEFICIARY_PASSWORD_REQUEST = 'SET_BENEFICIARY_PASSWORD_REQUEST';
export const SET_BENEFICIARY_PASSWORD_SUCCESS = 'SET_BENEFICIARY_PASSWORD_SUCCESS';
export const SET_BENEFICIARY_PASSWORD_ERROR = 'SET_BENEFICIARY_PASSWORD_ERROR';

export const LOGOUT = 'LOGOUT';
export const RESET_STATE = 'RESET_STATE';

export const RESET_BLOCK_AUTH = 'RESET_BLOCK_AUTH';

export const RESET_FLAGS_AUTH = 'RESET_FLAGS_AUTH';
import storage from 'redux-persist/es/storage';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  signup: { ...block },
  login: { ...block },
  logout: { ...block },
  userProfile: { ...block },
  loginUser: { ...block },
  user: { ...block },
  otp: { ...block },
  resetPassword: { ...block },
  newPassword: { ...block },
  updateUserPassword: { ...block },
  updatedUser: { ...block },
  verifyInvitedUser: { ...block },
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return { ...state, signup: { ...state.signup, loading: true } };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signup: { ...state.signup, loading: false, success: true, error: '' },
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        signup: { ...state.signup, loading: false, error: action.error },
      };

    case LOGIN_REQUEST:
      return { ...state, login: { ...state.login, loading: true } };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: true,
          error: '',
        },
        user: action.data,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        login: { ...state.login, loading: false, error: action.error },
      };

    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPassword: { ...state.resetPassword, loading: true } };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: { ...state.resetPassword, loading: false, error: action.error },
      };

    case NEW_PASSWORD_REQUEST:
      return { ...state, newPassword: { ...state.newPassword, loading: true } };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case NEW_PASSWORD_ERROR:
      return {
        ...state,
        newPassword: { ...state.newPassword, loading: false, error: action.error },
      };

    case UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        updateUserPassword: { ...state.updateUserPassword, loading: true },
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updateUserPassword: {
          ...state.updateUserPassword,
          loading: false,
          success: true,
          error: '',
        },
      };
    case UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        updateUserPassword: {
          ...state.updateUserPassword,
          loading: false,
          error: action.error,
        },
      };

    case GET_USER_PROFILE_REQUEST:
      return { ...state, userProfile: { ...state.userProfile, loading: true } };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loading: false,
          success: true,
          error: '',
        },
        user: action.data,
      };
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfile: { ...state.userProfile, loading: false, error: action.error },
      };

    case OTP_REQUEST:
      return { ...state, otp: { ...state.otp, loading: true } };
    case OTP_SUCCESS:
      return {
        ...state,
        otp: {
          ...state.otp,
          loading: false,
          success: true,
          error: '',
        },
        loginUser: { data: action.data },
        user: { data: { role: action.data.role, user: action.data.user } },
      };
    case OTP_ERROR:
      return {
        ...state,
        otp: { ...state.otp, loading: false, error: action.error },
      };

    case UPDATE_USER_PROFILE_REQUEST:
      return { ...state, updatedUser: { ...state.updatedUser, loading: true } };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updatedUser: {
          ...state.updatedUser,
          loading: false,
          success: true,
          error: '',
        },
      };
    case UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updatedUser: { ...state.updatedUser, loading: false, error: action.error },
      };

    case SET_BENEFICIARY_PASSWORD_REQUEST:
      return { ...state, login: { ...state.login, loading: true } };
    case SET_BENEFICIARY_PASSWORD_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: true,
          error: '',
        },
        loginUser: { data: action.data },
        user: { data: { role: action.data.role, user: action.data.user } },
      };
    case SET_BENEFICIARY_PASSWORD_ERROR:
      return {
        ...state,
        login: { ...state.login, loading: false, error: action.error },
      };

    //Demo
    case VERIFY_INVITED_USER_REQUEST:
      return {
        ...state,
        verifyInvitedUser: { ...state.verifyInvitedUser, loading: true },
      };
    case VERIFY_INVITED_USER_SUCCESS:
      return {
        ...state,
        verifyInvitedUser: {
          ...state.verifyInvitedUser,
          loading: false,
          success: true,
          error: '',
        },
      };
    case VERIFY_INVITED_USER_ERROR:
      return {
        ...state,
        verifyInvitedUser: {
          ...state.verifyInvitedUser,
          loading: false,
          error: action.error,
        },
      };

    case SET_INVITED_USER_PASSWORD_REQUEST:
      return {
        ...state,
        login: { ...state.login, loading: true },
      };
    case SET_INVITED_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          success: true,
          error: '',
        },
      };
    case SET_INVITED_USER_PASSWORD_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: action.error,
        },
      };

    //
    case LOGOUT:
      const authRecall = localStorage.getItem('auth-recall');
      const expAuthRecall = localStorage.getItem('exp-auth-recall');
      const redirectPath = localStorage.getItem('redirectPath');
      const lastLogin = localStorage.getItem('lastLogin');
      localStorage.clear();
      if (action?.sessionExpired) {
        localStorage.setItem('auth-recall', authRecall);
        localStorage.setItem('exp-auth-recall', expAuthRecall);
      }
      localStorage.setItem('redirectPath', redirectPath);
      localStorage.setItem('lastLogin', lastLogin);
      storage.removeItem('persist:root');
      return {
        ...initialState,
        logout: {
          success: true,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_AUTH:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_AUTH:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
