import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { flattenCategories } from 'utils/helper';

const useExpenseCategories = (setState, state, value, type) => {
  const dispatch = useDispatch();
  const {
    fetchCategories: { data: categoryData, loading: isCatLoading, success: isCatSuccess },
    deleteCategories: { success: successDelete, loading: loadingDeleteCategory },
  } = useSelector(({ categories }) => categories);

  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    code: '',
    name: '',
  });

  const [expenseCategories, setExpenseCategories] = useState({
    isLoading: false,
    options: [],
    value: '',
  });

  useEffect(() => {
    if (!categoryData?.categories?.length) dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (successDelete) setShowCategoryDeleteModal(false);
  }, [successDelete]);

  useEffect(() => {
    if (categoryData?.categories) {
      const data = flattenCategories(categoryData?.categories);
      setExpenseCategories({ ...expenseCategories, options: data });
    }
  }, [categoryData?.categories, state]);

  const onHandleGetSelected = (code, name) => {
    setExpenseCategories({ ...expenseCategories, value: code });
    setState((prevState) => ({ ...prevState, [value]: name }));
  };

  const onOpenDeleteModal = (name, code) => {
    setSelectedCategory({
      code,
      name,
    });
    setShowCategoryDeleteModal(!showCategoryDeleteModal);
  };

  const onHandleDeleteCategory = () => {
    dispatch(deleteCategories(selectedCategory.code));
  };

  const resetCategoryValue = () => {
    setExpenseCategories({
      ...expenseCategories,
      value: '',
    });
    setState((prevState) => ({ ...prevState, [value]: '' }));
  };

  return {
    showCategoryDeleteModal,
    selectedCategory,
    expenseCategories,
    onHandleGetSelected,
    onOpenDeleteModal,
    onHandleDeleteCategory,
    loadingDeleteCategory,
    setExpenseCategories,
    resetCategoryValue,
  };
};

export default useExpenseCategories;
