import { CloseOutlined } from '@ant-design/icons';
import { NotificationAlert, NotificationInfo } from 'assets/icons';
import classNames from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import Modal from 'components/UI/Modal';
import { toastError, toastSuccess } from 'components/UI/toast';
import { formatDistance, subDays } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import {
  getNotification,
  markNotificationAsRead,
} from 'redux/actions/NotificationsAction';
import { declineReimbursements } from 'redux/actions/ReimbursementsAction';
import { toggleNotificationAction } from 'redux/actions/ToggleAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import './styles.scss';

const NotificationsModal = ({ toggleNotification }) => {
  const [key, setKey] = useState('all');
  const { push } = useHistory();
  const [page, setPage] = useState(1);
  const [isPaginated, setIsPaginated] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [active, setActive] = useState(false);
  const [note, setNote] = useState('');
  const showForm = (index) => {
    setActive(index);
  };
  const [selectedApprovalNotification, setSelectedApprovalNotification] = useState('');

  const {
    declineReimbursement: { loading: isDeclineLoading, success: isDeclineSuccess },
    approveReimbursement: { success: successApprove },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    reviewRequest: { loading: reviewRequestLoading, success: reviewRequestSuccess },
  } = useSelector(({ approval }) => approval);

  const {
    markNotificationAsRead: { loading: markLoading, success },
    markAllNotificationAsRead: { loading: markAllLoading, success: markAllSuccess },
    getNotification: { data, loading },
  } = useSelector(({ notifications }) => notifications);

  const { permissions } = allPermissions();

  const canEditRequest = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-edit'],
  });

  const onHandleRejection = (code, entityType) => {
    const data = { code, note };
    if (!note.trim()) return toastError('Please enter a reason');

    if (entityType === 'ApprovalRequest') {
      dispatch(reviewRequest({ code, status: 'declined', reason: note }));
    } else {
      dispatch(declineReimbursements(data));
    }
    setActive(false);
  };

  const onHandleApproval = (code, entityType, ntfCode) => {
    onHandleMark(ntfCode);
    setSelectedApprovalNotification(ntfCode);
    if (entityType === 'ApprovalRequest') {
      push(`/transactions/requests/${code}/details`);
      dispatch(toggleNotificationAction());
      // dispatch(reviewRequest({ code, status: 'approved' }));
    } else {
      // dispatch(approveReimbursements(code));
    }
    return null;
  };

  useEffect(() => {
    if (isDeclineSuccess) {
      toastSuccess('Reimbursement declined successfully');
      dispatch(getNotification({ page: 1 }));
      setPage(1);
    }
  }, [isDeclineSuccess]);

  useEffect(() => {
    if (successApprove) {
      toastSuccess('Reimbursement approved successfully');
      dispatch(getNotification({ page: 1 }));
      setPage(1);
    }
  }, [successApprove]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    // if (tabKey != 'payments') {
    //   history.push(`/transactions/${tabKey}`);
    // } else {
    //   history.push(`/transactions/payments`);
    // }
  };

  useEffect(() => {
    dispatch(getNotification({ page }));
  }, [page]);

  const handleClose = () => {
    dispatch(toggleNotificationAction());
  };

  const handlePagination = () => {
    setPage((prev) => prev + 1);
    setIsPaginated(true);
  };

  const fetchMoreData = () => {
    setTimeout(() => {
      if (!loading) {
        handlePagination();
      }
    }, 1500);
  };

  useEffect(() => {
    if (success) {
      setNotificationList((prevMessages) =>
        prevMessages.map((item) => {
          if (item?.code === selectedApprovalNotification) {
            return {
              ...item,
              seen: true,
            };
          }
          return item;
        }),
      );
    }
  }, [success]);

  useEffect(() => {
    if (reviewRequestSuccess) {
      setNotificationList((prevMessages) =>
        prevMessages.map((item) => {
          if (item?.code === selectedApprovalNotification) {
            return {
              ...item,
              badge: 'info',
              type: 'info',
            };
          }
          return item;
        }),
      );
    }
  }, [reviewRequestSuccess]);

  const onHandleMark = (code) => {
    setSelectedApprovalNotification(code);
    dispatch(markNotificationAsRead(code));
  };

  const handleShowDecline = (index, ntfCode) => {
    showForm(index);
    onHandleMark(ntfCode);
    setSelectedApprovalNotification(ntfCode);
  };

  useEffect(() => {
    if (data?.notifications?.length) {
      setNotificationList((prevData) => {
        const newData = [...prevData, ...data?.notifications];
        const uniqueData = Array.from(
          new Set(newData.map((item) => JSON.stringify(item))),
        ).map((item) => JSON.parse(item));
        return page === 1 ? data?.notifications : newData;
      });
    }
  }, [data?.notifications]);

  const hanldeViewMore = (entity, ntfCode) => {
    handleClose();
    onHandleMark(ntfCode);
    const code = entity.substring(0, 4);

    const routeTo = {
      ['rbs_']: `/requests/reimbursements/${entity}`,
      ['frq_']: `/requests/funds/${entity}`,
      ['inv_']: `/receivables/invoices/${entity}/details`,
      ['trx_']: `/transactions/payments/${entity}/details`,
      ['apr_']: `/transactions/requests/${entity}/details`,
    };

    push(routeTo[code]);
  };

  const getBadges = (badge, title) => {
    const initials = title.substring(0, 2);
    const list = {
      info: <NotificationInfo className="warning-icon" />,
      alert: <NotificationAlert className="warning-icon" />,
      request: <div className="user-initial">{initials}</div>,
    };

    return list[badge];
  };
  const PageView = () => {
    return (
      <div className="align-border d-flex flex-column">
        <Tabs
          id="contolled-tab-example"
          activeKey={key}
          onSelect={handleSelect}
          className="overflow-x-scroll flex-nowrap nowrap"
        >
          <Tab
            eventKey="all"
            title={
              <div className="d-flex">
                All
                <div className="count">
                  <span className="m-auto">{data?.meta?.total || 0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            {!!data?.notifications.length && (
              <div
                className="h-100-custom overflow-y-scroll position-relative"
                ref={scrollRef}
              >
                <InfiniteScroll
                  className="notice-container"
                  loadMore={fetchMoreData}
                  pageStart={0}
                  hasMore={data?.meta?.hasMore}
                  useWindow={false}
                  getScrollParent={() => scrollRef.current}
                  loader={
                    <div
                      key={0}
                      className="d-flex mt-3 h-10 w-100 align-items-center justify-content-center"
                    >
                      Loading
                    </div>
                  }
                >
                  {notificationList?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames('border-b py-3', {
                          ['bg-new-info']: !item.seen,
                        })}
                      >
                        <div className="notice-item">
                          <div>{getBadges(item?.badge, item?.title)}</div>
                          <div className="notice-info">
                            <h6 className="Notice-title">{item?.title}</h6>
                            <p className="mt-1 mb-0">{item?.body?.message}</p>

                            <p className="m-0 mt-1 text-xs">
                              {formatDistance(
                                subDays(new Date(item?.created_at), 0),
                                new Date(),
                                {
                                  addSufix: true,
                                },
                              )}{' '}
                              ago
                            </p>

                            {active === index && (
                              <section className="reject-message">
                                <label htmlFor="reason">Enter a reason</label>
                                <input
                                  name="note"
                                  type="text"
                                  placeholder="Enter reason for decline"
                                  onChange={(e) => setNote(e.target.value)}
                                />
                                <div className="action-wrapper">
                                  <CustomButton
                                    type="submit"
                                    className="notification-btn"
                                    onClick={() =>
                                      onHandleRejection(
                                        item?.body?.body?.code,
                                        item?.body?.body?.entity,
                                      )
                                    }
                                    disabled={isDeclineLoading || reviewRequestLoading}
                                    loading={isDeclineLoading || reviewRequestLoading}
                                  >
                                    Send
                                  </CustomButton>
                                  <span onClick={() => showForm(null)}>cancel</span>
                                </div>
                              </section>
                            )}
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <div className="d-flex gap-2 p-0 m-0 ms-auto">
                            {item?.type === 'request' && active !== index && (
                              <CustomButton
                                className="base-button button-dimension-fit-content text-sm font-medium"
                                withoutBg
                                onClick={() => handleShowDecline(index, item?.code)}
                              >
                                Decline
                              </CustomButton>
                            )}

                            {!!item?.body?.body?.entityId && (
                              <CustomButton
                                className="base-button me-2 black-transparent text-sm font-medium dark-button"
                                onClick={() =>
                                  hanldeViewMore(item?.body?.body?.entityId, item?.code)
                                }
                                disabled={markLoading}
                              >
                                View more details
                              </CustomButton>
                            )}

                            {!item?.seen && ['info', 'alert'].includes(item.badge) && (
                              <CustomButton
                                className="base-button me-2 black-transparent text-sm font-medium dark-button"
                                onClick={() => onHandleMark(item?.code)}
                                disabled={markLoading}
                              >
                                Mark as read
                              </CustomButton>
                            )}

                            {item?.type === 'request' && active !== index && (
                              <CustomButton
                                className="base-button w-fit text-sm font-medium dark-button button-dimension-fit-content"
                                onClick={() =>
                                  onHandleApproval(
                                    item?.body?.body?.code,
                                    item?.body?.body?.entity,
                                    item?.code,
                                  )
                                }
                                // disabled={
                                //   item?.code === selectedApprovalNotification
                                //     ? loadingApprove
                                //     : false
                                // }
                                // loading={
                                //   item?.code === selectedApprovalNotification
                                //     ? loadingApprove
                                //     : false
                                // }
                              >
                                Approve
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
          </Tab>
          {/* <Tab
            eventKey="accounts"
            title={
              <div className="d-flex">
                Accounts
                <div className="count">
                  <span className="m-auto">{0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            <div>Accounts</div>
          </Tab>
          <Tab
            eventKey="transactions"
            title={
              <div className="d-flex">
                Transactions
                <div className="count">
                  <span className="m-auto">{0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            <div>Transactions</div>
          </Tab>
          <Tab
            eventKey="approval-rules"
            title={
              <div className="d-flex">
                Approval rules
                <div className="count">
                  <span className="m-auto">{0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            <div>Approval rules</div>
          </Tab>
          <Tab
            eventKey="budgets"
            title={
              <div className="d-flex">
                Budgets
                <div className="count">
                  <span className="m-auto">{0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            <div>Budgets</div>
          </Tab>
          <Tab
            eventKey="vendors"
            title={
              <div className="d-flex">
                Vendors
                <div className="count">
                  <span className="m-auto">{0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            <div>Vendors</div>
          </Tab>
          <Tab
            eventKey="policies"
            title={
              <div className="d-flex">
                Policies
                <div className="count">
                  <span className="m-auto">{0}</span>
                </div>
              </div>
            }
            tabClassName="new-tab"
          >
            <div>Policies</div>
          </Tab> */}
        </Tabs>
      </div>
    );
  };

  return (
    <>
      <Modal show={toggleNotification}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{PageView()}</div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationsModal;
