import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Col, Modal, Row } from 'react-bootstrap';

import ConfirmDialog from 'components/ConfirmDialog';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import useTextCounter from 'hooks/useTextCounter';
import { useDispatch, useSelector } from 'react-redux';
import { createCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { getCurrency } from 'utils/helper';
import { formatNumThousandSeparator } from 'utils/utility';
import './styles.scss';

const CreateSubCategory = () => {
  const initial = {
    description: undefined,
    name: undefined,
    parent: undefined,
    limit: undefined,
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    fetchCategories: { data: { categories = [] } = {}, loading: catLoading },
    createCategories: { success: createSuccess, loading: createLoading },
  } = useSelector(({ categories }) => categories);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryData, setSubcategoryData] = useState({ ...initial });
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(fetchCategories());
    return () => {
      history.replace({ state: {} });
    };
  }, []);

  useEffect(() => {
    if (createSuccess) {
      history.push('/compliances/categories');
    }
  }, [createSuccess]);

  useEffect(() => {
    setCategoryList(
      categories.map(({ code, name, limitLeft, limit }) => ({
        value: name,
        label: name,
        code,
        limitLeft: limitLeft ? limitLeft / 100 : 0,
        limit: (limit ?? 0) / 100,
      })),
    );

    if (location?.state?.parentCategory) {
      const { parentCategory } = location?.state;
      setSubcategoryData((prevValues) => ({
        ...prevValues,
        parent: {
          value: parentCategory?.name,
          label: parentCategory?.name,
          code: parentCategory?.code,
          limitLeft: (parentCategory?.limitLeft ?? 0) / 100,
          limit: (parentCategory?.limit ?? 0) / 100,
        },
      }));
    }
  }, [categories.length, location.state?.parentCategory?.code]);

  const onHandleCancel = () => {
    history.goBack(-1);
  };

  const handleDescriptionChange = (value) => {
    setSubcategoryData({
      ...subCategoryData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    subCategoryData?.description,
    250,
    handleDescriptionChange,
  );

  const handleSubmit = () => {
    const { name, description, parent, limit } = subCategoryData;
    if (!name) return toastError('Please input name');
    if (!parent?.code) return toastError('Please select a parent category');
    if (!!parent?.limit && parent?.limitLeft < limit) {
      setShowConfirmModal(true);
      return;
    }

    handleConfirm(name, description, parent, limit);
  };

  const handleConfirm = (name, description, parent, limit) => {
    const payload = {
      name,
      description: description ?? undefined,
      parent: parent.code,
      limit: limit ? limit * 100 : undefined,
    };

    dispatch(createCategories(payload));
  };

  return (
    <div className="pt-5 pb-3 mx-auto policy-page-body">
      <div className="approval-header">
        <div className="back-click mb-4" onClick={onHandleCancel}>
          <ArrowLeftOutlined />
          Back
        </div>
      </div>

      <div className="information-wrapper create-form form-wrapper__2 w-100 position-relative">
        <form>
          <div className="d-flex flex-sm-wrap align-items-start justify-content-between">
            <div>
              {' '}
              <h6 className="text-lg">Create a sub category</h6>
              <p className="text-sm">Organise and access your sub categories</p>
            </div>
            <div className="d-flex">
              <CustomButton
                onClick={onHandleCancel}
                className="add-button add-action me-3"
                customClass={true}
              >
                Cancel
              </CustomButton>
              <CustomButton
                className="add-button dark-button"
                customClass={true}
                onClick={handleSubmit}
                loading={createLoading}
              >
                Create
              </CustomButton>
            </div>
          </div>
          <div className="divider"></div>
          <Row>
            <CustomSelect
              label="Select parent category"
              name="recipient"
              options={categoryList}
              placeholder={'Select'}
              onChange={(val) => setSubcategoryData({ ...subCategoryData, parent: val })}
              className="mb-3"
              value={subCategoryData?.parent}
              isLoading={catLoading}
              md={8}
            />
            <CustomInput
              label="Sub category name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              className="mb-3"
              id="name"
              onChange={(event) =>
                setSubcategoryData({ ...subCategoryData, name: event.target.value })
              }
              value={subCategoryData?.name}
              md={8}
            />
            <Col className="mb-3" md={8}>
              <CustomTextarea
                label="Description (Optional)"
                name="description"
                placeholder="Enter policy description (e.g. All spend > ₦25,000 requires receipt)"
                value={text || subCategoryData?.description}
                labelClass="text-sm"
                charCount={charCount}
                onChange={handleCharChange}
                rowSize={4}
              />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={8}>
              <CustomInput
                placeholder="0.00"
                type="number"
                label="Sub category limit (optional)"
                isAmount
                otherCurrency={false}
                name="limit"
                onChange={(event) =>
                  setSubcategoryData({ ...subCategoryData, limit: event.target.rawValue })
                }
                value={subCategoryData.limit}
              />
              {!!subCategoryData?.parent?.limitLeft && (
                <span style={{ color: '#d28b28' }} className="text-xs">
                  {getCurrency('NGN')}
                  {formatNumThousandSeparator(subCategoryData?.parent?.limitLeft)} is left
                  of the parent category limit
                </span>
              )}
            </Col>
          </Row>
        </form>

        <div style={{ bottom: 0 }} className="position-absolute w-100">
          <div className="divider"></div>

          <div className="d-flex justify-content-end mt-3">
            <CustomButton
              onClick={onHandleCancel}
              className="add-button add-action me-3"
              customClass={true}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className="add-button dark-button"
              customClass={true}
              onClick={handleSubmit}
              loading={createLoading}
            >
              Create
            </CustomButton>
          </div>
        </div>
      </div>

      <Modal show={showConfirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Create subcategory`}
          subTitle={`Are you sure you want to create this subcategory? This action will update the parent category limit to ${getCurrency(
            'NGN',
          )}${formatNumThousandSeparator(
            subCategoryData?.parent?.limit + Number(subCategoryData?.limit),
          )}.`}
          onConfirm={() => {
            const { name, description, parent, limit } = subCategoryData;
            handleConfirm(name, description, parent, limit);
          }}
          loading={createLoading}
          onCancel={() => setShowConfirmModal(false)}
          isDeleteDialog={false}
        />
      </Modal>
    </div>
  );
};

export default CreateSubCategory;
