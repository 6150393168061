import {
  CREATE_POLICIES_REQUEST,
  ADD_POLICY_DOCUMENT_REQUEST,
  DELETE_POLICY_DOCUMENT_REQUEST,
  DELETE_POLICY_REQUEST,
  EDIT_POLICY_REQUEST,
  FETCH_SINGLE_POLICY_REQUEST,
  GET_POLICIES_REQUEST,
  GET_POLICY_DOCUMENTS_REQUEST,
  GET_POLICY_TYPES_REQUEST,
  GET_SINGLE_BUDGET_POLICIES_REQUEST,
  LINK_BUDGET_POLICY_REQUEST,
  PAUSE_BUDGET_POLICY_REQUEST,
  UNLINK_BUDGET_POLICY_REQUEST,
  ADD_SINGLE_POLICY_DOCUMENT_REQUEST,
  GET_ALL_POLICY_ASSET_REQUEST,
} from 'redux/reducers/PoliciesReducer';

export const createPolicy = (payload) => ({ type: CREATE_POLICIES_REQUEST, payload });
export const addPolicyDocument = (payload) => ({
  type: ADD_POLICY_DOCUMENT_REQUEST,
  payload,
});
export const addSinglePolicyDocument = (payload) => ({
  type: ADD_SINGLE_POLICY_DOCUMENT_REQUEST,
  payload,
});
export const getPolicyTypes = () => ({ type: GET_POLICY_TYPES_REQUEST });
export const getPolicyDocuments = () => ({ type: GET_POLICY_DOCUMENTS_REQUEST });
export const getPolicies = (payload) => ({ type: GET_POLICIES_REQUEST, payload });
export const getSingleBudgetPolicies = (payload) => ({
  type: GET_SINGLE_BUDGET_POLICIES_REQUEST,
  payload,
});
export const fetchSinglePolicy = (payload) => ({
  type: FETCH_SINGLE_POLICY_REQUEST,
  payload,
});
export const getAllPolicyAsset = (payload) => ({
  type: GET_ALL_POLICY_ASSET_REQUEST,
  payload,
});
export const editPolicy = (payload) => ({ type: EDIT_POLICY_REQUEST, payload });
export const pauseSingleBudget = (payload) => ({
  type: PAUSE_BUDGET_POLICY_REQUEST,
  payload,
});
export const deletePolicy = (payload) => ({ type: DELETE_POLICY_REQUEST, payload });
export const deletePolicyDocument = (payload) => ({
  type: DELETE_POLICY_DOCUMENT_REQUEST,
  payload,
});
export const linkBudgetPolicy = (payload) => ({
  type: LINK_BUDGET_POLICY_REQUEST,
  payload,
});
export const unlinkBudgetPolicy = (payload) => ({
  type: UNLINK_BUDGET_POLICY_REQUEST,
  payload,
});
