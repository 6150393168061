export const FETCH_CATEGORY_STATS_REQUEST = 'FETCH_CATEGORY_STATS_REQUEST';
export const FETCH_CATEGORY_STATS_SUCCESS = 'FETCH_CATEGORY_STATS_SUCCESS';
export const FETCH_CATEGORY_STATS_ERROR = 'FETCH_CATEGORY_STATS_ERROR';

export const FETCH_BENEFICIARY_STATS_REQUEST = 'FETCH_BENEFICIARY_STATS_REQUEST';
export const FETCH_BENEFICIARY_STATS_SUCCESS = 'FETCH_BENEFICIARY_STATS_SUCCESS';
export const FETCH_BENEFICIARY_STATS_ERROR = 'FETCH_BENEFICIARY_STATS_ERROR';

export const FETCH_VENDOR_STATS_REQUEST = 'FETCH_VENDOR_STATS_REQUEST';
export const FETCH_VENDOR_STATS_SUCCESS = 'FETCH_VENDOR_STATS_SUCCESS';
export const FETCH_VENDOR_STATS_ERROR = 'FETCH_VENDOR_STATS_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getCategoryStats: { ...block },
  getBeneficiaryStats: { ...block },
  getVendorStats: { ...block },
};

export const AnaylyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_STATS_REQUEST:
      return {
        ...state,
        getCategoryStats: {
          ...state.getCategoryStats,
          loading: true,
        },
      };
    case FETCH_CATEGORY_STATS_SUCCESS:
      return {
        ...state,
        getCategoryStats: {
          ...state.getCategoryStats,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_CATEGORY_STATS_ERROR:
      return {
        ...state,
        getCategoryStats: {
          ...state.getCategoryStats,
          success: false,
          error: action.error,
        },
      };

    case FETCH_BENEFICIARY_STATS_REQUEST:
      return {
        ...state,
        getBeneficiaryStats: {
          ...state.getBeneficiaryStats,
          loading: true,
        },
      };
    case FETCH_BENEFICIARY_STATS_SUCCESS:
      return {
        ...state,
        getBeneficiaryStats: {
          ...state.getBeneficiaryStats,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_BENEFICIARY_STATS_ERROR:
      return {
        ...state,
        getBeneficiaryStats: {
          ...state.getBeneficiaryStats,
          success: false,
          error: action.error,
        },
      };

    case FETCH_VENDOR_STATS_REQUEST:
      return {
        ...state,
        getVendorStats: {
          ...state.getVendorStats,
          loading: true,
        },
      };
    case FETCH_VENDOR_STATS_SUCCESS:
      return {
        ...state,
        getVendorStats: {
          ...state.getVendorStats,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case FETCH_VENDOR_STATS_ERROR:
      return {
        ...state,
        getVendorStats: {
          ...state.getVendorStats,
          success: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
