import {
  CANCEL_SPEND_REQUEST,
  DELETE_SPEND_REQUEST,
  GET_SPENDS_REQUEST,
  GET_SPEND_RECEIPTS_REQUEST,
  GET_SPEND_REQUEST,
  GET_SPEND_TRANSACTIONS_REQUEST,
  SPEND_STATISTICS_REQUEST,
} from '../reducers/SpendReducer';

export const getSpend = (payload = {}) => ({ type: GET_SPEND_REQUEST, payload });
export const getSpends = (query = {}) => ({ type: GET_SPENDS_REQUEST, query });
export const getSpendTransactions = (payload) => ({
  type: GET_SPEND_TRANSACTIONS_REQUEST,
  payload,
});
export const getSpendReceipts = (payload) => ({
  type: GET_SPEND_RECEIPTS_REQUEST,
  payload,
});

export const cancelSubscription = (payload) => ({
  type: CANCEL_SPEND_REQUEST,
  payload,
});

export const deleteSubscription = (payload) => ({
  type: DELETE_SPEND_REQUEST,
  payload,
});

export const getSpendStatistics = (payload) => ({
  type: SPEND_STATISTICS_REQUEST,
  payload,
});
