import {
  GET_VENDOR_APPROVERS_REQUEST,
  GET_VENDOR_PROFILE_REQUEST,
} from 'redux/reducers/VendorProfileReducer';

export const getVendorProfile = (payload) => ({
  type: GET_VENDOR_PROFILE_REQUEST,
  payload,
});

export const getVendorApprovers = (payload) => ({
  type: GET_VENDOR_APPROVERS_REQUEST,
  payload,
});
