import { Axios } from 'api/axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import {
  VERIFY_BVN_REQUEST,
  VERIFY_BVN_SUCCESS,
  VERIFY_BVN_ERROR,
  VERIFY_BVN_OTP_REQUEST,
  VERIFY_BVN_OTP_SUCCESS,
  VERIFY_BVN_OTP_ERROR,
} from '../reducers/BvnRequestReducer';
import { toastError } from 'components/UI/toast';

async function verifyBvn({ payload }) {
  const key = payload.redisKey;
  delete payload.redisKey;
  return await Axios.post(`/cards/card-holder/${key}`, payload);
}

function* handleVerifyBvn({ payload }) {
  try {
    const response = yield call(verifyBvn, { payload });
    if (response) {
      yield put({
        type: VERIFY_BVN_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_BVN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function verifyBvnOTP({ payload }) {
  return await Axios.post('/cards/card-holder/otp/verification', payload);
}

function* handleVerifyBvnOTP({ payload }) {
  try {
    const response = yield call(verifyBvnOTP, { payload });
    if (response) {
      yield put({
        type: VERIFY_BVN_OTP_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_BVN_OTP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(VERIFY_BVN_REQUEST, handleVerifyBvn),
  takeLatest(VERIFY_BVN_OTP_REQUEST, handleVerifyBvnOTP),
]);
