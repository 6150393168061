import { Checkbox } from 'antd';
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { toastError } from 'components/UI/toast';
import React, { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from 'redux/actions/AuthAction';
import {
  encryptAndSaveToLocalStorage,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';
import Layout, { OverflowWrapLayout } from '../Layout';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { sliderContents } from '../config';
import { ArrowRight } from 'assets/icons';
import { allPermissions } from 'utils/AllowedTo';

const Login = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    login: { error = {}, success, loading },
  } = useSelector(({ auth }) => auth);

  const { loading: loadingPermission } = allPermissions();

  useEffect(() => {
    const token = retrieveAndDecryptFromLocalStorage('app-session');

    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');
    if (redirect) {
      if (token) {
        history.push(redirect);
      } else {
        retrieveAndDecryptFromLocalStorage('redirectPath', redirect);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (error) {
      if (error === 'Please verify your email/phone number') {
        history.push({
          pathname: '/verification',
          state: { authData: authData.email },
        });
      }
    }
    if (success) {
      history.push({
        pathname: '/verification',
        state: { authData: authData.email, action: 'login' },
      });
    }
  }, [error, success]);

  const dispatch = useDispatch();
  const [authData, setAuthData] = React.useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });

    if (name === 'rememberMe') encryptAndSaveToLocalStorage('exp-auth-recall', !value);
  };

  const rememberMe = retrieveAndDecryptFromLocalStorage('exp-auth-recall') === false;
  const refreshToken = retrieveAndDecryptFromLocalStorage('auth-recall');

  useEffect(() => {
    setAuthData({
      ...authData,
      rememberMe,
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!authData.email) return toastError('Please enter your email');
    if (!authData.password) return toastError('Please enter a password');
    if (authData.rememberMe || rememberMe) {
      if (refreshToken) {
        dispatch(login({ ...authData, rememberMe, refreshToken }));
      } else {
        dispatch(login(authData));
      }
      return;
    }

    delete authData.rememberMe;
    return dispatch(login(authData));
  };

  return (
    <Layout hideSteps slider={sliderContents} isLogin>
      <AuthFormHeader
        title="Sign in to Bujeti"
        subtitle="Welcome back! Please sign in to continue"
      />
      <OverflowWrapLayout>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <CustomInput
              label="Email"
              error="enter valid email"
              placeholder="Enter your work email"
              type="email"
              name="email"
              onChange={handleChange}
              value={authData.email}
              required
            />
          </Row>
          <Row className="mb-3">
            <CustomInput
              label="Password"
              error="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              placeholder="••••••••"
              type="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              value={authData.password}
              onChange={handleChange}
              name="password"
              maxLength={70}
              required
            />
          </Row>

          <div className=" d-flex align-items-center justify-content-between gap-2 my-3 ">
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                onChange={(event) =>
                  handleChange({
                    target: { name: 'rememberMe', value: event.target.checked },
                  })
                }
                id="rememberMe"
                name="rememberMe"
                checked={authData?.rememberMe}
              ></Checkbox>
              <label htmlFor="rememberMe" className="d-flex flex-column text-sm">
                Keep me logged in
              </label>
            </div>

            <h4 className="link-tag-gray">
              <Link to="/reset-request"> Forgot password? </Link>
            </h4>
          </div>
          <CustomButton
            type="submit"
            fullWidth
            loading={loading || (success && loadingPermission)}
            disabled={loading || (success && loadingPermission)}
          >
            Sign in <ArrowRight color="#fff" className="ms-2" />
          </CustomButton>
        </Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default Login;
