import { useEffect, useState } from 'react';

/**
 * A custom hook to handle visibility of segments over time.
 * @param {number} numSegments - Total number of segments to display.
 * @param {number} duration - Duration between each segment appearing (in ms).
 * @returns {number} visibleSegments - The number of currently visible segments.
 */
const useSegmentVisibility = (numSegments, duration = 225) => {
  const [visibleSegments, setVisibleSegments] = useState(0);

  useEffect(() => {
    const intervals = Array.from({ length: numSegments }, (_, i) =>
      setTimeout(() => setVisibleSegments(i + 1), duration * (i + 1)),
    );

    return () => intervals.forEach(clearTimeout);
  }, [numSegments, duration]);

  return visibleSegments;
};

export default useSegmentVisibility;
