import { default as access } from 'assets/icons/nigeria-logos/access_bank/access_bank.svg';
import { default as asobank } from 'assets/icons/nigeria-logos/aso_savings_loans/aso_savings_loans.svg';
import { default as ecobank } from 'assets/icons/nigeria-logos/ecobank/ecobank.svg';
import { default as ekondo_microfinance_bank } from 'assets/icons/nigeria-logos/ekondo_microfinance_bank/ekondo_microfinance_bank.svg';
import { default as fcmb } from 'assets/icons/nigeria-logos/fcmb/fcmb.svg';
import { default as fidelity } from 'assets/icons/nigeria-logos/fidelity_bank/fidelity_bank.svg';
import { default as first_bank } from 'assets/icons/nigeria-logos/first_bank/first_bank.svg';
import { default as flutterwave } from 'assets/icons/nigeria-logos/flutterwave/flutterwave.svg';
import { default as globus_bank } from 'assets/icons/nigeria-logos/others/globus.svg';
import { default as guaranty_trust_bank } from 'assets/icons/nigeria-logos/guaranty_trust_bank/guaranty_trust_bank.svg';
import { default as heritage_bank } from 'assets/icons/nigeria-logos/heritage_bank/heritage_bank.svg';
// import { default as interswitch } from 'assets/icons/nigeria-logos/interswitch/interswitch.svg';
import { default as keystone_bank } from 'assets/icons/nigeria-logos/others/keystone.svg';
import { default as kuda_bank } from 'assets/icons/nigeria-logos/kuda_bank/kuda_bank.svg';
// import { default as mainstreet_bank } from 'assets/icons/nigeria-logos/mainstreet_bank/mainstreet_bank.svg';
// import { default as moniepoint } from 'assets/icons/nigeria-logos/moniepoint/moniepoint.svg';
// import { default as paystack } from 'assets/icons/nigeria-logos/paystack/paystack.svg';
import { default as polaris_bank } from 'assets/icons/nigeria-logos/polaris_bank/polaris_bank.svg';
import { default as providus } from 'assets/icons/nigeria-logos/others/providus.svg';
import { default as stanbic_ibtc } from 'assets/icons/nigeria-logos/others/stanbic.svg';
import { default as standard_chartered } from 'assets/icons/nigeria-logos/others/standard.svg';
import { default as sterling_bank } from 'assets/icons/nigeria-logos/sterling_bank/sterling_bank.svg';
import { default as union_bank } from 'assets/icons/nigeria-logos/union_bank/union_bank.svg';
import { default as united_bank_for_africa } from 'assets/icons/nigeria-logos/united_bank_for_africa/united_bank_for_africa.svg';
import { default as wema_bank } from 'assets/icons/nigeria-logos/wema_bank/wema_bank.svg';
import { default as zenith_bank } from 'assets/icons/nigeria-logos/zenith_bank/zenith_bank.svg';
import { default as fsdh } from 'assets/icons/nigeria-logos/fsdh/fsdh.svg';
import { default as suntrust } from 'assets/icons/nigeria-logos/others/suntrust.svg';
import { default as unity } from 'assets/icons/nigeria-logos/others/unity.svg';
import { default as titan } from 'assets/icons/nigeria-logos/others/titan.svg';

export const Banks = [
  {
    label: 'Access Bank',
    value: '044',
    icon: access,
  },
  {
    label: 'Citi Bank',
    value: '023',
  },
  {
    label: 'EcoBank PLC',
    value: '050',
    icon: ecobank,
  },
  {
    label: 'First Bank PLC',
    value: '011',
    icon: first_bank,
  },
  {
    label: 'First City Monument Bank',
    value: '214',
    icon: fcmb,
  },
  {
    label: 'Fidelity Bank',
    value: '070',
    icon: fidelity,
  },
  {
    label: 'Guaranty Trust Bank',
    value: '058',
    icon: guaranty_trust_bank,
  },
  {
    label: 'Polaris bank',
    value: '076',
    icon: polaris_bank,
  },
  {
    label: 'Stanbic IBTC Bank',
    value: '221',
    icon: stanbic_ibtc,
  },
  {
    label: 'Standard Chaterted bank PLC',
    value: '068',
    icon: standard_chartered,
  },
  {
    label: 'Sterling Bank PLC',
    value: '232',
    icon: sterling_bank,
  },
  {
    label: 'United Bank for Africa',
    value: '033',
    icon: united_bank_for_africa,
  },
  {
    label: 'Union Bank PLC',
    value: '032',
    icon: union_bank,
  },
  {
    label: 'Wema Bank PLC',
    value: '035',
    icon: wema_bank,
  },
  {
    label: 'Zenith bank PLC',
    value: '057',
    icon: zenith_bank,
  },
  {
    label: 'Unity Bank PLC',
    value: '215',
    icon: unity,
  },
  {
    label: 'ProvidusBank PLC',
    value: '101',
    icon: providus,
  },
  {
    label: 'Keystone Bank',
    value: '082',
    icon: keystone_bank,
  },
  {
    label: 'Jaiz Bank',
    value: '301',
  },
  {
    label: 'Heritage Bank',
    value: '030',
    icon: heritage_bank,
  },
  {
    label: 'Suntrust Bank',
    value: '100',
    icon: suntrust,
  },
  {
    label: 'FINATRUST MICROFINANCE BANK',
    value: '608',
  },
  {
    label: 'Rubies Microfinance Bank',
    value: '090175',
  },
  {
    label: 'Kuda',
    value: '090267',
    icon: kuda_bank,
  },
  {
    label: 'TCF MFB',
    value: '090115',
  },
  {
    label: 'FSDH Merchant Bank',
    value: '400001',
    icon: fsdh,
  },
  {
    label: 'Rand merchant Bank',
    value: '502',
  },
  {
    label: 'Globus Bank',
    value: '103',
    icon: globus_bank,
  },
  {
    label: 'Paga',
    value: '327',
  },
  {
    label: 'Taj Bank Limited',
    value: '000026',
  },
  {
    label: 'GoMoney',
    value: '100022',
  },
  {
    label: 'AMJU Unique Microfinance Bank',
    value: '090180',
  },
  {
    label: 'BRIDGEWAY MICROFINANCE BANK',
    value: '090393',
  },
  {
    label: 'Eyowo MFB',
    value: '090328',
  },
  {
    label: 'Mint-Finex MICROFINANCE BANK',
    value: '090281',
  },
  {
    label: 'Covenant Microfinance Bank',
    value: '070006',
  },
  {
    label: 'VFD Micro Finance Bank',
    value: '090110',
  },
  {
    label: 'PatrickGold Microfinance Bank',
    value: '090317',
  },
  {
    label: 'Sparkle',
    value: '090325',
  },
  {
    label: 'Paycom',
    value: '305',
  },
  {
    label: 'NPF MicroFinance Bank',
    value: '070001',
  },
  {
    label: 'PayAttitude Online',
    value: '110001',
  },
  {
    label: 'Intellifin',
    value: '100027',
  },
  {
    label: 'Contec Global Infotech Limited (NowNow)',
    value: '100032',
  },
  {
    label: 'FCMB Easy Account',
    value: '100031',
    icon: fcmb,
  },
  {
    label: 'EcoMobile',
    value: '100030',
  },
  {
    label: 'Innovectives Kesh',
    value: '100029',
  },
  {
    label: 'One Finance',
    value: '100026',
  },
  {
    label: 'Zinternet Nigera Limited',
    value: '100025',
  },
  {
    label: 'TagPay',
    value: '100023',
  },
  {
    label: 'Eartholeum',
    value: '100021',
  },
  {
    label: 'MoneyBox',
    value: '100020',
  },
  {
    label: 'Fidelity Mobile',
    value: '100019',
    icon: fidelity,
  },
  {
    label: 'Enterprise Bank',
    value: '000019',
  },
  {
    label: 'Coronation Merchant Bank',
    value: '060001',
  },
  {
    label: 'FBNQUEST Merchant Bank',
    value: '060002',
  },
  {
    label: 'Nova Merchant Bank',
    value: '060003',
  },
  {
    label: 'Omoluabi savings and loans',
    value: '070007',
  },
  {
    label: 'ASOSavings & Loans',
    value: '090001',
    icon: asobank,
  },
  {
    label: 'Trustbond Mortgage Bank',
    value: '090005',
  },
  {
    label: 'SafeTrust ',
    value: '090006',
  },
  {
    label: 'FBN Mortgages Limited',
    value: '090107',
    icon: first_bank,
  },
  {
    label: 'Imperial Homes Mortgage Bank',
    value: '100024',
  },
  {
    label: 'AG Mortgage Bank',
    value: '100028',
  },
  {
    label: 'Gateway Mortgage Bank',
    value: '070009',
  },
  {
    label: 'Abbey Mortgage Bank',
    value: '070010',
  },
  {
    label: 'Refuge Mortgage Bank',
    value: '070011',
  },
  {
    label: 'Lagos Building Investment Company',
    value: '070012',
  },
  {
    label: 'Platinum Mortgage Bank',
    value: '070013',
  },
  {
    label: 'First Generation Mortgage Bank',
    value: '070014',
  },
  {
    label: 'Brent Mortgage Bank',
    value: '070015',
  },
  {
    label: 'Infinity Trust Mortgage Bank',
    value: '070016',
  },
  {
    label: 'Jubilee-Life Mortgage  Bank',
    value: '090003',
  },
  {
    label: 'Haggai Mortgage Bank Limited',
    value: '070017',
  },
  {
    label: 'New Prudential Bank',
    value: '090108',
  },
  {
    label: 'Fortis Microfinance Bank',
    value: '070002',
  },
  {
    label: 'Page Financials',
    value: '070008',
  },
  {
    label: 'Parralex Microfinance bank',
    value: '090004',
  },
  {
    label: 'Ekondo MFB',
    value: '090097',
    icon: ekondo_microfinance_bank,
  },
  {
    label: 'Seed Capital Microfinance Bank',
    value: '090112',
  },
  {
    label: 'Empire trust MFB',
    value: '090114',
  },
  {
    label: 'AMML MFB',
    value: '090116',
  },
  {
    label: 'Boctrust Microfinance Bank',
    value: '090117',
  },
  {
    label: 'IBILE Microfinance Bank',
    value: '090118',
  },
  {
    label: 'Ohafia Microfinance Bank',
    value: '090119',
  },
  {
    label: 'Wetland Microfinance Bank',
    value: '090120',
  },
  {
    label: 'Hasal Microfinance Bank',
    value: '090121',
  },
  {
    label: 'Gowans Microfinance Bank',
    value: '090122',
  },
  {
    label: 'Verite Microfinance Bank',
    value: '090123',
  },
  {
    label: 'Xslnce Microfinance Bank',
    value: '090124',
  },
  {
    label: 'Regent Microfinance Bank',
    value: '090125',
  },
  {
    label: 'Fidfund Microfinance Bank',
    value: '090126',
  },
  {
    label: 'BC Kash Microfinance Bank',
    value: '090127',
  },
  {
    label: 'Ndiorah Microfinance Bank',
    value: '090128',
  },
  {
    label: 'Money Trust Microfinance Bank',
    value: '090129',
  },
  {
    label: 'Consumer Microfinance Bank',
    value: '090130',
  },
  {
    label: 'Allworkers Microfinance Bank',
    value: '090131',
  },
  {
    label: 'Richway Microfinance Bank',
    value: '090132',
  },
  {
    label: ' AL-Barakah Microfinance Bank',
    value: '090133',
  },
  {
    label: 'Accion Microfinance Bank',
    value: '090134',
  },
  {
    label: 'Personal Trust Microfinance Bank',
    value: '090135',
  },
  {
    label: 'Baobab Microfinance Bank',
    value: '090136',
  },
  {
    label: 'PecanTrust Microfinance Bank',
    value: '090137',
  },
  {
    label: 'Royal Exchange Microfinance Bank',
    value: '090138',
  },
  {
    label: 'Visa Microfinance Bank',
    value: '090139',
  },
  {
    label: 'Sagamu Microfinance Bank',
    value: '090140',
  },
  {
    label: 'Chikum Microfinance Bank',
    value: '090141',
  },
  {
    label: 'Yes Microfinance Bank',
    value: '090142',
  },
  {
    label: 'Apeks Microfinance Bank',
    value: '090143',
  },
  {
    label: 'CIT Microfinance Bank',
    value: '090144',
  },
  {
    label: 'Fullrange Microfinance Bank',
    value: '090145',
  },
  {
    label: 'Trident Microfinance Bank',
    value: '090146',
  },
  {
    label: 'Hackman Microfinance Bank',
    value: '090147',
  },
  {
    label: 'Bowen Microfinance Bank',
    value: '090148',
  },
  {
    label: 'IRL Microfinance Bank',
    value: '090149',
  },
  {
    label: 'Virtue Microfinance Bank',
    value: '090150',
  },
  {
    label: 'Mutual Trust Microfinance Bank',
    value: '090151',
  },
  {
    label: 'Nagarta Microfinance Bank',
    value: '090152',
  },
  {
    label: 'FFS Microfinance Bank',
    value: '090153',
  },
  {
    label: 'CEMCS Microfinance Bank',
    value: '090154',
  },
  {
    label: 'La Fayette Microfinance Bank',
    value: '090155',
  },
  {
    label: 'e-Barcs Microfinance Bank',
    value: '090156',
  },
  {
    label: 'Infinity Microfinance Bank',
    value: '090157',
  },
  {
    label: 'Futo Microfinance Bank',
    value: '090158',
  },
  {
    label: 'Credit Afrique Microfinance Bank',
    value: '090159',
  },
  {
    label: 'Addosser Microfinance Bank',
    value: '090160',
  },
  {
    label: 'Okpoga Microfinance Bank',
    value: '090161',
  },
  {
    label: 'Stanford Microfinance Bak',
    value: '090162',
  },
  {
    label: 'First Royal Microfinance Bank',
    value: '090164',
  },
  {
    label: 'Petra Microfinance Bank',
    value: '090165',
  },
  {
    label: 'Eso-E Microfinance Bank',
    value: '090166',
  },
  {
    label: 'Daylight Microfinance Bank',
    value: '090167',
  },
  {
    label: 'Gashua Microfinance Bank',
    value: '090168',
  },
  {
    label: 'Alpha Kapital Microfinance Bank',
    value: '090169',
  },
  {
    label: 'Mainstreet Microfinance Bank',
    value: '090171',
  },
  {
    label: 'Astrapolaris Microfinance Bank',
    value: '090172',
  },
  {
    label: 'Reliance Microfinance Bank',
    value: '090173',
  },
  {
    label: 'Malachy Microfinance Bank',
    value: '090174',
  },
  {
    label: 'HighStreet Microfinance Bank',
    value: '090175',
  },
  {
    label: 'Bosak Microfinance Bank',
    value: '090176',
  },
  {
    label: 'Lapo Microfinance Bank',
    value: '090177',
  },
  {
    label: 'GreenBank Microfinance Bank',
    value: '090178',
  },
  {
    label: 'FAST Microfinance Bank',
    value: '090179',
  },
  {
    label: 'Baines Credit Microfinance Bank',
    value: '090188',
  },
  {
    label: 'Esan Microfinance Bank',
    value: '090189',
  },
  {
    label: 'Mutual Benefits Microfinance Bank',
    value: '090190',
  },
  {
    label: 'KCMB Microfinance Bank',
    value: '090191',
  },
  {
    label: 'Midland Microfinance Bank',
    value: '090192',
  },
  {
    label: 'Unical Microfinance Bank',
    value: '090193',
  },
  {
    label: 'NIRSAL Microfinance Bank',
    value: '090194',
  },
  {
    label: 'Grooming Microfinance Bank',
    value: '090195',
  },
  {
    label: 'Pennywise Microfinance Bank',
    value: '090196',
  },
  {
    label: 'ABU Microfinance Bank',
    value: '090197',
  },
  {
    label: 'RenMoney Microfinance Bank',
    value: '090198',
  },
  {
    label: 'New Dawn Microfinance Bank',
    value: '090205',
  },
  {
    label: 'UNN MFB',
    value: '090251',
  },
  {
    label: 'Imo State Microfinance Bank',
    value: '090258',
  },
  {
    label: 'Alekun Microfinance Bank',
    value: '090259',
  },
  {
    label: 'Above Only Microfinance Bank',
    value: '090260',
  },
  {
    label: 'Quickfund Microfinance Bank',
    value: '090261',
  },
  {
    label: 'Stellas Microfinance Bank',
    value: '090262',
  },
  {
    label: 'Navy Microfinance Bank',
    value: '090263',
  },
  {
    label: 'Auchi Microfinance Bank',
    value: '090264',
  },
  {
    label: 'Lovonus Microfinance Bank',
    value: '090265',
  },
  {
    label: 'Uniben Microfinance Bank',
    value: '090266',
  },
  {
    label: 'Adeyemi College Staff Microfinance Bank',
    value: '090268',
  },
  {
    label: 'Greenville Microfinance Bank',
    value: '090269',
  },
  {
    label: 'AB Microfinance Bank',
    value: '090270',
  },
  {
    label: 'Lavender Microfinance Bank',
    value: '090271',
  },
  {
    label: 'Olabisi Onabanjo University Microfinance Bank',
    value: '090272',
  },
  {
    label: 'Emeralds Microfinance Bank',
    value: '090273',
  },
  {
    label: 'Trustfund Microfinance Bank',
    value: '090276',
  },
  {
    label: 'Al-Hayat Microfinance Bank',
    value: '090277',
  },
  {
    label: 'FET',
    value: '100001',
  },
  {
    label: 'Moniepoint Microfinance Bank',
    value: '100003',
  },
  {
    label: 'Parkway-ReadyCash',
    value: '100003',
  },
  {
    label: 'Cellulant',
    value: '100005',
  },
  {
    label: 'eTranzact',
    value: '100006',
  },
  {
    label: 'Stanbic IBTC @ease wallet',
    value: '100007',
  },
  {
    label: 'Ecobank Xpress Account',
    value: '100008',
  },
  {
    label: 'GTMobile',
    value: '100009',
  },
  {
    label: 'TeasyMobile',
    value: '100010',
  },
  {
    label: 'Mkudi',
    value: '100011',
  },
  {
    label: 'VTNetworks',
    value: '100012',
  },
  {
    label: 'AccessMobile',
    value: '100013',
  },
  {
    label: 'FBNMobile',
    value: '100014',
  },
  {
    label: 'Kegow',
    value: '100015',
  },
  {
    label: 'FortisMobile',
    value: '100016',
  },
  {
    label: 'Hedonmark',
    value: '100017',
  },
  {
    label: 'ZenithMobile',
    value: '100018',
    icon: zenith_bank,
  },
  {
    label: 'Flutterwave Technology Solutions Limited',
    value: '110002',
    icon: flutterwave,
  },
  {
    label: 'NIP Virtual Bank',
    value: '999999',
  },
  {
    label: 'Titan Trust Bank',
    value: '000025',
    icon: titan,
  },
  {
    label: 'ChamsMobile',
    value: '303',
  },
  {
    label: 'MAUTECH Microfinance Bank',
    value: '090423',
  },
  {
    label: 'Greenwich Merchant Bank',
    value: '060004',
  },
  {
    label: 'Parallex Bank',
    value: '000030',
  },
  {
    label: 'Firmus MFB',
    value: '090366',
  },
  {
    label: 'PALMPAY',
    value: '100033',
  },
  {
    label: 'Manny Microfinance bank',
    value: '090383',
  },
  {
    label: 'Letshego MFB',
    value: '090420',
  },
  {
    label: 'M36',
    value: '100035',
  },
  {
    label: 'Safe Haven MFB',
    value: '090286',
  },
  {
    label: '9 Payment Service Bank',
    value: '120001',
  },
  {
    label: 'Tangerine Bank',
    value: '090426',
  },
  {
    label: 'FEDETH MICROFINANCE BANK',
    value: '090482',
  },
  {
    label: 'Carbon',
    value: '100026',
  },
  {
    label: 'CHANGHAN RTS MICROFINANCE BANK',
    value: '090470',
  },
  {
    label: 'PremiumTrust Bank',
    value: '000031',
  },
  {
    label: 'Links Microfinance Bank',
    value: '090435',
  },
  {
    label: 'TeamApt',
    value: '110007',
  },
  {
    label: 'OPAY',
    value: '100004',
  },
];
