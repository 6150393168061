import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Skeleton } from 'antd';
import { convertNaNToZero, getCurrency } from 'utils/helper';
import CurrencyFormat from 'react-currency-format';
import { getCompanyLogoAsset } from 'redux/actions/CompaniesAction';
import { PreviewTable, AmountInformation, Footer } from '../CustomerInvoice';

import dayjs from 'dayjs';
import LogoIcon from '../../../assets/icons/Vector.png';

const InvoiceAsset = ({ data, loading, isRecurring }) => {
  const dispatch = useDispatch();
  const payments = data?.installments || [];
  const isInstalment = !!payments.length;

  const {
    getCompanyAsset: { data: logoAsset },
    getCompany: { data: companyData = {}, loading: isCompanyLoading },
  } = useSelector(({ companies }) => companies);

  const isLoading = loading || isCompanyLoading;

  useEffect(() => {
    if (companyData?.logo) getCompanyLogo(companyData?.logo);
  }, [companyData]);

  const getCompanyLogo = (logo) => {
    dispatch(getCompanyLogoAsset(logo?.code));
  };

  const handleAmount = (payment) => {
    const { type, percentage, amount } = payment;
    if (type === 'percentage') {
      return (((percentage / 100) * data.amount) / 100).toFixed(2);
    }

    return amount / 100;
  };

  return (
    <>
      {isLoading ? (
        <div>
          <section
            className="invoice-preview detail d-flex flex-column"
            style={{ background: 'inherit', height: 'auto' }}
          >
            <Skeleton.Image size={140} active style={{ width: '100%', height: '90vh' }} />
          </section>
        </div>
      ) : (
        <section className="invoice-preview detail d-flex flex-column" id="html-to-pdf">
          <div
            className="header d-flex align-items-center pb-3"
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            <span style={{ fontSize: '28px', fontWeight: 700, color: '#212529' }}>
              Invoice
            </span>
            <div className="ms-auto">
              <img src={logoAsset?.asset?.url ?? LogoIcon} style={{ width: '40px' }} />
            </div>
          </div>
          <header className="bg-secondary-50 border-12">
            <div className="row">
              <div className="col">
                <div className="row pb-3">
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">Issue Date</h1>
                      <p className="text">
                        {dayjs(data?.created_at).format('DD MMM, YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">Due Date</h1>
                      <p className="text">
                        {data?.due_date
                          ? dayjs(data?.due_date).format('DD MMM, YYYY')
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="row">
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">From</h1>
                      <p className="text">{`${companyData?.name}`}</p>
                    </div>
                    <p className="text">{companyData?.contactEmail}</p>
                    <p className="text">{companyData?.contact_phone}</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="lhs">
                  <div>
                    <h1 className="header-title">Billed to</h1>
                    <p className="header-sub">
                      {data?.customer?.name ||
                        `${data?.customer?.firstName} ${data?.customer?.lastName}`}
                    </p>
                  </div>
                  <p className="text">{data?.customer?.email}</p>
                  <p className="text">{data?.customer?.phoneNumber?.localFormat}</p>
                  {data?.customer?.address?.street && (
                    <p className="text">
                      {data?.customer?.address?.street},{data?.customer?.address?.city},
                      {data?.customer?.address?.country},
                    </p>
                  )}
                  {data?.customer?.phoneNumber?.tin && (
                    <p className="text">TIN: {data?.customer?.phoneNumber?.tin}</p>
                  )}
                </div>
              </div>
            </div>
          </header>

          {/* Balance Region */}
          {isInstalment ? (
            <div className="balance-region">
              <p className="balance">Balance due</p>
              <p className="amount">
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={convertNaNToZero(data?.amountRemaining / 100).toFixed(2)}
                  thousandSeparator={true}
                  displayType="text"
                />
              </p>
            </div>
          ) : (
            <span></span>
          )}

          <main>
            <PreviewTable
              data={data}
              isInstalment={isInstalment}
              isRecurring={isRecurring}
            />
            <section className="d-flex justify-content-end px-3">
              <AmountInformation
                data={data}
                payments={payments}
                handleAmount={handleAmount}
                isRecurring={isRecurring}
              />
            </section>
          </main>
          <section className="invoice-preview-footer mt-auto">
            <Footer data={data} />
          </section>
        </section>
      )}
    </>
  );
};

export default InvoiceAsset;
