import { LaptopIcon, TripleLayers, UploadCloudIcon } from 'assets/icons';
import { ReactComponent as CloudArrowUp } from 'assets/icons/CloudArrowUp.svg';
import pdfFileType from 'assets/images/pdf-filetype-icon.png';
import cs from 'classnames';
import CustomPopover from 'components/CustomPopover';
import format from 'date-fns/format';
import CustomButton from 'pages/Profile/Components/CustomButton';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadProgress as updateProgress,
  uploadFiles,
} from 'redux/actions/AssetsAction';
import { RESET_BLOCK_ASSET } from 'redux/reducers/AssetsReducer';
import { truncateMiddle } from 'utils/helper';
import './styles.scss';
import UploadCardComponent from './UploadCardComponent';
import { getAllPolicyAsset } from 'redux/actions/PoliciesAction';

const FileUpload = ({
  wrapperClass,
  onChange,
  extraCall = null,
  showUploadedFiles = false,
  showUploadForm = true,
  uploadingFile,
  supportType = 'JPEG, PNG, and PDF formats, up to 10 MB.',
  cloud = true,
  isButton = false,
  acceptedFile = {
    'application/pdf': ['.pdf'],
  },
  placement,
  multiple = false,
  isRounded = false,
}) => {
  const dispatch = useDispatch();
  const {
    uploadFiles: { loading },
    uploadProgress,
  } = useSelector(({ assets }) => assets);

  const {
    getPolicyDocuments: { data: documents },
  } = useSelector(({ policies }) => policies);

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [showExistingFiles, setShowExistingFiles] = useState(false);

  const target = useRef(null);

  const addExisitingFile = (existingFile) => {
    const uploadedFilesMaxIndex = Object.keys(uploadProgress).length
      ? Math.max(...Object.keys(uploadProgress)) + 1
      : 0;

    const newExistingFile = {
      [uploadedFilesMaxIndex]: {
        property: uploadedFilesMaxIndex,
        loading: false,
        name: existingFile.name,
        size: existingFile?.size,
        assetCode: existingFile.code,
      },
    };

    dispatch(updateProgress({ progress: newExistingFile }));
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setIsButtonToggle(false);
    if (rejectedFiles && rejectedFiles.length > 0) {
      toastError('File size exceeds 5MB limit');
      return;
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadingFile,
    maxFiles: multiple ? 2 : 1,
    accept: acceptedFile,
    maxSize: 10 * 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_BLOCK_ASSET,
        blockType: 'uploadProgress',
      });
    };
  }, []);

  useEffect(() => {
    const uploadedFilesMaxIndex = Object.keys(uploadProgress).length
      ? Math.max(...Object.keys(uploadProgress)) + 1
      : 0;

    const acceptedFilesModified = {};

    for (let i = 0; i < acceptedFiles.length; i++) {
      acceptedFilesModified[uploadedFilesMaxIndex + i] = {
        property: uploadedFilesMaxIndex + i,
        data: 0,
        loading: true,
        loadedBytes: 0,
        name: acceptedFiles[i].name,
        size: acceptedFiles[i].size,
        type: acceptedFiles[i].type,
      };
    }
    dispatch(updateProgress({ progress: acceptedFilesModified }));

    if (acceptedFiles.length > 0 && cloud) {
      dispatch(
        uploadFiles({
          acceptedFiles,
          uploadedFilesMaxIndex,
          extraCall,
          trackProgress: true,
        }),
      );
    }
  }, [acceptedFiles]);

  const handleMultipleRemove = (index) => {
    const { [index]: _, ...newObj } = uploadProgress;
    dispatch(updateProgress({ progress: newObj, replace: true }));
  };

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const actions2 = (
    <div className="popover-actions-dialog">
      {documents && documents?.length ? (
        documents.map((item) => (
          <div
            key={item.code}
            className="actionLink"
            onClick={() => addExisitingFile(item)}
          >
            <div className="d-flex align-items-start gap-3">
              <img width="30" src={pdfFileType} alt="Document Icon" />
              <div>
                <p style={{ whiteSpace: 'normal' }} className="text-sm mb-1 gray-500">
                  {truncateMiddle(item.name, 33)}
                </p>
                <div className="text-xs">
                  <span>Uploaded {format(new Date(item.created_at), 'd/MM/yyyy')}</span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="noLink">No documents found</div>
      )}
    </div>
  );

  const actions = (
    <CustomPopover
      zIndex="1"
      content={actions2}
      showPopover={showExistingFiles}
      clickOutside={() => setShowExistingFiles(!showExistingFiles)}
    >
      <div className="popover-actions-dialog">
        <div
          className="actionLink"
          onClick={() => setShowExistingFiles(!showExistingFiles)}
        >
          <TripleLayers /> Existing files
        </div>

        <div className="actionLink">
          <div {...getRootProps({ className: 'dropzone w-100' })}>
            <input {...getInputProps()} id={'file'} name={'file'} />
            <LaptopIcon className="me-1" /> Upload from your computer
          </div>
        </div>
      </div>
    </CustomPopover>
  );

  return (
    <div>
      {showUploadForm && (
        <>
          {isRounded ? (
            <div
              className={cs('fileUpload groupWrapper cursor', {
                [wrapperClass]: wrapperClass,
              })}
            >
              <div>
                <CustomPopover
                  zIndex="1"
                  content={actions}
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                  placement={placement}
                >
                  <div className="add-more" onClick={handleButtonToggle}>
                    <div>
                      {/* <input {...getInputProps()} id={name} name={name} /> */}
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 7.3335V16.6668M7.33325 12.0002H16.6666"
                          stroke="#A9A29D"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </CustomPopover>
              </div>
            </div>
          ) : isButton ? (
            <CustomButton className={'dark-outline-button'}>
              <div {...getRootProps({ className: 'dropzone w-100' })}>
                <input {...getInputProps()} id={'file'} name={'file'} />
                <UploadCloudIcon width={'16.67px'} height={'15px'} />
                <span className="ms-1 text-sm">Upload document</span>
              </div>
            </CustomButton>
          ) : (
            <div className="uploadFileBorder policy-fileupload w-100" onClick={null}>
              <div {...getRootProps({ className: 'dropzone w-100' })}>
                <input {...getInputProps()} id={'file'} name={'file'} />
                <div className="uploadFileIcon">
                  <CloudArrowUp />
                </div>
                <div className="uploadFileTextBorder">
                  <span className="uploadFileText1 font-bold">
                    Choose a file or drag & drop it here.
                  </span>
                  <span className="uploadFileText2">{supportType}</span>
                  <CustomButton style={{ order: 2 }} className={'dark-outline-button'}>
                    Browse file
                  </CustomButton>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {!!Object.values(uploadProgress).length &&
        !!showUploadedFiles &&
        Object.values(uploadProgress)
          .slice()
          .reverse()
          .map((item, index) => (
            <UploadCardComponent
              key={item.property}
              item={item}
              truncateLength={50}
              handleViewDocument={() =>
                dispatch(getAllPolicyAsset([{ code: item?.assetCode }]))
              }
              handleRemove={handleMultipleRemove}
            />
          ))}
    </div>
  );
};
export default FileUpload;
