import {
  ArrowDownIcon,
  BankNoteO1Icon,
  CalendarPlusIcon,
  CoinSwap,
  CoinsHand,
  CreditCard02,
  DotsVertical,
  FileCheck,
  LayersTwoIcon,
  PieChart02,
  TransactionsIcon,
  User03,
} from 'assets/icons';
import { SendFundsButton } from 'components/UI/CustomButton';
import ImgCard from 'components/UI/ImgCard';
import CustomPopover from 'components/UI/Popover';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';
import AllowedTo from 'utils/AllowedTo';

const SubHeader = ({ handleSwap, openModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(1);
  const [slideDirection, setSlideDirection] = useState('');
  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const isDisabled = (index) => {
    return activeIndex === index;
  };

  const handleArrowClick = (index) => {
    if (isDisabled(index)) return;

    setSlideDirection('slide-out');

    setTimeout(() => {
      setActiveIndex(index);
      setSlideDirection('slide-in');
    }, 200);
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog overview">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const [showOptions, setShowOptions] = useState(false);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink overview d-flex white align-items-center cursor"
          onClick={() => {
            handleSwap();
            setShowOptions(false);
          }}
        >
          <div className="arrow-icon-wrapper">
            <TransactionsIcon stroke="#7F7F7D" className="first-svg left" />
            <TransactionsIcon stroke="#7F7F7D" className="second-svg left" />
          </div>

          <span>Internal transfer</span>
        </div>

        <div
          className="actionLink overview d-flex white align-items-center cursor"
          onClick={() => {
            openModal();
            setShowOptions(false);
          }}
        >
          <div className="arrow-icon-wrapper">
            <ArrowDownIcon stroke="#7F7F7D" className="first-svg bottom" />
            <ArrowDownIcon stroke="#7F7F7D" className="second-svg bottom" />
          </div>

          <span>Deposit funds</span>
        </div>

        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            setShowOptions(false);
          }}
        >
          <div className="arrow-icon-wrapper">
            <BankNoteO1Icon stroke="#7F7F7D" className="first-svg bottom" />
            <BankNoteO1Icon stroke="#7F7F7D" className="second-svg bottom" />
          </div>

          <span>Single payment</span>
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <div className="arrow-icon-wrapper">
              <LayersTwoIcon stroke="#7F7F7D" className="first-svg bottom" />
              <LayersTwoIcon stroke="#7F7F7D" className="second-svg bottom" />
            </div>

            <span>Batch payments</span>
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            setShowOptions(false);
            isSchedule();
          }}
        >
          <div className="arrow-icon-wrapper">
            <CalendarPlusIcon stroke="#7F7F7D" className="first-svg bottom" />
            <CalendarPlusIcon stroke="#7F7F7D" className="second-svg bottom" />
          </div>

          <span>Schedule payments</span>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex w-100 pt-4 mb-3">
      <div className="d-flex w-100 justify-content-between">
        <div className="overview-top">
          <h1 className="page-title mb-1">Dashboard</h1>
        </div>

        <div className="position-absolute d-flex d-lg-none" style={{ right: 20 }}>
          <CustomPopover
            zIndex="1"
            content={<Actions />}
            showPopover={showOptions}
            clickOutside={() => setShowOptions(false)}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowOptions(true);
              }}
            >
              <DotsVertical />
            </div>
          </CustomPopover>
        </div>
      </div>
      <div className="ms-auto d-lg-flex d-none gap-3 ">
        <div
          className="add-custom add-button overview d-flex white align-items-center cursor"
          onClick={handleSwap}
        >
          <div className="arrow-icon-wrapper">
            <TransactionsIcon stroke="#7F7F7D" className="first-svg left" />
            <TransactionsIcon stroke="#7F7F7D" className="second-svg left" />
          </div>

          <span>Internal transfer</span>
        </div>

        <div
          className="add-custom add-button overview d-flex white align-items-center cursor"
          onClick={openModal}
        >
          <div className="arrow-icon-wrapper">
            <ArrowDownIcon stroke="#7F7F7D" className="first-svg bottom" />
            <ArrowDownIcon stroke="#7F7F7D" className="second-svg bottom" />
          </div>

          <span>Deposit funds</span>
        </div>

        <div className="d-flex">
          <AllowedTo scopes={['transaction-*', 'transaction-create']}>
            <CustomPopover
              zIndex="1"
              id="overview-fund-dropdown"
              content={<PaymentActions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <SendFundsButton onClick={handleButtonToggle} />
            </CustomPopover>
          </AllowedTo>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
