import { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { TrashIcon } from 'assets/icons';
import { FullPageLoader } from 'components/UI/Loading';
import TableGrid from 'components/UI/TableGrid';
import { ActionCellTemplate } from 'components/UI/TableGrid/ActionCellTemplate';
import { AmountCellTemplate } from 'components/UI/TableGrid/AmountCellTemplate';
import { CreateButtonCellTemplate } from 'components/UI/TableGrid/CreateButtonCellTemplate';
import { CustomDropdownTemplate } from 'components/UI/TableGrid/CustomDropdownTemplate';
import { CustomTextCellTemplate } from 'components/UI/TableGrid/CustomTextCellTemplate';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createBulkCategories } from 'redux/actions/CategoryAction';
import { v4 as uuidv4 } from 'uuid';
import './styles.scss';
import { flattenCategories } from 'utils/helper';
import { toastError } from 'components/UI/toast';
import FloatingCta from 'components/BulkAction/FloatingCta';
import { formatNumThousandSeparator } from 'utils/utility';

const CreateMultipleCategories = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    createBulkCategories: { loading, success },
    fetchCategories: { data: { categories: categoriesdata = [], meta = {} } = {} },
  } = useSelector(({ categories }) => categories);

  const defaultRowHeight = 54;

  const cellVerticalPadding = 24;

  const invalidCellStyle = {
    border: {
      left: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
      top: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
      right: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
      bottom: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
    },
  };

  const elementRef = useRef(null);

  const [width, setWidth] = useState(0);
  const [kebabId, setKebabId] = useState('');
  const [changedRowId, setChangedRowId] = useState(null);
  const [noOfCategories, setNoOfCategories] = useState(1);
  const [limitsLeft, setLimitsLeft] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [cellChangesIndex, setCellChangesIndex] = useState(() => -1);
  const [cellChanges, setCellChanges] = useState(() => []);

  useEffect(() => {
    if (success) {
      history.push('/compliances/categories');
    }
  }, [success]);

  const getCategories = () => {
    return [
      {
        categoryName: '',
        description: '',
        limit: '',
        action: '',
        rowId: uuidv4(),
        hasChildren: true,
        expandChildren: false,
      },
      {
        categoryName: 'Add category',
        description: '',
        limit: '',
        action: '',
        colspan: 4,
        isSubRow: false,
        rowId: uuidv4(),
      },
    ];
  };

  const [categories, setCategories] = useState(getCategories());

  const customDropdownTemplateRef = useRef({});
  const customTextTemplateRef = useRef({}); // Create a ref to store the input element reference

  // Function to receive the input reference from CustomCellTemplate
  const handleCustomDropdownRef = (rowId, ref) => {
    customDropdownTemplateRef.current = {
      [rowId]: ref,
      ...customDropdownTemplateRef.current,
    };
  };

  const handleCustomTextRef = (rowId, ref) => {
    customTextTemplateRef.current = { [rowId]: ref, ...customTextTemplateRef.current };
  };

  useEffect(() => {
    if (
      customDropdownTemplateRef.current?.[changedRowId] ||
      customTextTemplateRef.current?.[changedRowId]
    ) {
      setCategories((prevCategories) => {
        let row = prevCategories.find((item) => item.rowId === changedRowId);

        if (!row) return prevCategories;

        //Increase height of row that corresponds to the height of the cell that has the maximum height in that row
        let maxCellHeight = Math.max(
          customDropdownTemplateRef?.current[changedRowId]?.offsetHeight ?? 0,
          customTextTemplateRef?.current[changedRowId]?.offsetHeight ?? 0,
        );
        row['height'] =
          maxCellHeight + cellVerticalPadding <= defaultRowHeight
            ? defaultRowHeight
            : maxCellHeight + cellVerticalPadding;

        return [...prevCategories];
      });
    }
  }, [customDropdownTemplateRef?.current, customTextTemplateRef?.current]);

  useEffect(() => {
    if (elementRef.current) {
      const elementWidth = elementRef.current.getBoundingClientRect().width;
      setWidth(elementWidth);
    }
  }, []);

  const getColumns = () => [
    { columnId: 'categoryName', width: width * (30 / 100) },
    { columnId: 'description', width: width * (45 / 100) },
    { columnId: 'limit', width: width * (20 / 100) },
    { columnId: 'action', width: width * (5 / 100) },
  ];

  const headerRow = {
    rowId: 'header',
    cells: [
      { type: 'header', text: 'Category name*', className: 'rg-cell-custom' },
      { type: 'header', text: 'Description', className: 'rg-cell-custom' },
      { type: 'header', text: 'Limit', className: 'rg-cell-custom' },
      { type: 'header', text: '', className: 'rg-cell-custom' },
    ],
    height: 34,
  };

  const handleTogglePopover = (event, rowId) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (kebabId === rowId) return setKebabId('');

    setKebabId(rowId);
  };

  const handleExpand = (rowId) => {
    setCategories((prevCategories) => {
      let category = prevCategories.find((item) => item.rowId === rowId);
      category.expandChildren = !category.expandChildren;
      category.children = category?.children?.map((item) => ({
        ...item,
        isExpanded: !item.isExpanded,
      }));
      if (category.expandChildren) {
        category.children = [
          ...(category.children || []),
          {
            categoryName: 'Add subcategory',
            description: '',
            limit: '',
            action: '',
            colspan: 4,
            isSubRow: true,
            parentId: rowId,
            rowId: uuidv4(),
          },
        ];
      } else {
        category.children.splice(-1, 1);
      }

      return [...prevCategories];
    });
  };

  const handleCreate = (rowId, parentId) => {
    if (!parentId) {
      setCategories((prevCategories) => {
        let modifiedCategories = prevCategories.slice();

        modifiedCategories.splice(-1, 0, {
          categoryName: '',
          description: '',
          limit: '',
          action: '',
          rowId: uuidv4(),
          hasChildren: true,
          expandChildren: false,
        });

        return modifiedCategories;
      });
      setNoOfCategories((prev) => prev + 1);
    } else {
      setCategories((prevCategories) => {
        let parentCategory = prevCategories.find((opt) => opt.rowId === parentId);
        parentCategory.children.splice(-1, 0, {
          categoryName: '',
          description: '',
          limit: '',
          action: '',
          rowId: uuidv4(),
          parentId: parentId,
          isExpanded: true,
        });

        return [...prevCategories];
      });
    }
  };

  const handleDelete = (rowId, parentId) => {
    setCategories((prevCategories) => {
      if (!parentId) {
        setLimitsLeft((prevValues) => {
          const { [rowId]: _, ...rest } = prevValues;
          return rest;
        });
        return prevCategories.filter((opt) => opt.rowId !== rowId);
      } else {
        let parentCategory = prevCategories.find((opt) => opt.rowId === parentId);
        let subCategory;
        parentCategory.children = parentCategory.children.filter((opt) => {
          if (opt.rowId !== rowId) {
            return true;
          } else {
            subCategory = opt;
            return false;
          }
        });

        setLimitsLeft((prevValues) => {
          const updatedtotalLimit =
            prevValues?.[parentId]?.['totalChildrenLimit'] -
            Number(subCategory.limit || 0);

          return {
            ...prevValues,
            [parentId]: {
              ...prevValues[parentId],
              totalChildrenLimit: updatedtotalLimit,
            },
          };
        });
        return [...prevCategories];
      }
    });
    if (!parentId) setNoOfCategories((prev) => prev - 1);
  };

  const handleSave = () => {
    let hasError = false;
    let data = [];
    categories.forEach((opt) => {
      if (opt.isSubRow !== undefined) return;

      const newCategory = { ...opt };

      if (!opt.categoryName) {
        hasError = true;

        opt.invalid = [...(opt.invalid ?? []), 'categoryName'];
      }

      let hasInvalidChildren = false;

      let children = [];
      opt?.children?.forEach((item, idx) => {
        if (item.isSubRow !== undefined) return;

        if (!item.categoryName) {
          hasError = true;
          hasInvalidChildren = true;

          item.invalid = [...(item.invalid ?? []), 'categoryName'];
        }

        children.push(item);
      });
      if (hasInvalidChildren && !opt.expandChildren) {
        opt.expandChildren = true;
        let transformedChildren = opt.children.map((item) => ({
          ...item,
          isExpanded: true,
        }));

        opt.children = [
          ...transformedChildren,
          {
            categoryName: 'Add subcategory',
            description: '',
            limit: '',
            action: '',
            colspan: 4,
            isSubRow: true,
            parentId: opt.rowId,
            rowId: uuidv4(),
          },
        ];
      } else {
        newCategory.children = children;
      }

      data.push(newCategory);
    });

    setCategories([...categories]);

    if (!hasError) {
      const payload = data.map((opt) => {
        return {
          name: opt.categoryName,
          description: opt.description || undefined,
          limit: opt.limit ? opt.limit.replace(/,/g, '') * 100 : undefined,
          subCategories: opt?.children?.map((item) => {
            return {
              name: item.categoryName,
              description: item.description || undefined,
              limit: opt.limit ? opt.limit.replace(/,/g, '') * 100 : undefined,
            };
          }),
        };
      });
      dispatch(
        createBulkCategories({
          categories: payload,
        }),
      );
    }
  };

  const getRows = (categories) => [
    headerRow,
    ...flattenCategories(categories).map((category, idx) => ({
      rowId: category.rowId,
      parentId: category?.parentId,
      isExpanded: category?.isExpanded,
      cells: [
        {
          type: category?.colspan ? 'create' : 'customDropdown',
          text: category.categoryName,
          onExpand: () => {
            handleExpand(category?.rowId);
          },
          onCreate: () => {
            handleCreate(category?.rowId, category?.parentId);
          },
          onSelect: (isChecked) => {
            if (isChecked) {
              setSelectedItem((prevValues) => [
                ...prevValues,
                { rowId: category?.rowId, parentId: category?.parentId },
              ]);
            } else {
              setSelectedItem((prevValues) =>
                [
                  ...prevValues,
                  { rowId: category?.rowId, parentId: category?.parentId },
                ].filter((item) => item.rowId !== category?.rowId),
              );
            }
          },
          colspan: category?.colspan,
          nonEditable: !!category?.colspan,
          hasChildren: category?.parentId ? false : true,
          indent: category?.parentId ? 1.2 : 0.5,
          className: 'rg-cell-custom',
          rowId: category.rowId,
          parentId: category?.parentId,
          style: category?.invalid?.includes('categoryName')
            ? invalidCellStyle
            : undefined,
        },
        {
          type: 'customText',
          text: category.description,
          nonEditable: !!category?.colspan,
          className: 'rg-cell-custom',
          parentId: category?.parentId,
          rowId: category.rowId,
        },
        {
          type: 'amount',
          value: category.limit,
          currency: '₦',
          nonEditable: !!category?.colspan,
          parentId: category?.parentId,
          className: 'rg-cell-custom',
          style: category?.invalid?.includes('limit') ? invalidCellStyle : undefined,
          isValid: (value) => {
            if (category?.parentId) {
              if (limitsLeft[category?.parentId]?.['parentLimit'] === undefined)
                return true;
              let limit = Number(value.replace(/,/g, ''));
              let limitLeft =
                limitsLeft[category?.parentId]['parentLimit'] -
                (limitsLeft[category?.parentId]?.['totalChildrenLimit'] || 0) +
                Number(category.limit.replace(/,/g, ''));
              if (!isNaN(limitLeft) && limitLeft < limit) {
                toastError('Subcategory limits must add up to parent category limit');
                return false;
              }

              return true;
            } else {
              return true;
            }
          },
        },
        {
          type: 'modify',
          text: category.action,
          nonEditable: true,
          kebabId,
          onClick: handleTogglePopover,
          className: 'rg-cell-custom',
          rowId: category.rowId,
          parentId: category?.parentId,
          actionList: [
            {
              icon: TrashIcon,
              text: 'Delete',
              className: 'text-danger svg-danger',
              iconClassName: 'me-0',
              width: '16',
              height: '16',
              onClick: () => handleDelete(category.rowId, category.parentId),
            },
          ],
        },
      ],
      height: category.height ?? defaultRowHeight,
    })),
  ];

  const rows = getRows(categories).filter((item) => item.isExpanded !== false);
  const columns = getColumns();

  const handleChanges = (changes) => {
    setCategories((prevCategories) => applyChangesToCategory(changes, prevCategories));
  };

  const applyChangesToCategory = (changes, prevCategories) => {
    changes.forEach((change) => {
      const categoryIndex = change.rowId;
      const parentCategoryIndex = change.previousCell?.parentId;
      const fieldName = change.columnId;
      let changedRow = prevCategories.find(
        (item) => item.rowId === categoryIndex || item.rowId === parentCategoryIndex,
      );

      if (parentCategoryIndex) {
        changedRow = changedRow.children.find((item) => item.rowId === categoryIndex);
      }

      changedRow[fieldName] = change.newCell.text;
      if (fieldName === 'limit') {
        if (!parentCategoryIndex) {
          setLimitsLeft((prevValues) => {
            let updatedLimit;
            if (
              (prevValues[categoryIndex]?.['totalChildrenLimit'] || 0) >
              change.newCell.value
            ) {
              updatedLimit = prevValues[categoryIndex]?.['totalChildrenLimit'];
              changedRow[fieldName] =
                prevValues[categoryIndex]?.['totalChildrenLimit']?.toString();

              toastError('Subcategory limits must add up to parent category limit');
            } else {
              updatedLimit = change.newCell.value;
              changedRow[fieldName] = change.newCell.text;
            }
            return {
              ...prevValues,
              [categoryIndex]: {
                ...prevValues[categoryIndex],
                parentLimit: updatedLimit,
              },
            };
          });
        } else {
          setLimitsLeft((prevValues) => {
            let updatedtotalLimit =
              (prevValues[parentCategoryIndex]?.['totalChildrenLimit'] || 0) +
              Number(change.newCell.value || 0) -
              Number(change.previousCell.value || 0);
            return {
              ...prevValues,
              [parentCategoryIndex]: {
                ...prevValues[parentCategoryIndex],
                totalChildrenLimit: updatedtotalLimit,
              },
            };
          });
        }
      }

      if (changedRow?.invalid?.length)
        changedRow.invalid = changedRow.invalid.filter((opt) => opt !== fieldName);

      if (changedRowId !== categoryIndex) setChangedRowId(categoryIndex);
    });

    return [...prevCategories];
  };

  const cellTemplates = {
    customDropdown: new CustomDropdownTemplate(handleCustomDropdownRef, 40),
    modify: new ActionCellTemplate(),
    create: new CreateButtonCellTemplate(),
    amount: new AmountCellTemplate(),
    customText: new CustomTextCellTemplate(handleCustomTextRef, 250),
  };

  return (
    <div className="mt-5">
      <div
        className="back-click back-button gap-2 d-flex font-bold"
        onClick={() => history.push('/compliances/categories')}
        style={{ lineHeight: '20px', cursor: 'pointer', color: '#D28B28' }}
      >
        <ArrowLeftOutlined style={{ fontSize: 16, margin: 'auto 0px' }} />
        <span className="text-sm">Back</span>
      </div>
      <div className="mb-4 members-page">
        <h5 className="m-0">Type in details</h5>
        <h6 className="subtext text-sm mt-2">Upload details at your convenience.</h6>
      </div>
      <div ref={elementRef} style={{ width: '100%' }}>
        <TableGrid
          rows={rows}
          columns={columns}
          onCellsChanged={handleChanges}
          customCellTemplates={cellTemplates}
          onSave={handleSave}
          onCancel={() => history.push('/compliances/categories')}
          loading={loading}
          type={`${noOfCategories > 1 ? 'categories' : 'category'}`}
          itemNo={noOfCategories}
        />
      </div>
      {/* <FloatingCta /> */}
      {loading && <FullPageLoader />}
    </div>
  );
};
export default CreateMultipleCategories;
