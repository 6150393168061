export const AccountsIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.12">
      <path
        d="M16.1667 17.5002C16.6334 17.5002 16.8667 17.5002 17.045 17.4094C17.2018 17.3295 17.3293 17.202 17.4092 17.0452C17.5 16.8669 17.5 16.6336 17.5 16.1668V15.5002C17.5 15.0335 17.5 14.8001 17.4092 14.6219C17.3293 14.4651 17.2018 14.3376 17.045 14.2577C16.8667 14.1668 16.6334 14.1668 16.1667 14.1668L3.83333 14.1668C3.36662 14.1668 3.13327 14.1668 2.95501 14.2577C2.79821 14.3376 2.67072 14.4651 2.59083 14.6219C2.5 14.8001 2.5 15.0335 2.5 15.5002L2.5 16.1668C2.5 16.6336 2.5 16.8669 2.59083 17.0452C2.67072 17.202 2.79821 17.3295 2.95501 17.4093C3.13327 17.5002 3.36662 17.5002 3.83333 17.5002L16.1667 17.5002Z"
        fill="#79716B"
      />
      <path
        d="M16.1667 7.50018C16.6334 7.50018 16.8667 7.50018 17.045 7.40935C17.2018 7.32946 17.3293 7.20197 17.4092 7.04517C17.5 6.86691 17.5 6.63356 17.5 6.16685V5.23641C17.5 4.85477 17.5 4.66395 17.4324 4.50649C17.3727 4.36759 17.2764 4.24754 17.1538 4.15918C17.0147 4.05901 16.8285 4.01761 16.4559 3.93483L10.2892 2.56446C10.1813 2.54047 10.1273 2.52848 10.0728 2.52369C10.0244 2.51945 9.97564 2.51945 9.9272 2.52369C9.87267 2.52848 9.8187 2.54047 9.71076 2.56446L3.54409 3.93483C3.17154 4.01761 2.98527 4.05901 2.84622 4.15918C2.72358 4.24754 2.62727 4.36759 2.56762 4.50648C2.5 4.66395 2.5 4.85477 2.5 5.23641L2.5 6.16685C2.5 6.63356 2.5 6.86691 2.59083 7.04517C2.67072 7.20197 2.79821 7.32946 2.95501 7.40935C3.13327 7.50018 3.36662 7.50018 3.83333 7.50018H16.1667Z"
        fill="#79716B"
      />
    </g>
    <path
      d="M4.16667 7.50018V14.1668M7.91667 7.50018V14.1668M12.0833 7.50018V14.1668M15.8333 7.50018V14.1668M2.5 15.5002L2.5 16.1668C2.5 16.6336 2.5 16.8669 2.59083 17.0452C2.67072 17.202 2.79821 17.3295 2.95501 17.4094C3.13327 17.5002 3.36662 17.5002 3.83333 17.5002H16.1667C16.6334 17.5002 16.8667 17.5002 17.045 17.4094C17.2018 17.3295 17.3293 17.202 17.4092 17.0452C17.5 16.8669 17.5 16.6336 17.5 16.1668V15.5002C17.5 15.0335 17.5 14.8001 17.4092 14.6219C17.3293 14.4651 17.2018 14.3376 17.045 14.2577C16.8667 14.1668 16.6334 14.1668 16.1667 14.1668H3.83333C3.36662 14.1668 3.13327 14.1668 2.95501 14.2577C2.79821 14.3376 2.67072 14.4651 2.59083 14.6219C2.5 14.8001 2.5 15.0335 2.5 15.5002ZM9.71076 2.56446L3.54409 3.93483C3.17154 4.01761 2.98527 4.05901 2.84622 4.15918C2.72358 4.24754 2.62727 4.36759 2.56762 4.50648C2.5 4.66395 2.5 4.85477 2.5 5.23641L2.5 6.16685C2.5 6.63356 2.5 6.86691 2.59083 7.04517C2.67072 7.20197 2.79821 7.32946 2.95501 7.40935C3.13327 7.50018 3.36662 7.50018 3.83333 7.50018H16.1667C16.6334 7.50018 16.8667 7.50018 17.045 7.40935C17.2018 7.32946 17.3293 7.20197 17.4092 7.04517C17.5 6.86691 17.5 6.63356 17.5 6.16685V5.23641C17.5 4.85477 17.5 4.66395 17.4324 4.50649C17.3727 4.36759 17.2764 4.24754 17.1538 4.15918C17.0147 4.05901 16.8285 4.01762 16.4559 3.93483L10.2892 2.56446C10.1813 2.54047 10.1273 2.52848 10.0728 2.52369C10.0244 2.51945 9.97564 2.51945 9.9272 2.52369C9.87267 2.52848 9.8187 2.54047 9.71076 2.56446Z"
      stroke="#79716B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CardsIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.12"
      d="M1.66675 6.83317V8.33317H18.3334V6.83317C18.3334 5.89975 18.3334 5.43304 18.1518 5.07652C17.992 4.76292 17.737 4.50795 17.4234 4.34816C17.0669 4.1665 16.6002 4.1665 15.6667 4.1665H4.33342C3.39999 4.1665 2.93328 4.1665 2.57676 4.34816C2.26316 4.50795 2.00819 4.76292 1.8484 5.07652C1.66675 5.43304 1.66675 5.89975 1.66675 6.83317Z"
      fill="#79716B"
    />
    <path
      d="M18.3334 8.33317H1.66675M9.16675 11.6665H5.00008M1.66675 6.83317L1.66675 13.1665C1.66675 14.0999 1.66675 14.5666 1.8484 14.9232C2.00819 15.2368 2.26316 15.4917 2.57676 15.6515C2.93328 15.8332 3.39999 15.8332 4.33341 15.8332L15.6667 15.8332C16.6002 15.8332 17.0669 15.8332 17.4234 15.6515C17.737 15.4917 17.992 15.2368 18.1518 14.9232C18.3334 14.5666 18.3334 14.0999 18.3334 13.1665V6.83317C18.3334 5.89975 18.3334 5.43304 18.1518 5.07652C17.992 4.76292 17.737 4.50795 17.4234 4.34816C17.0669 4.16651 16.6002 4.16651 15.6667 4.1665L4.33342 4.1665C3.39999 4.1665 2.93328 4.1665 2.57676 4.34816C2.26316 4.50795 2.00819 4.76292 1.8484 5.07652C1.66675 5.43304 1.66675 5.89975 1.66675 6.83317Z"
      stroke="#79716B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoicesIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.12"
      d="M7.33325 18.3332H12.6666C14.0667 18.3332 14.7668 18.3332 15.3016 18.0607C15.772 17.821 16.1544 17.4386 16.3941 16.9681C16.6666 16.4334 16.6666 15.7333 16.6666 14.3332V5.6665C16.6666 4.26637 16.6666 3.56631 16.3941 3.03153C16.1544 2.56112 15.772 2.17867 15.3016 1.93899C14.7668 1.6665 14.0667 1.6665 12.6666 1.6665H10.4166L3.33325 13.7498V14.3332C3.33325 15.7333 3.33325 16.4334 3.60574 16.9681C3.84542 17.4386 4.22787 17.821 4.69828 18.0607C5.23305 18.3332 5.93312 18.3332 7.33325 18.3332Z"
      fill="#79716B"
    />
    <path
      d="M10.4167 1.6665H12.6667C14.0668 1.6665 14.7669 1.6665 15.3016 1.93899C15.772 2.17867 16.1545 2.56112 16.3942 3.03153C16.6667 3.56631 16.6667 4.26637 16.6667 5.6665V14.3332C16.6667 15.7333 16.6667 16.4334 16.3942 16.9681C16.1545 17.4386 15.772 17.821 15.3016 18.0607C14.7669 18.3332 14.0668 18.3332 12.6667 18.3332H7.33333C5.9332 18.3332 5.23314 18.3332 4.69836 18.0607C4.22795 17.821 3.8455 17.4386 3.60582 16.9681C3.33333 16.4334 3.33333 15.7333 3.33333 14.3332V13.7498M13.3333 10.8332H9.58333M13.3333 7.49984H10.4167M13.3333 14.1665H6.66667M5 8.33317V3.74984C5 3.05948 5.55964 2.49984 6.25 2.49984C6.94036 2.49984 7.5 3.05948 7.5 3.74984V8.33317C7.5 9.71388 6.38071 10.8332 5 10.8332C3.61929 10.8332 2.5 9.71388 2.5 8.33317V4.99984"
      stroke="#79716B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BillsIcon = (props) => (
  <svg
    width="20"
    {...props}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.12"
      d="M7.33325 18.3332H12.6666C14.0667 18.3332 14.7668 18.3332 15.3016 18.0607C15.772 17.821 16.1544 17.4386 16.3941 16.9681C16.6666 16.4334 16.6666 15.7333 16.6666 14.3332V5.6665C16.6666 4.26637 16.6666 3.56631 16.3941 3.03153C16.1544 2.56112 15.772 2.17867 15.3016 1.93899C14.7668 1.6665 14.0667 1.6665 12.6666 1.6665H10.4166L3.33325 13.7498V14.3332C3.33325 15.7333 3.33325 16.4334 3.60574 16.9681C3.84542 17.4386 4.22787 17.821 4.69828 18.0607C5.23305 18.3332 5.93312 18.3332 7.33325 18.3332Z"
      fill="black"
    />
    <path
      d="M5 8.33317V3.74984C5 3.05948 5.55964 2.49984 6.25 2.49984C6.94036 2.49984 7.5 3.05948 7.5 3.74984V8.33317C7.5 9.71388 6.38071 10.8332 5 10.8332C3.61929 10.8332 2.5 9.71388 2.5 8.33317V4.99984M10.4167 1.6665H12.6667C14.0668 1.6665 14.7669 1.6665 15.3016 1.93899C15.772 2.17867 16.1545 2.56112 16.3942 3.03153C16.6667 3.56631 16.6667 4.26637 16.6667 5.6665V14.3332C16.6667 15.7333 16.6667 16.4334 16.3942 16.9681C16.1545 17.4386 15.772 17.821 15.3016 18.0607C14.7669 18.3332 14.0668 18.3332 12.6667 18.3332H7.33333C5.9332 18.3332 5.23314 18.3332 4.69836 18.0607C4.22795 17.821 3.8455 17.4386 3.60582 16.9681C3.33333 16.4334 3.33333 15.7333 3.33333 14.3332V13.7498"
      stroke="#79716B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BudgetsIcon = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2488_1853)">
      <path
        opacity="0.12"
        d="M17.6667 9.99998C17.8982 9.99998 18.014 9.99998 18.1067 9.94992C18.1841 9.9081 18.2544 9.83191 18.2899 9.7514C18.3324 9.65501 18.3238 9.54801 18.3067 9.33401C18.2372 8.46778 18.0326 7.61633 17.699 6.81095C17.2802 5.7999 16.6664 4.88124 15.8926 4.10742C15.1187 3.3336 14.2001 2.71977 13.189 2.30098C12.3836 1.96738 11.5322 1.76274 10.666 1.6933C10.452 1.67614 10.345 1.66756 10.2486 1.71005C10.1681 1.74555 10.0919 1.81588 10.0501 1.8933C10 1.98598 10 2.10175 10 2.33331V9.33331C10 9.56667 10 9.68334 10.0454 9.77247C10.0854 9.85087 10.1491 9.91462 10.2275 9.95456C10.3166 9.99998 10.4333 9.99998 10.6667 9.99998H17.6667Z"
        fill="#79716B"
      />
      <path
        d="M17.6749 13.2416C17.1448 14.4954 16.3156 15.6002 15.2598 16.4594C14.2041 17.3186 12.9539 17.9062 11.6186 18.1706C10.2833 18.4351 8.90362 18.3684 7.60005 17.9765C6.29649 17.5845 5.10878 16.8792 4.14078 15.9222C3.17277 14.9652 2.45394 13.7856 2.04712 12.4866C1.64031 11.1876 1.5579 9.80872 1.80709 8.4705C2.05629 7.13229 2.62951 5.8755 3.47663 4.81C4.32376 3.7445 5.419 2.90274 6.6666 2.35831M17.6989 6.81095C18.0325 7.61633 18.2372 8.46778 18.3066 9.33401C18.3238 9.54801 18.3323 9.65501 18.2899 9.7514C18.2544 9.83191 18.184 9.9081 18.1066 9.94992C18.0139 9.99998 17.8982 9.99998 17.6666 9.99998H10.6666C10.4332 9.99998 10.3166 9.99998 10.2274 9.95456C10.149 9.91462 10.0853 9.85087 10.0453 9.77247C9.99993 9.68334 9.99993 9.56667 9.99993 9.33331V2.33331C9.99993 2.10175 9.99993 1.98598 10.05 1.8933C10.0918 1.81588 10.168 1.74555 10.2485 1.71005C10.3449 1.66756 10.4519 1.67614 10.6659 1.6933C11.5321 1.76274 12.3836 1.96738 13.189 2.30098C14.2 2.71977 15.1187 3.3336 15.8925 4.10742C16.6663 4.88124 17.2801 5.7999 17.6989 6.81095Z"
        stroke="#79716B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2488_1853">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const LoadingCircle = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62 33C62 36.8083 61.2499 40.5794 59.7925 44.0978C58.3351 47.6163 56.199 50.8132 53.5061 53.5061C50.8132 56.199 47.6163 58.3351 44.0978 59.7925C40.5794 61.2499 36.8083 62 33 62C29.1917 62 25.4206 61.2499 21.9022 59.7925C18.3837 58.3351 15.1868 56.199 12.4939 53.5061C9.801 50.8132 7.66488 47.6163 6.20749 44.0978C4.7501 40.5794 4 36.8083 4 33C4 29.1917 4.75011 25.4206 6.2075 21.9022C7.66489 18.3837 9.80101 15.1868 12.4939 12.4939C15.1868 9.801 18.3837 7.66487 21.9022 6.20749C25.4206 4.7501 29.1917 4 33 4C36.8083 4 40.5794 4.75011 44.0978 6.2075C47.6163 7.66489 50.8132 9.80101 53.5061 12.4939C56.199 15.1868 58.3351 18.3838 59.7925 21.9022C61.2499 25.4206 62 29.1917 62 33L62 33Z"
      stroke="url(#paint0_angular_2488_8562)"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0.1 8"
    />
    <defs>
      <radialGradient
        id="paint0_angular_2488_8562"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(33 33) rotate(169.077) scale(29.0258)"
      >
        <stop stopColor="#AF7421" />
        <stop offset="1" stopColor="#AF7421" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
