import { getSimplifiedError } from '../../utils/error';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import {
  CALCULATE_FEES_REQUEST,
  CALCULATE_FEES_ERROR,
  CALCULATE_FEES_SUCCESS,
} from '../reducers/FeesReducer';
import { toastError } from 'components/UI/toast';

export async function calculateFees({ payload }) {
  return await Axios.post('/payments/calculate-fee', payload);
}

function* handleFeesCalculation({ payload }) {
  try {
    const response = yield call(calculateFees, { payload });
    if (response) {
      yield put({
        type: CALCULATE_FEES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CALCULATE_FEES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([takeLatest(CALCULATE_FEES_REQUEST, handleFeesCalculation)]);
