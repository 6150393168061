import {
  // CardType,
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import AddReimbursement from 'components/ReimbursementView/EmployeeModal/AddReimbursement';
import AddReimbursementBankDetails from 'components/ReimbursementView/EmployeeModal/AddReimbursementBankDetails';
import ViewReimbursementDetails from 'components/ReimbursementView/EmployeeModal/ViewReimbursementDetails';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { getReimbursements } from 'redux/actions/ReimbursementsAction';
import { buildEmployeeReimbursementTableData } from 'utils/helper';
import { employeeColumnsReimbursement } from 'utils/mockData';

const TeamMemberReimbursment = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [selectReimbursement, setSelectReimbursement] = useState(null);
  const [reimbursmentCreated, setReimbursmentCreated] = useState(false);
  const [reimbursmentDeleted, setReimbursmentDeleted] = useState(false);
  const [addBankDetails, setAddBankDetails] = useState(false);

  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);

  const {
    getBeneficiaryBank: { data: beneficiaryBankData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getReimbursement: { data: { meta = {} } = {}, data = {}, loading },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    downloadAsset: { data: { asset } = {}, success: assetSuccess },
  } = useSelector(({ assets }) => assets);

  useEffect(() => {
    if (assetSuccess) {
      window.open(asset.url, 'Download');
    }
  }, [assetSuccess]);

  const { page, total, hasMore, perPage } = meta;
  const { reimbursements = [] } = data;
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const toggleHandler = () => {
    if (!isOtherPopoverOpen) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (!reimbursements.length) dispatch(getReimbursements());
    return () => {
      if (filtered) dispatch(getReimbursements());
    };
  }, [filtered]);

  const handleRowClick = (row) => {
    // dispatch(getSingleReimbursement(row?.transactionData?.code));
    setSelectReimbursement(row);
    if (!isOtherPopoverOpen) {
      // toggleModal();
      setIsOpenView(!isOpenView);
    }
  };

  useEffect(() => {
    if (reimbursements.length > 0 && !isFillData) {
      setIsFillData(true);
    }
    // if (success) setTableData(buildEmployeeReimbursementTableData(reimbursements));
  }, [reimbursements, reimbursmentCreated, reimbursmentDeleted]);

  const handleFilter = (query) => {
    isFiltered.current = !!Object.keys(query).length;
    const { status: s, currency: c, from: f, to: t } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const to = t ? t.toString() : undefined;
    const from = f ? f.toString() : undefined;

    if ((!from && to) || (from && !to)) {
      return toastError('To filter by date, please set from and to date');
    }
    dispatch(
      getReimbursements({
        status,
        currency,
        from,
        to,
      }),
    );
  };

  useEffect(() => {
    dispatch(getBeneficiaryBank());
  }, []);

  useEffect(() => {
    if (reimbursmentCreated) {
      if (beneficiaryBankData.length > 0) {
        setIsFillData(false);
        toggleHandler();
        dispatch(getReimbursements());
      } else {
        setAddBankDetails(true);
      }
    }
  }, [reimbursmentCreated]);

  useEffect(() => {
    if (reimbursmentDeleted) {
      window.setTimeout(function () {
        setIsFillData(false);
        handleRowClick();
        dispatch(getReimbursements());
      }, 1000);
    }
  }, [reimbursmentDeleted]);

  if (loading && !isFiltered) return <Loading isPage color="#D28B28" />;

  return (
    <div className="reimbursements-wrapper">
      <PendingOnboardingNotice />

      <TopBar
        addIcon
        showFilter
        addOnClick={toggleHandler}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withOutSearch={true}
        withDate={true}
      />

      {!reimbursements.length ? (
        <div className="innerWrapper">
          {filtered ? (
            <NoData
              headerText="You have no reimbursements for this filter"
              bodyText="Alter the filter to see your reimbursement"
              withButton={false}
            />
          ) : (
            <NoData
              headerText="You have no reimbursements yet"
              buttonLabel="Create your first reimbursement"
              bodyText="The complete list of your reimbursements will be displayed here. Request your first reimbursement"
              onClickHandler={toggleHandler}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row>
              <Col xs={12}>
                <Table
                  columns={employeeColumnsReimbursement}
                  data={buildEmployeeReimbursementTableData(reimbursements)}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  currentPage={page}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      {/* <EmployeeModal
        addReimbursement={addReimbursement}
        toggleHandler={toggleHandler}
      /> */}
      {selectReimbursement && (
        <ViewReimbursementDetails
          isOpen={isOpenView}
          handleRowClick={handleRowClick}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          selectReimbursement={selectReimbursement}
          setReimbursmentDeleted={setReimbursmentDeleted}
        />
      )}
      {!addBankDetails ? (
        <AddReimbursement
          isOpen={isOpen}
          toggleHandler={toggleHandler}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          setReimbursmentCreated={setReimbursmentCreated}
        />
      ) : (
        <AddReimbursementBankDetails
          isOpen={isOpen}
          toggleHandler={toggleHandler}
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
        />
      )}
    </div>
  );
};

export default TeamMemberReimbursment;
