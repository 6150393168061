export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';

export const CREATE_BANK_ACCOUNT_REQUEST = 'CREATE_BANK_ACCOUNT_REQUEST';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_ERROR = 'CREATE_BANK_ACCOUNT_ERROR';

export const SYNC_BANK_ACCOUNT_REQUEST = 'SYNC_BANK_ACCOUNT_REQUEST';
export const SYNC_BANK_ACCOUNT_SUCCESS = 'SYNC_BANK_ACCOUNT_SUCCESS';
export const SYNC_BANK_ACCOUNT_ERROR = 'SYNC_BANK_ACCOUNT_ERROR';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_ERROR = 'GET_STATE_ERROR';

export const ONBOARDING_BUSINESS_INFORMATION_REQUEST =
  'ONBOARDING_BUSINESS_INFORMATION_REQUEST';
export const ONBOARDING_BUSINESS_INFORMATION_SUCCESS =
  'ONBOARDING_BUSINESS_INFORMATION_SUCCESS';
export const ONBOARDING_BUSINESS_INFORMATION_ERROR =
  'ONBOARDING_BUSINESS_INFORMATION_ERROR';

export const ONBOARDING_BUSINESS_ADDRESS_REQUEST = 'ONBOARDING_BUSINESS_ADDRESS_REQUEST';
export const ONBOARDING_BUSINESS_ADDRESS_SUCCESS = 'ONBOARDING_BUSINESS_ADDRESS_SUCCESS';
export const ONBOARDING_BUSINESS_ADDRESS_ERROR = 'ONBOARDING_BUSINESS_ADDRESS_ERROR';

export const ONBOARDING_BUSINESS_DOC_REQUEST = 'ONBOARDING_BUSINESS_DOC_REQUEST';
export const ONBOARDING_BUSINESS_DOC_SUCCESS = 'ONBOARDING_BUSINESS_DOC_SUCCESS';
export const ONBOARDING_BUSINESS_DOC_ERROR = 'ONBOARDING_BUSINESS_DOC_ERROR';

export const GET_COMPANY_STATS_REQUEST = 'GET_COMPANY_STATS_REQUEST';
export const GET_COMPANY_STATS_SUCCESS = 'GET_COMPANY_STATS_SUCCESS';
export const GET_COMPANY_STATS_ERROR = 'GET_COMPANY_STATS_ERROR';

export const GET_BANK_ACCOUNT_REQUEST = 'GET_BANK_ACCOUNT_REQUEST';
export const GET_BANK_ACCOUNT_SUCCESS = 'GET_BANK_ACCOUNT_SUCCESS';
export const GET_BANK_ACCOUNT_ERROR = 'GET_BANK_ACCOUNT_ERROR';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR';

export const REVIEW_COMPANY_REQUEST = 'REVIEW_COMPANY_REQUEST';
export const REVIEW_COMPANY_SUCCESS = 'REVIEW_COMPANY_SUCCESS';
export const REVIEW_COMPANY_ERROR = 'REVIEW_COMPANY_ERROR';

export const FUND_WIDTH_CARDS_REQUEST = 'FUND_WIDTH_CARDS_REQUEST';
export const FUND_WIDTH_CARDS_SUCCESS = 'FUND_WIDTH_CARDS_SUCCESS';
export const FUND_WIDTH_CARDS_ERROR = 'FUND_WIDTH_CARDS_ERROR';

export const FUND_DOLLAR_BALANCE_REQUEST = 'FUND_DOLLAR_BALANCE_REQUEST';
export const FUND_DOLLAR_BALANCE_SUCCESS = 'FUND_DOLLAR_BALANCE_SUCCESS';
export const FUND_DOLLAR_BALANCE_ERROR = 'FUND_DOLLAR_BALANCE_ERROR';

export const GET_DOLLAR_ACCOUNT_REQUEST = 'GET_DOLLAR_ACCOUNT_REQUEST';
export const GET_DOLLAR_ACCOUNT_SUCCESS = 'GET_DOLLAR_ACCOUNT_SUCCESS';
export const GET_DOLLAR_ACCOUNT_ERROR = 'GET_DOLLAR_ACCOUNT_ERROR';

export const GET_REJECTED_DOCUMENTS_REQUEST = 'GET_REJECTED_DOCUMENTS_REQUEST';
export const GET_REJECTED_DOCUMENTS_SUCCESS = 'GET_REJECTED_DOCUMENTS_SUCCESS';
export const GET_REJECTED_DOCUMENTS_ERROR = 'GET_REJECTED_DOCUMENTS_ERROR';

export const RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST =
  'RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST';
export const RE_SUBMIT_REJECTED_DOCUMENTS_SUCCESS =
  'RE_SUBMIT_REJECTED_DOCUMENTS_SUCCESS';
export const RE_SUBMIT_REJECTED_DOCUMENTS_ERROR = 'RE_SUBMIT_REJECTED_DOCUMENTS_ERROR';

export const RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST =
  'RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST';
export const RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_SUCCESS =
  'RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_SUCCESS';
export const RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_ERROR =
  'RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_ERROR';

export const INVITE_DIRECTOR_REQUEST = 'INVITE_DIRECTOR_REQUEST';
export const INVITE_DIRECTOR_SUCCESS = 'INVITE_DIRECTOR_SUCCESS';
export const INVITE_DIRECTOR_ERROR = 'INVITE_DIRECTOR_ERROR';

export const GET_INVITED_DIRECTORS_REQUEST = 'GET_INVITED_DIRECTORS_REQUEST';
export const GET_INVITED_DIRECTORS_SUCCESS = 'GET_INVITED_DIRECTORS_SUCCESS';
export const GET_INVITED_DIRECTORS_ERROR = 'GET_INVITED_DIRECTORS_ERROR';

export const UPLOAD_DIRECTOR_INFO_REQUEST = 'UPLOAD_DIRECTOR_INFO_REQUEST';
export const UPLOAD_DIRECTOR_INFO_SUCCESS = 'UPLOAD_DIRECTOR_INFO_SUCCESS';
export const UPLOAD_DIRECTOR_INFO_ERROR = 'UPLOAD_DIRECTOR_INFO_ERROR';

export const UPLOAD_INVITED_DIRECTOR_INFO_REQUEST =
  'UPLOAD_INVITED_DIRECTOR_INFO_REQUEST';
export const UPLOAD_INVITED_DIRECTOR_INFO_SUCCESS =
  'UPLOAD_INVITED_DIRECTOR_INFO_SUCCESS';
export const UPLOAD_INVITED_DIRECTOR_INFO_ERROR = 'UPLOAD_INVITED_DIRECTOR_INFO_ERROR';

export const GET_SINGLE_INVITED_DIRECTOR_REQUEST = 'GET_SINGLE_INVITED_DIRECTOR_REQUEST';
export const GET_SINGLE_INVITED_DIRECTOR_SUCCESS = 'GET_SINGLE_INVITED_DIRECTOR_SUCCESS';
export const GET_SINGLE_INVITED_DIRECTOR_ERROR = 'GET_SINGLE_INVITED_DIRECTOR_ERROR';

export const RESEND_ONBOARDING_INVITE_REQUEST = 'RESEND_ONBOARDING_INVITE_REQUEST';
export const RESEND_ONBOARDING_INVITE_SUCCESS = 'RESEND_ONBOARDING_INVITE_SUCCESS';
export const RESEND_ONBOARDING_INVITE_ERROR = 'RESEND_ONBOARDING_INVITE_ERROR';

export const DELETE_INVITED_DIRECTOR_REQUEST = 'DELETE_INVITED_DIRECTOR_REQUEST';
export const DELETE_INVITED_DIRECTOR_SUCCESS = 'DELETE_INVITED_DIRECTOR_SUCCESS';
export const DELETE_INVITED_DIRECTOR_ERROR = 'DELETE_INVITED_DIRECTOR_ERROR';

export const UPDATE_INVITED_DIRECTOR_REQUEST = 'UPDATE_INVITED_DIRECTOR_REQUEST';
export const UPDATE_INVITED_DIRECTOR_SUCCESS = 'UPDATE_INVITED_DIRECTOR_SUCCESS';
export const UPDATE_INVITED_DIRECTOR_ERROR = 'UPDATE_INVITED_DIRECTOR_ERROR';

export const SEND_MONO_AUTH_CODE_REQUEST = 'SEND_MONO_AUTH_CODE_REQUEST';
export const SEND_MONO_AUTH_CODE_SUCCESS = 'SEND_MONO_AUTH_CODE_SUCCESS';
export const SEND_MONO_AUTH_CODE_ERROR = 'SEND_MONO_AUTH_CODE_ERROR';

export const GET_MONO_PAYMENT_LINK_REQUEST = 'GET_MONO_PAYMENT_LINK_REQUEST';
export const GET_MONO_PAYMENT_LINK_SUCCESS = 'GET_MONO_PAYMENT_LINK_SUCCESS';
export const GET_MONO_PAYMENT_LINK_ERROR = 'GET_MONO_PAYMENT_LINK_ERROR';

export const ADD_FUNDS_REQUEST = 'ADD_FUNDS_REQUEST';
export const ADD_FUNDS_SUCCESS = 'ADD_FUNDS_SUCCESS';
export const ADD_FUNDS_ERROR = 'ADD_FUNDS_ERROR';

export const VERIFY_MONO_PAYMENT_REFERENCE_REQUEST =
  'VERIFY_MONO_PAYMENT_REFERENCE_REQUEST';
export const VERIFY_MONO_PAYMENT_REFERENCE_SUCCESS =
  'VERIFY_MONO_PAYMENT_REFERENCE_SUCCESS';
export const VERIFY_MONO_PAYMENT_REFERENCE_ERROR = 'VERIFY_MONO_PAYMENT_REFERENCE_ERROR';

export const MONO_ACCOUNT_REAUTHORIZATION_CODE_REQUEST =
  'MONO_ACCOUNT_REAUTHORIZATION_CODE_REQUEST';
export const MONO_ACCOUNT_REAUTHORIZATION_CODE_SUCCESS =
  'MONO_ACCOUNT_REAUTHORIZATION_CODE_SUCCESS';
export const MONO_ACCOUNT_REAUTHORIZATION_CODE_ERROR =
  'MONO_ACCOUNT_REAUTHORIZATION_CODE_ERROR';

export const GET_COMPANY_ASSET_REQUEST = 'GET_COMPANY_ASSET_REQUEST';
export const GET_COMPANY_ASSET_SUCCESS = 'GET_COMPANY_ASSET_SUCCESS';
export const GET_COMPANY_ASSET_ERROR = 'GET_COMPANY_ASSET_ERROR';

export const CREATE_MANDATE_REQUEST = 'CREATE_MANDATE_REQUEST';
export const CREATE_MANDATE_SUCCESS = 'CREATE_MANDATE_SUCCESS';
export const CREATE_MANDATE_ERROR = 'CREATE_MANDATE_ERROR';

export const CANCEL_MANDATE_REQUEST = 'CANCEL_MANDATE_REQUEST';
export const CANCEL_MANDATE_SUCCESS = 'CANCEL_MANDATE_SUCCESS';
export const CANCEL_MANDATE_ERROR = 'CANCEL_MANDATE_ERROR';

export const GET_MANDATE_STATUS_REQUEST = 'GET_MANDATE_STATUS_REQUEST';
export const GET_MANDATE_STATUS_SUCCESS = 'GET_MANDATE_STATUS_SUCCESS';
export const GET_MANDATE_STATUS_ERROR = 'GET_MANDATE_STATUS_ERROR';

export const RESET_BLOCK_COMPANY = 'RESET_BLOCK_COMPANY';

export const RESET_FLAGS_COMPANY = 'RESET_FLAGS_COMPANY';

export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_ERROR = 'ADD_COMPANY_ERROR';

export const SWITCH_COMPANY_REQUEST = 'SWITCH_COMPANY_REQUEST';
export const SWITCH_COMPANY_SUCCESS = 'SWITCH_COMPANY_SUCCESS';
export const SWITCH_COMPANY_ERROR = 'SWITCH_COMPANY_ERROR';

export const AUTHENTICATE_COMPANY_REQUEST = 'AUTHENTICATE_COMPANY_REQUEST';
export const AUTHENTICATE_COMPANY_SUCCESS = 'AUTHENTICATE_COMPANY_SUCCESS';
export const AUTHENTICATE_COMPANY_ERROR = 'AUTHENTICATE_COMPANY_ERROR';

export const UPLOAD_ONBOARDING_DOCUMENTS_REQUEST = 'UPLOAD_ONBOARDING_DOCUMENTS_REQUEST';
export const UPLOAD_ONBOARDING_DOCUMENTS_SUCCESS = 'UPLOAD_ONBOARDING_DOCUMENTS_SUCCESS';
export const UPLOAD_ONBOARDING_DOCUMENTS_ERROR = 'UPLOAD_ONBOARDING_DOCUMENTS_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  addEntity: { ...block },
  getCompany: { ...block },
  getCompanies: { ...block },
  getStatistics: { ...block },
  getBankAccount: { ...block, data: {} },
  createBankAccount: { ...block, data: {} },
  updateCompany: { ...block },
  reviewCompany: { ...block },
  onboardingBusinessInformation: { ...block },
  onboardingBusinessAddress: { ...block },
  onboardingBusinessDoc: { ...block },
  fundCreate: { ...block },
  getCountry: { ...block },
  getState: { ...block },
  getRejectedDocumentDetails: { ...block },
  reSubmitRejectedDocumentDetails: { ...block },
  reSubmitRejectedIsCompleted: { ...block },
  fundDollarBalance: { ...block },
  getDollarAccount: { ...block },
  inviteDirector: { ...block },
  getInvitedDirectors: { ...block },
  uploadDirectorInfo: { ...block },
  uploadInvitedDirectorInfo: { ...block },
  getSingleInvitedDirector: { ...block },
  resendOnboardingInvite: { ...block },
  deleteInvitedDirector: { ...block },
  updateInvitedDirector: { ...block },
  sendMonoAuthCode: { ...block },
  getPaymentLink: { ...block },
  verifyPaymentReference: { ...block },
  getAccountReauthorizationCode: { ...block },
  getCompanyAsset: { ...block },
  addFundsToAccount: { ...block },
  createMandate: { ...block },
  getMandateStatus: { ...block },
  syncBankAccountData: { ...block },
  switchCompany: { ...block },
  authenticate: { ...block },
  cancelMandate: { ...block },
  uploadOnboardingDocuments: { ...block },
};

export const CompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return { ...state, getCompany: { ...state.getCompany, loading: true } };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        getCompany: {
          ...state.getCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        getCompany: { ...state.getCompany, loading: false, error: action.error },
      };

    case GET_COUNTRY_REQUEST:
      return { ...state, getCountry: { ...state.getCountry, loading: true } };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        getCountry: {
          ...state.getCountry,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COUNTRY_ERROR:
      return {
        ...state,
        getCountry: { ...state.getCountry, loading: false, error: action.error },
      };

    case GET_STATE_REQUEST:
      return { ...state, getState: { ...state.getState, loading: true } };
    case GET_STATE_SUCCESS:
      return {
        ...state,
        getState: {
          ...state.getState,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_STATE_ERROR:
      return {
        ...state,
        getState: { ...state.getState, loading: false, error: action.error },
      };

    case GET_COMPANY_ASSET_REQUEST:
      return { ...state, getCompanyAsset: { ...state.getCompanyAsset, loading: true } };
    case GET_COMPANY_ASSET_SUCCESS:
      return {
        ...state,
        getCompanyAsset: {
          ...state.getCompanyAsset,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COMPANY_ASSET_ERROR:
      return {
        ...state,
        getCompanyAsset: {
          ...state.getCompanyAsset,
          loading: false,
          error: action.error,
        },
      };

    case ONBOARDING_BUSINESS_INFORMATION_REQUEST:
      return {
        ...state,
        onboardingBusinessInformation: {
          ...state.onboardingBusinessInformation,
          loading: true,
        },
      };
    case ONBOARDING_BUSINESS_INFORMATION_SUCCESS:
      return {
        ...state,
        onboardingBusinessInformation: {
          ...state.onboardingBusinessInformation,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ONBOARDING_BUSINESS_INFORMATION_ERROR:
      return {
        ...state,
        onboardingBusinessInformation: {
          ...state.onboardingBusinessInformation,
          loading: false,
          error: action.error,
        },
      };

    case ONBOARDING_BUSINESS_ADDRESS_REQUEST:
      return {
        ...state,
        onboardingBusinessAddress: { ...state.onboardingBusinessAddress, loading: true },
      };
    case ONBOARDING_BUSINESS_ADDRESS_SUCCESS:
      return {
        ...state,
        onboardingBusinessAddress: {
          ...state.onboardingBusinessAddress,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ONBOARDING_BUSINESS_ADDRESS_ERROR:
      return {
        ...state,
        onboardingBusinessAddress: {
          ...state.onboardingBusinessAddress,
          loading: false,
          error: action.error,
        },
      };

    case ONBOARDING_BUSINESS_DOC_REQUEST:
      return {
        ...state,
        onboardingBusinessDoc: { ...state.onboardingBusinessDoc, loading: true },
      };
    case ONBOARDING_BUSINESS_DOC_SUCCESS:
      return {
        ...state,
        onboardingBusinessDoc: {
          ...state.onboardingBusinessDoc,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ONBOARDING_BUSINESS_DOC_ERROR:
      return {
        ...state,
        onboardingBusinessDoc: {
          ...state.onboardingBusinessDoc,
          loading: false,
          error: action.error,
        },
      };

    case GET_COMPANY_STATS_REQUEST:
      return { ...state, getStatistics: { ...state.getStatistics, loading: true } };
    case GET_COMPANY_STATS_SUCCESS:
      return {
        ...state,
        getStatistics: {
          ...state.getStatistics,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COMPANY_STATS_ERROR:
      return {
        ...state,
        getStatistics: { ...state.getStatistics, loading: false, error: action.error },
      };

    case GET_BANK_ACCOUNT_REQUEST:
      return { ...state, getBankAccount: { ...state.getBankAccount, loading: true } };
    case GET_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        getBankAccount: {
          ...state.getBankAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        getBankAccount: { ...state.getBankAccount, loading: false, error: action.error },
      };

    case CREATE_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        createBankAccount: { ...state.createBankAccount, loading: true },
      };
    case CREATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        createBankAccount: {
          ...state.createBankAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        createBankAccount: {
          ...state.createBankAccount,
          loading: false,
          error: action.error,
        },
      };

    case SYNC_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        syncBankAccountData: { ...state.syncBankAccountData, loading: true },
      };
    case SYNC_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        syncBankAccountData: {
          ...state.syncBankAccountData,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SYNC_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        syncBankAccountData: {
          ...state.syncBankAccountData,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_COMPANY_REQUEST:
      return { ...state, updateCompany: { ...state.updateCompany, loading: true } };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompany: {
          ...state.updateCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        updateCompany: { ...state.updateCompany, loading: false, error: action.error },
      };

    case REVIEW_COMPANY_REQUEST:
      return { ...state, reviewCompany: { ...state.reviewCompany, loading: true } };
    case REVIEW_COMPANY_SUCCESS:
      return {
        ...state,
        reviewCompany: {
          ...state.reviewCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_COMPANY_ERROR:
      return {
        ...state,
        reviewCompany: { ...state.reviewCompany, loading: false, error: action.error },
      };

    case FUND_WIDTH_CARDS_REQUEST:
      return { ...state, fundCreate: { ...state.fundCreate, loading: true } };
    case FUND_WIDTH_CARDS_SUCCESS:
      return {
        ...state,
        fundCreate: {
          ...state.fundCreate,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FUND_WIDTH_CARDS_ERROR:
      return {
        ...state,
        fundCreate: { ...state.fundCreate, loading: false, error: action.error },
      };

    case FUND_DOLLAR_BALANCE_REQUEST:
      return {
        ...state,
        fundDollarBalance: { ...state.fundDollarBalance, loading: true },
      };
    case FUND_DOLLAR_BALANCE_SUCCESS:
      return {
        ...state,
        fundDollarBalance: {
          ...state.fundDollarBalance,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FUND_DOLLAR_BALANCE_ERROR:
      return {
        ...state,
        fundDollarBalance: {
          ...state.fundDollarBalance,
          loading: false,
          error: action.error,
        },
      };

    case GET_DOLLAR_ACCOUNT_REQUEST:
      return { ...state, getDollarAccount: { ...state.getDollarAccount, loading: true } };
    case GET_DOLLAR_ACCOUNT_SUCCESS:
      return {
        ...state,
        getDollarAccount: {
          ...state.getDollarAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_DOLLAR_ACCOUNT_ERROR:
      return {
        ...state,
        getDollarAccount: {
          ...state.getDollarAccount,
          loading: false,
          error: action.error,
        },
      };

    case GET_REJECTED_DOCUMENTS_REQUEST:
      return {
        ...state,
        getRejectedDocumentDetails: {
          ...state.getRejectedDocumentDetails,
          loading: true,
        },
      };
    case GET_REJECTED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getRejectedDocumentDetails: {
          ...state.getRejectedDocumentDetails,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REJECTED_DOCUMENTS_ERROR:
      return {
        ...state,
        getRejectedDocumentDetails: {
          ...state.getRejectedDocumentDetails,
          loading: false,
          error: action.error,
        },
      };

    case RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST:
      return {
        ...state,
        reSubmitRejectedDocumentDetails: {
          ...state.reSubmitRejectedDocumentDetails,
          loading: true,
        },
      };
    case RE_SUBMIT_REJECTED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        reSubmitRejectedDocumentDetails: {
          ...state.reSubmitRejectedDocumentDetails,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case RE_SUBMIT_REJECTED_DOCUMENTS_ERROR:
      return {
        ...state,
        reSubmitRejectedDocumentDetails: {
          ...state.reSubmitRejectedDocumentDetails,
          loading: false,
          error: action.error,
        },
      };

    case RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST:
      return {
        ...state,
        reSubmitRejectedIsCompleted: {
          ...state.reSubmitRejectedIsCompleted,
          loading: true,
        },
      };
    case RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_SUCCESS:
      return {
        ...state,
        reSubmitRejectedIsCompleted: {
          ...state.reSubmitRejectedIsCompleted,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_ERROR:
      return {
        ...state,
        reSubmitRejectedIsCompleted: {
          ...state.reSubmitRejectedIsCompleted,
          loading: false,
          error: action.error,
        },
      };

    case INVITE_DIRECTOR_REQUEST:
      return { ...state, inviteDirector: { ...state.inviteDirector, loading: true } };
    case INVITE_DIRECTOR_SUCCESS:
      return {
        ...state,
        inviteDirector: {
          ...state.inviteDirector,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case INVITE_DIRECTOR_ERROR:
      return {
        ...state,
        inviteDirector: { ...state.inviteDirector, loading: false, error: action.error },
      };

    case GET_SINGLE_INVITED_DIRECTOR_REQUEST:
      return {
        ...state,
        getSingleInvitedDirector: { ...state.getSingleInvitedDirector, loading: true },
      };
    case GET_SINGLE_INVITED_DIRECTOR_SUCCESS:
      return {
        ...state,
        getSingleInvitedDirector: {
          ...state.getSingleInvitedDirector,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_INVITED_DIRECTOR_ERROR:
      return {
        ...state,
        getSingleInvitedDirector: {
          ...state.getSingleInvitedDirector,
          loading: false,
          error: action.error,
        },
      };

    case RESEND_ONBOARDING_INVITE_REQUEST:
      return {
        ...state,
        resendOnboardingInvite: { ...state.resendOnboardingInvite, loading: true },
      };
    case RESEND_ONBOARDING_INVITE_SUCCESS:
      return {
        ...state,
        resendOnboardingInvite: {
          ...state.resendOnboardingInvite,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case RESEND_ONBOARDING_INVITE_ERROR:
      return {
        ...state,
        resendOnboardingInvite: {
          ...state.resendOnboardingInvite,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_INVITED_DIRECTOR_REQUEST:
      return {
        ...state,
        deleteInvitedDirector: { ...state.deleteInvitedDirector, loading: true },
      };
    case DELETE_INVITED_DIRECTOR_SUCCESS:
      return {
        ...state,
        deleteInvitedDirector: {
          ...state.deleteInvitedDirector,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_INVITED_DIRECTOR_ERROR:
      return {
        ...state,
        deleteInvitedDirector: {
          ...state.deleteInvitedDirector,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_INVITED_DIRECTOR_REQUEST:
      return {
        ...state,
        updateInvitedDirector: { ...state.updateInvitedDirector, loading: true },
      };
    case UPDATE_INVITED_DIRECTOR_SUCCESS:
      return {
        ...state,
        updateInvitedDirector: {
          ...state.updateInvitedDirector,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_INVITED_DIRECTOR_ERROR:
      return {
        ...state,
        updateInvitedDirector: {
          ...state.updateInvitedDirector,
          loading: false,
          error: action.error,
        },
      };

    case GET_INVITED_DIRECTORS_REQUEST:
      return {
        ...state,
        getInvitedDirectors: { ...state.getInvitedDirectors, loading: true },
      };
    case GET_INVITED_DIRECTORS_SUCCESS:
      return {
        ...state,
        getInvitedDirectors: {
          ...state.getInvitedDirectors,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_INVITED_DIRECTORS_ERROR:
      return {
        ...state,
        getInvitedDirectors: {
          ...state.getInvitedDirectors,
          loading: false,
          error: action.error,
        },
      };

    case UPLOAD_DIRECTOR_INFO_REQUEST:
      return {
        ...state,
        uploadDirectorInfo: { ...state.uploadDirectorInfo, loading: true },
      };
    case UPLOAD_DIRECTOR_INFO_SUCCESS:
      return {
        ...state,
        uploadDirectorInfo: {
          ...state.uploadDirectorInfo,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPLOAD_DIRECTOR_INFO_ERROR:
      return {
        ...state,
        uploadDirectorInfo: {
          ...state.uploadDirectorInfo,
          loading: false,
          error: action.error,
        },
      };

    case UPLOAD_INVITED_DIRECTOR_INFO_REQUEST:
      return {
        ...state,
        uploadInvitedDirectorInfo: { ...state.uploadInvitedDirectorInfo, loading: true },
      };
    case UPLOAD_INVITED_DIRECTOR_INFO_SUCCESS:
      return {
        ...state,
        uploadInvitedDirectorInfo: {
          ...state.uploadInvitedDirectorInfo,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPLOAD_INVITED_DIRECTOR_INFO_ERROR:
      return {
        ...state,
        uploadInvitedDirectorInfo: {
          ...state.uploadInvitedDirectorInfo,
          loading: false,
          error: action.error,
        },
      };

    case SEND_MONO_AUTH_CODE_REQUEST:
      return {
        ...state,
        sendMonoAuthCode: { ...state.sendMonoAuthCode, loading: true },
      };
    case SEND_MONO_AUTH_CODE_SUCCESS:
      return {
        ...state,
        sendMonoAuthCode: {
          ...state.sendMonoAuthCode,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SEND_MONO_AUTH_CODE_ERROR:
      return {
        ...state,
        sendMonoAuthCode: {
          ...state.sendMonoAuthCode,
          loading: false,
          error: action.error,
        },
      };

    case GET_MONO_PAYMENT_LINK_REQUEST:
      return {
        ...state,
        getPaymentLink: { ...state.getPaymentLink, loading: true },
      };
    case GET_MONO_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        getPaymentLink: {
          ...state.getPaymentLink,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_MONO_PAYMENT_LINK_ERROR:
      return {
        ...state,
        getPaymentLink: {
          ...state.getPaymentLink,
          loading: false,
          error: action.error,
        },
      };

    case VERIFY_MONO_PAYMENT_REFERENCE_REQUEST:
      return {
        ...state,
        verifyPaymentReference: { ...state.verifyPaymentReference, loading: true },
      };
    case VERIFY_MONO_PAYMENT_REFERENCE_SUCCESS:
      return {
        ...state,
        verifyPaymentReference: {
          ...state.verifyPaymentReference,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VERIFY_MONO_PAYMENT_REFERENCE_ERROR:
      return {
        ...state,
        verifyPaymentReference: {
          ...state.verifyPaymentReference,
          loading: false,
          error: action.error,
        },
      };

    case MONO_ACCOUNT_REAUTHORIZATION_CODE_REQUEST:
      return {
        ...state,
        getAccountReauthorizationCode: {
          ...state.getAccountReauthorizationCode,
          loading: true,
        },
      };
    case MONO_ACCOUNT_REAUTHORIZATION_CODE_SUCCESS:
      return {
        ...state,
        getAccountReauthorizationCode: {
          ...state.getAccountReauthorizationCode,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case MONO_ACCOUNT_REAUTHORIZATION_CODE_ERROR:
      return {
        ...state,
        getAccountReauthorizationCode: {
          ...state.getAccountReauthorizationCode,
          loading: false,
          error: action.error,
        },
      };

    case ADD_FUNDS_REQUEST:
      return {
        ...state,
        addFundsToAccount: {
          ...state.addFundsToAccount,
          loading: true,
        },
      };
    case ADD_FUNDS_SUCCESS:
      return {
        ...state,
        addFundsToAccount: {
          ...state.addFundsToAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_FUNDS_ERROR:
      return {
        ...state,
        addFundsToAccount: {
          ...state.addFundsToAccount,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_MANDATE_REQUEST:
      return {
        ...state,
        createMandate: {
          ...state.createMandate,
          loading: true,
        },
      };
    case CREATE_MANDATE_SUCCESS:
      return {
        ...state,
        createMandate: {
          ...state.createMandate,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_MANDATE_ERROR:
      return {
        ...state,
        createMandate: {
          ...state.createMandate,
          loading: false,
          error: action.error,
        },
      };

    case CANCEL_MANDATE_REQUEST:
      return {
        ...state,
        cancelMandate: {
          ...state.cancelMandate,
          loading: true,
        },
      };
    case CANCEL_MANDATE_SUCCESS:
      return {
        ...state,
        cancelMandate: {
          ...state.cancelMandate,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CANCEL_MANDATE_ERROR:
      return {
        ...state,
        cancelMandate: {
          ...state.cancelMandate,
          loading: false,
          error: action.error,
        },
      };

    case GET_MANDATE_STATUS_REQUEST:
      return {
        ...state,
        getMandateStatus: {
          ...state.getMandateStatus,
          loading: true,
        },
      };

    case GET_MANDATE_STATUS_SUCCESS:
      return {
        ...state,
        getMandateStatus: {
          ...state.getMandateStatus,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_MANDATE_STATUS_ERROR:
      return {
        ...state,
        getMandateStatus: {
          ...state.getMandateStatus,
          loading: false,
          error: action.error,
        },
      };

    case UPLOAD_ONBOARDING_DOCUMENTS_REQUEST:
      return {
        ...state,
        uploadOnboardingDocuments: {
          ...state.uploadOnboardingDocuments,
          loading: true,
        },
      };

    case UPLOAD_ONBOARDING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadOnboardingDocuments: {
          ...state.uploadOnboardingDocuments,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPLOAD_ONBOARDING_DOCUMENTS_ERROR:
      return {
        ...state,
        uploadOnboardingDocuments: {
          ...state.uploadOnboardingDocuments,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_COMPANY:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_COMPANY:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    case GET_COMPANIES_REQUEST:
      return { ...state, getCompanies: { ...state.getCompanies, loading: true } };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        getCompanies: {
          ...state.getCompanies,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        getCompanies: { ...state.getCompanies, loading: false, error: action.error },
      };

    case ADD_COMPANY_REQUEST:
      return { ...state, addEntity: { ...state.addEntity, loading: true } };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        addEntity: {
          ...state.addEntity,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_COMPANY_ERROR:
      return {
        ...state,
        addEntity: { ...state.addEntity, loading: false, error: action.error },
      };

    case SWITCH_COMPANY_REQUEST:
      return { ...state, switchCompany: { ...state.switchCompany, loading: true } };
    case SWITCH_COMPANY_SUCCESS:
      return {
        ...state,
        switchCompany: {
          ...state.switchCompany,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SWITCH_COMPANY_ERROR:
      return {
        ...state,
        switchCompany: { ...state.switchCompany, loading: false, error: action.error },
      };

    case AUTHENTICATE_COMPANY_REQUEST:
      return { ...state, authenticate: { ...state.authenticate, loading: true } };
    case AUTHENTICATE_COMPANY_SUCCESS:
      return {
        ...state,
        authenticate: {
          ...state.authenticate,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case AUTHENTICATE_COMPANY_ERROR:
      return {
        ...state,
        authenticate: { ...state.authenticate, loading: false, error: action.error },
      };

    default:
      return state;
  }
};
