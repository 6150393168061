import {
  FETCH_SETTLEMENT_ACCOUNTS_REQUEST,
  DELETE_SETTLEMENT_ACCOUNT_REQUEST,
  CREATE_SETTLEMENT_ACCOUNT_REQUEST,
  VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST,
  VERIFY_ACCOUNT_REQUEST,
} from '../reducers/SettlementAccount';

export const fetchAllSettlementAccounts = (payload) => ({
  type: FETCH_SETTLEMENT_ACCOUNTS_REQUEST,
  payload,
});

export const addSettlementAccount = (payload) => ({
  type: CREATE_SETTLEMENT_ACCOUNT_REQUEST,
  payload,
});

export const deleteSettlementAccount = (payload) => ({
  type: DELETE_SETTLEMENT_ACCOUNT_REQUEST,
  payload,
});

export const verifyAccountOtp = (payload) => ({
  type: VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST,
  payload,
});

export const verifyAccountAction = (payload) => ({
  type: VERIFY_ACCOUNT_REQUEST,
  payload,
});
