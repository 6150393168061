import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSingleRequest,
  requestMoreInfo,
  updateRequests,
} from 'redux/actions/RequestsAction';
import './styles.scss';
const RequestMoreDetails = ({
  setStep,
  hideHeader,
  selectRequest,
  kebabAction = false,
  onClose,
}) => {
  if (!selectRequest) return <div />;

  const [value, setValue] = useState('');

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const {
    requestMoreInfo: { loading, success },
    updateRequest: { loading: updateLoading, success: updateSuccess },
  } = useSelector(({ requests }) => requests);

  const { code = '', moreInfoLogs, description } = selectRequest || {};

  const isOwner = user?.code === selectRequest?.user?.code;

  const handleSubmit = () => {
    if (!value) return toastError('Enter infomation');

    const payload = {
      note: value,
      code,
    };
    if (isOwner) {
      dispatch(updateRequests({ code, description: value }));
    } else {
      dispatch(requestMoreInfo(payload));
    }
  };

  useEffect(() => {
    if (isOwner) setValue(description);

    return () => {
      setValue('');
    };
  }, []);

  const closeHandler = () => {
    if (onClose) onClose();
    if (hideHeader) hideHeader(false);
    if (setStep) setStep(1);
    setValue('');
  };

  useEffect(() => {
    if (success || updateSuccess) {
      closeHandler();
      if (code) dispatch(getSingleRequest(code));
    }
  }, [success, updateSuccess]);

  return (
    <div>
      <div className="card-modal-header">
        <div className="d-flex align-items-center cursor" onClick={closeHandler}>
          {kebabAction ? <CloseOutlined /> : <ArrowLeftOutlined />}
          <span className="ps-1">{kebabAction ? 'Close' : 'Back'}</span>
        </div>
      </div>
      <div className="request-more-wrapper">
        <div className="information-wrapper bg-white title-padding">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="color-grey-900 text-ds-xs font-semibold">
              Request more details
            </h2>
          </div>
        </div>
        <div className="py-4 details-wrap">
          {moreInfoLogs?.map((item, key) => (
            <div className="border-bottom details mb-3" key={key}>
              <h6>Details</h6>
              <p>{item?.info}</p>
            </div>
          ))}

          <Row className="my-3 details">
            <CustomTextarea
              row={5}
              label="Enter Information"
              name="reason"
              onChange={handleValueChange}
              value={value}
              placeholder="Enter Information"
            />
          </Row>
        </div>
      </div>
      <div className="card-modal-footer justify-content-end">
        <CustomButton
          onClick={closeHandler}
          className="base-button text-sm font-medium  black-transparent"
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          loading={loading || updateLoading}
          disabled={loading || updateLoading}
          className="base-button text-sm font-medium dark-button"
        >
          Send
        </CustomButton>
      </div>
    </div>
  );
};

export default RequestMoreDetails;
