import Table from 'components/Table';
import 'jspdf-autotable';
import { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getTransaction } from 'redux/actions/TransactionsAction';
import { profileTransactionColumns, spendTranscationColumns } from 'utils/mockData';
import { buildProfileTransactionTableData } from '../../../utils/helper';
import NoData from './NoData';
import TableLoading from './TableLoading';
import { getSpendTransactions } from 'redux/actions/SpendAction';

const TransactionTable = ({ id, type, userName, isCustomer = false }) => {
  const dispatch = useDispatch();

  const {
    getTransaction: {
      data: { meta = {}, transactions: fetchedTransactions = [] } = {},
      loading: fetchGenericTransactions,
    },
  } = useSelector(({ transaction }) => transaction);
  const {
    getSpendTransactions: {
      data: spendTranscations = [],
      loading: loadingSpendTransactions,
    },
  } = useSelector(({ spends }) => spends);
  const { page, total, hasMore, perPage, nextPage } = meta;

  const transactions = type !== 'spend' ? fetchedTransactions : spendTranscations;
  const loading = fetchGenericTransactions || loadingSpendTransactions;

  const rows = useMemo(
    () => buildProfileTransactionTableData(transactions, type),
    [transactions, type],
  );

  const handlePagination = (page) => {
    if (type === 'spend') dispatch(getSpendTransactions({ code: id }));
    else dispatch(getTransaction({ [type]: [id], perPage, page }));
  };

  const tableColumn = useMemo(() => {
    if (type && type === 'spend') return spendTranscationColumns;
    return profileTransactionColumns.filter((option) => {
      if (type === 'vendor') {
        return option.accessor !== 'to';
      } else if (type === 'payer') {
        return option.accessor !== 'by';
      } else return !!option.accessor;
    });
  }, [transactions, type]);

  useEffect(() => {
    if (!isCustomer) {
      if (type === 'spend') dispatch(getSpendTransactions({ code: id }));
      else dispatch(getTransaction({ [type]: [id] }));
    }
  }, []);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  return (
    <>
      {!loading && !transactions.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No transaction"
            bodyText={`Haven't made any payments yet`}
            withButton={true}
            buttonLabel={
              <span className="d-flex justify-content-center">Show all Invoices</span>
            }
            // onClickHandler={clearLocationState}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handlePagination(nextPage)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default TransactionTable;
