import { all, put, takeEvery } from 'redux-saga/effects';
import {
  TOGGLE_EXPENSE_REQUEST,
  TOGGLE_EXPENSE_SUCCESS,
} from 'redux/reducers/ToggleExpenseReducer';

function* toggleExpense(payload) {
  yield put({
    type: TOGGLE_EXPENSE_SUCCESS,
    payload,
  });
}

export default all([takeEvery(TOGGLE_EXPENSE_REQUEST, toggleExpense)]);
