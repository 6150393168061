import { InfoCircleIcon } from 'assets/icons';
import classNames from 'classnames';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { createCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { capitalizeFirstLetter, flattenCategories } from 'utils/helper';
import { Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';

const createOption = (label) => ({
  label,
  value: label,
});

const ConditionRowComponent = ({
  rules,
  setConditionName,
  removeCondition,
  getOperatorValue,
  getAmount,
  getOperand,
  getInputChange,
}) => {
  const {
    createCategories: { data: { category } = {}, success },
    fetchCategories: { success: isCatSuccess, data: { categories: fetchCat = [] } = {} },
  } = useSelector(({ categories }) => categories);

  const {
    getBudget: { loading: loadingBudget },
  } = useSelector(({ budgets }) => budgets);

  const [newCategory, setNewCategory] = useState({
    val: { value: '', label: '', code: '' },
    index: '',
    type: '',
  });

  const [createable, setCreateable] = useState({
    isLoading: false,
    options: [],
    value: undefined,
  });
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const data = flattenCategories(fetchCat);
    const opt = data?.map(({ name: label, name: value, code }) => ({
      label,
      value,
      code,
    }));

    if (opt) setOptions(opt);
  }, [isCatSuccess, location?.state?.operator]);

  useEffect(() => {
    setCreateable({ ...createable, options: options });
  }, [options]);

  // Handle Createable
  const handleCreate = (inputValue, index, type) => {
    setCreateable({ isLoading: true });
    setTimeout(() => {
      const { options } = createable;
      const newOption = createOption(inputValue);

      setCreateable({
        ...createable,
        value: newOption,
        isLoading: false,
      });
      const data = {
        name: inputValue,
      };
      dispatch(createCategories(data));

      setNewCategory({ val: { value: inputValue, label: inputValue }, index, type });
    }, 500);
  };

  const getSelectedCategory = (value, index, type) => {
    if (!value)
      return (
        setCreateable({ ...createable, value: undefined }),
        setNewCategory({ val: { value: value?.value, label: value?.label }, index, type })
      );

    setCreateable({ ...createable, value }), getOperand(value, index, type);
  };

  const handleActionType = (val, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        val = createable.options.filter((v) => v.isFixed);
        break;
    }
  };

  useEffect(() => {
    if (success) {
      dispatch(fetchCategories());
      getOperand(
        { ...newCategory.val, code: category.code },
        newCategory.index,
        newCategory.type,
      );
      setCreateable({
        ...createable,
        options: [...options, { ...newCategory.val, code: category.code }],
      });
      dispatch({ type: 'RESET_BLOCK_CATEGORY', blockType: 'createCategories' });
    }
  }, [success]);
  return (
    <>
      <span className="conditions-label">{capitalizeFirstLetter(rules?.[0]?.type)}</span>
      {rules?.map((rule, index) => {
        const isMultiple =
          rule?.['condition-two']?.selectedValue?.value?.indexOf('any') >= 0;
        return (
          <div className={classNames({ 'mt-3': index !== 0 })} key={index}>
            {index !== 0 && (
              <div className="conditions-label d-flex justify-content-between align-items-center">
                <span>{capitalizeFirstLetter(rule.type)}</span>
                <span
                  className="conditions-labelRemove-text"
                  onClick={() => removeCondition(index, 'rules')}
                >
                  Remove
                </span>
              </div>
            )}

            <div
              className={classNames('d-flex gap-3 align-items-center position-relative', {
                ['flex-wrap']: isMultiple,
              })}
            >
              <div className={classNames('approval-condition', { ['w-50']: isMultiple })}>
                <CustomSelect
                  name={rule.name}
                  placeholder={'Select'}
                  options={rule?.['condition-one'].options}
                  value={rule?.['condition-one'].selectedValue}
                  defaultValue={rule?.['condition-one'].selectedValue}
                  onChange={(val) => setConditionName(val, index)}
                  isDisabled={
                    location?.state?.trigger &&
                    location?.state?.trigger ===
                      rule?.['condition-one'].selectedValue?.value
                  }
                />
              </div>

              <div className={classNames('approval-condition', { ['w-50']: isMultiple })}>
                <CustomSelect
                  name={rule.name}
                  placeholder="condition"
                  options={rule?.['condition-two'].options}
                  value={rule?.['condition-two'].selectedValue}
                  defaultValue={rule?.['condition-two'].selectedValue}
                  onChange={(val) => getOperatorValue(val, index, 'two')}
                />
              </div>

              <div className={classNames('approval-condition', { 'w-100': isMultiple })}>
                {rule?.name === 'amount' ? (
                  <CustomInput
                    name={rule?.name}
                    placeholder="0.00"
                    type="number"
                    value={rule?.['condition-three'].amount}
                    isAmount
                    onChange={(event) => getAmount(event, index)}
                  />
                ) : rule?.name === 'category' ? (
                  <CustomSelect
                    name="condition"
                    createable
                    isMulti={isMultiple}
                    placeholder="condition"
                    defaultValue={rule?.['condition-three'].selectedValue}
                    noOptionsMessage={() => null}
                    isDisabled={
                      createable.isLoading ||
                      (location?.state?.trigger &&
                        !isMultiple &&
                        location?.state?.operands?.[index]?.[location?.state?.trigger]
                          ?.name === rule?.['condition-three'].selectedValue?.[0]?.value)
                    }
                    isLoading={createable.isLoading}
                    options={createable.options ?? rule?.['condition-three'].options}
                    value={createable.value ?? rule?.['condition-three'].selectedValue}
                    onCreateOption={(val) => handleCreate(val, index, 'three')}
                    onChange={(val, actionMeta) => {
                      handleActionType(val, actionMeta);
                      getSelectedCategory(val, index, 'three');
                    }}
                  />
                ) : (
                  <CustomSelect
                    isMulti={isMultiple}
                    name="condition"
                    placeholder="condition"
                    options={rule?.['condition-three'].options}
                    value={rule?.['condition-three'].selectedValue}
                    defaultValue={rule?.['condition-three'].selectedValue}
                    onChange={(val) => getOperand(val, index, 'three')}
                    onInputChange={(value) => getInputChange(value, rule?.name)}
                    isLoading={loadingBudget}
                  />
                )}
              </div>

              <Tooltip
                placement="right"
                title={
                  <div className="text-xs py-2 px-2">
                    <span className="fw-bold">How do approval rules work?</span>
                    <div className="fw-normal mt-2">
                      Approval rules can be set based on various conditions such as
                      transaction type, amount, budget, category, or vendor.
                    </div>
                  </div>
                }
                color={'#000'}
              >
                <div className={`information-icon ${index === 0 ? '' : 'invisible'}`}>
                  <InfoCircleIcon stroke="#A0A09E" />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ConditionRowComponent;
