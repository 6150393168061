import { Skeleton } from 'antd';

const DocumentsLoading = () => {
  return (
    <div>
      {Array.from({ length: 2 }).map((_, index) => {
        return (
          <Skeleton.Input
            active
            key={index}
            size={80}
            block={true}
            style={{
              width: '100%',
              marginBottom: `${index === 1 ? '' : '15px'}`,
              borderRadius: '10px',
            }}
          />
        );
      })}
    </div>
  );
};
export default DocumentsLoading;
