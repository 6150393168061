import { Skeleton } from 'antd';
import { ArrowLeft, BuildingIcon, ListIcon, User3Icon } from 'assets/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BusinessKycTabs from '../components/BusinessKycTabs';
import BusinessKycTabsView from '../components/BusinessKycTabView';
import '../styles.scss';
import AdditionalDetails from './AdditionalDetails';
import BusinessDetails from './BusinessDetails';
import BusinessOwner from './BusinessOwner';

const tabs = [
  { name: 'Business details', icon: <BuildingIcon />, key: 'businessDetails' },
  { name: 'Business owners', icon: <User3Icon />, key: 'businessOwner' },
  { name: 'Additional details', icon: <ListIcon />, key: 'additionalDetails' },
];

const getActiveStep = (steps) => {
  if (!steps?.businessDetails?.completed) return 'businessDetails';
  if (!steps?.businessOwners?.completed) return 'businessOwner';
  if (!steps?.additionalDetails?.completed) return 'additionalDetails';
  return 'businessDetails';
};

const BusinessKyc = () => {
  const { push } = useHistory();
  const [mounted, setMounted] = useState(false);

  const [key, setKey] = useState('businessDetails');

  const {
    getCompany: { loading: loadingCompany, data: companyData },
  } = useSelector(({ companies }) => companies);
  const activeIndex = tabs?.findIndex((tab) => tab?.key === key);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
  };

  useEffect(() => {
    if (companyData?.code && !loadingCompany) {
      setMounted(true);
      setKey(getActiveStep(companyData?.onboardingSteps));
    }
  }, [companyData?.code]);

  const components = useMemo(
    () => [
      <BusinessDetails key="businessDetails" />,
      <BusinessOwner key="businessOwner" />,
      <AdditionalDetails key="additionalDetails" />,
    ],
    [],
  );

  return (
    <section className="onboarding-section">
      <div className="business-kyc-section max-w mx-auto">
        <div className="back-btn" onClick={() => push('/get-started')}>
          <ArrowLeft color="#D28B28" /> <div>Back</div>
        </div>
        <div className="business-kyc-title">
          <h1>Complete Business KYC</h1>
          <p>
            Get ready to use Bujeti by verifying a few organisational details about your
            company.
          </p>
        </div>
        {!mounted && loadingCompany ? (
          <LoadingSkeleton />
        ) : (
          <div className="forms-tab-wrapper">
            <div>
              <BusinessKycTabs tabs={tabs} active={key} onSelect={handleSelect} />

              <p className="text-xs mt-4" style={{ width: ' 242px', color: '#79716B' }}>
                Your documents are stored securely and in compliance with NDPR
                regulations.
                <br />
                <br />
                Your progress is automatically saved.
              </p>
            </div>

            <div
              className="business-tab-view overview-holder"
              style={{ height: `calc(100vh - 200px)` }}
            >
              <BusinessKycTabsView index={activeIndex} tabs={components} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BusinessKyc;

const LoadingSkeleton = () => {
  return (
    <div className="forms-tab-wrapper">
      <div>
        <div className="mb-3 mt-3 grid grid-col-2 gap-2 align-items-center">
          <Skeleton.Input
            active
            className="rounded-2"
            style={{ width: ' 242px' }}
            size={42}
          />
        </div>

        <div className="mb-3">
          <Skeleton.Input
            active
            className="rounded-2"
            style={{ width: ' 242px' }}
            size={42}
          />
        </div>
        <div className="mb-3">
          <Skeleton.Input
            active
            className="rounded-2"
            style={{ width: ' 242px' }}
            size={42}
          />
        </div>

        <div className="mb-4">
          <Skeleton active style={{ width: ' 242px' }} paragraph={{ rows: 1 }} />
        </div>
      </div>

      <div className="business-tab-view">
        <div className="mb-3 mt-3 pb-3 border-bottom">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </div>

        <div className="mb-3 border-bottom pb-3">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </div>
        <div className="mb-3 pb-3">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </div>
      </div>
    </div>
  );
};
