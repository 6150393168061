export const GET_REQUESTS_REQUEST = 'GET_REQUESTS_REQUEST';
import { reviewMultipleRequests } from 'redux/actions/ApprovalAction';
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const GET_REQUESTS_ERROR = 'GET_REQUESTS_ERROR';

export const GET_SELECTED_REQUESTS_REQUEST = 'GET_SELECTED_REQUESTS_REQUEST';
export const GET_SELECTED_REQUESTS_SUCCESS = 'GET_SELECTED_REQUESTS_SUCCESS';
export const GET_SELECTED_REQUESTS_ERROR = 'GET_SELECTED_REQUESTS_ERROR';

export const CREATE_REQUESTS_REQUEST = 'CREATE_REQUESTS_REQUEST';
export const CREATE_REQUESTS_SUCCESS = 'CREATE_REQUESTS_SUCCESS';
export const CREATE_REQUESTS_ERROR = 'CREATE_REQUESTS_ERROR';

export const UPDATE_REQUESTS_REQUEST = 'UPDATE_REQUESTS_REQUEST';
export const UPDATE_REQUESTS_SUCCESS = 'UPDATE_REQUESTS_SUCCESS';
export const UPDATE_REQUESTS_ERROR = 'UPDATE_REQUESTS_ERROR';

export const REQUEST_MORE_INFO_REQUEST = 'REQUEST_MORE_INFO_REQUEST';
export const REQUEST_MORE_INFO_SUCCESS = 'REQUEST_MORE_INFO_SUCCESS';
export const REQUEST_MORE_INFO_ERROR = 'REQUEST_MORE_INFO_ERROR';

export const DELETE_REQUESTS_REQUEST = 'DELETE_REQUESTS_REQUEST';
export const DELETE_REQUESTS_SUCCESS = 'DELETE_REQUESTS_SUCCESS';
export const DELETE_REQUESTS_ERROR = 'DELETE_REQUESTS_ERROR';

export const DECLINE_REQUESTS_REQUEST = 'DECLINE_REQUESTS_REQUEST';
export const DECLINE_REQUESTS_SUCCESS = 'DECLINE_REQUESTS_SUCCESS';
export const DECLINE_REQUESTS_ERROR = 'DECLINE_REQUESTS_ERROR';

export const APPROVE_REQUESTS_REQUEST = 'APPROVE_REQUESTS_REQUEST';
export const APPROVE_REQUESTS_SUCCESS = 'APPROVE_REQUESTS_SUCCESS';
export const APPROVE_REQUESTS_ERROR = 'APPROVE_REQUESTS_ERROR';

export const GET_SINGLE_REQUESTS_REQUEST = 'GET_SINGLE_REQUESTS_REQUEST';
export const GET_SINGLE_REQUESTS_SUCCESS = 'GET_SINGLE_REQUESTS_SUCCESS';
export const GET_SINGLE_REQUESTS_ERROR = 'GET_SINGLE_REQUESTS_ERROR';

export const GET_ALL_REQUESTS_ASSET = 'GET_ALL_REQUESTS_ASSET';
export const GET_ALL_REQUESTS_ASSET_SUCCESS = 'GET_ALL_REQUESTS_ASSET_SUCCESS';
export const GET_ALL_REQUESTS_ASSET_ERROR = 'GET_ALL_REQUESTS_ASSET_ERROR';

export const REVIEW_MULTIPLE_REQUESTS_REQUEST = 'REVIEW_MULTIPLE_REQUESTS_REQUEST';
export const REVIEW_MULTIPLE_REQUESTS_SUCCESS = 'REVIEW_MULTIPLE_REQUESTS_SUCCESS';
export const REVIEW_MULTIPLE_REQUESTS_ERROR = 'REVIEW_MULTIPLE_REQUESTS_ERROR';

export const DELETE_MULTIPLE_REQUESTS_REQUEST = 'DELETE_MULTIPLE_REQUESTS_REQUEST';
export const DELETE_MULTIPLE_REQUESTS_SUCCESS = 'DELETE_MULTIPLE_REQUESTS_SUCCESS';
export const DELETE_MULTIPLE_REQUESTS_ERROR = 'DELETE_MULTIPLE_REQUESTS_ERROR';

export const RESET_BLOCK_REQUESTS = 'RESET_BLOCK_REQUESTS';

export const RESET_FLAGS_REQUESTS = 'RESET_FLAGS_REQUESTS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getRequest: { ...block },
  getSelectedRequest: { ...block },
  approveRequest: { ...block },
  declineRequest: { ...block },
  deleteRequest: { ...block },
  createRequest: { ...block },
  updateRequest: { ...block },
  getSingleRequest: { ...block },
  requestReceipts: { ...block },
  requestMoreInfo: { ...block },
  reviewMultipleRequests: { ...block },
  deleteMultipleRequests: { ...block },
};

export const RequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTS_REQUEST:
      return { ...state, getRequest: { ...state.getRequest, loading: true } };
    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        getRequest: {
          ...state.getRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REQUESTS_ERROR:
      return {
        ...state,
        getRequest: {
          ...state.getRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_SELECTED_REQUESTS_REQUEST:
      return {
        ...state,
        getSelectedRequest: { ...state.getSelectedRequest, loading: true },
      };
    case GET_SELECTED_REQUESTS_SUCCESS:
      return {
        ...state,
        getSelectedRequest: {
          ...state.getSelectedRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SELECTED_REQUESTS_ERROR:
      return {
        ...state,
        getSelectedRequest: {
          ...state.getSelectedRequest,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_REQUESTS_REQUEST:
      return {
        ...state,
        createRequest: { ...state.createRequest, loading: true },
      };
    case CREATE_REQUESTS_SUCCESS:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_REQUESTS_ERROR:
      return {
        ...state,
        createRequest: {
          ...state.createRequest,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_REQUESTS_REQUEST:
      return {
        ...state,
        updateRequest: { ...state.updateRequest, loading: true },
      };
    case UPDATE_REQUESTS_SUCCESS:
      return {
        ...state,
        updateRequest: {
          ...state.updateRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_REQUESTS_ERROR:
      return {
        ...state,
        updateRequest: {
          ...state.updateRequest,
          loading: false,
          error: action.error,
        },
      };

    case REQUEST_MORE_INFO_REQUEST:
      return {
        ...state,
        requestMoreInfo: { ...state.requestMoreInfo, loading: true },
      };
    case REQUEST_MORE_INFO_SUCCESS:
      return {
        ...state,
        requestMoreInfo: {
          ...state.requestMoreInfo,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REQUEST_MORE_INFO_ERROR:
      return {
        ...state,
        requestMoreInfo: {
          ...state.requestMoreInfo,
          loading: false,
          error: action.error,
        },
      };

    case APPROVE_REQUESTS_REQUEST:
      return {
        ...state,
        approveRequest: { ...state.approveRequest, loading: true },
      };
    case APPROVE_REQUESTS_SUCCESS:
      return {
        ...state,
        approveRequest: {
          ...state.approveRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case APPROVE_REQUESTS_ERROR:
      return {
        ...state,
        approveRequest: {
          ...state.approveRequest,
          loading: false,
          error: action.error,
        },
      };

    case DECLINE_REQUESTS_REQUEST:
      return {
        ...state,
        declineRequest: { ...state.declineRequest, loading: true },
      };
    case DECLINE_REQUESTS_SUCCESS:
      return {
        ...state,
        declineRequest: {
          ...state.declineRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DECLINE_REQUESTS_ERROR:
      return {
        ...state,
        declineRequest: {
          ...state.declineRequest,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_REQUESTS_REQUEST:
      return {
        ...state,
        deleteRequest: { ...state.deleteRequest, loading: true },
      };
    case DELETE_REQUESTS_SUCCESS:
      return {
        ...state,
        deleteRequest: {
          ...state.deleteRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_REQUESTS_ERROR:
      return {
        ...state,
        deleteRequest: {
          ...state.deleteRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_REQUESTS_REQUEST:
      return {
        ...state,
        getSingleRequest: { ...state.getSingleRequest, loading: true },
      };
    case GET_SINGLE_REQUESTS_SUCCESS:
      return {
        ...state,
        getSingleRequest: {
          ...state.getSingleRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_REQUESTS_ERROR:
      return {
        ...state,
        getSingleRequest: {
          ...state.getSingleRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_ALL_REQUESTS_ASSET:
      return {
        ...state,
        requestReceipts: { ...state.requestReceipts, loading: true },
      };
    case GET_ALL_REQUESTS_ASSET_SUCCESS:
      return {
        ...state,
        requestReceipts: {
          ...state.requestReceipts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ALL_REQUESTS_ASSET_ERROR:
      return {
        ...state,
        requestReceipts: {
          ...state.requestReceipts,
          loading: false,
          error: action.error,
        },
      };

    case REVIEW_MULTIPLE_REQUESTS_REQUEST:
      return {
        ...state,
        reviewMultipleRequests: {
          ...state.reviewMultipleRequests,
          loading: true,
        },
      };
    case REVIEW_MULTIPLE_REQUESTS_SUCCESS:
      return {
        ...state,
        reviewMultipleRequests: {
          ...state.reviewMultipleRequests,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_MULTIPLE_REQUESTS_ERROR:
      return {
        ...state,
        reviewMultipleRequests: {
          ...state.reviewMultipleRequests,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_MULTIPLE_REQUESTS_REQUEST:
      return {
        ...state,
        deleteMultipleRequests: {
          ...state.deleteMultipleRequests,
          loading: true,
        },
      };
    case DELETE_MULTIPLE_REQUESTS_SUCCESS:
      return {
        ...state,
        deleteMultipleRequests: {
          ...state.deleteMultipleRequests,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_MULTIPLE_REQUESTS_ERROR:
      return {
        ...state,
        deleteMultipleRequests: {
          ...state.deleteMultipleRequests,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_REQUESTS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_REQUESTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
