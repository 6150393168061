export const AUTOMATE_REQUEST = 'AUTOMATE_REQUEST';
export const AUTOMATE_SUCCESS = 'AUTOMATE_SUCCESS';

const initialState = {
  automate: false,
  payload: null,
};

export const AutomationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTOMATE_SUCCESS:
      return {
        ...state,
        automate: !state.automate,
        payload: action?.payload,
      };
    default:
      return state;
  }
};
