import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import ConfirmDialog from 'components/ConfirmDialog';
import CustomButton from 'components/UI/CustomButton';
import CustomTextarea from 'components/UI/CustomTextarea';
import React from 'react';
import { Row } from 'react-bootstrap';

const BatchPaymentDialog = ({
  icon,
  title,
  description,
  amount,
  fee,
  total,
  type = 'confirm',
  onCancel,
  onClick,
  onNoteChanged,
  disabled,
  balance,
  lowBalance = false,
  addNote = false,
  note = '',
  loading = false,
}) => {
  return (
    <ConfirmDialog
      // onConfirm={handleDeleteClick}
      // loading={deleteLoading}
      onCancel={onCancel}
    >
      <span className="w-100">{icon}</span>

      <div className="my-4 w-100 heading-confirm">
        <h5>{title}</h5>
        <h6>{description}</h6>
      </div>

      {type === 'confirm' ? (
        <div className="w-100 heading-confirm">
          <div className="d-flex justify-content-between">
            <h5>Amount</h5>
            <h6>{amount}</h6>
          </div>
          {fee && (
            <div className="d-flex justify-content-between">
              <h5>Fees</h5>
              <h6>{fee}</h6>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <h5>Total</h5>
            <h6>{total}</h6>
          </div>
          {balance && (
            <div className="d-flex justify-content-between">
              <Tooltip
                title={
                  'Balance available accross every accounts and/or budgets involved in this operation'
                }
              >
                <h5 style={{ textAlign: 'center', display: 'block' }}>
                  Available balance{' '}
                  <QuestionCircleOutlined
                    className="pointer-event cursor"
                    style={{ fontSize: '14px', color: '#89898A' }}
                  />
                </h5>
              </Tooltip>
              <h6>{balance}</h6>
            </div>
          )}
          {lowBalance && (
            <span style={{ color: 'red', fontSize: 12 }}>
              You do not have enough fund to carry out the action
            </span>
          )}

          {addNote && (
            <CustomTextarea
              row={5}
              label="Reason for declining"
              name="reason"
              onChange={({ target: { value } }) => onNoteChanged(value)}
              value={note}
              placeholder="Specify the reason for the decline"
            />
          )}
        </div>
      ) : (
        <div className="w-100">
          <Row className="mb-3">
            <CustomTextarea
              row={5}
              label="Reason for declining"
              name="reason"
              // onChange={handleValueChange}
              // value={value}
              placeholder="Specify the reason for the decline"
            />
          </Row>
        </div>
      )}

      <div className="mt-4 d-flex w-100 gap-3 pb-4">
        <CustomButton
          fullWidth
          className="border-btn text-sm"
          onClick={onCancel}
          disabled={disabled}
        >
          Cancel
        </CustomButton>

        <CustomButton
          fullWidth
          className="confirm-btn text-sm"
          disabled={disabled || lowBalance}
          onClick={onClick}
          loading={loading}
        >
          Confirm
        </CustomButton>
      </div>
    </ConfirmDialog>
  );
};

export default BatchPaymentDialog;
