import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from 'components/UIHooks/JsPDF';
import { formatNumThousandSeparator } from '../../utils/utility';
import Inter_Medium from './Inter/static/Inter-Medium.ttf';
import Inter_Regular from './Inter/static/Inter-Regular.ttf';
import Inter_SemiBold from './Inter/static/Inter-SemiBold.ttf';

// Register font
Font.register({
  family: 'Inter Variable',
  fonts: [
    { src: Inter_Regular }, // font-style: normal, font-weight: normal
    { src: Inter_Medium },
    { src: Inter_SemiBold },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Inter Variable',
    backgroundColor: '#fff',
    width: '100%',
    paddingBottom: 54,
    paddingTop: 16,
  },
  dFlex: {
    display: 'flex',
  },
  topSectionContainer: {},
  padding16: {
    padding: '16px',
  },
  headerSection: {
    borderBottom: '1px solid #F5F5F4',
    height: '64px',
    padding: 16,
    paddingTop: 0,
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexRow: {
    flexDirection: 'row',
  },
  rightAlign: {
    float: 'right',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  textStyle: {
    fontSize: '10px',
    fontWeight: '400',
    color: '#1C1917',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  tableSection: {
    flexDirection: 'row',
    marginTop: '16px',
  },
  section: {
    padding: '0 16px 16px 16px',
    flexGrow: 1,
  },
  header: {
    fontWeight: 'bold',
    backgroundColor: '#F5F5F4',
    fontSize: '10px',
    color: '#79716B',
    fontWeight: '500',
    padding: '8px',
    borderRadius: '6px',
    height: 34,
    marginBottom: '13px',
    marginTop: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  cell: {},
});

const DownloadPdfStatement = ({
  querys,
  source,
  companyData,
  data,
  tableData,
  tableColumns,
  summary,
  handleButtonToggle,
}) => {
  const currencySign = summary?.currency || 'NGN';
  const { street, country, city, state } = companyData?.address ?? {};
  const filteredArray = [street, city, state, country].filter((item) => !!item);
  const companyAddress = filteredArray.join(', ');

  return (
    <Document style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
      <Page style={styles.page}>
        <View style={[styles.rowStyle, styles.headerSection, styles.alignItemsCenter]}>
          <Text
            style={{
              fontSize: 24,
              fontWeight: 600,
              color: '#1C1917',
            }}
          >
            Account Statement
          </Text>
          <Image
            src={require('../../assets/logos/blackLogo.png')}
            style={{ width: 77, height: 32 }}
          />
        </View>
        <View style={[styles.padding16, styles.rowStyle, styles.alignItemsStart]}>
          <View style={{ minWidth: '159px', maxWidth: '260px' }}>
            <Text
              style={{
                fontSize: '12px',
                fontWeight: '600',
                color: '#1C1917',
                marginBottom: '8px',
              }}
            >
              {companyData?.name ?? ''}
            </Text>
            <Text style={{ ...styles.textStyle, marginBottom: '8px' }}>
              {companyAddress}
            </Text>
            <Text style={{ ...styles.textStyle, marginBottom: '8px' }}>
              <Text style={{ color: '#79716B' }}>RC number:</Text>{' '}
              <Text style={{ fontWeight: '500' }}>{summary?.RCNumber ?? ''}</Text>
            </Text>
            <Text style={{ ...styles.textStyle, marginBottom: '8px' }}>
              <Text style={{ color: '#79716B' }}>From:</Text>{' '}
              <Text style={{ fontWeight: '500' }}>{querys?.startDate}</Text>{' '}
              <Text style={{ color: '#79716B' }}>to</Text>{' '}
              <Text style={{ fontWeight: '500' }}>{querys?.endDate}</Text>
            </Text>
            {summary?.statementAccount?.length <= 4 && (
              <>
                {summary?.statementAccount?.map((option, index) => {
                  return (
                    <Text
                      key={index}
                      style={{
                        ...styles.textStyle,
                        marginBottom: `${
                          summary?.statementAccount?.length === index + 1 ? '' : '8px'
                        }`,
                      }}
                    >
                      <Text style={{ color: '#79716B', textTransform: 'capitalize' }}>
                        {option?.bankName?.toLowerCase()}:
                      </Text>{' '}
                      <Text style={{ fontWeight: '500' }}>{option.accountNumber}</Text>
                    </Text>
                  );
                })}
              </>
            )}
          </View>
          <View
            style={{
              backgroundColor: '#F5F5F4',
              minWidth: '239px',
              maxWidth: '290px',
              borderRadius: '6px',
              ...styles.padding16,
            }}
          >
            <Text
              style={{
                ...styles.textStyle,
                fontWeight: '600',
                color: '#44403C',
                marginBottom: '12px',
              }}
            >
              Summary
            </Text>
            <View style={{ ...styles.flexRow }}>
              <View
                style={{
                  ...styles.textStyle,
                  fontWeight: '500',
                  flexBasis: '50%',
                  marginBottom: '22px',
                }}
              >
                <Text style={{ color: '#79716B', display: 'block', marginBottom: '8px' }}>
                  Opening Balance
                </Text>
                <Text style={{ display: 'block' }}>
                  {summary?.openingBalance
                    ? `${currencySign} ${formatNumThousandSeparator(
                        summary?.openingBalance / 100,
                      )}`
                    : `${currencySign} ${formatNumThousandSeparator(0)}`}
                </Text>
              </View>
              <View style={{ ...styles.textStyle, fontWeight: '500', flexBasis: '50%' }}>
                <Text style={{ color: '#79716B', display: 'block', marginBottom: '8px' }}>
                  Closing Balance
                </Text>
                <Text style={{ display: 'block' }}>
                  {summary?.closingBalance
                    ? `${currencySign} ${formatNumThousandSeparator(
                        summary?.closingBalance / 100,
                      )}`
                    : `${currencySign} ${formatNumThousandSeparator(0)}`}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.flexRow }}>
              <View style={{ ...styles.textStyle, fontWeight: '500', flexBasis: '50%' }}>
                <Text style={{ color: '#79716B', display: 'block', marginBottom: '8px' }}>
                  Money In
                </Text>
                <Text style={{ display: 'block' }}>
                  {summary?.moneyIn
                    ? `${currencySign} ${formatNumThousandSeparator(
                        summary?.moneyIn / 100,
                      )}`
                    : `${currencySign} ${formatNumThousandSeparator(0)}`}
                </Text>
              </View>
              <View style={{ ...styles.textStyle, fontWeight: '500', flexBasis: '50%' }}>
                <Text style={{ color: '#79716B', display: 'block', marginBottom: '8px' }}>
                  Money Out
                </Text>
                <Text style={{ display: 'block' }}>
                  {summary?.moneyOut
                    ? `${currencySign} ${formatNumThousandSeparator(
                        summary?.moneyOut / 100,
                      )}`
                    : `${currencySign} ${formatNumThousandSeparator(0)}`}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.tableSection]}>
          <View style={styles.section}>
            <View style={{ ...styles.row, ...styles.header, alignItems: 'center' }} fixed>
              {tableColumns.map((rowData, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.cell,
                    width: rowData.width,
                    marginRight: rowData?.marginRight ?? '0px',
                  }}
                >
                  {rowData.value}
                </Text>
              ))}
            </View>
            {tableData.map((rowData, index) => (
              <View
                style={{
                  ...styles.row,
                  ...styles.textStyle,
                  fontWeight: '500',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  marginBottom: '24px',
                  width: '100%',
                }}
                key={index}
              >
                <Text
                  style={{
                    ...styles.cell,
                    flexBasis: '62px',
                    flexShrink: 0,
                    marginRight: '12px',
                  }}
                >
                  {rowData.date}
                </Text>
                <Text
                  style={{
                    ...styles.cell,
                    color: '#57534E',
                    flexBasis: '100px',
                    flexShrink: 0,
                    marginRight: '6px',
                  }}
                >
                  {rowData.type === 'Debit' ? `-${rowData.amount}` : rowData.amount}
                </Text>
                <View
                  style={{
                    flexBasis: '275px',
                    flexShrink: 0,
                    marginRight: '12px',
                  }}
                >
                  {rowData.type === 'Credit' ? (
                    <Text style={{ ...styles.cell, marginBottom: '6px' }}>
                      {rowData.from}
                    </Text>
                  ) : (
                    <Text style={{ ...styles.cell, marginBottom: '6px' }}>
                      {rowData.to}
                    </Text>
                  )}
                  <Text style={{ ...styles.cell, color: '#79716B' }}>
                    {rowData.description}
                  </Text>
                </View>
                <Text
                  style={{
                    ...styles.cell,
                    color: '#57534E',
                    flexBasis: '100px',
                    flexShrink: 0,
                  }}
                >
                  {rowData.balanceAfter}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View
          fixed
          style={{
            ...styles.textStyle,
            position: 'absolute',
            bottom: '0',
            height: 48,
            borderTop: '1px solid #F5F5F4',
            padding: '16px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Text>
            Visit Bujeti at{' '}
            <Link style={{ color: '#D28B28' }} src="https://dashboard.bujeti.com">
              <Text>www.bujeti.com</Text>
            </Link>{' '}
            Plot 1B Block 129, Jide Sawyyerr Drive, Lekki Phase 1, Lagos
          </Text>
        </View>
      </Page>

      {summary?.statementAccount?.length > 4 && (
        <Page style={styles.page}>
          <View style={[styles.rowStyle, styles.headerSection, styles.alignItemsCenter]}>
            <Text
              style={{
                fontSize: 24,
                fontWeight: 600,
                color: '#1C1917',
              }}
            >
              Account Statement
            </Text>
            <Image
              src={require('../../assets/logos/blackLogo.png')}
              style={{ width: 77, height: 32 }}
            />
          </View>

          <View
            style={{
              backgroundColor: '#F5F5F4',
              width: '396px',
              borderRadius: '6px',
              margin: '16px',
              ...styles.padding16,
            }}
          >
            <Text
              style={{
                ...styles.textStyle,
                fontWeight: '600',
                color: '#44403C',
              }}
            >
              Account numbers
            </Text>
            <View
              style={{
                ...styles.rowStyle,
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {summary?.statementAccount?.map((option, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      ...styles.textStyle,
                      fontWeight: '500',
                      flexBasis: '174px',
                      marginLeft: `${(index + 1) % 2 === 0 ? '8px' : 'none'}`,
                      marginRight: `${(index + 1) % 2 === 0 ? 'none' : '8px'}`,
                      ...styles.rowStyle,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: `${index === 0 || index === 1 ? '12px' : '16px'}`,
                    }}
                  >
                    <Image
                      style={{ width: 6, height: 6, marginRight: '8px' }}
                      src={require('./ellipseIcon.png')}
                    />

                    <Text
                      style={{
                        ...styles.textStyle,
                      }}
                    >
                      <Text style={{ color: '#79716B', textTransform: 'capitalize' }}>
                        {option?.bankName?.toLowerCase()}:
                      </Text>{' '}
                      <Text style={{ fontWeight: '500' }}>{option.accountNumber}</Text>
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>

          <View
            fixed
            style={{
              ...styles.textStyle,
              position: 'absolute',
              bottom: '0',
              height: 48,
              borderTop: '1px solid #F5F5F4',
              padding: '16px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Text>
              Visit Bujeti at{' '}
              <Link style={{ color: '#D28B28' }} src="https://dashboard.bujeti.com">
                <Text>www.bujeti.com</Text>
              </Link>{' '}
              Plot 1B Block 129, Jide Sawyyerr Drive, Lekki Phase 1, Lagos
            </Text>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default DownloadPdfStatement;
