import { ReactComponent as Active } from 'assets/icons/active.svg';
import { ReactComponent as Inactive } from 'assets/icons/inactive.svg';
import { ReactComponent as PopoverDelete } from 'assets/icons/popoverDelete.svg';
import ConfirmDialog from 'components/ConfirmDialog';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTeamMembers, teamMemberStatus } from 'redux/actions/TeamsAction';
import { buildTeamsTableData } from 'utils/helper';
import { TeamsColumns } from 'utils/mockData';
import { StatusType } from '../../../components/FilterModal/FilterHelper';
import SelectedTeamsModal from './SelectedTeamsModal';

const TeamMembers = () => {
  const dispatch = useDispatch();
  const { teamsId } = useParams();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const {
    getTeamMembers: { data: { members = [] } = {}, loading },
    teamMemberStatus: { success: updateSuccess, loading: updateLoading },
    addTeamMember: { success },
  } = useSelector(({ teams }) => teams);

  const {
    getRoles: { data: rolesData = [] },
  } = useSelector(({ roles }) => roles);

  const [filterData, setFilterData] = useState([...StatusType]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isFillData, setIsFillData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [search, setSearch] = useState('');
  const [dialogData, setDialogData] = useState({});

  const debouncedValue = useDebounce(search, 600);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    return toggleHandler(!isOpen);
  };

  useEffect(() => {
    if (rolesData?.length) {
      if (!isFillData) {
        setIsFillData(true);
        const roleList = [];

        rolesData
          .filter(({ name }) => name != 'Admin')
          .map((role) => {
            roleList.push({
              value: role.code,
              label: role.name === 'Employee' ? 'Member' : role.name,
              isSelected: false,
            });
          });

        setFilterData([...filterData, { title: 'Role', list: roleList }]);
      }
    }
  }, [rolesData]);

  useEffect(() => {
    if (debouncedValue)
      dispatch(getTeamMembers({ code: teamsId, search: debouncedValue }));
    if (!debouncedValue && isFiltered) {
      dispatch(getTeamMembers({ code: teamsId }));
    }
    if (debouncedValue) setIsFiltered(true);
  }, [debouncedValue]);

  const handleFilterApply = (query) => {
    setIsFiltered(!!Object.keys(query).length);
    const { status: statusQ, role: roleQ, from: fromQ, to: toQ } = query;
    const status = statusQ ? statusQ.toString() : undefined;
    const role = roleQ ? roleQ.toString() : undefined;
    const to = toQ ? toQ.toString() : undefined;
    const from = fromQ ? fromQ.toString() : undefined;

    if ((!from && to) || (from && !to)) {
      return toastError('To filter by date, please set from and to date');
    }

    dispatch(getTeamMembers({ code: teamsId, status, role, from, to }));
  };

  useEffect(() => {
    if (updateSuccess || success) {
      setIsConfirm(null);
      dispatch(getTeamMembers({ code: teamsId }));
    }
  }, [updateSuccess, success]);

  const handleMemberStatus = () => {
    const { code: teamMember, status } = dialogData;
    dispatch(teamMemberStatus({ code: teamsId, teamMember, status }));
  };

  const handleMemberStatusModal = (data) => {
    setIsConfirm(!isConfirm);
    setDialogData(data);
  };

  const ListActions = ({ list }) => {
    const { code } = list;
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setIsPopoverOpen(true);
            handleMemberStatusModal({ code, status: 'inactive' });
          }}
        >
          <Inactive className="mr-8" /> Set as Inactive
        </div>
        <div
          className="actionLink"
          onClick={() => {
            setIsPopoverOpen(true);
            handleMemberStatusModal({ code, status: 'active' });
          }}
        >
          <Active className="mr-8" /> Set as Active
        </div>
        <div
          className="actionLink"
          onClick={() => {
            setIsPopoverOpen(true);
            handleMemberStatusModal({ code, status: 'deleted' });
          }}
        >
          <PopoverDelete className="mr-8" /> Delete
        </div>
      </div>
    );
  };

  if (loading && !isFiltered) return <Loading isPage color="#D28B28" />;

  const MembersList = () => {
    return (
      <>
        {members.length ? (
          <Container className="p-0">
            <Row>
              <Col xs={12}>
                <Table
                  columns={TeamsColumns}
                  data={buildTeamsTableData(members)}
                  popoverAction={ListActions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="tabinnerWrapper">
            {isFiltered ? (
              <NoData
                headerText="You have no members for this filter"
                bodyText="Alter filter to see members..."
                withButton={false}
              />
            ) : (
              <NoData
                headerText="You have no members yet"
                bodyText="Members will be listed here..."
                withButton
                onClickHandler={toggleHandler}
                buttonLabel="Add Team Member"
              />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="members-wrapper">
      <PendingOnboardingNotice />

      <TopBar
        filterData={filterData}
        inputPlaceholder="Search by name or email"
        searchVal={search}
        setSearchVal={setSearch}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilterApply}
        withDate
        showBarSearch
        withOutSearch
        addOnClick={toggleHandler}
        addIcon
        showFilter
      />

      {MembersList()}
      <SelectedTeamsModal isOpen={isOpen} handleClose={handleClose} />

      <Modal show={isConfirm} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Set member as ${dialogData.status}`}
          subTitle={`Are you sure you want to set this member as ${dialogData.status}? ${
            dialogData.status === 'deleted' ? 'This action cannot be undone' : ''
          }`}
          onConfirm={handleMemberStatus}
          loading={updateLoading}
          onCancel={() => handleMemberStatusModal(dialogData)}
          isDeleteDialog={dialogData.status === 'deleted'}
        />
      </Modal>
    </div>
  );
};
export default TeamMembers;
