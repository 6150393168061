import CustomButton from 'components/UI/CustomButton';
import CustomSelect from 'components/UI/CustomSelect';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, getCurrency } from 'utils/helper';

const OrderSummary = ({
  selectedPayment,
  type,
  getAddonText,
  handlePayment,
  isLoading,
  billing,
  setBilling,
}) => {
  const {
    viewPlan: { data: viewPlanData },
  } = useSelector(({ billing }) => billing);

  const actualPrice =
    billing?.billingPeriod?.value === 'annually'
      ? (viewPlanData?.amount * (1 - 0.16) * 12) / 100
      : viewPlanData?.amount / 100;

  const vat =
    ((billing?.billingPeriod?.value === 'annually'
      ? (viewPlanData?.amount * (1 - 0.16) * 12) / 100
      : viewPlanData?.amount / 100) *
      7.5) /
    100;

  const total = actualPrice + vat;
  return (
    <section className="order-summary-wrapper">
      <h1 className="payment-title">Order Summary</h1>
      <CustomSelect
        label="Billing Interval"
        name="billingPeriod"
        options={[
          { value: 'monthly', label: 'Paid monthly' },
          { value: 'annually', label: 'Paid annually' },
        ]}
        value={billing?.billingPeriod}
        onChange={(value) => {
          setBilling({ ...billing, billingPeriod: value });
        }}
      />
      <div className="d-flex flex-column gap-2 details-wrapper">
        {/* <div className="order-summary d-flex justify-content-between align-items-center">
          <h6 className="title">VAT(7.5%)</h6>
          <p className="body">₦3,750</p>
        </div> */}
        <div className="order-summary d-flex justify-content-between align-items-center">
          <h6 className="title">{capitalizeFirstLetter(type)} plan</h6>
          <p className="body">
            <CurrencyFormat
              prefix={getCurrency(viewPlanData?.currency)}
              value={actualPrice}
              displayType="text"
              thousandSeparator={true}
            />
          </p>
        </div>
        <div className="order-summary d-flex justify-content-between align-items-center">
          <h6 className="title">VAT(7.5%)</h6>
          <p className="body">
            <CurrencyFormat
              prefix={getCurrency(viewPlanData?.currency)}
              value={vat}
              displayType="text"
              thousandSeparator={true}
            />
          </p>
        </div>
      </div>
      <div className="order-summary d-flex justify-content-between align-items-center">
        <h6 className="total">Total</h6>
        <p className="total">
          <CurrencyFormat
            prefix={getCurrency(viewPlanData?.currency)}
            value={total}
            displayType="text"
            thousandSeparator={true}
          />
        </p>
      </div>

      {selectedPayment !== 'bujeti' && (
        <p className="payment-subtext mt-2">
          Click <b>Continue to payment</b> to link your bank account and confirm your
          payment. We will return you to this page to complete your subscription.
        </p>
      )}
      <div className="mt-4">
        <CustomButton
          fullWidth={true}
          onClick={handlePayment}
          loading={isLoading}
          disabled={isLoading}
        >
          {selectedPayment === 'bujeti'
            ? `${getAddonText} to ${type}`
            : 'Continue to Payment'}
        </CustomButton>
      </div>
    </section>
  );
};

export default OrderSummary;
