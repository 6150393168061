import RequestFund from 'components/FundRequest/Requester';
import ManageFundRequest from 'components/FundRequest/Reviewer';
import EmployeeReimbursement from 'components/ReimbursementView/Employee';
import ManageReimbursement from 'components/ReimbursementView/Manager';
import TopBar from 'components/TopBar';
import CustomButton, { SendFundsButton } from 'components/UI/CustomButton';
import PendingApprovals from 'pages/Approvals/PendingApprovals';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getBalances } from 'redux/actions/BudgetsAction';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import { getReimbursements } from 'redux/actions/ReimbursementsAction';
import { fetchPendingApprovals } from 'redux/actions/ApprovalAction';
import { getRequests } from 'redux/actions/RequestsAction';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';

import { getCurrency } from 'utils/helper';
import AnalyticsCard from 'components/UI/AnalyticsCard';
import FormattedCurrency from 'components/UI/FormattedCurrency';

import useSegmentVisibility from 'hooks/useSegmentVisibility';
import CustomPopover from 'components/UI/Popover';
import { BankNoteO1Icon, CalendarPlusIcon, LayersTwoIcon } from 'assets/icons';
import { toggleAction } from 'redux/actions/ToggleAction';
import { useMediaQuery } from 'react-responsive';

const Requests = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [statsLoaded, setStatsLoaded] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);

  const visibleSegments = useSegmentVisibility(2, 300);

  const { permissions } = allPermissions();
  const { reimbursementCode, requestCode } = useParams();

  useEffect(() => {
    if (reimbursementCode) setKey('reimbursements');
    if (requestCode) setKey('funds');
  }, []);

  const {
    user: { data: { user: { company = {} } } = {} },
  } = useSelector(({ auth }) => auth);

  const {
    getReimbursement: { data: { meta = {} } = {}, data = {} },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    getRequest: { data: { meta: metaRequests = {} } = {}, data: requestsData = {} },
  } = useSelector(({ requests }) => requests);

  const {
    fetchPendingApprovals: { data: pendingApproval, loading, meta: approvalsMeta },
  } = useSelector(({ approval }) => approval);

  const {
    getStatistics: { data: statsData, loading: statsLoading, success: successStat },
  } = useSelector(({ companies }) => companies);

  const {
    getBalances: { data: balances },
  } = useSelector(({ budgets }) => budgets);

  const { total } = meta;
  const { total: totalRequests } = metaRequests;
  const { reimbursements = [] } = data;
  const { requests = [] } = requestsData;

  const canViewAdmin = hasPermission({
    permissions,
    scopes: ['org-*', 'reimbursement-*', 'access-full', 'request-approve'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-create', 'reimbursement-edit'],
  });

  const canView = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-view'],
  });

  useEffect(() => {
    if (!reimbursements.length && canView) dispatch(getReimbursements());
    if (!requests.length && canView) dispatch(getRequests());
    if (!pendingApproval?.length) dispatch(fetchPendingApprovals());
    if (!balances) dispatch(getBalances({ includeCardBalances: 1 }));
  }, []);

  useEffect(() => {
    if (company?.onboardingStatus === 'approved' && !statsData && canViewAdmin)
      dispatch(getCompanyStats({ id: company?.code }));
  }, [company.code]);

  const toggleHandler = () => {
    if (key === 'rules') {
      history.push('/requests/rules/create');
    } else {
      if (!isOtherPopoverOpen) {
        setIsOpen(!isOpen);
      }
    }
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);
  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  useEffect(() => {
    if (location.state?.isModal) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  useEffect(() => {
    const type = ['reimbursements', 'funds', 'payments'].includes(key) ? key : null;
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: {
        selectedRows: [],
        type: type,
      },
    });
  }, [key]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey !== 'payments') {
      history.push(`/requests/${tabKey}`);
    } else {
      history.push(`/requests/payments`, { disableFade: true });
    }
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  const modules = {
    reimbursements: {
      buttonTitle: 'Claim a reimbursement',
      action: null,
    },
    funds: {
      buttonTitle: 'Request funds',
      action: null,
    },
    payments: {
      buttonTitle: 'Send funds',
      action: null,
    },
  };

  useEffect(() => {
    if (successStat) setStatsLoaded(true);
  }, [successStat]);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const getStatsData = (pointer) =>
    statsData?.pendingAmountsAndCounts?.[pointer]?.['NGN']?.amount || 0;

  return (
    <div className="position-relative container">
      <div>
        <TopBar
          headerText="Requests"
          subText="Handle all expense requests: reimbursements, fund requests, and salary advances."
          addButton={canCreate}
          dropDownBtn={
            !['payments'].includes(key) ? (
              <CustomButton className="add-button" onClick={toggleHandler}>
                {modules[key]?.buttonTitle}
              </CustomButton>
            ) : (
              <AllowedTo scopes={['transaction-*', 'transaction-create']}>
                <CustomPopover
                  zIndex="1"
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                  content={<Actions />}
                  placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
                  id={isTabletOrMobile ? 'invoice-schedule' : ''}
                >
                  <SendFundsButton onClick={handleButtonToggle} />
                </CustomPopover>
              </AllowedTo>
            )
          }
        />
      </div>

      <section>
        <Row className="overview-holder medium">
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              <Tab
                eventKey="payments"
                title={
                  <div className="d-flex">
                    Payments
                    <div className="count">
                      <span className="m-auto">{approvalsMeta?.total || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                <div className="fade-in">
                  <PendingApprovals
                    canView={canView}
                    requestCode={requestCode}
                    onRowSelect={(data) => {
                      handleRowSelect(data, 'requests');
                    }}
                    handleSelect={setKey}
                  />
                </div>
              </Tab>

              <Tab
                eventKey="reimbursements"
                title={
                  <div className="d-flex">
                    Reimbursements
                    <div className="count">
                      <span className="m-auto">{total || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                <div className="fade-in">
                  {canViewAdmin && (
                    <ManageReimbursement
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && key === 'reimbursements'}
                      toggleHandler={toggleHandler}
                      reimbursementCode={reimbursementCode}
                      onRowSelect={(data) => {
                        handleRowSelect(data, 'reimbursements');
                      }}
                    />
                  )}
                  {!canViewAdmin && (
                    <EmployeeReimbursement
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && key === 'reimbursements'}
                      toggleHandler={toggleHandler}
                      reimbursementCode={reimbursementCode}
                    />
                  )}
                </div>
              </Tab>

              <Tab
                eventKey="funds"
                title={
                  <div className="d-flex">
                    Fund Requests
                    <div className="count">
                      <span className="m-auto">{totalRequests || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                <div className="fade-in">
                  {canViewAdmin && (
                    <ManageFundRequest
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && key === 'funds'}
                      toggleHandler={toggleHandler}
                      requestCode={requestCode}
                      onRowSelect={(data) => {
                        handleRowSelect(data, 'funds');
                      }}
                    />
                  )}
                  {!canViewAdmin && (
                    <RequestFund
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && ['funds', 'payments'].includes(key)}
                      toggleHandler={toggleHandler}
                      requestCode={requestCode}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export const AnalyticsDisplay = ({ data, loading, handleClick, activeKey }) => {
  const {
    getBalances: { data: balances },
  } = useSelector(({ budgets }) => budgets);

  const {
    totalPending: pending = 0,
    totalApproved: approved = 0,
    total = 0,
  } = data || {};

  const analyticsData = [
    { caption: 'Pending', filterable: true, value: pending || 0 },
    { caption: 'Approved', filterable: true, value: approved || 0 },
    { caption: 'Total', value: total || 0 },
    { caption: 'Your balance', value: balances?.totalBalances?.['NGN'] || 0 },
  ];

  return (
    <div className="sub-analytics__holder mt-4 mb-1">
      {analyticsData.map(({ caption, value, filterable }, index) => {
        return (
          <div
            className="sub-analytics__stats"
            key={caption}
            onClick={() => (filterable ? handleClick(caption) : null)}
          >
            <AnalyticsCard
              caption={caption}
              isActive={activeKey === caption.toLowerCase()}
              hasExtra={!!filterable}
              loading={loading}
              customClass={filterable ? 'pointer' : ''}
              value={
                index !== analyticsData.length - 1 ? (
                  <span> {value} </span>
                ) : (
                  <FormattedCurrency value={value || 0} prefix={getCurrency('NGN')} />
                )
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default Requests;
