import { ArrowLeftOutlined } from '@ant-design/icons';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { default as classNames, default as cs } from 'classnames';
import OverviewCard from 'components/OverviewCards';
import AccountName from 'components/UI/AccountName';
import CustomBanksSelect from 'components/UI/CustomBanksSelec';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import FileUpload from 'components/UI/FileUpload';
import { toastError } from 'components/UI/toast';
import LabeledInfo from 'components/bujetiPayModal/LabeledInfo';
import { addDays, addYears, format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getBalances, getSingleBalance } from 'redux/actions/BudgetsAction';
import { createMandate } from 'redux/actions/CompaniesAction';
import { getDirectDebitBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';
import { Banks } from 'utils/banks';
import './styles.scss';

const DirectDebitModal = ({ onClose }) => {
  const [externalUpload, setExternalUpload] = useState(false);
  const [removeFile, setRemoveFile] = useState(false);
  const [imgBase64, setImgBase64] = useState(undefined);
  const [isFile, setSetFile] = useState(false);
  const [selectBank, setSelectBank] = useState({
    bank: '',
    accountNumber: '',
    accountName: '',
  });
  const { accountCode } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isAccountSet, setIsAccountSet] = useState(false);
  const [type, setType] = useState('e-mandate');

  const {
    createMandate: { data, loading, success },
  } = useSelector(({ companies }) => companies);
  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getDirectDebitBanks: {
      data: banks = [],
      loading: loadingBanks,
      success: successBank,
    },
  } = useSelector(({ payments }) => payments);

  const readUploadFile = (files, fileName) => {
    if (files) {
      // Ensure files is not null or empty
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const image = new Image();

          image.onload = function () {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            const maxWidth = 700; // Max width for the image
            const maxHeight = 700; // Max height for the image
            let width = image.width;
            let height = image.height;

            // Calculate new dimensions to fit within the max width and height
            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw image on canvas
            context.drawImage(image, 0, 0, width, height);

            // Convert canvas to base64
            const base64String = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed

            setImgBase64(base64String.split(',')[1]);
          };

          // Set image source
          image.src = e.target.result;
        } catch (error) {
          toastError('Error reading file:', error);
        }
      };

      reader.readAsDataURL(files);
    } else {
      setImgBase64(undefined);
    }
    setSetFile(!!fileName);
  };

  const handleOnClose = () => {
    onClose();
    dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'createMandate' });
  };

  useEffect(() => {
    if (success) {
      // dispatch(getSingleBalance(accountCode));
      dispatch(getBalances());
      if (type === 'signed-mandate') handleOnClose();
    }
  }, [success]);

  useEffect(() => {
    if (!banks?.length) dispatch(getDirectDebitBanks());
  }, []);

  const banksList = useMemo(() => {
    return banks?.map((bank) => ({
      value: bank?.bankCode,
      label: bank?.label,
      img: Banks.find((item) => item.value === bank?.bankCode).icon ?? bank?.logo,
    }));
  }, [successBank]);

  useEffect(() => {
    if (selectBank.accountNumber.length === 10 && selectBank.bank.value) {
      const { accountNumber, bank } = selectBank;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bank.value }));
    }
    if (selectBank.accountNumber.length < 10) {
      setIsAccountSet(false);
      setSelectBank({ ...selectBank, accountName: undefined });
    }
  }, [selectBank.accountNumber, selectBank.bank.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setSelectBank({ ...selectBank, accountName: accName.account_name });
      setIsAccountSet(true);
    }

    if (accountNameError) setSelectBank({ ...selectBank, accountName: undefined });
    if (accountNameLoading) setSelectBank({ ...selectBank, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  const bankDestination = data?.transferDestinations?.find(
    (item) => item.bank_name === 'Paystack Titan',
  );

  const handleCreate = () => {
    if (!isFile && !bankDestination) return toastError('Please upload your signature');

    const today = new Date();
    const startDate = addDays(today, 3); // Start date is 3 days from now
    const endDate = addYears(today, 1); // End date is 1 year from now

    const payload = {
      accountNumber: selectBank?.accountNumber,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      signature: imgBase64,
      bankCode: selectBank?.bank?.value,
      amount: 200000000 * 100,
      accountName: selectBank?.accountName,
      bankName: selectBank?.bank?.label,
    };
    if (bankDestination) return handleOnClose();
    return dispatch(createMandate(payload));
  };

  const emandateRequest = () => {
    const today = new Date();
    const startDate = addDays(today, 3); // Start date is 3 days from now
    const endDate = addYears(today, 1); // End date is 1 year from now

    const payload = {
      accountNumber: selectBank?.accountNumber,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      bankCode: selectBank?.bank?.value,
      amount: 200000000 * 100,
      accountName: selectBank?.accountName,
      bankName: selectBank?.bank?.label,
    };
    dispatch(createMandate(payload));
  };

  const handleSubmit = () => {
    if (step === 1) {
      if (!selectBank?.bank?.value) return toastError('Please select your bank');
      if (!selectBank?.accountNumber)
        return toastError('Please enter your account number');
      if (!isAccountSet) return toastError('Please verify your account number');
      return setStep(2);
    }

    return handleCreate();
  };

  const handleClose = () => {
    if (step === 2) {
      return setStep(1);
    }

    return onClose();
  };

  const handleSelectMandate = (type) => {
    setType(type);
  };

  const SelectbankAccount = (
    <div>
      <h4 className="text-start">Link your bank account with Bujeti</h4>
      <p className="text-start text-xs desc">
        Securely connect your bank account to enable seamless transactions.
      </p>

      <div className="mt-3">
        <CustomBanksSelect
          placeholder="Search for your bank"
          name="bank"
          options={banksList}
          onChange={(value) => setSelectBank({ ...selectBank, bank: value })}
          value={selectBank.bank}
          isDisabled={loadingBanks}
          isLoading={loadingBanks}
        />

        <Row className="mt-2">
          <div className={classNames('relative', { ['mb-5']: visible })}>
            <CustomInput
              type="text"
              label="Account number *"
              placeholder="Enter account number"
              name="accountNumber"
              onChange={({ target }) =>
                target.validity.valid &&
                setSelectBank({ ...selectBank, accountNumber: target.value })
              }
              value={selectBank.accountNumber}
              maxLength="10"
              pattern="[0-9]*"
            />
            {selectBank?.accountName && !accountNameLoading && (
              <div className="mt-2 text-xs color-grey-500 ">
                {selectBank?.accountName}
              </div>
            )}

            <AccountName
              setValue={(value) => setSelectBank({ ...selectBank, accountName: value })}
              visible={visible}
              accountNameError={accountNameError}
              accountNameLoading={accountNameLoading}
            />
          </div>
        </Row>
      </div>
    </div>
  );

  const UploadMandate = (
    <>
      <div className="w-100 mb-3 d-flex align-items-center justify-content-center">
        <img
          src={selectBank?.bank?.img}
          style={{
            padding: 3,
            width: 35,
            height: 35,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: '100%',
            borderColor: '#E7E5E4',
            color: '#44403C',
            marginRight: 10,
          }}
          alt={selectBank?.bank?.img}
        />
      </div>
      <h4 className="text-start">
        Direct debit {type === 'e-mandate' ? 'e-Mandate ' : 'signed mandate '}
        authorisation
      </h4>
      <p className="text-start text-xs desc">
        This step confirms you&apos;ve authorized us to securely access your external
        account.
        <br />
        <br />
        <b>Note:</b> You can spend up to 200 million Naira via direct debit. If your total
        transfers exceed this limit, you cannot use direct debit until you renew your
        mandate.
      </p>
      <div className={classNames('swtich-wrapper mt-3')}>
        <div
          aria-label={type}
          className={classNames('swtich-text', {
            ['active-switch']: type === 'e-mandate',
          })}
          onClick={() => handleSelectMandate('e-mandate')}
        >
          e-Mandate
        </div>
        <div
          aria-label={type}
          className={classNames(
            'swtich-text',
            {
              ['active-switch']: type === 'signed-mandate',
            },
            { ['disabled bg-transparent']: bankDestination },
          )}
          onClick={() => {
            bankDestination ? null : handleSelectMandate('signed-mandate');
          }}
        >
          Signed mandate
        </div>
      </div>

      {type === 'e-mandate' ? (
        <>
          <div className="activate-card activate-card-overview mt-3">
            <OverviewCard minHeight={0}>
              <div className="p-2 track__card">
                <h4 className="text-start">Instructions</h4>

                <p className="space-y-2 text-start text-gray  px-0 activate-card-overview m-0 text-sm">
                  Kindly authorise this mandate by transferring ₦50.00 from your{' '}
                  <b>
                    {selectBank?.bank?.label} {selectBank?.accountNumber}
                  </b>{' '}
                  to the bank account that will be provided below.
                </p>
              </div>
            </OverviewCard>
          </div>

          {bankDestination && (
            <div className="form-normal mt-3">
              <div className="preview-card__transfer mb-3">
                <div className="px-4 pt-1 pb-2 w-100">
                  <LabeledInfo label="Bank name" value={bankDestination?.bank_name} />
                  <LabeledInfo
                    label="Account number"
                    value={bankDestination?.account_number}
                  />
                  <LabeledInfo className="mt-1" label="Amount" value="₦50.00" />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="activate-card activate-card-overview mt-3">
            <OverviewCard minHeight={0}>
              <div className="p-2 track__card">
                <h4 className="text-start">Instructions</h4>
                <p className="text-gray text-sm my-2 text-start">
                  For enhanced security and to verify your request, we need your digital
                  signature.
                </p>
                <ul className="space-y-2 text-gray activate-card px-0 activate-card-overview m-0 text-sm">
                  <li>
                    <span className="fw-medium">Prepare your signature:</span> If you
                    haven’t already, please prepare a digital version of your signature.
                    This can be done by signing on a blank piece of paper and taking a
                    clear photo of it, or by using a digital drawing tool.
                  </li>
                  <li>
                    <span className="fw-medium">Upload your signature: </span> Click the{' '}
                    {`"Upload"`} button below to select your signature file from your
                    device. We accept images in JPEG, PNG, or PDF formats.
                  </li>
                  <li>
                    <span className="fw-medium">Confirm upload: </span>
                    After selecting your file, click the {`"Confirm" `} button to upload
                    your signature.
                  </li>
                </ul>
              </div>
            </OverviewCard>
          </div>

          <div>
            <div>
              <FileUpload
                supportType="JPEG, PNG formats, up to 3 MB."
                cloud={false}
                onChange={({ file, fileName }) => readUploadFile(file, fileName)}
                externalUpload={externalUpload}
                removeFile={removeFile}
                label="Upload signature *"
                setRemoveFile={setRemoveFile}
              />
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <div>
      <div className="mandate-modal-overlay" />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body ">
          <div className="w-100 d-flex align-items-center justify-content-between">
            {step === 2 && !bankDestination && (
              <div className="back-icon">
                <ArrowLeftOutlined onClick={handleClose} />
              </div>
            )}
            <div className="cancel-icon">
              <Cancel onClick={handleOnClose} />
            </div>
          </div>
          <div>
            <div className="mt-0 w-100 mandate-wrapper">
              {step == 1 ? SelectbankAccount : UploadMandate}

              <div className="mt-4 d-flex w-100 gap-3">
                {type === 'e-mandate' && step === 2 ? null : (
                  <CustomButton
                    fullWidth
                    disabled={loading}
                    className="border-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </CustomButton>
                )}

                {!bankDestination && type === 'e-mandate' && step === 2 ? null : (
                  <CustomButton
                    fullWidth
                    disabled={loading || accountNameLoading}
                    loading={loading}
                    className={cs('confirm-btn')}
                    onClick={handleSubmit}
                  >
                    {step === 1
                      ? 'Continue'
                      : `${type === 'e-mandate' ? `I've sent the money` : 'Confirm'}`}
                  </CustomButton>
                )}

                {type === 'e-mandate' && !bankDestination && step === 2 && (
                  <CustomButton
                    fullWidth
                    disabled={loading}
                    loading={loading}
                    className={cs('confirm-btn')}
                    onClick={emandateRequest}
                  >
                    Generate account number
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DirectDebitModal;
