import React from 'react';
import AuthForm from 'components/Auth/Form';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';

const PasswordRequestForm = () => {
  const initialValues = {
    email: '',
  };

  const onFinish = async () => {
    // dispatch(loginAction(values, onSuccess, onError));
  };

  return (
    <>
      <AuthForm
        onFinish={onFinish}
        initialValues={initialValues}
        submitText="Reset password"
        hidePassword
      />
      <h4 className="secondary-action">
        <ArrowLeft />
        {/* <Link to={Pages.LOGIN_LINK}> Return to Log in </Link>{' '} */}
      </h4>
      <h4 className="secondary-action">
        <ArrowLeft />
        {/* <Link to={Pages.VERIFICATION_LINK}> Verification </Link>{' '} */}
      </h4>
    </>
  );
};

export default PasswordRequestForm;
