import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { getCurrency } from 'utils/helper';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlanButton from './PlanButton';
import { CheckMark } from 'assets/icons';
import { Skeleton } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

const PlanTable = ({ data, type, isLoading }) => {
  const history = useHistory();
  const [active, setActive] = useState('');
  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;

  const [pricingFeatures, setPricingFeatures] = useState([]);
  const [addsOn, setAddsOn] = useState([]);

  const onHandlePlanSelector = (plan) => {
    // window.localStorage.setItem('type', JSON.stringify(type));
    // history.push(`/settings/checkout/${planType}`);
    history.push({
      pathname: `/settings/checkout/${plan.code}/${plan.name}`,
      state: { plan, type },
    });
  };

  useEffect(() => {
    if (userData) {
      setActive(
        userData?.user?.company?.paymentPlan?.name === 'scale'
          ? 'custom'
          : userData?.user?.company?.paymentPlan?.name,
      );
    }
  }, [userData]);

  const activeplan =
    userData?.user?.company?.paymentPlan?.name === 'scale'
      ? 'custom'
      : userData?.user?.company?.paymentPlan?.name;

  const filteredPlan = data?.plans?.filter((item) => {
    if (activeplan === 'custom') {
      return item.name !== 'scale';
    } else if (['start', 'growth'].includes(activeplan)) {
      return item.name !== 'custom';
    }
  });

  useEffect(() => {
    if (data && userData) {
      setPricingFeatures(transformPlansToFeatureGroups(filteredPlan));
      setAddsOn(transformAddsOnGroups(filteredPlan));
    }
  }, [data, userData]);

  const getIconByValue = (value) => {
    if (value === true) return <CheckMark />;
    return <MinusCircleOutlined />;
  };

  const groupValuesMapper = [
    {
      name: 'General',
      features: [
        {
          name: 'Max Users',
          fetch: (plan) => plan?.configuration?.beneficiaries?.max_users,
          translate: (value) =>
            isNaN(value)
              ? 'Unlimited'
              : `Up to ${new Intl.NumberFormat().format(value)} users`,
        },
      ],
    },
    {
      name: 'Treasury Management',
      features: (plans) => {
        const featureList = [
          {
            name: 'Free Bank Account',
            fetch: (plan) => plan?.configuration?.treasury?.freeBankAccount,
            translate: (value) =>
              isNaN(value) ? 'Unlimited' : `${new Intl.NumberFormat().format(value)}`,
          },
          {
            name: 'Account Linking',
            fetch: (plan) => plan?.configuration?.treasury?.linkedAccounts,
            translate: (value) =>
              isNaN(value) ? 'Unlimited' : `${new Intl.NumberFormat().format(value)}`,
          },
          {
            name: 'Direct Debit',
            fetch: (plan) => plan?.configuration?.treasury?.directDebit,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
        ];
        const fundingFeesCurrencies = Object.keys(
          plans[0]?.configuration?.treasury?.fundingFees ?? {},
        ).filter((key) => key.length === 3);
        for (const currency of fundingFeesCurrencies) {
          featureList.push({
            name: 'Funding Fees',
            fetch: (plan) => plan?.configuration?.treasury?.fundingFees[currency],
            translate: (value) =>
              `${value?.percentage}% capped at ${getCurrency(
                currency,
              )}${new Intl.NumberFormat().format(value?.max / 100)}`,
          });
        }

        const dataSyncCurrencies = Object.keys(
          plans[0]?.configuration?.treasury?.dataSync ?? {},
        ).filter((key) => key.length === 3);
        for (const currency of dataSyncCurrencies) {
          featureList.push({
            name: 'Free Data Sync',
            fetch: (plan) => plan?.configuration?.treasury?.dataSync[currency],
            translate: (value) =>
              `${value?.maxFreeCount} free sync per day Additional comes at ${getCurrency(
                currency,
              )}${new Intl.NumberFormat().format(value?.additionalCost)} per sync`,
          });
        }
        return featureList;
      },
    },

    {
      name: 'Payables Management',
      features: (plans) => {
        const featureList = [
          {
            name: 'Free Sub Account/Budget',
            fetch: (plan) => plan?.configuration?.payables?.freeSubAccountsOrBudgets,
            translate: (value) => (isNaN(value) ? 'Unlimited' : value),
          },
          {
            name: 'Bill Payment',
            fetch: (plan) => plan?.configuration?.payables?.billPayment,
            translate: (value) =>
              isNaN(value?.max)
                ? 'Unlimited'
                : `${value?.max} free per ${value?.interval}`,
          },
        ];
        const feesCurrencies = Object.keys(
          plans[0]?.configuration?.payables?.payoutFees ?? {},
        ).filter((key) => key.length === 3);
        for (const currency of feesCurrencies) {
          featureList.push({
            name: 'Payouts Fees',
            fetch: (plan) => plan?.configuration?.payables?.payoutFees[currency],
            translate: (value) =>
              `${getCurrency(currency)}${new Intl.NumberFormat().format(value / 100)}`,
          });
        }

        const virtualCardsCurrencies = Object.keys(
          plans[0]?.configuration?.payables?.freeVirtualCards ?? {},
        ).filter((key) => key.length === 3);
        for (const currency of virtualCardsCurrencies) {
          featureList.push({
            name: `Free ${currency} Virtual Cards`,
            fetch: (plan) => plan?.configuration?.payables?.freeVirtualCards[currency],
            translate: (value) => value,
          });
        }

        const physicalCardsCurrencies = Object.keys(
          plans[0]?.configuration?.payables?.freePhysicalCards ?? {},
        ).filter((key) => key.length === 3);
        for (const currency of physicalCardsCurrencies) {
          featureList.push({
            name: 'Free Physical Card',
            fetch: (plan) => plan?.configuration?.payables?.freePhysicalCards[currency],
            translate: (value) => value,
          });
        }

        return featureList;
      },
    },

    {
      name: 'Receivabe Management',
      features: (plans) => {
        const featureList = [
          {
            name: 'Smart Invoices',
            fetch: (plan) => plan?.configuration?.receivables?.smartInvoices,
            translate: (value) => (isNaN(value) ? 'Unlimited' : value),
          },
        ];
        const feesCurrencies = Object.keys(
          plans[0]?.configuration?.receivables?.collectionFees ?? {},
        ).filter((key) => key.length === 3);
        for (const currency of feesCurrencies) {
          featureList.push({
            name: 'Collection Fees',
            fetch: (plan) => plan?.configuration?.receivables?.collectionFees[currency],
            translate: (value) =>
              `${value?.percentage}% capped at ${getCurrency(
                currency,
              )}${new Intl.NumberFormat().format(value?.max / 100)}`,
          });
        }

        return featureList;
      },
    },

    {
      name: 'Multi Entity Management',
      features: (plans) => {
        const featureList = [
          {
            name: 'Multiple company',
            fetch: (plan) => plan?.configuration?.multiEntity?.multipleCompany,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
          {
            name: 'Subsidiaries/Branches',
            fetch: (plan) => plan?.configuration?.multiEntity?.subsidiaries,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
        ];

        return featureList;
      },
    },

    {
      name: 'Other',
      features: (plans) => {
        const featureList = [
          {
            name: 'Accounting Integration',
            fetch: (plan) => plan?.configuration?.accountIntegration,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
          {
            name: 'Sub category management',
            fetch: (plan) => plan?.configuration?.subCategoryManagement,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
        ];

        return featureList;
      },
    },

    {
      name: 'Policy Management',
      features: (plans) => {
        const featureList = [
          {
            name: 'Policies',
            fetch: (plan) => plan?.configuration?.policyManagement?.policies,
            translate: (value) => (isNaN(value) ? 'Unlimited' : value),
          },
          {
            name: 'Approval Rules',
            fetch: (plan) => plan?.configuration?.policyManagement?.approvalRules,
            translate: (value) => (isNaN(value) ? 'Unlimited' : `${value} level(s)`),
          },
          {
            name: 'Custom Roles',
            fetch: (plan) => plan?.configuration?.policyManagement?.customRoles,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
        ];

        return featureList;
      },
    },

    {
      name: 'Support',
      features: (plans) => {
        const featureList = [
          {
            name: 'Dedicated Slack Channel',
            fetch: (plan) => plan?.configuration?.support?.dedicatedSlackChannel,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
          {
            name: 'Priority Support',
            fetch: (plan) => plan?.configuration?.support?.prioritySupport,
            translate: (value) => (value ? 'Yes' : 'No'),
          },
        ];

        return featureList;
      },
    },
  ];

  function transformPlansToFeatureGroups(plans) {
    return groupValuesMapper.map((groupMapper) => ({
      name: groupMapper.name,
      features: (typeof groupMapper.features === 'function'
        ? groupMapper.features(plans)
        : groupMapper.features
      ).map((feature) => ({
        name: feature.name,
        options: plans.map((plan) => {
          const value = feature.fetch(plan);
          return feature.translate(value);
        }),
      })),
    }));
  }

  const groupAddsOnMapper = [
    {
      name: 'Add-ons',
      features: (plans) => {
        const featureList = [
          {
            name: 'Additional Virtual NGN Cards',
            fetch: (plan) => plan?.configuration?.addOns?.virtualCards['NGN'],
            translate: (value) =>
              `${getCurrency('NGN')}${value?.fee / 100} + ${getCurrency(
                'NGN',
              )}${new Intl.NumberFormat().format(value?.fundingFee / 100)} Funding fee `,
          },
          {
            name: 'Additional Virtual USD Cards',
            fetch: (plan) => plan?.configuration?.addOns?.virtualCards['USD'],
            translate: (value) =>
              `${getCurrency('USD')}${value?.fee / 100} + ${
                value?.fundingFeePercent
              }% Funding fee (capped at ${getCurrency(
                'USD',
              )}${new Intl.NumberFormat().format(value?.max / 100)})`,
          },
          {
            name: 'Additional Physical NGN Card',
            fetch: (plan) => plan?.configuration?.addOns?.physicalCards['NGN'],
            translate: (value) =>
              `${getCurrency('NGN')}${value?.fee / 100} + ${getCurrency(
                'NGN',
              )}${new Intl.NumberFormat().format(value?.fundingFee / 100)} Funding fee`,
          },
          {
            name: 'Additional User (Growth)',
            fetch: (plan) => plan?.configuration?.addOns?.additionalUsers?.growth,
            translate: (value) =>
              `${getCurrency('NGN')}${new Intl.NumberFormat().format(
                value?.amount['NGN']?.fee / 100,
              )} per ${value?.interval}`,
          },
          {
            name: 'Additional Bank Account',
            fetch: (plan) => plan?.configuration?.addOns?.additionalAccounts,
            translate: (value) =>
              `${getCurrency('NGN')}${new Intl.NumberFormat().format(
                value?.amount['NGN']?.fee / 100,
              )}`,
          },
          {
            name: 'Additional Sub-Account/Budget',
            fetch: (plan) => plan?.configuration?.addOns?.additionalSubAccounts,
            translate: (value) =>
              `${getCurrency('NGN')}${new Intl.NumberFormat().format(
                value?.amount['NGN']?.fee / 100,
              )}`,
          },
        ];

        return featureList;
      },
    },
  ];

  function transformAddsOnGroups(plans) {
    return groupAddsOnMapper.map((groupMapper) => ({
      name: groupMapper.name,
      features: (typeof groupMapper.features === 'function'
        ? groupMapper.features(plans)
        : groupMapper.features
      ).map((feature) => ({
        name: feature.name,
        options: plans.map((plan) => {
          const value = feature.fetch(plan);
          return feature.translate(value);
        }),
      })),
    }));
  }

  const getAddonText = (data, index, active) => {
    const activeIndex = data?.findIndex((item) => item.name === active);
    if (activeIndex > index) return 'Downgrade';
    if (activeIndex < index) return 'Upgrade';
  };

  return (
    <table className="plans mb-5">
      <thead>
        <tr className="header">
          <th></th>
          {isLoading ? (
            <>
              {Array.from({ length: 3 }).map((_, i) => (
                <th key={i}>
                  <Skeleton.Input
                    active
                    className="mt-1"
                    size={20}
                    style={{ borderRadius: 4, minWidth: '7rem' }}
                  />
                </th>
              ))}
            </>
          ) : (
            <>
              {filteredPlan?.map((plan, index) => {
                return <th key={index}>{plan.name}</th>;
              })}
            </>
          )}
        </tr>

        <tr className="header-items">
          <th></th>

          {isLoading ? (
            <>
              {Array.from({ length: 3 }).map((_, i) => (
                <th key={i}>
                  <div>
                    <Skeleton.Input
                      active
                      className="mt-1"
                      size={24}
                      style={{ borderRadius: 4, minWidth: '7rem' }}
                    />

                    <div className="mt-2">
                      <Skeleton.Input
                        active
                        className="mt-1"
                        size={12}
                        style={{ borderRadius: 4, minWidth: '14rem' }}
                      />
                      <Skeleton.Input
                        active
                        size={12}
                        style={{ borderRadius: 4, minWidth: '6rem' }}
                      />
                    </div>

                    <div className="mt-2">
                      <Skeleton.Button
                        active
                        size={40}
                        style={{ borderRadius: 4, minWidth: '14rem' }}
                      />
                    </div>
                  </div>
                </th>
              ))}
            </>
          ) : (
            <>
              {filteredPlan?.map((plan, index) => {
                return (
                  <th key={index}>
                    <div className="plan-description">
                      {plan.amount === 0 && plan.name === 'scale' && 'no-amount' ? (
                        <h1
                          className={`${
                            plan.amount === 0 && plan.name === 'scale' && 'no-amount'
                          } nowrap`}
                        >
                          Flexible pricing for your business
                        </h1>
                      ) : (
                        <h1
                          className={`${
                            plan.amount === 0 && plan.name === 'scale' && 'no-amount'
                          } nowrap`}
                        >
                          <CurrencyFormat
                            prefix={getCurrency('NGN')}
                            value={
                              type === 'annually'
                                ? (plan.amount * 12 - plan.amount * 12 * 0.16) / 100
                                : plan.amount / 100
                            }
                            displayType="text"
                            thousandSeparator={true}
                          />
                          <span>per {type === 'annually' ? 'year' : 'month'}</span>
                        </h1>
                      )}

                      <p>
                        {plan.description} {plan.name !== 'start' && '(tax excl.)'}
                      </p>
                      <PlanButton
                        index={index}
                        onClick={onHandlePlanSelector}
                        textAddon={getAddonText(filteredPlan, index, active)}
                        className={plan.name === active && 'current'}
                        plan={plan}
                        active={active}
                      />
                    </div>
                  </th>
                );
              })}
            </>
          )}
        </tr>
      </thead>

      {isLoading ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <tbody key={index}>
              <tr className="title">
                <td colSpan={5}>
                  <Skeleton.Input
                    active
                    className="mt-1"
                    size={16}
                    style={{ borderRadius: 4, minWidth: '7rem' }}
                  />
                </td>
              </tr>

              {Array.from({ length: 3 }).map((_, i) => (
                <tr className="plan-item" key={i}>
                  {Array.from({ length: 4 }).map((_, j) => (
                    <td key={j}>
                      <Skeleton.Input
                        active
                        className="mt-1"
                        size={18}
                        style={{ borderRadius: 4, minWidth: '7rem' }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ))}
        </>
      ) : (
        <tbody>
          {pricingFeatures?.map((pricing, index) => {
            return (
              <>
                <tr className="title" key={index}>
                  <td colSpan={5}>{pricing?.name}</td>
                </tr>
                {pricing?.features?.map((features, index) => {
                  return (
                    <tr className="plan-item" key={index}>
                      <td>{features?.name}</td>
                      <td className="text-center">
                        <span>{features?.options[0]}</span>
                      </td>
                      <td className="text-center">
                        <span>{features?.options[1]}</span>
                      </td>
                      <td className="text-center">
                        {['scale', 'custom'].includes(filteredPlan[2]?.name) ? (
                          <span>{features?.options[2]}</span>
                        ) : (
                          <span>Custom</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      )}

      <tbody>
        {addsOn?.map((pricing, index) => {
          return (
            <>
              <tr className="title" key={index}>
                <td colSpan={5}>{pricing?.name}</td>
              </tr>
              {pricing?.features?.map((features, index) => {
                return (
                  <tr className="plan-item" key={index}>
                    <td>{features?.name}</td>
                    <td className="text-center">
                      <span>{features?.options[0]}</span>
                    </td>
                  </tr>
                );
              })}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default PlanTable;
