import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import CustomPopover from '../Popover';
import { KebabIcon } from 'assets/icons';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';

const ModalHeader = ({
  action,
  isPopoverOpen,
  handleTogglePopover,
  modalName,
  onClose,
  goBack,
}) => {
  return (
    <div
      className="z-3 position-fixed bg-gray-200 left-0 right-0 top-0 w-100 px-3 border-bottom  d-flex justify-content-between align-items-center"
      style={{ height: 52 }}
    >
      {goBack ? (
        <LeftIcon className="cursor" onClick={goBack} />
      ) : (
        <CloseOutlined onClick={onClose} className=" cursor" />
      )}
      <div className="text-sm fw-medium">{modalName}</div>
      {action ? (
        <div>
          <CustomPopover
            content={action}
            showPopover={isPopoverOpen}
            clickOutside={handleTogglePopover}
          >
            <div onClick={handleTogglePopover} className="cursor">
              <KebabIcon stroke="#A9A29D" style={{ transform: 'rotate(90deg)' }} />
            </div>
          </CustomPopover>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default ModalHeader;
