import { useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { TrashIcon } from 'assets/icons';
import { FullPageLoader } from 'components/UI/Loading';
import TableGrid from 'components/UI/TableGrid';
import { ActionCellTemplate } from 'components/UI/TableGrid/ActionCellTemplate';
import { AmountCellTemplate } from 'components/UI/TableGrid/AmountCellTemplate';
import { CreateButtonCellTemplate } from 'components/UI/TableGrid/CreateButtonCellTemplate';
import { CustomDropdownTemplate } from 'components/UI/TableGrid/CustomDropdownTemplate';
import { CustomTextCellTemplate } from 'components/UI/TableGrid/CustomTextCellTemplate';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createBulkCategories,
  fetchCategories,
  updateBulkCategories,
} from 'redux/actions/CategoryAction';
import { v4 as uuidv4 } from 'uuid';
import './styles.scss';
import { flattenCategories } from 'utils/helper';
import { toastError } from 'components/UI/toast';
import FloatingCta from 'components/BulkAction/FloatingCta';
import CategoryHeader from './Components/CategoryHeader';
import TableLoading from './Components/TableLoading';
import { formatNumThousandSeparator } from 'utils/utility';

const EditMultipleCategories = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    updateBulkCategories: { loading, success },
    fetchCategories: {
      data: { categories: categoriesdata = [], meta = {} } = {},
      loading: fetchLoading,
    },
  } = useSelector(({ categories }) => categories);

  const defaultRowHeight = 54;

  const cellVerticalPadding = 24;

  const invalidCellStyle = {
    border: {
      left: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
      top: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
      right: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
      bottom: {
        color: '#FDA298',
        style: 'solid',
        width: '1px',
      },
    },
  };

  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      const elementWidth = elementRef.current.getBoundingClientRect().width;
      setWidth(elementWidth);
    }
  }, [elementRef.current]);

  const [width, setWidth] = useState(0);
  const [kebabId, setKebabId] = useState('');
  const [changedRowId, setChangedRowId] = useState(null);
  const [limitsLeft, setLimitsLeft] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [initialCategories, setInitialCategories] = useState({});
  const [categories, setCategories] = useState([]);
  const [updatedCategories, setUpdatedCategories] = useState({});

  useEffect(() => {
    if (success) {
      history.push('/compliances/categories');
    }
  }, [success]);

  useEffect(() => {
    if (!categoriesdata.length) {
      dispatch(fetchCategories());
    }
  }, []);

  useEffect(() => {
    let categoryList = transformCategories(categoriesdata);
    setInitialCategories(categoryList.obj);
    setCategories([
      ...categoryList.arr,
      {
        categoryName: 'Add category',
        description: '',
        limit: '',
        action: '',
        colspan: 4,
        isSubRow: false,
        rowId: uuidv4(),
      },
    ]);
  }, [categoriesdata.length]);

  const transformCategories = (categoryList = []) => {
    const transformedCategoriesList = [];
    const transformedCategoriesObj = {};
    let limitLeftData = {};
    categoryList?.forEach((category) => {
      const categoryRowId = uuidv4();

      const modifiedCategory = {
        code: category.code,
        categoryName: category.name,
        description: category.description ?? '-',
        limit: category.limit ? (category.limit / 100).toString() : '-',
        action: '',
        rowId: categoryRowId,
        hasChildren: true,
        expandChildren: false,
      };

      const transformedSubcategoriesList = [];
      const transformedSubcategoriesObj = {};

      limitLeftData[categoryRowId] = {
        ...limitLeftData[categoryRowId],
        parentLimit: !!category?.limit ? category?.limit / 100 : undefined,
      };

      category?.children?.forEach((subCategory) => {
        const subCategoryRowId = uuidv4();

        const modifiedSubcategory = {
          code: subCategory.code,
          categoryName: subCategory.name,
          description: subCategory.description ?? '-',
          limit: subCategory.limit ? (subCategory.limit / 100).toString() : '-',
          action: '',
          rowId: subCategoryRowId,
          parentId: categoryRowId,
          isExpanded: false,
        };

        transformedSubcategoriesList.push(modifiedSubcategory);
        transformedSubcategoriesObj[subCategoryRowId] = modifiedSubcategory;

        limitLeftData[categoryRowId] = {
          ...limitLeftData[categoryRowId],
          totalChildrenLimit:
            (limitLeftData[categoryRowId]?.['totalChildrenLimit'] || 0) +
            (subCategory?.limit || 0),
        };
      });

      const newArrItem = { ...modifiedCategory };

      const newObj = { ...modifiedCategory };

      newArrItem.children = transformedSubcategoriesList.length
        ? transformedSubcategoriesList
        : undefined;

      newObj.children = Object.keys(transformedSubcategoriesObj).length
        ? transformedSubcategoriesObj
        : undefined;

      transformedCategoriesList.push(newArrItem);

      transformedCategoriesObj[categoryRowId] = newObj;
    });

    setLimitsLeft(limitLeftData);

    return { arr: transformedCategoriesList, obj: transformedCategoriesObj };
  };

  const customDropdownTemplateRef = useRef({});
  const customTextTemplateRef = useRef({}); // Create a ref to store the input element reference

  // Function to receive the input reference from CustomCellTemplate
  const handleCustomDropdownRef = (rowId, ref) => {
    customDropdownTemplateRef.current = {
      [rowId]: ref,
      ...customDropdownTemplateRef.current,
    };
  };

  const handleCustomTextRef = (rowId, ref) => {
    customTextTemplateRef.current = { [rowId]: ref, ...customTextTemplateRef.current };
  };

  useEffect(() => {
    if (
      customDropdownTemplateRef.current?.[changedRowId] ||
      customTextTemplateRef.current?.[changedRowId]
    ) {
      setCategories((prevCategories) => {
        let row = prevCategories.find((item) => item.rowId === changedRowId);

        if (!row) return prevCategories;

        //Increase height of row that corresponds to the height of the cell that has the maximum height in that row
        let maxCellHeight = Math.max(
          customDropdownTemplateRef?.current[changedRowId]?.offsetHeight ?? 0,
          customTextTemplateRef?.current[changedRowId]?.offsetHeight ?? 0,
        );
        row['height'] =
          maxCellHeight + cellVerticalPadding <= defaultRowHeight
            ? defaultRowHeight
            : maxCellHeight + cellVerticalPadding;

        return [...prevCategories];
      });
    }
  }, [customDropdownTemplateRef?.current, customTextTemplateRef?.current]);

  const getColumns = () => [
    { columnId: 'categoryName', width: width * (30 / 100) },
    { columnId: 'description', width: width * (45 / 100) },
    { columnId: 'limit', width: width * (20 / 100) },
    { columnId: 'action', width: width * (5 / 100) },
  ];

  const headerRow = {
    rowId: 'header',
    cells: [
      { type: 'header', text: 'Category name*', className: 'rg-cell-custom' },
      { type: 'header', text: 'Description', className: 'rg-cell-custom' },
      { type: 'header', text: 'Limit', className: 'rg-cell-custom' },
      { type: 'header', text: '', className: 'rg-cell-custom' },
    ],
    height: 34,
  };

  const handleTogglePopover = (event, rowId) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (kebabId === rowId) return setKebabId('');

    setKebabId(rowId);
  };

  const handleExpand = (rowId) => {
    setCategories((prevCategories) => {
      let category = prevCategories.find((item) => item.rowId === rowId);
      category.expandChildren = !category.expandChildren;
      category.children = category?.children?.map((item) => ({
        ...item,
        isExpanded: !item.isExpanded,
      }));
      if (category.expandChildren) {
        category.children = [
          ...(category.children || []),
          {
            categoryName: 'Add subcategory',
            description: '',
            limit: '',
            action: '',
            colspan: 4,
            isSubRow: true,
            parentId: rowId,
            rowId: uuidv4(),
          },
        ];
      } else {
        category.children.splice(-1, 1);
      }

      return [...prevCategories];
    });
  };

  const handleCreate = (parentId) => {
    if (!parentId) {
      let rowId = uuidv4();

      setCategories((prevCategories) => {
        let modifiedCategories = prevCategories.slice();

        modifiedCategories.splice(-1, 0, {
          categoryName: '',
          description: '',
          limit: '',
          action: '',
          rowId,
          hasChildren: true,
          expandChildren: false,
        });

        return modifiedCategories;
      });
    } else {
      let rowId = uuidv4();
      setCategories((prevCategories) => {
        let parentCategory = prevCategories.find((opt) => opt.rowId === parentId);
        parentCategory.children.splice(-1, 0, {
          categoryName: '',
          description: '',
          limit: '',
          action: '',
          rowId,
          parentId,
          isExpanded: true,
        });

        return [...prevCategories];
      });
    }
  };

  const handleDelete = (rowId, parentId) => {
    setCategories((prevCategories) => {
      if (!parentId) {
        setLimitsLeft((prevValues) => {
          const { [rowId]: _, ...rest } = prevValues;
          return rest;
        });
        return prevCategories.filter((opt) => opt.rowId !== rowId);
      } else {
        let parentCategory = prevCategories.find((opt) => opt.rowId === parentId);

        let subCategory;
        parentCategory.children = parentCategory.children.filter((opt) => {
          if (opt.rowId !== rowId) {
            return true;
          } else {
            subCategory = opt;
            return false;
          }
        });

        setLimitsLeft((prevValues) => {
          const updatedtotalLimit =
            prevValues?.[parentId]?.['totalChildrenLimit'] -
            Number(
              subCategory.limit && subCategory.limit !== '-' ? subCategory.limit : 0,
            );

          return {
            ...prevValues,
            [parentId]: {
              ...prevValues[parentId],
              totalChildrenLimit: updatedtotalLimit,
            },
          };
        });
        return [...prevCategories];
      }
    });

    setUpdatedCategories((prevValues) => {
      if (!parentId) {
        return {
          ...prevValues,
          [rowId]: {
            updateType: 'delete',
            children: prevValues[rowId]?.children
              ? {
                  ...prevValues[rowId].children,
                }
              : undefined,
          },
        };
      } else {
        return {
          ...prevValues,
          [parentId]: {
            ...prevValues[parentId],
            children: {
              ...(prevValues[parentId]?.children || {}),
              [rowId]: {
                updateType: 'delete',
              },
            },
          },
        };
      }
    });
  };

  const handleSave = () => {
    let hasError = false;
    let data = {};
    categories.forEach((opt) => {
      if (opt.isSubRow !== undefined) return;

      if (!opt.categoryName) {
        hasError = true;

        opt.invalid = [...(opt.invalid ?? []), 'categoryName'];
      }

      data = { ...data, [opt.rowId]: opt };

      let hasInvalidChildren = false;

      let childrenObj = {};

      opt?.children?.forEach((item, idx) => {
        if (item.isSubRow !== undefined) return;

        if (!item.categoryName) {
          hasError = true;
          hasInvalidChildren = true;

          item.invalid = [...(item.invalid ?? []), 'categoryName'];
        }

        childrenObj = { ...childrenObj, [item.rowId]: item };
      });

      data = {
        ...data,
        [opt.rowId]: {
          ...data[opt.rowId],
          children: Object.keys(childrenObj).length ? childrenObj : undefined,
        },
      };

      if (hasInvalidChildren && !opt.expandChildren) {
        opt.expandChildren = true;
        let transformedChildren = opt.children.map((item) => ({
          ...item,
          isExpanded: true,
        }));

        opt.children = [
          ...transformedChildren,
          {
            categoryName: 'Add subcategory',
            description: '',
            limit: '',
            action: '',
            colspan: 4,
            isSubRow: true,
            parentId: opt.rowId,
            rowId: uuidv4(),
          },
        ];
      }
    });

    setCategories([...categories]);

    if (!hasError) {
      let payload = [];

      const compareTwoValues = (oldValue, newValue, shouldCompare = true) => {
        if (shouldCompare && oldValue === newValue) return undefined;
        return newValue;
      };

      const getData = (oldValue, newValue, extraItems = {}) => {
        const shouldCompare = !!(oldValue && newValue);
        return {
          code: oldValue?.code,
          name: compareTwoValues(
            oldValue?.categoryName,
            newValue?.categoryName,
            shouldCompare,
          ),
          description: compareTwoValues(
            oldValue?.description && oldValue.description !== '-'
              ? oldValue.description
              : undefined,
            newValue?.description && newValue.description !== '-'
              ? newValue.description
              : undefined,
            shouldCompare,
          ),
          limit: compareTwoValues(
            oldValue?.limit && oldValue.limit !== '-'
              ? oldValue.limit.replace(/,/g, '') * 100
              : undefined,
            newValue?.limit && newValue.limit !== '-'
              ? newValue.limit.replace(/,/g, '') * 100
              : undefined,
            shouldCompare,
          ),
          ...extraItems,
        };
      };

      for (let key in updatedCategories) {
        let newCategory = data[key];
        let oldCategory = initialCategories[key];

        let transformedCategory = getData(oldCategory, newCategory, {
          shouldDelete: !!(updatedCategories[key]?.updateType === 'delete') || undefined,
        });

        if (transformedCategory.shouldDelete && !transformedCategory.code) continue;

        if (updatedCategories[key]?.children) {
          transformedCategory.subCategories = [];

          for (let childKey in updatedCategories[key].children) {
            let newSubCategory = data[key]?.['children']?.[childKey];
            let oldSubCategory = initialCategories[key]?.['children']?.[childKey];
            let transformedSubcategory = getData(oldSubCategory, newSubCategory, {
              shouldDelete:
                !!(
                  updatedCategories[key]?.children?.[childKey]?.updateType === 'delete'
                ) || undefined,
            });

            if (transformedSubcategory.shouldDelete && !transformedSubcategory.code)
              continue;

            transformedCategory.subCategories.push(transformedSubcategory);
          }
        }

        payload.push(transformedCategory);
      }
      dispatch(
        updateBulkCategories({
          categories: payload,
        }),
      );
    }
  };

  const getRows = (categories) => [
    headerRow,
    ...flattenCategories(categories).map((category, idx) => ({
      rowId: category.rowId,
      parentId: category?.parentId,
      isExpanded: category?.isExpanded,
      cells: [
        {
          type: category?.colspan ? 'create' : 'customDropdown',
          text: category.categoryName,
          onExpand: () => {
            handleExpand(category?.rowId);
          },
          onCreate: () => {
            handleCreate(category?.parentId);
          },
          onSelect: (isChecked) => {
            if (isChecked) {
              setSelectedItem((prevValues) => [
                ...prevValues,
                { rowId: category?.rowId, parentId: category?.parentId },
              ]);
            } else {
              setSelectedItem((prevValues) =>
                [
                  ...prevValues,
                  { rowId: category?.rowId, parentId: category?.parentId },
                ].filter((item) => item.rowId !== category?.rowId),
              );
            }
          },
          colspan: category?.colspan,
          nonEditable: !!category?.colspan,
          hasChildren: category?.parentId ? false : true,
          indent: category?.parentId ? 1.2 : 0.5,
          className: 'rg-cell-custom',
          rowId: category.rowId,
          parentId: category?.parentId,
          style: category?.invalid?.includes('categoryName')
            ? invalidCellStyle
            : undefined,
        },
        {
          type: 'customText',
          text: category.description,
          nonEditable: !!category?.colspan,
          className: 'rg-cell-custom',
          parentId: category?.parentId,
          rowId: category.rowId,
        },
        {
          type: 'amount',
          value: category.limit,
          currency: '₦',
          nonEditable: !!category?.colspan,
          parentId: category?.parentId,
          className: 'rg-cell-custom',
          style: category?.invalid?.includes('limit') ? invalidCellStyle : undefined,
          isValid: (value) => {
            if (category?.parentId) {
              if (limitsLeft[category?.parentId]?.['parentLimit'] === undefined)
                return true;
              let limit = Number(value.replace(/,/g, ''));
              let limitLeft =
                limitsLeft[category?.parentId]['parentLimit'] -
                (limitsLeft[category?.parentId]?.['totalChildrenLimit'] || 0) +
                Number(category.limit.replace(/,/g, ''));
              if (!isNaN(limitLeft) && limitLeft < limit) {
                toastError('Subcategory limits must add up to parent category limit');
                return false;
              }

              return true;
            } else {
              return true;
            }
          },
        },
        {
          type: 'modify',
          text: category.action,
          nonEditable: true,
          kebabId,
          onClick: handleTogglePopover,
          className: 'rg-cell-custom',
          rowId: category.rowId,
          parentId: category?.parentId,
          actionList: [
            {
              icon: TrashIcon,
              text: 'Delete',
              className: 'text-danger svg-danger',
              iconClassName: 'me-0',
              width: '16',
              height: '16',
              onClick: () => handleDelete(category.rowId, category.parentId),
            },
          ],
        },
      ],
      height: category.height ?? defaultRowHeight,
    })),
  ];

  const rows = getRows(categories).filter((item) => item.isExpanded !== false);
  const columns = getColumns();

  const applyChangesToCategory = (changes, prevCategories) => {
    changes.forEach((change) => {
      const categoryIndex = change.rowId;
      const parentCategoryIndex = change.previousCell?.parentId;
      const fieldName = change.columnId;
      let parentRow;
      let changedRow = prevCategories.find(
        (item) => item.rowId === categoryIndex || item.rowId === parentCategoryIndex,
      );

      if (parentCategoryIndex) {
        parentRow = changedRow;
        changedRow = changedRow.children.find((item) => item.rowId === categoryIndex);
      }

      changedRow[fieldName] = change.newCell.text;

      if (fieldName === 'limit') {
        if (!parentCategoryIndex) {
          setLimitsLeft((prevValues) => {
            let updatedLimit;
            if (
              (prevValues[categoryIndex]?.['totalChildrenLimit'] || 0) >
              change.newCell.value
            ) {
              updatedLimit = prevValues[categoryIndex]?.['totalChildrenLimit'];
              changedRow[fieldName] =
                prevValues[categoryIndex]?.['totalChildrenLimit']?.toString();

              toastError('Subcategory limits must add up to parent category limit');
            } else {
              updatedLimit = change.newCell.value;
              changedRow[fieldName] = change.newCell.text;
            }
            return {
              ...prevValues,
              [categoryIndex]: {
                ...prevValues[categoryIndex],
                parentLimit: updatedLimit,
              },
            };
          });
        } else {
          setLimitsLeft((prevValues) => {
            let updatedtotalLimit =
              (prevValues[parentCategoryIndex]?.['totalChildrenLimit'] || 0) +
              Number(change.newCell.value || 0) -
              Number(change.previousCell.value || 0);
            return {
              ...prevValues,
              [parentCategoryIndex]: {
                ...prevValues[parentCategoryIndex],
                totalChildrenLimit: updatedtotalLimit,
              },
            };
          });
        }
      }

      if (changedRow?.invalid?.length)
        changedRow.invalid = changedRow.invalid.filter((opt) => opt !== fieldName);

      setUpdatedCategories((prevValues) => {
        if (!parentCategoryIndex) {
          return {
            ...prevValues,
            [categoryIndex]: {
              updateType: 'edit',
              children: prevValues[categoryIndex]?.children
                ? {
                    ...prevValues[categoryIndex].children,
                  }
                : undefined,
            },
          };
        } else {
          return {
            ...prevValues,
            [parentCategoryIndex]: {
              ...(prevValues[parentCategoryIndex] || {}),
              children: {
                ...(prevValues[parentCategoryIndex]?.children || {}),
                [categoryIndex]: {
                  updateType: 'edit',
                },
              },
            },
          };
        }
      });

      if (changedRowId !== categoryIndex) setChangedRowId(categoryIndex);
    });

    return [...prevCategories];
  };

  const handleChanges = (changes) => {
    setCategories((prevCategories) => applyChangesToCategory(changes, prevCategories));
  };

  const cellTemplates = {
    customDropdown: new CustomDropdownTemplate(handleCustomDropdownRef, 40),
    modify: new ActionCellTemplate(),
    create: new CreateButtonCellTemplate(),
    amount: new AmountCellTemplate(),
    customText: new CustomTextCellTemplate(handleCustomTextRef, 250),
  };

  return (
    <CategoryHeader
      hasSaveChangesButton={true}
      hasEditButton={false}
      addExport={false}
      handleSaveChanges={handleSave}
      setFilteredQuery={setFilteredQuery}
      filteredQuery={filteredQuery}
      page="Edit"
    >
      <div
        ref={elementRef}
        className="mt-4"
        style={{ width: '100%', marginBottom: '2.5rem' }}
      >
        {fetchLoading ? (
          <TableLoading columns={['Category name*', 'Description', 'Limit', '']} />
        ) : (
          <TableGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges}
            customCellTemplates={cellTemplates}
            onSave={handleSave}
            onCancel={() => history.push('/compliances/categories')}
            loading={loading}
          />
        )}
      </div>
      {/* <FloatingCta /> */}
      {loading && <FullPageLoader />}
    </CategoryHeader>
  );
};
export default EditMultipleCategories;
