import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  GET_SPEND_REQUEST,
  GET_SPEND_SUCCESS,
  GET_SPEND_ERROR,
  GET_SPENDS_REQUEST,
  GET_SPENDS_SUCCESS,
  GET_SPENDS_ERROR,
  GET_SPEND_TRANSACTIONS_SUCCESS,
  GET_SPEND_TRANSACTIONS_ERROR,
  GET_SPEND_RECEIPTS_SUCCESS,
  GET_SPEND_RECEIPTS_ERROR,
  GET_SPEND_RECEIPTS_REQUEST,
  GET_SPEND_TRANSACTIONS_REQUEST,
  DELETE_SPEND_SUCCESS,
  DELETE_SPEND_ERROR,
  CANCEL_SPEND_SUCCESS,
  CANCEL_SPEND_ERROR,
  DELETE_SPEND_REQUEST,
  CANCEL_SPEND_REQUEST,
  SPEND_STATISTICS_SUCCESS,
  SPEND_STATISTICS_ERROR,
  SPEND_STATISTICS_REQUEST,
} from '../reducers/SpendReducer';
import { toastError, toastSuccess } from 'components/UI/toast';

async function getSpendStatistics(params = {}) {
  return await Axios.get('/spends/statistics', params);
}
function* handleGetSpendStatistics({ payload }) {
  try {
    const response = yield call(getSpendStatistics, payload);
    if (response) {
      yield put({
        type: SPEND_STATISTICS_SUCCESS,
        data: {
          ...response.data,
          meta: response.meta,
        },
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SPEND_STATISTICS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSpend(params = {}) {
  return await Axios.get('/spends/' + params.code);
}
function* handleGetSpend({ payload }) {
  try {
    const response = yield call(getSpend, payload);
    if (response) {
      yield put({
        type: GET_SPEND_SUCCESS,
        data: {
          ...response.data,
          meta: response.meta,
        },
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SPEND_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function cancelSpend(params = {}) {
  return await Axios.patch('/spends/' + params.code);
}
function* handleCancelSpend({ payload }) {
  try {
    const response = yield call(cancelSpend, payload);
    if (response) {
      yield put({
        type: CANCEL_SPEND_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CANCEL_SPEND_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteSpend(params = {}) {
  return await Axios.delete('/spends/' + params.code);
}
function* handleDeleteSpend({ payload }) {
  try {
    const response = yield call(deleteSpend, payload);
    if (response) {
      yield put({
        type: DELETE_SPEND_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_SPEND_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSpendTransactions(params = {}) {
  return await Axios.get('/spends/' + params.code + '/transactions');
}
function* handleGetSpendTransactions({ payload }) {
  try {
    const response = yield call(getSpendTransactions, payload);
    if (response) {
      yield put({
        type: GET_SPEND_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SPEND_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSpendReceipts(params = {}) {
  return await Axios.get('/spends/' + params.code + '/receipts');
}
function* handleGetSpendReceipts({ payload }) {
  try {
    const response = yield call(getSpendReceipts, payload);
    if (response) {
      yield put({
        type: GET_SPEND_RECEIPTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SPEND_RECEIPTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSpends(params = {}) {
  return await Axios.get('/spends', { params });
}
function* handleGetSpends({ query }) {
  try {
    const response = yield call(getSpends, query);
    if (response) {
      yield put({
        type: GET_SPENDS_SUCCESS,
        data: response.data,
        meta: response.meta,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SPENDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_SPEND_REQUEST, handleGetSpend),
  takeLatest(DELETE_SPEND_REQUEST, handleDeleteSpend),
  takeLatest(CANCEL_SPEND_REQUEST, handleCancelSpend),
  takeLatest(GET_SPENDS_REQUEST, handleGetSpends),
  takeLatest(GET_SPEND_TRANSACTIONS_REQUEST, handleGetSpendTransactions),
  takeLatest(GET_SPEND_RECEIPTS_REQUEST, handleGetSpendReceipts),
  takeLatest(SPEND_STATISTICS_REQUEST, handleGetSpendStatistics),
]);
