const PlanButton = ({ onClick, active, plan, textAddon, className }) => {
  return (
    <button
      disabled={active === plan.name || plan.name === 'scale'}
      onClick={() => onClick(plan)}
      className={className}
    >
      {plan.name === active && 'Current Plan'}
      {plan.name !== active && plan.name !== 'scale' && `${textAddon} to ${plan.name}`}
      {plan.name === 'scale' && 'Contact sales'}
    </button>
  );
};

export default PlanButton;
