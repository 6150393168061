import {
  CREATE_BILL_REQUEST,
  FETCH_BILLS_REQUEST,
  FETCH_SINGLE_BILL_REQUEST,
  UPDATE_BILL_REQUEST,
  UPDATE_SCHEDULED_BILL_REQUEST,
  PAY_BILL_REQUEST,
  DRAFT_BILLS_REQUEST,
  FETCH_SCHEDULED_BILLS_REQUEST,
  FETCH_SINGLE_SCHEDULED_BILL_REQUEST,
  DELETE_BILL_REQUEST,
  DELETE_SCHEDULED_BILL_REQUEST,
  FETCH_ARCHIVED_BILLS_REQUEST,
  MARK_BILL_AS_PAID_REQUEST,
  UPDATE_BILL_PAYLOAD,
  SET_BILL_DATA,
} from '../reducers/BillReducer';

export const createBillAction = (payload) => ({
  type: CREATE_BILL_REQUEST,
  payload,
});

export const updateBill = (payload) => ({
  type: UPDATE_BILL_REQUEST,
  payload,
});

export const updateScheduledBill = (payload) => ({
  type: UPDATE_SCHEDULED_BILL_REQUEST,
  payload,
});

export const fetchBills = (payload) => ({
  type: FETCH_BILLS_REQUEST,
  payload,
});

export const fetchDraftBills = (payload) => ({
  type: DRAFT_BILLS_REQUEST,
  payload,
});

export const fetchSingleBill = (payload) => ({
  type: FETCH_SINGLE_BILL_REQUEST,
  payload,
});

export const payBill = (payload) => ({
  type: PAY_BILL_REQUEST,
  payload,
});

export const fetchScheduledBills = (payload) => ({
  type: FETCH_SCHEDULED_BILLS_REQUEST,
  payload,
});

export const fetchSingleScheduledBill = (payload) => ({
  type: FETCH_SINGLE_SCHEDULED_BILL_REQUEST,
  payload,
});

export const deleteBill = (payload) => ({
  type: DELETE_BILL_REQUEST,
  payload,
});

export const deleteScheduledBill = (payload) => ({
  type: DELETE_SCHEDULED_BILL_REQUEST,
  payload,
});

export const fetchArchivedBills = (payload) => ({
  type: FETCH_ARCHIVED_BILLS_REQUEST,
  payload,
});

export const markBillAsPaid = (payload) => ({
  type: MARK_BILL_AS_PAID_REQUEST,
  payload,
});

export const updateBillPayload = (key, value) => ({
  type: UPDATE_BILL_PAYLOAD,
  payload: { key, value },
});

export const setBillPayload = (payload) => ({
  type: SET_BILL_DATA,
  payload,
});

export const addSingleBillDocument = (payload) => ({
  type: ADD_SINGLE_POLICY_DOCUMENT_REQUEST,
  payload,
});
