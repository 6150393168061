import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { FETCH_PENDING_APPROVALS_REQUEST } from 'redux/reducers/ApprovalReducer';
import { getSimplifiedError } from 'utils/error';
import {
  BULK_RETRY_TRANSACTIONS_ERROR,
  BULK_RETRY_TRANSACTIONS_REQUEST,
  BULK_RETRY_TRANSACTIONS_SUCCESS,
  CANCEL_TRANSACTION_ERROR,
  CANCEL_TRANSACTION_REQUEST,
  CANCEL_TRANSACTION_SUCCESS,
  EDIT_TRANSACTIONS_BY_ID_ERROR,
  EDIT_TRANSACTIONS_BY_ID_REQUEST,
  EDIT_TRANSACTIONS_BY_ID_SUCCESS,
  EXPORT_TRANSACTIONS_ERROR,
  EXPORT_TRANSACTIONS_REQUEST,
  EXPORT_TRANSACTIONS_SUCCESS,
  GET_ALL_TRANSACTION_ASSET,
  GET_ALL_TRANSACTION_ASSET_ERROR,
  GET_ALL_TRANSACTION_ASSET_SUCCESS,
  GET_FAILED_TRANSACTIONS_ERROR,
  GET_FAILED_TRANSACTIONS_REQUEST,
  GET_FAILED_TRANSACTIONS_SUCCESS,
  GET_SCHEDULED_TRANSACTIONS_ERROR,
  GET_SCHEDULED_TRANSACTIONS_REQUEST,
  GET_SCHEDULED_TRANSACTIONS_SUCCESS,
  GET_SELECTED_TRANSACTIONS_ERROR,
  GET_SELECTED_TRANSACTIONS_REQUEST,
  GET_SELECTED_TRANSACTIONS_SUCCESS,
  GET_SINGLE_SCHEDULED_TRANSACTIONS_ERROR,
  GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST,
  GET_SINGLE_SCHEDULED_TRANSACTIONS_SUCCESS,
  GET_SINGLE_TRANSACTIONS_ERROR,
  GET_SINGLE_TRANSACTIONS_REQUEST,
  GET_SINGLE_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  RESET_BLOCK_TRANSACTIONS,
  GET_TRANSFERS_ERROR,
  GET_TRANSFERS_REQUEST,
  GET_TRANSFERS_SUCCESS,
  GET_PAYOUTS_REQUEST,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_ERROR,
  RESET_FLAGS_TRANSACTIONS,
  RETRY_TRANSACTION_ERROR,
  RETRY_TRANSACTION_REQUEST,
  RETRY_TRANSACTION_SUCCESS,
  UPDATE_SCHEDULED_TRANSACTIONS_ERROR,
  UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_ERROR,
  UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST,
  UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_SUCCESS,
  UPDATE_SCHEDULED_TRANSACTIONS_REQUEST,
  UPDATE_SCHEDULED_TRANSACTIONS_SUCCESS,
  BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST,
  BULK_CANCEL_SCHEDULED_TRANSACTIONS_SUCCESS,
  BULK_CANCEL_SCHEDULED_TRANSACTIONS_ERROR,
  REQUERY_TRANSACTIONS_SUCCESS,
  REQUERY_TRANSACTIONS_ERROR,
  REQUERY_TRANSACTIONS_REQUEST,
  EDIT_BATCH_TRANSACTIONS_BY_ID_SUCCESS,
  EDIT_BATCH_TRANSACTIONS_BY_ID_ERROR,
  EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST,
  MASS_ASSIGN_TRANSACTION_SUCCESS,
  MASS_ASSIGN_TRANSACTION_ERROR,
  MASS_ASSIGN_TRANSACTION_REQUEST,
  GET_CUSTOMER_TRANSACTION_REQUEST,
  GET_CUSTOMER_TRANSACTION_SUCCESS,
  GET_CUSTOMER_TRANSACTION_ERROR,
  APPROVE_TRANSACTION_REQUEST,
  APPROVE_TRANSACTION_SUCCESS,
  APPROVE_TRANSACTION_ERROR,
  REQUEST_TRANSACTION_DETAILS_REQUEST,
  REQUEST_TRANSACTION_DETAILS_SUCCESS,
  REQUEST_TRANSACTION_DETAILS_ERROR,
  EXPORT_BATCH_TRANSACTIONS_REQUEST,
  EXPORT_BATCH_TRANSACTIONS_SUCCESS,
  EXPORT_BATCH_TRANSACTIONS_ERROR,
} from '../reducers/TransactionsReducer';

async function getTransaction({ payload = {} }) {
  return await Axios.get('/transactions/', { params: payload });
}

function* handleGetTransaction(payload) {
  try {
    const response = yield call(getTransaction, payload);

    if (response) {
      yield put({
        type: GET_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCustomerTransaction({ payload = {} }) {
  const { code, params } = payload;
  const url = `/customers/${code}/transactions${params ? `?${params}` : ''}`;
  return await Axios.get(url);
}

function* handleGetCustomerTransaction(payload) {
  try {
    const response = yield call(getCustomerTransaction, payload);
    if (response) {
      yield put({
        type: GET_CUSTOMER_TRANSACTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_CUSTOMER_TRANSACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getScheduledTransaction({ payload = {} }) {
  return await Axios.get('/payments/schedule', { params: payload });
}
function* handleGetScheduledTransaction(payload) {
  try {
    const response = yield call(getScheduledTransaction, payload);
    if (response) {
      yield put({
        type: GET_SCHEDULED_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SCHEDULED_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function massAssignCategory(payload) {
  return await Axios.put('/transactions/categories/mass-assign', payload);
}
async function massAssignPayer(payload) {
  return await Axios.put('/transactions/payers/mass-assign', payload);
}
async function massAssignRecipient(payload) {
  return await Axios.put('/transactions/recipients/mass-assign', payload);
}

function* handleMassAssignCategory({ category: payload }) {
  try {
    const response = yield call(massAssignCategory, payload);
    if (response) {
      toastSuccess(`Category: ${response.message}`);
      yield put({
        type: MASS_ASSIGN_TRANSACTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    let simplifiedError = getSimplifiedError(error);
    if (error) {
      toastError(`Category: ${simplifiedError}`);
    }
    yield put({
      type: MASS_ASSIGN_TRANSACTION_ERROR,
      error: `Category: ${simplifiedError}`,
    });
  }
}

function* handleMassAssignPayer({ payer: payload }) {
  try {
    const response = yield call(massAssignPayer, payload);
    if (response) {
      toastSuccess(`Payer: ${response.message}`);
      yield put({
        type: MASS_ASSIGN_TRANSACTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    let simplifiedError = getSimplifiedError(error);
    if (error) {
      toastError(`Payer: ${simplifiedError}`);
    }
    yield put({
      type: MASS_ASSIGN_TRANSACTION_ERROR,
      error: `Payer: ${simplifiedError}`,
    });
  }
}

function* handleMassAssignRecipient({ recipient: payload }) {
  try {
    const response = yield call(massAssignRecipient, payload);
    if (response) {
      toastSuccess(`Recipient: ${response.message}`);
      yield put({
        type: MASS_ASSIGN_TRANSACTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    let simplifiedError = getSimplifiedError(error);
    if (error) {
      toastError(`Recipient: ${simplifiedError}`);
    }
    yield put({
      type: MASS_ASSIGN_TRANSACTION_ERROR,
      error: `Recipient: ${simplifiedError}`,
    });
  }
}

function* handleMassAssignTransaction({ payload }) {
  let endpoints = {
    category: handleMassAssignCategory,
    payer: handleMassAssignPayer,
    recipient: handleMassAssignRecipient,
  };
  yield all(
    Object.keys(payload).map((key) => {
      if (!!payload[key]) {
        return call(endpoints[key], payload);
      }
    }),
  );
}

async function getSingleScheduledTransaction(code) {
  return await Axios.get(`/payments/schedule/${code}`);
}
function* handleGetSingleScheduledTransaction({ payload }) {
  try {
    const response = yield call(getSingleScheduledTransaction, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_SCHEDULED_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_SCHEDULED_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function approveTransaction(code) {
  return await Axios.post(`/transactions/${code}/override-policy`);
}

function* handleApproveTransaction({ payload }) {
  try {
    const response = yield call(approveTransaction, payload);
    if (response) {
      yield put({
        type: APPROVE_TRANSACTION_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'approveTransaction',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: APPROVE_TRANSACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function requestTransactionDetails(code) {
  return await Axios.get(`/transactions/${code}/request-details`);
}

function* handleRequestTransactionDetails({ payload }) {
  try {
    const response = yield call(requestTransactionDetails, payload);
    if (response) {
      yield put({
        type: REQUEST_TRANSACTION_DETAILS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REQUEST_TRANSACTION_DETAILS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateScheduledTransaction({ payload }) {
  const code = payload.code;
  delete payload.code;
  return await Axios.patch(`/payments/schedule/${code}`, payload);
}
function* handleUpdateScheduledTransaction(payload) {
  try {
    const response = yield call(updateScheduledTransaction, payload);
    if (response) {
      yield put({
        type: UPDATE_SCHEDULED_TRANSACTIONS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'updateScheduledTransaction',
      });

      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_SCHEDULED_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function bulkCancelScheduledTransaction({ payload }) {
  return await Axios.patch(`/payments/schedule/bulk-cancel`, payload);
}
function* handleBulkCancelScheduledTransaction(payload) {
  try {
    const response = yield call(bulkCancelScheduledTransaction, payload);
    if (response) {
      yield put({
        type: BULK_CANCEL_SCHEDULED_TRANSACTIONS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: BULK_CANCEL_SCHEDULED_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateScheduledTransactionReceipt({ payload }) {
  const code = payload.code;
  delete payload.code;
  return await Axios.patch(`payments/schedule/${code}/upload`, payload);
}
function* handleUpdateScheduledTransactionReceipt(payload) {
  try {
    const response = yield call(updateScheduledTransactionReceipt, payload);
    if (response) {
      yield put({
        type: UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'updateScheduledTransactionReceipt',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getTransfers({ payload }) {
  const { code, type, ...rest } = payload;
  return await Axios.get('/balances/' + code + '/' + type, { params: rest });
}

async function getPayouts({ payload }) {
  const { code, type, ...rest } = payload;
  return await Axios.get('/balances/' + code + '/' + type, { params: rest });
}
function* handleGetTransfers(payload) {
  try {
    const response = yield call(getTransfers, payload);
    if (response) {
      yield put({
        type: GET_TRANSFERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_TRANSFERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetPayouts(payload) {
  try {
    const response = yield call(getPayouts, payload);
    if (response) {
      yield put({
        type: GET_PAYOUTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_PAYOUTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getFailedTransactions({ payload = {} }) {
  return await Axios.get('/transactions/', { params: payload });
}
function* handleGetFailedTransaction(payload) {
  try {
    const response = yield call(getFailedTransactions, payload);
    if (response) {
      yield put({
        type: GET_FAILED_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_FAILED_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function requeryTransaction(code) {
  return await Axios.patch(`/transactions/${code}/requery`);
}
function* handleRequeryTransaction({ payload }) {
  try {
    const response = yield call(requeryTransaction, payload);
    if (response) {
      yield put({
        type: REQUERY_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'requeryTransaction',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REQUERY_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

///transactions/trx_hUOPQPvG1VzaNzeX9/requery
async function getSelectedTransaction({ payload = {} }) {
  return await Axios.get('/transactions/', { params: payload });
}
function* handleGetSelectedTransaction(payload) {
  try {
    const response = yield call(getSelectedTransaction, payload);
    if (response) {
      yield put({
        type: GET_SELECTED_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SELECTED_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function exportTransaction({ payload = {} }) {
  return await Axios.get('/transactions/export', { params: payload });
}
function* handleExportTransaction(payload) {
  try {
    const response = yield call(exportTransaction, payload);
    if (response) {
      yield put({
        type: EXPORT_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EXPORT_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function exportBatchTransaction({ payload = {} }) {
  return await Axios.get('/payments/batch/export', { params: payload });
}
function* handleExportBatchTransaction(payload) {
  try {
    const response = yield call(exportBatchTransaction, payload);
    if (response) {
      yield put({
        type: EXPORT_BATCH_TRANSACTIONS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EXPORT_BATCH_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleTransaction(payload) {
  return await Axios.get(`/transactions/${payload}`);
}
function* handleSingleTransaction({ payload }) {
  try {
    const response = yield call(singleTransaction, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_TRANSACTIONS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function editTransactionById({ restPayload: payload }) {
  const entries = Object.entries(payload);
  let name = entries[0][0];
  let value = entries[0][1];
  const data = {
    [name]: value,
  };
  return await Axios.put(`/transactions/${payload.transaction_code}`, data);
}

function* handleEditTransactionById({ payload }) {
  const { showSuccessToast, ...restPayload } = payload;
  try {
    const response = yield call(editTransactionById, { restPayload });
    if (response) {
      if (showSuccessToast !== false) toastSuccess(response.message);
      yield put({
        type: EDIT_TRANSACTIONS_BY_ID_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EDIT_TRANSACTIONS_BY_ID_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function editBatchTransactionById({ restPayload: payload }) {
  const { transaction_code, ...rest } = payload;

  return await Axios.put(`/batch/${transaction_code}`, rest);
}

function* handleEditBatchTransactionById({ payload }) {
  const { showSuccessToast, delay: delayTime, ...restPayload } = payload;
  try {
    const response = yield call(editBatchTransactionById, { restPayload });
    if (response) {
      if (showSuccessToast !== false) toastSuccess(response.message);
      yield put({
        type: EDIT_BATCH_TRANSACTIONS_BY_ID_SUCCESS,
        data: response,
      });

      yield delay(delayTime ?? 300);
      yield put({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'editBatchTransactionById',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EDIT_BATCH_TRANSACTIONS_BY_ID_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function retryTransaction(code) {
  return await Axios.patch(`/transactions/${code}/retry`);
}

function* handleRetryTransaction({ payload }) {
  try {
    const response = yield call(retryTransaction, payload);
    if (response) {
      yield put({
        type: RETRY_TRANSACTION_SUCCESS,
        data: response,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'retryTransaction',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RETRY_TRANSACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function cancelTransaction(code) {
  return await Axios.patch(`/transactions/${code}/cancel`);
}
function* handleCancelTransaction({ payload }) {
  try {
    const response = yield call(cancelTransaction, payload);
    if (response) {
      yield put({
        type: CANCEL_TRANSACTION_SUCCESS,
        data: response,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_FLAGS_TRANSACTIONS,
        blockType: 'cancelTransaction',
      });

      yield put({
        type: FETCH_PENDING_APPROVALS_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CANCEL_TRANSACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function bulkRetryTransaction(payload) {
  return await Axios.patch(`/transactions/bulk-retrial`, payload);
}

function* handleBulkRetryTransaction({ payload }) {
  try {
    const response = yield call(bulkRetryTransaction, payload);
    if (response) {
      yield put({
        type: BULK_RETRY_TRANSACTIONS_SUCCESS,
        data: response,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_FLAGS_TRANSACTIONS,
        blockType: 'bulkRetryTransactions',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: BULK_RETRY_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetTransactionAsset({ payload }) {
  const assetData = [];

  try {
    for (const item of payload) {
      const getAsset = (item) => {
        const code = item?.code;
        if (code) return Axios.get(`/assets/${code}`);
      };
      const response = yield call(getAsset, item);
      const {
        data: { asset },
      } = response;

      assetData.push({
        ...asset,
      });
    }

    yield put({
      type: GET_ALL_TRANSACTION_ASSET_SUCCESS,
      data: assetData,
    });
  } catch (error) {
    yield put({
      type: GET_ALL_TRANSACTION_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_TRANSACTIONS_REQUEST, handleGetTransaction),
  takeLatest(GET_SCHEDULED_TRANSACTIONS_REQUEST, handleGetScheduledTransaction),
  takeLatest(
    GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST,
    handleGetSingleScheduledTransaction,
  ),
  takeLatest(UPDATE_SCHEDULED_TRANSACTIONS_REQUEST, handleUpdateScheduledTransaction),
  takeLatest(
    BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST,
    handleBulkCancelScheduledTransaction,
  ),
  takeLatest(
    UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST,
    handleUpdateScheduledTransactionReceipt,
  ),
  takeLatest(GET_TRANSFERS_REQUEST, handleGetTransfers),
  takeLatest(APPROVE_TRANSACTION_REQUEST, handleApproveTransaction),
  takeLatest(REQUEST_TRANSACTION_DETAILS_REQUEST, handleRequestTransactionDetails),
  takeLatest(GET_PAYOUTS_REQUEST, handleGetPayouts),
  takeLatest(MASS_ASSIGN_TRANSACTION_REQUEST, handleMassAssignTransaction),
  takeLatest(GET_SELECTED_TRANSACTIONS_REQUEST, handleGetSelectedTransaction),
  takeLatest(EXPORT_TRANSACTIONS_REQUEST, handleExportTransaction),
  takeLatest(EXPORT_BATCH_TRANSACTIONS_REQUEST, handleExportBatchTransaction),
  takeLatest(GET_SINGLE_TRANSACTIONS_REQUEST, handleSingleTransaction),
  takeLatest(EDIT_TRANSACTIONS_BY_ID_REQUEST, handleEditTransactionById),
  takeLatest(CANCEL_TRANSACTION_REQUEST, handleCancelTransaction),
  takeLatest(RETRY_TRANSACTION_REQUEST, handleRetryTransaction),
  takeLatest(GET_FAILED_TRANSACTIONS_REQUEST, handleGetFailedTransaction),
  takeLatest(BULK_RETRY_TRANSACTIONS_REQUEST, handleBulkRetryTransaction),
  takeLatest(GET_ALL_TRANSACTION_ASSET, handleGetTransactionAsset),
  takeLatest(REQUERY_TRANSACTIONS_REQUEST, handleRequeryTransaction),
  takeLatest(EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST, handleEditBatchTransactionById),
  takeLatest(GET_CUSTOMER_TRANSACTION_REQUEST, handleGetCustomerTransaction),
]);
