export const CREATE_POLICIES_REQUEST = 'CREATE_POLICIES_REQUEST';
export const CREATE_POLICIES_SUCCESS = 'CREATE_POLICIES_SUCCESS';
export const CREATE_POLICIES_ERROR = 'CREATE_POLICIES_ERROR';

export const ADD_POLICY_DOCUMENT_REQUEST = 'ADD_POLICY_DOCUMENT_REQUEST';
export const ADD_POLICY_DOCUMENT_SUCCESS = 'ADD_POLICY_DOCUMENT_SUCCESS';
export const ADD_POLICY_DOCUMENT_ERROR = 'ADD_POLICY_DOCUMENT_ERROR';

export const ADD_SINGLE_POLICY_DOCUMENT_REQUEST = 'ADD_SINGLE_POLICY_DOCUMENT_REQUEST';
export const ADD_SINGLE_POLICY_DOCUMENT_SUCCESS = 'ADD_SINGLE_POLICY_DOCUMENT_SUCCESS';
export const ADD_SINGLE_POLICY_DOCUMENT_ERROR = 'ADD_SINGLE_POLICY_DOCUMENT_ERROR';

export const GET_POLICY_TYPES_REQUEST = 'GET_POLICY_TYPES_REQUEST';
export const GET_POLICY_TYPES_SUCCESS = 'GET_POLICY_TYPES_SUCCESS';
export const GET_POLICY_TYPES_ERROR = 'GET_POLICY_TYPES_ERROR';

export const GET_POLICIES_REQUEST = 'GET_POLICIES_REQUEST';
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS';
export const GET_POLICIES_ERROR = 'GET_POLICIES_ERROR';

export const GET_POLICY_DOCUMENTS_REQUEST = 'GET_POLICY_DOCUMENTS_REQUEST';
export const GET_POLICY_DOCUMENTS_SUCCESS = 'GET_POLICY_DOCUMENTS_SUCCESS';
export const GET_POLICY_DOCUMENTS_ERROR = 'GET_POLICY_DOCUMENTS_ERROR';

export const GET_SINGLE_BUDGET_POLICIES_REQUEST = 'GET_SINGLE_BUDGET_POLICIES_REQUEST';
export const GET_SINGLE_BUDGET_POLICIES_SUCCESS = 'GET_SINGLE_BUDGET_POLICIES_SUCCESS';
export const GET_SINGLE_BUDGET_POLICIES_ERROR = 'GET_SINGLE_BUDGET_POLICIES_ERROR';

export const FETCH_SINGLE_POLICY_REQUEST = 'FETCH_SINGLE_POLICY_REQUEST';
export const FETCH_SINGLE_POLICY_SUCCESS = 'FETCH_SINGLE_POLICY_SUCCESS';
export const FETCH_SINGLE_POLICY_ERROR = 'FETCH_SINGLE_POLICY_ERROR';

export const EDIT_POLICY_REQUEST = 'EDIT_POLICY_REQUEST';
export const EDIT_POLICY_SUCCESS = 'EDIT_POLICY_SUCCESS';
export const EDIT_POLICY_ERROR = 'EDIT_POLICY_ERROR';

export const PAUSE_BUDGET_POLICY_REQUEST = 'PAUSE_BUDGET_POLICY_REQUEST';
export const PAUSE_BUDGET_POLICY_SUCCESS = 'PAUSE_BUDGET_POLICY_SUCCESS';
export const PAUSE_BUDGET_POLICY_ERROR = 'PAUSE_BUDGET_POLICY_ERROR';

export const DELETE_POLICY_REQUEST = 'DELETE_POLICY_REQUEST';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_ERROR = 'DELETE_POLICY_ERROR';

export const DELETE_POLICY_DOCUMENT_REQUEST = 'DELETE_POLICY_DOCUMENT_REQUEST';
export const DELETE_POLICY_DOCUMENT_SUCCESS = 'DELETE_POLICY_DOCUMENT_SUCCESS';
export const DELETE_POLICY_DOCUMENT_ERROR = 'DELETE_POLICY_DOCUMENT_ERROR';

export const LINK_BUDGET_POLICY_REQUEST = 'LINK_BUDGET_POLICY_REQUEST';
export const LINK_BUDGET_POLICY_SUCCESS = 'LINK_BUDGET_POLICY_SUCCESS';
export const LINK_BUDGET_POLICY_ERROR = 'LINK_BUDGET_POLICY_ERROR';

export const UNLINK_BUDGET_POLICY_REQUEST = 'UNLINK_BUDGET_POLICY_REQUEST';
export const UNLINK_BUDGET_POLICY_SUCCESS = 'UNLINK_BUDGET_POLICY_SUCCESS';
export const UNLINK_BUDGET_POLICY_ERROR = 'UNLINK_BUDGET_POLICY_ERROR';

export const GET_ALL_POLICY_ASSET_REQUEST = 'GET_ALL_POLICY_ASSET_REQUEST';
export const GET_ALL_POLICY_ASSET_SUCCESS = 'GET_ALL_POLICY_ASSET_SUCCESS';
export const GET_ALL_POLICY_ASSET_ERROR = 'GET_ALL_POLICY_ASSET_ERROR';

export const GET_SINGLE_POLICY = 'GET_SINGLE_POLICY';

export const RESET_BLOCK_POLICIES = 'RESET_BLOCK_POLICIES';

export const RESET_FLAGS_POLICIES = 'RESET_FLAGS_POLICIES';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  createPolicy: { ...block },
  addPolicyDocument: { ...block },
  addSinglePolicyDocument: { ...block },
  getPolicyTypes: { ...block },
  getPolicyDocuments: { ...block },
  getPolicies: { ...block },
  getSingleBudgetPolicies: { ...block },
  getSinglePolicy: { data: {} },
  editPolicy: { ...block },
  fetchSinglePolicy: { ...block },
  pauseSingleBudget: { ...block },
  deletePolicy: { ...block },
  deletePolicyDocument: { ...block },
  linkBudgetPolicy: { ...block },
  unlinkBudgetPolicy: { ...block },
  policyAssets: { ...block },
};

export const PoliciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POLICIES_REQUEST:
      return { ...state, createPolicy: { ...state.createPolicy, loading: true } };
    case CREATE_POLICIES_SUCCESS:
      return {
        ...state,
        createPolicy: {
          ...state.createPolicy,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_POLICIES_ERROR:
      return {
        ...state,
        createPolicy: { ...state.createPolicy, loading: false, error: action.error },
      };

    case ADD_POLICY_DOCUMENT_REQUEST:
      return {
        ...state,
        addPolicyDocument: { ...state.addPolicyDocument, loading: true },
      };
    case ADD_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state,
        addPolicyDocument: {
          ...state.addPolicyDocument,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_POLICY_DOCUMENT_ERROR:
      return {
        ...state,
        addPolicyDocument: {
          ...state.addPolicyDocument,
          loading: false,
          error: action.error,
        },
      };

    case ADD_SINGLE_POLICY_DOCUMENT_REQUEST:
      return {
        ...state,
        addSinglePolicyDocument: { ...state.addSinglePolicyDocument, loading: true },
      };
    case ADD_SINGLE_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state,
        addSinglePolicyDocument: {
          ...state.addSinglePolicyDocument,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_SINGLE_POLICY_DOCUMENT_ERROR:
      return {
        ...state,
        addSinglePolicyDocument: {
          ...state.addSinglePolicyDocument,
          loading: false,
          error: action.error,
        },
      };

    case EDIT_POLICY_REQUEST:
      return { ...state, editPolicy: { ...state.editPolicy, loading: true } };
    case EDIT_POLICY_SUCCESS:
      return {
        ...state,
        editPolicy: {
          ...state.editPolicy,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EDIT_POLICY_ERROR:
      return {
        ...state,
        editPolicy: { ...state.editPolicy, loading: false, error: action.error },
      };

    case GET_POLICY_TYPES_REQUEST:
      return { ...state, getPolicyTypes: { ...state.getPolicyTypes, loading: true } };
    case GET_POLICY_TYPES_SUCCESS:
      return {
        ...state,
        getPolicyTypes: {
          ...state.getPolicyTypes,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_POLICY_TYPES_ERROR:
      return {
        ...state,
        getPolicyTypes: { ...state.getPolicyTypes, loading: false, error: action.error },
      };

    case GET_ALL_POLICY_ASSET_REQUEST:
      return { ...state, policyAssets: { ...state.policyAssets, loading: true } };
    case GET_ALL_POLICY_ASSET_SUCCESS:
      return {
        ...state,
        policyAssets: {
          ...state.policyAssets,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ALL_POLICY_ASSET_ERROR:
      return {
        ...state,
        policyAssets: { ...state.policyAssets, loading: false, error: action.error },
      };

    case GET_POLICY_DOCUMENTS_REQUEST:
      return {
        ...state,
        getPolicyDocuments: { ...state.getPolicyDocuments, loading: true },
      };
    case GET_POLICY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getPolicyDocuments: {
          ...state.getPolicyDocuments,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_POLICY_DOCUMENTS_ERROR:
      return {
        ...state,
        getPolicyDocuments: {
          ...state.getPolicyDocuments,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_SINGLE_POLICY_REQUEST:
      return {
        ...state,
        fetchSinglePolicy: { ...state.fetchSinglePolicy, loading: true },
      };
    case FETCH_SINGLE_POLICY_SUCCESS:
      return {
        ...state,
        fetchSinglePolicy: {
          ...state.fetchSinglePolicy,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_SINGLE_POLICY_ERROR:
      return {
        ...state,
        fetchSinglePolicy: {
          ...state.fetchSinglePolicy,
          loading: false,
          error: action.error,
        },
      };

    case GET_POLICIES_REQUEST:
      return { ...state, getPolicies: { ...state.getPolicies, loading: true } };
    case GET_POLICIES_SUCCESS:
      return {
        ...state,
        getPolicies: {
          ...state.getPolicies,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_POLICIES_ERROR:
      return {
        ...state,
        getPolicies: { ...state.getPolicies, loading: false, error: action.error },
      };

    case GET_SINGLE_BUDGET_POLICIES_REQUEST:
      return {
        ...state,
        getSingleBudgetPolicies: { ...state.getSingleBudgetPolicies, loading: true },
      };
    case GET_SINGLE_BUDGET_POLICIES_SUCCESS:
      return {
        ...state,
        getSingleBudgetPolicies: {
          ...state.getSingleBudgetPolicies,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_BUDGET_POLICIES_ERROR:
      return {
        ...state,
        getSingleBudgetPolicies: {
          ...state.getSingleBudgetPolicies,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_POLICY_REQUEST:
      return { ...state, deletePolicy: { ...state.deletePolicy, loading: true } };
    case DELETE_POLICY_SUCCESS:
      return {
        ...state,
        deletePolicy: {
          ...state.deletePolicy,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_POLICY_ERROR:
      return {
        ...state,
        deletePolicy: { ...state.deletePolicy, loading: false, error: action.error },
      };

    case DELETE_POLICY_DOCUMENT_REQUEST:
      return {
        ...state,
        deletePolicyDocument: { ...state.deletePolicyDocument, loading: true },
      };
    case DELETE_POLICY_DOCUMENT_SUCCESS:
      return {
        ...state,
        deletePolicyDocument: {
          ...state.deletePolicyDocument,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_POLICY_DOCUMENT_ERROR:
      return {
        ...state,
        deletePolicyDocument: {
          ...state.deletePolicyDocument,
          loading: false,
          error: action.error,
        },
      };

    case LINK_BUDGET_POLICY_REQUEST:
      return { ...state, linkBudgetPolicy: { ...state.linkBudgetPolicy, loading: true } };
    case LINK_BUDGET_POLICY_SUCCESS:
      return {
        ...state,
        linkBudgetPolicy: {
          ...state.linkBudgetPolicy,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case LINK_BUDGET_POLICY_ERROR:
      return {
        ...state,
        linkBudgetPolicy: {
          ...state.linkBudgetPolicy,
          loading: false,
          error: action.error,
        },
      };

    case UNLINK_BUDGET_POLICY_REQUEST:
      return {
        ...state,
        unlinkBudgetPolicy: { ...state.unlinkBudgetPolicy, loading: true },
      };
    case UNLINK_BUDGET_POLICY_SUCCESS:
      return {
        ...state,
        unlinkBudgetPolicy: {
          ...state.unlinkBudgetPolicy,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UNLINK_BUDGET_POLICY_ERROR:
      return {
        ...state,
        unlinkBudgetPolicy: {
          ...state.unlinkBudgetPolicy,
          loading: false,
          error: action.error,
        },
      };

    case PAUSE_BUDGET_POLICY_REQUEST:
      return {
        ...state,
        pauseSingleBudget: { ...state.pauseSingleBudget, loading: true },
      };
    case PAUSE_BUDGET_POLICY_SUCCESS:
      return {
        ...state,
        pauseSingleBudget: {
          ...state.pauseSingleBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case PAUSE_BUDGET_POLICY_ERROR:
      return {
        ...state,
        pauseSingleBudget: {
          ...state.pauseSingleBudget,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_POLICY:
      return {
        ...state,
        getSinglePolicy: { data: action.payload },
      };

    //reset block with flag and data
    case RESET_BLOCK_POLICIES:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_POLICIES:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
