import React, { useEffect } from 'react';
import { WarningFilled, CloseOutlined } from '@ant-design/icons';
import './styles.scss';

const NotificationModal = ({
  handleClose = () => null,
  title = '',
  icon,
  message = '',
  withLink = [],
}) => {
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 5000);
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="close-icon-notification" onClick={handleClose}>
        <CloseOutlined />
      </div>
      <div className="icon-color">{icon ? icon : <WarningFilled />}</div>
      <div className="ps-3">
        <h6 className="notification-title">{title}</h6>
        <p className="notification-sub-title">{message}</p>
        {withLink.length > 0 && (
          <div className="actions-list">
            {withLink.map((link, i) => (
              <span onClick={link.click} key={i}>
                {link.title}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default NotificationModal;
