import { Axios } from 'api/axios';
import { toastError } from 'components/UI/toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FIND_COMPANY_NAME_ERROR,
  FIND_COMPANY_NAME_REQUEST,
  FIND_COMPANY_NAME_SUCCESS,
  GET_DIRECTOR_DETAILS_ERROR,
  GET_DIRECTOR_DETAILS_SUCCESS,
} from 'redux/reducers/UtilsReducer';
import { getSimplifiedError } from 'utils/error';

async function findCompanyName(params) {
  return await Axios.get(`/utils/lookup`, { params });
}
function* handleFindCompanyName({ payload }) {
  try {
    const response = yield call(findCompanyName, payload);
    if (response) {
      yield put({
        type: FIND_COMPANY_NAME_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: FIND_COMPANY_NAME_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getDirectorDetails(params) {
  return await Axios.get(`/utils/lookup/shareholders`, { params });
}
function* handleGetDirectorDetails({ payload }) {
  try {
    const response = yield call(getDirectorDetails, payload);
    if (response) {
      yield put({
        type: GET_DIRECTOR_DETAILS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_DIRECTOR_DETAILS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FIND_COMPANY_NAME_REQUEST, handleFindCompanyName),
  takeLatest(GET_DIRECTOR_DETAILS_ERROR, handleGetDirectorDetails),
]);
