import { Skeleton } from 'antd';

const TableLoading = () => {
  return (
    <div style={{ marginTop: '30px' }}>
      {Array.from({ length: 6 }).map((_, index) => {
        return (
          <Skeleton.Input
            active
            key={index}
            size={40}
            block={true}
            style={{ width: '100%', marginBottom: '15px' }}
          />
        );
      })}
    </div>
  );
};
export default TableLoading;
