import { searchRecipients } from 'redux/actions/BeneficiariesAction';

export const CREATE_BENEFICIARIES_REQUEST = 'CREATE_BENEFICIARIES_REQUEST';
export const CREATE_BENEFICIARIES_SUCCESS = 'CREATE_BENEFICIARIES_SUCCESS';
export const CREATE_BENEFICIARIES_ERROR = 'CREATE_BENEFICIARIES_ERROR';

export const BULK_CREATE_BENEFICIARIES_REQUEST = 'BULK_CREATE_BENEFICIARIES_REQUEST';
export const BULK_CREATE_BENEFICIARIES_SUCCESS = 'BULK_CREATE_BENEFICIARIES_SUCCESS';
export const BULK_CREATE_BENEFICIARIES_ERROR = 'BULK_CREATE_BENEFICIARIES_ERROR';

export const BUILD_BULK_BENEFICIARIES_PAYLOAD = 'BUILD_BULK_BENEFICIARIES_PAYLOAD';
export const FILTER_BENEFICIARIES = 'FILTER_BENEFICIARIES';

export const GET_BENEFICIARIES_REQUEST = 'GET_BENEFICIARIES_REQUEST';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS';
export const GET_BENEFICIARIES_ERROR = 'GET_BENEFICIARIES_ERROR';

export const UPDATE_BENEFICIARIES_REQUEST = 'UPDATE_BENEFICIARIES_REQUEST';
export const UPDATE_BENEFICIARIES_SUCCESS = 'UPDATE_BENEFICIARIES_SUCCESS';
export const UPDATE_BENEFICIARIES_ERROR = 'UPDATE_BENEFICIARIES_ERROR';

export const SEARCH_RECIPIENTS_REQUEST = 'SEARCH_RECIPIENTS_REQUEST';
export const SEARCH_RECIPIENTS_SUCCESS = 'SEARCH_RECIPIENTS_SUCCESS';
export const SEARCH_RECIPIENTS_ERROR = 'SEARCH_RECIPIENTS_ERROR';

export const SEARCH_RECIPIENT_LIST_REQUEST = 'SEARCH_RECIPIENT_LIST_REQUEST';

export const RESET_BLOCK_BENEFICIARIE = 'RESET_BLOCK_BENEFICIARIE';

export const RESET_FLAGS_BENEFICIARIE = 'RESET_FLAGS_BENEFICIARIE';

export const GET_INVITED_BENEFICIARIES_REQUEST = 'GET_INVITED_BENEFICIARIES_REQUEST';
export const GET_INVITED_BENEFICIARIES_SUCCESS = 'GET_INVITED_BENEFICIARIES_SUCCESS';
export const GET_INVITED_BENEFICIARIES_ERROR = 'GET_INVITED_BENEFICIARIES_ERROR';

export const RESEND_BENEFICIARY_INVITE_REQUEST = 'RESEND_BENEFICIARY_INVITE_REQUEST';
export const RESEND_BENEFICIARY_INVITE_SUCCESS = 'RESEND_BENEFICIARY_INVITE_SUCCESS';
export const RESEND_BENEFICIARY_INVITE_ERROR = 'RESEND_BENEFICIARY_INVITE_ERROR';

export const ADD_BENEFICIARIES_BANK_REQUEST = 'ADD_BENEFICIARIES_BANK_REQUEST';
export const ADD_BENEFICIARIES_BANK_SUCCESS = 'ADD_BENEFICIARIES_BANK_SUCCESS';
export const ADD_BENEFICIARIES_BANK_ERROR = 'ADD_BENEFICIARIES_BANK_ERROR';

export const GET_BENEFICIARIES_BANK_REQUEST = 'GET_BENEFICIARIES_BANK_REQUEST';
export const GET_BENEFICIARIES_BANK_SUCCESS = 'GET_BENEFICIARIES_BANK_SUCCESS';
export const GET_BENEFICIARIES_BANK_ERROR = 'GET_BENEFICIARIES_BANK_ERROR';

export const DEFAULT_BENEFICIARIES_BANK_REQUEST = 'DEFAULT_BENEFICIARIES_BANK_REQUEST';
export const DEFAULT_BENEFICIARIES_BANK_SUCCESS = 'DEFAULT_BENEFICIARIES_BANK_SUCCESS';
export const DEFAULT_BENEFICIARIES_BANK_ERROR = 'DEFAULT_BENEFICIARIES_BANK_ERROR';

export const DELETE_BENEFICIARIES_BANK_REQUEST = 'DELETE_BENEFICIARIES_BANK_REQUEST';
export const DELETE_BENEFICIARIES_BANK_SUCCESS = 'DELETE_BENEFICIARIES_BANK_SUCCESS';
export const DELETE_BENEFICIARIES_BANK_ERROR = 'DELETE_BENEFICIARIES_BANK_ERROR';

export const DISABLED_BENEFICIARIES_REQUEST = 'DISABLED_BENEFICIARIES_REQUEST';
export const DISABLED_BENEFICIARIES_SUCCESS = 'DISABLED_BENEFICIARIES_SUCCESS';
export const DISABLED_BENEFICIARIES_ERROR = 'DISABLED_BENEFICIARIES_ERROR';

export const DELETE_BENEFICIARIES_REQUEST = 'DELETE_BENEFICIARIES_REQUEST';
export const DELETE_BENEFICIARIES_SUCCESS = 'DELETE_BENEFICIARIES_SUCCESS';
export const DELETE_BENEFICIARIES_ERROR = 'DELETE_BENEFICIARIES_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getBeneficiaries: { ...block },
  getInvitedBeneficiaryDetails: { ...block },
  createBeneficiaries: { ...block },
  bulkCreateBeneficiaries: { ...block },
  bulkBeneficiariesPayload: { ids: [], cancel: false, data: [], actionType: null },
  updateBeneficiaries: { ...block },
  addBeneficiaryBank: { ...block },
  defaultBeneficiaryBank: { ...block },
  deleteBeneficiaryBank: { ...block },
  getBeneficiaryBank: { ...block },
  searchRecipients: { ...block },
  searchRecipientList: { ...block },
  resendBeneficiaryInvite: { ...block },
  disableBeneficiary: { ...block },
  deleteBeneficiaries: { ...block },
  isFilterBeneficiaries: false,
};

export const BeneficiariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BENEFICIARIES_REQUEST:
      return { ...state, getBeneficiaries: { ...state.getBeneficiaries, loading: true } };
    case GET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getBeneficiaries: {
          ...state.getBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BENEFICIARIES_ERROR:
      return {
        ...state,
        getBeneficiaries: {
          ...state.getBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        createBeneficiaries: { ...state.createBeneficiaries, loading: true },
      };
    case CREATE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        createBeneficiaries: {
          ...state.createBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
        // getBeneficiaries: {
        //   ...state.getBeneficiaries,
        //   data: {
        //     ...state.getBeneficiaries.data,
        //     beneficiaries: [
        //       ...state.getBeneficiaries.data.beneficiaries,
        //       action.data.data,
        //     ],
        //   },
        // },
      };
    case CREATE_BENEFICIARIES_ERROR:
      return {
        ...state,
        createBeneficiaries: {
          ...state.createBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case BULK_CREATE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        bulkCreateBeneficiaries: { ...state.bulkCreateBeneficiaries, loading: true },
      };
    case BULK_CREATE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        bulkCreateBeneficiaries: {
          ...state.bulkCreateBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BULK_CREATE_BENEFICIARIES_ERROR:
      return {
        ...state,
        bulkCreateBeneficiaries: {
          ...state.bulkCreateBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case BUILD_BULK_BENEFICIARIES_PAYLOAD:
      return {
        ...state,
        bulkBeneficiariesPayload: {
          ids: action?.payload?.ids ?? state.bulkBeneficiariesPayload.ids,
          cancel: action?.payload?.cancel ?? state.bulkBeneficiariesPayload.cancel,
          data: action?.payload?.payload ?? state.bulkBeneficiariesPayload.data,
          actionType: action?.payload?.actionType ?? null,
        },
      };

    case UPDATE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        updateBeneficiaries: {
          ...state.updateBeneficiaries,
          loading: true,
        },
      };
    case UPDATE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        updateBeneficiaries: {
          ...state.updateBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_BENEFICIARIES_ERROR:
      return {
        ...state,
        updateBeneficiaries: {
          ...state.updateBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case DISABLED_BENEFICIARIES_REQUEST:
      return {
        ...state,
        disableBeneficiary: {
          ...state.disableBeneficiary,
          loading: true,
        },
      };
    case DISABLED_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        disableBeneficiary: {
          ...state.disableBeneficiary,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DISABLED_BENEFICIARIES_ERROR:
      return {
        ...state,
        disableBeneficiary: {
          ...state.disableBeneficiary,
          loading: false,
          error: action.error,
        },
      };

    case SEARCH_RECIPIENTS_REQUEST:
      return {
        ...state,
        searchRecipients: { ...state.searchRecipients, loading: true },
      };
    case SEARCH_RECIPIENTS_SUCCESS:
      return {
        ...state,
        searchRecipients: {
          ...state.searchRecipients,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SEARCH_RECIPIENTS_ERROR:
      return {
        ...state,
        searchRecipients: {
          ...state.searchRecipients,
          loading: false,
          error: action.error,
        },
      };

    case SEARCH_RECIPIENT_LIST_REQUEST:
      return {
        ...state,
        searchRecipients: { ...state.searchRecipients, loading: true },
      };

    //
    case GET_INVITED_BENEFICIARIES_REQUEST:
      return {
        ...state,
        getInvitedBeneficiaryDetails: {
          ...state.getInvitedBeneficiaryDetails,
          loading: true,
        },
      };
    case GET_INVITED_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getInvitedBeneficiaryDetails: {
          ...state.getInvitedBeneficiaryDetails,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_INVITED_BENEFICIARIES_ERROR:
      return {
        ...state,
        getInvitedBeneficiaryDetails: {
          ...state.getInvitedBeneficiaryDetails,
          loading: false,
          error: action.error,
        },
      };

    case RESEND_BENEFICIARY_INVITE_REQUEST:
      return {
        ...state,
        resendBeneficiaryInvite: {
          ...state.resendBeneficiaryInvite,
          loading: true,
        },
      };
    case RESEND_BENEFICIARY_INVITE_SUCCESS:
      return {
        ...state,
        resendBeneficiaryInvite: {
          ...state.resendBeneficiaryInvite,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case RESEND_BENEFICIARY_INVITE_ERROR:
      return {
        ...state,
        resendBeneficiaryInvite: {
          ...state.resendBeneficiaryInvite,
          loading: false,
          error: action.error,
        },
      };

    case ADD_BENEFICIARIES_BANK_REQUEST:
      return {
        ...state,
        addBeneficiaryBank: { ...state.addBeneficiaryBank, loading: true },
      };
    case ADD_BENEFICIARIES_BANK_SUCCESS:
      return {
        ...state,
        addBeneficiaryBank: {
          ...state.addBeneficiaryBank,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_BENEFICIARIES_BANK_ERROR:
      return {
        ...state,
        addBeneficiaryBank: {
          ...state.addBeneficiaryBank,
          loading: false,
          error: action.error,
        },
      };

    case GET_BENEFICIARIES_BANK_REQUEST:
      return {
        ...state,
        getBeneficiaryBank: { ...state.getBeneficiaryBank, loading: true },
      };
    case GET_BENEFICIARIES_BANK_SUCCESS:
      return {
        ...state,
        getBeneficiaryBank: {
          ...state.getBeneficiaryBank,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BENEFICIARIES_BANK_ERROR:
      return {
        ...state,
        getBeneficiaryBank: {
          ...state.getBeneficiaryBank,
          loading: false,
          error: action.error,
        },
      };

    case DEFAULT_BENEFICIARIES_BANK_REQUEST:
      return {
        ...state,
        defaultBeneficiaryBank: { ...state.defaultBeneficiaryBank, loading: true },
      };
    case DEFAULT_BENEFICIARIES_BANK_SUCCESS:
      return {
        ...state,
        defaultBeneficiaryBank: {
          ...state.defaultBeneficiaryBank,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DEFAULT_BENEFICIARIES_BANK_ERROR:
      return {
        ...state,
        defaultBeneficiaryBank: {
          ...state.defaultBeneficiaryBank,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_BENEFICIARIES_BANK_REQUEST:
      return {
        ...state,
        deleteBeneficiaryBank: { ...state.deleteBeneficiaryBank, loading: true },
      };
    case DELETE_BENEFICIARIES_BANK_SUCCESS:
      return {
        ...state,
        deleteBeneficiaryBank: {
          ...state.deleteBeneficiaryBank,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_BENEFICIARIES_BANK_ERROR:
      return {
        ...state,
        deleteBeneficiaryBank: {
          ...state.deleteBeneficiaryBank,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        deleteBeneficiaries: { ...state.deleteBeneficiaries, loading: true },
      };
    case DELETE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        deleteBeneficiaries: {
          ...state.deleteBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_BENEFICIARIES_ERROR:
      return {
        ...state,
        deleteBeneficiaries: {
          ...state.deleteBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case FILTER_BENEFICIARIES:
      return {
        ...state,
        isFilterBeneficiaries: action.payload,
      };

    //reset block with flag and data
    case RESET_BLOCK_BENEFICIARIE:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_BENEFICIARIE:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
