import { useMemo, useState } from 'react';
import { ChevronDown, PencilIcon, ArrowUpRightIcon, PlusIcon } from 'assets/icons';
import AssetViewer from 'components/AssetViewer';
import ConfirmDialog from 'components/ConfirmDialog';
import DeleteDialog from 'components/DeleteDialog';
import Timeline from 'components/Timeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import CustomDrawer from 'components/UI/CustomDrawer';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import DrawerHeader from 'components/UI/CustomDrawer/components/DrawerHeader';
import ReceiptList from 'components/UI/CustomDrawer/components/ReceiptList';
import CategorySelect from 'components/UI/CustomSelect/CategorySelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useEffect } from 'react';
import { Modal as BootstrapModal, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchSingleApproval,
  getPendingApprovalAsset,
  getRelatedApprovals,
  reviewRequest,
} from 'redux/actions/ApprovalAction';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { deleteCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { updateReimbursements } from 'redux/actions/ReimbursementsAction';
import { updateBill, payBill } from 'redux/actions/BillAction';
import {
  editBatchTransactionById,
  editTransactionById,
} from 'redux/actions/TransactionsAction';

import { buildVendorsTableData } from 'utils/helper';
import { RESET_BLOCK_APPROVAL } from 'redux/reducers/ApprovalReducer';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { returnSliceString } from 'utils';
import {
  capitalizeFirstLetter,
  getAvailableBalance,
  getFormattedDate,
  groupSourceOptions,
  transactionAcceptedFiles,
  transactionFileSupported,
} from 'utils/helper';
import CreateNewVendor from 'pages/Vendor/CreateNewVendor';
import CustomPopover from 'components/UI/Popover';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import classNames from 'classnames';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import ImgCard from 'components/UI/ImgCard';
import { QUERY_TYPE_MAP } from 'utils/utility';

const PendingApprovalModal = ({
  isOpen,
  data,
  setData,
  userCode,
  typeQuery,
  reloadData,
}) => {
  const [declineState, setDeclineState] = useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const {
    fetchSingleApproval: {
      loading: singleLoading,
      success: singleSuccess,
      data: approvalData,
    },
    relatedApprovals: { data: relatedData },
  } = useSelector(({ approval }) => approval);

  const [receipts, setReceipts] = useState([]);
  const [assetViewer, setAssetViewer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(false);

  useEffect(() => {
    if (data?.code && !singleLoading && !singleSuccess) {
      dispatch(fetchSingleApproval(data?.code));
    }
  }, [data]);

  if (!data) {
    return <div />; //this was added
  }

  // const createByMe = user?.code === data?.transaction?.user?.code;

  const handleClose = () => {
    setData(null);
    dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'fetchSingleApproval' });
    dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'pendingApprovalAssets' });
    setAssetViewer(false);
    setSelectedAsset(null);
    toggleAssetViewer(false);
  };

  const setReceiptList = (list) => {
    setReceipts(list);
  };

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);

  return (
    <section>
      <CustomDrawer show={isOpen}>
        {assetViewer && (
          <AssetViewer
            toggleVisibility={toggleAssetViewer}
            data={receipts}
            selectedAsset={selectedAsset}
          />
        )}
        <div className="content d-flex flex-column justify-content-between">
          {singleLoading && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <Loading color="#d2b28b" size={32} />
            </div>
          )}
          {!singleLoading && approvalData && (
            <>
              <PendingView
                declineState={declineState}
                typeQuery={typeQuery}
                setDeclineState={setDeclineState}
                data={data}
                approvalData={approvalData}
                relatedData={relatedData && relatedData}
                setData={setData}
                userCode={userCode}
                reloadData={reloadData}
                handleClose={handleClose}
                setAssetViewer={handleAssetViewer}
                getReceiptList={setReceiptList}
              />
            </>
          )}
        </div>
      </CustomDrawer>
    </section>
  );
};

const DeclineStateComponent = ({ goBack, code, setData, typeQuery, reloadData }) => {
  const [input, setInput] = useState({ reason: '' });
  const dispatch = useDispatch();
  const onHandleValueChange = (event) => {
    setInput({
      reason: event.target.value,
    });
  };

  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  useEffect(() => {
    if (success) {
      setData(null);

      if (typeQuery === QUERY_TYPE_MAP.INVOICE) reloadData();
    }
  }, [success]);

  const handleSubmit = () => {
    if (!input.reason) return toastError('Please enter a reason');
    dispatch(reviewRequest({ code, status: 'declined', reason: input.reason }));
  };

  return (
    <section className="information-wrapper decline-request-container">
      <div className="d-flex flex-column justify-content-between">
        <h2 className="color-grey-900 text-ds-xs font-semibold">Reason for decline</h2>
        <Row className="mb-3">
          <CustomTextarea
            row={5}
            name="reason"
            value={input?.reason}
            onChange={onHandleValueChange}
            placeholder="Specify the reason for the decline"
          />
        </Row>
        <CustomButton
          fullWidth
          className="mt-3"
          onClick={handleSubmit}
          disabled={loading}
        >
          Decline
        </CustomButton>
        <CustomButton withoutBg fullWidth className="mt-3" onClick={goBack}>
          Cancel
        </CustomButton>
      </div>
    </section>
  );
};

export const TRIGGER_STATE = {
  transaction: 'payer',
  reimbursement: 'user',
  batchTransactions: 'payer',
  invoice: 'user',
  bill: 'vendor',
};

const TRIGGER_STATE_RECEIVER = {
  transaction: 'recipient',
  reimbursement: 'vendor',
  batchTransactions: '',
  bill: 'vendor',
};

const PendingView = ({
  declineState,
  data,
  approvalData,
  relatedData,
  setData,
  setDeclineState,
  userCode,
  handleClose,
  setAssetViewer,
  getReceiptList,
  typeQuery,
  reloadData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    pendingApprovalAssets: { loading: assetLoading, data: allAssets },
  } = useSelector(({ approval }) => approval);
  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { success: successDelete, loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    updateReimbursement: {
      loading: updateLoading,
      success: updatingSuccess,
      error: updatingError,
    },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    editTransactionById: {
      loading: isEditing,
      success: isEditSuccess,
      error: isEditError,
    },
    editBatchTransactionById: {
      loading: isEditingBatch,
      success: isEditSuccessBatch,
      error: isEditErrorBatch,
    },
  } = useSelector(({ transaction }) => transaction);

  const {
    updateBill: { loading: isUpdating, success: updateSuccess },
  } = useSelector(({ bills }) => bills);

  const {
    updateVendor: { success: successUpdateVendors },
  } = useSelector(({ vendors }) => vendors);

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [ruleCode, setRuleCode] = useState(null);
  const [approvalType, setApprovalType] = useState(null);
  const [receiptCode, setReceiptCode] = useState('');
  const [receiptUploading, setReceiptUploading] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [budgetVisible, setBudgetVisible] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);

  const [approve, setIsApproving] = useState(false);

  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });

  const { budgets = [] } = budgetData;

  const [loadPage, setLoadPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  useEffect(() => {
    if (budgets.length === 0) dispatch(getBudgets({ status: 'active' }));
  }, []);

  const budgetsList = useMemo(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, true, true);

      return [...available_balance].concat([...budget]);
    }
  }, [balances?.budgets, balances?.balances]);

  const { code, approvals, batchData, type } = data;

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetsList
      .filter((item) => item?.value !== approvalData?.source?.code)
      .filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase()));
    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const onHandleGetSelected = (value) => {
    hitEndpoint(approvalType, dataInfo?.code, 'category', value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
    setIsEditingCategory(true);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  /**
   * @param {string} approvalType if this is a reimbursment or transaction, just past the approvalType variable
   * @param {string} code the code of the transaction type; just use the dataInfo?.code
   * @param {string} key the property your are trying to edit
   * @param {*} value the value of the property you are editing
   */
  const hitEndpoint = (approvalType, code, key, value) => {
    if (approvalType === 'reimbursement') {
      dispatch(updateReimbursements({ code, [key]: value }));
    } else if (approvalType === 'batchTransactions') {
      dispatch(editBatchTransactionById({ transaction_code: code, [key]: value }));
    } else if (approvalType === 'bill') {
      dispatch(updateBill({ method: key, [key]: value, code }));
    } else {
      dispatch(
        editTransactionById({
          [key]: value,
          transaction_code: code,
        }),
      );
    }
  };

  const handleDescription = () => {
    setDescription({
      ...description,
      isEditingDescription: true,
    });
    if (description?.data !== dataInfo?.description) {
      hitEndpoint(approvalType, dataInfo?.code, 'description', description?.data);
    }
  };

  const saveBudget = (budget) => {
    setBudgetLoading(true);
    const source = budget?.value?.startsWith('blc_') ? 'balance' : 'budget';
    hitEndpoint(approvalType, dataInfo?.code, source, budget?.value);
  };

  const triggerMap = {
    reimbursement: approvalData?.reimbursement,
    batchTransactions: approvalData?.batchTransactions,
    invoice: approvalData?.invoice,
    bill: approvalData?.bill,
  };

  let triggerType = 'transaction';

  for (const [type, value] of Object.entries(triggerMap)) {
    if (value !== null) {
      triggerType = type;
      break;
    }
  }

  const dataInfo = approvalData[triggerType] ?? {};
  const { amount, currency, payer: org } = dataInfo ?? {};

  useEffect(() => {
    setApprovalType(triggerType);
  }, [triggerType]);

  useEffect(() => {
    if (dataInfo) {
      setDescription({
        ...description,
        data: dataInfo?.description,
      });
    }
  }, [dataInfo]);

  const isBatchPayment = type === 'Batch';

  useEffect(() => {
    if (receiptCode?.length) {
      dispatch(
        approvalType === 'reimbursement'
          ? updateReimbursements({ receipt: receiptCode, code: dataInfo?.code })
          : editTransactionById({
              receipt: receiptCode,
              transaction_code: dataInfo?.code,
            }),
      );
      setReceiptUploading(true);
    }
  }, [receiptCode, approvalType]);

  useEffect(() => {
    if (!updateLoading && updatingSuccess) {
      dispatch(fetchSingleApproval(approvalData?.code));
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'updateReimbursement' });
      turnOffLoaders();
    }

    if (!(isEditing || isEditingBatch) && (isEditSuccess || isEditSuccessBatch)) {
      dispatch(fetchSingleApproval(approvalData?.code));
      dispatch({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'editTransactionById',
      });
      turnOffLoaders();
    }

    if (!updateLoading && !updatingSuccess && updatingError) {
      turnOffLoaders();
    }

    if (
      !(isEditing || isEditingBatch) &&
      !(isEditSuccess || isEditSuccessBatch) &&
      (isEditError || isEditErrorBatch)
    ) {
      turnOffLoaders();
    }

    if (updateSuccess || successUpdateVendors) {
      dispatch(fetchSingleApproval(approvalData?.code));
      turnOffLoaders();
    }
  }, [
    updateLoading,
    updatingSuccess,
    updatingError,
    isEditSuccess,
    isEditSuccessBatch,
    isEditErrorBatch,
    isEditing,
    isEditError,
    updateSuccess,
    successUpdateVendors,
  ]);

  const turnOffLoaders = () => {
    setIsEditingCategory(false);
    setBudgetLoading(false);
    setDescription({
      ...description,
      isEditingDescription: false,
    });
  };

  const [hasApproved, setHasApproved] = useState(false);
  const [payNow, setPayNow] = useState(true);

  const handleApproveRequest = ({ schedule }) => {
    dispatch(
      reviewRequest({
        code,
        status: 'approved',
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  useEffect(() => {
    if (success) {
      setIsApproving(false);
      dispatch(fetchSingleApproval(approvalData?.code));

      if ([QUERY_TYPE_MAP.INVOICE, QUERY_TYPE_MAP.BILL].includes(typeQuery)) {
        reloadData();
      }
    }
  }, [success]);

  useEffect(() => {
    if (approvals && userCode) {
      approvals.forEach((approval) => {
        if (approval.approver?.user?.code === userCode) {
          setHasApproved(true);
        }
      });
    }
  }, [approvals, userCode]);

  const dataMap = {
    Payments: approvalData?.transaction?.recipient,
    Reimbursements: approvalData?.reimbursement?.user,
    Bill: approvalData?.bill?.vendor,
  };

  const vendorDataAliaser = () => {
    const payload = dataMap[approvalData?.trigger] || {};
    const { name: vendorName = '', bankAccounts = [], BankAccounts = [] } = payload;

    const name = vendorName || `${payload?.firstName} ${payload?.lastName}`;
    const code = ['Payments', 'Bill'].includes(approvalData?.trigger)
      ? payload?.code
      : approvalData?.reimbursement?.vendor?.code ?? '';
    const isVendor = code?.split('_')[0] === 'vdr';

    return {
      name,
      code,
      isVendor,
      bankAccount: bankAccounts[0] || BankAccounts[0],
    };
  };

  const receipientName =
    dataInfo[TRIGGER_STATE[triggerType]]?.name ||
    `${dataInfo[TRIGGER_STATE[triggerType]]?.firstName} ${
      dataInfo[TRIGGER_STATE[triggerType]]?.lastName
    }`;

  const receiverType = TRIGGER_STATE_RECEIVER[triggerType];
  const isVendor = dataInfo?.[receiverType]?.code?.startsWith('vdr');
  const isBeneficiary = dataInfo?.[receiverType]?.code?.startsWith('usr');

  const getRecipientDetail = (property) => {
    if (triggerType === 'batchTransactions') {
      return dataInfo?.[property] ?? '';
    }

    if (triggerType === 'invoice') {
      return dataInfo?.customer?.[property] ?? '';
    }

    return property === 'code'
      ? isVendor
        ? dataInfo[receiverType]?.code
        : dataInfo[receiverType]?.beneficiary?.code
      : dataInfo[receiverType]?.name ??
          `${dataInfo[receiverType]?.firstName}  ${dataInfo[receiverType]?.lastName}`;
  };

  const receiver =
    receiverType === 'vendor' ? dataInfo[receiverType]?.name : getRecipientDetail('name');

  const receiverCode =
    receiverType === 'vendor' ? dataInfo[receiverType]?.code : getRecipientDetail('code');

  const bodyData = [
    {
      title: 'Requested by',
      value: receipientName,
      icon: (
        <div>
          <ImgCard initials={receipientName?.slice('')[0]} />
        </div>
      ),
      withBadge: true,
    },
    {
      title: 'Transaction type',
      value: capitalizeFirstLetter(
        triggerType === 'batchTransactions' ? 'Batch transactions' : triggerType,
      ),
    },
    ...(typeQuery === 'invoice'
      ? [{ title: 'Invoice id', value: approvalData[triggerType]?.code }]
      : []),
    { title: 'Date', value: getFormattedDate(approvalData?.created_at) },
  ];
  if (triggerType === 'reimbursement')
    bodyData.push({ title: 'Team', value: dataInfo?.team?.name ?? 'N/A' });

  useEffect(() => {
    if (dataInfo?.receipts?.length) {
      dispatch(getPendingApprovalAsset(dataInfo?.receipts));
    }
  }, [dataInfo]);

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    getReceiptList(allAssets);
  };

  const approvers = [];

  const approvalStages = approvalData?.rules.map(({ approvalStages, rule }) => ({
    approvalStages,
    rule,
  }));

  relatedData?.relatedApprovalRequests?.forEach((relations) => {
    const rule = relations.rule;
    relations.approvalStages.forEach((stages) => {
      if ([1, 'all'].includes(stages.threshold)) {
        approvers.push({
          rule,
          status: 'pending',
          message: '',
          type: stages.threshold,
          approvers: stages?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        stages.approvers.forEach((item) => {
          approvers.push({
            rule,
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });

    if (relations.approvals.length) {
      relations.approvals.forEach((item) => {
        approvers.push({
          rule,
          user: item?.approver?.user,
          date: getFormattedDate(item?.updated_at),
          status: item?.status,
          message: item?.reason ?? '',
        });
      });
    }
  });

  let alreadyApprovedUser = null;
  approvers.forEach((item) => {
    if (item.status === 'pending') {
      item.approvers.forEach((approver) => {
        if (approver?.user?.code === userData?.user?.code) {
          alreadyApprovedUser = false;
        }
      });
    }
    if (item.status === 'approved' && item?.user.code === userData?.user?.code) {
      alreadyApprovedUser = true;
    }
  });

  const approvalRequestUser = dataInfo[TRIGGER_STATE[triggerType]];
  const hasManager =
    userData?.user?.code === approvalRequestUser?.code && userData?.user?.manager?.code;

  let yourTurnToApprove = null;

  (approvers.length &&
    approvers.forEach((stage) => {
      stage?.approvers?.forEach((item) => {
        if (
          stage.status === 'pending' &&
          item?.user?.code === userData?.user?.code &&
          alreadyApprovedUser === false
        ) {
          yourTurnToApprove = true;
        }
      });
    })) ||
    (approvalData?.status === 'pending' && !approvalData?.code && !hasManager);

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setSaveButton(false);
  };

  const goToRule = (code) => {
    setRuleCode(code);
  };

  const closeApprovalRule = () => setRuleCode(null);

  const sliceBudgetValue = returnSliceString(
    approvalData?.source?.name,

    35,
  );

  const isBalance = dataInfo?.source?.code?.startsWith('blc_');

  const checkConditions = (approvers) => {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === userData?.user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === userData?.user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  };

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setPayNow(false);
            setIsApproving(true);
            handleButtonToggle();
          }}
        >
          Approve only
        </div>

        {triggerType !== 'batchTransactions' && (
          <div
            className="actionLink"
            onClick={() => {
              setIsSchedule(true);
              handleButtonToggle();
            }}
          >
            Approve and schedule payment
          </div>
        )}
      </div>
    );
  };

  const [isRequest, setIsRequest] = useState(false);
  const [isVendorOpen, setIsVendorOpen] = useState(false);
  const vendorData = buildVendorsTableData([dataMap[approvalData?.trigger] || {}]);

  const additionalInfo = () => {
    setIsVendorOpen(!isVendorOpen);
    setIsRequest(true);
  };

  const DialogSubTitle = () => {
    const defaultText = 'Are you sure you want to approve this request?';

    return typeQuery == QUERY_TYPE_MAP.BILL && !vendorDataAliaser().bankAccount && payNow
      ? `${defaultText} Vendor account missing, bill would be approved only.`
      : defaultText;
  };

  const routeToVendor = () => {
    history.push(`/expenses/vendors/profile/${vendorDataAliaser()?.code}`);
  };

  const UpdateVendorDetail = ({ text }) => (
    <span
      className="d-flex align-items-center gap-2 icon-animate"
      style={{ color: '#D28B28' }}
      onClick={additionalInfo}
    >
      <PlusIcon width="12" height="12" /> {text}
    </span>
  );

  const RequestDetails = ({ typeQuery }) => (
    <>
      <DrawerHeader
        status={approvalData?.status}
        receipient={receiver}
        recipientLink={
          isVendor
            ? `/expenses/vendors/profile/${receiverCode}`
            : isBeneficiary
            ? `/teams/members/profile/${receiverCode}`
            : false
        }
        moneyData={{
          amount,
          currency,
        }}
        handleClose={handleClose}
        title="Request Details"
      />
      <div className="card-modal-body">
        <div className="information-wrapper">
          <DrawerBody data={bodyData} />

          {typeQuery !== QUERY_TYPE_MAP.INVOICE && (
            <>
              <div className="divider"></div>

              <div className="mt-4">
                <div className="mb-3">
                  <ItemInfo
                    title={vendorDataAliaser().isVendor ? 'Vendor name' : 'Name'}
                    withAction
                    value={
                      <span style={{ cursor: 'pointer' }} onClick={routeToVendor}>
                        {vendorDataAliaser()?.name}{' '}
                      </span>
                    }
                    action={routeToVendor}
                    icon={
                      vendorDataAliaser()?.isVendor ? (
                        <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
                      ) : null
                    }
                  />
                </div>

                <div className="mb-3">
                  <ItemInfo
                    title="Bank name"
                    value={
                      vendorDataAliaser()?.bankAccount?.bankName || (
                        <UpdateVendorDetail text="Add bank name" />
                      )
                    }
                  />
                </div>

                <ItemInfo
                  title="Account number"
                  value={
                    vendorDataAliaser()?.bankAccount?.number || (
                      <UpdateVendorDetail text="Add account number" />
                    )
                  }
                />
              </div>

              {!typeQuery === QUERY_TYPE_MAP.BILL && <div className="divider"></div>}
            </>
          )}

          {![QUERY_TYPE_MAP.INVOICE, QUERY_TYPE_MAP.BILL].includes(typeQuery) && (
            <div className="padding-top-2">
              <ItemInfo
                title="Description"
                flex={1}
                value={dataInfo?.description}
                withAction={!['declined', 'processing'].includes(approvalData?.status)}
                hideValue={true}
                action={() => {
                  setDescription({ ...description, visible: !description.visible });
                  setSaveButton(!saveButton);
                }}
                isLoading={description?.isEditingDescription}
                icon={
                  !['declined', 'processing'].includes(approvalData?.status) && (
                    <PencilIcon stroke="#D28B28" width="18" height="18" />
                  )
                }
              />
              <div className="padding-top-xxs">
                <CustomTextarea
                  name="description"
                  placeholder="Description"
                  value={description?.data}
                  onChange={handleDescriptionChange}
                  disabled={
                    ['declined', 'processing'].includes(approvalData?.status) ||
                    !description?.visible
                  }
                  rowSize={4}
                />
              </div>
            </div>
          )}

          <div className="divider"></div>
          <Timeline
            goToRule={goToRule}
            data={approvers}
            code={approvalData?.code}
            pageFrom={`/transactions/requests`}
            multipleRule={approvalData.rules.length > 1}
          />

          {typeQuery !== QUERY_TYPE_MAP.INVOICE && (
            <>
              <div className="divider"></div>

              <ItemInfo
                title="Expense category"
                isLink={
                  dataInfo?.category?.code
                    ? `/compliances/categories/${dataInfo?.category?.code}/details`
                    : false
                }
                withAction={!['pending', 'declined'].includes(approvalData?.status)}
                action={() => setCategoryVisible(!categoryVisible)}
                isLoading={isEditingCategory}
                value={expenseCategories.value.label ?? dataInfo?.category?.name ?? 'N/A'}
                icon={<PencilIcon stroke="#D28B28" width="18" height="18" />}
              />
            </>
          )}

          {categoryVisible && (
            <div className="padding-top-xxs">
              <CategoryDropdown
                placeholder="Select a category"
                onChange={(val) => onHandleGetSelected(val)}
                value={
                  expenseCategories.value ?? {
                    value: dataInfo?.category?.code,
                    label: dataInfo?.category?.name,
                  }
                }
                defaultValue={{
                  value: dataInfo?.category?.code,
                  label: dataInfo?.category?.name,
                }}
                name="category"
              />
            </div>
          )}

          {![QUERY_TYPE_MAP.INVOICE].includes(typeQuery) && (
            <div className="padding-top-2">
              <ItemInfo
                title="Source of funds"
                isLink={
                  isBalance
                    ? `/accounts/${approvalData?.source?.code}`
                    : approvalData?.source?.type === 'budget'
                    ? `/expenses/budgets/${approvalData?.source?.code}/overview`
                    : false
                }
                withAction={
                  !['success', 'declined', 'processing'].includes(approvalData?.status)
                }
                action={() => setBudgetVisible(!budgetVisible)}
                value={sliceBudgetValue}
                isLoading={budgetLoading}
                icon={
                  !['approved', 'declined'].includes(approvalData?.status) && (
                    <PencilIcon stroke="#D28B28" width="18" height="18" />
                  )
                }
              />
            </div>
          )}

          {budgetVisible && (
            <div className="padding-top-xxs">
              <CustomSelectRadio
                placeholder="Select a source"
                name="source"
                onChange={(val) => saveBudget(val)}
                defaultValue={
                  approvalData?.source?.code && {
                    value: approvalData?.source?.code,
                    label: approvalData?.source?.name,
                  }
                }
                loadOptions={loadOptions}
              />
            </div>
          )}
          <div className="padding-top-2">
            {assetLoading && <Loading color="#D28B28" size={20} />}
            {!assetLoading && allAssets?.length <= 0 && allAssets !== undefined && (
              <div>
                <FileUpload
                  label="Receipts or Attachments"
                  placeholder="Upload receipts or invoices (PDF, JPEG, PNG, XLSX)"
                  name="file"
                  onChange={(value) =>
                    setReceiptCode(value?.map((item) => item.assetCode))
                  }
                  multiple
                  wrapperClass="new-receipt"
                  supportType={transactionFileSupported}
                  acceptedFile={transactionAcceptedFiles}
                />
              </div>
            )}
            {!assetLoading && allAssets?.length > 0 && (
              <ReceiptList
                receiptAssets={allAssets}
                viewImgUrl={viewImgUrl}
                onChange={(value) => setReceiptCode(value?.map((item) => item.assetCode))}
              />
            )}
          </div>
        </div>
      </div>
      {saveButton && (
        <div className="card-modal-footer justify-content-end">
          <CustomButton
            onClick={cancelDescriptionEdit}
            className="base-button text-sm font-medium  black-transparent"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleDescription}
            className="base-button text-sm font-medium dark-button"
          >
            {description?.isEditingDescription ? (
              <Loading color="#D28B28" size={20} />
            ) : (
              'Save changes'
            )}
          </CustomButton>
        </div>
      )}
      {yourTurnToApprove &&
        !saveButton &&
        !['declined', 'cancelled'].includes(approvalData?.status) && (
          <div className="card-modal-footer justify-content-end">
            <CustomButton
              withoutBg
              className="base-button text-sm font-medium danger-button"
              onClick={() => setDeclineState(true)}
              disabled={hasApproved}
              style={{ width: 'fit-content', padding: '10px', minWidth: 0 }}
            >
              Decline
            </CustomButton>

            {/* <CustomButton
              className="base-button dark-button  text-sm font-medium"
              onClick={() => setIsApproving(true)}
              disabled={hasApproved ? true : loading}
            >
              Approve
            </CustomButton> */}

            <div className="d-flex">
              <button
                disabled={hasApproved ? true : loading}
                onClick={() => {
                  setIsApproving(true);
                  setPayNow(canApprovePay);
                }}
                className={classNames('px-3 nowrap dropdown-btn action-btn text-white', {
                  ['rounded-3 px-4']: !canApprovePay,
                  'is-plain': typeQuery === QUERY_TYPE_MAP.INVOICE,
                })}
              >
                {canApprovePay
                  ? typeQuery === QUERY_TYPE_MAP.INVOICE
                    ? 'Approve and send'
                    : 'Approve and pay'
                  : 'Approve only'}
              </button>

              {typeQuery !== QUERY_TYPE_MAP.INVOICE && canApprovePay && (
                <CustomPopover
                  placement="top-start"
                  id="invoice-schedule"
                  zIndex="9999"
                  content={<Actions />}
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                >
                  <button
                    className="dropdown-btn"
                    disabled={hasApproved ? true : loading}
                    onClick={handleButtonToggle}
                  >
                    <ChevronDown
                      color="#ffffff"
                      className={`icon ${isButtonToggle && 'is-toggled'}`}
                    />
                  </button>
                </CustomPopover>
              )}
            </div>
          </div>
        )}

      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => {
            setIsSchedule(false);
          }}
          loading={loading}
          setSchedule={handleApproveRequest}
          // handleCreate={onHandleSubmit}
        />
      </Modal>

      {approve && (
        <BootstrapModal show={true} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title="Approve request"
            subTitle={DialogSubTitle()}
            onConfirm={handleApproveRequest}
            loading={loading}
            onCancel={() => setIsApproving(false)}
            isDeleteDialog={false}
          />
        </BootstrapModal>
      )}

      <CreateNewVendor
        isOpen={isVendorOpen}
        isUpdate={true}
        toggleHandler={() => setIsVendorOpen(false)}
        data={vendorData?.[0]}
        isRequest={isRequest}
      />
    </>
  );

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  if (!declineState) {
    return <>{RequestDetails({ typeQuery })}</>;
  }
  return (
    <>
      <DeclineStateComponent
        goBack={() => setDeclineState(false)}
        setData={setData}
        code={isBatchPayment ? batchData?.code : code}
        typeQuery={typeQuery}
        reloadData={reloadData}
      />
    </>
  );
};

export default PendingApprovalModal;
