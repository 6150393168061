import React from 'react';

const FixedSideModal = ({ children, open }) => {
  return (
    <div
      style={{
        width: `${!!open ? '30%' : '0'}`,
        minHeight: '100%',
        right: 0,
      }}
      className="position-absolute border-start px-4 pt-4"
    >
      {children}
    </div>
  );
};

export default FixedSideModal;
