import React from 'react';
import Loading from '../Loading';

const Saving = ({ message }) => {
  return (
    <div className="d-flex justify-content-center pt-5">
      <div className="text-center" style={{ marginTop: '50%' }}>
        <Loading />
        {message && <p className="fs-6 mt-2 text-center">{message}</p>}
      </div>
    </div>
  );
};

export default Saving;
