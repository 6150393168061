import React from 'react';
import './styles.scss';
import CustomButton from '../UI/CustomButton';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as Confirm } from '../../assets/icons/confirm.svg';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import cs from 'classnames';
import classNames from 'classnames';

const ConfirmDialog = ({
  onCancel,
  onConfirm,
  title = 'Delete',
  subTitle = '',
  loading,
  isDeleteDialog = true,
  dialogIcon = null,
  children = null,
  actionBtnText,
  style,
  extraChild,
  disabled,
}) => {
  return (
    <div>
      <div className="confirm-modal-overlay" onClick={onCancel} />
      <div className="confirm-modal-wrapper position-relative">
        <div className="confirm-modal-body p-0" style={{ ...style }}>
          <i onClick={onCancel} className="cancel-icon">
            <Cancel />
          </i>
          {children ? (
            <div className="px-4 pt-4 w-100">{children}</div>
          ) : (
            <>
              <div className="px-4 pt-4">
                <span className={classNames({ ['delete-icon']: !dialogIcon })}>
                  {dialogIcon ? dialogIcon : isDeleteDialog ? <Delete /> : <Confirm />}
                </span>
                <h2 className="text-start mt-2">{title}</h2>
                <p className="text-start">{subTitle}</p>
                {extraChild}
              </div>

              <div className="custom-modal_footer mt-4 d-flex w-100 gap-3">
                <CustomButton
                  fullWidth
                  disabled={loading}
                  className="border-btn"
                  onClick={onCancel}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  fullWidth
                  disabled={loading || disabled}
                  loading={loading}
                  className={cs({
                    'remove-btn': isDeleteDialog,
                    'confirm-btn': !isDeleteDialog,
                  })}
                  onClick={onConfirm}
                >
                  {isDeleteDialog && actionBtnText?.length && actionBtnText}
                  {isDeleteDialog && !actionBtnText?.length && 'Delete'}
                  {!isDeleteDialog && (actionBtnText || 'Confirm')}
                </CustomButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ConfirmDialog;
