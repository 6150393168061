import Table from 'components/Table';
import 'jspdf-autotable';
import { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { spendReceipts } from 'utils/mockData';
import { buildReceiptsTableData } from '../../../utils/helper';
import NoData from './NoData';
import TableLoading from './TableLoading';
import { getSpendReceipts } from 'redux/actions/SpendAction';

const ReceiptsTable = ({ id, type, userName }) => {
  const dispatch = useDispatch();

  const {
    getSpendReceipts: { data: receipts = [], loading },
  } = useSelector(({ spends }) => spends);
  // const { page, total, hasMore, perPage, nextPage } = meta;

  const rows = useMemo(() => buildReceiptsTableData(receipts), [receipts]);

  const handlePagination = (page) => {
    dispatch(getSpendReceipts({ code: id }));
  };

  const tableColumn = useMemo(() => spendReceipts, [receipts]);

  useEffect(() => {
    dispatch(getSpendReceipts({ code: id }));
  }, []);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  return (
    <>
      {!loading && !receipts.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No receipts"
            bodyText={`Your receipts will show up here when fetched from your email`}
            withButton={true}
            buttonLabel={
              <span className="d-flex justify-content-center">Show all receipts</span>
            }
            // onClickHandler={clearLocationState}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={rows}
                  // pagination
                  // hasMore={hasMore}
                  hasCheckBox={false}
                  // currentPage={page}
                  // nextPage={() => handlePagination(nextPage)}
                  // previousPage={() => handlePagination(page - 1)}
                  // totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default ReceiptsTable;
