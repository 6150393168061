import { useState } from 'react';

import { Col, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import TopBar from 'components/TopBar';
import PendingApprovals from 'pages/Approvals/PendingApprovals';
import Customers from 'pages/Customers';
import Invoices from 'pages/Invoices';
import RecurringInvoices from 'pages/Invoices/RecurringInvoices';

import { PlusDropDownIcon } from 'assets/icons';
import { ActiveButton } from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { useSelector } from 'react-redux';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import './index.style.scss';

import AddCustomerModal from 'pages/Invoices/components/AddCustomerModal';
import { useMediaQuery } from 'react-responsive';

const Collections = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [isPopOver, setIsPopOver] = useState(false);
  const [isCustomerModal, setIsCustomerModal] = useState(false);

  const { permissions } = allPermissions();
  const canViewInvoice = hasPermission({
    permissions,
    scopes: ['invoice-*', 'invoice-view', 'invoice-create'],
  });

  const canViewCustomer = hasPermission({
    permissions,
    scopes: ['customer-*', 'customer-view', 'customer-create'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['customer-*', 'invoice-*', 'customer-create', 'invoice-create'],
  });

  const {
    fetchInvoices: { data },
    customers: { data: customers },
    fetchScheduledInvoice: { data: scheduledInvoice },
  } = useSelector(({ invoices }) => invoices);

  const { fetchPendingApprovals: listPendingApprovals } = useSelector(
    ({ approval }) => approval,
  );

  const handlePopOver = () => setIsPopOver(!isPopOver);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey !== 'invoices') {
      history.push(`/receivables/${tabKey}`);
    } else {
      history.push(`/receivables/invoices`, { disableFade: true });
    }
  };

  const openCustomerModal = () => {
    setIsPopOver(false);
    setIsCustomerModal(true);
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  const handleRoute = (path, search = '') => {
    history.push({
      pathname: path,
      search: search,
      state: { isNew: true },
    });
  };

  const InvoicePopOver = () => {
    return (
      <div className="actions-dialog invoice">
        <div
          className="actionLink mx-1"
          onClick={() => handleRoute('/receivables/invoices/create')}
        >
          <span>New invoice</span>
        </div>

        <div
          className="actionLink  mx-1"
          onClick={() => handleRoute('/receivables/invoices/create', '?isRecurring=true')}
        >
          <span>New recurring invoice</span>
        </div>

        <div style={{ borderTop: ' 1px solid #e7e5e4' }} className="my-1" />

        <div className="actionLink mx-1" onClick={openCustomerModal}>
          <span>New Customer</span>
        </div>

        <div
          className="actionLink mx-1"
          onClick={() => handleRoute('/receivables/customers/create-multiple')}
        >
          <span> Create multiple customers</span>
        </div>
      </div>
    );
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="position-relative container">
      <TopBar
        isRightBar
        headerText="Customer invoices"
        subText="Manage your earnings effortlessly. Create, send, and track all invoices you've issued to your customers."
        addButton={!isCreateOpen && canCreate}
        dropDownBtn={
          <CustomPopover
            zIndex="100"
            content={<InvoicePopOver />}
            showPopover={isPopOver}
            clickOutside={handlePopOver}
            placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
            id={isTabletOrMobile ? 'invoice-schedule' : ''}
          >
            <ActiveButton
              position="left"
              text="Create"
              onClick={handlePopOver}
              icon={<PlusDropDownIcon stroke="#fff" />}
              className="add-button d-flex align-items-center justify-content-center gap-2"
            />
          </CustomPopover>
        }
      />

      <section className="overview-holder">
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              {canViewInvoice && (
                <Tab
                  eventKey="invoices"
                  title={
                    <div className="d-flex">
                      Invoices{' '}
                      <div className="count">
                        <span className="m-auto">{data?.meta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <Invoices
                      handleOpenCreate={() => setIsCreateOpen(true)}
                      closeModal={() => setIsOpen(null)}
                      isOpen={isOpen === 'invoices'}
                      openCustomerModal={openCustomerModal}
                      handleSelect={setKey}
                    />
                  </div>
                </Tab>
              )}

              {canViewInvoice && (
                <Tab
                  eventKey="requests"
                  title={
                    <div className="d-flex">
                      Need review{' '}
                      <div className="count">
                        <span className="m-auto">
                          {listPendingApprovals?.meta?.total || 0}
                        </span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <PendingApprovals
                      typeQuery="invoice"
                      onRowSelect={(data) => {
                        handleRowSelect(data, 'requests');
                      }}
                    />
                  </div>
                </Tab>
              )}

              {canViewInvoice && (
                <Tab
                  eventKey="recurring-invoices"
                  title={
                    <div className="d-flex">
                      Recurring{' '}
                      <div className="count">
                        <span className="m-auto">
                          {' '}
                          {scheduledInvoice?.meta?.total || 0}
                        </span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <RecurringInvoices
                      handleOpenCreate={() => setIsCreateOpen(true)}
                      closeModal={() => setIsOpen(null)}
                      isOpen={isOpen === 'recurring-invoices'}
                      openCustomerModal={openCustomerModal}
                      handleSelect={setKey}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </section>

      <Modal show={isCustomerModal} centered dialogClassName="custom-dialog">
        <AddCustomerModal
          isOpen={isCustomerModal}
          handleClose={() => setIsCustomerModal(false)}
        />
      </Modal>
    </div>
  );
};

export default Collections;
