import OverlayingIcons from 'components/OverlayingIcons';
import React from 'react';
import './styles.scss';

const OverlayIconsName = ({ value, name, budget }) => {
  return (
    <div className="space-y-2">
      <span className="fw-200 mb-1 teamlist-card-subname">
        {name} <div className="teamlist-squres" />
      </span>
      <div className="d-flex align-items-center">
        <OverlayingIcons userList={value} budget={budget} />
      </div>
    </div>
  );
};

export default OverlayIconsName;
