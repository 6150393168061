import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Success from 'components/UI/ModalSaving/Success';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import MonoConnect from '@mono.co/connect.js';
import { ReactComponent as Bank } from 'assets/icons/bank.svg';
import { ReactComponent as FundDollar } from 'assets/icons/FundDollar.svg';
import cs from 'classnames';

const SelectFunds = ({
  setFundStep,
  fundType,
  isLinked = false,
  setMonoAuthCode,
  accounts,
  initiatePayment,
  verifyPayment,
  paymentVerified,
  paymentFailed,
  getReauthorizationCode,
  reauthorizationCode,
}) => {
  const [isFunding, setIsFunding] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [successfulFunding, setSuccessfulFunding] = useState(paymentVerified);
  const [failedFunding, setFailedFunding] = useState(paymentFailed);
  const [account, setAccount] = useState(null);
  const [currency, setCurrency] = useState('NGN');
  const [amount, setAmount] = useState(0);
  const [bankAccounts] = useState(
    accounts.map(({ code, number, bankName, requiresReauth }) => ({
      value: code,
      label: `${bankName} (${number})`,
      requiresReauth,
    })),
  );

  const location = useLocation();
  const [searchParams] = useState(new URLSearchParams(location.search));

  const dataArray = [
    {
      image: <Bank />,
      headerText: 'By bank transfer',
      subHeaderText: 'Wire your fund to this account',
      tag: 'VIRTUAL',
    },
    // {
    //   image: <Card />,
    //   headerText: 'By debit/credit card',
    //   subHeaderText: 'Fund whenever you feel like',
    //   tag: '',
    // },
    {
      image: <Bank />,
      headerText: `${isLinked ? 'Debit' : 'Link'} my bank account`,
      subHeaderText: isLinked
        ? 'Pull money from your external accounts'
        : 'Better for automated top up',
      tag: '',
    },
  ];

  const dollarArray = [
    {
      image: <Bank />,
      headerText: 'By bank transfer',
      subHeaderText: 'Wire your fund to this account',
      tag: 'VIRTUAL',
    },
    {
      image: <FundDollar />,
      headerText: 'By converting naira to dollar',
      subHeaderText: 'Topup your USD balance from your Naira balance',
      tag: '',
    },
  ];
  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => {
        setIsFunding(false);
      },
      onSuccess: ({ code }) => {
        setMonoAuthCode(code);
        monoInstance.close();
      },
      key: process.env.REACT_APP_MONO_CONNECT_KEY,
    });

    monoInstance.setup();
    return monoInstance;
  }, []);

  const mapData = fundType === 0 ? dataArray : dollarArray;

  const checkIndex = (index, fundType) => {
    return index === 0 && fundType === 0
      ? 2
      : index === 0 && fundType === 1
      ? 5
      : index === 1 && fundType === 1
      ? 4
      : 1;
  };

  const handleClick = (num) => () => {
    setFundStep(num);
    if (num === 1 && !isLinked) monoConnect.open();
    else setIsFunding(true);
  };

  const setBankAccount = ({ value, requiresReauth }) => {
    if (requiresReauth) {
      getReauthorizationCode(account.value);
    } else setAccount({ value });
  };

  const openPayment = () => {
    const payload = {
      currency,
      amount: amount * 100,
      account: account.value,
    };
    initiatePayment(payload);
  };

  useEffect(() => {
    if (reauthorizationCode) {
      monoConnect.reauthorise(reauthorizationCode);
    }
  }, [reauthorizationCode]);

  useEffect(() => {
    if (searchParams.get('funding') === 'true') {
      const status = searchParams.get('status');
      if (status !== 'successful') {
        setFailed(true);
      } else if (searchParams.get('reference')) {
        setIsFunding(false);
        setIsVerifying(true);
        verifyPayment(searchParams.get('reference'));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setIsVerifying(false);
    if (paymentFailed) {
      setSuccessfulFunding(false);
      setFailedFunding(true);
    } else if (paymentVerified) {
      setFailedFunding(false);
      setSuccessfulFunding(true);
    }
  }, [paymentFailed, paymentVerified]);

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    return setAmount(rawValue);
  };
  const HeaderMessage = isFunding
    ? 'Add funds'
    : 'How would you like to fund your balance?';

  const PickerScreen = () => (
    <div className="selectFundsWrapper">
      <Row className="cardWrapper">
        {mapData.map((item, index) => {
          return (
            <div
              key={index}
              className={cs('balance-card-wrapper')}
              onClick={handleClick(checkIndex(index, fundType))}
            >
              <div className="pe-4 ps-2">{item.image}</div>
              <div>
                <div className="d-flex align-items-center">
                  <span className="balance-title">{item.headerText}</span>
                  {item.tag && (
                    <span
                      className={cs('balance-tag', {
                        ['dark-bg']: item.tag !== 'VIRTUAL',
                      })}
                    >
                      {item.tag}
                    </span>
                  )}
                </div>
                <span className="balance-sub-title">{item.subHeaderText}</span>
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );

  const FundingForm = () => (
    <div className="selectFundsWrapper">
      <div className="form-normal">
        <Row className="mb-3">
          <CustomInput
            label="How much do you want to fund?"
            isAmount
            placeholder="0.00"
            type="text"
            name="amount"
            className="amount"
            value={amount}
            onChange={handleChange}
          />
        </Row>
        <Row className="mb-3 mt-3 align-items-center">
          <CustomSelect
            label="From which bank account?"
            name="account"
            placeholder="Select a bank account"
            onChange={(account) => setBankAccount(account)}
            value={account}
            options={bankAccounts}
          />
        </Row>

        <CustomButton className="mb-4" onClick={openPayment} fullWidth={true}>
          Continue
        </CustomButton>
      </div>
    </div>
  );

  const VerifyingMessage = () => (
    <Success title="Thank you!" message="Your funding is being processed..." />
  );
  const SuccessMessage = () => (
    <Success title="Successful!" message="Your balance have been funded..." />
  );
  const FailureMessage = () => (
    <Success title="Failed!" message="Your funding was not successful, try again!" />
  );
  //
  return (
    <>
      {isFunding ? (
        <div className="selectFundsTitle">
          <h2 className="card-title w-100">{HeaderMessage}</h2>
        </div>
      ) : null}
      {isFunding
        ? FundingForm()
        : isVerifying
        ? VerifyingMessage()
        : successfulFunding
        ? SuccessMessage()
        : failedFunding
        ? FailureMessage()
        : PickerScreen()}
    </>
  );
};
export default SelectFunds;
