import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Skeleton } from 'antd';
import { getCurrency } from 'utils/helper';
import { Tab, Tabs, Row, Col } from 'react-bootstrap';

import TableLoading from './TableLoading';
import AnalyticsCard from 'components/UI/AnalyticsCard';
import FormattedCurrency from 'components/UI/FormattedCurrency';

const ProfileTabs = ({ informationBasedOnUserType, data, loading }) => {
  const dispatch = useDispatch();

  const [key, setKey] = useState('transactions');
  const [loadedTabs, setLoadedTabs] = useState(['transactions']);

  const handleSwitch = (key) => {
    const uniqueKeys = new Set([...loadedTabs, key]);

    setLoadedTabs(Array.from(uniqueKeys));
    setKey(key);
  };

  return (
    <>
      {informationBasedOnUserType.pageLoading ? (
        <>
          <Skeleton.Input
            active
            block={true}
            size={54}
            style={{ borderRadius: '10px', width: '100%', marginBottom: '10px' }}
          />
          <TableLoading />
        </>
      ) : (
        <>
          {data && (
            <section className="mmt-10">
              <div className="sub-analytics__holder mb-3">
                {data.map(({ title, amount, currency }, index) => {
                  return (
                    <div className="sub-analytics__stats" key={'element-' + index}>
                      <AnalyticsCard
                        caption={title}
                        hasExtra={false}
                        loading={loading}
                        value={
                          currency ? (
                            <FormattedCurrency
                              value={amount}
                              prefix={getCurrency(currency)}
                            />
                          ) : (
                            <span> {amount} </span>
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </section>
          )}
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleSwitch}
            className="mb-2 profile-nav-bar rounded py-2 px-2"
          >
            {informationBasedOnUserType?.tabList?.map((option, index) => {
              return (
                <Tab
                  key={index}
                  eventKey={option.key}
                  title={<div className="d-flex">{option.name}</div>}
                  tabClassName="profile-new-tab"
                >
                  {loadedTabs.includes(option.key) && <>{option.component}</>}
                </Tab>
              );
            })}
          </Tabs>
        </>
      )}
    </>
  );
};
export default ProfileTabs;
