export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export const FETCH_PLANS_ERROR = 'FETCH_PLANS_ERROR';

export const FETCH_BILLING_HISTORY_REQUEST = 'FETCH_BILLING_HISTORY_REQUEST';
export const FETCH_BILLING_HISTORY_SUCCESS = 'FETCH_BILLING_HISTORY_SUCCESS';
export const FETCH_BILLING_HISTORY_ERROR = 'FETCH_BILLING_HISTORY_SUCCESS';

export const VIEW_PLAN_REQUEST = 'VIEW_PLAN_REQUEST';
export const VIEW_PLAN_SUCCESS = 'VIEW_PLAN_SUCCESS';
export const VIEW_PLAN_ERROR = 'VIEW_PLAN_ERROR';

export const SUBSCRIBE_TO_PLAN_REQUEST = 'SUBSCRIBE_TO_PLAN_REQUEST';
export const SUBSCRIBE_TO_PLAN_SUCCESS = 'SUBSCRIBE_TO_PLAN_SUCCESS';
export const SUBSCRIBE_TO_PLAN_ERROR = 'SUBSCRIBE_TO_PLAN_ERROR';

export const GET_COMPANY_SUBSCRIPTION_REQUEST = 'GET_COMPANY_SUBSCRIPTION_REQUEST';
export const GET_COMPANY_SUBSCRIPTION_SUCCESS = 'GET_COMPANY_SUBSCRIPTION_SUCCESS';
export const GET_COMPANY_SUBSCRIPTION_ERROR = 'GET_COMPANY_SUBSCRIPTION_ERROR';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';

export const CANCEL_PLAN_REQUEST = 'CANCEL_PLAN_REQUEST';
export const CANCEL_PLAN_SUCCESS = 'CANCEL_PLAN_SUCCESS';
export const CANCEL_PLAN_ERROR = 'CANCEL_PLAN_ERROR';

export const GENERATE_RECEIPT_REQUEST = 'GENERATE_RECEIPT_REQUEST';
export const GENERATE_RECEIPT_SUCCESS = 'GENERATE_RECEIPT_SUCCESS';
export const GENERATE_RECEIPT_ERROR = 'GENERATE_RECEIPT_ERROR';

export const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_ERROR = 'ADD_PAYMENT_METHOD_ERROR';

export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_ERROR = 'GET_PAYMENT_METHOD_ERROR';

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_ERROR = 'DELETE_PAYMENT_METHOD_ERROR';

export const ADD_BILLING_ADDRESS_REQUEST = 'ADD_BILLING_ADDRESS_REQUEST';
export const ADD_BILLING_ADDRESS_SUCCESS = 'ADD_BILLING_ADDRESS_SUCCESS';
export const ADD_BILLING_ADDRESS_ERROR = 'ADD_BILLING_ADDRESS_ERROR';

export const GET_BILLING_ADDRESS_REQUEST = 'GET_BILLING_ADDRESS_REQUEST';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_ERROR = 'GET_BILLING_ADDRESS_ERROR';

export const RESET_BLOCK_BILLING = 'RESET_BLOCK_BILLING';

const block = {
  loading: false,
  error: '',
  success: false,
  data: '',
};

const initialState = {
  fetchPlans: { ...block },
  fetchBillingHistory: { ...block },
  viewPlan: { ...block },
  subscribeToPlan: { ...block },
  getCompanySubscription: { ...block },
  updateSubscription: { ...block },
  cancelPlan: { ...block },
  generateReceipt: { ...block },
  addPaymentMethod: { ...block },
  getPaymentMethod: { ...block },
  deletePaymentMethod: { ...block },
  addBillingAddress: { ...block },
  getBillingAddress: { ...block },
};

export const BillingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANS_REQUEST:
      return {
        ...state,
        fetchPlans: { ...state.fetchPlans, loading: true },
      };
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        fetchPlans: {
          ...state.fetchPlans,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_PLANS_ERROR:
      return {
        ...state,
        fetchPlans: {
          ...state.fetchPlans,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_BILLING_HISTORY_REQUEST:
      return {
        ...state,
        fetchBillingHistory: { ...state.fetchBillingHistory, loading: true },
      };
    case FETCH_BILLING_HISTORY_SUCCESS:
      return {
        ...state,
        fetchBillingHistory: {
          ...state.fetchBillingHistory,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_BILLING_HISTORY_ERROR:
      return {
        ...state,
        fetchBillingHistory: {
          ...state.fetchBillingHistory,
          loading: false,
          error: action.error,
        },
      };

    case VIEW_PLAN_REQUEST:
      return {
        ...state,
        viewPlan: { ...state.viewPlan, loading: true },
      };
    case VIEW_PLAN_SUCCESS:
      return {
        ...state,
        viewPlan: {
          ...state.viewPlan,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VIEW_PLAN_ERROR:
      return {
        ...state,
        viewPlan: {
          ...state.viewPlan,
          loading: false,
          error: action.error,
        },
      };

    case SUBSCRIBE_TO_PLAN_REQUEST:
      return {
        ...state,
        subscribeToPlan: { ...state.subscribeToPlan, loading: true },
      };
    case SUBSCRIBE_TO_PLAN_SUCCESS:
      return {
        ...state,
        subscribeToPlan: {
          ...state.subscribeToPlan,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SUBSCRIBE_TO_PLAN_ERROR:
      return {
        ...state,
        subscribeToPlan: {
          ...state.subscribeToPlan,
          loading: false,
          error: action.error,
        },
      };

    case GET_COMPANY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        getCompanySubscription: { ...state.getCompanySubscription, loading: true },
      };

    case GET_COMPANY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        getCompanySubscription: {
          ...state.getCompanySubscription,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case GET_COMPANY_SUBSCRIPTION_ERROR:
      return {
        ...state,
        getCompanySubscription: {
          ...state.getCompanySubscription,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updateSubscription: { ...state.updateSubscription, loading: true },
      };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscription: {
          ...state.updateSubscription,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case UPDATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        updateSubscription: {
          ...state.updateSubscription,
          loading: false,
          error: action.error,
        },
      };

    case CANCEL_PLAN_REQUEST:
      return {
        ...state,
        cancelPlan: { ...state.cancelPlan, loading: true },
      };

    case CANCEL_PLAN_SUCCESS:
      return {
        ...state,
        cancelPlan: {
          ...state.cancelPlan,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case CANCEL_PLAN_ERROR:
      return {
        ...state,
        cancelPlan: {
          ...state.cancelPlan,
          loading: false,
          error: action.error,
        },
      };

    case GENERATE_RECEIPT_REQUEST:
      return {
        ...state,
        generateReceipt: { ...state.generateReceipt, loading: true },
      };

    case GENERATE_RECEIPT_SUCCESS:
      return {
        ...state,
        generateReceipt: {
          ...state.generateReceipt,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case GENERATE_RECEIPT_ERROR:
      return {
        ...state,
        generateReceipt: {
          ...state.generateReceipt,
          loading: false,
          error: action.error,
        },
      };

    case ADD_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        addPaymentMethod: { ...state.addPaymentMethod, loading: true },
      };

    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        addPaymentMethod: {
          ...state.addPaymentMethod,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case ADD_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        addPaymentMethod: {
          ...state.addPaymentMethod,
          loading: false,
          error: action.error,
        },
      };

    case GET_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        getPaymentMethod: { ...state.getPaymentMethod, loading: true },
      };

    case GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        getPaymentMethod: {
          ...state.getPaymentMethod,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case GET_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        getPaymentMethod: {
          ...state.getPaymentMethod,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        deletePaymentMethod: { ...state.deletePaymentMethod, loading: true },
      };

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        deletePaymentMethod: {
          ...state.deletePaymentMethod,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case DELETE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        deletePaymentMethod: {
          ...state.deletePaymentMethod,
          loading: false,
          error: action.error,
        },
      };

    case ADD_BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        addBillingAddress: { ...state.addBillingAddress, loading: true },
      };

    case ADD_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        addBillingAddress: {
          ...state.addBillingAddress,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case ADD_BILLING_ADDRESS_ERROR:
      return {
        ...state,
        addBillingAddress: {
          ...state.addBillingAddress,
          loading: false,
          error: action.error,
        },
      };

    case GET_BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        getBillingAddress: { ...state.getBillingAddress, loading: true },
      };

    case GET_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        getBillingAddress: {
          ...state.getBillingAddress,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case GET_BILLING_ADDRESS_ERROR:
      return {
        ...state,
        getBillingAddress: {
          ...state.getBillingAddress,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_BILLING:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
