import CardView from 'components/CardView';
import {
  CardStatusType,
  CardType,
  CurrencyType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import TopBar from 'components/TopBar';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { budgetCard } from 'redux/actions/BudgetsAction';
import CardsModal from '../../Cards/CardsFormModal';
import CardsTableModal from '../../Cards/CardsTableModal';
import MigrateOldBudgetInfo from '../MigrateOldBudgetInfo';
import PendingOnboardingNotice from '../PendingOnboardingNotice';
import CardsEmptyState from 'pages/Cards/CardsEmptyState';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import useIsFiltered from 'hooks/useIsFiltered';

const BudgetCard = () => {
  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const [selectCards, setSelectCards] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [isFillData, setIsFillData] = useState(false);
  const [filterData, setFilterData] = useState([
    ...CardType,
    ...CardStatusType,
    ...CurrencyType,
  ]);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const { filtered, isFiltered, filteredQuery, setFilteredQuery } = useIsFiltered();

  const {
    getBudgetCard: { data: { cards: budgetCards = [] } = {}, loading: budgetCardLoading },
    getSingleBudget: { data: budget, loading },
  } = useSelector(({ budgets }) => budgets);

  const toggleHandler = () => {
    if (!isOtherPopoverOpen) return setIsOpen(!isOpen);
  };

  const handleCardClick = (row) => {
    setSelectCards(row);
  };

  useEffect(() => {
    if (!!budgetCards.length && !isFillData) {
      const allCardUser = budgetCards.map((c) => {
        return {
          value: c.user?.code,
          label: `${c.user?.firstName} ${c.user?.lastName}`,
          isSelected: false,
        };
      });
      const uniqueArray = allCardUser.filter(
        (v, i, a) => a.findIndex((v2) => v2.code === v.code) === i,
      );
      setFilterData([{ title: 'Owner/holder', list: uniqueArray }, ...filterData]);
      setIsFillData(true);
    }
  }, [budgetCards]);

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        budgetCard({ ...filteredQuery, budget: budgetId, search: debouncedValue }),
      );
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(budgetCard({ budget: budgetId, ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleFilterApply = (query) => {
    dispatch(budgetCard({ budget: budgetId, ...query }));
    isFiltered.current = !!Object.keys(query).length;
    setFilteredQuery(query);
  };

  const handleOtherPopover = (val) => {
    setIsOtherPopoverOpen(val);
  };

  const show = !!budgetCards?.length || (filtered && !budgetCards?.length);

  const isClosed = budget?.status === 'closed';
  if (budgetCardLoading && !filtered) return <Loading isPage color="#D28B28" />;

  const renderCard = (card, index) => {
    return (
      <Col md={6} lg={4} className="mb-3" key={`card-${index}`}>
        <CardView
          card={card}
          isActive={card.type !== 'physical'}
          handleSelect={handleCardClick}
        />
      </Col>
    );
  };
  const renderCards = () => {
    return (
      <>
        <TopBar
          addOnClick={toggleHandler}
          inputPlaceholder="Search by name"
          filterData={filterData}
          setSearchVal={setSearch}
          withOutSearch
          showBarSearch={show}
          searchVal={search}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          handleFilterApply={handleFilterApply}
          addIcon={
            (budgetCards?.length || (filtered && !budgetCards?.length)) && !isClosed
          }
          showFilter={
            (budgetCards?.length || (filtered && !budgetCards?.length)) && !isClosed
          }
        />

        {!!budgetCards.length ? (
          <Row className="mt-4">{budgetCards.map(renderCard)}</Row>
        ) : (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no cards for this filter"
                  bodyText="Alter filter to see cards"
                  withButton={false}
                />
              </div>
            ) : (
              <div className="mt-5">
                <CardsEmptyState toggleHandler={toggleHandler} />
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <CardsModal
        IsOtherPopoverOpen={handleOtherPopover}
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        selectedBudget={{
          value: budget?.code,
          label: budget?.name,
          amt: budget?.available,
          spent: budget?.spent,
        }}
        // onSave={beneficiariesAPI}
      />

      <div>
        <PendingOnboardingNotice />
        <MigrateOldBudgetInfo />
        {renderCards()}
      </div>
      <CardsTableModal selectCards={selectCards} setSelectCards={setSelectCards} />
    </>
  );
};

export default BudgetCard;
