import CardView from 'components/CardView';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { buildRequestedCardsTable, capitalizeFirstLetter } from 'utils/helper';
import { requestedCards } from 'utils/mockData';
import { getQueryParams, updateStatus } from 'utils/utility';
import { CurrencyType } from '../../components/FilterModal/FilterHelper';
import { useDebounce } from '../../hooks/useDebounce';
import Loader from './Loader';
import {
  deleteCardRequest,
  getMyCardsAction,
  getRequestedCards,
} from '../../redux/actions/CardAction';
import CardsEmptyState from './CardsEmptyState';
import PhysicalCardDetails from './CardsFormModal/PhysicalCardDetails';
import { CreditCardLock, DeleteUserIcon, TrashIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';

const RequestedCardTable = ({ toggleHandler }) => {
  const dispatch = useDispatch();
  const [activeView, setActiveView] = useState('list');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [step, setStep] = useState(1);

  const [selectCards, setSelectCards] = useState(null);
  const payerCode = query.get('payer');

  const [filterData, setFilterData] = useState([...CurrencyType]);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [search, setSearch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isFillData, setIsFillData] = useState(false);

  const debouncedValue = useDebounce(search, 300);

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getRequestedCards: {
      data: { meta = {}, cardRequests: cardList = [] } = {},
      loading,
      success,
    },
    deleteCardRequest: { loading: isDeleteLoading, success: isDeleteSuccess },
  } = useSelector(({ card }) => card);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const { page, total, hasMore, perPage, nextPage } = meta;

  useEffect(() => {
    if (!cardList?.length) dispatch(getRequestedCards({ payer: payerCode }));
    return () => {
      if (filtered || location?.search) dispatch(getRequestedCards({ payer: payerCode }));
    };
  }, []);

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(getRequestedCards({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(getRequestedCards({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(getRequestedCards({ ...filteredQuery }));
      closeModal();
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (location?.search && userData?.user?.code) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getRequestedCards({ payer: payerCode }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  const handleFilter = (query) => {
    setFilteredQuery(query);
    dispatch(getRequestedCards(query));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleClose = () => {
    setSelectCards(null);
  };

  useEffect(() => {
    if (cardList.length > 0 && budgetData?.budgets?.length > 0 && !isFillData) {
      const allCardUser = cardList
        .map((card) => {
          return {
            value: card.cardOwner?.code,
            label: `${card.cardOwner?.firstName} ${card.cardOwner?.lastName}`,
            isSelected: false,
          };
        })
        .reduce((acc, user) => {
          if (!acc.find((u) => u.value === user.value)) {
            acc.push(user);
          }
          return acc;
        }, []);
      const data = [];
      budgetData?.budgets.forEach((budget) => {
        const { code, name } = budget;
        data.push({
          value: code,
          label: name,
          isSelected: false,
        });
      });
      setFilterData([
        { title: 'Budget', list: data },
        { title: 'Owner', list: allCardUser },
        ...filterData,
      ]);
      setIsFillData(true);
    }
  }, [cardList, budgetData?.budgets]);

  const onHandleToggleView = (option) => {
    setActiveView(option);
  };

  const actionHandler = (event, action, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    if (action === 'activate') return setSelectCards(data);
    setActionType(action);
    setSelectedData(data);
    setShowModal(true);
  };

  const handleAction = () => {
    if (actionType === 'delete') {
      dispatch(deleteCardRequest(selectedData?.code));
    }
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setShowModal(false);
    setSelectedData(null);
    setActionType(null);
  };

  const Actions = ({ list }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'activate', list)}
        >
          <CreditCardLock width="16" height="16" stroke="#57534E" className="mr-3" />{' '}
          Activate card
        </div>
        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', list)}
        >
          <TrashIcon width="16" height="16" className="mr-4" /> Delete request
        </div>
      </div>
    );
  };

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(
          getMyCardsAction({
            owner: userData?.user?.code,
            payer: payerCode,
            perPage,
            ...filteredQuery,
          }),
        );
        isFiltered.current = true;
      }
    }, 1500);
  };

  const handleRowClick = (row) => {
    setSelectCards(row);
  };

  const cardItem = () => {
    return (
      <>
        {cardList.length > 0 ? (
          <>
            <Container className="px-0 mt-4">
              {activeView === 'grid' && (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => handleMore(perPage + 50)}
                  hasMore={hasMore}
                  className="w-100"
                >
                  <Row className="budgets-card-wrapper w-100">
                    {cardList?.map((card) => {
                      const cardData = {
                        ...card,
                        name: `${card.firstName} ${card.lastName}`,
                        brand: 'verve',
                        user: { firstName: card.firstName, lastName: card.lastName },
                        status: 'processing',
                        type: 'physical',
                      };

                      return (
                        <Col md={6} lg={4} className="mb-3" key={`card-${card.code}`}>
                          <CardView
                            card={cardData}
                            handleSelect={() => setSelectCards(cardData)}
                            key={card.code}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </InfiniteScroll>
              )}

              {activeView === 'list' && (
                <Row>
                  <Col xs={12}>
                    <Table
                      hasCheckBox={false}
                      isCustomWidth={true}
                      stringType={false}
                      columns={requestedCards}
                      data={buildRequestedCardsTable(cardList)}
                      onRowClick={handleRowClick}
                      pagination
                      hasMore={hasMore}
                      currentPage={page}
                      nextPage={() => handleMore(nextPage)}
                      previousPage={() => handleMore(page - 1)}
                      totalPage={Math.ceil(total / perPage)}
                      popoverAction={Actions}
                      popoverState={isPopoverOpen}
                      setPopoverState={setIsPopoverOpen}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </>
        ) : (
          <div>
            {isFiltered.current ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no cards for this filter yet"
                  buttonLabel="Go back to my cards"
                  bodyText="You currently don't have any cards created for this particular filter yet, use the button to go back your card listing"
                  onClickHandler={() => dispatch(getMyCardsAction(query))}
                  withButton={true}
                />
              </div>
            ) : (
              <CardsEmptyState toggleHandler={toggleHandler} />
            )}
          </div>
        )}
      </>
    );
  };

  const show = !!cardList?.length || (filtered && !cardList?.length);

  return (
    <div>
      <PendingOnboardingNotice />

      <TopBar
        showBarSearch={show}
        searchVal={search}
        inputPlaceholder="Search by name "
        showFilter={show}
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        setSearchVal={setSearch}
        handleFilterApply={handleFilter}
        toggleView={show}
        handleToggleView={onHandleToggleView}
        activeView={activeView}
      />

      {loading && !filtered ? (
        <Loader
          columns={requestedCards}
          data={buildRequestedCardsTable(cardList)}
          isRequest
        />
      ) : (
        cardItem()
      )}

      <PhysicalCardDetails
        selectCards={selectCards}
        setSelectCards={setSelectCards}
        step={step}
        handleClose={handleClose}
        setStep={setStep}
      />

      <Modal show={showModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} card`}
          subTitle={
            <>
              Are you sure you want to {actionType} this card?
              {actionType === 'delete' && (
                <>
                  <br />
                  This action cannot be undone.
                </>
              )}
            </>
          }
          onConfirm={handleAction}
          loading={isDeleteLoading}
          onCancel={closeModal}
          isDeleteDialog={actionType === 'delete'}
        />
      </Modal>
    </div>
  );
};

export default RequestedCardTable;
