import { CopyIcon, PencilIcon } from 'assets/icons';
import AssetViewer from 'components/AssetViewer';
import DeleteDialog from 'components/DeleteDialog';
import Timeline from 'components/Timeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import CustomDrawer from 'components/UI/CustomDrawer';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import DrawerHeader from 'components/UI/CustomDrawer/components/DrawerHeader';
import ReceiptList from 'components/UI/CustomDrawer/components/ReceiptList';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import CategorySelect from 'components/UI/CustomSelect/CategorySelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBudgets } from 'redux/actions/BudgetsAction';
import { deleteCategories, fetchCategories } from 'redux/actions/CategoryAction';
import {
  deleteReimbursements,
  getAllReimbursementAsset,
  getReimbursements,
  getSingleReimbursement,
  updateReimbursements,
} from 'redux/actions/ReimbursementsAction';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import {
  getFormattedDate,
  transactionAcceptedFiles,
  transactionFileSupported,
} from 'utils/helper';

const ViewReimbursementDetails = ({ isOpen, handleRowClick, selectReimbursement }) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleting, setDeleting] = useState(false);
  const [assetViewer, setAssetViewer] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);

  const {
    getAsset: { data: assetData = {} },
  } = useSelector(({ assets }) => assets);
  const { asset = [] } = assetData;

  const {
    deleteReimbursement: { success },
    reimbursementReceipts: { data: receiptAssets, loading: loadingReceipt },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    reimbursementsData: {
      budget,
      code,
      reviewer = {},
      reviewed_on = null,
      expense_date,
      status,
      approvalRequest = {},
      user: reimbursementUser,
    } = {},
  } = selectReimbursement || {};
  const { approvals: completedApprovals = [] } = approvalRequest || {};

  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [budgetVisible, setBudgetVisible] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [ruleCode, setRuleCode] = useState(null);
  const [budgetsList, setBudgetsList] = useState([]);
  const [receiptCode, setReceiptCode] = useState([]);

  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [saveButton, setSaveButton] = useState(false);

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
    getBudget: { data: budgetData = {} },
    getTransferableBalance: { data: budgetBalance = {} },
  } = useSelector(({ budgets }) => budgets);

  const { budgets = [] } = budgetData;

  const {
    getSingleReimbursement: { data: singleData = {}, loading: singleLoading },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { success: successDelete, loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const createByMe = user?.code === reimbursementUser?.code;

  useEffect(() => {
    if (!singleLoading && code) {
      dispatch(getSingleReimbursement(code));
    }
  }, [selectReimbursement]);

  useEffect(() => {
    if (singleData?.data?.reimbursement?.code) {
      dispatch(getAllReimbursementAsset(singleData?.data?.reimbursement?.receipts));
    }
  }, [singleData]);

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(false);
    }
  }, [receiptCode?.receipt]);

  const handleClose = () => {
    setAssetViewer(false);
    setSelectedAsset(null);
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'getSingleReimbursement' });
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'reimbursementReceipts' });
    toggleAssetViewer(false);
    handleRowClick();
  };

  const onHandleGetSelected = (value) => {
    editReimbursement(code, value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
  };

  const editReimbursement = (code, categoryCode) => {
    dispatch(updateReimbursements({ code, category: categoryCode }));
    setIsEditingCategory(true);
  };

  const handleClick = () => {
    history.push(`/expenses/budgets/${budget?.code}/overview`);
  };

  const attemptDelete = () => {
    setDeleting(true);
  };

  const deleteReimbursement = () => {
    dispatch(deleteReimbursements(code));
  };

  useEffect(() => {
    if (budgets.length === 0) dispatch(getBudgets({ status: 'active' }));
  }, []);

  useEffect(() => {
    if (budgets.length > 0) {
      const budgetsOptions = budgets?.map(({ code: value, name: label }) => ({
        value,
        label,
      }));

      setBudgetsList(budgetsOptions);
    }
  }, [budgets]);

  const saveBudget = (budget) => {
    setBudgetLoading(true);
    return dispatch(updateReimbursements({ code, budget: budget?.value }));
  };

  useEffect(() => {
    if (success) {
      window.setTimeout(function () {
        handleRowClick();
        dispatch(getReimbursements());
      }, 1000);
    }
  }, [success]);

  const viewImgUrl = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const toggleAssetViewer = () => {
    setAssetViewer(!assetViewer);
  };

  const closeApprovalRule = () => setRuleCode(null);

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const openRule = (code) => {
    setRuleCode(code);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  const cancelEdit = () => {
    setReceiptCode('');
    setSaveButton(false);
  };

  const handleEdit = () => {
    if (!!receiptCode?.receipt?.length) {
      dispatch(updateReimbursements({ code, receipt: receiptCode?.receipt }));
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }
  };

  const approvers = [];

  approvalRequest?.approvalStages.forEach((item) => {
    item.approvers.forEach((approver) => {
      const user = approver.user;
      const approver_code = approver.code;
      approvers.push({ user, approver_code, status: 'pending', message: '' });
    });
  });

  completedApprovals?.forEach((item) => {
    approvers.push({
      user: item?.approver?.user,
      date: getFormattedDate(item?.updated_at),
      status: item?.status,
      message: item?.reason ?? '',
    });
  });

  const bodyData = [
    {
      title: 'Requested by',
      value: `${singleData?.data?.reimbursement?.user?.firstName} ${singleData?.data?.reimbursement?.user?.lastName}`,
      icon: (
        <span className="detail-thumbnail thumbnail-primary thumbnail-small text-xs">
          {singleData?.data?.reimbursement?.user?.firstName.slice('')[0]}
        </span>
      ),
    },
    {
      title: 'Date',
      value: getFormattedDate(expense_date),
    },
    {
      title: 'Reimbursement ID',
      value: singleData?.data?.reimbursement?.code,
      isCopy: true,
      icon: <CopyIcon stroke="#D28B28" width="20" height="20" />,
    },
  ];

  return (
    <div>
      <CustomDrawer show={true} onClose={handleClose}>
        {assetViewer && (
          <AssetViewer
            toggleVisibility={toggleAssetViewer}
            data={receiptAssets}
            selectedAsset={selectedAsset}
          />
        )}
        {singleLoading && (
          <div className="content">
            <div className="card-modal-body">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '100vh' }}
              >
                <Loading color="#d2b28b" size={32} />
              </div>
            </div>
          </div>
        )}
        {!singleLoading && singleData && !deleting && (
          <div className="content">
            <DrawerHeader
              handleClose={handleClose}
              status={selectReimbursement?.status?.value}
              receipient={selectReimbursement?.vendor}
              title="Reimbursement details"
              moneyData={{
                currency: singleData?.data?.reimbursement?.currency,
                amount: singleData?.data?.reimbursement?.amount,
              }}
            />

            <div className="card-modal-body">
              <div className="information-wrapper">
                <DrawerBody data={bodyData} />
                <div className="divider"></div>
                <Timeline data={approvers} goToRule={openRule} />
                <div className="divider"></div>
                <div>
                  <ItemInfo
                    title="Expense category"
                    value={
                      expenseCategories.value.label ??
                      singleData?.data?.reimbursement?.category?.name ??
                      'None'
                    }
                    withAction={status !== 'declined' && createByMe}
                    action={() => setCategoryVisible(!categoryVisible)}
                    isLoading={isEditingCategory}
                    icon={
                      status !== 'declined' &&
                      createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                    }
                  />
                </div>

                {categoryVisible && (
                  <div className="padding-top-xxs">
                    <CategoryDropdown
                      placeholder="Select a category"
                      onChange={(val) => onHandleGetSelected(val)}
                      value={
                        expenseCategories.value ?? {
                          value: singleData?.data?.reimbursement?.category?.code,
                          label: singleData?.data?.reimbursement?.category?.name,
                        }
                      }
                      defaultValue={{
                        value: singleData?.data?.reimbursement?.category?.code,
                        label: singleData?.data?.reimbursement?.category?.name,
                      }}
                      name="category"
                    />
                  </div>
                )}
                <div className="padding-top-2">
                  <ItemInfo
                    title="Budget"
                    isLink={
                      singleData?.data?.reimbursement?.budget?.code
                        ? `/expenses/budgets/${singleData?.data?.reimbursement?.budget.code}/overview`
                        : false
                    }
                    withAction={!['success', 'declined', 'processing'].includes(status)}
                    action={() => setBudgetVisible(!budgetVisible)}
                    value={singleData?.data?.reimbursement?.budget?.name ?? 'N/A'}
                    isLoading={budgetLoading}
                    icon={
                      createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                    }
                  />
                </div>
                {budgetVisible && (
                  <div className="padding-top-xxs">
                    <CustomSelect
                      placeholder="Select a budget"
                      name="budget"
                      isClearable
                      options={budgetsList}
                      onChange={(val) => saveBudget(val)}
                      defaultValue={{
                        value: singleData?.data?.reimbursement?.budget?.code,
                        label: singleData?.data?.reimbursement?.budget?.name,
                      }}
                    />
                  </div>
                )}

                <div className="padding-top-2">
                  <ItemInfo
                    title="Description"
                    flex={1}
                    value={singleData?.data?.reimbursement?.description}
                    withAction={!['declined', 'processing'].includes(status)}
                    hideValue={description?.visible}
                    action={() => {
                      setDescription({ ...description, visible: !description.visible });
                      setSaveButton(!saveButton);
                    }}
                    isLoading={isLoading === 'description'}
                    icon={
                      !['declined', 'processing'].includes(status) &&
                      createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                    }
                  />
                  {description?.visible && (
                    <div className="padding-top-xxs">
                      <CustomTextarea
                        name="description"
                        placeholder="Description"
                        value={description?.data}
                        onChange={handleDescriptionChange}
                        rowSize={3}
                      />
                    </div>
                  )}
                </div>

                <div className="padding-top-2">
                  {loadingReceipt && <Loading color="#D28B28" size={20} />}
                  {!loadingReceipt && receiptAssets?.length <= 0 && createByMe && (
                    <div>
                      <FileUpload
                        label="Receipts or Attachments"
                        placeholder="Upload receipts or invoices (PDF, JPEG, PNG, XLSX)"
                        name="file"
                        onChange={(value) =>
                          setReceiptCode({
                            receipt: value?.map((item) => item.assetCode),
                          })
                        }
                        removeFile={!receiptCode.receipt}
                        multiple
                        wrapperClass="new-receipt"
                        supportType={transactionFileSupported}
                        acceptedFile={transactionAcceptedFiles}
                      />
                    </div>
                  )}
                  {!loadingReceipt && receiptAssets?.length > 0 && (
                    <ReceiptList
                      receiptAssets={receiptAssets}
                      onChange={(value) =>
                        setReceiptCode({
                          receipt: value?.map((item) => item.assetCode),
                        })
                      }
                      removeFile={!receiptCode.receipt}
                      viewImgUrl={viewImgUrl}
                    />
                  )}
                </div>
              </div>
            </div>

            {saveButton && (
              <div className="card-modal-footer justify-content-end">
                <CustomButton
                  onClick={cancelEdit}
                  className="base-button text-sm font-medium  black-transparent"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  onClick={handleEdit}
                  className="base-button text-sm font-medium dark-button"
                >
                  {receiptCode?.isUpdating ? (
                    <Loading color="#D28B28" size={20} />
                  ) : (
                    'Save changes'
                  )}
                </CustomButton>
              </div>
            )}

            {status === 'pending' && createByMe && !saveButton && (
              <div className="card-modal-footer justify-content-end">
                <CustomButton
                  withoutBg
                  fullWidth
                  className="base-button danger-button text-sm font-medium"
                  onClick={attemptDelete}
                >
                  Delete request
                </CustomButton>
              </div>
            )}
          </div>
        )}
      </CustomDrawer>

      {deleting && (
        <DeleteDialog
          title={`Delete request`}
          subTitle="Are you sure you want to delete this request? This action can not be undone."
          onCancel={() => setDeleting(false)}
          onDelete={deleteReimbursement}
          styles={{ width: 400 }}
          isLoading={loadingDelete}
        />
      )}
    </div>
  );
};
export default ViewReimbursementDetails;
