import { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import Modal from 'components/UI/Modal';
import CurrencyFormat from 'react-currency-format';
import { getAvailableBalance, getCurrency, groupSourceOptions } from 'utils/helper';

import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances, withdrawBudgetFunds } from 'redux/actions/BudgetsAction';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import './styles.scss';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';

const WithdrawFundsModal = ({ handleClose, data }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState({ amount: 0, source: '' });

  const {
    withdrawnBudget: { loading, success },
  } = useSelector(({ budgets }) => budgets);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const currentAmount = data?.available / 100;

  const handleChange = ({ target: { value, rawValue } }) => {
    setInput({ ...input, amount: rawValue });
  };
  const isDisabled = parseInt(input.amount) > currentAmount || loading;

  const onHandleSubmit = () => {
    if (parseInt(input.amount) > currentAmount)
      return toastError("You don't have up to this amount");
    if (!input.source) return toastError('Please select a source');
    dispatch(
      withdrawBudgetFunds({
        code: data?.code,
        amount: parseInt(input.amount) * 100,
        settlementBalance: input.source.value,
      }),
    );
  };

  useEffect(() => {
    if (!loading && success) {
      dispatch({
        type: RESET_BLOCK_BUDGET,
        blockType: 'withdrawnBudget',
      });
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [loading, success]);

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.balances?.length) {
      const available_balance = getAvailableBalance(balances?.balances);
      setBudgetList((prevOptions) => [...available_balance]);
    }
  }, [balances?.balances]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }
  return (
    <Modal show={true} onClose={handleClose}>
      <div className="content withdraw">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Cancel</span>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="information-wrapper">
            <h2 className="card-title w-100">Withdraw funds from {data.name}</h2>
            <div>
              <CustomInput
                label="Amount"
                value={input.amount}
                isAmount
                editCurrency={false}
                type="number"
                onChange={handleChange}
              />
              <div className="amount-info">
                <span>
                  You have&nbsp;
                  <CurrencyFormat
                    prefix={getCurrency(data?.currency)}
                    value={currentAmount}
                    thousandSeparator={true}
                    displayType="text"
                    className="total-amount"
                    onClick={() => setInput({ ...input, amount: data?.available / 100 })}
                  />
                  &nbsp;available in {data?.name}
                </span>
              </div>

              <div className="mb-2 mt-2">
                <CustomSelectRadio
                  label="Transfer remaining funds to"
                  name="source"
                  value={input.source}
                  placeholder="Select a destination account"
                  onChange={(val) => setInput({ ...input, source: val })}
                  // defaultValue={getSingleBudgetData?.source?.code && defaultValue}
                  isLoading={loadingBalances}
                  isDisabled={loadingBalances}
                  loadOptions={loadOptions}
                />
              </div>
            </div>
            <div className="modal-footer">
              <CustomButton
                onClick={handleClose}
                fullWidth={true}
                className="custom-button ghost-button"
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={onHandleSubmit}
                fullWidth={true}
                disabled={isDisabled}
                className="custom-button primary-button"
              >
                {loading ? <Loading color="#d28b28" size={20} /> : 'Confirm withdraw'}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawFundsModal;
