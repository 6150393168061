/**
 *
 * @param {function} handleClose: a callback function to close the drawer
 * @param {boolean} status: the status of the transaction
 * @returns
 */

import { ArrowDownIcon, KebabIcon, XcloseIcon } from 'assets/icons';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import CurrencyFormat from 'react-currency-format';
import { Link, useHistory } from 'react-router-dom';
import { FormattedCurrency, getColor, getCurrency } from 'utils/helper';
import ImgCard from 'components/UI/ImgCard';
import { TransferInIcon } from 'assets/icons/transfer-in-icon';
import './index.scss';

const PaymentHeader = ({
  handleClose,
  status,
  popoverData,
  receipient,
  moneyData,
  title,
  badgeIcon = null,
  recipientLink = null,
}) => {
  const history = useHistory();

  return (
    <div className="payment-header">
      <BadgeType
        value={{
          value: status,
          color: getColor(status),
          icon: badgeIcon,
          isStatus: true,
        }}
      />

      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="amount-value">
          <FormattedCurrency
            className="details-amount font-semibold"
            value={moneyData?.amount}
            prefix={getCurrency(moneyData?.currency)}
          />
        </div>

        <div className="transfer-in-icon lg">
          <ArrowDownIcon height={30} width={30} />
        </div>
      </div>
      <div className="d-flex align-items-center text-sm text-grey gap-2 receiver">
        <p className="m-0">To</p>
        <div
          className="d-flex cursor"
          onClick={recipientLink ? () => history.push(recipientLink) : null}
        >
          <ImgCard initials={receipient && receipient[0].trim()} />
        </div>
        {recipientLink ? (
          <Link to={recipientLink} target="_blank" className="recipient-link">
            <span>{receipient}</span>
          </Link>
        ) : (
          <span>{receipient}</span>
        )}
      </div>
    </div>
  );
};

export default PaymentHeader;
