import {
  ADD_CONDITION_REQUEST,
  ADD_NEW_APPROVAL_LEVEL_REQUEST,
  DELETE_APPROVAL_LEVEL_REQUEST,
  DELETE_CONDITION_REQUEST,
  EDIT_APPROVAL_RULE_BY_ID_REQUEST,
  FETCH_APPROVAL_RULES_REQUEST,
  FETCH_PENDING_APPROVALS_REQUEST,
  FETCH_PENDING_APPROVAL_ASSET_REQUEST,
  FETCH_RELATED_APPROVALS_REQUEST,
  FETCH_SINGLE_APPROVAL_REQUEST,
  RESET_APPROVAL_LEVEL_RULE,
  RESET_CREATE_APPROVAL_RULE,
  REVIEW_MULTIPLE_REQUEST_ERROR,
  REVIEW_REQUEST_REQUEST,
  FETCH_APPROVAL_RULES_ACTIVITY,
  REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST,
} from '../reducers/ApprovalReducer';

export const fetchApprovalRules = (payload) => ({
  type: FETCH_APPROVAL_RULES_REQUEST,
  payload,
});

export const fetchApprovalRule = (code) => ({
  type: 'FETCH_APPROVAL_RULE_REQUEST',
  code,
});

export const fetchApprovalRuleActivity = (payload) => ({
  type: FETCH_APPROVAL_RULES_ACTIVITY,
  payload,
});

export const createApprovalRule = (payload) => ({
  type: 'CREATE_APPROVAL_RULE_REQUEST',
  payload,
});

export const fetchTransactionTypes = () => ({
  type: 'FETCH_TRANSACTION_TYPES_REQUEST',
});

export const fetchApprovers = () => ({
  type: 'FETCH_APPROVERS_REQUEST',
});

export const deleteApprovers = (id) => ({
  type: 'DELETE_APPROVAL_RULE_REQUEST',
  id,
});

export const fetchPendingApprovals = (payload) => ({
  type: FETCH_PENDING_APPROVALS_REQUEST,
  payload,
});

export const fetchSingleApproval = (code) => ({
  type: FETCH_SINGLE_APPROVAL_REQUEST,
  code,
});

export const getRelatedApprovals = (code) => ({
  type: FETCH_RELATED_APPROVALS_REQUEST,
  code,
});

export const reviewRequest = (payload) => ({
  type: REVIEW_REQUEST_REQUEST,
  payload,
});

export const reviewMultipleRequests = (payload) => ({
  type: REVIEW_MULTIPLE_REQUEST_ERROR,
  payload,
});

export const reviewMultiplePendingRequests = (payload) => ({
  type: REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST,
  payload,
});

export const resetCreateApprovalRule = () => ({
  type: RESET_CREATE_APPROVAL_RULE,
});

export const editApprovalRule = (payload) => ({
  type: EDIT_APPROVAL_RULE_BY_ID_REQUEST,
  payload,
});

export const deleteCondition = (payload) => ({
  type: DELETE_CONDITION_REQUEST,
  payload,
});

export const addCondition = (payload) => ({
  type: ADD_CONDITION_REQUEST,
  payload,
});

export const addNewApproverLevel = (payload) => ({
  type: ADD_NEW_APPROVAL_LEVEL_REQUEST,
  payload,
});

export const deleteApprovalLevel = (payload) => ({
  type: DELETE_APPROVAL_LEVEL_REQUEST,
  payload,
});

export const resetDeleteAPprovalLevel = () => ({
  type: RESET_APPROVAL_LEVEL_RULE,
});

export const getPendingApprovalAsset = (payload) => ({
  type: FETCH_PENDING_APPROVAL_ASSET_REQUEST,
  payload,
});
