import React from 'react';
import { Switch } from 'antd';
import cs from 'classnames';

const CustomSwitch = ({ label, fullWidth = true, ...res }) => {
  return (
    <div
      className={cs(
        { 'w-100': fullWidth },
        'd-flex justify-content-between align-items-center',
      )}
    >
      {label && <label className="pe-2">{label}</label>}
      <Switch {...res} className="switch-default" />
    </div>
  );
};
export default CustomSwitch;
