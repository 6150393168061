import React from 'react';
import { Tooltip } from 'antd';
import './styles.scss';

const OverlayingIcons = ({ userList = [], budget = false }) => {
  if (userList?.length === 0) return <div />;
  const listIcon = () => {
    if (budget) {
      return (
        userList.length &&
        userList?.map((list, index) => {
          if (index > 1) return;
          if (index === 1 && userList?.length > 1) {
            return (
              <div className="icon-wrappers counter mx-0" key={index}>
                <span className="icon-name">+{userList.length - 1}</span>
              </div>
            );
          } else {
            return (
              <div key={index}>
                <span className="mr-3 fs-6">{list.name}</span>
              </div>
            );
          }
        })
      );
    } else {
      return (
        userList.length &&
        userList?.map((list, index) => {
          const {
            firstName = '',
            lastName = '',
            url = '',
            email = '',
          } = (list?.user ? list?.user : list) ?? {};
          if (index > 2) return;
          if (index === 2 && userList?.length > 2) {
            return (
              <div className="icon-wrappers counter" key={index}>
                <span className="icon-name">+{userList?.length - 2}</span>
              </div>
            );
          } else {
            return (
              <div className="icon-wrappers" key={index}>
                {url ? (
                  <img src={url} className="icon-img" alt="icon-img" />
                ) : budget ? (
                  <span className="mr-3 fs-6">{userList?.name}</span>
                ) : (
                  <Tooltip
                    placement="bottom"
                    title={`${firstName} ${lastName}`}
                    key={`${firstName}-${lastName}`}
                    color={'#000'}
                  >
                    <span className="icon-name">
                      {firstName ? `${firstName.charAt(0)}` : email.substr(0, 2)}
                    </span>
                  </Tooltip>
                )}
              </div>
            );
          }
        })
      );
    }
  };

  return <div className="overlaying-icons-wrapper">{listIcon()}</div>;
};
export default OverlayingIcons;
