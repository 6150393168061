import { Axios } from 'api/axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import {
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_ERROR,
  FETCH_INVOICE_REQUEST,
  FETCH_INVOICE_SUCCESS,
  FETCH_INVOICE_ERROR,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  SEARCH_INVOICE_CUSTOMERS_SUCCESS,
  SEARCH_INVOICE_CUSTOMERS_ERROR,
  RESET_FLAGS_INVOICE,
  SEARCH_INVOICE_CUSTOMERS_REQUEST,
  SHARE_INVOICE_ERROR,
  SHARE_INVOICE_REQUEST,
  SHARE_INVOICE_SUCCESS,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_REQUEST,
  FETCH_CUSTOMERS_ERROR,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_ERROR,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  DELETE_INVOICE_ERROR,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_REQUEST,
  SCAN_INVOICE_ERROR,
  SCAN_INVOICE_SUCCESS,
  SCAN_INVOICE_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_REQUEST,
  MARK_AS_PAID_SUCCESS,
  MARK_AS_PAID_ERROR,
  MARK_AS_PAID_REQUEST,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_ERROR,
  UPDATE_SCHEDULED_INVOICE_REQUEST,
  UPDATE_SCHEDULED_INVOICE_SUCCESS,
  UPDATE_SCHEDULED_INVOICE_ERROR,
  RESET_BLOCK_INVOICE,
  UPDATE_INVOICE_REQUEST,
  FETCH_SCHEDULED_INVOICE_SUCCESS,
  FETCH_SCHEDULED_INVOICE_ERROR,
  FETCH_SCHEDULED_INVOICE_REQUEST,
  FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST,
  FETCH_SINGLE_SCHEDULED_INVOICE_SUCCESS,
  FETCH_SINGLE_SCHEDULED_INVOICE_ERROR,
  DELETE_SCHEDULED_INVOICE_SUCCESS,
  DELETE_SCHEDULED_INVOICE_ERROR,
  DELETE_SCHEDULED_INVOICE_REQUEST,
  CREATE_BULK_CUSTOMER_ERROR,
  CREATE_BULK_CUSTOMER_SUCCESS,
  CREATE_BULK_CUSTOMER_REQUEST,
} from '../reducers/InvoiceReducer';
import { toastError, toastSuccess } from 'components/UI/toast';

async function fetchInvoices({ payload = {} }) {
  return await Axios.get('/invoices', { params: payload });
}

function* handleFetchInvoices(payload) {
  try {
    const response = yield call(fetchInvoices, payload);
    if (response) {
      yield put({
        type: FETCH_INVOICES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_INVOICES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchInvoice({ payload }) {
  return await Axios.get(`/invoices/${payload.code}`);
}

function* handleFetchInvoice({ payload }) {
  try {
    const response = yield call(fetchInvoice, { payload });
    if (response) {
      yield put({
        type: FETCH_INVOICE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createInvoice(payload) {
  return await Axios.post('/invoices', payload);
}

function* handleCreateInvoice({ payload }) {
  try {
    const response = yield call(createInvoice, payload);
    if (response) {
      yield put({
        type: CREATE_INVOICE_SUCCESS,
        data: response.data,
      });
      yield put({
        type: FETCH_INVOICES_REQUEST,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchScheduledInvoice({ payload = {} }) {
  return await Axios.get('/scheduled-invoice', { params: payload });
}

function* handleFetchScheduledInvoices(payload) {
  try {
    const response = yield call(fetchScheduledInvoice, payload);
    if (response) {
      yield put({
        type: FETCH_SCHEDULED_INVOICE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SCHEDULED_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSingleScheduledInvoice({ payload }) {
  return await Axios.get(`/scheduled-invoice/${payload.code}`);
}

function* handleFetchSingleScheduledInvoice({ payload }) {
  try {
    const response = yield call(fetchSingleScheduledInvoice, { payload });
    if (response) {
      yield put({
        type: FETCH_SINGLE_SCHEDULED_INVOICE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_SCHEDULED_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteScheduledInvoice(code) {
  return await Axios.delete(`/scheduled-invoice/${code}`);
}

function* handleDeleteScheduledInvoice({ payload }) {
  try {
    const response = yield call(deleteScheduledInvoice, payload);
    if (response) {
      yield put({
        type: DELETE_SCHEDULED_INVOICE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_SCHEDULED_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateInvoice(payload) {
  const { code, ...data } = payload;
  return await Axios.put(`/invoices/${code}`, data);
}

function* handleUpdateInvoice({ payload }) {
  try {
    const response = yield call(updateInvoice, payload);
    if (response) {
      yield put({
        type: UPDATE_INVOICE_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_INVOICE,
        blockType: 'updateInvoice',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateScheduledInvoice(payload) {
  const { code, ...data } = payload;
  return await Axios.patch(`/scheduled-invoice/${code}`, data);
}

function* handleUpdateScheduledInvoice({ payload }) {
  try {
    const response = yield call(updateScheduledInvoice, payload);
    if (response) {
      yield put({
        type: UPDATE_SCHEDULED_INVOICE_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_INVOICE,
        blockType: 'updateInvoice',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_SCHEDULED_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function scanFile(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/invoices/scan/${code}`, data);
}

function* handleScanFile({ payload }) {
  try {
    const response = yield call(scanFile, payload);
    if (response) {
      yield put({
        type: SCAN_INVOICE_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_INVOICE,
        blockType: 'scanFile',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SCAN_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createCustomer({ payload }) {
  return await Axios.post('/customers', payload);
}

function* handleCreateCustomers({ payload }) {
  try {
    const response = yield call(createCustomer, { payload });
    if (response) {
      yield put({
        type: CREATE_CUSTOMER_SUCCESS,
        data: response.data,
      });
    }
    toastSuccess(response.message);
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_CUSTOMER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBulkCustomers({ payload }) {
  return await Axios.post('/customers/mass-create', payload);
}

function* handleCreateBulkCustomers({ payload }) {
  try {
    const response = yield call(createBulkCustomers, { payload });
    if (response) {
      yield put({
        type: CREATE_BULK_CUSTOMER_SUCCESS,
        data: response.data,
      });
    }
    toastSuccess(response.message);

    yield delay(300);
    yield put({
      type: RESET_FLAGS_INVOICE,
      blockType: 'createBulkCustomers',
    });
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BULK_CUSTOMER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteCustomer(code) {
  return await Axios.delete(`/customers/${code}`);
}
function* handleDeleteCustomer({ payload }) {
  try {
    const response = yield call(deleteCustomer, payload);
    if (response) {
      yield put({
        type: DELETE_CUSTOMER_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function searchCustomers(params = {}) {
  return await Axios.get('/customers/search', { params });
}

function* handleCustomerSearch({ payload }) {
  try {
    const response = yield call(searchCustomers, payload);
    if (response) {
      yield put({
        type: SEARCH_INVOICE_CUSTOMERS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_FLAGS_INVOICE,
        blockType: 'invoiceCustomers',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SEARCH_INVOICE_CUSTOMERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function shareInvoice({ payload }) {
  const code = payload.code;
  delete payload.code;

  return await Axios.post(`/invoices/${code}/send-reminder`, payload);
}

function* handleShareInvoice({ payload }) {
  try {
    const response = yield call(shareInvoice, { payload });
    if (response) {
      yield put({
        type: SHARE_INVOICE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SHARE_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchCustomers({ payload = {} }) {
  return await Axios.get('/customers', { params: payload });
}

function* handleFetchCustomers(payload) {
  try {
    const response = yield call(fetchCustomers, payload);
    if (response) {
      yield put({
        type: FETCH_CUSTOMERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CUSTOMERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchCustomer({ payload = {} }) {
  return await Axios.get(`/customers/${payload.code}`);
}

function* handleFetchCustomer(payload) {
  try {
    const response = yield call(fetchCustomer, payload);
    if (response) {
      yield put({
        type: FETCH_CUSTOMER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CUSTOMER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteInvoice(code) {
  return await Axios.delete(`/invoices/${code}`);
}

function* handleDeleteInvoice({ payload }) {
  try {
    const response = yield call(deleteInvoice, payload);
    if (response) {
      yield put({
        type: DELETE_INVOICE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_INVOICE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function markInvoiceAsPaid(code, payload) {
  return await Axios.patch(`/invoices/${code}/mark-as-paid`, payload);
}

function* handleMarkInvoiceAsPaid({ payload: { code, installments } }) {
  try {
    const response = yield call(markInvoiceAsPaid, code, { installments });
    if (response) {
      yield put({
        type: MARK_AS_PAID_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: MARK_AS_PAID_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_INVOICES_REQUEST, handleFetchInvoices),
  takeLatest(FETCH_INVOICE_REQUEST, handleFetchInvoice),
  takeLatest(CREATE_INVOICE_REQUEST, handleCreateInvoice),
  takeLatest(SEARCH_INVOICE_CUSTOMERS_REQUEST, handleCustomerSearch),
  takeLatest(SHARE_INVOICE_REQUEST, handleShareInvoice),
  takeLatest(CREATE_CUSTOMER_REQUEST, handleCreateCustomers),
  takeLatest(CREATE_BULK_CUSTOMER_REQUEST, handleCreateBulkCustomers),
  takeLatest(DELETE_CUSTOMER_REQUEST, handleDeleteCustomer),
  takeLatest(FETCH_CUSTOMERS_REQUEST, handleFetchCustomers),
  takeLatest(FETCH_CUSTOMER_REQUEST, handleFetchCustomer),
  takeLatest(DELETE_INVOICE_REQUEST, handleDeleteInvoice),
  takeLatest(DELETE_SCHEDULED_INVOICE_REQUEST, handleDeleteScheduledInvoice),
  takeLatest(MARK_AS_PAID_REQUEST, handleMarkInvoiceAsPaid),
  takeLatest(UPDATE_INVOICE_REQUEST, handleUpdateInvoice),
  takeLatest(UPDATE_SCHEDULED_INVOICE_REQUEST, handleUpdateScheduledInvoice),
  takeLatest(SCAN_INVOICE_REQUEST, handleScanFile),
  takeLatest(FETCH_SCHEDULED_INVOICE_REQUEST, handleFetchScheduledInvoices),
  takeLatest(FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST, handleFetchSingleScheduledInvoice),
]);
