import DeleteDialog from 'components/DeleteDialog';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteTeam, getTeams, updateTeam } from 'redux/actions/TeamsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import TeamsModal from '../TeamsModal';
import './styles.scss';
import { MoreOutlined } from '@ant-design/icons';
import TopBar from 'components/TopBar';
import CustomPopover from 'components/UI/Popover';
import { Badge } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon-red.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import { ReactComponent as ToggleIcon } from '../../../assets/icons/toggle-icon.svg';

const SelectedTeamsHeader = ({ teamData = {} }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { permissions, onboardingStatus } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['team-*', 'team-create', 'team-edit'],
  });

  const {
    deleteTeam: { success: deleteTeamSuccess },
    updateTeam: { success },
  } = useSelector(({ teams }) => teams);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isActive, setIsActive] = useState();

  useEffect(() => {
    if (teamData) {
      setIsActive(teamData.status === 'active');
    }
  }, [teamData]);

  useEffect(() => {
    if (deleteTeamSuccess && deleteModal) {
      history.push('/teams');
      deleteToggleHandler();
    }
    if (success) {
      dispatch(getTeams());
    }
  }, [deleteTeamSuccess, success]);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = () => {
    dispatch(deleteTeam(teamData?.code));
    deleteToggleHandler();
  };

  const deleteToggleHandler = () => setDeleteModal(!deleteModal);
  const onSwitchHandler = () => {
    dispatch(
      updateTeam({ code: teamData?.code, status: isActive ? 'inactive' : 'active' }),
    );
    setIsActive(!isActive);
  };

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            ['pending', 'rejected'].includes(onboardingStatus) ? null : onSwitchHandler(),
              handleButtonToggle();
          }}
        >
          <ToggleIcon className="mr-4" /> {isActive ? 'Deactivate team' : 'Activate team'}
        </div>
        <div
          className="actionLink"
          onClick={() => {
            toggleHandler();
            handleButtonToggle();
          }}
        >
          <SettingsIcon className="mr-4" /> Update team
        </div>
        <div
          className="actionLink svg-danger text-danger"
          onClick={() => {
            deleteToggleHandler();
            handleButtonToggle();
          }}
        >
          <DeleteIcon className="mr-4" /> Delete team
        </div>
      </div>
    );
  };
  const RightBar = (
    <>
      <div className="d-flex align-items-center">
        {canManage && (
          <div className="ps-3 cursor mb-2">
            <CustomPopover
              content={<Actions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <div onClick={handleButtonToggle} className="cursor">
                <MoreOutlined style={{ fontSize: '110%' }} />
              </div>
            </CustomPopover>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="headerBudgets">
      <TopBar
        headerText={
          <div className="d-flex align-items-center ">
            {teamData?.name}
            {!isActive && (
              <Badge className="text-uppercase balance-tag dark-bg text-white text-dark">
                inactive
              </Badge>
            )}
          </div>
        }
        largeTitle
        breadcrumbs={[
          { title: 'Teams', action: () => history.push('/teams') },
          {
            title: teamData?.name,
            action: () => {
              null;
            },
          },
        ]}
        isRightBar={RightBar}
      />

      <TeamsModal
        selectTeam={teamData}
        isOpen={isOpen}
        handleClose={toggleHandler}
        Update
        singleTeam
      />

      {deleteModal && (
        <DeleteDialog
          title="Delete Team"
          subTitle={
            <>
              Are you sure you want to delete this team? <br />
              This action cannot be undone.
            </>
          }
          onCancel={deleteToggleHandler}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};
export default SelectedTeamsHeader;
