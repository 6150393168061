import React, { useState } from 'react';
import AccountInfo from 'components/BusinessCard/Card/Bank/AccountInfo';
import MoreActions from './MoreActions';
import TryAgain from 'components/BusinessCard/Card/Bank/TryAgain';

const DollarTransfer = ({ closeHandler, setFundStep, handleBack }) => {
  const [tryAgain, setTryAgain] = useState(false);

  const handleBtnClick = () => {
    closeHandler();
  };

  const clickHandler = () => {
    setFundStep(4);
  };
  return (
    <>
      {tryAgain ? (
        <TryAgain goBackToSelectType={handleBack} />
      ) : (
        <>
          <AccountInfo
            title="Transfer funds to"
            subtTitle="Send USD to the account below and your USD balance would be credited afterwards"
            hideLink
            handleBtnClick={handleBtnClick}
            setTryAgain={setTryAgain}
            accountType="USD"
            handleBack={handleBack}
          />
          <div className="pt-4">
            <MoreActions
              handleClick={clickHandler}
              fundCardMessage="By converting naira to dollar"
            />
          </div>
        </>
      )}
    </>
  );
};
export default DollarTransfer;
