import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import DeleteDialog from 'components/DeleteDialog';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import Modal from 'components/UI/Modal';
import { toastError, toastSuccess } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { deleteCategories } from 'redux/actions/CategoryAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { getAvailableBalance, groupSourceOptions } from 'utils/helper';
import './styles.scss';

const batchValue = {
  id: '',
  name: '',
  recipientName: '',
  description: '',
  budget: '',
  amount: '',
  currency: '',
  accountNumber: '',
  bankCode: '',
  bankName: '',
  category: '',
  accountName: undefined,
  error: false,
};

const BatchModal = ({
  isOpen,
  handleClose,
  setIsOtherPopoverOpen,
  selectedbatch,
  setTableData,
  tableData,
}) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();

  const [batch, setBatch] = useState(batchValue);
  const [isAccountSet, setIsAccountSet] = useState(false);
  const [recipientName, setReceipientName] = useState();

  const [expenseCategories, setExpenseCategories] = useState({
    isLoading: false,
    options: [],
    value: '',
  });

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  let tableJson = tableData;

  const updateJsonData = (
    id,
    recipientName,
    accountNumber,
    bankCode,
    source,
    amount,
    description,
    error,
  ) => {
    for (let i = 0; i < tableJson.length; i++) {
      if (i === id) {
        tableJson[i]['Recipient'] = recipientName;
        tableJson[i]['Account Number'] = accountNumber;
        tableJson[i]['Bank Code'] = bankCode;
        tableJson[i]['Source'] = source;
        tableJson[i]['Amount'] = amount;
        tableJson[i]['Description'] = description;
        tableJson[i]['error'] = error;
        return tableJson;
      }
    }
  };

  useEffect(() => {
    if (selectedbatch) {
      const {
        batchData: { Amount, currency, Category, batchName, budget, Source },
        recipientName,
        description,
        accountNumber,
        bankCode,
        id,
        error,
      } = selectedbatch;

      const bankDetails = allBanks?.find((item) => item.value === bankCode);

      setBatch({
        id,
        name: batchName,
        recipientName,
        description,
        budget: Source ?? budget,
        amount: Amount,
        currency,
        accountNumber,
        bankCode,
        bankName: bankDetails,
        category: Category,
        error,
      });
    }
  }, [selectedbatch]);

  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { success, loading },
  } = useSelector(({ categories }) => categories);

  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    code: '',
    name: '',
  });

  const handleChange = ({ name, value }) => {
    setBatch({ ...batch, [name]: value });
  };

  const handleSubmit = () => {
    if (!batch.amount) return toastError('Please enter an amount');
    if (+batch.amount <= 0) return toastError('Please enter a valid amount');
    if (!batch.recipientName) return toastError('Please enter recipient name');
    if (!batch.bankName.value) return toastError('Please select a bank');
    if (!batch.accountNumber) return toastError('Please enter account number');
    if (batch.accountNumber.length < 10)
      return toastError('Please enter a valid account number');
    if (!batch.description) return toastError('Please enter batch name');
    const newTableData = updateJsonData(
      batch.id,
      batch.recipientName,
      String(batch.accountNumber),
      batch.bankName.value,
      batch.budget,
      batch.amount,
      batch.description,
      batch.error,
    );

    setTableData(newTableData);
    handleClose();
    toastSuccess('Record updated');
  };

  useEffect(() => {
    if (!banksData) dispatch(getAllBanks());
  }, []);

  useEffect(() => {
    if (batch?.bankName?.value) {
      if (batch.accountNumber.length === 10 && batch.bankName.value) {
        const { accountNumber, bankName } = batch;
        dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
      }
      if (batch.accountNumber.length < 10) {
        setIsAccountSet(false);
        setBatch({ ...batch, accountName: undefined });
      }
    }
  }, [batch?.accountNumber, batch?.bankName?.value]);

  useEffect(() => {
    if (accountNameSuccess && batch.bankName.value) {
      setBatch({
        ...batch,
        accountName: accName.account_name,
        recipientName: accName.account_name,
        error: false,
      });
      setIsAccountSet(true);
    }
    if (accountNameError && batch.bankName.value)
      setBatch({ ...batch, accountName: undefined });
    if (accountNameLoading && batch.bankName.value)
      setBatch({ ...batch, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  useEffect(() => {
    if (recipientName) {
      setBatch({
        ...batch,
        recipientName,
        accountName: undefined,
      });

      setReceipientName();
    }
  }, [recipientName]);

  const handleChangeAmount = (data) => {
    const {
      target: { name, rawValue },
    } = data;
    setBatch({ ...batch, [name]: rawValue });
  };

  const handleGetSign = (currency) => {
    setBatch({ ...batch, currency });
  };

  const onOpenDeleteModal = (name, code) => {
    setSelectedCategory({
      code,
      name,
    });
    setShowCategoryDeleteModal(true);
  };

  const onHandleDeleteCategory = () => {
    dispatch(deleteCategories(selectedCategory.code));
  };

  useEffect(() => {
    if (success) setShowCategoryDeleteModal(false);
  }, [success]);

  useEffect(() => {
    if (categoryData) {
      setExpenseCategories({ ...expenseCategories, options: categoryData?.categories });
    }
  }, [categoryData]);
  // dispatch({ type: RESET_BLOCK_PAYMENTS, blockType: 'verifyBankAccount' });
  const onHandleGetSelected = (code, name) => {
    setExpenseCategories({ ...expenseCategories, value: code });
    setBatch({ ...batch, category: name });
  };

  const visible = accountNameLoading || !!batch.accountName || accountNameError;

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balance');
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const batchForm = () => (
    <div className="beneficiaries">
      <h2 className="batch-title">Make a payment</h2>

      <Row className="mb-3 ">
        <CustomInput
          label="batch name"
          placeholder="Enter batch name"
          type="text"
          name="name"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={batch.name}
          disabled
        />
      </Row>

      <Row className="mb-3">
        <CustomSelectRadio
          label="Source of funds"
          name="budgets"
          placeholder="Select a source"
          onChange={(val) => handleChange({ name: 'budget', value: val })}
          value={batch.budget}
          isDisabled={loadingBalances}
          isLoading={loadingBalances}
          loadOptions={loadOptions}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          placeholder="Enter amount"
          label="Amount"
          type="text"
          otherCurrency
          name="amount"
          onChange={handleChangeAmount}
          value={batch.amount}
          isAmount
          setIsOtherPopoverOpen={setIsOtherPopoverOpen}
          editCurrency={false}
          getTypeVal={handleGetSign}
          existAction={batch.currency || null}
        />
      </Row>

      <Row className="mb-3 ">
        <CustomInput
          label="Recipient name"
          placeholder="Enter recipient name"
          type="text"
          name="recipientName"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={batch.recipientName}
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="Bank"
          name="bankName"
          placeholder="Select Bank"
          onChange={(val) => setBatch({ ...batch, bankName: val })}
          value={batch.bankName}
          options={allBanks}
          onMenuClose={onMenuCloseBanks}
          onInputChange={handleGetBankOnChange}
          isDisabled={loadingBanks && !bankValuedebounced}
          isLoading={loadingBanks && !bankValuedebounced}
        />
      </Row>

      <Row className="mb-3">
        <div className={classNames('relative', { ['mb-5']: visible })}>
          <CustomInput
            type="text"
            label="Account number"
            placeholder="Enter number"
            name="accountNumber"
            onChange={({ target: { name, value } }) => handleChange({ name, value })}
            value={batch.accountNumber}
            maxLength="10"
            pattern="[0-9]*"
          />
          <AccountName
            setValue={setReceipientName}
            visible={visible}
            accountNameError={accountNameError}
            accountNameLoading={accountNameLoading}
            accountName={batch?.accountName}
          />
        </div>
      </Row>

      <Row className="mb-3 ">
        <CustomTextarea
          label="Description"
          name="description"
          placeholder="Enter batch description"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={batch.description}
          maxLength="50"
          rowSize={3}
        />
      </Row>

      <CustomButton
        className="mt-3"
        onClick={handleSubmit}
        disabled={!isAccountSet}
        fullWidth={true}
      >
        {'Save'}
      </CustomButton>
      <CustomButton withoutBg className="mt-3" onClick={handleClose} fullWidth={true}>
        Cancel
      </CustomButton>
    </div>
  );

  return (
    <div>
      <Modal show={isOpen} onClose={handleClose}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{batchForm()}</div>
        </div>
      </Modal>
      {showCategoryDeleteModal && (
        <DeleteDialog
          isLoading={loading}
          title={`Delete ${selectedCategory?.name} Category`}
          subTitle="Are you sure you want to delete this category? This action cannot be undone and all transactions in this category would be categorized as unknown"
          onCancel={() => setShowCategoryDeleteModal(false)}
          onDelete={onHandleDeleteCategory}
          styles={{ width: 400 }}
        />
      )}
    </div>
  );
};
export default BatchModal;
