import classNames from 'classnames';
import CustomSelect from 'components/UI/CustomSelect';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { remapKeys, toCamelCase } from 'utils/helper';
import { read, utils } from 'xlsx';
import { toastError } from '../toast';

const columns = ['Bujeti column', 'Sample data', 'Uploaded columns'];

const UploadDynamicFile = ({
  file,
  bujetiColumns: columnSample,
  title,
  description,
  setIsUploaded,
  isUploaded,
  setTableData,
  handleCancel,
  tableData,
  duplicateColumns = [],
  setRemoveFile,
}) => {
  const [FormRows, setFormRows] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [sampleOption, setSampleOption] = useState([]);

  const menuPortalTarget = document.body;

  const readUploadFile = (files) => {
    if (files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet, {
          defval: null,
          raw: true,
        });

        if (json.length > 500) {
          setRemoveFile(true);
          return toastError('Data too large (500 items per upload)');
        }
        setFormRows(json);
        setIsUploaded(true);
      };
      reader.readAsArrayBuffer(files);
    }
  };

  useEffect(() => {
    if (file) readUploadFile(file);
    else if (!file && setIsUploaded) {
      setIsUploaded(false);
    }
  }, [file]);

  const bujetiColumns = columnSample.map((item) => item.column);

  const convertData = (arrayOfObjects) => {
    const result = [];

    const obj = arrayOfObjects[0] || {};
    Object.keys(obj).forEach((key) => {
      result.push([key, obj[key], key]);
    });

    return result;
  };

  useEffect(() => {
    const formRows = FormRows.length ? convertData(FormRows) : [];

    const formOptions = FormRows.length
      ? Object.keys(FormRows[0] || {}).map((item) => ({
          label: item,
          value: item,
        }))
      : [];

    setSampleData(formRows);

    setSampleOption(formOptions);
  }, [FormRows]);

  const changeColumn = (swapIndex, searchValue, newValue) => {
    const array = [...sampleData];
    const indexToUpdate = array.findIndex((subArray) => subArray?.[2] === searchValue);

    // If found, update the value and perform the swap
    if (indexToUpdate !== -1) {
      // Update the value
      array[indexToUpdate][0] = newValue;

      // Swap the item to the specified index position
      if (swapIndex !== indexToUpdate) {
        // Swap elements at indexToUpdate and swapIndex
        const temp = array[swapIndex];
        array[swapIndex] = array[indexToUpdate];
        array[indexToUpdate] = temp;
      }
    }

    setSampleData(array);
  };

  const columnMapping = columnSample?.reduce((acc, { column, accessor }) => {
    acc[column] = accessor;
    return acc;
  }, {});

  const keyMapping = sampleData
    ?.slice(0, bujetiColumns.length)
    ?.reduce((acc, [newKey, _, oldKey]) => {
      if (columnMapping[newKey]) {
        acc[oldKey] = toCamelCase(columnMapping[newKey]);
      }
      return acc;
    }, {});

  const newData = remapKeys(FormRows, keyMapping, duplicateColumns);

  const confirmData = () => {
    const validate = bujetiColumns?.some((col, index) => {
      const cell = sampleData?.[index];

      const value = col === cell?.[0] ? { label: cell?.[2], value: cell?.[2] } : '';

      return value === '';
    });

    if (validate) return toastError('Please map all columns');

    const sss = newData.map((item, index) => ({ ...item, id: index }));
    if (setTableData) setTableData(sss);
  };

  if (!isUploaded || (isUploaded && !!tableData.length)) return;
  return (
    <div>
      <div className="border mt-4 rounded-3">
        <div className="section-title border-0 px-4">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>

        <Table className="import-component_table w-100" responsive>
          <thead>
            <tr>
              {columns.map((option, indx) => {
                return (
                  <React.Fragment key={indx}>
                    <th
                      className={classNames('text-sm font-medium', {
                        ['first-column']: indx === 0,
                      })}
                    >
                      {option}
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>

          <tbody className="w-100">
            {bujetiColumns?.map((col, index) => {
              const cell = sampleData?.[index];

              const value =
                col === cell?.[0] ? { label: cell?.[2], value: cell?.[2] } : '';

              const label = col === cell?.[0] ? cell?.[1] : '';

              return (
                <tr key={index}>
                  <td
                    className={classNames('text-sm font-medium first-column')}
                    style={{ width: 280 }}
                  >
                    {col}
                  </td>

                  <td
                    className={classNames('text-sm font-medium')}
                    style={{ width: 280 }}
                  >
                    {columnSample[index]?.sample}
                  </td>
                  <td className="last-column">
                    <CustomSelect
                      label=""
                      options={sampleOption}
                      placeholder="Select column"
                      className="w-100"
                      onChange={({ value }) => changeColumn(index, value, col)}
                      value={value}
                      isDisabled={index >= sampleData.length}
                      hasError={!value}
                      customStyles={{ control: { minWidth: 180, maxWidth: 280 } }}
                      menuPortalTarget={menuPortalTarget}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="mt-4 border-top py-4 d-flex w-100">
        <div className="ms-auto d-flex gap-3">
          <button className="btn border sm" onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn black sm" onClick={confirmData}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadDynamicFile;
