import { PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { DownloadIcon } from 'assets/icons';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import InvoicePdf from './InvoicePdf';

const MemoizedInvoicePdf = React.memo(InvoicePdf);

const DownloadPdfComponent = ({
  invoiceSelected,
  companyData,
  companyLogo,
  pdfDownloadRef,
  handleDownloadPdf,
}) => {
  const documentData = useMemo(() => {
    return (
      <MemoizedInvoicePdf
        data={
          invoiceSelected && {
            ...invoiceSelected?.details,
            ...companyLogo,
            company: { ...companyData, logo: companyLogo?.logo },
          }
        }
      />
    );
  }, [invoiceSelected, companyData]);

  return (
    <>
      <div className="actionLink" style={{ display: 'none' }}>
        <PDFDownloadLink
          document={documentData}
          fileName={`Invoice from ${companyData?.name}_${
            invoiceSelected?.details?.code
          }_${dayjs(invoiceSelected?.details?.createdAt).format('DD-MMMM-YYYY')}.pdf`}
          style={{
            textDecoration: 'none',
            color: '#212529',
          }}
        >
          <span
            ref={pdfDownloadRef}
            onClick={(event) => {
              event?.stopPropagation();
              if (handleDownloadPdf) handleDownloadPdf();
            }}
          >
            <DownloadIcon
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Download PDF
          </span>
        </PDFDownloadLink>
      </div>
    </>
  );
};

export default DownloadPdfComponent;
