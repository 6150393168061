import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomDrawer from 'components/UI/CustomDrawer';
import CustomTextarea from 'components/UI/CustomTextarea';
import Modal from 'components/UI/Modal';
import useTextCounter from 'hooks/useTextCounter';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getScheduledTransactions,
  updateScheduledTransactions,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { MAXLENGTH } from '../../../utils';
import { toastError } from 'components/UI/toast';

const MAX_LENGTH = MAXLENGTH?.singlePaymentDescription;

const PauseScheduledTransactionModal = ({
  selectedTransaction,
  setSelectedTransaction,
  setToggle,
  filter,
}) => {
  const [reason, setReason] = useState(undefined);

  const dispatch = useDispatch();
  const handleClose = () => {
    setSelectedTransaction(null);
    setToggle(false);
    dispatch({
      type: RESET_BLOCK_TRANSACTIONS,
      blockType: 'getSingleScheduledTransaction',
    });
    dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'transactionReceipts' });
  };

  const {
    updateScheduledTransaction: { loading, success },
  } = useSelector(({ transaction }) => transaction);

  const { transactionData: selectedData = {} } = selectedTransaction ?? {};

  const handleReasonChange = (value) => {
    setReason(value);
  };

  const {
    text: reasonText,
    charCount: reasonCharCount,
    handleCharChange: reasonHandleCharChange,
  } = useTextCounter(reason, MAX_LENGTH, handleReasonChange);

  const pauseTransaction = () => {
    if (!reason || reason.trim() === '') return toastError('Please enter a reason');
    const payload = {
      code: selectedData?.code,
      status: 'pause',
      reason,
    };
    dispatch(updateScheduledTransactions(payload));
  };

  useEffect(() => {
    if (success) {
      setSelectedTransaction(null);
      setToggle(false);
      dispatch(getScheduledTransactions({ ...filter }));
    }
  }, [success]);

  return (
    <Modal show={selectedTransaction}>
      <CustomDrawer show>
        <div className="content d-flex flex-column">
          <>
            <div className="card-modal-header">
              <div className="d-flex align-items-center cursor" onClick={handleClose}>
                <CloseOutlined />
                <span className="ps-1">Close</span>
              </div>
            </div>
            <div className="card-modal-body mt-0">
              <div className="information-wrapper">
                <h2 className="card-title w-100 mb-2">Pause scheduled transaction</h2>
                <div className="w-100">
                  <div>
                    <Row>
                      <CustomTextarea
                        rowSize={5}
                        label="Enter your reason *"
                        name="reason"
                        onChange={reasonHandleCharChange}
                        value={reasonText || reason}
                        placeholder="Reason"
                        maxLength={MAX_LENGTH}
                        showCounter={true}
                        charCount={reasonCharCount}
                      />
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {selectedData?.status === 'active' && (
              <div className="card-modal-footer py-3">
                <div className="w-100 batch-footer d-flex gap-2 justify-content-end">
                  <CustomButton
                    disabled={loading}
                    className="border-btn button-dimension-fit-content"
                    onClick={() => {
                      setSelectedTransaction(null);
                      setToggle(false);
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    onClick={pauseTransaction}
                    className="customConfirmButton button-dimension-fit-content"
                    loading={loading}
                  >
                    Pause transaction
                  </CustomButton>
                </div>
              </div>
            )}
          </>
        </div>
      </CustomDrawer>
    </Modal>
  );
};

export default PauseScheduledTransactionModal;
