import React, { useEffect, useState } from 'react';
import { ReactComponent as AlertTrangle } from '../../assets/icons/alert-triangle.svg';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
const PendingOnboardingNotice = () => {
  const { push } = useHistory();
  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    setShowOnboarding(localStorage.getItem('onboardingAlert') === 'true');
  }, [companyData]);

  return (
    <>
      {showOnboarding && (
        <Container className="px-0 mt-4">
          <div className="budget__notice">
            <div className="alert-triangle-container">
              <AlertTrangle />
            </div>
            <div>
              <h4>Complete business details and KYC</h4>
              <p>
                You&apos;re currently in Test Mode. Fill out your business details to
                activate your Bujeti account and make transactions. Any progress you make
                will be saved, so you can always finish later.
              </p>
              <div
                className="add-button add-action bg-white"
                onClick={() => push('/get-started')}
              >
                Upload documents
              </div>
            </div>
            {/* <CloseOutlined className="ms-auto cursor" onClick={removeStatus} /> */}
          </div>
        </Container>
      )}
    </>
  );
};

export default PendingOnboardingNotice;
