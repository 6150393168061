//action types
export const GENERATE_ACCOUNT_STATEMENT_REQUEST = 'GENERATE_ACCOUNT_STATEMENT_REQUEST';
export const GENERATE_ACCOUNT_STATEMENT_ERROR = 'GENERATE_ACCOUNT_STATEMENT_ERROR';
export const GENERATE_ACCOUNT_STATEMENT_SUCCESS = 'GENERATE_ACCOUNT_STATEMENT_SUCCESS';
export const RESET_BLOCK_ACCOUNT_STATEMENT = 'RESET_BLOCK_ACCOUNT_STATEMENT';
export const DOWNLOAD_ACCOUNT_STATEMENT_REQUEST = 'DOWNLOAD_ACCOUNT_STATEMENT_REQUEST';
export const DOWNLOAD_ACCOUNT_STATEMENT_ERROR = 'DOWNLOAD_ACCOUNT_STATEMENT_ERROR';
export const DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS = 'DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  generateStatement: { ...block },
  downloadStatement: { ...block },
};

export const AccountStatementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_ACCOUNT_STATEMENT_REQUEST:
      return {
        ...state,
        generateStatement: { ...state.generateStatement, loading: true },
      };
    case GENERATE_ACCOUNT_STATEMENT_SUCCESS:
      return {
        ...state,
        generateStatement: {
          ...state.generateStatement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GENERATE_ACCOUNT_STATEMENT_ERROR:
      return {
        ...state,
        generateStatement: {
          ...state.generateStatement,
          loading: false,
          error: action.error,
        },
      };

    //
    case DOWNLOAD_ACCOUNT_STATEMENT_REQUEST:
      return {
        ...state,
        downloadStatement: { ...state.downloadStatement, loading: true },
      };
    case DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS:
      return {
        ...state,
        downloadStatement: {
          ...state.downloadStatement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DOWNLOAD_ACCOUNT_STATEMENT_ERROR:
      return {
        ...state,
        downloadStatement: {
          ...state.downloadStatement,
          loading: false,
          error: action.error,
        },
      };

    case RESET_BLOCK_ACCOUNT_STATEMENT:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
