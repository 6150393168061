import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';

import { getSimplifiedError } from 'utils/error';

import {
  AUTHORIZE_QUICKBOOKS_ERROR,
  AUTHORIZE_QUICKBOOKS_SUCCESS,
  AUTHORIZE_QUICKBOOKS_REQUEST,
  FETCH_ALL_INTEGRATION_APPS_REQUEST,
  FETCH_ALL_INTEGRATION_APPS_SUCCESS,
  FETCH_ALL_INTEGRATION_APPS_ERROR,
  FETCH_QUICKBOOKS_CATEGORY_ERROR,
  FETCH_QUICKBOOKS_CATEGORY_SUCCESS,
  FETCH_QUICKBOOKS_CATEGORY_REQUEST,
  DISCONNECT_QUICKBOOKS_SUCCESS,
  DISCONNECT_QUICKBOOKS_ERROR,
  DISCONNECT_QUICKBOOKS_REQUEST,
  CREATE_CATEGORY_MAPPER_REQUEST,
  CREATE_CATEGORY_MAPPER_SUCCESS,
  CREATE_CATEGORY_MAPPER_ERROR,
  FETCH_MAPPED_CATEGORY_REQUEST,
  FETCH_MAPPED_CATEGORY_SUCCESS,
  FETCH_MAPPED_CATEGORY_ERROR,
  FETCH_INTEGRATION_REQUEST,
  FETCH_INTEGRATION_SUCCESS,
  FETCH_INTEGRATION_ERROR,
  RESET_BLOCK_QUICKBOOKS,
  SET_ZOHO_BOOKS_ORGANIZATION_REQUEST,
  SET_ZOHO_BOOKS_ORGANIZATION_SUCCESS,
  SET_ZOHO_BOOKS_ORGANIZATION_ERROR,
  SYNC_ACCOUNTING_ENTITY_REQUEST,
  SYNC_ACCOUNTING_ENTITY_SUCCESS,
  SYNC_ACCOUNTING_ENTITY_ERROR,
  FETCH_ZOHO_PEOPLE_REQUEST,
  FETCH_ZOHO_PEOPLE_SUCCESS,
  FETCH_ZOHO_PEOPLE_ERROR,
} from '../reducers/IntegrationsReducers';
import { toastError, toastSuccess } from 'components/UI/toast';

async function authorizeIntegrations({ payload }) {
  const type = payload.type;
  delete payload.type;
  return await Axios.post(`/integrations/${type.toLowerCase()}/authorize`, payload);
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleAuthorizeIntegrations({ payload }) {
  try {
    const response = yield call(authorizeIntegrations, { payload });
    if (response) {
      yield put({
        type: AUTHORIZE_QUICKBOOKS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: AUTHORIZE_QUICKBOOKS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function integrationApps() {
  return await Axios.get('/integrations');
}

function* handleFetchIntegrationApps() {
  try {
    const response = yield call(integrationApps);
    if (response) {
      yield put({
        type: FETCH_ALL_INTEGRATION_APPS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_ALL_INTEGRATION_APPS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function integrationCategories() {
  return await Axios.get('/integrations/quickbooks/categories');
}

function* handleFetchIntegrationCategories() {
  try {
    const response = yield call(integrationCategories);
    if (response) {
      yield put({
        type: FETCH_QUICKBOOKS_CATEGORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_QUICKBOOKS_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

//TODO: allow this function to accept an app type
async function disconnectIntegration() {
  return await Axios.delete('/integrations/quickbooks/disconnect');
}

function* handleDisconnectIntegration() {
  try {
    const response = yield call(disconnectIntegration);
    if (response) {
      yield put({
        type: DISCONNECT_QUICKBOOKS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);

      yield delay(100);
      yield put({
        type: RESET_BLOCK_QUICKBOOKS,
        blockType: 'authorizingQuickbooks',
      });
      yield put({
        type: RESET_BLOCK_QUICKBOOKS,
        blockType: 'integrationApps',
      });
      yield put({
        type: RESET_BLOCK_QUICKBOOKS,
        blockType: 'disconnectApps',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DISCONNECT_QUICKBOOKS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createCategoryMapper({ payload }) {
  return await Axios.post(`/integrations/quickbooks/category-mapping`, payload);
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleCategoryMapper({ payload }) {
  try {
    const response = yield call(createCategoryMapper, { payload });
    if (response) {
      yield put({
        type: CREATE_CATEGORY_MAPPER_SUCCESS,
        data: response.data,
      });
      toastSuccess('All done, your data is being synced...');
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_CATEGORY_MAPPER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

//change the endpoint url
async function mappedCategory() {
  return await Axios.get(`/integrations/quickbooks/category-mapping`);
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleMappedCategory() {
  try {
    const response = yield call(mappedCategory);
    if (response) {
      yield put({
        type: FETCH_MAPPED_CATEGORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_MAPPED_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

//change the endpoint url
async function fetchIntegration({ code }) {
  return await Axios.get(`/integration/platforms/${code}`);
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleIntegrationFetch({ payload }) {
  try {
    const response = yield call(fetchIntegration, { code: payload.code });
    if (response) {
      yield put({
        type: FETCH_INTEGRATION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_INTEGRATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

//change the endpoint url
async function setZohoBooksOrganization({ code, organization }) {
  return await Axios.post(
    `/integration/integrations/${code}/organization/${organization}`,
  );
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleZohoBooksOrganizationSetting({ payload }) {
  try {
    const response = yield call(setZohoBooksOrganization, payload);
    if (response) {
      yield put({
        type: SET_ZOHO_BOOKS_ORGANIZATION_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SET_ZOHO_BOOKS_ORGANIZATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

//change the endpoint url
async function syncAccountingEntity({ codes, element, platform }) {
  return await Axios.post(`/${element}/${platform}/sync`, { codes });
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleSyncAccountingEntity({ payload }) {
  try {
    const response = yield call(syncAccountingEntity, payload);
    if (response) {
      yield put({
        type: SYNC_ACCOUNTING_ENTITY_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error && error?.response?.status !== 404) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SYNC_ACCOUNTING_ENTITY_ERROR,
      error: getSimplifiedError(error),
      data: {
        openIntegrationPage: error?.response?.status === 404,
        platform: 'zoho',
      },
    });
  }
}

//change the endpoint url
async function fetchZohoPeople() {
  return await Axios.get(`/integration/zoho/people`);
}

//TODO: make the yield for the saga dynamic based on the app integration selected
function* handleFetchZohoPeople() {
  try {
    const response = yield call(fetchZohoPeople);
    if (response) {
      yield put({
        type: FETCH_ZOHO_PEOPLE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error && error?.response?.status !== 404) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_ZOHO_PEOPLE_ERROR,
      error: getSimplifiedError(error),
      data: {
        openIntegrationPage: error?.response?.status === 404,
        platform: 'zoho',
      },
    });
  }
}

export default all([
  takeLatest(AUTHORIZE_QUICKBOOKS_REQUEST, handleAuthorizeIntegrations),
  takeLatest(FETCH_ALL_INTEGRATION_APPS_REQUEST, handleFetchIntegrationApps),
  takeLatest(FETCH_QUICKBOOKS_CATEGORY_REQUEST, handleFetchIntegrationCategories),
  takeLatest(DISCONNECT_QUICKBOOKS_REQUEST, handleDisconnectIntegration),
  takeLatest(CREATE_CATEGORY_MAPPER_REQUEST, handleCategoryMapper),
  takeLatest(FETCH_MAPPED_CATEGORY_REQUEST, handleMappedCategory),
  takeLatest(FETCH_INTEGRATION_REQUEST, handleIntegrationFetch),
  takeLatest(SET_ZOHO_BOOKS_ORGANIZATION_REQUEST, handleZohoBooksOrganizationSetting),
  takeLatest(SYNC_ACCOUNTING_ENTITY_REQUEST, handleSyncAccountingEntity),
  takeLatest(FETCH_ZOHO_PEOPLE_REQUEST, handleFetchZohoPeople),
]);
