import { Container } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import { ReactComponent as AlertTrangle } from '../../../assets/icons/alert-triangle.svg';
import './index.scss';
const LowBalanceUi = ({ isBalanceLow, route, currency, amount, type = 0 }) => {
  const header = type === 0 ? 'Insuffcient balance' : 'Unfunded budget';
  const message =
    type === 0 ? (
      <p>
        You have{' '}
        {
          <CurrencyFormat
            prefix={getCurrency(currency)}
            value={numFormatter(amount)}
            displayType="text"
            thousandSeparator={true}
            isNumericString
          />
        }{' '}
        available in your balance. Please fund balance to make payment.
      </p>
    ) : (
      <p>
        Your budget does not hold fund, you can select an account or subaccount to make
        payment from below
      </p>
    );
  return (
    <>
      {isBalanceLow && (
        <Container className="px-0 mt-4">
          <div className="budget__balance-notice">
            <div className="alert-triangle-container d-flex align-items-center justify-content-center border rounded-1 p-1 border-orange">
              <AlertTrangle style={{ height: '15px', width: '25px' }} />
            </div>
            <div>
              <h4>{header}</h4>
              {message}
              {type === 0 && (
                <div className="action-button" onClick={route}>
                  Fund balance
                </div>
              )}
            </div>
            {/* <CloseOutlined className="ms-auto cursor" onClick={removeStatus} /> */}
          </div>
        </Container>
      )}
    </>
  );
};

export default LowBalanceUi;
