import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './styles.scss';
import { ReactComponent as Logo } from 'assets/logos/smallLogo.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PayImg from 'assets/images/create-vitrine.png';
import Stripe from 'assets/images/stripe.png';

const FundPay = () => {
  return (
    <>
      <Container className="pay-wrapper pt-5">
        <Row className="pt-4">
          <Col md={6}>
            <div className="header-wrapper">
              <div className="ms-4 ps-2 mb-3">
                <Logo />
              </div>
              <div className="d-flex align-items-center">
                <ArrowLeftOutlined />
                <span className="ps-2">Back to Bujeti</span>
              </div>
            </div>
            <div className="amount-wrapper ps-4 pt-5">
              <span className="text-muted">Fund</span>
              <h1>$ 20,000.00</h1>
              <img src={PayImg} className="w-75" alt="patImg" />
              <p className="pt-5">
                <img src={Stripe} alt="Stripe" />
              </p>
            </div>
          </Col>
          <Col md={6}>dashdash</Col>
        </Row>
      </Container>
    </>
  );
};
export default FundPay;
