import { Col, Row } from 'react-bootstrap';
import AnalyticsCard from 'components/UI/AnalyticsCard';
import FormattedCurrency from 'components/UI/FormattedCurrency';

import { getCurrency } from 'utils/helper';

const CustomerAnalytics = ({ data, loading, activeTag, handleTagClick }) => {
  const analyticsData = [
    { caption: 'Total customers', value: data?.totalCustomer || 0 },
    { caption: 'Debtors', key: 'debtors', value: data?.debtors || 0, filterable: true },
    {
      caption: 'Outstanding debt',
      key: 'totalDebt',
      filterable: true,
      value: data?.totalDebt?.['NGN'] || 0,
    },
  ];

  const renderAnalyticsCard = ({ caption, key, filterable, value }) => (
    <Col
      key={key}
      sm={4}
      className="mb-md-0 mb-2"
      onClick={() => (filterable ? handleTagClick(key) : null)}
    >
      <AnalyticsCard
        hasExtra={!!filterable}
        caption={caption}
        loading={loading}
        isActive={filterable && key === activeTag}
        value={
          key === 'totalDebt' ? (
            <FormattedCurrency value={value} prefix={getCurrency('NGN')} />
          ) : (
            <span> {value}</span>
          )
        }
      />
    </Col>
  );

  return (
    <section className="analytics-region mb-md-3 mb-0">
      <Row>{analyticsData.map(renderAnalyticsCard)}</Row>
    </section>
  );
};

export default CustomerAnalytics;
