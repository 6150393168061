import {
  AUTHORIZE_QUICKBOOKS_REQUEST,
  CREATE_CATEGORY_MAPPER_REQUEST,
  DISCONNECT_QUICKBOOKS_REQUEST,
  FETCH_ALL_INTEGRATION_APPS_REQUEST,
  FETCH_MAPPED_CATEGORY_REQUEST,
  FETCH_QUICKBOOKS_CATEGORY_REQUEST,
  FETCH_INTEGRATION_REQUEST,
  SET_ZOHO_BOOKS_ORGANIZATION_REQUEST,
  SYNC_ACCOUNTING_ENTITY_REQUEST,
  FETCH_ZOHO_PEOPLE_REQUEST,
} from '../reducers/IntegrationsReducers';

export const actionTypeMapper = {
  QuickBooks: AUTHORIZE_QUICKBOOKS_REQUEST,
};

/**
 * @param {Object} payload: this is an object that contains the public token and type {publicToken: 189231_129301eiadfas_ladiasdf, type: redux action to call}
 * @returns
 */
export const authorize = (payload) => ({
  type: AUTHORIZE_QUICKBOOKS_REQUEST,
  payload,
});

export const fetchApps = () => ({
  type: FETCH_ALL_INTEGRATION_APPS_REQUEST,
});

export const fetchQuickbooksCategory = () => ({
  type: FETCH_QUICKBOOKS_CATEGORY_REQUEST,
});

export const disconnectQuickbooks = () => ({
  type: DISCONNECT_QUICKBOOKS_REQUEST,
});

export const createCategoryMapper = (payload) => ({
  type: CREATE_CATEGORY_MAPPER_REQUEST,
  payload,
});

export const fetchMappedCategory = () => ({
  type: FETCH_MAPPED_CATEGORY_REQUEST,
});

export const fetchIntegration = (payload) => ({
  type: FETCH_INTEGRATION_REQUEST,
  payload,
});

export const setDefaultZohoOrganization = (payload) => ({
  type: SET_ZOHO_BOOKS_ORGANIZATION_REQUEST,
  payload,
});

export const syncAccountingEntity = (payload) => ({
  type: SYNC_ACCOUNTING_ENTITY_REQUEST,
  payload,
});

export const fetchZohoPeople = () => ({
  type: FETCH_ZOHO_PEOPLE_REQUEST,
});
