export const CREATE_TEAMS_REQUEST = 'CREATE_TEAMS_REQUEST';
export const CREATE_TEAMS_SUCCESS = 'CREATE_TEAMS_SUCCESS';
export const CREATE_TEAMS_ERROR = 'CREATE_TEAMS_ERROR';

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_ERROR = 'GET_TEAMS_ERROR';

export const GET_TEAM_MEMBERS_REQUEST = 'GET_TEAM_MEMBERS_REQUEST';
export const GET_TEAM_MEMBERS_SUCCESS = 'GET_TEAM_MEMBERS_SUCCESS';
export const GET_TEAM_MEMBERS_ERROR = 'GET_TEAM_MEMBERS_ERROR';

export const TEAM_OVERVIEW_REQUEST = 'TEAM_OVERVIEW_REQUEST';
export const TEAM_OVERVIEW_SUCCESS = 'TEAM_OVERVIEW_SUCCESS';
export const TEAM_OVERVIEW_ERROR = 'TEAM_OVERVIEW_ERROR';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_ERROR = 'DELETE_TEAM_ERROR';

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_ERROR = 'UPDATE_TEAM_ERROR';

export const ADD_TEAM_MEMBER_REQUEST = 'ADD_TEAM_MEMBER_REQUEST';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_ERROR = 'ADD_TEAM_MEMBER_ERROR';

export const TEAM_MEMBER_STATUS_REQUEST = 'TEAM_MEMBER_STATUS_REQUEST';
export const TEAM_MEMBER_STATUS_SUCCESS = 'TEAM_MEMBER_STATUS_SUCCESS';
export const TEAM_MEMBER_STATUS_ERROR = 'TEAM_MEMBER_STATUS_ERROR';

export const RESET_BLOCK_TEAMS = 'RESET_BLOCK_PAYMENT';

export const RESET_FLAGS_TEAMS = 'RESET_FLAGS_PAYMENT';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  createTeam: { ...block },
  getTeams: { ...block },
  teamOverview: { ...block },
  deleteTeam: { ...block },
  updateTeam: { ...block },
  addTeamMember: { ...block },
  teamMemberStatus: { ...block },
  getTeamMembers: { ...block },
};

export const TeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TEAMS_REQUEST:
      return { ...state, createTeam: { ...state.createTeam, loading: true } };
    case CREATE_TEAMS_SUCCESS:
      return {
        ...state,
        createTeam: {
          ...state.createTeam,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_TEAMS_ERROR:
      return {
        ...state,
        createTeam: { ...state.createTeam, loading: false, error: action.error },
      };

    case ADD_TEAM_MEMBER_REQUEST:
      return { ...state, addTeamMember: { ...state.addTeamMember, loading: true } };
    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        addTeamMember: {
          ...state.addTeamMember,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_TEAM_MEMBER_ERROR:
      return {
        ...state,
        addTeamMember: { ...state.addTeamMember, loading: false, error: action.error },
      };

    case TEAM_MEMBER_STATUS_REQUEST:
      return { ...state, teamMemberStatus: { ...state.teamMemberStatus, loading: true } };
    case TEAM_MEMBER_STATUS_SUCCESS:
      return {
        ...state,
        teamMemberStatus: {
          ...state.teamMemberStatus,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case TEAM_MEMBER_STATUS_ERROR:
      return {
        ...state,
        teamMemberStatus: {
          ...state.teamMemberStatus,
          loading: false,
          error: action.error,
        },
      };

    case GET_TEAMS_REQUEST:
      return { ...state, getTeams: { ...state.getTeams, loading: true } };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        getTeams: {
          ...state.getTeams,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_TEAMS_ERROR:
      return {
        ...state,
        getTeams: { ...state.getTeams, loading: false, error: action.error },
      };

    case GET_TEAM_MEMBERS_REQUEST:
      return { ...state, getTeamMembers: { ...state.getTeamMembers, loading: true } };
    case GET_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        getTeamMembers: {
          ...state.getTeamMembers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_TEAM_MEMBERS_ERROR:
      return {
        ...state,
        getTeamMembers: { ...state.getTeamMembers, loading: false, error: action.error },
      };

    case TEAM_OVERVIEW_REQUEST:
      return { ...state, teamOverview: { ...state.teamOverview, loading: true } };
    case TEAM_OVERVIEW_SUCCESS:
      return {
        ...state,
        teamOverview: {
          ...state.teamOverview,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case TEAM_OVERVIEW_ERROR:
      return {
        ...state,
        teamOverview: { ...state.teamOverview, loading: false, error: action.error },
      };

    case DELETE_TEAM_REQUEST:
      return { ...state, deleteTeam: { ...state.deleteTeam, loading: true } };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        deleteTeam: {
          ...state.deleteTeam,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_TEAM_ERROR:
      return {
        ...state,
        deleteTeam: { ...state.deleteTeam, loading: false, error: action.error },
      };

    case UPDATE_TEAM_REQUEST:
      return { ...state, updateTeam: { ...state.updateTeam, loading: true } };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        updateTeam: {
          ...state.updateTeam,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_TEAM_ERROR:
      return {
        ...state,
        updateTeam: { ...state.updateTeam, loading: false, error: action.error },
      };

    //reset block with flag and data
    case RESET_BLOCK_TEAMS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_TEAMS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
