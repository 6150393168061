import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useRef } from 'react';

const CustomPopover = ({
  id = 'outer-popover',
  children,
  showPopover,
  content,
  clickOutside,
  placement = 'right-start',
}) => {
  const overlayRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (showPopover && clickOutside) {
      clickOutside();
    }
  };
  const outerPopover = (
    <Popover id={id}>
      <Popover.Body className="p-0">{content}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={'click'}
      overlay={outerPopover}
      placement={placement}
      show={showPopover}
      onToggle={handleOutsideClick}
      rootClose={true}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomPopover;
