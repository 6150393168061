import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllInvoices } from 'redux/actions/InvoiceAction';
import { buildCustomerInvoiceTableData } from 'utils/helper';
import { customerInvoiceColumns } from 'utils/mockData';
import { Col, Container, Row } from 'react-bootstrap';

import NoData from './NoData';
import Table from 'components/Table';
import TableLoading from './TableLoading';

const InvoicesTable = ({ id, userName }) => {
  const dispatch = useDispatch();

  const {
    fetchInvoices: { loading, data: { meta = {} } = {}, data = {} },
  } = useSelector(({ invoices }) => invoices);

  const { page, total, hasMore, perPage, nextPage } = meta;

  const [filteredQuery, setFilteredQuery] = useState({});

  useEffect(() => {
    dispatch(fetchAllInvoices({ customer: [id] }));
  }, [id]);

  const rows = buildCustomerInvoiceTableData(data?.invoices || []);

  const onHandleNextPage = (page) => {
    dispatch(fetchAllInvoices({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const onHandlePreviousPage = (page) => {
    dispatch(fetchAllInvoices({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  return (
    <>
      {!loading && !data?.invoices?.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No Invoice"
            bodyText={`${userName} does not have any invoices yet`}
            withButton={false}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={customerInvoiceColumns}
                  pagination
                  data={rows}
                  hasCheckBox={false}
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => onHandleNextPage(nextPage)}
                  previousPage={() => onHandlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default InvoicesTable;
