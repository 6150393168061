import {
  CREATE_CARD_REQUEST,
  GET_CARD_REQUEST,
  GET_SINGLE_CARD_REQUEST,
  GET_MY_CARDS_REQUEST,
  DEACTIVATE_CARD_REQUEST,
  FREEZE_UNFREEZE_CARD_REQUEST,
  GET_SELECTED_CARD_REQUEST,
  LOAD_UNLOAD_CARD_REQUEST,
  TOPUP_CARD_REQUEST,
  SET_NEW_PIN_REQUEST,
  ACTIVATE_PHYSICAL_CARD_REQUEST,
  PHYSICAL_CARD_REQUEST,
  GET_REQUESTED_CARDS_REQUEST,
  GET_DEFAULT_PIN_REQUEST,
  GET_MCCS_REQUEST,
  UPDATE_CARD_SETTINGS_REQUEST,
  DELETE_CARD_REQUEST,
  DELETE_CARD_REQUEST_REQUEST,
  REASSIGN_CARD_REQUEST,
} from '../reducers/CardReducer';

export const getCard = (query = {}) => ({ type: GET_CARD_REQUEST, query });
export const getMyCardsAction = (query = {}) => ({ type: GET_MY_CARDS_REQUEST, query });
export const getSelectedCard = (payload) => ({
  type: GET_SELECTED_CARD_REQUEST,
  payload,
});
export const createCard = (payload) => ({ type: CREATE_CARD_REQUEST, payload });
export const updateCardSettings = (payload) => ({
  type: UPDATE_CARD_SETTINGS_REQUEST,
  payload,
});
export const deactivateCard = (payload) => ({ type: DEACTIVATE_CARD_REQUEST, payload });
export const deleteCard = (payload) => ({ type: DELETE_CARD_REQUEST, payload });
export const deleteCardRequest = (payload) => ({
  type: DELETE_CARD_REQUEST_REQUEST,
  payload,
});
export const getSingleCard = (payload) => ({
  type: GET_SINGLE_CARD_REQUEST,
  payload,
});

export const freezeUnfreezeCard = (payload) => ({
  type: FREEZE_UNFREEZE_CARD_REQUEST,
  payload,
});

export const loadUnloadCard = (payload) => ({
  type: LOAD_UNLOAD_CARD_REQUEST,
  payload,
});

export const topUpCard = (payload) => ({
  type: TOPUP_CARD_REQUEST,
  payload,
});

export const setNewPinAction = (payload) => ({
  type: SET_NEW_PIN_REQUEST,
  payload,
});

export const activatePhysicalCard = (payload) => ({
  type: ACTIVATE_PHYSICAL_CARD_REQUEST,
  payload,
});

export const physicalCardRequest = (payload) => ({
  type: PHYSICAL_CARD_REQUEST,
  payload,
});

export const getRequestedCards = (payload) => ({
  type: GET_REQUESTED_CARDS_REQUEST,
  payload,
});

export const getDefaultPin = (payload) => ({
  type: GET_DEFAULT_PIN_REQUEST,
  payload,
});

export const getMccs = (payload) => ({
  type: GET_MCCS_REQUEST,
  payload,
});

export const reassignCard = (payload) => ({
  type: REASSIGN_CARD_REQUEST,
  payload,
});
