import React from 'react';
import './styles.scss';
import cs from 'classnames';
import { weekdays } from 'utils/helper';

const CustomDays = ({ days, setDays, className }) => {
  const handleSetDay = (event) => {
    let selectedDays = [...days, event];
    if (days.includes(event)) {
      selectedDays = selectedDays.filter((day) => day !== event);
    }
    setDays(selectedDays);
  };
  return (
    <div className={cs('custom-days', { [className]: className })}>
      {weekdays.map((item, index) => (
        <div
          className={cs('days', { ['active-day']: days.includes(item.value) })}
          onClick={() => handleSetDay(item.value)}
          key={index}
        >
          <span className="m-auto">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomDays;
