import Table from 'components/Table';
import 'jspdf-autotable';
import { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRequests } from 'redux/actions/RequestsAction';
import { columnsRequests } from 'utils/mockData';
import { buildRequestTableData } from '../../../utils/helper';
import NoData from './NoData';
import TableLoading from './TableLoading';

const RequestsTable = ({ id, type, userName }) => {
  const dispatch = useDispatch();

  const {
    getRequest: { data: { meta = {}, fundRequests: requests = [] } = {}, loading },
  } = useSelector(({ requests }) => requests);
  const { page, total, hasMore, perPage, nextPage } = meta;

  const rows = useMemo(() => buildRequestTableData(requests), [requests]);

  const handlePagination = (page) => {
    dispatch(getRequests({ [type]: id, perPage, page }));
  };

  const tableColumn = useMemo(
    () => columnsRequests.filter((item) => item.accessor !== 'by'),
    [requests],
  );

  useEffect(() => {
    dispatch(getRequests({ [type]: id }));
  }, []);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  return (
    <>
      {!loading && !requests.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No requests"
            bodyText={`${userName} does not have any requests yet`}
            withButton={true}
            buttonLabel={
              <span className="d-flex justify-content-center">Show all Invoices</span>
            }
            // onClickHandler={clearLocationState}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4 spaced-table">
              <Col xs={12}>
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handlePagination(nextPage)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default RequestsTable;
