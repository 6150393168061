import React from 'react';
import { ReactComponent as ChevronRight } from 'assets/icons/budget-analytics/chevron-right.svg';
import './style.scss';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

const TaskAction = ({ borderBottom, title, icon: Icon, link, total }) => {
  const { push } = useHistory();

  const handleClick = () => {
    push(link);
  };

  return (
    <div
      className={classNames('card-list__item', {
        ['border-bottom']: borderBottom,
      })}
      onClick={link ? handleClick : null}
    >
      <div className="card-list__content">
        <Icon />
        <span className="card-list__text">
          {title}
          {total && (
            <>
              <br />
              <div className="card-list__subtext">For a total amount of {total}</div>
            </>
          )}
        </span>
      </div>
      <ChevronRight />
    </div>
  );
};

export default TaskAction;
