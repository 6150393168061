import { Progress } from 'antd';
import CurrencyFormat from 'react-currency-format';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const ProgressBar = ({ value }) => {
  const Formatter = ({ currency, value }) => {
    return (
      <CurrencyFormat
        prefix={getCurrency(currency)}
        value={numFormatter(value / 100)}
        displayType="text"
        thousandSeparator={true}
        isNumericString
      />
    );
  };

  return (
    <>
      <Progress
        percent={(value.spent / value.amount) * 100}
        showInfo={false}
        strokeColor="#D28B28"
        trailColor="#E7E5E4"
      />

      <span className="d-block text-end text-xs" style={{ color: '#44403C' }}>
        <Formatter currency={value.currency} value={value.spent} />/{' '}
        <Formatter currency={value.currency} value={value.amount} />
      </span>
    </>
  );
};

export default ProgressBar;
