import { Table } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { getCurrency, getSignedVariation } from 'utils/helper';
import ImgCard from 'components/UI/ImgCard';

const nameStyle = {
  fontSize: 15,
  color: '#1C1917',
  fontWeight: 500,
  lineHeight: '30px',
};

const headerStyle = {
  color: '#79716B',
  fontSize: 12,
  fontWeight: 500,
};

const amountStyle = {
  fontSize: 15,
  color: '#1C1917',
};

const ReportTable = ({ data, totalReport }) => {
  if (!data.length) return null;
  const tableRows = data.map((element, index) => {
    return (
      <tr key={index} style={{ cursor: 'pointer' }}>
        <td colSpan={4} style={nameStyle}>
          <div className="d-flex gap-2 justify-content align-center">
            <ImgCard
              size="medium"
              customGap="c-gap-10"
              fullWidth={false}
              initials={<>{element?.name?.charAt(0)}</>}
            />

            {element.name}
            {element.transactionsCount ? ` (${element.transactionsCount})` : null}
          </div>
        </td>
        <td></td>
        <td colSpan={2} style={amountStyle}>
          <CurrencyFormat
            prefix={getCurrency(element.currency || 'NGN')}
            value={element.amount / 100}
            displayType="text"
            thousandSeparator={true}
          />
          <span style={{ marginLeft: 10, fontSize: 12, color: '#696C80' }}>
            {getSignedVariation(element.previousPeriod, element.amount)}% from{' '}
            <CurrencyFormat
              prefix={getCurrency(element.currency || 'NGN')}
              value={element.previousPeriod / 100}
              displayType="text"
              thousandSeparator={true}
            />
          </span>
        </td>
      </tr>
    );
  });

  return (
    <section className="report-table">
      <Table>
        <thead>
          <tr>
            <th colSpan={4} style={headerStyle}>
              Name (Transactions)
            </th>
            <th colSpan={1} style={headerStyle}></th>
            <th colSpan={2} style={headerStyle}>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
    </section>
  );
};

export default ReportTable;
