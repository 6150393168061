import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCategories } from 'redux/actions/CategoryAction';

import Logo from 'assets/logos/smallLogo.svg';
import CustomSelect from 'components/UI/CustomSelect';
import { IntegrationsContext } from 'pages/Settings';
import {
  createCategoryMapper,
  fetchMappedCategory,
  fetchQuickbooksCategory,
  fetchIntegration,
  setDefaultZohoOrganization,
} from '../../../redux/actions/IntegrationsActions';

import Loading from 'components/UI/Loading';
import { RESET_FLAGS_CATEGORY_MAPPER } from '../../../redux/reducers/IntegrationsReducers';
import { toTitle } from 'utils/helper';

const Connector = () => {
  const dispatch = useDispatch();

  const { selectedIntegration, openDeleteModal, onHandleGoBack } =
    useContext(IntegrationsContext);
  const [bujetiCategories, setBujetiCategories] = useState([]);
  const [zohoOrganizations, setZohoOrganizations] = useState([]);
  const [zohoOrganization, setZohoOrganization] = useState(null);
  const [qkbCategories, setQkbCategories] = useState([]);
  const [mappedQkbCategories, setMappedQkbCategories] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [mappedCategoryData, setMappedCategoryData] = useState([]);
  const [initialMappedData, setInitialMappedData] = useState([]);

  const {
    fetchCategories: { loading, data, error },
  } = useSelector(({ categories }) => categories);

  const {
    quickbooksCategory: {
      loading: categoryLoading,
      data: category,
      error: isCategoryError,
    },
    createCategoryMapper: { loading: isCreating, success: isCreated },
    mappedCategory: { loading: isFetchingMapped, data: mappedData },
    fetchIntegration: { loading: fetchingIntegration, data: integrationData },
    // disconnectApps: { loading: isDisconnecting, success: isDisconnected },
  } = useSelector(({ integrations }) => integrations);

  const connected = selectedIntegration?.selected?.connected;

  useEffect(() => {
    switch (name) {
      case 'QUICKBOOKS':
        if (data?.categories?.length) return categoryMapper(data?.categories);
        dispatch(fetchCategories());
        break;
      case 'ZOHO':
        if (integrationData?.organizations?.length)
          setZohoOrganizations(integrationData.organizations);
        break;
      default:
        return;
    }
  }, [data]);

  useEffect(() => {
    if (name !== 'QUICKBOOKS') return;
    if (!categoryLoading && category) return qbCategoryMapper(category);
    if (!category && isCategoryError) return;
    dispatch(fetchQuickbooksCategory());
  }, [categoryLoading, category, isCategoryError]);

  useEffect(() => {
    if (name !== 'QUICKBOOKS') return;
    if (connected && !isFetchingMapped && mappedData) return;
    if (connected && !isFetchingMapped && !mappedData?.length)
      dispatch(fetchMappedCategory());
  }, [connected, mappedData, isFetchingMapped]);

  useEffect(() => {
    if (name !== 'ZOHO') return;
    if (!(integrationData && integrationData.metadata))
      dispatch(fetchIntegration({ code: selectedIntegration?.selected?.name }));
    if (integrationData?.metadata?.organizations) {
      setZohoOrganizations(
        integrationData?.metadata?.organizations.map(({ organization_id, name }) => ({
          value: organization_id,
          label: name,
          code: organization_id,
        })),
      );
      setZohoOrganization({
        code: integrationData?.metadata.organizationId,
        value: integrationData?.metadata.organizationId,
        label: integrationData?.metadata.organizationName,
      });
    }
  }, [fetchingIntegration, integrationData]);
  //TODO: make this function dynamic
  const categoryMapper = (categories) => {
    let newCategories = categories?.map((category) => {
      return {
        value: category.slug,
        label: category.name,
        code: category.code,
      };
    });

    setBujetiCategories(newCategories);
  };

  const qbCategoryMapper = (categories) => {
    let newCategories = categories?.map((category) => {
      return {
        value: category.name,
        label: category.name,
        code: category.id,
      };
    });
    setQkbCategories(newCategories);
  };

  const handleQkbSelect = (value, index) => {
    // if (value === null) {
    //   delete mappedQkbCategories[index];
    //   setMappedQkbCategories({
    //     ...mappedQkbCategories,
    //   });
    // } else {
    setMappedQkbCategories({
      ...mappedQkbCategories,
      [index]: value,
    });
    // }
  };

  const handleZohoSelect = (value) => {
    setZohoOrganization(value);
  };

  const onSubmit = () => {
    if (name === 'ZOHO') {
      dispatch(
        setDefaultZohoOrganization({
          code: integrationData.code,
          organization: zohoOrganization.code,
        }),
      );
      return;
    }
    let categories = [];
    for (let key in mappedQkbCategories) {
      if (mappedQkbCategories[key]?.code) {
        categories.push({
          bujetiCategory: bujetiCategories[key]?.code,
          quickBooksCategory: {
            code: mappedQkbCategories[key]?.code,
            name: mappedQkbCategories[key]?.value,
          },
        });
      }
    }

    const payload = { categories };
    dispatch(createCategoryMapper(payload));
  };

  useEffect(() => {
    if (!isFetchingMapped && mappedData?.length > 0) {
      const newResponse = mappedData?.map((res) => {
        return {
          ...res,
          quickBooksCategory:
            res?.quickBooksCategory === null
              ? { value: '', label: '' }
              : {
                  code: res?.quickBooksCategory?.code,
                  value: res?.quickBooksCategory?.name,
                  label: res?.quickBooksCategory?.name,
                },
        };
      });
      setMappedCategoryData(newResponse);
      setInitialMappedData(newResponse?.filter((item) => item.quickBooksCategory?.value));
      createMappedQBOcategory(newResponse);
    }
  }, [isFetchingMapped, mappedData]);

  const createMappedQBOcategory = (newResponse) => {
    const categories = newResponse.reduce((accumulator, current, index) => {
      if (current?.quickBooksCategory?.code) {
        accumulator[index] = current.quickBooksCategory;
      }
      return accumulator;
    }, {});
    setMappedQkbCategories(categories);
  };

  useEffect(() => {
    if (!isCreating && isCreated && isEditing) {
      setIsEditing(false);
      dispatch(fetchMappedCategory());
      dispatch({ type: RESET_FLAGS_CATEGORY_MAPPER, blockType: 'createCategoryMapper' });
    }
    if (!isCreating && isCreated && !isEditing) {
      setIsEditing(false);
      dispatch(fetchMappedCategory());
      dispatch({ type: RESET_FLAGS_CATEGORY_MAPPER, blockType: 'createCategoryMapper' });
    }
  }, [isCreating, isCreated]);

  const Image = selectedIntegration?.selected?.imageUrl;
  const name = selectedIntegration?.selected?.name;

  if (isFetchingMapped || categoryLoading) <Loading isPage={true} color="#D28B28" />;

  const initialMappedDataLength = initialMappedData.length;

  return (
    <section className="connector__wrapper">
      <header>
        <div>
          <h1
            className={
              connected && initialMappedDataLength ? 'title connected-title' : 'title'
            }
          >
            {connected &&
              !initialMappedDataLength &&
              name === 'QUICKBOOKS' &&
              !isEditing &&
              "Great! Let's set up your account mapping"}
            {connected && (initialMappedDataLength || name !== 'QUICKBOOKS')
              ? `${toTitle(name)} Settings`
              : null}
          </h1>
          {name === 'QUICKBOOKS' && (
            <p>
              In the right field, select a category that you want to link to your Chart of
              Accounts.
            </p>
          )}
        </div>
        {connected && initialMappedDataLength && !isEditing ? (
          <div className="action__btn">
            <button
              onClick={(event) => {
                openDeleteModal(event);
              }}
            >
              Disconnect
            </button>
            <button onClick={() => setIsEditing(true)}>Edit</button>
          </div>
        ) : null}
      </header>
      <main className="category__optionsWrapper">
        <div className="row">
          {name.toLowerCase() === 'zoho' && (
            <>
              <p>Select the organization you would like to sync data for</p>
              <CustomSelect
                name="default_organiation"
                placeholder="Type or select your organization"
                options={zohoOrganizations}
                value={zohoOrganization}
                onChange={(val) => handleZohoSelect(val)}
                isClearable
              />
            </>
          )}
          {name.toLowerCase() !== 'zoho' && (
            <>
              <div className="col-sm-6">
                <div className="d-flex align-items-center gap-2 category-header">
                  <span className="logo__container--item">
                    <img src={Logo} alt="bujeti logo" />
                  </span>{' '}
                  Bujeti Category
                </div>
              </div>
              <div className="col-sm-6">
                <div className="category-header">
                  <div>
                    <Image />
                    {name?.toLowerCase()} Category
                  </div>
                </div>
              </div>
              <div className="category__selector flex-column mt-2">
                <>
                  {connected && isEditing === false && !initialMappedDataLength
                    ? bujetiCategories?.map((bujetiCategory, index) => {
                        const selectedValue = mappedQkbCategories[index];
                        return (
                          connected && (
                            <div
                              className="d-flex full__width align-items-center category__selector--list mt-2"
                              key={index}
                            >
                              <div className="left__side full__width">
                                <CustomSelect
                                  name="budget_category"
                                  placeholder=""
                                  options={bujetiCategories}
                                  value={{
                                    value: bujetiCategory.value,
                                    label: bujetiCategory.label,
                                  }}
                                  disabled={true}
                                />
                              </div>
                              <ArrowRight />
                              <div className="right__side full__width">
                                <CustomSelect
                                  name="budget_category"
                                  placeholder="Type or select a category"
                                  options={qkbCategories}
                                  value={selectedValue || 'type or select a category'}
                                  onChange={(val) => handleQkbSelect(val, index)}
                                  isClearable
                                />
                              </div>
                            </div>
                          )
                        );
                      })
                    : null}
                </>

                {connected && initialMappedDataLength
                  ? bujetiCategories?.map((bujetiCategory, index) => {
                      const selectedValue = mappedQkbCategories[index];
                      return (
                        connected && (
                          <div
                            className="d-flex full__width align-items-center category__selector--list mt-2"
                            key={index}
                          >
                            <div className="left__side full__width">
                              <CustomSelect
                                name="budget_category"
                                placeholder=""
                                options={bujetiCategories}
                                value={{
                                  value: bujetiCategory.value,
                                  label: bujetiCategory.label,
                                }}
                                disabled={true}
                              />
                            </div>
                            <ArrowRight />
                            <div className="right__side full__width">
                              <CustomSelect
                                name="budget_category"
                                placeholder="Select a category"
                                options={qkbCategories}
                                value={
                                  mappedCategoryData && !isEditing
                                    ? !mappedCategoryData[index]?.quickBooksCategory
                                        ?.value
                                      ? selectedValue
                                      : mappedCategoryData[index]?.quickBooksCategory
                                    : selectedValue
                                }
                                onChange={(val) => handleQkbSelect(val, index)}
                                disabled={isEditing ? false : true}
                                isClearable
                              />
                            </div>
                          </div>
                        )
                      );
                    })
                  : null}
              </div>
            </>
          )}
        </div>
        {connected && !initialMappedDataLength && isEditing === false ? (
          <footer>
            <div className="d-flex align-items-center gap-3 mt-4">
              <button onClick={() => setIsEditing(false)}>Cancel</button>
              <button onClick={onSubmit}>
                {isCreating ? <Loading size={18} color="#D28B28" /> : 'Save'}
              </button>
            </div>
          </footer>
        ) : null}
        {connected && initialMappedDataLength && isEditing ? (
          <footer>
            <div className="d-flex align-items-center gap-3 mt-4">
              <button onClick={() => setIsEditing(false)}>Cancel</button>
              <button onClick={onSubmit}>
                {isCreating ? <Loading size={18} color="#D28B28" /> : 'Save'}
              </button>
            </div>
          </footer>
        ) : null}
      </main>
    </section>
  );
};

export default Connector;

const ArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19M19 12L12 5M19 12L12 19"
        stroke="#6D6F6B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
