import { Axios } from 'api/axios';
import axios from 'axios';
import { getSimplifiedError } from './error';
import { addNewFile } from './FileUploadHelper';
import { toastError } from 'components/UI/toast';

export const awsUploadNew = async (payload, file, setProgress, setUploading, select) => {
  try {
    let { data } = await addNewFile(payload);

    const { key, originalFilename, signedUrl, url } = data;
    const res = await axios.put(signedUrl, file, {
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
      headers: { 'Content-Type': file?.type },
    });

    const fileType = file?.type.split('/')[1];
    const assetPayload = {
      key: key,
      name: file?.name,
      type: fileType,
    };

    const getAsset = await Axios.post('/assets', assetPayload);
    const {
      data: { asset },
    } = getAsset;
    setProgress(100);
    select({
      assetCode: asset.code,
      key,
      originalFilename,
      signedUrl,
      url,
      fileType: file?.type,
      fileName: file?.name,
      fileSize: file.size,
    });

    // setTimeout(() => {
    //   setUploading(false);
    // }, 0);

    if (res.status === 200) {
      // toastSuccess('Upload successful');
    }
  } catch (error) {
    toastError(getSimplifiedError(error));
    setUploading(false);
    return 'error';
  }
};
