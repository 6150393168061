import axios from 'axios';
import { store } from 'index';
import { get_user_profile } from 'redux/actions/AuthAction';
import { retrieveAndDecryptFromLocalStorage } from 'utils/utility';

const baseURL = process.env.REACT_APP_BASE_URL;

const getToken = (name = 'app-session') => {
  return retrieveAndDecryptFromLocalStorage(name);
};

let queue = [];

const Axios = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

Axios.interceptors.request.use(
  async (config) => {
    const token = getToken('app-session');
    const vendorToken = getToken('vendor-app-session');
    const directorToken = getToken('director-app-session');

    if (directorToken) config.headers.Authorization = `Bearer ${directorToken}`;
    if (vendorToken) config.headers.Authorization = `Bearer ${vendorToken}`;
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const newStore = store;

    if (error.response && error.response.status === 403) {
      new Promise((resolve, reject) => {
        queue.push(resolve);
        if (queue.length === 1) {
          // toastError('You are not allowed perform this action');
          newStore.dispatch(get_user_profile());
        }
      });
      return Promise.reject(error);
    }
    if (error && error.response && error.response.status === 401) {
      localStorage.removeItem('app-session');
      localStorage.removeItem('vendor-app-session');
      localStorage.removeItem('director-app-session');
    } else {
      return Promise.reject(error);
    }
  },
);

export { Axios };
