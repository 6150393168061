import ItemInfo from 'components/TransactionModal/itemInfo';

const DrawerBody = ({ data }) => {
  return data?.map((datum, index) => {
    const classname = index === 0 ? '' : 'padding-top-2';
    return (
      <div className={classname} key={index}>
        <ItemInfo
          title={datum?.title}
          value={datum?.value}
          icon={datum?.icon}
          isCopy={datum?.isCopy}
          withBadge={datum?.withBadge}
        />
      </div>
    );
  });
};

export default DrawerBody;
