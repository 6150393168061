export const TransferInIcon = () => {
  return (
    <svg
      width="190"
      height="230"
      viewBox="0 0 190 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddddd_2626_1596)">
        <g filter="url(#filter1_ddi_2626_1596)">
          <rect
            x="83"
            y="41"
            width="64"
            height="64"
            rx="24"
            fill="#E3FF8F"
            shapeRendering="crispEdges"
          />
          <rect
            x="83.5"
            y="41.5"
            width="63"
            height="63"
            rx="23.5"
            stroke="black"
            strokeOpacity="0.06"
            style={{ mixBlendMode: 'multiply' }}
            shapeRendering="crispEdges"
          />
          <g style={{ mixBlendMode: 'plus-darker' }}>
            <path
              d="M115 64.8333V81.1667M115 81.1667L123.167 73M115 81.1667L106.833 73"
              stroke="#A0A09E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddddd_2626_1596"
          x="0"
          y="0"
          width="230"
          height="230"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.589713" />
          <feGaussianBlur stdDeviation="0.717736" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2626_1596"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.30375" />
          <feGaussianBlur stdDeviation="2.22486" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.119754 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2626_1596"
            result="effect2_dropShadow_2626_1596"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.66126" />
          <feGaussianBlur stdDeviation="5.31844" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0962419 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_2626_1596"
            result="effect3_dropShadow_2626_1596"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="18.2581" />
          <feGaussianBlur stdDeviation="12.626" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0763308 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_2626_1596"
            result="effect4_dropShadow_2626_1596"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="42" />
          <feGaussianBlur stdDeviation="41.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.047058 0"
          />
          <feBlend
            mode="normal"
            in2="effect4_dropShadow_2626_1596"
            result="effect5_dropShadow_2626_1596"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect5_dropShadow_2626_1596"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddi_2626_1596"
          x="81"
          y="40"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.4" />
          <feGaussianBlur stdDeviation="0.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2626_1596"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2626_1596"
            result="effect2_dropShadow_2626_1596"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2626_1596"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.8" />
          <feGaussianBlur stdDeviation="0.4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="plus-darker"
            in2="shape"
            result="effect3_innerShadow_2626_1596"
          />
        </filter>
      </defs>
    </svg>
  );
};
