import React from 'react';

const RoleParagraphs = ({ datum }) => {
  const lines = datum?.permissions?.map(
    (permission) => permission.description.split('-')[0],
  );

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}.
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default RoleParagraphs;
