import { useContext } from 'react';
import { IntegrationsContext } from 'pages/Settings';
import Logo from 'assets/logos/smallLogo.svg';
import { ConnectingArrowIcon, ValueStarIcon } from 'assets/icons';
import Loading from 'components/UI/Loading';

const ConnectionDescription = () => {
  const { selectedIntegration, handleConnect, loading, processingRutter } =
    useContext(IntegrationsContext);
  const selected = selectedIntegration?.selected?.name?.toLowerCase();
  return (
    <section className="connection__description">
      <header>
        <div className="logo__container d-flex align-items-center justify-content-center">
          <span className="logo__container--item">
            <img src={Logo} alt="bujeti logo" />
          </span>
          <div>
            <ConnectingArrowIcon />
          </div>
          <span className="option-logo">
            <selectedIntegration.selected.imageUrl />
          </span>
        </div>
        <h1 className="title">Connect Bujeti to your {selected} accounts</h1>
        <p className="text-center">
          {' '}
          Work faster and smarter by integrating directly with {selected}, right in the
          app.
        </p>
      </header>
      <main>
        {selectedIntegration?.selected?.values?.map((item) => {
          return (
            <div className="values" key={item.title}>
              <span className="d-flex justify-content-center align-items-center">
                <ValueStarIcon />
              </span>
              <div className="details">
                <h6>{item.title}</h6>
                <p>{item.description}</p>
              </div>
            </div>
          );
        })}
      </main>
      <footer className="d-flex justify-content-center align-items-center">
        <button onClick={handleConnect} disabled={processingRutter || loading}>
          Connect to {selected}
          {processingRutter || loading ? (
            <Loading color="#D28B28" size={18} />
          ) : (
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66675 7H13.3334M13.3334 7L7.50008 1.16667M13.3334 7L7.50008 12.8333"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </footer>
    </section>
  );
};

export default ConnectionDescription;
