export const GET_VENDOR_PROFILE_REQUEST = 'GET_VENDOR_PROFILE_REQUEST';
export const GET_VENDOR_PROFILE_SUCCESS = 'GET_VENDOR_PROFILE_SUCCESS';
export const GET_VENDOR_PROFILE_ERROR = 'GET_VENDOR_PROFILE_ERROR';

export const GET_VENDOR_APPROVERS_REQUEST = 'GET_VENDOR_APPROVERS_REQUEST';
export const GET_VENDOR_APPROVERS_SUCCESS = 'GET_VENDOR_APPROVERS_SUCCESS';
export const GET_VENDOR_APPROVERS_ERROR = 'GET_VENDOR_APPROVERS_ERROR';

export const RESET_BLOCK_VENDOR_PROFILE = 'RESET_BLOCK_VENDOR_PROFILE';

export const RESET_FLAGS_VENDOR_PROFILE = 'RESET_FLAGS_VENDOR_PROFILE';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getVendorProfile: { ...block },
  getVendorApprovers: { ...block },
};

export const VendorProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDOR_PROFILE_REQUEST:
      return {
        ...state,
        getVendorProfile: { ...state.getVendorProfile, loading: true },
      };
    case GET_VENDOR_PROFILE_SUCCESS:
      return {
        ...state,
        getVendorProfile: {
          ...state.getVendorProfile,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_VENDOR_PROFILE_ERROR:
      return {
        ...state,
        getVendorProfile: {
          ...state.getVendorProfile,
          loading: false,
          error: action.error,
        },
      };

    case GET_VENDOR_APPROVERS_REQUEST:
      return {
        ...state,
        getVendorApprovers: { ...state.getVendorApprovers, loading: true },
      };
    case GET_VENDOR_APPROVERS_SUCCESS:
      return {
        ...state,
        getVendorApprovers: {
          ...state.getVendorApprovers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_VENDOR_APPROVERS_ERROR:
      return {
        ...state,
        getVendorApprovers: {
          ...state.getVendorApprovers,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_VENDOR_PROFILE:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_VENDOR_PROFILE:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
