import {
  FIND_COMPANY_NAME_REQUEST,
  GET_DIRECTOR_DETAILS_REQUEST,
} from 'redux/reducers/UtilsReducer';

export const findCompanyName = (payload) => ({
  type: FIND_COMPANY_NAME_REQUEST,
  payload,
});
export const getDirectorsDetails = (payload) => ({
  type: GET_DIRECTOR_DETAILS_REQUEST,
  payload,
});
