export const FETCH_SETTLEMENT_ACCOUNTS_REQUEST = 'FETCH_SETTLEMENT_ACCOUNTS_REQUEST';
export const FETCH_SETTLEMENT_ACCOUNTS_ERROR = 'FETCH_SETTLEMENT_ACCOUNTS_ERROR';
export const FETCH_SETTLEMENT_ACCOUNTS_SUCCESS = 'FETCH_SETTLEMENT_ACCOUNTS_SUCCESS';

export const CREATE_SETTLEMENT_ACCOUNT_REQUEST = 'CREATE_SETTLEMENT_ACCOUNT_REQUEST';
export const CREATE_SETTLEMENT_ACCOUNT_ERROR = 'CREATE_SETTLEMENT_ACCOUNT_ERROR';
export const CREATE_SETTLEMENT_ACCOUNT_SUCCESS = 'CREATE_SETTLEMENT_ACCOUNT_SUCCESS';

export const DELETE_SETTLEMENT_ACCOUNT_REQUEST = 'DELETE_SETTLEMENT_ACCOUNT_REQUEST';
export const DELETE_SETTLEMENT_ACCOUNT_ERROR = 'DELETE_SETTLEMENT_ACCOUNT_ERROR';
export const DELETE_SETTLEMENT_ACCOUNT_SUCCESS = 'DELETE_SETTLEMENT_ACCOUNT_SUCCESS';

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_ERROR = 'VERIFY_ACCOUNT_ERROR';

export const VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST =
  'VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST';
export const VERIFY_SETTLEMENT_ACCOUNT_OTP_ERROR = 'VERIFY_SETTLEMENT_ACCOUNT_OTP_ERROR';
export const VERIFY_SETTLEMENT_ACCOUNT_OTP_SUCCESS =
  'VERIFY_SETTLEMENT_ACCOUNT_OTP_SUCCESS';

export const RESET_FLAGS_SETTLEMENT = 'RESET_FLAGS_SETTLEMENT';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  fetchSettlementAccounts: { ...block },
  createSettlementAccount: { ...block },
  deleteSettlementAccount: { ...block },
  verifySettlementAccountOtp: { ...block },
  verifyAccount: { ...block },
};

export const SettlementAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTLEMENT_ACCOUNTS_REQUEST:
      return {
        ...state,
        fetchSettlementAccounts: {
          ...state.fetchSettlementAccounts,
          loading: true,
        },
      };
    case FETCH_SETTLEMENT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        fetchSettlementAccounts: {
          ...state.fetchSettlementAccounts,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_SETTLEMENT_ACCOUNTS_ERROR:
      return {
        ...state,
        fetchSettlementAccounts: {
          ...state.fetchSettlementAccounts,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case CREATE_SETTLEMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        createSettlementAccount: {
          ...state.createSettlementAccount,
          loading: true,
        },
      };
    case CREATE_SETTLEMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        createSettlementAccount: {
          ...state.createSettlementAccount,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case CREATE_SETTLEMENT_ACCOUNT_ERROR:
      return {
        ...state,
        createSettlementAccount: {
          ...state.createSettlementAccount,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case DELETE_SETTLEMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteSettlementAccount: {
          ...state.deleteSettlementAccount,
          loading: true,
        },
      };
    case DELETE_SETTLEMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteSettlementAccount: {
          ...state.deleteSettlementAccount,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case DELETE_SETTLEMENT_ACCOUNT_ERROR:
      return {
        ...state,
        deleteSettlementAccount: {
          ...state.deleteSettlementAccount,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST:
      return {
        ...state,
        verifySettlementAccountOtp: {
          ...state.verifySettlementAccountOtp,
          loading: true,
        },
      };
    case VERIFY_SETTLEMENT_ACCOUNT_OTP_SUCCESS:
      return {
        ...state,
        verifySettlementAccountOtp: {
          ...state.verifySettlementAccountOtp,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case VERIFY_SETTLEMENT_ACCOUNT_OTP_ERROR:
      return {
        ...state,
        verifySettlementAccountOtp: {
          ...state.verifySettlementAccountOtp,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case VERIFY_ACCOUNT_REQUEST:
      return {
        ...state,
        verifyAccount: {
          ...state.verifyAccount,
          loading: true,
        },
      };
    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        verifyAccount: {
          ...state.verifyAccount,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case VERIFY_ACCOUNT_ERROR:
      return {
        ...state,
        verifyAccount: {
          ...state.verifyAccount,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case RESET_FLAGS_SETTLEMENT: {
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          data: {},
          ...block,
        },
      };
    }
    default:
      return state;
  }
};
