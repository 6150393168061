import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import {
  ACTIVATE_BUDGET_ERROR,
  ACTIVATE_BUDGET_REQUEST,
  ACTIVATE_BUDGET_SUCCESS,
  ADD_MULTIPLE_BENEFICIARIES_ERROR,
  ADD_MULTIPLE_BENEFICIARIES_REQUEST,
  ADD_MULTIPLE_BENEFICIARIES_SUCCESS,
  ADD_SUBACCOUNT_MEMBERS_ERROR,
  ADD_SUBACCOUNT_MEMBERS_REQUEST,
  ADD_SUBACCOUNT_MEMBERS_SUCCESS,
  BUDGET_BENEFICIARIES_ERROR,
  BUDGET_BENEFICIARIES_REQUEST,
  BUDGET_BENEFICIARIES_SUCCESS,
  BUDGET_CARD_ERROR,
  BUDGET_CARD_REQUEST,
  BUDGET_CARD_SUCCESS,
  CREATE_BUDGET_ERROR,
  CREATE_BUDGET_REQUEST,
  CREATE_BUDGET_SUCCESS,
  CREATE_NEW_BENEFICIARIES_ERROR,
  CREATE_NEW_BENEFICIARIES_REQUEST,
  CREATE_NEW_BENEFICIARIES_SUCCESS,
  CURRENCY_CONVERSION_RATE_ERROR,
  CURRENCY_CONVERSION_RATE_REQUEST,
  CURRENCY_CONVERSION_RATE_SUCCESS,
  DEACTIVATE_BUDGET_ERROR,
  DEACTIVATE_BUDGET_REQUEST,
  DEACTIVATE_BUDGET_SUCCESS,
  DELETE_BUDGET_BENEFICIARIES_ERROR,
  DELETE_BUDGET_BENEFICIARIES_REQUEST,
  DELETE_BUDGET_BENEFICIARIES_SUCCESS,
  DELETE_BUDGET_ERROR,
  DELETE_BUDGET_REQUEST,
  DELETE_BUDGET_SUCCESS,
  DOWNLOAD_BUDGET_AUDIT_ERROR,
  DOWNLOAD_BUDGET_AUDIT_REQUEST,
  DOWNLOAD_BUDGET_AUDIT_SUCCESS,
  GET_ACCOUNT_SUBACCOUNTS_ERROR,
  GET_ACCOUNT_SUBACCOUNTS_REQUEST,
  GET_ACCOUNT_SUBACCOUNTS_SUCCESS,
  GET_BALANCES_ERROR,
  GET_BALANCES_REQUEST,
  GET_BALANCES_SUCCESS,
  GET_BUDGET_ERROR,
  GET_BUDGET_LEDGER_ERROR,
  GET_BUDGET_LEDGER_REQUEST,
  GET_BUDGET_LEDGER_SUCCESS,
  GET_BUDGET_REQUEST,
  GET_BUDGET_STATS_ERROR,
  GET_BUDGET_STATS_REQUEST,
  GET_BUDGET_STATS_SUCCESS,
  GET_BUDGET_SUCCESS,
  GET_SELECTED_BUDGET_ERROR,
  GET_SELECTED_BUDGET_REQUEST,
  GET_SELECTED_BUDGET_SUCCESS,
  GET_SINGLE_BALANCE_ERROR,
  GET_SINGLE_BALANCE_REQUEST,
  GET_SINGLE_BALANCE_SUCCESS,
  GET_SINGLE_BUDGET_ERROR,
  GET_SINGLE_BUDGET_REQUEST,
  GET_SINGLE_BUDGET_SUCCESS,
  GET_SINGLE_SUBACCOUNT_ERROR,
  GET_SINGLE_SUBACCOUNT_REQUEST,
  GET_SINGLE_SUBACCOUNT_SUCCESS,
  GET_SUB_ACCOUNT_ERROR,
  GET_SUB_ACCOUNT_REQUEST,
  GET_SUB_ACCOUNT_SUCCESS,
  GET_SUBACCOUNT_MEMBERS_ERROR,
  GET_SUBACCOUNT_MEMBERS_REQUEST,
  GET_SUBACCOUNT_MEMBERS_SUCCESS,
  GET_SUBACCOUNT_TRANSACTIONS_ERROR,
  GET_SUBACCOUNT_TRANSACTIONS_REQUEST,
  GET_SUBACCOUNT_TRANSACTIONS_SUCCESS,
  GET_TRANSFERABLE_BALANCE_ERROR,
  GET_TRANSFERABLE_BALANCE_REQUEST,
  GET_TRANSFERABLE_BALANCE_SUCCESS,
  REMOVE_SUBACCOUNT_MEMBER_ERROR,
  REMOVE_SUBACCOUNT_MEMBER_REQUEST,
  REMOVE_SUBACCOUNT_MEMBER_SUCCESS,
  RESET_BLOCK_BUDGET,
  TOPUP_BUDGET_ERROR,
  TOPUP_BUDGET_REQUEST,
  TOPUP_BUDGET_SUCCESS,
  UPDATE_BUDGET_BENEFICIARY_SETTINGS_ERROR,
  UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST,
  UPDATE_BUDGET_BENEFICIARY_SETTINGS_SUCCESS,
  UPDATE_BUDGET_ERROR,
  UPDATE_BUDGET_REQUEST,
  UPDATE_BUDGET_SUCCESS,
  WITHDRAW_BUDGET_ERROR,
  WITHDRAW_BUDGET_FUNDS_ERROR,
  WITHDRAW_BUDGET_FUNDS_REQUEST,
  WITHDRAW_BUDGET_FUNDS_SUCCESS,
  WITHDRAW_BUDGET_REQUEST,
  WITHDRAW_BUDGET_SUCCESS,
} from '../reducers/BudgetsReducer';
import { GENERATE_ACCOUNT_STATEMENT_SUCCESS } from 'redux/reducers/AccountStatementReducer';

async function getBudget(params = {}) {
  return await Axios.get('/budgets', { params });
}
function* handleGetBudget({ payload }) {
  try {
    const response = yield call(getBudget, payload);
    if (response) {
      yield put({
        type: GET_BUDGET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSelectedBudget(params = {}) {
  return await Axios.get('/budgets', { params });
}
function* handleSelectedGetBudget({ payload }) {
  try {
    const response = yield call(getSelectedBudget, payload);
    if (response) {
      yield put({
        type: GET_SELECTED_BUDGET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SELECTED_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteBudget({ code }) {
  return await Axios.delete(`/budgets/${code}`);
}
function* handleDeleteBudget({ payload }) {
  try {
    const response = yield call(deleteBudget, payload);
    if (response) {
      yield put({
        type: DELETE_BUDGET_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'deleteBudget',
      });

      yield put({
        type: GET_BUDGET_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBudgetStats({ id, params }) {
  return await Axios.get(`/budgets/${id}/statistics`, { params });
}
function* handleGetBudgetStats({ payload }) {
  try {
    const response = yield call(getBudgetStats, payload);
    if (response) {
      yield put({
        type: GET_BUDGET_STATS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BUDGET_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBudget(payload) {
  return await Axios.post('/budgets', payload);
}
function* handleCreateBudget({ payload }) {
  try {
    const response = yield call(createBudget, payload);
    if (response) {
      yield put({
        type: CREATE_BUDGET_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'createBudget',
      });
      // yield put({
      //   type: GET_BUDGET_REQUEST,
      // });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBudget({ payload, code }) {
  return await Axios.put(`/budgets/${code}`, payload);
}
function* handleUpdateBudget({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(updateBudget, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: UPDATE_BUDGET_SUCCESS,
        data: payload,
      });
      // yield put({
      //   type: GET_SINGLE_BUDGET_REQUEST,
      //   payload: { id: getPayload.code },
      // });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'updateBudget',
      });

      yield put({
        type: GET_BUDGET_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleBudget({ id }) {
  return await Axios.get(`/budgets/${id}`);
}
function* handleSingleBudget({ payload }) {
  try {
    const response = yield call(singleBudget, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_BUDGET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function budgetBeneficiaries(params) {
  return await Axios.get('/beneficiaries', { params });
}
function* handleBudgetBeneficiaries({ payload }) {
  try {
    const response = yield call(budgetBeneficiaries, payload);
    if (response) {
      yield put({
        type: BUDGET_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: BUDGET_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function budgetCard(params) {
  return await Axios.get('/cards', { params });
}
function* handleBudgetCard({ payload }) {
  try {
    const response = yield call(budgetCard, payload);
    if (response) {
      yield put({
        type: BUDGET_CARD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: BUDGET_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getTransferableBalance(payload) {
  return await Axios.get(`/budgets/${payload}/transferable-balance`);
}
function* handleGetTransferableBalance({ payload }) {
  try {
    const response = yield call(getTransferableBalance, payload);
    if (response) {
      yield put({
        type: GET_TRANSFERABLE_BALANCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_TRANSFERABLE_BALANCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function newBeneficiaries(payload) {
  const { budgetsCode, beneficiariesCode } = { ...payload };
  delete payload['budgetsCode'];
  delete payload['beneficiariesCode'];
  return await Axios.post(
    `/budgets/${budgetsCode}/beneficiaries/${beneficiariesCode}`,
    payload,
  );
}
function* handleCreateNewBeneficiaries({ payload }) {
  try {
    const response = yield call(newBeneficiaries, payload);
    if (response) {
      yield put({
        type: CREATE_NEW_BENEFICIARIES_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'createNewBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_NEW_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addMultipleBeneficiary({ payload, code }) {
  return await Axios.post(`/budgets/${code}/beneficiaries`, payload);
}

function* handleAddMultipleBeneficiary({ payload }) {
  try {
    const requestPayload = { ...payload };
    delete payload.code;
    const response = yield call(addMultipleBeneficiary, {
      payload,
      code: requestPayload.code,
    });
    if (response) {
      yield put({
        type: ADD_MULTIPLE_BENEFICIARIES_SUCCESS,
        data: payload,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'multipleBeneficiaries',
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_MULTIPLE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteBudgetBeneficiaries({ budgetsCode, beneficiariesCode }) {
  return await Axios.delete(`budgets/${budgetsCode}/beneficiaries/${beneficiariesCode}`);
}
function* handleDeleteBudgetBeneficiaries({ payload }) {
  try {
    const response = yield call(deleteBudgetBeneficiaries, payload);
    if (response) {
      yield put({
        type: DELETE_BUDGET_BENEFICIARIES_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'deleteBudgetBeneficiaries',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_BUDGET_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCurrencyConversionRate(payload) {
  return await Axios.post(
    `utils/exchange/${payload.baseCurrency}/${payload.targetCurrency}
  `,
    payload,
  );
}
function* handleGetCurrencyConversionRate({ payload }) {
  try {
    const response = yield call(getCurrencyConversionRate, payload);
    if (response) {
      yield put({
        type: CURRENCY_CONVERSION_RATE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CURRENCY_CONVERSION_RATE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function topUpBudget({ payload, code }) {
  return await Axios.put(`/budgets/${code}/topup`, payload);
}
function* handleTopUpBudget({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(topUpBudget, { payload, code: getPayload.code });
    if (response) {
      toastSuccess(response.message);
      yield put({
        type: TOPUP_BUDGET_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'topUpBudget',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: TOPUP_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function withdrawBudget({ payload, code }) {
  return await Axios.put(`/budgets/${code}/withdraw`, payload);
}
function* handleWithdrawBudget({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(withdrawBudget, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: WITHDRAW_BUDGET_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'withdrawBudget',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: WITHDRAW_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deactivateBudget({ payload, code }) {
  return await Axios.patch(`/budgets/${code}/deactivate`, payload);
}
function* handleDeactivateBudget({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(deactivateBudget, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: DEACTIVATE_BUDGET_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'deactivateBudget',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DEACTIVATE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function activateBudget(payload) {
  return await Axios.patch(`/budgets/${payload}/activate`);
}
function* handleActivateBudget({ payload }) {
  try {
    const response = yield call(activateBudget, payload);
    if (response) {
      yield put({
        type: ACTIVATE_BUDGET_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'activateBudget',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ACTIVATE_BUDGET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBudgetLedger({ payload, code }) {
  return await Axios.get(`/budgets/${code}/history`, { params: payload });
}
function* handleGetBudgetLedger({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.budget;
    const { budget: code } = getPayload;

    const response = yield call(getBudgetLedger, { payload, code });
    if (response) {
      yield put({
        type: GET_BUDGET_LEDGER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BUDGET_LEDGER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSubAccount(payload) {
  return await Axios.get(`/budgets/${payload}/account`);
}
function* handleGetSubAccount({ payload }) {
  try {
    const response = yield call(getSubAccount, payload);
    if (response) {
      yield put({
        type: GET_SUB_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SUB_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBalances(params) {
  return await Axios.get(`/balances`, { params });
}
function* handleGetBalances({ payload }) {
  try {
    const response = yield call(getBalances, payload);
    if (response) {
      yield put({
        type: GET_BALANCES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BALANCES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getAccountSubAccounts(payload) {
  const { balanceCode } = payload;
  delete payload.balanceCode;
  return await Axios.get(`/balances/${balanceCode}/subaccounts`, {
    params: { ...payload },
  });
}

function* handleGetAccountSubaccounts({ payload }) {
  try {
    const response = yield call(getAccountSubAccounts, payload);
    if (response) {
      yield put({
        type: GET_ACCOUNT_SUBACCOUNTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_ACCOUNT_SUBACCOUNTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function removeSubAccountMember({ code, memberCode }) {
  return await Axios.delete(`/banks/${code}/members/${memberCode}`);
}

function* handleRemoveSubAccountMember({ payload }) {
  try {
    const response = yield call(removeSubAccountMember, payload);
    if (response) {
      yield put({
        type: REMOVE_SUBACCOUNT_MEMBER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REMOVE_SUBACCOUNT_MEMBER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSubAccountMembers({ code, params }) {
  return await Axios.get(`/banks/${code}/members`, { params });
}

function* handleGetSubAccountMembers({ payload }) {
  try {
    const response = yield call(getSubAccountMembers, payload);
    if (response) {
      yield put({
        type: GET_SUBACCOUNT_MEMBERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SUBACCOUNT_MEMBERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSubAccountTransactions(payload) {
  const code = payload.code;
  delete payload.code;
  return await Axios.get(`/balances/${code}/entries`, { params: { ...payload } });
}

function* handleGetSubAccountTransactions({ payload }) {
  try {
    const pageSource = payload.pageSource;
    delete payload.pageSource;
    const response = yield call(getSubAccountTransactions, payload);
    if (response) {
      yield put({
        type: GET_SUBACCOUNT_TRANSACTIONS_SUCCESS,
        data: response.data,
        pageSource,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SUBACCOUNT_TRANSACTIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addSubAccountMembers({ code, payload }) {
  return await Axios.post(`/banks/${code}/members`, payload);
}

function* handleAddSubAccountMembers({ payload }) {
  try {
    const response = yield call(addSubAccountMembers, payload);
    if (response) {
      yield put({
        type: ADD_SUBACCOUNT_MEMBERS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'addSubAccountMembers',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_SUBACCOUNT_MEMBERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function withdrawFunds({ payload, code }) {
  return await Axios.put(`/budgets/${code}/withdraw`, payload);
}

function* handleWithdrawFunds({ payload }) {
  try {
    const requestPayload = { ...payload };
    delete payload.code;
    const response = yield call(withdrawFunds, {
      payload,
      code: requestPayload.code,
    });
    if (response) {
      yield put({
        type: WITHDRAW_BUDGET_FUNDS_SUCCESS,
        data: payload,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: WITHDRAW_BUDGET_FUNDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleBalance(payload) {
  return await Axios.get(`/balances/${payload}`);
}
function* handleGetSingleBalance({ payload }) {
  try {
    const response = yield call(getSingleBalance, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_BALANCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_BALANCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleSubAccount(payload) {
  return await Axios.get(`/balances/${payload}`);
}
function* handleGetSingleSubAccount({ payload }) {
  try {
    const response = yield call(getSingleSubAccount, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_SUBACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_SUBACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBudgetBeneficiarySettings({ payload }) {
  const { code, bnfCode, ...rest } = payload;
  return await Axios.put(`/budgets/${code}/beneficiaries/${bnfCode}`, rest);
}
function* handleUpdateBudgetBeneficiarySettings({ payload }) {
  try {
    const response = yield call(updateBudgetBeneficiarySettings, { payload });
    if (response) {
      yield put({
        type: UPDATE_BUDGET_BENEFICIARY_SETTINGS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BUDGET,
        blockType: 'updateBudgetBeneficiarySettings',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_BUDGET_BENEFICIARY_SETTINGS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function downloadBudgetAudit(params) {
  return await Axios.get(`/budgets/transactions/export-statement`, { params });
}
function* handleDownloadBudgetAudit({ payload }) {
  try {
    const response = yield call(downloadBudgetAudit, payload);
    if (response) {
      yield put({
        type: DOWNLOAD_BUDGET_AUDIT_SUCCESS,
        data: response.data,
      });
      yield put({
        type: GENERATE_ACCOUNT_STATEMENT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DOWNLOAD_BUDGET_AUDIT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

//balances
export default all([
  takeLatest(GET_BUDGET_REQUEST, handleGetBudget),
  takeLatest(GET_SELECTED_BUDGET_REQUEST, handleSelectedGetBudget),
  takeLatest(DELETE_BUDGET_REQUEST, handleDeleteBudget),
  takeLatest(GET_BUDGET_STATS_REQUEST, handleGetBudgetStats),
  takeLatest(CREATE_BUDGET_REQUEST, handleCreateBudget),
  takeLatest(UPDATE_BUDGET_REQUEST, handleUpdateBudget),
  takeLatest(GET_SINGLE_BUDGET_REQUEST, handleSingleBudget),
  takeLatest(BUDGET_BENEFICIARIES_REQUEST, handleBudgetBeneficiaries),
  takeLatest(BUDGET_CARD_REQUEST, handleBudgetCard),
  takeLatest(CREATE_NEW_BENEFICIARIES_REQUEST, handleCreateNewBeneficiaries),
  takeLatest(DELETE_BUDGET_BENEFICIARIES_REQUEST, handleDeleteBudgetBeneficiaries),
  takeLatest(GET_TRANSFERABLE_BALANCE_REQUEST, handleGetTransferableBalance),
  takeLatest(CURRENCY_CONVERSION_RATE_REQUEST, handleGetCurrencyConversionRate),
  takeLatest(TOPUP_BUDGET_REQUEST, handleTopUpBudget),
  takeLatest(WITHDRAW_BUDGET_REQUEST, handleWithdrawBudget),
  takeLatest(DEACTIVATE_BUDGET_REQUEST, handleDeactivateBudget),
  takeLatest(ACTIVATE_BUDGET_REQUEST, handleActivateBudget),
  takeLatest(GET_BUDGET_LEDGER_REQUEST, handleGetBudgetLedger),
  takeLatest(GET_SUB_ACCOUNT_REQUEST, handleGetSubAccount),
  takeLatest(GET_BALANCES_REQUEST, handleGetBalances),
  takeLatest(GET_ACCOUNT_SUBACCOUNTS_REQUEST, handleGetAccountSubaccounts),
  takeLatest(GET_SUBACCOUNT_MEMBERS_REQUEST, handleGetSubAccountMembers),
  takeLatest(GET_SUBACCOUNT_TRANSACTIONS_REQUEST, handleGetSubAccountTransactions),
  takeLatest(REMOVE_SUBACCOUNT_MEMBER_REQUEST, handleRemoveSubAccountMember),
  takeLatest(ADD_SUBACCOUNT_MEMBERS_REQUEST, handleAddSubAccountMembers),
  takeLatest(ADD_MULTIPLE_BENEFICIARIES_REQUEST, handleAddMultipleBeneficiary),
  takeLatest(WITHDRAW_BUDGET_FUNDS_REQUEST, handleWithdrawFunds),
  takeLatest(GET_SINGLE_BALANCE_REQUEST, handleGetSingleBalance),
  takeLatest(GET_SINGLE_SUBACCOUNT_REQUEST, handleGetSingleSubAccount),
  takeLatest(
    UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST,
    handleUpdateBudgetBeneficiarySettings,
  ),
  takeLatest(DOWNLOAD_BUDGET_AUDIT_REQUEST, handleDownloadBudgetAudit),
]);
