import {
  VERIFY_BVN_OTP_REQUEST,
  VERIFY_BVN_REQUEST,
} from '../reducers/BvnRequestReducer';

export const verifyBvnAction = (payload) => ({
  type: VERIFY_BVN_REQUEST,
  payload,
});

export const verifyBvnOtpAction = (payload) => ({
  type: VERIFY_BVN_OTP_REQUEST,
  payload,
});
