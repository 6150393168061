import classNames from 'classnames';
import AssetViewer from 'components/AssetViewer';
import useOutsideClick from 'components/clickoutside';
import { useEffect, useRef } from 'react';
import './styles.scss';

const ModalComponent = ({
  children,
  onClick,
  active,
  close,

  size,
  position = 'side',
  px = true,
  singleData,
  selectedAsset,
  setSelectedAsset,
  assetData,
  background = '#fff',
}) => {
  const ref = useRef(null);
  const buttonClickedOutside = useOutsideClick(ref);

  useEffect(() => {
    if (buttonClickedOutside && active) {
      onClick(!active);
    }
  }, [buttonClickedOutside, onClick, active]);

  useEffect(() => {
    if (active) {
      document.body.classList.add('overflow-hidden'); // Add the class to disable scrolling
    } else {
      document.body.classList.remove('overflow-hidden'); // Remove the class to re-enable scrolling
    }

    return () => {
      document.body.classList.remove('overflow-hidden'); // Make sure to remove the class on unmount
    };
  }, [active]);

  const SideModal = (
    <>
      <div
        className={classNames(
          active ? 'opacity-100' : 'pointer-events-none opacity-0',
          'overlay',
        )}
      ></div>

      <div
        className="position-fixed top-0"
        style={{
          zIndex: 99,
          left: '30rem',
          transform: 'translateX(-50%)',
          maxHeight: '100vh',
          overflowY: 'auto',
        }}
      >
        {selectedAsset && (
          <AssetViewer
            toggleVisibility={() => setSelectedAsset(null)}
            data={assetData}
            selectedAsset={selectedAsset}
            singleData={singleData}
          />
        )}
      </div>

      <div
        className={classNames(
          'hide-scrollbar modal-container-side',
          active ? 'active-container' : 'inactive-container',
        )}
      >
        <div
          ref={ref}
          className={classNames(
            'hide-scrollbar modal-container-child bg-white',
            // { ['px-4 md:px-8']: px },
          )}
        >
          {/* <div className="z-3 bg-gray-200 left-0 right-0 top-0 w-100 px-3 border-bottom py-3 d-flex justify-content-between align-items-center">
            <CloseOutlined onClick={() => onClick(!active)} className=" cursor" />
            <div className="text-sm fw-medium">{modalName}</div>
            <div>
              <CustomPopover
                content={action}
                showPopover={isPopoverOpen}
                clickOutside={handleTogglePopover}
              >
                <div onClick={handleTogglePopover} className="cursor">
                  <KebabIcon stroke="#A9A29D" style={{ transform: 'rotate(90deg)' }} />
                </div>
              </CustomPopover>
            </div>
          </div> */}

          {/* <div
            onClick={() => onClick(!active)}
            className="position-absolute left-0 d-md-flex d-none h-6 w-6 -translate-y-[2.2rem] translate-x-5 rounded-full border outline-none  transition-all duration-500 ease-in-out hover:bg-red-500 hover:text-white md:flex xl:h-7 xl:w-7 xl:-translate-y-[2.8rem] xl:translate-x-8"
          >
            <CloseOutlined className="m-auto" />
          </div> */}

          <div className="w-100 h-100" style={{ background }}>
            {children}
          </div>
        </div>
      </div>
    </>
  );

  const CenteredModal = (
    <>
      <div
        // tabindex="-1"
        className={classNames(
          'modal hide-scrollbar  modal-container-center',
          active ? 'opacity-100' : 'pointer-events-none opacity-0',
        )}
      >
        <div
          ref={ref}
          className={classNames(
            'mx-auto w-11/12 bg-white origin-bottom transform rounded-[10px] pt-6 transition-all delay-200 duration-500 xl:pt-10 ',
            active
              ? 'translate-y-0 scale-100 opacity-100'
              : 'translate-y-10 scale-90 opacity-0',
            size ? size : 'max-w-lg',
          )}
        >
          {/* {close && (
            <button
              onClick={() => onClick(!active)}
              className="absolute right-0 flex h-6 w-6 -translate-x-5 -translate-y-[0.4rem] rounded-full border  outline-none transition-all duration-500 ease-in-out hover:bg-red-500 hover:text-white xl:h-7 xl:w-7 xl:-translate-x-8 xl:-translate-y-[0.7rem]"
            >
              <CloseOutlined className="m-auto" />
            </button>
          )} */}

          {children}
        </div>
      </div>
    </>
  );
  return (
    <div className="modal-component">
      {position === 'center' ? CenteredModal : SideModal}
    </div>
  );
};

export default ModalComponent;
