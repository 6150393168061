import { Checkbox } from 'antd';
import { CancelICon, PlusDropDownIcon } from 'assets/icons';
import { ReactComponent as BankIcon } from 'assets/icons/gray-bank-icon.svg';
import CustomButton, { ActiveButton } from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container, Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from 'redux/actions/CategoryAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { validateFormTable, validateObjectData } from 'utils';
import BatchDuplicates from './BatchDuplicates';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';

const columns = [
  'Bank name',
  'Account number',
  'Name',
  'Source of fund',
  'Amount',
  'Category',
  'Description',
  '',
];

function findDuplicateObjects(array) {
  let counts = {};
  let totalDuplicates = 0;
  let duplicates = [];

  array.forEach((obj) => {
    const key = JSON.stringify(obj);
    counts[key] = (counts[key] || 0) + 1;
  });

  for (const key in counts) {
    if (counts[key] > 1) {
      const data = JSON.parse(key);
      const count = counts[key];
      totalDuplicates += count;
      duplicates.push({ data, count });
    }
  }

  return { total: totalDuplicates, duplicates };
}
const stickyColumns = ['', 'Name'];
const MultipleAccountUpload = ({
  loadOptions,
  loadingBalances,
  setTableData,
  batchName,
  setIsUploaded,
  formRows,
  setFormRows,
  getSelected,
  selectedRows,
  setSelectedRows,
  selectAll,
  setSelectAll,
  setPreview,
}) => {
  const menuPortalTarget = document.body;

  const [isAccountSet, setIsAccountSet] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [duplicates, setDuplicates] = useState();
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const tableRef = useRef(null);
  const scrollableTableRef = useRef(null);

  const dispatch = useDispatch();

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  const {
    fetchCategories: {
      data: { categories = [] } = {},
      loading: isCatLoading,
      success: isCatSuccess,
    },
  } = useSelector(({ categories }) => categories);

  //get category
  useEffect(() => {
    dispatch(fetchCategories());
    if (!banksData) dispatch(getAllBanks());
  }, []);

  const categoriesList = useMemo(() => {
    return categories?.map((category) => ({
      value: category?.code,
      label: category?.name,
    }));
  }, [isCatSuccess]);

  useEffect(() => {
    const table = tableRef.current;

    if (!table) return;

    const updateColumnWidth = () => {
      const columnsHeads = table.querySelectorAll('thead th.sticky-column');
      const columns = Array.from(table.querySelectorAll('tbody td.sticky-column'));
      const widths = {};

      let sliceValues = {
        startIndex: 0,
        endIndex: stickyColumns.length + 1,
      };

      formRows.forEach((row) => {
        const columnSet = columns.slice(sliceValues.startIndex, sliceValues.endIndex);

        columnSet.forEach((column, index) => {
          if (index > 0) {
            const prevColumnWidth = widths[`column${index}`] || 0;
            const prevColumnOffset = widths[`offset${index}`] || 0;
            const prevColumnTotalWidth = prevColumnWidth + prevColumnOffset;

            column.style.left = `${prevColumnTotalWidth}px`;
            if (columnsHeads?.[index]) {
              columnsHeads[index].style.left = `${prevColumnTotalWidth}px`;
            }
          } else if (index == 0) {
            column.style.left = `${0}px`;
            columnsHeads[index].style.left = `${0}px`;
          }

          widths[`column${index + 1}`] = column.offsetWidth;
          widths[`offset${index + 1}`] = parseInt(column.style.left, 10) || 0;
        });

        sliceValues = {
          startIndex: sliceValues.endIndex - 1,
          endIndex: sliceValues.endIndex + stickyColumns.length,
        };
      });
    };

    updateColumnWidth();

    if (formRows.length == 1) {
      window.addEventListener('resize', updateColumnWidth);
    }

    return () => {
      window.removeEventListener('resize', updateColumnWidth);
    };
  }, [formRows]);

  // handle add row
  const handleAddRow = () => {
    let hasError = false;

    // Check existing formRows for missing fields
    for (let i = 0; i < formRows.length; i++) {
      const row = formRows[i];
      const isValidRow = validateFormTable(row, ['Category']).isValid;

      if (!isValidRow) {
        if (
          !isAccountSet &&
          validateFormTable(row, ['Category']).message.includes('Recipient')
        ) {
          toastError('Please validate recipient account');
        } else {
          toastError(validateFormTable(row, ['Category']).message);
        }

        hasError = true;
        break; // Exit loop if any row has an error
      }
    }

    if (hasError) {
      return;
    }

    setFormRows([
      {
        Recipient: '',
        'Account Number': '',
        'Bank Code': '',
        Source: null,
        currency: 'NGN',
        Amount: '',
        Category: '',
        Description: '',
      },
      ...formRows,
    ]);
  };

  //handle remove row
  const handleRemoveRow = (index) => {
    if (formRows.length === 1) {
      const updatedRows = [...formRows];
      updatedRows[index] = {
        Recipient: '',
        'Account Number': '',
        'Bank Code': '',
        Source: null,
        currency: 'NGN',
        Amount: '',
        Category: '',
        Description: '',
      };
      setFormRows(updatedRows);
      return;
    }
    const updatedRows = [...formRows];
    updatedRows.splice(index, 1);
    setFormRows(updatedRows);

    setSelectedRows([]);
    setSelectAll(false);
  };

  // handle input change
  const handleInputChange = (index, key, value) => {
    setCurrentIndex(index);
    const updatedRows = [...formRows];
    updatedRows[index][key] = value;
    setFormRows(updatedRows);
  };

  const addBatch = formRows?.map((item) => ({ ...item, batchName }));

  //continue to payment if duplicates
  const handleContinueDuplicate = () => {
    const updatedTableData = [...addBatch];

    setTableData(updatedTableData);
    setFormRows(updatedTableData);
    setIsUploaded(true);
    setPreview(true);
  };
  //

  // handle save
  const handleSave = () => {
    if (!batchName) return toastError('Please enter batch name');
    let hasError = false;

    // Check existing formRows for missing fields
    for (let i = 0; i < formRows.length; i++) {
      const row = formRows[i];
      const isValidRow = validateFormTable(row, ['Category']).isValid;

      if (!isValidRow) {
        if (
          !isAccountSet &&
          validateFormTable(row, ['Category']).message.includes('Recipient')
        ) {
          toastError('Please validate recipient account');
        } else {
          toastError(validateFormTable(row, ['Category']).message);
        }

        hasError = true;
        break; // Exit loop if any row has an error
      }
    }

    if (hasError) {
      return;
    }
    const findDuplicates = findDuplicateObjects(addBatch);

    if (findDuplicates?.duplicates?.length) {
      setOpenDuplicate(true);
      setDuplicates(findDuplicates);
    } else handleContinueDuplicate();
  };

  //account validation
  const accountNumber = formRows[currentIndex]?.['Account Number'];
  const bankCode = formRows[currentIndex]?.['Bank Code'];

  useEffect(() => {
    if (accountNumber?.length === 10 && bankCode) {
      dispatch(verifyBankAccount({ accountNumber, bankCode }));
    }
    if (accountNumber?.length < 10) {
      setIsAccountSet(false);

      const updatedRows = [...formRows];

      if (!updatedRows?.[currentIndex]) {
        updatedRows[currentIndex] = {};
      }

      if (updatedRows?.[currentIndex]?.['Recipient'])
        updatedRows[currentIndex]['Recipient'] = '';
      setFormRows(updatedRows);
    }
  }, [accountNumber, bankCode]);

  useEffect(() => {
    if (accountNameSuccess && accName.account_name) {
      let updatedRows = [...formRows];

      if (!updatedRows?.[currentIndex]) {
        updatedRows[currentIndex] = {};
      }

      if (!accountNameLoading)
        updatedRows[currentIndex]['Recipient'] = accName.account_name;
      setFormRows(updatedRows);
      setIsAccountSet(true);
    }

    if (accountNameError) {
      let updatedRows = [...formRows];
      if (updatedRows?.[currentIndex]?.['Recipient'])
        updatedRows[currentIndex]['Recipient'] = '';
      setFormRows(updatedRows);
      toastError('Account validation failed');
    }
  }, [accountNameSuccess, accountNameError, accountNameLoading]);
  //

  //table checkbox
  // select each row
  const handleRowSelection = (item) => {
    const isSelected = selectedRows.some((row) => isEqual(row, item));
    let updatedRows = [];

    if (isSelected) {
      updatedRows = selectedRows.filter((row) => !isEqual(row, item));
      setSelectAll(false); // Uncheck select all when deselecting an item
    } else {
      updatedRows = [...selectedRows, item];
      if (updatedRows.length === formRows?.length) {
        setSelectAll(true); // Check select all when selecting all items
      }
    }

    getSelected && getSelected(updatedRows);
    setSelectedRows(updatedRows);
  };

  // select all rows
  const handleSelectAll = () => {
    const allItems = formRows || [];
    if (!selectAll) {
      const remapWithID = allItems.map((item, index) => ({ ...item, id: index }));
      getSelected && getSelected(remapWithID);
      setSelectedRows(remapWithID);
    } else {
      getSelected && getSelected([]);
      setSelectedRows([]);
    }

    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  useEffect(() => {
    getSelected && getSelected(selectedRows);
  }, [selectedRows, getSelected]);

  //

  return (
    <Container className="px-0 pt-5 bulk-payment-page w-100">
      <div className="d-flex justify-content-between  align-items-center mb-3">
        <div>
          <h6>Type in details</h6>
          <p className="text-sm">Upload details at your convenience</p>
        </div>

        {/* <div className="search-input me-2">
          <Input
            className="input"
            placeholder="Search recipient"
            prefix={<SearchIcon stroke="#A9A29D" className="searchIcon" />}
            // value={searchVal}
            onChange={handleSearch}
          />
        </div> */}
      </div>

      <div ref={tableRef} className="w-100 table-wrapper position-relative">
        <Table
          ref={scrollableTableRef}
          className="custom-table-dimension bulk-payment-table w-100 overflow-x-scroll"
          responsive
        >
          <thead>
            <tr>
              <th
                className="position-sticky sticky-column text-sm font-medium"
                style={{ zIndex: 9 }}
              >
                <Checkbox
                  checked={selectedRows?.length === formRows?.length}
                  onChange={handleSelectAll}
                />
              </th>

              {columns.map((option, indx) => {
                return (
                  <React.Fragment key={indx}>
                    {stickyColumns.includes(option) ? (
                      <th
                        className="position-sticky sticky-column text-sm font-medium"
                        style={{ zIndex: 2 }}
                      >
                        {option}
                      </th>
                    ) : (
                      <th className="text-sm font-medium" style={{ zIndex: 2 }}>
                        {option}
                      </th>
                    )}
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody className="w-100">
            {formRows.map((row, index) => {
              return (
                <tr key={index}>
                  <td className="position-sticky sticky-column">
                    <Checkbox
                      checked={selectedRows?.some((rows) =>
                        isEqual(rows, { ...row, id: index }),
                      )}
                      onChange={() => handleRowSelection({ ...row, id: index })}
                    />
                  </td>
                  <td>
                    <CustomSelect
                      label=""
                      options={allBanks}
                      onMenuClose={onMenuCloseBanks}
                      onInputChange={handleGetBankOnChange}
                      isDisabled={loadingBanks && !bankValuedebounced}
                      isLoading={loadingBanks && !bankValuedebounced}
                      placeholder="Select bank"
                      className="w-100"
                      customStyles={{ control: { minWidth: 220 } }}
                      value={{ label: row['Bank Name'], value: row['Bank Code'] }}
                      onChange={(value) => {
                        handleInputChange(index, 'Bank Code', value.value);
                        handleInputChange(index, 'Bank Name', value.label);
                      }}
                      menuPortalTarget={menuPortalTarget}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label=""
                      placeholder="Enter number"
                      type="text"
                      style={{ minWidth: 130 }}
                      className="w-100"
                      value={row['Account Number']}
                      onChange={(e) =>
                        e.target.validity.valid &&
                        handleInputChange(index, 'Account Number', e.target.value)
                      }
                      maxLength="10"
                      pattern="[0-9]*"
                    />
                  </td>
                  <td className="position-sticky sticky-column">
                    <CustomInput
                      label=""
                      type="text"
                      disabled
                      className="w-100"
                      style={{ minWidth: 180 }}
                      value={row.Recipient}
                      onChange={(e) =>
                        handleInputChange(index, 'Recipient', e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <CustomSelectRadio
                      name={`budgets_${index}`}
                      placeholder="Select a source"
                      onChange={(value) => handleInputChange(index, 'Source', value)}
                      value={row.Source}
                      customStyles={{ control: { minWidth: 250 } }}
                      menuPortalTarget={menuPortalTarget}
                      isLoading={loadingBalances}
                      loadOptions={loadOptions}
                    />
                  </td>
                  <td>
                    <CustomInput
                      otherCurrency={false}
                      useCurrency={false}
                      isAmount
                      label=""
                      placeholder="Enter amount"
                      type="amount"
                      className="w-100"
                      style={{ minWidth: 150 }}
                      value={row.Amount}
                      onChange={(e) =>
                        handleInputChange(index, 'Amount', +e.target.rawValue)
                      }
                    />
                  </td>
                  <td>
                    <CategoryDropdown
                      placeholder="Select a category"
                      onChange={(value) =>
                        handleInputChange(index, 'Category', {
                          ...value,
                          name: value?.label,
                        })
                      }
                      value={row.Category}
                      name="category"
                      customStyles={{
                        control: { minWidth: 200 },
                        singleValue: { paddingLeft: '8px' },
                      }}
                      menuPortalTarget={menuPortalTarget}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label=""
                      placeholder="Enter description"
                      type="text"
                      style={{ minWidth: 230 }}
                      className="w-100"
                      value={row.Description}
                      onChange={(e) =>
                        handleInputChange(index, 'Description', e.target.value)
                      }
                    />
                  </td>
                  <td className="cancel position-sticky">
                    <button
                      style={{ height: 48, appearance: 'none' }}
                      className="bg-white p-1 w-100 d-flex btn align-items-center justify-content-center"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <CancelICon />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="w-100 batch-footer position-absolute d-flex gap-2 justify-content-end py-0 mt-2 pb-5">
          <ActiveButton
            text="Add another"
            variant="light"
            icon={<PlusDropDownIcon />}
            onClick={handleAddRow}
            customClass="add-button add-custom"
          ></ActiveButton>

          <CustomButton
            className="add-button button-dimension-fit-content"
            onClick={handleSave}
          >
            Save
          </CustomButton>
        </div>
      </div>

      <Modal
        show={openDuplicate}
        centered
        dialogClassName="custom-dialog batch-payment-confirm"
      >
        <BatchDuplicates
          onCancel={() => setOpenDuplicate(!openDuplicate)}
          description={
            <>
              <span className="fw-bolder">{duplicates?.total} duplicates</span> found if
              you want to continue confirm or cancel and go back to edit
            </>
          }
          onClick={handleContinueDuplicate}
          icon={<BankIcon />}
          title="Duplicate transactions found"
          transactions={duplicates?.duplicates}
        />
      </Modal>
    </Container>
  );
};

export default MultipleAccountUpload;
