import DeleteDialog from 'components/DeleteDialog';
import { subYears, format } from 'date-fns';
import BeneficiariesTableModal from 'pages/Beneficiaries/BeneficiariesTable/BeneficiariesTableModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { deleteBeneficiary } from 'redux/actions/BeneficiariesAction';
import {
  getMemberProfile,
  getProfileStats,
  getSingleUser,
} from 'redux/actions/MemberProfileAction';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import { RESET_BLOCK_CARD } from 'redux/reducers/CardReducer';
import { RESET_BLOCK_MEMBER_PROFILE } from 'redux/reducers/MemberProfileReducer';
import { RESET_BLOCK_REQUESTS } from 'redux/reducers/RequestsReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { buildBeneficiariesTableData, getColor, getFormattedDate } from 'utils/helper';
import BudgetsTab from './Components/BudgetsTab';
import CardTable from './Components/CardTable';
import ProfileComponent from './Components/ProfileComponent';
import RequestsTable from './Components/RequestsTable';
import TransactionTable from './Components/TransactionTable';
import dayjs from 'dayjs';
import { getBudgets } from 'redux/actions/BudgetsAction';

const MemberProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: memberId } = useParams();

  const [id, setId] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [bankInformation, setBankInformation] = useState([]);
  const [basicInformation, setBasicInformation] = useState([]);
  const [userInformation, setUserInformation] = useState({});
  const [informationBasedOnUserType, setInformationBasedOnUserType] = useState({
    userType: 'member',
    tabList: [
      {
        name: 'Transaction',
        key: 'transactions',
      },
      {
        name: 'Requests',
        key: 'requests',
      },
      {
        name: 'Cards',
        key: 'cards',
      },
      {
        name: 'Budgets',
        key: 'budgets',
      },
    ],
    pageLoading,
  });

  const [chartData, setChartData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [memberData, setMemberData] = useState(null);

  const {
    getMemberProfile: { loading, data: profileData = {} },
    getProfileStats: { loading: chartLoading, data: profileStats = {} },
    getSingleUser: { data: singleUser = {} },
  } = useSelector(({ memberProfile }) => memberProfile);

  const {
    deleteBeneficiaries: { loading: isDeleting, success: isDeletedSuccess },
    updateBeneficiaries: { success: isUpdateSuccess },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const today = new Date();
  const lastYearStartDate = subYears(today, 1);
  const lastYearEndDate = today;

  useEffect(() => {
    dispatch(getMemberProfile({ code: memberId }));
    dispatch(getBudgets({ status: 'active' }));
  }, []);

  useEffect(() => {
    // Profile stats endpoint is called last, it sets page loading to false when it's loading state is false
    // this is to ensure uniformity of loading when the page is first loaded
    if (!chartLoading) {
      setPageLoading(false);
    }
  }, [chartLoading]);

  useEffect(() => {
    setPageLoading(true);
  }, []);

  useEffect(() => {
    if (profileData?.user?.code) {
      dispatch(
        getProfileStats({
          payer: profileData.user.code,
          from: dayjs(lastYearStartDate).format('YYYY-MM-DD'),
          to: dayjs(lastYearEndDate).format('YYYY-MM-DD'),
        }),
      );
      dispatch(getSingleUser({ user: profileData.user.code }));
    }
  }, [profileData?.user?.code]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_MEMBER_PROFILE, blockType: 'getMemberProfile' });
      dispatch({ type: RESET_BLOCK_MEMBER_PROFILE, blockType: 'getProfileStats' });
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getTransaction' });
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'getRequest' });
      dispatch({ type: RESET_BLOCK_CARD, blockType: 'getCard' });
      dispatch({ type: RESET_BLOCK_BUDGET, blockType: 'getBudget' });
    };
  }, []);

  useEffect(() => {
    const { user, user: { team } = {}, created_at } = profileData;

    if (user) {
      setId(user.code);

      setUserInformation({
        name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
        type: 'Member',
        subText: user?.email,
        avatar: null,
        code: user?.code,
        canSendFunds: user.status === 'active' ? true : false,
        canEdit: user.status !== 'deleted' ? true : false,
        canDelete: user.status !== 'deleted' ? true : false,
      });

      setBasicInformation([
        {
          label: 'Status',
          value: user?.status,
          type: 'badge',
          color: getColor(user?.status),
        },
        {
          label: 'Name',
          value: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
          type: 'text',
        },
        { label: 'Email', value: user?.email ?? '-', type: 'text' },
        {
          label: 'Phone number',
          value: user?.phoneNumber?.internationalFormat
            ? `+${user?.phoneNumber?.internationalFormat}`
            : '-',
          type: 'text',
        },
        { label: 'Team', value: team?.name ?? '-', type: 'text' },
        { label: 'Assigned Role', value: user?.role?.name, type: 'text' },
        {
          label: 'Line Manager',
          value: user?.manager
            ? `${user?.manager?.firstName} ${user?.manager?.lastName ?? ''}`
            : '-',
          type: user?.manager ? 'has avatar' : 'text',
          avatar: null,
        },
        { label: 'Added On', value: getFormattedDate(created_at), type: 'text' },
      ]);
    }
  }, [profileData]);

  useEffect(() => {
    const { user: { firstName, lastName } = {} } = profileData;

    if (id && firstName) {
      setInformationBasedOnUserType({
        userType: 'member',
        tabList: [
          {
            name: 'Transaction',
            key: 'transactions',
            component: (
              <TransactionTable
                id={id}
                type={'payer'}
                userName={`${firstName ?? ''} ${lastName ?? ''}`}
              />
            ),
          },
          {
            name: 'Requests',
            key: 'requests',
            component: (
              <RequestsTable
                id={id}
                type={'user'}
                userName={`${firstName ?? ''} ${lastName ?? ''}`}
              />
            ),
          },
          {
            name: 'Cards',
            key: 'cards',
            component: (
              <CardTable
                id={id}
                type={'user'}
                userName={`${firstName ?? ''} ${lastName ?? ''}`}
              />
            ),
          },
          {
            name: 'Budgets',
            key: 'budgets',
            component: (
              <BudgetsTab
                id={id}
                type={'user'}
                userName={`${firstName ?? ''} ${lastName ?? ''}`}
              />
            ),
          },
        ],
        pageLoading,
      });
    }
  }, [profileData?.user?.firstName, profileData?.user?.lastName, id, pageLoading]);

  useEffect(() => {
    const { spent } = profileStats;

    if (spent) {
      let totalSpent = 0;

      let data = Object.values(spent)?.map((option) => {
        totalSpent += option.totalAmount;

        const year = option.year.toString();

        return {
          name: `${option.month} ${year.substring(year.length - 2, year.length)}`,
          spent: option.totalAmount / 100,
        };
      });

      setChartData({ totalSpent, data });
    }
  }, [profileStats]);

  useEffect(() => {
    const { bankAccounts } = singleUser;

    if (bankAccounts) {
      setBankInformation([
        {
          label: 'Bank account number',
          value: bankAccounts?.[0]?.number ?? '-',
        },
        {
          label: 'Routing number',
          value: bankAccounts?.[0]?.routingNumber ?? '-',
        },
        { label: 'Bank name', value: bankAccounts?.[0]?.bankName ?? '-' },
      ]);
    }
  }, [singleUser]);

  const handleDateRangeSelection = (from, to) => {
    dispatch(
      getProfileStats({
        payer: id,
        from: dayjs(from).format('YYYY-MM-DD'),
        to: dayjs(to).format('YYYY-MM-DD'),
      }),
    );
  };

  const handleDelete = () => {
    const code = profileData.code;
    dispatch(deleteBeneficiary({ code }));
  };

  const handleEdit = () => {
    setMemberData(buildBeneficiariesTableData([{ ...profileData }]));
  };

  useEffect(() => {
    if (isDeletedSuccess) {
      setDeleteModal(false);
      history.push('/teams/people');
    }
  }, [isDeletedSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(getMemberProfile({ code: memberId }));
    }
  }, [isUpdateSuccess]);

  return (
    <ProfileComponent
      basicInformation={basicInformation}
      bankInformation={bankInformation}
      informationBasedOnUserType={informationBasedOnUserType}
      chartData={chartData}
      handleDateRangeSelection={handleDateRangeSelection}
      userInformation={userInformation}
      onEdit={handleEdit}
      onDelete={() => {
        setDeleteModal(true);
      }}
      loading={pageLoading || loading}
      chartLoading={pageLoading || chartLoading}
    >
      {memberData && (
        <BeneficiariesTableModal
          selectBeneficiaries={memberData[0]}
          setSelectBeneficiaries={setMemberData}
        />
      )}

      {deleteModal && (
        <DeleteDialog
          title={`${'Delete member'}`}
          subTitle={`Are you sure you want to  delete this member?`}
          onCancel={() => {
            setDeleteModal(false);
          }}
          onDelete={handleDelete}
          deleteText={'Delete'}
          isLoading={isDeleting}
        />
      )}
    </ProfileComponent>
  );
};
export default MemberProfile;
