export const GET_SPEND_REQUEST = 'GET_SPEND_REQUEST';
export const GET_SPEND_SUCCESS = 'GET_SPEND_SUCCESS';
export const GET_SPEND_ERROR = 'GET_SPEND_ERROR';

export const GET_SPENDS_REQUEST = 'GET_SPENDS_REQUEST';
export const GET_SPENDS_SUCCESS = 'GET_SPENDS_SUCCESS';
export const GET_SPENDS_ERROR = 'GET_SPENDS_ERROR';

export const GET_SPEND_TRANSACTIONS_REQUEST = 'GET_SPEND_TRANSACTIONS_REQUEST';
export const GET_SPEND_TRANSACTIONS_SUCCESS = 'GET_SPEND_TRANSACTIONS_SUCCESS';
export const GET_SPEND_TRANSACTIONS_ERROR = 'GET_SPEND_TRANSACTIONS_ERROR';

export const GET_SPEND_RECEIPTS_REQUEST = 'GET_SPEND_RECEIPTS_REQUEST';
export const GET_SPEND_RECEIPTS_SUCCESS = 'GET_SPEND_RECEIPTS_SUCCESS';
export const GET_SPEND_RECEIPTS_ERROR = 'GET_SPEND_RECEIPTS_ERROR';

export const CANCEL_SPEND_REQUEST = 'CANCEL_SPEND_REQUEST';
export const CANCEL_SPEND_SUCCESS = 'CANCEL_SPEND_SUCCESS';
export const CANCEL_SPEND_ERROR = 'CANCEL_SPEND_ERROR';

export const DELETE_SPEND_REQUEST = 'DELETE_SPEND_REQUEST';
export const DELETE_SPEND_SUCCESS = 'DELETE_SPEND_SUCCESS';
export const DELETE_SPEND_ERROR = 'DELETE_SPEND_ERROR';

export const SPEND_STATISTICS_REQUEST = 'SPEND_STATISTICS_REQUEST';
export const SPEND_STATISTICS_SUCCESS = 'SPEND_STATISTICS_SUCCESS';
export const SPEND_STATISTICS_ERROR = 'SPEND_STATISTICS_ERROR';

export const RESET_BLOCK_SPEND = 'RESET_BLOCK_SPEND';

export const RESET_FLAGS_SPEND = 'RESET_FLAGS_SPEND';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getSpends: { ...block, data: {} },
  getSpend: { ...block, data: {} },
  getSpendStatistics: { ...block, data: {} },
  getSpendTransactions: { ...block, data: {} },
  getSpendReceipts: { ...block, data: [] },
  cancelSubscription: { ...block, data: [] },
  deleteSubscription: { ...block, data: [] },
};

export const SpendReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPEND_REQUEST:
      return { ...state, getSpend: { ...state.getSpends, loading: true } };
    case GET_SPEND_SUCCESS:
      return {
        ...state,
        getSpend: {
          ...state.getSpend,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SPEND_ERROR:
      return {
        ...state,
        getSpend: { ...state.getSpend, loading: false, error: action.error },
      };

    case DELETE_SPEND_REQUEST:
      return {
        ...state,
        deleteSubscription: { ...state.deleteSubscription, loading: true },
      };
    case DELETE_SPEND_SUCCESS:
      return {
        ...state,
        deleteSubscription: {
          ...state.deleteSubscription,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_SPEND_ERROR:
      return {
        ...state,
        deleteSubscription: {
          ...state.deleteSubscription,
          loading: false,
          error: action.error,
        },
      };

    case SPEND_STATISTICS_REQUEST:
      return {
        ...state,
        getSpendStatistics: { ...state.getSpendStatistics, loading: true },
      };
    case SPEND_STATISTICS_SUCCESS:
      return {
        ...state,
        getSpendStatistics: {
          ...state.getSpendStatistics,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SPEND_STATISTICS_ERROR:
      return {
        ...state,
        getSpendStatistics: {
          ...state.getSpendStatistics,
          loading: false,
          error: action.error,
        },
      };

    case CANCEL_SPEND_REQUEST:
      return {
        ...state,
        cancelSubscription: { ...state.cancelSubscription, loading: true },
      };
    case CANCEL_SPEND_SUCCESS:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CANCEL_SPEND_ERROR:
      return {
        ...state,
        cancelSubscription: {
          ...state.cancelSubscription,
          loading: false,
          error: action.error,
        },
      };

    case GET_SPEND_RECEIPTS_REQUEST:
      return { ...state, getSpendReceipts: { ...state.getSpendReceipts, loading: true } };
    case GET_SPEND_RECEIPTS_SUCCESS:
      return {
        ...state,
        getSpendReceipts: {
          ...state.getSpendReceipts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SPEND_RECEIPTS_ERROR:
      return {
        ...state,
        getSpendReceipts: {
          ...state.getSpendReceipts,
          loading: false,
          error: action.error,
        },
      };

    case GET_SPEND_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSpendTransactions: { ...state.getSpendTransactions, loading: true },
      };
    case GET_SPEND_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSpendTransactions: {
          ...state.getSpendTransactions,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SPEND_TRANSACTIONS_ERROR:
      return {
        ...state,
        getSpendTransactions: {
          ...state.getSpendTransactions,
          loading: false,
          error: action.error,
        },
      };

    case GET_SPENDS_REQUEST:
      return { ...state, getSpends: { ...state.getSpends, loading: true } };
    case GET_SPENDS_SUCCESS:
      return {
        ...state,
        getSpends: {
          ...state.getSpends,
          loading: false,
          success: true,
          error: '',
          data: action.data,
          meta: action.meta,
        },
      };
    case GET_SPENDS_ERROR:
      return {
        ...state,
        getSpends: { ...state.getSpends, loading: false, error: action.error },
      };

    //reset block with flag and data
    case RESET_BLOCK_SPEND:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
