export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const CREATE_ROLES_REQUEST = 'CREATE_ROLES_REQUEST';
export const CREATE_ROLES_SUCCESS = 'CREATE_ROLES_SUCCESS';
export const CREATE_ROLES_ERROR = 'CREATE_ROLES_ERROR';

export const DELETE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const EDIT_ROLE_NAME_REQUEST = 'EDIT_ROLE_NAME_REQUEST';
export const EDIT_ROLE_NAME_SUCCESS = 'EDIT_ROLE_NAME_SUCCESS';
export const EDIT_ROLE_NAME_ERROR = 'EDIT_ROLE_NAME_ERROR';

export const EDIT_PERMISSIONS_REQUEST = 'EDIT_PERMISSIONS_REQUEST';
export const EDIT_PERMISSIONS_SUCCESS = 'EDIT_PERMISSIONS_SUCCESS';
export const EDIT_PERMISSIONS_ERROR = 'EDIT_PERMISSIONS_ERROR';

export const RESET_BLOCK_ROLES = 'RESET_BLOCK_ROLES';
export const RESET_FLAGS_ROLES = 'RESET_FLAGS_ROLES';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getRoles: { ...block },
  createRole: { ...block },
  deleteRole: { ...block },
  editRoleName: { ...block },
  editPermissions: { ...block },
};

export const RolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_REQUEST:
      return { ...state, getRoles: { ...state.getRoles, loading: true } };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        getRoles: {
          ...state.getRoles,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ROLES_ERROR:
      return {
        ...state,
        getRoles: { ...state.getRoles, loading: false, error: action.error },
      };

    case CREATE_ROLES_REQUEST:
      return { ...state, createRole: { ...state.createRole, loading: true } };
    case CREATE_ROLES_SUCCESS:
      return {
        ...state,
        createRole: {
          ...state.createRole,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_ROLES_ERROR:
      return {
        ...state,
        createRole: { ...state.createRole, loading: false, error: action.error },
      };

    case DELETE_ROLE_REQUEST:
      return { ...state, deleteRole: { ...state.deleteRole, loading: true } };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRole: {
          ...state.deleteRole,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_ROLE_ERROR:
      return {
        ...state,
        deleteRole: { ...state.deleteRole, loading: false, error: action.error },
      };

    case EDIT_ROLE_NAME_REQUEST:
      return { ...state, editRoleName: { ...state.editRoleName, loading: true } };
    case EDIT_ROLE_NAME_SUCCESS:
      return {
        ...state,
        editRoleName: {
          ...state.editRoleName,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EDIT_ROLE_NAME_ERROR:
      return {
        ...state,
        editRoleName: { ...state.editRoleName, loading: false, error: action.error },
      };

    case EDIT_PERMISSIONS_REQUEST:
      return { ...state, editPermissions: { ...state.editPermissions, loading: true } };
    case EDIT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        editPermissions: {
          ...state.editPermissions,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EDIT_PERMISSIONS_ERROR:
      return {
        ...state,
        editPermissions: {
          ...state.editPermissions,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_ROLES:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_ROLES:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
