import {
  AlertCircle,
  CancelICon,
  DownloadArrowIcon,
  LoadingSpinner,
  TrashIcon,
  VerifiedIcon,
} from 'assets/icons';
import { ProgressBar } from 'react-bootstrap';
import { truncateMiddle } from 'utils/helper';
import pdfFileType from 'assets/images/pdf-filetype-icon.png';
import format from 'date-fns/format';

const UploadCardComponent = ({
  item,
  handleDeleteClick,
  handleRemove,
  type = 'not uploaded',
  truncateLength = 60,
  handleDownload,
  handleRetryUpload,
  hasPermission = true,
  handleViewDocument = null,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`border rounded-4 ${
        item?.status === 'failed' ? 'upload-error-border' : ''
      } pb-2 pt-3 px-3 mt-2`}
    >
      <div className="d-flex align-items-start justify-content-between mb-2">
        <div className="d-flex align-items-start gap-3">
          <img src={pdfFileType} />
          <div>
            <p
              onClick={handleViewDocument}
              className={`text-sm mb-1 ${handleViewDocument ? 'cursor' : ''} gray-500`}
            >
              {truncateMiddle(item.name, truncateLength)}
            </p>
            <div className="text-xs">
              {item?.size && (
                <>
                  {(item?.loading || item?.status === 'failed') && (
                    <span>{item.loadedBytes}kb of</span>
                  )}{' '}
                  <span>{Math.round(item.size / 1024)}kb</span> <span>∙</span>
                </>
              )}{' '}
              {type === 'uploaded' || !!item?.isExistingFile ? (
                <>
                  <VerifiedIcon className="align-text-top" />{' '}
                  <span>Uploaded {format(new Date(item.created_at), 'd/MM/yyyy')}</span>
                </>
              ) : item?.loading ? (
                <>
                  <LoadingSpinner /> <span>Uploading...</span>
                </>
              ) : item?.status === 'failed' ? (
                <>
                  <AlertCircle className="align-text-top" /> <span>Failed</span>
                </>
              ) : (
                <>
                  <VerifiedIcon className="align-text-top" /> <span>Completed</span>
                </>
              )}
            </div>
            {item?.status === 'failed' && (
              <div onClick={() => handleRetryUpload(item)}>
                <span className="upload-error">Try again</span>
              </div>
            )}
          </div>
        </div>
        <>
          {item?.loading ? (
            <div>
              <CancelICon
                style={{ width: '10px' }}
                onClick={() => handleRemove(item.property)}
              />
            </div>
          ) : item?.status === 'failed' ? (
            <div>
              <TrashIcon
                onClick={() => handleRemove(item.property)}
                width="16"
                height="16"
                stroke="#D92D20"
              />
            </div>
          ) : (
            <div>
              <DownloadArrowIcon
                onClick={() => handleDownload(item.assetCode ?? item.code)}
                width="16"
                height="16"
                className="me-3"
              />
              {hasPermission && (
                <TrashIcon
                  onClick={
                    type === 'uploaded' || type === 'straight upload'
                      ? () =>
                          handleDeleteClick('document', {
                            code: item.assetCode ?? item.code,
                            property: item?.property,
                          })
                      : () => handleRemove(item.property)
                  }
                  width="16"
                  height="16"
                />
              )}
            </div>
          )}
        </>
      </div>
      {type !== 'uploaded' && item?.loading && (
        <ProgressBar now={item.data ?? 0} label={`${item.data ?? 0}%`} visuallyHidden />
      )}
    </div>
  );
};

export default UploadCardComponent;
