import React, { useEffect, useState } from 'react';
import UploadDynamicFile from './UploadDynamicFile';
import PreviewUpload from './PreviewUpload';
import './styles.scss';

const CustomImport = ({
  file,
  setFile,
  bujetiColumns = [],
  tableColumns = [],
  submitData,
  label = '',
  duplicateColumns = [],
  loading,
  setRemoveFile,
}) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleCancel = () => {
    setTableData([]);
    setIsUploaded(false);
    if (setFile) setFile();
  };

  const handleCancelPreview = () => {
    setTableData([]);
  };

  useEffect(() => {
    if (!!tableData.length && !file) handleCancel();
  }, [file]);

  const confirmData = () => {
    const cleanData = tableData.map(({ id, modify, ...rest }) => ({ ...rest }));
    if (submitData) submitData(cleanData);
  };
  return (
    <>
      <UploadDynamicFile
        setRemoveFile={setRemoveFile}
        duplicateColumns={duplicateColumns}
        bujetiColumns={bujetiColumns}
        file={file}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        setTableData={setTableData}
        handleCancel={handleCancel}
        tableData={tableData}
        title="Map columns in your file to company properties"
        description="Each column header below should be mapped to a company property in Bujeti."
      />

      {tableData && (
        <PreviewUpload
          loading={loading}
          tableColumns={tableColumns}
          bujetiColumns={bujetiColumns}
          handleCancel={handleCancelPreview}
          data={tableData}
          title={`Review ${label} list`}
          setTableData={setTableData}
          confirmData={confirmData}
          description={`Review the list of ${tableData.length} ${label} to complete their import into Bujeti.`}
        />
      )}
    </>
  );
};

export default CustomImport;
