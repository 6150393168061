import ConfirmDialog from 'components/ConfirmDialog';
import CustomButton from 'components/UI/CustomButton';

const BatchDuplicates = ({
  icon,
  title,
  description,
  onCancel,
  onClick,
  transactions,
}) => {
  return (
    <ConfirmDialog onCancel={onCancel}>
      <span className="w-100">{icon}</span>

      <div className="my-4 w-100 heading-confirm">
        <h5>{title}</h5>
        <h6>{description}</h6>
      </div>

      <div className="w-100 heading-confirm">
        {transactions?.map((item, index) => (
          <div key={index} className="d-flex justify-content-between">
            <h5>{item?.data?.Recipient}</h5>
            <h6>x{item?.count}</h6>
          </div>
        ))}
      </div>

      <div className="mt-4 d-flex w-100 gap-3 pb-4">
        <CustomButton fullWidth className="border-btn text-sm" onClick={onCancel}>
          Back to edit
        </CustomButton>
        <CustomButton fullWidth className="confirm-btn text-sm" onClick={onClick}>
          Contiune
        </CustomButton>
      </div>
    </ConfirmDialog>
  );
};

export default BatchDuplicates;
