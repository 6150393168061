import {
  getCellProperty,
  getCharFromKey,
  isAlphaNumericKey,
  isKeyPrintable,
  isNavigationKey,
  keyCodes,
  TextCellTemplate,
} from '@silevis/reactgrid';
import Cleave from 'cleave.js/react';
import CurrencyFormat from 'react-currency-format';

export class AmountCellTemplate extends TextCellTemplate {
  getCompatibleCell(uncertainCell) {
    let cellValue = getCellProperty(uncertainCell, 'value', 'string');
    let value = cellValue === '-' ? 0 : cellValue.replace(/,/g, '') || 0;
    let text = cellValue == 0 || cellValue === '-' ? '' : cellValue;

    return { ...uncertainCell, value, text, cellValue };
  }

  isFocusable = (cell) => {
    if (cell.nonEditable) return false;
    return true;
  };

  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      return (
        <div>
          {!!cell.text ? (
            <>
              <span>{cell.currency}</span>
              <CurrencyFormat
                value={Number(cell.value).toFixed(2)}
                displayType="text"
                thousandSeparator={true}
              />
            </>
          ) : (
            <span>{cell.cellValue === '-' ? cell.cellValue : ''}</span>
          )}
        </div>
      );
    }
    return (
      <div className="d-flex flex-wrap align-items-center">
        <span className="text-sm">{cell.currency}</span>
        <Cleave
          className="custom-rg-input"
          options={{
            numeral: true,
            numeralDecimalMark: '.',
            numeralThousandsGroupStyle: 'thousand',
            numeralPositiveOnly: true,
          }}
          value={cell?.text}
          htmlRef={(ref) => ref && ref.focus()}
          onChange={(e) => {
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                value: e.target.value,
              }),
              false,
            );
          }}
          onBlur={(e) => {
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                value: e.target.value,
              }),
              !this.wasEscKeyPressed,
            );
            this.wasEscKeyPressed = false;
          }}
          onCopy={(e) => e.stopPropagation()}
          onCut={(e) => e.stopPropagation()}
          onPaste={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode)) {
              if (!cell.isValid(e.target.value + e.key)) {
                e.preventDefault();
                return;
              }
            }
            // Allow navigation keys and numbers
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) {
              e.stopPropagation();
            }
          }}
        />
      </div>
    );
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt, key) {
    const char = getCharFromKey(key);

    if (!ctrl && isKeyPrintable(key) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          value: cell.value === '-' ? char : cell.value + char,
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.text });
  }
}
