import cs from 'classnames';
import { getCard } from 'utils/helper';

import bujetiIconWhite from '../../assets/images/bujetiIcon-white.png';
import bujetiIcon from '../../assets/images/bujetiicon.png';
import wifiImg from '../../assets/images/wifi.png';

import Chip from '../../assets/icons/chip.png';
import './card.styles.scss';

const CardView = ({
  viewType,
  wrapperClass,
  isActive,
  handleSelect = () => null,
  card,
  cardType,
}) => {
  const {
    brand = '',
    user = {},
    cvv,
    pan,
    status,
    exp_month,
    exp_year,
    type,
    requiresPinChange,
  } = card;

  const active = card?.status === 'active' || card?.status === 'ACTIVE';
  const newPan = pan ? '•••• ' + pan.substr(-4) : '••••  ••••';

  const expiryMonth = exp_month?.length < 2 ? '0' + exp_month : exp_month;

  const statusTitle = requiresPinChange ? 'inactive' : status;
  const isPhysicalCard = cardType === 'physical';
  return (
    <div
      className={cs(
        'v-card card-bg',
        { ['isActive']: isActive },
        { [wrapperClass]: wrapperClass },
        { [type]: type !== undefined ? type : 'virtual' },
      )}
      onClick={() => handleSelect(card)}
    >
      <div className="d-flex justify-content-between">
        {viewType === 'view' && (
          <div className="d-flex gap-3">
            <img src={wifiImg} alt="vector" height={24} />

            <div className={`pill-holder ${cardType === 'physical' && 'is-physical'}`}>
              <span
                className="pill-dot"
                style={{ background: statusTitle === 'active' ? '#12B76A' : '#B54708' }}
              ></span>

              <span
                className={`${cardType === 'physical' && 'text-gray'}`}
                style={{ textTransform: 'capitalize' }}
              >
                {statusTitle}
              </span>
            </div>
          </div>
        )}

        <div className="d-flex flex-column" style={{ marginLeft: 'auto' }}>
          <img
            src={isPhysicalCard ? bujetiIconWhite : bujetiIcon}
            alt="bujeti"
            style={{ width: 15, height: 20 }}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <img src={Chip} alt="" style={{ width: 50, height: 34 }} />
      </div>
      {viewType !== 'view' && <div className="pan">{newPan}</div>}

      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column" style={{ width: '75%' }}>
            {viewType !== 'view' ? (
              <div className="d-flex align-items-center">
                <small>{`VALID UNTIL ${expiryMonth || '••'}/${exp_year || '••'}`}</small>
                {viewType !== 'view' && (
                  <small className="cvv">{`CVV ${cvv || '••'}`}</small>
                )}
              </div>
            ) : (
              <div className="user-holder">
                <span className="name">{`${user?.firstName} ${user?.lastName}`}</span>
                <small style={{ marginLeft: 'auto' }}>{`${expiryMonth || '••'}/${
                  exp_year || '••'
                }`}</small>
                {viewType !== 'view' && (
                  <small className="cvv">{`CVV ${cvv || '••'}`}</small>
                )}
              </div>
            )}

            <h2 className={` ${viewType === 'view' && 'pan small'}`}>
              {viewType === 'view' ? newPan : `${user?.firstName} ${user?.lastName}`}
            </h2>
          </div>

          <div className="card-name">
            {brand && <div className="modal-card">{getCard(brand?.toLowerCase())}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardView;
