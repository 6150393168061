import React from 'react';

import './styles.scss';

const CustomTextarea = React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      onChange,
      value,
      maxLength,
      rowSize = 5,
      defaultValue,
      charCount = 0,
      showCounter = false,

      ...props
    },
    ref,
  ) => {
    return (
      <div className="form-group">
        {label && (
          <div className="d-flex align-items-center justify-content-between ">
            <label className="textareaLabel form-label">{label}</label>
            {showCounter && (
              <span className="length-counter">{`${charCount}/${maxLength}`}</span>
            )}
          </div>
        )}
        <textarea
          ref={ref}
          className="form-control"
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
          rows={rowSize}
          defaultValue={defaultValue}
          {...props}
        ></textarea>
      </div>
    );
  },
);

export default CustomTextarea;
