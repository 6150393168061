import { ReactComponent as Trash } from 'assets/icons/trashcan.svg';
import AssetViewer from 'components/AssetViewer';
import DeleteDialog from 'components/DeleteDialog';
import CustomDrawer from 'components/UI/CustomDrawer';
import DrawerHeader from 'components/UI/CustomDrawer/components/DrawerHeader';
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteRequests,
  getRequests,
  getSingleRequest,
} from 'redux/actions/RequestsAction';
import { RESET_BLOCK_REQUESTS } from 'redux/reducers/RequestsReducer';
import RequestMoreDetails from '../ReviewerModal/RequestMoreDetails';
import ViewRequestDetails from './ViewRequestDetails';
import { Modal } from 'react-bootstrap';

const RequesterModal = ({ selectRequest, setSelectRequest }) => {
  const [step, setStep] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { requestCode } = useParams();
  const history = useHistory();

  const [assetViewer, setAssetViewer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [hideHeader, setHideHeader] = useState(false);

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);
  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const { requestData: { code = '', status, user: requestUser } = {} } =
    selectRequest || {};

  const createByMe = user?.code === requestUser?.code;

  const {
    updateRequest: { success: updateSuccess, error },
    deleteRequest: { loading: loadingDelete, success: successDelete },
    getSingleRequest: { data: singleData = {}, loading: singleLoading },
  } = useSelector(({ requests }) => requests);

  useEffect(() => {
    if (!singleLoading && selectRequest?.requestData?.code) {
      dispatch(getSingleRequest(selectRequest?.requestData?.code));
    }
  }, [selectRequest?.requestData?.code]);

  const toggleHandler = () => {
    setStep(step - 1);
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const setReceiptList = (list) => {
    setReceipts(list);
  };

  const handleClose = () => {
    setSelectedAsset(null);
    dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'requestReceipts' });
    if (requestCode) history.push('/requests/funds');
    if (isLoaded) {
      dispatch(getRequests({ view_as: 'reviewer' }));
    }
    if (step === 2 && !isLoaded) {
      toggleHandler();
    } else {
      setSelectRequest(null);
      setAssetViewer(false);
      dispatch({ type: RESET_BLOCK_REQUESTS, blockType: 'getSingleRequest' });
    }
    toggleAssetViewer(false);
    setIsLoaded(false);
  };

  useEffect(() => {
    if (successDelete) {
      handleClose();
    }
  }, [successDelete]);

  useEffect(() => {
    if (updateSuccess) {
      setIsLoaded(true);
      dispatch(getSingleRequest(selectRequest?.requestData?.code));
    }
    if (error) setIsLoaded(false);
  }, [updateSuccess]);

  if (!selectRequest) return <div />;
  const renderCard = () => {
    switch (step) {
      case 1:
        return (
          <ViewRequestDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            setAssetViewer={handleAssetViewer}
            selectRequest={selectRequest}
            handleClose={handleClose}
            getReceiptList={setReceiptList}
            hideHeader={setHideHeader}
          />
        );
      case 2:
        return (
          <RequestMoreDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            selectRequest={singleData?.data?.fundRequest}
            hideHeader={setHideHeader}
            userCode={user?.code}
          />
        );

      default:
        return;
    }
  };

  const attemptDelete = () => {
    setDeleting(!deleting);
  };

  const handleDelete = () => {
    dispatch(deleteRequests(code));
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          onClick={() => {
            handleTogglePopover();
            attemptDelete();
          }}
          className="actionLink text-danger svg-danger"
        >
          <Trash className="mr-4" /> <span>Delete request</span>
        </div>
      </div>
    );
  };

  const recipientName = selectRequest?.by?.value;

  return (
    <>
      <CustomDrawer
        show={(selectRequest && selectRequest) || (step === 2 && handleClose)}
      >
        {assetViewer && (
          <AssetViewer
            toggleVisibility={toggleAssetViewer}
            data={receipts}
            selectedAsset={selectedAsset}
            singleData={singleData?.data?.fundRequest}
          />
        )}
        <div className="content">
          {singleLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <Loading color="#d2b28b" size={32} />
            </div>
          ) : (
            <>
              {!hideHeader && (
                <DrawerHeader
                  handleClose={handleClose}
                  status={singleData?.data?.fundRequest?.status}
                  receipient={recipientName}
                  title="Request details"
                  moneyData={{
                    currency: selectRequest?.requestData?.currency,
                    amount: selectRequest?.requestData?.amount,
                  }}
                  popoverData={
                    ['pending', 'draft'].includes(status) &&
                    createByMe && {
                      action: <Actions />,
                      isPopoverOpen,
                      handleTogglePopover,
                    }
                  }
                />
              )}

              {renderCard()}
            </>
          )}
        </div>
      </CustomDrawer>
      {deleting && (
        <Modal show={deleting} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete reqeust`}
            subTitle="Are you sure you want to delete this request? This action cannot be undone"
            onCancel={() => setDeleting(false)}
            onDelete={handleDelete}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}
    </>
  );
};

export default RequesterModal;
