import { PlusOutlined } from '@ant-design/icons';
import cs from 'classnames';
import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import copy from 'copy-text-to-clipboard';
import './styles.scss';

const TransactionInfo = ({
  title,
  value,
  withIcon = false,
  titleClass = '',
  valueClass = '',
  removeIconBg,
  fullCursor,
  onTitleClick,
  isCopy = true,
  action,
  actionTitle,
  isLoading,
  renderProp,
  statusIcon,
  alignment,
  style,
  itemToCopy,
}) => {
  const styles = alignment === 'right' && 'd-flex flex-column align-items-end';
  return (
    <div className={cs('mt-4', { ['cursor']: fullCursor })}>
      <div
        className="d-flex align-items-center justify-content-between"
        style={style}
        onClick={onTitleClick}
      >
        <div className={cs('transaction-wrapper', styles)}>
          {statusIcon && <div className="mb-4">{statusIcon()}</div>}
          <p className={titleClass}>{title}</p>
          {renderProp ? (
            renderProp()
          ) : (
            <h3
              className={valueClass}
              style={style && { textAlign: 'right', textTransform: 'uppercase' }}
            >
              {value}
            </h3>
          )}
          {actionTitle && !value && (
            <div className="edit-category" onClick={action}>
              {isLoading ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                <div className="d-flex justify-content-center align-items-center gap-1">
                  <PlusOutlined />
                  {actionTitle}
                </div>
              )}
            </div>
          )}
        </div>
        {actionTitle && value && (
          <div className="edit-category" onClick={action}>
            {isLoading ? (
              <Loading color="#D28B28" size={20} />
            ) : (
              <div className="d-flex justify-content-center align-items-center gap-1">
                <PlusOutlined />
                {actionTitle}
              </div>
            )}
          </div>
        )}
        {withIcon && (
          <div
            className={cs('transaction-icon', { ['removeIconBg']: removeIconBg })}
            onClick={() => {
              if (isCopy) {
                copy(itemToCopy || value);
                toastSuccess(`Copied Successfully`);
              }
            }}
          >
            {withIcon}
          </div>
        )}
      </div>
    </div>
  );
};
export default TransactionInfo;
