import { Axios } from 'api/axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import {
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_ERROR,
  FETCH_BILLING_HISTORY_REQUEST,
  FETCH_BILLING_HISTORY_SUCCESS,
  FETCH_BILLING_HISTORY_ERROR,
  VIEW_PLAN_SUCCESS,
  VIEW_PLAN_ERROR,
  SUBSCRIBE_TO_PLAN_SUCCESS,
  SUBSCRIBE_TO_PLAN_ERROR,
  GET_COMPANY_SUBSCRIPTION_SUCCESS,
  GET_COMPANY_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_ERROR,
  CANCEL_PLAN_SUCCESS,
  CANCEL_PLAN_ERROR,
  GENERATE_RECEIPT_SUCCESS,
  GENERATE_RECEIPT_ERROR,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_ERROR,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
  ADD_BILLING_ADDRESS_SUCCESS,
  ADD_BILLING_ADDRESS_ERROR,
  GET_BILLING_ADDRESS_SUCCESS,
  GET_BILLING_ADDRESS_ERROR,
  VIEW_PLAN_REQUEST,
  SUBSCRIBE_TO_PLAN_REQUEST,
  GET_COMPANY_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_REQUEST,
  CANCEL_PLAN_REQUEST,
  GENERATE_RECEIPT_REQUEST,
  ADD_PAYMENT_METHOD_REQUEST,
  GET_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_REQUEST,
  ADD_BILLING_ADDRESS_REQUEST,
  GET_BILLING_ADDRESS_REQUEST,
  RESET_BLOCK_BILLING,
} from '../reducers/BillingReducer';
import { toastError, toastSuccess } from 'components/UI/toast';

async function fetchplans() {
  return await Axios.get('/billing/plans');
}

function* handleFetchAllPlans() {
  try {
    const response = yield call(fetchplans);
    if (response) {
      yield put({
        type: FETCH_PLANS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_PLANS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchBillingHistory() {
  return await Axios.get('/billing/history');
}

function* handleBillingHistory() {
  try {
    const response = yield call(fetchBillingHistory);
    if (response) {
      yield put({
        type: FETCH_BILLING_HISTORY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_BILLING_HISTORY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function viewPlanHistory(payload) {
  return await Axios.get(`/billing/plans/${payload}`);
}

function* handleViewPlan({ payload }) {
  try {
    const response = yield call(viewPlanHistory, payload);
    if (response) {
      yield put({
        type: VIEW_PLAN_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VIEW_PLAN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function subscribeToPlan(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/billing/plans/${code}/subscribe`, data);
}

function* handleSubscribeToPlan({ payload }) {
  try {
    const response = yield call(subscribeToPlan, payload);
    if (response) {
      yield put({
        type: SUBSCRIBE_TO_PLAN_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BILLING,
        blockType: 'subscribeToPlan',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SUBSCRIBE_TO_PLAN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCompanySubscription() {
  return await Axios.get('/billing/subscription-details');
}

function* handleGetCompanySubscription() {
  try {
    const response = yield call(getCompanySubscription);
    if (response) {
      yield put({
        type: GET_COMPANY_SUBSCRIPTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COMPANY_SUBSCRIPTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateSubscription(payload) {
  const { code, ...data } = payload;
  return await Axios.put(`/billing/${code}`, data);
}

function* handleUpdateSubscription({ payload }) {
  try {
    const response = yield call(updateSubscription, payload);
    if (response) {
      yield put({
        type: UPDATE_SUBSCRIPTION_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BILLING,
        blockType: 'updateSubscription',
      });

      yield put({
        type: GET_COMPANY_SUBSCRIPTION_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_SUBSCRIPTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function cancelPlan(payload) {
  const { code, ...data } = payload;
  return await Axios.patch(`/billing/${code}/cancel`, data);
}

function* handleCancelPlan({ payload }) {
  try {
    const response = yield call(cancelPlan, payload);
    if (response) {
      yield put({
        type: CANCEL_PLAN_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BILLING,
        blockType: 'cancelPlan',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CANCEL_PLAN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function generateReceipt(code) {
  return await Axios.get(`/billing/plans/${code}/receipt`);
}

function* handleGenerateReceipt({ payload }) {
  try {
    const response = yield call(generateReceipt, payload);
    if (response) {
      yield put({
        type: GENERATE_RECEIPT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GENERATE_RECEIPT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addPaymentMethod(payload) {
  return await Axios.post('/billing/payment-methods', payload);
}

function* handleAddPaymentMethod({ payload }) {
  try {
    const response = yield call(addPaymentMethod, payload);
    if (response) {
      yield put({
        type: ADD_PAYMENT_METHOD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_PAYMENT_METHOD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getPaymentMethod() {
  return await Axios.get('/billing/payment-methods');
}

function* handleGetPaymentMethod() {
  try {
    const response = yield call(getPaymentMethod);
    if (response) {
      yield put({
        type: GET_PAYMENT_METHOD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_PAYMENT_METHOD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deletePaymentMethod() {
  return await Axios.delete('/billing/history');
}

function* handleDeletePaymentMethod() {
  try {
    const response = yield call(deletePaymentMethod);
    if (response) {
      yield put({
        type: DELETE_PAYMENT_METHOD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_PAYMENT_METHOD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addBillingAddress(payload) {
  return await Axios.post('/billing/billing-addresses', payload);
}

function* handleAddBillingAddress({ payload }) {
  try {
    const response = yield call(addBillingAddress, payload);
    if (response) {
      yield put({
        type: ADD_BILLING_ADDRESS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_BILLING_ADDRESS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBillingAddress() {
  return await Axios.get('/billing/billing-addresses');
}

function* handleGetBillingAddress() {
  try {
    const response = yield call(getBillingAddress);
    if (response) {
      yield put({
        type: GET_BILLING_ADDRESS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BILLING_ADDRESS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_PLANS_REQUEST, handleFetchAllPlans),
  takeLatest(FETCH_BILLING_HISTORY_REQUEST, handleBillingHistory),
  takeLatest(VIEW_PLAN_REQUEST, handleViewPlan),
  takeLatest(SUBSCRIBE_TO_PLAN_REQUEST, handleSubscribeToPlan),
  takeLatest(GET_COMPANY_SUBSCRIPTION_REQUEST, handleGetCompanySubscription),
  takeLatest(UPDATE_SUBSCRIPTION_REQUEST, handleUpdateSubscription),
  takeLatest(CANCEL_PLAN_REQUEST, handleCancelPlan),
  takeLatest(GENERATE_RECEIPT_REQUEST, handleGenerateReceipt),
  takeLatest(ADD_PAYMENT_METHOD_REQUEST, handleAddPaymentMethod),
  takeLatest(GET_PAYMENT_METHOD_REQUEST, handleGetPaymentMethod),
  takeLatest(DELETE_PAYMENT_METHOD_REQUEST, handleDeletePaymentMethod),
  takeLatest(ADD_BILLING_ADDRESS_REQUEST, handleAddBillingAddress),
  takeLatest(GET_BILLING_ADDRESS_REQUEST, handleGetBillingAddress),
]);
