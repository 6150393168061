import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import MoreActions from './MoreActions';

const FundCard = ({ setInsideModalClick, setFundStep }) => {
  const clickHandler = () => {
    setFundStep(2);
  };
  return (
    <div>
      <div className="fundsTitle mb-5">
        <h2 className="card-title w-100">Fund with card</h2>
      </div>
      <CustomInput
        label="Amount"
        type="number"
        isAmount
        setIsOtherPopoverOpen={setInsideModalClick}
        wrapperClass="mb-3"
      />
      {/*<div className="py-4">
        <hr style={{ background: '#b3b3b3' }} />
      </div>
      <p>Fund with</p>
      <div className="cardInfo-wrapper">
        <p>
          <b>Mastercard ••4221</b> <span> | EXP 02 / 26</span>
        </p>
        <CheckCircleFilled />
      </div>
      <div className="cardInfo-wrapper">
        <p>
          <b>VISA ••5549</b> <span> | EXP 09 / 24</span>
        </p>
      </div>
      <div className="cardInfo-wrapper">
        <p>
          <b>Use a new Card</b>
        </p>
      </div>*/}
      <CustomButton fullWidth className="mt-4 mb-4">
        Add funds
      </CustomButton>
      <MoreActions fundCardMessage={'Fund with Transfer'} handleClick={clickHandler} />
    </div>
  );
};
export default FundCard;
