import {
  GET_TRANSFERS_REQUEST,
  GET_PAYOUTS_REQUEST,
  GET_SINGLE_TRANSFERS_REQUEST,
  EXPORT_TRANSFERS_REQUEST,
  BULK_RETRY_TRANSACTIONS_REQUEST,
  CANCEL_TRANSACTION_REQUEST,
  EDIT_TRANSACTIONS_BY_ID_REQUEST,
  EXPORT_TRANSACTIONS_REQUEST,
  GET_ALL_TRANSACTION_ASSET,
  GET_FAILED_TRANSACTIONS_REQUEST,
  GET_SCHEDULED_TRANSACTIONS_REQUEST,
  GET_SELECTED_TRANSACTIONS_REQUEST,
  GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST,
  GET_SINGLE_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_REQUEST,
  RETRY_TRANSACTION_REQUEST,
  UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST,
  UPDATE_SCHEDULED_TRANSACTIONS_REQUEST,
  BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST,
  BULK_CANCEL_SCHEDULED_TRANSACTIONS_SUCCESS,
  BULK_CANCEL_SCHEDULED_TRANSACTIONS_ERROR,
  REQUERY_TRANSACTIONS_REQUEST,
  EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST,
  MASS_ASSIGN_TRANSACTION_REQUEST,
  GET_CUSTOMER_TRANSACTION_REQUEST,
  APPROVE_TRANSACTION_REQUEST,
  REQUEST_TRANSACTION_DETAILS_REQUEST,
  EXPORT_BATCH_TRANSACTIONS_REQUEST,
} from '../reducers/TransactionsReducer';

export const getTransaction = (payload) => ({ type: GET_TRANSACTIONS_REQUEST, payload });
export const approveTransaction = (payload) => ({
  type: APPROVE_TRANSACTION_REQUEST,
  payload,
});
export const requestTransactionDetails = (payload) => ({
  type: REQUEST_TRANSACTION_DETAILS_REQUEST,
  payload,
});
export const getSelectedTransaction = (payload) => ({
  type: GET_SELECTED_TRANSACTIONS_REQUEST,
  payload,
});
export const exportTransaction = (payload) => ({
  type: EXPORT_TRANSACTIONS_REQUEST,
  payload,
});
export const exportBatchTransaction = (payload) => ({
  type: EXPORT_BATCH_TRANSACTIONS_REQUEST,
  payload,
});
export const getSingleTransaction = (payload) => ({
  type: GET_SINGLE_TRANSACTIONS_REQUEST,
  payload,
});

export const getCustomerTransaction = (payload) => ({
  type: GET_CUSTOMER_TRANSACTION_REQUEST,
  payload,
});

export const massAssignTransaction = (payload) => ({
  type: MASS_ASSIGN_TRANSACTION_REQUEST,
  payload,
});
export const getTransfers = (payload) => ({ type: GET_TRANSFERS_REQUEST, payload });
export const getPayouts = (payload) => ({ type: GET_PAYOUTS_REQUEST, payload });

export const exportTransfers = (payload) => ({
  type: EXPORT_TRANSFERS_REQUEST,
  payload,
});

export const getSingleTransfer = (payload) => ({
  type: GET_SINGLE_TRANSFERS_REQUEST,
  payload,
});

export const editTransactionById = (payload) => ({
  type: EDIT_TRANSACTIONS_BY_ID_REQUEST,
  payload,
});

export const editBatchTransactionById = (payload) => ({
  type: EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST,
  payload,
});
export const retryTransaction = (payload) => ({
  type: RETRY_TRANSACTION_REQUEST,
  payload,
});
export const cancelTransaction = (payload) => ({
  type: CANCEL_TRANSACTION_REQUEST,
  payload,
});

export const getFailedTransactions = (payload) => ({
  type: GET_FAILED_TRANSACTIONS_REQUEST,
  payload,
});

export const bulkRetryTransactions = (payload) => ({
  type: BULK_RETRY_TRANSACTIONS_REQUEST,
  payload,
});

export const getAllTransactionAsset = (payload) => ({
  type: GET_ALL_TRANSACTION_ASSET,
  payload,
});

export const getScheduledTransactions = (payload) => ({
  type: GET_SCHEDULED_TRANSACTIONS_REQUEST,
  payload,
});

export const getSingleScheduledTransaction = (payload) => ({
  type: GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST,
  payload,
});

export const updateScheduledTransactions = (payload) => ({
  type: UPDATE_SCHEDULED_TRANSACTIONS_REQUEST,
  payload,
});

export const bulkCancelScheduledTransactions = (payload) => ({
  type: BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST,
  payload,
});

export const updateScheduledTransactionReceipt = (payload) => ({
  type: UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST,
  payload,
});

export const requeryTransaction = (payload) => ({
  type: REQUERY_TRANSACTIONS_REQUEST,
  payload,
});
