import { Axios } from 'api/axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import { toastError, toastSuccess } from 'components/UI/toast';
import {
  CREATE_SETTLEMENT_ACCOUNT_ERROR,
  CREATE_SETTLEMENT_ACCOUNT_REQUEST,
  CREATE_SETTLEMENT_ACCOUNT_SUCCESS,
  DELETE_SETTLEMENT_ACCOUNT_ERROR,
  DELETE_SETTLEMENT_ACCOUNT_REQUEST,
  DELETE_SETTLEMENT_ACCOUNT_SUCCESS,
  FETCH_SETTLEMENT_ACCOUNTS_ERROR,
  FETCH_SETTLEMENT_ACCOUNTS_REQUEST,
  FETCH_SETTLEMENT_ACCOUNTS_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_SETTLEMENT_ACCOUNT_OTP_ERROR,
  VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST,
  VERIFY_SETTLEMENT_ACCOUNT_OTP_SUCCESS,
} from '../reducers/SettlementAccount';

async function fetchSettlementAccounts({ payload = {} }) {
  return await Axios.get('/settlement-account', { params: payload });
}

function* handleFetchSettlement(payload) {
  try {
    const response = yield call(fetchSettlementAccounts, payload);
    if (response) {
      yield put({
        type: FETCH_SETTLEMENT_ACCOUNTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SETTLEMENT_ACCOUNTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createSettlementAccount({ payload }) {
  return await Axios.post('/settlement-account', payload);
}

function* handleCreateSettlement({ payload }) {
  try {
    const response = yield call(createSettlementAccount, { payload });
    if (response) {
      yield put({
        type: CREATE_SETTLEMENT_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_SETTLEMENT_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function verifySettlementAccountOtp({ payload }) {
  return await Axios.post('/settlement-account/verify-otp', payload);
}

function* handleVerifySettlementOtp({ payload }) {
  try {
    const response = yield call(verifySettlementAccountOtp, { payload });
    if (response) {
      yield put({
        type: VERIFY_SETTLEMENT_ACCOUNT_OTP_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_SETTLEMENT_ACCOUNT_OTP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteSettlementAccount({ payload }) {
  return await Axios.delete(`/settlement-account/${payload.code}`);
}

function* handleDeleteSettlement({ payload }) {
  try {
    const response = yield call(deleteSettlementAccount, { payload });
    if (response) {
      yield put({
        type: DELETE_SETTLEMENT_ACCOUNT_SUCCESS,
        data: response.data,
      });
      yield put({
        type: FETCH_SETTLEMENT_ACCOUNTS_REQUEST,
      });
      toastSuccess('Bank account deleted successfully');
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_SETTLEMENT_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export async function verifyAccount(payload) {
  return await Axios.post(`/banks/verify/account`, payload);
}
function* handleVerifyAccount({ payload }) {
  try {
    const response = yield call(verifyAccount, payload);
    if (response) {
      yield put({
        type: VERIFY_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: VERIFY_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_SETTLEMENT_ACCOUNTS_REQUEST, handleFetchSettlement),
  takeLatest(CREATE_SETTLEMENT_ACCOUNT_REQUEST, handleCreateSettlement),
  takeLatest(VERIFY_SETTLEMENT_ACCOUNT_OTP_REQUEST, handleVerifySettlementOtp),
  takeLatest(DELETE_SETTLEMENT_ACCOUNT_REQUEST, handleDeleteSettlement),
  takeLatest(VERIFY_ACCOUNT_REQUEST, handleVerifyAccount),
]);
