import CardView from 'components/CardView';
import {
  CardStatusType,
  CardType,
  CurrencyType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import TopBar from 'components/TopBar';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSelectedCard } from 'redux/actions/CardAction';
import CardsModal from '../../Cards/CardsFormModal';
import CardsTableModal from '../../Cards/CardsTableModal';

const TeamCard = ({ budget }) => {
  const dispatch = useDispatch();
  const { teamsId } = useParams();
  const [selectCards, setSelectCards] = useState(null);
  // const [isFiltered, setIsFiltered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);
  const [isFillData, setIsFillData] = useState(false);
  const [filterData, setFilterData] = useState([
    ...CardType,
    ...CardStatusType,
    ...CurrencyType,
  ]);

  const {
    teamOverview: { data: { team: teamData = {} } = {} },
  } = useSelector(({ teams }) => teams);

  const { members = [] } = teamData;

  const {
    getSelectedCard: { data: selectedCard = {}, loading },
  } = useSelector(({ card }) => card);
  const { cards = [] } = selectedCard;

  const toggleHandler = () => {
    if (!isOtherPopoverOpen) return setIsOpen(!isOpen);
  };

  const handleCardClick = (row) => {
    setSelectCards(row);
  };

  useEffect(() => {
    if (cards?.length) {
      if (!isFillData) {
        setIsFillData(true);
        const allCardUser = cards.map((c) => {
          return {
            value: c.user?.code,
            label: `${c.user?.firstName} ${c.user?.lastName}`,
            isSelected: false,
          };
        });
        const uniqueArray = allCardUser.filter(
          (v, i, a) => a.findIndex((v2) => v2.code === v.code) === i,
        );
        setFilterData([...filterData, { title: 'Owner/holder', list: uniqueArray }]);
      }
    }
  }, [cards]);

  const handleFilterApply = (query) => {
    dispatch(getSelectedCard({ team: teamsId, ...query }));
    // setIsFiltered(!!Object.keys(query).length);
  };

  const handleOtherPopover = (val) => {
    setIsOtherPopoverOpen(val);
  };

  // if (loading)
  //   return (
  //     <div className="mt-200">
  //       <Loading color="#D28B28" />
  //     </div>
  //   );

  const renderNoData = () => {
    return (
      <div className="tabinnerWrapper mt-5">
        <NoData
          headerText="No team cards yet"
          buttonLabel="Create your first card"
          bodyText="Create a new virtual card - Request or issue a new physical card - Set up spending restrictions"
          onClickHandler={toggleHandler}
        />
      </div>
    );
  };
  const renderCard = (card, index) => {
    return (
      <Col md={6} lg={4} className="mb-3" key={`card-${index}`}>
        <CardView
          card={card}
          isActive={card.type !== 'physical'}
          handleSelect={handleCardClick}
        />
      </Col>
    );
  };
  const renderCards = () => {
    return (
      <div>
        <PendingOnboardingNotice />

        <TopBar
          addOnClick={toggleHandler}
          inputPlaceholder="Search by name "
          filterData={filterData}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          handleFilterApply={handleFilterApply}
          addIcon
          showFilter
        />
        <Row className="pt-4 mt-4">{cards.map(renderCard)}</Row>
      </div>
    );
  };
  return (
    <>
      <CardsModal
        IsOtherPopoverOpen={handleOtherPopover}
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        budget={budget}
        seletedBeneficiary={members}
        // onSave={beneficiariesAPI}
      />
      {cards.length ? renderCards() : renderNoData()}{' '}
      <CardsTableModal selectCards={selectCards} setSelectCards={setSelectCards} />
    </>
  );
};

export default TeamCard;
