import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PlusOutlined } from '@ant-design/icons';
import { DeleteIcon, EditIcon } from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog/index';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import ApprovalRuleEmptyStateData from 'pages/Transactions/approvalRuleEmptyStateData';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  deleteApprovers,
  fetchApprovalRuleActivity,
  fetchApprovalRules,
} from 'redux/actions/ApprovalAction';
import { buildApprovalRulesTableData } from 'utils/helper';
import { approvalColumns } from 'utils/mockData';
import SearchLoader from './Components/SearchLoader';

const CategoryApprovalRules = ({
  ruleCode,
  handleSelect = () => null,
  filterBy = null,
  categoryCode,
  categoryData,
  tabKey,
}) => {
  const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = useState(null);
  const [approvalLevelDelete, setDeleteApprovalLevel] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({ category: categoryCode });
  // const [selectedRuleCode, setSelectedRuleCode] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const {
    fetchApprovalRules: { data: { rules: data = [], meta = {} } = {}, loading },
    deleteApprovers: { loading: isDeleting, success: isDeleted },
    deleteApprovalLevel: { success: approvalLevelDeleted, error: isDeleteFailed },
  } = useSelector(({ approval }) => approval);

  const { page, total, hasMore, perPage, nextPage } = meta;

  useEffect(() => {
    if (categoryCode && tabKey === 'approval-rules') {
      dispatch(fetchApprovalRules({ category: categoryCode }));
    }
  }, [categoryCode]);

  const onShowDeleteModal = (value) => {
    if (value.status.value === 'deleted') return;
    setSelectedRule({ name: value.name, approvalData: value?.approvalData });
    setDeleteOpen(true);
  };

  const onHandleDeleteApproval = () => {
    setDeleteOpen(true);
    dispatch(deleteApprovers(selectedRule?.approvalData.code));
  };

  const onHandleEditModal = (value) => {
    if (value.status.value === 'deleted') return;
    dispatch(
      fetchApprovalRuleActivity({
        selectedRuleCode: value.approvalData.code,
        selectedRule: value,
      }),
    );
    history.push(
      `/compliances/categories/${categoryCode}/approval-rules/edit/${value.approvalData.code}`,
    );
  };

  const toggleHandler = () => {
    history.push({
      pathname: `/compliances/categories/${categoryCode}/approval-rules/create`,
      state: {
        trigger: 'category',
        operator: 'is',
        operands: [
          {
            category: {
              name: categoryData?.name,
              code: categoryData?.code,
              isFixed: true,
            },
          },
        ],
      },
    });
  };

  const rows = buildApprovalRulesTableData(data);
  useEffect(() => {
    if (ruleCode) {
      const element = rows.find(({ code }) => code === ruleCode);
      dispatch(
        fetchApprovalRuleActivity({ selectedRuleCode: ruleCode, selectedRule: element }),
      );
    }
  }, [ruleCode]);

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchApprovalRules({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchApprovalRules({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
      // history.replace({ state: {} });
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (approvalLevelDeleted) {
      setDeleteApprovalLevel(false);
    }
  }, [approvalLevelDeleted]);

  useEffect(() => {
    if (isDeleteFailed) {
      setDeleteApprovalLevel(false);
    }
  }, [isDeleteFailed]);

  const handlePreviousPage = (page) => {
    dispatch(fetchApprovalRules({ category: categoryCode, perPage, page }));
  };

  const handleNextPage = (page) => {
    dispatch(fetchApprovalRules({ category: categoryCode, perPage, page }));
  };

  useEffect(() => {
    if (!isDeleting && isDeleted) {
      setDeleteOpen(null);
    }
  }, [isDeleting, isDeleted]);

  useEffect(() => {
    if (!isDeleting && isDeleteFailed) {
      setDeleteOpen(null);
    }
  }, [isDeleting, isDeleteFailed]);

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (type === 'edit') onHandleEditModal(data);
    if (type === 'delete') onShowDeleteModal(data);
    setIsPopoverOpen(true);
  };

  const Actions = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', selectedData)}
        >
          <EditIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Edit rule
        </div>

        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', selectedData)}
        >
          <DeleteIcon className="mr-4" width={15} height={16} /> Delete rule
        </div>
      </div>
    );
  };

  const show = !!data?.length || (filtered && !data?.length);

  return (
    <>
      {loading && !filtered ? (
        <SearchLoader hasFilter={false} hasAddbtn={true} />
      ) : (
        <TopBar
          showBarSearch={show}
          addIcon={show}
          customAddButton={
            <button onClick={toggleHandler} className="add-button add-action">
              <PlusOutlined
                style={{
                  verticalAlign: 0,
                  fontSize: 14,
                  paddingLeft: 5,
                }}
              />
              <span className="ms-1">Create new rule</span>
            </button>
          }
          addOnClick={toggleHandler}
          // showFilter
          withOutSearch
          // withDate
          inputPlaceholder="Search rule"
          searchVal={search}
          setSearchVal={setSearch}
        />
      )}
      {!data.length && !loading ? (
        <div>
          {isFiltered.current ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText={`You have no approval rule for this filter`}
                bodyText="Alter filter to see approval rules"
                withButton={true}
                buttonLabel="View all approval rules"
                onClickHandler={() =>
                  dispatch(fetchApprovalRules({ category: categoryCode }))
                }
              />
            </div>
          ) : (
            <ApprovalRuleEmptyStateData
              handleSelect={handleSelect}
              toggleHandler={toggleHandler}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={approvalColumns}
                  data={rows}
                  pagination
                  onRowClick={onHandleEditModal}
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  loading={loading}
                  hasAvatar={false}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
      {deleteOpen && (
        <DeleteDialog
          title={`Delete ${selectedRule.name} Rule`}
          subTitle="Are you sure you want to delete this rule? This action cannot be undone."
          onCancel={() => setDeleteOpen(false)}
          onDelete={onHandleDeleteApproval}
          styles={{ width: 400 }}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

export default CategoryApprovalRules;
