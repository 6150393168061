import { ArrowLeft, Download02, HintAlertIcon } from 'assets/icons';
import CustomImport from 'components/UI/CustomImport';
import ClientFileUpload from 'components/UI/FileUpload/ClientFileUpload';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createBulkCategories } from 'redux/actions/CategoryAction';

const tableColumns = [
  {
    Header: 'Category name',
    accessor: 'name',
    type: 'string',
    sample: 'Miscellaneous',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    sample: 'This category is to track the miscellaneous expenses of the company',
  },
  {
    Header: 'Limit',
    accessor: 'limit',
    type: 'string',
    sample: '7000',
  },
  {
    Header: '',
    accessor: 'modify',
    type: 'modify',
    withEdit: true,
    withDelete: true,
  },
];

const customerTemplate =
  'https://bujeti-public.s3.eu-west-1.amazonaws.com/Bujeti_Categories_Template.xlsx';

const UploadMultipleCategories = () => {
  const [file, setFile] = useState();

  const { push } = useHistory();
  const dispatch = useDispatch();
  const {
    createBulkCategories: { loading, success },
  } = useSelector(({ categories }) => categories);

  const bujetiColumns = tableColumns
    .filter((item) => item.Header != '')
    .map((item) => ({
      column: item.Header,
      sample: item.sample,
      accessor: item.accessor,
    }));

  useEffect(() => {
    if (success) push('/compliances/categories');
  }, [success]);

  // createBulkCategories
  const submitHandler = (data) => {
    const payload = data?.map((item) => {
      const { limit } = item;

      return {
        ...item,
        limit: !limit || isNaN(limit) ? undefined : Number(limit) * 100,
      };
    });

    dispatch(createBulkCategories({ categories: payload }));
  };

  return (
    <section className="component_page-section">
      <div className="elements-section">
        <div className="back-btn" onClick={() => push('/compliances/categories')}>
          <ArrowLeft color="#D28B28" /> <div>Back</div>
        </div>
        <div className="section-title">
          <h1>Import categories from CSV</h1>
          <p>
            Upload your CSV to kickstart bulk categories data import. Our smart upload
            will parse your file and map fields for a seamless upload experience.
          </p>
        </div>

        <div className="alert_action-banner d-flex gap-3">
          <div className="m-0 p-0">
            <HintAlertIcon width={20} height={20} />
          </div>

          <div>
            <div className="banner-text">
              <h1>
                No CSV file? No problem. Download our sample template to get started.
              </h1>
              <p>
                Get a head start by downloading our pre-formatted CSV template. Simply add
                your contacts to the template or use it as a guide to format your existing
                spreadsheet for a flawless import.
              </p>
            </div>
            <div className="actions">
              <a
                href={customerTemplate}
                className="text-decoration-none text-reset btn xxs"
                download
                rel="noreferrer"
              >
                Download template <Download02 />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <ClientFileUpload
            label="File upload"
            supportType="CSV, and XLS formats, up to 5 MB."
            acceptedFile={{ 'text/html': ['.xlsx', '.csv'] }}
            onChange={(files) => {
              setFile(files.file);
            }}
          />
        </div>

        <CustomImport
          loading={loading}
          file={file}
          setFile={setFile}
          bujetiColumns={bujetiColumns}
          tableColumns={tableColumns}
          submitData={submitHandler}
          duplicateColumns={['name']}
          label="catgeories"
        />
      </div>
    </section>
  );
};

export default UploadMultipleCategories;
