import { PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { CsvIcon, PdfIcon02 } from 'assets/icons';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { toastSuccess } from 'components/UI/toast';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildExportStatementData,
  buildExportStatementQuickBooks,
  buildExportStatementXero,
  capitalizeFirstLetter,
} from 'utils/helper';
import { columnsTransactionQuickBooks, columnsTransactionXero } from 'utils/mockData';
import { formatNumThousandSeparator } from 'utils/utility';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';
import AccountStatementTable from './AccountStatementTable';
import DownloadExcelStatement from './DownloadExcelStatement';
import DownloadPdfStatement from './DownloadPdfStatement';

const AccountStatement = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const querys = {
    source: searchParams.get('source'),
    type: searchParams.get('type'),
    startDate: searchParams.get('startDate'),
    endDate: searchParams.get('endDate'),
  };

  const { downloadStatement: { data: { transactions = [], summary = {} } = {} } = {} } =
    useSelector(({ accountStatement }) => accountStatement);

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const forSpecificBusiness = ['cmp_T238e2bV5kFUCvNXN'].includes(companyData.code);
  const source =
    querys.source.startsWith('bdg') && transactions.length
      ? transactions[0].source
      : querys.source;

  const { user: { data = {} } = {} } = useSelector(({ auth }) => auth);
  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const { permissions } = allPermissions();
  const canExport = hasPermission({
    permissions,
    scopes: ['statement-export', 'statement-*'],
  });

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const generatePdfSuccess = async (event, instance) => {
    event?.stopPropagation();
    if (instance.loading || !instance.url) return;
    toastSuccess('Account statement downloaded');
  };

  const tableColumns = [
    { value: 'Date', width: '62px', marginRight: '12px' },
    { value: 'Amount', width: '100px', marginRight: '6px' },
    { value: 'From/To/Description', width: '275px', marginRight: '12px' },
    { value: 'Balance After', width: '100px' },
  ];

  const tableData = transactions.map((item) => {
    const credit = item.type.toLowerCase() === 'credit' && item?.amount;
    const debit = item.type.toLowerCase() === 'debit' && item?.amount;

    const currency = item?.currency || 'NGN';

    const bodyData = {
      date: item?.created_at ? dayjs(item.created_at).format('DD/MM/YYYY') : '-',
      description: item?.description ?? '',
      amount: item?.amount
        ? `${currency} ${formatNumThousandSeparator(Math.abs(item.amount) / 100)}`
        : '',
      type: capitalizeFirstLetter(item?.type),
      source: item?.source,
      from: item?.from ?? '',
      to: item?.to ?? '',
      balanceAfter: item?.balanceAfter
        ? `${currency} ${formatNumThousandSeparator(item.balanceAfter / 100)}`
        : '',
    };

    return bodyData;
  });

  useEffect(() => {
    return () => {
      if (location?.state?.canExport) {
        history.replace({ state: {} });
      }
    };
  }, []);

  const csvData = [
    [
      'Date',
      'Description',
      'Amount(Credit/Debit)',
      'Type',
      'Source',
      'From',
      'To',
      'Balance after',
    ],
    ...buildExportStatementData(transactions),
  ];

  const csvDataXero = [
    columnsTransactionXero.map((item) => item.Header),
    ...buildExportStatementXero(transactions),
  ];

  const csvDataQuickBooks = [
    columnsTransactionQuickBooks.map((item) => item.Header),
    ...buildExportStatementQuickBooks(transactions),
  ];

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <CSVLink
          data={csvData}
          onClick={handleButtonToggle}
          className="actionLink statement-download-action"
          filename={`statement-${source}-${querys.type}${
            querys.startDate && querys.endDate
              ? '-' + querys.startDate + '-' + querys.endDate
              : ''
          }.csv`}
        >
          <CsvIcon height="16" width="16" />
          Download as CSV
        </CSVLink>
        <DownloadExcelStatement handleButtonToggle={handleButtonToggle} />
        <PDFDownloadLink
          document={
            <DownloadPdfStatement
              querys={querys}
              source={source}
              data={data}
              companyData={companyData}
              tableData={tableData}
              tableColumns={tableColumns}
              summary={summary}
            />
          }
          fileName={`statement-${source}-${querys.type}${
            querys.startDate && querys.endDate
              ? '-' + querys.startDate + '-' + querys.endDate
              : ''
          }.pdf`}
          style={{
            textDecoration: 'none',
            height: 40,
            display: 'flex',
            width: '100%',
            fontFamily: 'Inter var !important',
            color: '#212529',

            alignItems: 'center',
          }}
          className="actionLink"
          onClick={(event, instance) => {
            generatePdfSuccess(event, instance);
          }}
        >
          <PdfIcon02 height="16" width="16" />
          Download as PDF
        </PDFDownloadLink>
        <CSVLink
          data={csvDataXero}
          onClick={handleButtonToggle}
          className="actionLink statement-download-action"
          filename={`statement-${querys.type}${
            querys.startDate && querys.endDate
              ? '-' + querys.startDate + '-' + querys.endDate
              : ''
          }-xero.csv`}
        >
          <CsvIcon height="16" width="16" />
          Download for Xero
        </CSVLink>
        <CSVLink
          data={csvDataQuickBooks}
          onClick={handleButtonToggle}
          className="actionLink statement-download-action"
          filename={`statement-${querys.type}${
            querys.startDate && querys.endDate
              ? '-' + querys.startDate + '-' + querys.endDate
              : ''
          }-quickbooks.csv`}
        >
          <CsvIcon height="16" width="16" />
          Download for Quickbooks
        </CSVLink>
      </div>
    );
  };

  return (
    <>
      <div className="people-wrapper account-statement-wrapper">
        <TopBar
          headerText="Account Statement"
          isRightBar
          // dropDownBtn={canExport && <DownloadPdfStatement />}
          dropDownBtn={
            <CustomPopover
              zIndex="1"
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
              content={<Actions />}
            >
              <CustomButton className="add-button" onClick={handleButtonToggle}>
                Download statement <DownArrow />
              </CustomButton>
            </CustomPopover>
          }
          addButton={canExport || location?.state?.canExport}
        />

        <Container>
          <Row>
            <Col xs={12} className="mt-4">
              <AccountStatementTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AccountStatement;
