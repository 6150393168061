import classNames from 'classnames';
import React from 'react';
const LineStatus = ({ active, pending, exceeded = false }) => {
  return (
    <div className="lines">
      {
        // TODO Remember the color code we did for the app, so when below 50% it is green etc... do the same here please
        // <50% => Green; between 50 and 80% => Orange, above 80% => Red
      }
      <div className="disbursed-line" style={{ width: `${pending + active}%` }} />
      <div
        className={classNames(exceeded ? 'overdraft' : 'spent-line')}
        style={{ width: `${active}%` }}
      />
    </div>
  );
};
export default LineStatus;
