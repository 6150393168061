import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import PolicyTypeCard from './PolicyTypeCard';
import CustomSelect from 'components/UI/CustomSelect';
import CustomInput from 'components/UI/CustomInput';
import CustomDays from 'components/UI/CustomDays';
import { PlusIcon } from 'assets/icons';
import CurrencyFormat from 'react-currency-format';
import { getCurrency, isObjectEmpty, weekdays } from 'utils/helper';
import { RECEIPT_TRANSACTION_TYPE, weekends } from '../PolicyModal';
import { toastError } from 'components/UI/toast';
import ConfirmDialog from 'components/ConfirmDialog';
import { isArray } from 'lodash';

const PolicyTypeSelector = ({
  isEditing,
  policy,
  policyTypes,
  typesConstant,
  setIsOtherPopoverOpen,
  getPolicyTypesData,
  types,
  setTypes,
}) => {
  const [typeSelector, setTypeSelector] = useState(!isEditing);
  const [currentType, setCurrentType] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [secondOption, setSecondOption] = useState(false);
  const [completed, setIsCompleted] = useState(false);
  const [isDays, setIsDays] = useState([]);
  const [remove, setRemove] = useState(false);
  const [removePolicyModal, setRemovePolicyModal] = useState({
    visible: false,
    selected: null,
  });

  const CALENDAR_PERIODS = {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    weekends: ['Sat', 'Sun'],
  };

  const filterPolicyType = policyTypes.filter(
    (item) => item.code != typesConstant.INDIVIDUAL_ALLOCATION,
  );

  //This is to make the add another policy button show when types state is preserved
  //when creating a new policy and an error is thrown
  useEffect(() => {
    if (types.length > 0) setTypeSelector(false);
  }, []);

  const handleChange = ({ name, value }) => {
    if (checkPolicyAlreadyExist(value?.value))
      return toastError(`You can only add one ${value?.label}`);
    setCurrentType({
      ...currentType,
      label: value?.label,
      value: value?.value,
    });
    setTypeSelector(true);
    setSecondOption(true);
  };

  const handleChangeAmount = (data) => {
    const {
      target: { name, rawValue },
    } = data;
    setCurrentType({
      ...currentType,
      data: {
        ...currentType?.data,
        amount: rawValue,
      },
    });
  };

  const handleSecondValueChange = ({ name, value }) => {
    if (value?.value.toLowerCase() === 'weekends') {
      setIsDays([]);
    }
    setCurrentType({
      ...currentType,
      data: {
        ...currentType?.data,
        [name]: value,
      },
    });
  };

  const handleGetSign = (currency) => {
    setCurrentType({ ...currentType, currency });
  };

  const typesCode = types?.map((type) => type.value);
  const spendingLimitType = typesConstant?.SPENDING_LIMITS;
  const calendarLimitType = typesConstant?.CALENDAR_LIMITS;
  const receiptLimitType = typesConstant?.RECEIPT_POLICY;

  //this function major function is to extract the payload for each policy
  //and send it to the policyModal component for api payload
  const onHandleSaveTypes = () => {
    if (!Object.keys(currentType).length) return;
    if (isObjectEmpty(currentType))
      return toastError(`Please enter the complete data for ${currentType?.label}`);
    if (currentType.label.toLowerCase() === 'calendar limits') {
      const newTypes = { ...currentType };
      if (currentType.data.period.label.toLowerCase().includes('custom')) {
        newTypes.data.period.value = isDays;
      }
      setTypes([...types, newTypes]);
    } else {
      setTypes([...types, currentType]);
    }
    setTypeSelector(false);
    setCurrentType({});
    setIsDays([]);
    setSecondOption(false);
    if (!isEditing) {
      getPolicyTypesData(extractPolicyPayload(currentType));
    }
  };

  const onHandleEditSave = () => {
    const updatedItems = types?.map((item) => {
      if (item.label.toLowerCase() === 'calendar limits') {
        return {
          ...item,
          data: {
            period: {
              label: isDays?.length
                ? 'Custom'
                : currentType?.data?.period?.label ?? item?.data?.period?.label,
              value: currentType?.data?.period?.label?.toLowerCase()?.includes('custom')
                ? isDays
                : isArray(currentType?.data?.period?.value) ||
                  isArray(item?.data?.period?.value)
                ? currentType?.data?.period?.value ?? item?.data?.period?.value
                : CALENDAR_PERIODS[
                    currentType?.data?.period?.value ?? item?.data?.period?.value
                  ],
            },
          },
        };
      }
      if (item.value === currentType?.value) {
        return {
          ...item,
          ...currentType,
        };
      }

      return item;
    });
    if (!isEditing) {
      if (currentType?.label.toLowerCase() === 'calendar limits') {
        // setIsDays(currentType?.data?.period?.value);
        const newTypes = { ...currentType };
        if (currentType.data.period.label.toLowerCase().includes('custom')) {
          newTypes.data.period.value = isDays;
        }
        setTypes([...types, newTypes]);
      }
      getPolicyTypesData(extractPolicyPayload(currentType));
    }
    setTypes(updatedItems);
    setTypeSelector(false);
    setCurrentType({});
    setIsEdit(false);
    setSecondOption(false);
  };

  //generate the payload structure for each policy types
  //to fit into the old data structure for creating a policy
  const extractPolicyPayload = (data) => {
    if (data?.value === spendingLimitType) {
      return {
        amountRange: {
          maxAmount: data?.data?.amount * 100,
        },
        frequency: data?.data?.frequency.value,
        currency: data?.currency || data?.data?.currency,
      };
    } else if (data?.value === calendarLimitType) {
      return {
        period: data?.data?.period?.label?.includes('Custom')
          ? data?.data?.period?.value
          : CALENDAR_PERIODS[data?.data?.period?.value],
      };
    } else if (data?.value === receiptLimitType) {
      const receiptData = {
        receiptAmount: {
          condition: data?.data?.type?.value,
        },
        currency: data?.currency || data?.data?.currency,
      };
      if (data?.data?.type.value !== 'all') {
        receiptData.receiptAmount.value = data?.data?.amount * 100;
      }
      return receiptData;
    }
  };

  //this is to render the policyTypeCard by populating
  //the types array with the current data structure for each type of policy
  useEffect(() => {
    const handleLimitData = (limitType, policyType) => {
      if (!typesCode.includes(limitType) && policyType.includes(limitType)) {
        if (limitType === calendarLimitType) {
          setCalendarLimitsData(policy, policy?.type ? 'singular' : undefined);
        } else if (limitType === spendingLimitType) {
          setSpendingLimitsData(policy, policy?.type ? 'singular' : undefined);
        } else {
          setReceiptPolicyLimitsData(policy, policy?.type ? 'singular' : undefined);
        }
      }
    };

    if (isEditing) {
      if (!policy?.type && policy?.types.length) {
        const policyTypes = policy?.types?.map((type) => type.code);
        handleLimitData(calendarLimitType, policyTypes);
        handleLimitData(spendingLimitType, policyTypes);
        handleLimitData(receiptLimitType, policyTypes);
      } else {
        handleLimitData(spendingLimitType, policy.type.code);
        handleLimitData(calendarLimitType, policy.type.code);
        handleLimitData(receiptLimitType, policy.type.code);
      }
    }
  }, [isEditing, policy]);

  //the use of typeofPolicy here is to make it backward compatible
  //with the old policy that have been created before.

  const setCalendarLimitsData = (policy, typeOfPolicy = 'plural') => {
    const data = {};
    const calendarLimit =
      typeOfPolicy === 'plural'
        ? policy?.types?.find((item) => item.code === calendarLimitType)
        : policy?.type;
    data.label = calendarLimit?.name;
    data.value = calendarLimit?.code;

    const period = JSON.stringify(policy?.period);

    const isWeekends = JSON.stringify(CALENDAR_PERIODS?.weekends) === period;
    const isWeekdays = JSON.stringify(CALENDAR_PERIODS?.weekdays) === period;

    if (isWeekends) {
      data.data = {
        period: {
          label: 'Weekends (Sat-Sun)',
          value: 'weekends',
        },
      };
    } else if (isWeekdays) {
      data.data = {
        period: {
          label: 'Weekdays (Mon-Fri)',
          value: 'weekdays',
        },
      };
    } else {
      data.data = {
        period: {
          label: 'Custom...',
          value: policy?.period,
        },
      };
    }
    setTypes((prevItems) => prevItems.concat(data));
    setIsDays(policy?.period);
  };

  const setSpendingLimitsData = (policy, typeOfPolicy = 'plural') => {
    const data = {};
    const spendingLimitData =
      typeOfPolicy === 'plural'
        ? policy?.types?.find((item) => item?.code === spendingLimitType)
        : policy.type;
    data.label = spendingLimitData?.name;
    data.value = spendingLimitData?.code;
    data.data = {
      amount: policy?.maxAmount / 100,
      currency: policy?.currency,
      frequency: {
        label: policy?.frequency.toUpperCase(),
        value: policy?.frequency,
      },
    };

    setTypes((prevItems) => prevItems.concat(data));
  };

  const setReceiptPolicyLimitsData = (policy, typeOfPolicy = 'plural') => {
    const data = {};

    const receiptLimit =
      typeOfPolicy === 'plural'
        ? policy?.types?.find((item) => item.code === receiptLimitType)
        : policy.type;

    data.label = receiptLimit.name;
    data.value = receiptLimit.code;
    data.currency = policy?.currency;

    data.data = {
      type: {
        label: policy?.receiptPolicyCondition?.toUpperCase(),
        value: policy?.receiptPolicyCondition,
      },
      amount: policy?.receiptPolicyValue / 100,
    };
    setTypes((prevItems) => prevItems.concat(data));
  };

  useEffect(() => {
    if (!isEditing && types?.length) {
      const allTypes = types?.map((type) => {
        return type.value;
      });
      getPolicyTypesData({
        type: allTypes,
      });
    }

    if (isEditing && types?.length) {
      let data = {
        type: types?.map((type) => {
          return type.value;
        }),
      };
      types?.forEach((type) => {
        const extractedData = extractPolicyPayload(type);
        data = {
          ...data,
          ...extractedData,
        };
      });
      getPolicyTypesData(data);
    }

    if (types?.length === 3) {
      setIsCompleted(true);
    }
  }, [isEditing, types]);

  const onHandleCancel = () => {
    setCurrentType({});
    setIsEdit(false);
    setTypeSelector(false);
  };

  const onHandleAction = (type, data) => {
    if (type === 'remove') {
      const updatedItems = [...types];
      const newItem = updatedItems.filter((item) => item.value !== data?.value);
      const isExist = checkPolicyAlreadyExist(data?.value);
      if (isEditing && !isExist) {
        return setRemovePolicyModal({
          visible: true,
          selected: data,
        });
      }
      setTypes(newItem);
      setIsCompleted(false);
    } else {
      setIsEdit(true);
      setCurrentType(data);
      setTypeSelector(true);
    }
  };

  const onRemovePolicyType = () => {
    const updatedItems = [...types];
    const newItem = updatedItems.filter(
      (item) => item.value !== removePolicyModal?.selected?.value,
    );
    setTypes(newItem);
    setIsCompleted(false);
    setRemovePolicyModal({
      visible: false,
      selected: null,
    });
  };

  const checkPolicyAlreadyExist = (code) => {
    return types.find((type) => type.value === code);
  };

  const options = policyTypes
    .filter(
      (policyType) =>
        policyType.name !== 'Individual Allocation' &&
        !types.some((type) => type.value === policyType.code),
    )
    .map((policyType) => ({
      label: policyType.name,
      name: policyType?.name,
      code: policyType?.code,
      value: policyType?.code,
    }));

  return (
    <section className="policy-types-selector">
      {types?.map((type, index) => {
        return (
          <Row className="mb-3" key={index}>
            <div className="form-group">
              <PolicyTypeCard
                data={type}
                type={type?.label}
                item={index}
                onHandleAction={onHandleAction}
              />
            </div>
          </Row>
        );
      })}

      <Row className="mb-3">
        {types?.length < 3 && types.length !== 0 && typeSelector == false && (
          <section className="mt-2">
            <button className="policy-type-button" onClick={() => setTypeSelector(true)}>
              <PlusIcon stroke="#44403c" />
              Add another policy type
            </button>
          </section>
        )}
        {types?.length <= 0 || (typeSelector && !completed) ? (
          <CustomSelect
            label="Type *"
            placeholder="Select type"
            name="type"
            options={options}
            onChange={(val) => handleChange({ name: 'type', value: val })}
            value={isEdit ? currentType : currentType?.type}
          />
        ) : null}
      </Row>
      {currentType?.value === typesConstant.SPENDING_LIMITS && typeSelector && (
        <Row className="mb-3 align-items-center">
          <div className="frequency-label">
            <h3>Amount & frequency</h3>
            <p>You can set a maximum spending limit and a frequency</p>
          </div>
          <CustomInput
            placeholder="Enter amount"
            type="text"
            xs={7}
            md={7}
            otherCurrency
            name="amount"
            onChange={handleChangeAmount}
            value={currentType?.data?.amount}
            isAmount
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            editCurrency={!isEditing}
            getTypeVal={handleGetSign}
            existAction={currentType?.sign || null}
          />
          <Col xs={5}>
            <CustomSelect
              name="frequency"
              options={[
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'Monthly' },
                { value: 'yearly', label: 'Yearly' },
              ]}
              onChange={(val) =>
                handleSecondValueChange({ name: 'frequency', value: val })
              }
              value={currentType?.data?.frequency}
            />
          </Col>
        </Row>
      )}

      {currentType?.value === typesConstant.CALENDAR_LIMITS && typeSelector && (
        <Row className="mb-3">
          <CustomSelect
            label="Days"
            placeholder="Select days"
            name="period"
            options={[
              { value: 'weekdays', label: 'Weekdays (Mon-Fri)' },
              { value: 'weekends', label: 'Weekends (Sat-Sun)' },
              { value: 'custom', label: 'Custom...' },
            ]}
            onChange={(val) => handleSecondValueChange({ name: 'period', value: val })}
            value={currentType?.data?.period}
          />
          {currentType?.data?.period?.label?.toLowerCase()?.includes('custom') ? (
            <CustomDays days={isDays} setDays={setIsDays} className="mt-2" />
          ) : null}
        </Row>
      )}
      {currentType?.value === typesConstant.RECEIPT_POLICY && typeSelector && (
        <Row className="mb-3">
          <div className="receipt-policy-wrapper">
            <h6>Receipt issue</h6>
            <p>Set up the amount you will like to issue a receipt for this budget</p>
          </div>
          <div className="mb-2">
            <CustomSelect
              label="When transaction is"
              placeholder="Select type"
              name="type"
              options={RECEIPT_TRANSACTION_TYPE}
              onChange={(val) => handleSecondValueChange({ name: 'type', value: val })}
              value={currentType?.data?.type}
            />
          </div>
          {currentType?.data?.type && currentType?.data?.type.value !== 'all' && (
            <div>
              <CustomInput
                label="Amount"
                isAmount
                placeholder="Enter aEnmount"
                name="amount"
                className="amount"
                onChange={handleChangeAmount}
                getTypeVal={handleGetSign}
                value={currentType?.data?.amount}
              />
            </div>
          )}
        </Row>
      )}

      {secondOption || isEdit ? (
        <Row className="mb-3">
          <div className="form-group">
            <div className="policy-type-actionbtn">
              <span onClick={onHandleCancel}>Cancel</span>
              <button onClick={isEdit ? onHandleEditSave : onHandleSaveTypes}>
                Save
              </button>
            </div>
          </div>
        </Row>
      ) : null}
    </section>
  );
};

export default PolicyTypeSelector;
