import { useState } from 'react';
import LogoIcon from 'assets/icons/Vector.png';
import { ChevronDown } from 'assets/icons/index';
import CustomPopover from 'components/UI/Popover';
import dayjs from 'dayjs';
import { Col, Row, Table } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCurrency, capitalizeFirstLetter, convertNaNToZero } from 'utils/helper';

export const PreviewInvoice = ({
  data,
  loading,
  actions: Actions,
  onSubmit,
  preview,
  onHandlePreview,
  isRecurring,
  isInstalment,
  isEdit,
  handleEdit,
  updateInvoiceLoading,
}) => {
  const { push } = useHistory();
  const { user } = useSelector(({ auth }) => auth);
  const { getCompany } = useSelector(({ companies }) => companies);
  const { data: userData = {} } = user;
  const { data: companyData = {} } = getCompany;

  const address = `${companyData?.address?.street ?? '-'} ${
    companyData?.address?.city ?? '-'
  }  ${companyData?.address?.state ?? '-'}, ${capitalizeFirstLetter(
    companyData?.address?.country.toLowerCase(),
  )}`;

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  return (
    <section className="border-top mt-4 overview-holder">
      <main className="invoice-preview-wrapper mt-4">
        <div className="invoice-preview d-flex flex-column">
          <div className=" d-flex justify-content-between pb-3 mx-4">
            <div>
              <span style={{ fontSize: 28, fontWeight: 700 }}>Invoice</span>
              <br />
              {data?.invoiceNumber && (
                <p className="invoice-number">#{data.invoiceNumber}</p>
              )}
            </div>

            <div className="image-holder">
              <img src={data?.logo ?? LogoIcon} alt="company logo" />
            </div>
          </div>
          <header className="bg-secondary-50 border-12">
            <Row>
              <Col>
                <Row className="pb-3">
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">Title/Purchase order</h1>
                      <p className="text">{data?.purchaseOrder}</p>
                    </div>
                  </div>
                </Row>
                <Row className="pb-3">
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">Issue Date</h1>
                      <p className="text pt-1">
                        {dayjs(data?.issuedDate).format('DD MMM, YYYY')}
                      </p>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">Due Date</h1>
                      <p className="text pt-1">
                        {data?.dueDate
                          ? dayjs(data?.dueDate).format('DD MMM, YYYY')
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                </Row>
              </Col>

              <Col>
                <div className="lhs">
                  <div>
                    <h1 className="header-title">Billed to:</h1>
                    <p className="header-sub">
                      {data?.customer?.name ||
                        `${data?.customer?.firstName} ${data?.customer?.lastName}`}
                    </p>
                  </div>
                  <p className="text">{data?.customer?.email}</p>
                  <p className="text">
                    {data?.customer?.phoneNumber?.localFormat
                      ? `+${data?.customer?.phoneNumber?.countryCode ?? ''}${
                          data?.customer?.phoneNumber?.localFormat ?? ''
                        }`
                      : null}
                  </p>
                  {data?.customer?.address?.street && (
                    <p className="text">
                      {`${data?.customer?.address?.street}, ${
                        data?.customer?.address?.city
                      }, ${capitalizeFirstLetter(
                        data?.customer?.address?.country?.toLowerCase(),
                      )}`}
                    </p>
                  )}
                  {data?.customer?.phoneNumber?.tin && (
                    <p className="text">TIN: {data?.customer?.phoneNumber?.tin}</p>
                  )}
                </div>
              </Col>

              <Col>
                <Row>
                  <div className="lhs">
                    <div>
                      <h1 className="header-title">From:</h1>
                      <p className="header-sub">{`${userData?.user?.company?.name}`}</p>
                    </div>
                    <p className="text">{userData?.user?.company?.contactEmail}</p>
                    <p className="text">
                      {userData?.user?.company?.contact_phone
                        ? `+${userData?.user?.company?.contact_phone ?? ''}`
                        : null}
                    </p>
                    <p className="text">{address}</p>
                  </div>
                </Row>
              </Col>
            </Row>
          </header>
          <main>
            <PreviewTable data={data} />
            <section className="d-flex justify-content-end">
              <AmountInformation data={data} isInstalment={isInstalment} />
            </section>
          </main>
          <section className="invoice-preview-footer mt-auto">
            <Footer description={data?.description} bankDetails={data?.account} />
          </section>
        </div>
      </main>

      <div className="border-top my-4">
        <div className="d-flex justify-content-between flex-wrap mt-3">
          <div
            className="d-flex ms-auto gap-3 right-side align-items-center position-relative"
            style={{ padding: '10px' }}
          >
            <button
              className="add-button add-custom w-100"
              onClick={preview ? onHandlePreview : () => push('/receivables/invoices')}
            >
              {preview ? 'Edit' : 'Cancel'}
            </button>

            <div
              className="add-button add-custom w-100 min-w-fit px-3"
              onClick={() => (isEdit ? handleEdit() : onSubmit(true))}
              disabled={updateInvoiceLoading || loading}
            >
              Save as draft
            </div>

            {/* <CustomButton
              className="add-button"
              onClick={() => {
                onSubmit(false);
              }}
              disabled={loading}
            >
              Save and send
            </CustomButton> */}

            <div className="d-flex">
              <button
                disabled={loading}
                onClick={() => onSubmit(false)}
                className={`${
                  isRecurring && 'is-plain'
                } px-3 nowrap dropdown-btn action-btn text-white`}
              >
                Save and send
              </button>

              {!isRecurring && (
                <CustomPopover
                  placement="top-start"
                  id="invoice-schedule"
                  zIndex="1"
                  content={<Actions />}
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                >
                  <button
                    className="dropdown-btn"
                    disabled={loading}
                    onClick={handleButtonToggle}
                  >
                    <ChevronDown
                      color="#ffffff"
                      className={`icon ${isButtonToggle && 'is-toggled'}`}
                    />
                  </button>
                </CustomPopover>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const InvoicePresentationWrapper = ({ children, style }) => {
  return (
    <section className="d-flex justify-content-between align-items-center" style={style}>
      {children}
    </section>
  );
};

export const PreviewTable = ({ data }) => {
  return (
    <section className="preview-table">
      <Table>
        <thead>
          <tr style={{ borderBottomWidth: 2, borderColor: '#1C1917' }}>
            <th style={{ minWidth: 130 }}>
              Item{`${data?.products.length > 1 ? 's' : ''}`}
            </th>
            <th style={{ minWidth: 40 }}>Qty</th>
            <th>Unit Price</th>
            <th style={{ textAlign: 'right' }}>Amount excl. Tax</th>
          </tr>
        </thead>
        <tbody>
          {data?.products?.map((datum, index) => {
            return (
              <tr key={index}>
                <td>{datum.name}</td>
                <td>{datum.quantity}</td>
                <td>
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={datum?.unitPrice}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </td>

                <td style={{ textAlign: 'right' }}>
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={datum?.unitPrice * datum?.quantity}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
};

export const AmountInformation = ({ data, isInstalment }) => {
  const { payments, type } = data.instalmentPayload;

  const handleAmount = (amount) => {
    if (type === 'percentage') {
      return ((amount / 100) * data.totalAmount).toFixed(2);
    }

    return amount;
  };

  return (
    <section className="account-info">
      <div
        className="d-flex justify-content-between align-items-center info pt-2"
        style={{ borderTop: '1px solid #1C1917', borderRadius: 0 }}
      >
        <h1>Subtotal excl. Tax</h1>
        <p>
          <CurrencyFormat
            prefix={getCurrency('NGN')}
            value={convertNaNToZero(data?.subTotal)}
            displayType="text"
            thousandSeparator={true}
          />
        </p>
      </div>

      <div className="d-flex justify-content-between align-items-center info">
        <h1>Discount </h1>
        {data?.discount ? (
          <p>
            <CurrencyFormat
              prefix={`- ${getCurrency('NGN')}`}
              value={convertNaNToZero(Number((Number(data?.discount) / 100).toFixed(2)))}
              displayType="text"
              thousandSeparator={true}
            />
          </p>
        ) : (
          <span></span>
        )}
      </div>

      <div
        className="d-flex justify-content-between align-items-center info pb-1"
        style={{ borderBottom: '1px solid #1C1917', borderRadius: 0 }}
      >
        <h1>VAT ({data?.vat}%)</h1>
        {data?.vatValue ? (
          <p>
            <CurrencyFormat
              prefix={getCurrency('NGN')}
              value={convertNaNToZero(data?.vatValue)}
              displayType="text"
              thousandSeparator={true}
            />
          </p>
        ) : (
          <span></span>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center total pt-1">
        <h1>TOTAL incl. Tax</h1>
        <p>
          <CurrencyFormat
            prefix={getCurrency('NGN')}
            value={convertNaNToZero(data?.totalAmount)}
            displayType="text"
            thousandSeparator={true}
          />
        </p>
      </div>

      {/* Instalment region */}
      {isInstalment && (
        <section className="mt-3">
          {payments.map((payment, index) => (
            <div className="instalment-holder" key={index}>
              <div className="mb-2">
                <p className="payment">Payment {index + 1}</p>
                <p className="due-date">
                  Due on {dayjs(payment?.dueDate).format('DD MMM, YYYY')}
                </p>
              </div>

              <div className="ms-auto">
                <p className="amount">
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={handleAmount(+payment.amount)}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </p>
              </div>
            </div>
          ))}
        </section>
      )}
    </section>
  );
};

export const Footer = ({ description, bankDetails }) => {
  return (
    <Row className="mt-3">
      {description && (
        <Col md={6}>
          <Row className="pb-3 max-w-300">
            <div className="lhs">
              <div style={{ marginRight: 15 }}>
                <h1 className="header-title">Notes / Payment Terms:</h1>
                <p className="text preview">{description}</p>
              </div>
            </div>
          </Row>
        </Col>
      )}
      {bankDetails && (
        <Col md={5} className="ms-auto">
          <Row className="max-w-300">
            <div className="lhs">
              <h1 className="header-title">Bank Details</h1>
              <p className="text justify-content-between d-flex align-items-center pb-1">
                <span className="text preview">Bank</span>
                <span className="text preview w-50">{bankDetails?.bank || 'N/A'}</span>
              </p>

              <p className="text justify-content-between d-flex align-items-center pb-1">
                <span className="text preview">Account name</span>
                <span className="text preview w-50">
                  {bankDetails?.accountName || 'N/A'}
                </span>
              </p>

              <p className="text justify-content-between d-flex align-items-center pb-1">
                <span className="text preview"> Account number</span>
                <span className="text preview w-50">
                  {bankDetails?.accountNumber || 'N/A'}
                </span>
              </p>
            </div>
          </Row>
        </Col>
      )}
    </Row>
  );
};
