import { ArrowLeft, Download02, HintAlertIcon } from 'assets/icons';
import CustomImport from 'components/UI/CustomImport';
import ClientFileUpload from 'components/UI/FileUpload/ClientFileUpload';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createBulkCustomers } from 'redux/actions/InvoiceAction';
import { createBulkVendors } from 'redux/actions/VendorsAction';
import { bulkVerifyBankAccounts } from 'redux/sagas/PaymentSaga';
import { getSimplifiedError } from 'utils/error';

const tableColumns = [
  {
    Header: 'Vendor name',
    accessor: 'name',
    type: 'string',
    sample: 'Jasi Jack',
  },
  {
    Header: 'Email',
    accessor: 'email',
    type: 'string',
    sample: 'joh@mail.com',
  },
  {
    Header: 'Tax identification number',
    accessor: 'taxIdenticationNumber',
    type: 'string',
    sample: '223232-222213',
  },
  {
    Header: 'Tax witholding',
    accessor: 'taxWithHolding',
    type: 'string',
    sample: '20',
  },
  {
    Header: 'Bank name',
    accessor: 'bankName',
    type: 'string',
    sample: 'Sterling bank',
  },
  {
    Header: 'Bank code',
    accessor: 'bankCode',
    type: 'string',
    sample: '022',
  },
  {
    Header: 'Account number',
    accessor: 'accountNumber',
    type: 'string',
    sample: '2023400000',
  },
  {
    Header: 'Description',
    accessor: 'description',
    type: 'string',
    sample: 'Cloud computing and IT infrastructure provider.',
  },
  {
    Header: '',
    accessor: 'modify',
    type: 'modify',
    withEdit: true,
    withDelete: true,
  },
];

const customerTemplate =
  'https://bujeti-public.s3.eu-west-1.amazonaws.com/customer+upload+template.xlsx';

const ImportVendors = () => {
  const [file, setFile] = useState();
  const [validatingAccount, setValidatingAccount] = useState(false);

  const { push } = useHistory();
  const dispatch = useDispatch();
  const {
    createBulkVendors: { loading, success },
  } = useSelector(({ vendors }) => vendors);

  const bujetiColumns = tableColumns
    .filter((item) => item.Header != '')
    .map((item) => ({
      column: item.Header,
      sample: item.sample,
      accessor: item.accessor,
    }));

  useEffect(() => {
    if (success) push('/expenses/vendors');
  }, [success]);

  // createBulkCustomers
  const submitHandler = async (data) => {
    let fullDetails = data;
    const allAccount = data.map((item) => ({
      accountNumber: String(item['accountNumber']),
      bankCode: String(item['bankCode']),
    }));

    const getArrayOfObjects = async () => {
      setValidatingAccount(true);
      return bulkVerifyBankAccounts({ accounts: allAccount })
        .then((data) => {
          return data.data;
        })
        .catch((error) => {
          setValidatingAccount(false);
          toastError(getSimplifiedError(error));
        });
    };

    const verifyResponse = await getArrayOfObjects();

    const mergeArrays = async () => {
      setValidatingAccount(false);

      const mergedArray = [];
      for (let index = 0; index < fullDetails?.length; index++) {
        for (let verifyIndex = 0; verifyIndex < verifyResponse?.length; verifyIndex++) {
          if (index === verifyIndex) {
            mergedArray.push({
              ...fullDetails[index],
              accountNumber: verifyResponse[verifyIndex]?.verified
                ? verifyResponse[verifyIndex]?.account_number
                : '',
              accountName: verifyResponse[verifyIndex]?.verified
                ? verifyResponse[verifyIndex]?.account_name
                : '',
            });
          }
        }
      }
      return mergedArray;
    };
    const finalData = await mergeArrays();

    const vendors = finalData.map((item) => ({
      name: item?.name,
      email: item?.email,
      description: item?.description,
      taxIdenticationNumber: item?.taxIdenticationNumber,
      taxWithHolding: item?.taxWithHolding,
      ...(item?.accountNumber
        ? {
            bankName: item?.bankName,
            // bankCode: item?.bankCode,
            accountName: item?.accountName,
            accountNumber: item?.accountNumber,
            currency: 'NGN',
          }
        : undefined),
    }));

    if (vendors.length) {
      dispatch(createBulkVendors({ vendors }));
    }
  };

  return (
    <section className="component_page-section">
      <div className="elements-section">
        <div className="back-btn" onClick={() => push('/expenses/vendors')}>
          <ArrowLeft color="#D28B28" /> <div>Back</div>
        </div>
        <div className="section-title">
          <h1>Import vendors from CSV</h1>
          <p>
            Upload your CSV to kickstart bulk vendor data import. Our smart upload will
            parses your file and map fields for a seamless upload experience.
          </p>
        </div>

        <div className="alert_action-banner d-flex gap-3">
          <div className="m-0 p-0">
            <HintAlertIcon width={20} height={20} />
          </div>

          <div>
            <div className="banner-text">
              <h1>
                No CSV file? No problem. Download our sample template to get started.
              </h1>
              <p>
                Get a head start by downloading our pre-formatted CSV template. Simply add
                your contacts to the template or use it as a guide to format your existing
                spreadsheet for a flawless import.
              </p>
            </div>
            <div className="actions">
              <a
                href={customerTemplate}
                className="text-decoration-none text-reset btn xxs"
                download
                rel="noreferrer"
              >
                Download template <Download02 />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <ClientFileUpload
            label="File upload"
            supportType="CSV, and XLS formats, up to 5 MB."
            acceptedFile={{ 'text/html': ['.xlsx', '.csv'] }}
            onChange={(files) => {
              setFile(files.file);
            }}
          />
        </div>

        <CustomImport
          loading={loading || validatingAccount}
          file={file}
          setFile={setFile}
          bujetiColumns={bujetiColumns}
          tableColumns={tableColumns}
          submitData={submitHandler}
          duplicateColumns={['email']}
          label="vendors"
        />
      </div>
    </section>
  );
};

export default ImportVendors;
