export const FIND_COMPANY_NAME_REQUEST = 'FIND_COMPANY_NAME_REQUEST';
export const FIND_COMPANY_NAME_SUCCESS = 'FIND_COMPANY_NAME_SUCCESS';
export const FIND_COMPANY_NAME_ERROR = 'FIND_COMPANY_NAME_ERROR';

export const GET_DIRECTOR_DETAILS_REQUEST = 'GET_DIRECTOR_DETAILS_REQUEST';
export const GET_DIRECTOR_DETAILS_SUCCESS = 'GET_DIRECTOR_DETAILS_SUCCESS';
export const GET_DIRECTOR_DETAILS_ERROR = 'GET_DIRECTOR_DETAILS_ERROR';

export const RESET_BLOCK_UTILS = 'RESET_BLOCK_UTILS';
export const RESET_FLAGS_UTILS = 'RESET_FLAGS_UTILS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  findCompanyName: { ...block },
  getDireectorDetails: { ...block },
};

export const UtilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_COMPANY_NAME_REQUEST:
      return { ...state, findCompanyName: { ...state.findCompanyName, loading: true } };
    case FIND_COMPANY_NAME_SUCCESS:
      return {
        ...state,
        findCompanyName: {
          ...state.findCompanyName,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FIND_COMPANY_NAME_ERROR:
      return {
        ...state,
        findCompanyName: {
          ...state.findCompanyName,
          loading: false,
          error: action.error,
        },
      };

    case GET_DIRECTOR_DETAILS_REQUEST:
      return {
        ...state,
        getDireectorDetails: { ...state.getDireectorDetails, loading: true },
      };
    case GET_DIRECTOR_DETAILS_SUCCESS:
      return {
        ...state,
        getDireectorDetails: {
          ...state.getDireectorDetails,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_DIRECTOR_DETAILS_ERROR:
      return {
        ...state,
        getDireectorDetails: {
          ...state.getDireectorDetails,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_UTILS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_UTILS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
