import { Col, Row } from 'react-bootstrap';
import FormattedCurrency from 'components/UI/FormattedCurrency';
import { getCurrency } from 'utils/helper';
import AnalyticsCard from 'components/UI/AnalyticsCard';

const BudgetAnalytics = ({ data, loading }) => {
  const getValue = (key) => data?.['NGN']?.[key] || 0;

  const analyticsData = [
    { caption: 'Total', key: 'totalAvailable' },
    { caption: 'Spent', key: 'totalSpent' },
    { caption: 'Budgeted', key: 'totalBudgeted' },
  ];

  const renderAnalyticsCard = ({ caption, key }) => (
    <Col sm={4} className="mb-md-0 mb-2" key={key}>
      <AnalyticsCard
        hasExtra={false}
        caption={caption}
        loading={loading}
        value={
          <FormattedCurrency
            loading={loading}
            value={getValue(key)}
            prefix={getCurrency('NGN')}
          />
        }
      />
    </Col>
  );

  return (
    <section className="analytics-region mb-md-3 mb-0">
      <Row>{analyticsData.map(renderAnalyticsCard)}</Row>
    </section>
  );
};

export default BudgetAnalytics;
