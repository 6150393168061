import CurrencyFormat from 'react-currency-format';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'components/UIHooks/Rechart';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const year = new Date().getFullYear().toString();

const noChartData = [
  { name: `Jan ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Feb ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Mar ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Apr ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `May ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Jun ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Jul ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Aug ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Sep ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Oct ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Nov ${year.substring(year.length - 2, year.length)}`, spent: 0 },
  { name: `Dec ${year.substring(year.length - 2, year.length)}`, spent: 0 },
];

const ProfileChart = ({
  withoutBg = false,
  className,
  customClass,
  fullWidth = false,
  children,
  currency = 'NGN',
  loading,
  backgroundColor = '',
  data,
  isCustomer,
  ...props
}) => {
  const chartData = data.length ? data : noChartData;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white" style={{ fontSize: 12 }}>
          <p className="label">Month: {label}</p>
          <p className="label-data">
            <span> {isCustomer ? 'Collected' : 'Spent'}:</span>
            {
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(payload[0].value)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            }
          </p>
        </div>
      );
    }

    return null;
  };

  const formatYAxis = (value) =>
    `${getCurrency(currency)}${numFormatter(value, true, true)}`;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={chartData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset={'5%'} stopColor="#D28B28" stopOpacity={0.8} />
            <stop offset={'95%'} stopColor="#D28B28" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: 12 }} dataKey="name" />
        <YAxis tick={{ fontSize: 12 }} tickFormatter={formatYAxis} />
        <Tooltip content={<CustomTooltip />} />
        <Area type="monotone" dataKey="spent" stroke="#D28B28" fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default ProfileChart;
