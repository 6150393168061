import React from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const PasswordLabel = ({ label, checker }) => {
  return (
    <div className="password-strength-item">
      {checker ? (
        <CheckOutlined className="good-strength-icon" />
      ) : (
        <CloseOutlined className="bad-strength-icon" />
      )}
      <span>{label}</span>
    </div>
  );
};

export default PasswordLabel;
