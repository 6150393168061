import classNames from 'classnames';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { convertNaNToZero } from 'utils/helper';

const CustomPercentageInput = ({
  value: input = '',
  onChange,
  md = 12,
  label = '',
  className,
  wrapperClass = '',
  xs,
  subValue = '',
  currency = '',
  ...res
}) => {
  const handleInputChange = (e) => {
    const value = e.target.value;

    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    const parts = sanitizedValue.split('.');

    if (parts.length > 2) {
      return;
    }

    if (!isNaN(sanitizedValue) && sanitizedValue >= 0 && sanitizedValue <= 100) {
      const decimalPart = parts[1];
      const zerosCount = decimalPart ? decimalPart.match(/0*/)[0].length : 0;

      const integerPart = parts[0];
      if (!integerPart.startsWith('00') && zerosCount <= 2) {
        onChange(sanitizedValue);
      }
    }
  };

  return (
    <Form.Group
      as={Col}
      md={md}
      xs={xs}
      className={classNames('groupWrapper position-relative', {
        [wrapperClass]: wrapperClass,
      })}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <input
        className={classNames('form-control', { [className]: className })}
        type="text"
        id="percentageInput"
        value={input}
        onChange={handleInputChange}
        {...res}
      />

      {subValue ? (
        <>
          <span className="position-absolute" style={{ right: '12px', top: '20%' }}>
            <CurrencyFormat
              style={{ color: '#79716B', fontSize: 14 }}
              prefix={currency}
              value={convertNaNToZero(subValue)}
              displayType="text"
              thousandSeparator={true}
              isNumericString
            />
          </span>
        </>
      ) : (
        <span></span>
      )}
    </Form.Group>
  );
};

export default CustomPercentageInput;
