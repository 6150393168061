import { Avatar } from 'assets/icons';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBeneficiaries,
  searchRecipientList,
  searchRecipients,
} from 'redux/actions/BeneficiariesAction';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { RESET_FLAGS_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { getInitials } from 'utils/helper';
import CustomButton from '../../../components/UI/CustomButton';
import CustomSelect from '../../../components/UI/CustomSelect';
import {
  getTransaction,
  massAssignTransaction,
} from '../../../redux/actions/TransactionsAction';
import './bulkAssign.styles.scss';

const initailState = {
  recipient: null,
  payer: null,
};

const BulkAssignTransactionAside = ({ setOpenBulkAssign }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [recipientValue, setRecipientValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const recipientDebouncedValue = useDebounce(recipientValue, 200);
  const handleSelectChange = (val, name) => {
    if (name === 'payer') {
      setValue(val);
    } else if (name === 'recipient') {
      setRecipientValue(val);
    }
  };

  const {
    getBeneficiaries: {
      data: beneficiariesData = {},
      loading: isFetchingBeneficiaries,
      success: isBeneficiariesFetched,
    },
    searchRecipients: { data: searchRecipient = [], loading: isFetchingRecipient },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    massAssignTransaction: { loading: loadingMassAssign, success: successMassAssign },
  } = useSelector(({ transaction }) => transaction);

  const {
    selectedTableRows: { selectedRows, shouldClearRows },
  } = useSelector(({ table }) => table);

  const [transactionData, setTransactionData] = useState(initailState);
  const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
  const [recepientOptions, setRecepientOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [canMassAssignCategory, setCanMassAssignCategory] = useState(true);
  const [canMassAssignPayer, setCanMassAssignPayer] = useState(true);
  const [canMassAssignRecipient, setCanMassAssignRecipient] = useState(true);
  const [createable, setCreateable] = useState({
    isLoading: false,
    recepientOptions: [],
    value: undefined,
  });

  useEffect(() => {
    const beneficiaries = beneficiaryOptions?.find((option) =>
      option?.value?.toLowerCase()?.includes(debouncedValue?.toLowerCase()),
    );

    if (!beneficiaries && debouncedValue) {
      dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  useEffect(() => {
    const recipients = recepientOptions?.find((option) =>
      option?.value?.toLowerCase()?.includes(recipientDebouncedValue?.toLowerCase()),
    );

    if (!recipients && recipientDebouncedValue) {
      dispatch(searchRecipientList({ query: recipientDebouncedValue?.toLowerCase() }));
    }
  }, [recipientDebouncedValue]);

  useEffect(() => {
    if (!isFetchingRecipient && !searchRecipient?.recipients?.length) {
      dispatch(searchRecipients());
    } else {
      const searched = searchRecipient?.recipients?.filter(
        ({ status }) => status === 'active',
      );
      const opt = searched?.map(({ name: label, code, email }) => ({
        label: <BeneficiarySingleOption name={label} />,
        value: label,
        email,
        code,
        name: 'recipient',
      }));

      if (opt) setRecepientOptions(opt);
    }
  }, [isFetchingRecipient, searchRecipient]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_FLAGS_TRANSACTIONS, blockType: 'massAssignTransaction' });
      if (!!shouldClearRows) {
        dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: false } });
      }
    };
  }, []);

  useEffect(() => {
    // const isEachRoleValidForCategoryMassAssign = [];
    const isEachRoleValidForPayerMassAssign = [];
    const isEachRoleValidForRecipientMassAssign = [];

    selectedRows.forEach(({ transactionData: singleRow }) => {
      if (singleRow.payer !== null) {
        setTransactionData((prevTransactionData) => ({
          ...prevTransactionData,
          payer: null,
        }));
        isEachRoleValidForPayerMassAssign.push(false);
      } else {
        isEachRoleValidForPayerMassAssign.push(true);
      }

      if (singleRow.recipient !== null) {
        setTransactionData((prevTransactionData) => ({
          ...prevTransactionData,
          recipient: null,
        }));
        isEachRoleValidForRecipientMassAssign.push(false);
      } else {
        isEachRoleValidForRecipientMassAssign.push(true);
      }

      // if (!['success', 'failed'].includes(singleRow.status)) {
      //   isEachRoleValidForCategoryMassAssign.push(false);
      // } else {
      //   isEachRoleValidForCategoryMassAssign.push(true);
      // }
    });
    if (selectedRows.length > 0) {
      if (isEachRoleValidForPayerMassAssign.includes(false)) {
        setCanMassAssignPayer(false);
      } else {
        setCanMassAssignPayer(true);
      }

      if (isEachRoleValidForRecipientMassAssign.includes(false)) {
        setCanMassAssignRecipient(false);
      } else {
        setCanMassAssignRecipient(true);
      }
    }
  }, [selectedRows.length]);

  useEffect(() => {
    setCreateable({ ...createable, recepientOptions });
  }, [recepientOptions]);

  useEffect(() => {
    if (!isFetchingBeneficiaries && !beneficiariesData?.beneficiaries?.length)
      dispatch(getBeneficiaries());
    else
      setBeneficiaryOptions(
        beneficiariesData?.beneficiaries?.map(
          ({ user: { code, role, firstName, lastName } }) => ({
            value: `${firstName} ${lastName}`,
            code,
            label: <BeneficiarySingleOption name={`${firstName} ${lastName}`} />,
            name: 'payer',
          }),
        ),
      );
  }, [beneficiariesData, isFetchingBeneficiaries]);

  useEffect(() => {
    if (successMassAssign) {
      dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: true } });
      dispatch(getTransaction());
    }
  }, [successMassAssign]);

  const handleSelect = (value, name) => {
    setTransactionData((prevTransactionData) => ({
      ...prevTransactionData,
      [name]: value,
    }));
    if (debouncedValue && name === 'payer') dispatch(getBeneficiaries());
  };

  const handleSubmit = () => {
    let massPayers;
    let massRecipients;
    let massCategories;

    if (transactionData?.payer) {
      massPayers = selectedRows.map(({ transactionData: singleRow }) => ({
        transaction: singleRow?.code,
        payer: transactionData?.payer?.code,
      }));
    }
    if (transactionData?.recipient) {
      massRecipients = selectedRows.map(({ transactionData: singleRow }) => ({
        transaction: singleRow?.code,
        recipient: transactionData?.recipient?.code,
      }));
    }
    if (transactionData.category.value) {
      massCategories = selectedRows.map(({ transactionData: singleRow }) => ({
        transaction: singleRow?.code,
        category: transactionData?.category?.value,
      }));
    }

    const payload = {
      payer: massPayers ? { items: massPayers } : undefined,
      category: massCategories ? { items: massCategories } : undefined,
      recipient: massRecipients ? { items: massRecipients } : undefined,
    };

    dispatch(massAssignTransaction(payload));
  };

  const BeneficiarySingleOption = ({ name }) => {
    return (
      <div className="d-flex flex-wrap align-items-center beneficiary-option">
        <div className="img__card-initials">
          <h5>{getInitials(null, null, `${name}`)}</h5>
        </div>
        <span>{name}</span>
      </div>
    );
  };

  const budgetCard = () => (
    <div className="information-wrapper">
      <h2 className="aside-title w-100">Transactions selected ({selectedRows.length})</h2>
      <div className="form-normal">
        <div className="input-section">
          <h6>Category</h6>
          <Row className="mb-3">
            <CategoryDropdown
              label="Apply a category"
              placeholder="Select a category"
              onChange={(val) => handleSelect(val, 'category')}
              value={transactionData.category}
              name="category"
              // disabled={!canMassAssignCategory}
            />
          </Row>
        </div>

        {(!canMassAssignPayer || !canMassAssignRecipient) && (
          <div className="info-box px-2 py-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99996 0.666748C3.94987 0.666748 0.666626 3.94999 0.666626 8.00008C0.666626 12.0502 3.94987 15.3334 7.99996 15.3334C12.05 15.3334 15.3333 12.0502 15.3333 8.00008C15.3333 3.94999 12.05 0.666748 7.99996 0.666748ZM7.99996 4.66675C7.63177 4.66675 7.33329 4.96522 7.33329 5.33341C7.33329 5.7016 7.63177 6.00008 7.99996 6.00008H8.00663C8.37482 6.00008 8.67329 5.7016 8.67329 5.33341C8.67329 4.96522 8.37482 4.66675 8.00663 4.66675H7.99996ZM8.66663 8.00008C8.66663 7.63189 8.36815 7.33341 7.99996 7.33341C7.63177 7.33341 7.33329 7.63189 7.33329 8.00008V10.6667C7.33329 11.0349 7.63177 11.3334 7.99996 11.3334C8.36815 11.3334 8.66663 11.0349 8.66663 10.6667V8.00008Z"
                fill="#A9A29D"
              />
            </svg>
            <p className="m-0 ms-1">
              You cannot change{' '}
              {!canMassAssignPayer && !canMassAssignRecipient
                ? 'Payer or Merchant'
                : !canMassAssignRecipient
                ? 'Merchant'
                : 'Payer'}{' '}
              for transactions made from Bujeti.
            </p>
          </div>
        )}

        <div className="input-section">
          <h6>Merchant or recipient</h6>
          <Row className="mb-3">
            <CustomSelect
              label="Assign a merchant or a recipient"
              name="recipient"
              options={recepientOptions}
              customStyles={{
                option: { borderBottom: 'none', fontSize: '14px', color: '#1C1917' },
                singleValue: { fontSize: '14px' },
              }}
              placeholder={
                <span className="aside-placeholder">
                  <Avatar />{' '}
                  <span className="ms-1 text-sm">Select a merchant or a recipient</span>
                </span>
              }
              onChange={(val) => handleSelect(val, 'recipient')}
              value={transactionData.recipient}
              isClearable={true}
              onInputChange={(val) => handleSelectChange(val, 'recipient')}
              isLoading={isFetchingRecipient}
              isDisabled={!canMassAssignRecipient}
            />
          </Row>
        </div>

        <div className="input-section">
          <h6>Payer</h6>
          <Row className="mb-3">
            <CustomSelect
              label="Assign a payer"
              name="payer"
              options={beneficiaryOptions}
              customStyles={{
                option: { borderBottom: 'none', fontSize: '14px', color: '#1C1917' },
                singleValue: { fontSize: '14px' },
              }}
              placeholder={
                <span className="aside-placeholder">
                  <Avatar /> <span className="ms-1 text-sm">Select a payer</span>
                </span>
              }
              onChange={(val) => handleSelect(val, 'payer')}
              value={transactionData.payer}
              isClearable={true}
              onInputChange={(val) => handleSelectChange(val, 'payer')}
              isLoading={isFetchingBeneficiaries}
              isDisabled={!canMassAssignPayer}
            />
          </Row>
        </div>
      </div>
    </div>
  );

  const PageView = () => {
    return budgetCard();
  };
  return (
    <>
      <div className="bulk-assignment-aside border-start border-1">
        <div className="content px-3">
          <div className="card-modal-body">{PageView()}</div>
        </div>
        <div className="aside-button-container d-flex flex-wrap border-top justify-content-end">
          <CustomButton
            className="add-button add-action me-3"
            customClass={true}
            disabled={loadingMassAssign}
            onClick={() => {
              setOpenBulkAssign(false);
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className="add-button dark-button"
            onClick={handleSubmit}
            customClass={true}
            loading={loadingMassAssign}
            disabled={
              loadingMassAssign ||
              (!canMassAssignCategory && !canMassAssignPayer && !canMassAssignRecipient)
            }
          >
            Apply changes
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default BulkAssignTransactionAside;
