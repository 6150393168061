export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

export const MARK_NOTIFICATIONS_READ_REQUEST = 'MARK_NOTIFICATIONS_READ_REQUEST';
export const MARK_NOTIFICATIONS_READ_SUCCESS = 'MARK_NOTIFICATIONS_READ_SUCCESS';
export const MARK_NOTIFICATIONS_READ_ERROR = 'MARK_NOTIFICATIONS_READ_ERROR';

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST = 'MARK_ALL_NOTIFICATIONS_READ_REQUEST';
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_READ_ERROR = 'MARK_ALL_NOTIFICATIONS_READ_ERROR';

export const DECLINE_A_NOTIFICATION_REQUEST = 'DECLINE_A_NOTIFICATION_REQUEST';
export const DECLINE_A_NOTIFICATION_SUCCESS = 'DECLINE_A_NOTIFICATION_SUCCESS';
export const DECLINE_A_NOTIFICATION_ERROR = 'DECLINE_A_NOTIFICATION_ERROR';

export const APPROVE_A_NOTIFICATION_REQUEST = 'APPROVE_A_NOTIFICATION_REQUEST';
export const APPROVE_A_NOTIFICATION_SUCCESS = 'APPROVE_A_NOTIFICATION_SUCCESS';
export const APPROVE_A_NOTIFICATION_ERROR = 'APPROVE_A_NOTIFICATION_ERROR';

export const RESET_BLOCK_NOTIFICATION = 'RESET_BLOCK_NOTIFICATION';
export const RESET_FLAGS_NOTIFICATION = 'RESET_FLAGS_NOTIFICATION';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getNotification: { ...block },
  markNotificationAsRead: { ...block },
  markAllNotificationAsRead: { ...block },
  declineNotification: { ...block },
};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, getNotification: { ...state.getNotification, loading: true } };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getNotification: {
          ...state.getNotification,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        getNotification: {
          ...state.getNotification,
          loading: false,
          error: action.error,
        },
      };

    case MARK_NOTIFICATIONS_READ_REQUEST:
      return {
        ...state,
        markNotificationAsRead: { ...state.markNotificationAsRead, loading: true },
      };
    case MARK_NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        markNotificationAsRead: {
          ...state.markNotificationAsRead,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case MARK_NOTIFICATIONS_READ_ERROR:
      return {
        ...state,
        markNotificationAsRead: {
          ...state.markNotificationAsRead,
          loading: false,
          error: action.error,
        },
      };

    case MARK_ALL_NOTIFICATIONS_READ_REQUEST:
      return {
        ...state,
        markAllNotificationAsRead: { ...state.markAllNotificationAsRead, loading: true },
      };
    case MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        markAllNotificationAsRead: {
          ...state.markAllNotificationAsRead,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case MARK_ALL_NOTIFICATIONS_READ_ERROR:
      return {
        ...state,
        markAllNotificationAsRead: {
          ...state.markAllNotificationAsRead,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_NOTIFICATION:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_NOTIFICATION:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
