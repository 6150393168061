import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_ROLES_ERROR,
  CREATE_ROLES_REQUEST,
  CREATE_ROLES_SUCCESS,
  DELETE_ROLE_ERROR,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  EDIT_PERMISSIONS_ERROR,
  EDIT_PERMISSIONS_REQUEST,
  EDIT_PERMISSIONS_SUCCESS,
  EDIT_ROLE_NAME_ERROR,
  EDIT_ROLE_NAME_REQUEST,
  EDIT_ROLE_NAME_SUCCESS,
  GET_ROLES_ERROR,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  RESET_BLOCK_ROLES,
} from 'redux/reducers/RolesReducer';
import { getSimplifiedError } from 'utils/error';

async function getRoles(params) {
  return await Axios.get(`/roles`, { params });
}
function* handleGetRoles({ payload }) {
  try {
    const response = yield call(getRoles, payload);
    if (response) {
      yield put({
        type: GET_ROLES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_ROLES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createRoles(payload) {
  return await Axios.post(`/roles`, payload);
}
function* handleCreateRoles({ payload }) {
  try {
    const response = yield call(createRoles, payload);
    if (response) {
      yield put({
        type: CREATE_ROLES_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);

      yield put({
        type: GET_ROLES_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_ROLES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteRole(payload) {
  return await Axios.delete(`/roles/${payload}`);
}
function* handleDeleteRoles({ payload }) {
  try {
    const response = yield call(deleteRole, payload);
    if (response) {
      yield put({
        type: DELETE_ROLE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);

      yield delay(100);
      yield put({
        type: RESET_BLOCK_ROLES,
        blockType: 'deleteRole',
      });

      yield put({
        type: GET_ROLES_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_ROLE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function editRole(payload) {
  return await Axios.put(`/roles/${payload}`);
}
function* handleEditRoles({ payload }) {
  try {
    const response = yield call(editRole, payload);
    if (response) {
      yield put({
        type: EDIT_ROLE_NAME_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);

      yield put({
        type: GET_ROLES_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EDIT_ROLE_NAME_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function editPermissions(payload) {
  const { code, ...rest } = payload;
  const data = { ...rest };
  return await Axios.put(`/roles/${code}`, data);
}
function* handlePermissionsEdit({ payload }) {
  try {
    const response = yield call(editPermissions, payload);
    if (response) {
      yield put({
        type: EDIT_PERMISSIONS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response?.message);
      yield put({
        type: GET_ROLES_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EDIT_PERMISSIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_ROLES_REQUEST, handleGetRoles),
  takeLatest(CREATE_ROLES_REQUEST, handleCreateRoles),
  takeLatest(DELETE_ROLE_REQUEST, handleDeleteRoles),
  takeLatest(EDIT_ROLE_NAME_REQUEST, handleEditRoles),
  takeLatest(EDIT_PERMISSIONS_REQUEST, handlePermissionsEdit),
]);
