import { ReactComponent as FundWithBank } from 'assets/icons/FundwithBank.svg';
const MoreActions = ({ fundCardMessage, handleClick }) => {
  if (!fundCardMessage) return <></>;
  return (
    <div className="mt-4">
      <div className="line-text">
        <span className="text">Or</span>
        <hr />
      </div>
      <div className="dialog-fund-action">
        {/* <div className="select-fund-type" onClick={handleClick}>
          <FundWithCard className="icon-style" />
          <span>
            {fundCardMessage ? fundCardMessage : 'Fund with Debit / Credit Card'}
          </span>
        </div> */}
        <div
          className="select-fund-type d-flex align-items-center justify-content-center"
          onClick={handleClick}
        >
          <FundWithBank className="icon-style" />
          <span>{fundCardMessage}</span>
          {/* <span className="balance-tag dark-bg ps-2">COMING SOON</span> */}
        </div>
      </div>
    </div>
  );
};
export default MoreActions;
