import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './styles.scss';

const SliderComponent = ({ contents = [] }) => {
  return (
    <div className="slider-container">
      <Swiper
        modules={[Pagination, Autoplay]}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="mySwiper"
      >
        {contents.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              <img src={item.imgSrc} alt="slider" className="w-100 h-100" />
              <h2>{item.title}</h2>
              <p>{item.desc}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderComponent;
