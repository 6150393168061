import {
  FETCH_CATEGORY_REQUEST,
  CREATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,
  FETCH_SINGLE_CATEGORY_REQUEST,
  GET_CATEGORY_STATISTICS_REQUEST,
  EXPORT_CATEGORY_REQUEST,
  CREATE_BULK_CATEGORIES_REQUEST,
  CREATE_CATEGORIZATION_RULES_REQUEST,
  FETCH_CATEGORIZATION_RULES_REQUEST,
  UPDATE_CATEGORIZATION_RULES_REQUEST,
  DELETE_CATEGORIZATION_RULES_REQUEST,
  FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST,
  UPDATE_BULK_CATEGORIES_REQUEST,
} from '../reducers/CategoryReducer';

export const fetchCategories = (payload) => ({
  type: FETCH_CATEGORY_REQUEST,
  payload,
});

export const fetchCategorizationRules = (payload) => ({
  type: FETCH_CATEGORIZATION_RULES_REQUEST,
  payload,
});

export const createBulkCategories = (payload) => ({
  type: CREATE_BULK_CATEGORIES_REQUEST,
  payload,
});

export const updateBulkCategories = (payload) => ({
  type: UPDATE_BULK_CATEGORIES_REQUEST,
  payload,
});

export const exportCategories = (payload) => ({
  type: EXPORT_CATEGORY_REQUEST,
  payload,
});

export const getCategoryStatistics = (payload) => ({
  type: GET_CATEGORY_STATISTICS_REQUEST,
  payload,
});

export const fetchSingleCategory = (payload) => ({
  type: FETCH_SINGLE_CATEGORY_REQUEST,
  payload,
});

export const fetchSingleCategorizationRule = (payload) => ({
  type: FETCH_SINGLE_CATEGORIZATION_RULE_REQUEST,
  payload,
});

export const createCategories = (payload) => ({
  type: CREATE_CATEGORY_REQUEST,
  payload,
});

export const createCategorizationRules = (payload) => ({
  type: CREATE_CATEGORIZATION_RULES_REQUEST,
  payload,
});

export const updateCategories = (payload) => ({
  type: UPDATE_CATEGORY_REQUEST,
  payload,
});

export const updateCategorizationRule = (payload) => ({
  type: UPDATE_CATEGORIZATION_RULES_REQUEST,
  payload,
});

export const deleteCategories = (id) => ({
  type: DELETE_CATEGORY_REQUEST,
  id,
});

export const deleteCategorizationRule = (payload) => ({
  type: DELETE_CATEGORIZATION_RULES_REQUEST,
  payload,
});
