export const TOGGLE_EXPENSE_REQUEST = 'TOGGLE_EXPENSE_REQUEST';
export const TOGGLE_EXPENSE_SUCCESS = 'TOGGLE_EXPENSE_SUCCESS';

const initialState = {
  toggleExpense: false,
  payload: null,
};

export const ToggleExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EXPENSE_SUCCESS:
      return {
        ...state,
        toggleExpense: !state.toggleExpense,
        payload: action?.payload,
      };
    default:
      return state;
  }
};
