import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from 'antd';
import { PlusIcon } from 'assets/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomSelect from 'components/UI/CustomSelect';

import { fetchAllCustomersAction } from 'redux/actions/InvoiceAction';

const Loader = () => {
  return (
    <>
      <section className="mt-4 pt-2">
        <Skeleton.Input
          active
          size={16}
          style={{ borderRadius: '4px', width: '13rem' }}
        />

        <div className="pt-4">
          <Skeleton.Input
            active
            size={12}
            style={{ borderRadius: '4px', width: '2rem' }}
          />

          <div className="d-flex gap-3">
            {' '}
            <Skeleton.Input
              active
              size={40}
              style={{ borderRadius: '4px', width: '22rem' }}
            />
            <Skeleton.Button
              active
              size={40}
              style={{ borderRadius: '4px', width: '5rem' }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const CustomerInfo = ({ getCustomerValue, values, isLoading, handleCustomerModal }) => {
  const dispatch = useDispatch();

  const {
    customers: { data: { customers = [] } = {} },
  } = useSelector(({ invoices }) => invoices);

  useEffect(() => {
    if (!customers.length) dispatch(fetchAllCustomersAction());
  }, []);

  const handleChange = (value) => {
    getCustomerValue({ target: { name: 'customer', value } });
  };

  const allCustomers = customers.map((customer) => {
    return {
      value: customer,
      label: customer?.name,
    };
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="mt-4 pt-2">
          <h2 className="region-header">Customer’s information</h2>
          <div className="customer-holder">
            <CustomSelect
              id="customer-select"
              classNamePrefix="invoice-select"
              label="Customer"
              placeholder="Select a customer"
              options={allCustomers}
              onChange={(value) => handleChange(value)}
              value={values.customer}
            />
            <CustomButton
              className="add-button"
              onClick={() => handleCustomerModal(true)}
            >
              <PlusIcon stroke="#ffffff" height="14" width="14" />
              <span>Add</span>
            </CustomButton>
          </div>
        </section>
      )}
    </>
  );
};

export default CustomerInfo;
