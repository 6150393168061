export const FETCH_APPROVAL_RULES_REQUEST = 'FETCH_APPROVAL_RULES_REQUEST';
export const FETCH_APPROVAL_RULES_ERROR = 'FETCH_APPROVAL_RULES_ERROR';
export const FETCH_APPROVAL_RULES_SUCCESS = 'FETCH_APPROVAL_RULES_SUCCESS';

export const FETCH_APPROVAL_RULES_ACTIVITY = 'FETCH_APPROVAL_RULES_ACTIVITY';

export const FETCH_APPROVAL_RULE_REQUEST = 'FETCH_APPROVAL_RULE_REQUEST';
export const FETCH_APPROVAL_RULE_ERROR = 'FETCH_APPROVAL_RULE_ERROR';
export const FETCH_APPROVAL_RULE_SUCCESS = 'FETCH_APPROVAL_RULE_SUCCESS';

export const CREATE_APPROVAL_RULE_REQUEST = 'CREATE_APPROVAL_RULE_REQUEST';
export const CREATE_APPROVAL_RULE_ERROR = 'CREATE_APPROVAL_RULE_ERROR';
export const CREATE_APPROVAL_RULE_SUCCESS = 'CREATE_APPROVAL_RULE_SUCCESS';

export const DELETE_APPROVAL_RULE_REQUEST = 'DELETE_APPROVAL_RULE_REQUEST';
export const DELETE_APPROVAL_RULE_ERROR = 'DELETE_APPROVAL_RULE_ERROR';
export const DELETE_APPROVAL_RULE_SUCCESS = 'DELETE_APPROVAL_RULE_SUCCESS';

export const FETCH_TRANSACTION_TYPES_REQUEST = 'FETCH_TRANSACTION_TYPES_REQUEST';
export const FETCH_TRANSACTION_TYPES_SUCCESS = 'FETCH_TRANSACTION_TYPES_SUCCESS';
export const FETCH_TRANSACTION_TYPES_ERROR = 'FETCH_TRANSACTION_TYPES_ERROR';

export const FETCH_APPROVERS_REQUEST = 'FETCH_APPROVERS_REQUEST';
export const FETCH_APPROVERS_SUCCESS = 'FETCH_APPROVERS_SUCCESS';
export const FETCH_APPROVERS_ERROR = 'FETCH_APPROVERS_ERROR';

export const FETCH_PENDING_APPROVALS_REQUEST = 'FETCH_PENDING_APPROVALS_REQUEST';
export const FETCH_PENDING_APPROVALS_SUCCESS = 'FETCH_PENDING_APPROVALS_SUCCESS';
export const FETCH_PENDING_APPROVALS_ERROR = 'FETCH_PENDING_APPROVALS_ERROR';

export const REVIEW_REQUEST_REQUEST = 'REVIEW_REQUEST_REQUEST';
export const REVIEW_REQUEST_SUCCESS = 'REVIEW_REQUEST_SUCCESS';
export const REVIEW_REQUEST_ERROR = 'REVIEW_REQUEST_ERROR';

export const REVIEW_MULTIPLE_REQUEST_REQUEST = 'REVIEW_MULTIPLE_REQUEST_REQUEST';
export const REVIEW_MULTIPLE_REQUEST_SUCCESS = 'REVIEW_MULTIPLE_REQUEST_SUCCESS';
export const REVIEW_MULTIPLE_REQUEST_ERROR = 'REVIEW_MULTIPLE_REQUEST_ERROR';

export const REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST =
  'REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST';
export const REVIEW_MULTIPLE_PENDING_REQUEST_SUCCESS =
  'REVIEW_MULTIPLE_PENDING_REQUEST_SUCCESS';
export const REVIEW_MULTIPLE_PENDING_REQUEST_ERROR =
  'REVIEW_MULTIPLE_PENDING_REQUEST_ERROR';

export const EDIT_APPROVAL_RULE_BY_ID_REQUEST = 'EDIT_APPROVAL_RULE_BY_ID_REQUEST';
export const EDIT_APPROVAL_RULE_BY_ID_SUCCESS = 'EDIT_APPROVAL_RULE_BY_ID_SUCCESS';
export const EDIT_APPROVAL_RULE_BY_ID_ERROR = 'EDIT_APPROVAL_RULE_BY_ID_ERROR';

export const DELETE_SINGLE_APPROVER_SUCCESS = 'DELETE_SINGLE_APPROVER_SUCCESS';
export const DELETE_SINGLE_APPROVER_REQUEST = 'DELETE_SINGLE_APPROVER_REQUEST';
export const DELETE_SINGLE_APPROVER_ERROR = 'DELETE_SINGLE_APPROVER_ERROR';

export const ADD_APPROVER_SUCCESS = 'DELETE_SINGLE_APPROVER_SUCCESS';
export const ADD_APPROVER_REQUEST = 'ADD_APPROVER_REQUEST';
export const ADD_APPROVER_ERROR = 'ADD_APPROVER_ERROR';

export const DELETE_CONDITION_SUCCESS = 'DELETE_CONDITION_SUCCESS';
export const DELETE_CONDITION_REQUEST = 'DELETE_CONDITION_REQUEST';
export const DELETE_CONDITION_ERROR = 'DELETE_CONDITION_ERROR';

export const ADD_CONDITION_SUCCESS = 'ADD_CONDITION_SUCCESS';
export const ADD_CONDITION_REQUEST = 'ADD_CONDITION_REQUEST';
export const ADD_CONDITION_ERROR = 'ADD_CONDITION_ERROR';

export const ADD_NEW_APPROVAL_LEVEL_REQUEST = 'ADD_NEW_APPROVAL_LEVEL_REQUEST';
export const ADD_NEW_APPROVAL_LEVEL_SUCCESS = 'ADD_NEW_APPROVAL_LEVEL_SUCCESS';
export const ADD_NEW_APPROVAL_LEVEL_ERROR = 'ADD_NEW_APPROVAL_LEVEL_ERROR';

export const FETCH_SINGLE_APPROVAL_REQUEST = 'FETCH_SINGLE_APPROVAL_REQUEST';
export const FETCH_SINGLE_APPROVAL_SUCCESS = 'FETCH_SINGLE_APPROVAL_SUCCESS';
export const FETCH_SINGLE_APPROVAL_ERROR = 'FETCH_SINGLE_APPROVAL_ERROR';

export const RESET_CREATE_APPROVAL_RULE = 'RESET_CREATE_APPROVAL_RULE';

export const DELETE_APPROVAL_LEVEL_REQUEST = 'DELETE_APPROVAL_LEVEL_REQUEST';
export const DELETE_APPROVAL_LEVEL_SUCCESS = 'DELETE_APPROVAL_LEVEL_SUCCESS';
export const DELETE_APPROVAL_LEVEL_ERROR = 'DELETE_APPROVAL_LEVEL_ERROR';
export const RESET_APPROVAL_LEVEL_RULE = 'RESET_APPROVAL_LEVEL_RULE';

export const FETCH_RELATED_APPROVALS_REQUEST = 'FETCH_RELATED_APPROVALS_REQUEST';
export const FETCH_RELATED_APPROVALS_SUCCESS = 'FETCH_RELATED_APPROVALS_SUCCESS';
export const FETCH_RELATED_APPROVALS_ERROR = 'FETCH_RELATED_APPROVALS_ERROR';

export const RESET_BLOCK_APPROVAL = 'RESET_BLOCK_APPROVAL';
export const RESET_FLAGS_APPROVAL = 'RESET_FLAGS_APPROVAL';

export const FETCH_PENDING_APPROVAL_ASSET_REQUEST =
  'FETCH_PENDING_APPROVAL_ASSET_REQUEST';
export const FETCH_PENDING_APPROVAL_ASSET_SUCCESS =
  'FETCH_PENDING_APPROVAL_ASSET_SUCCESS';
export const FETCH_PENDING_APPROVAL_ASSET_ERROR = 'FETCH_PENDING_APPROVAL_ASSET_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  fetchApprovalRules: { ...block },
  fetchApprovalRule: { ...block },
  fetchApprovalRuleActivity: {
    isEditing: false,
    selectedRuleCode: null,
    selectedRule: null,
  },
  createApprovalRule: { ...block },
  fetchTransactionTypes: { ...block },
  fetchApprovers: { ...block },
  deleteApprovers: { ...block },
  deleteSingleApprover: { ...block },
  addNewApproverLevel: { ...block },
  fetchPendingApprovals: { ...block },
  fetchApprovedApprovals: { ...block },
  reviewRequest: { ...block },
  reviewMultipleRequests: { ...block },
  reviewMultiplePendingRequests: { ...block },
  editApprovalRule: { ...block },
  deleteCondition: { ...block },
  addCondition: { ...block },
  deleteApprovalLevel: { ...block },
  fetchSingleApproval: { ...block },
  pendingApprovalAssets: { ...block },
  relatedApprovals: { ...block },
};

export const ApprovalReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPROVAL_RULES_REQUEST:
      return {
        ...state,
        fetchApprovalRules: { ...state.fetchApprovalRules, loading: true },
      };
    case FETCH_APPROVAL_RULES_SUCCESS:
      return {
        ...state,
        fetchApprovalRules: {
          ...state.fetchApprovalRules,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_APPROVAL_RULES_ERROR:
      return {
        ...state,
        fetchApprovalRules: {
          ...state.fetchApprovalRules,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_APPROVAL_RULES_ACTIVITY:
      return {
        ...state,
        fetchApprovalRuleActivity: {
          isEditing:
            action?.payload?.isEditing ?? state.fetchApprovalRuleActivity.isEditing,
          selectedRuleCode:
            action?.payload?.selectedRuleCode ??
            state.fetchApprovalRuleActivity.selectedRuleCode,
          selectedRule:
            action?.payload?.selectedRule ?? state.fetchApprovalRuleActivity.selectedRule,
        },
      };

    case FETCH_APPROVAL_RULE_REQUEST:
      return {
        ...state,
        fetchApprovalRule: { ...state.fetchApprovalRule, loading: true },
      };
    case FETCH_APPROVAL_RULE_SUCCESS:
      return {
        ...state,
        fetchApprovalRule: {
          ...state.fetchApprovalRule,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_APPROVAL_RULE_ERROR:
      return {
        ...state,
        fetchApprovalRule: {
          ...state.fetchApprovalRule,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_APPROVAL_RULE_REQUEST:
      return {
        ...state,
        createApprovalRule: { ...state.createApprovalRule, loading: true },
      };
    case CREATE_APPROVAL_RULE_SUCCESS:
      return {
        ...state,
        createApprovalRule: {
          ...state.createApprovalRule,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_APPROVAL_RULE_ERROR:
      return {
        ...state,
        createApprovalRule: {
          ...state.createApprovalRule,
          loading: false,
          error: action.error,
        },
      };
    case RESET_CREATE_APPROVAL_RULE:
      return {
        ...state,
        createApprovalRule: {
          ...state.createApprovalRule,
          success: false,
          loading: false,
          error: '',
        },
      };

    case EDIT_APPROVAL_RULE_BY_ID_REQUEST:
      return {
        ...state,
        editApprovalRule: { ...state.editApprovalRule, loading: true },
      };
    case EDIT_APPROVAL_RULE_BY_ID_SUCCESS:
      return {
        ...state,
        editApprovalRule: {
          ...state.editApprovalRule,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EDIT_APPROVAL_RULE_BY_ID_ERROR:
      return {
        ...state,
        editApprovalRule: {
          ...state.editApprovalRule,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_APPROVAL_RULE_REQUEST:
      return {
        ...state,
        deleteApprovers: { ...state.deleteApprovers, loading: true },
      };
    case DELETE_APPROVAL_RULE_SUCCESS:
      return {
        ...state,
        deleteApprovers: {
          ...state.deleteApprovers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_APPROVAL_RULE_ERROR:
      return {
        ...state,
        deleteApprovers: {
          ...state.deleteApprovers,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_SINGLE_APPROVER_REQUEST:
      return {
        ...state,
        deleteSingleApprover: { ...state.deleteSingleApprover, loading: true },
      };
    case DELETE_SINGLE_APPROVER_SUCCESS:
      return {
        ...state,
        deleteSingleApprover: {
          ...state.deleteSingleApprover,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_SINGLE_APPROVER_ERROR:
      return {
        ...state,
        deleteSingleApprover: {
          ...state.deleteApprovers,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_APPROVAL_LEVEL_REQUEST:
      return {
        ...state,
        deleteApprovalLevel: {
          ...state.deleteApprovalLevel,
          loading: true,
        },
      };

    case DELETE_APPROVAL_LEVEL_SUCCESS:
      return {
        ...state,
        deleteApprovalLevel: {
          ...state.deleteApprovalLevel,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case DELETE_APPROVAL_LEVEL_ERROR:
      return {
        ...state,
        deleteApprovalLevel: {
          ...state.deleteApprovalLevel,
          loading: false,
          error: action.error,
        },
      };

    case RESET_APPROVAL_LEVEL_RULE:
      return {
        ...state,
        deleteApprovalLevel: {
          ...state.deleteApprovalLevel,
          success: false,
          loading: false,
          error: '',
        },
      };

    case ADD_NEW_APPROVAL_LEVEL_REQUEST:
      return {
        ...state,
        addNewApproverLevel: { ...state.addNewApproverLevel, loading: true },
      };
    case ADD_NEW_APPROVAL_LEVEL_SUCCESS:
      return {
        ...state,
        addNewApproverLevel: {
          ...state.addNewApproverLevel,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_NEW_APPROVAL_LEVEL_ERROR:
      return {
        ...state,
        addNewApproverLevel: {
          ...state.addNewApproverLevel,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_CONDITION_REQUEST:
      return {
        ...state,
        deleteCondition: { ...state.deleteCondition, loading: true },
      };
    case DELETE_CONDITION_SUCCESS:
      return {
        ...state,
        deleteCondition: {
          ...state.deleteCondition,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_CONDITION_ERROR:
      return {
        ...state,
        deleteCondition: {
          ...state.deleteCondition,
          loading: false,
          error: action.error,
        },
      };

    case ADD_CONDITION_REQUEST:
      return {
        ...state,
        addCondition: { ...state.addCondition, loading: true },
      };
    case ADD_CONDITION_SUCCESS:
      return {
        ...state,
        addCondition: {
          ...state.addCondition,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_CONDITION_ERROR:
      return {
        ...state,
        addCondition: {
          ...state.addCondition,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_TRANSACTION_TYPES_REQUEST:
      return {
        ...state,
        fetchTransactionTypes: { ...state.fetchTransactionTypes, loading: true },
      };
    case FETCH_TRANSACTION_TYPES_SUCCESS:
      return {
        ...state,
        fetchTransactionTypes: {
          ...state.fetchTransactionTypes,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_TRANSACTION_TYPES_ERROR:
      return {
        ...state,
        fetchTransactionTypes: {
          ...state.fetchTransactionTypes,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_APPROVERS_REQUEST:
      return {
        ...state,
        fetchApprovers: { ...state.fetchApprovers, loading: true },
      };
    case FETCH_APPROVERS_SUCCESS:
      return {
        ...state,
        fetchApprovers: {
          ...state.fetchApprovers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_APPROVERS_ERROR:
      return {
        ...state,
        fetchApprovers: {
          ...state.fetchApprovers,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_PENDING_APPROVALS_REQUEST:
      return {
        ...state,
        fetchPendingApprovals: { ...state.fetchPendingApprovals, loading: true },
      };
    case FETCH_PENDING_APPROVALS_SUCCESS:
      return {
        ...state,
        fetchPendingApprovals: {
          ...state.fetchPendingApprovals,
          loading: false,
          success: true,
          error: '',
          data: action.data,
          meta: action.meta,
          summary: action.summary,
        },
      };
    case FETCH_PENDING_APPROVALS_ERROR:
      return {
        ...state,
        fetchPendingApprovals: {
          ...state.fetchPendingApprovals,
          loading: false,
          error: action.error,
        },
      };

    case REVIEW_REQUEST_REQUEST:
      return {
        ...state,
        reviewRequest: { ...state.reviewRequest, loading: true },
      };
    case REVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        reviewRequest: {
          ...state.reviewRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_REQUEST_ERROR:
      return {
        ...state,
        reviewRequest: {
          ...state.reviewRequest,
          loading: false,
          error: action.error,
        },
      };

    case REVIEW_MULTIPLE_REQUEST_REQUEST:
      return {
        ...state,
        reviewMultipleRequests: { ...state.reviewMultipleRequests, loading: true },
      };
    case REVIEW_MULTIPLE_REQUEST_SUCCESS:
      return {
        ...state,
        reviewMultipleRequests: {
          ...state.reviewMultipleRequests,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_MULTIPLE_REQUEST_ERROR:
      return {
        ...state,
        reviewMultipleRequests: {
          ...state.reviewMultipleRequests,
          loading: false,
          error: action.error,
        },
      };

    case REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST:
      return {
        ...state,
        reviewMultiplePendingRequests: {
          ...state.reviewMultiplePendingRequests,
          loading: true,
        },
      };
    case REVIEW_MULTIPLE_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        reviewMultiplePendingRequests: {
          ...state.reviewMultiplePendingRequests,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_MULTIPLE_PENDING_REQUEST_ERROR:
      return {
        ...state,
        reviewMultiplePendingRequests: {
          ...state.reviewMultiplePendingRequests,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_SINGLE_APPROVAL_REQUEST:
      return {
        ...state,
        fetchSingleApproval: { ...state.fetchSingleApproval, loading: true },
      };
    case FETCH_SINGLE_APPROVAL_SUCCESS:
      return {
        ...state,
        fetchSingleApproval: {
          ...state.fetchSingleApproval,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_SINGLE_APPROVAL_ERROR:
      return {
        ...state,
        fetchSingleApproval: {
          ...state.fetchSingleApproval,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_PENDING_APPROVAL_ASSET_REQUEST:
      return {
        ...state,
        pendingApprovalAssets: { ...state.pendingApprovalAssets, loading: true },
      };
    case FETCH_PENDING_APPROVAL_ASSET_SUCCESS:
      return {
        ...state,
        pendingApprovalAssets: {
          ...state.pendingApprovalAssets,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case FETCH_PENDING_APPROVAL_ASSET_ERROR:
      return {
        ...state,
        pendingApprovalAssets: {
          ...state.pendingApprovalAssets,
          loading: false,
          error: action.error,
        },
      };

    case FETCH_RELATED_APPROVALS_REQUEST:
      return {
        ...state,
        relatedApprovals: { ...state.relatedApprovals, loading: true },
      };

    case FETCH_RELATED_APPROVALS_SUCCESS: {
      return {
        ...state,
        relatedApprovals: {
          ...state.relatedApprovals,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    }

    case FETCH_RELATED_APPROVALS_ERROR: {
      return {
        ...state,
        relatedApprovals: {
          ...state.relatedApprovals,
          loading: false,
          success: false,
          error: action.error,
        },
      };
    }

    case RESET_BLOCK_APPROVAL:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    case RESET_FLAGS_APPROVAL:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
