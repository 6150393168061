import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import { Radio } from 'antd';
import { PlusDropDownIcon } from 'assets/icons';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import useTextCounter from 'hooks/useTextCounter';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createCategorizationRules } from 'redux/actions/CategoryAction';
import ConditionRowComponent from './Components/ConditionRowComponent';
import './styles.scss';
import { flattenCategories } from 'utils/helper';

const CreateCategorizationRules = () => {
  const initial = {
    description: undefined,
    name: undefined,
    category: undefined,
    operator: 'or',
  };

  const categorizationOptions = [
    {
      value: 'startsWith',
      label: 'Starts with',
    },
    {
      value: 'endsWith',
      label: 'Ends with',
    },
    {
      value: 'contains',
      label: 'Contains',
    },
    {
      value: 'matches',
      label: 'Regular expression',
    },
  ];

  const dispatch = useDispatch();
  const { categoryCode } = useParams();
  const history = useHistory();

  const {
    createCategorizationRules: { success: createSuccess, loading: createLoading },
  } = useSelector(({ categories }) => categories);

  const [selectedCategorizationTypes, setSelectedCategorizationTypes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [categorizationConditions, setCategorizationConditions] = useState({
    rules: [
      {
        name: '',
        'condition-one': {
          options: categorizationOptions,
          selectedValue: null,
        },
        'condition-two': {
          selectedValue: null,
        },
      },
    ],
  });

  const [categorizationData, setCategorizationData] = useState({ ...initial });

  useEffect(() => {
    if (createSuccess) {
      history.push(`/compliances/categories/${categoryCode}/categorization-rules`);
    }
  }, [createSuccess]);

  // useEffect(() => {
  //   let newRules = categorizationConditions.rules.slice();
  //   for (let i = 0; i < newRules.length; i++) {
  //     if (i !== currentIndex) {
  //       newRules[i]['condition-one'].options = categorizationOptions.filter(
  //         (item) =>
  //           !selectedCategorizationTypes.includes(item.value) ||
  //           item.value === newRules[i]['condition-one'].selectedValue?.value,
  //       );
  //     }
  //   }
  //   setCategorizationConditions((prevValues) => ({ ...prevValues, rules: newRules }));
  // }, [selectedCategorizationTypes, currentIndex]);

  useEffect(() => {
    let newRules = categorizationConditions.rules.slice();
    if (categorizationData?.operator === 'or') {
      for (let i = 0; i < newRules.length; i++) {
        newRules[i]['condition-one'].options = categorizationOptions;
      }
    } else {
      for (let i = 0; i < newRules.length; i++) {
        newRules[i]['condition-one'].options = categorizationOptions.filter(
          (item) =>
            !selectedCategorizationTypes.slice(0, i).includes(item.value) ||
            item.value === newRules[i]['condition-one'].selectedValue?.value,
        );
      }
    }
    setCategorizationConditions((prevValues) => ({ ...prevValues, rules: newRules }));
  }, [categorizationData?.operator]);

  const onHandleCancel = () => {
    history.goBack(-1);
  };

  const handleConditionChange = (value, index, level) => {
    setCurrentIndex(index);
    let newRules = categorizationConditions.rules.slice();
    let selectedRule = newRules[index];
    selectedRule[`condition-${level}`].selectedValue = value;

    switch (level) {
      case 'one':
        setSelectedCategorizationTypes((prevValue) => {
          const newArray = prevValue.slice();
          if (!['contains', 'matches'].includes(value.value)) newArray.push(value.value);
          return newArray;
        });
        setConditionOneOptions(newRules, index, value);
        break;
      case 'two':
        setConditionTwoOptions(newRules, index, value);
        break;
    }

    setCategorizationConditions({ ...categorizationConditions, rules: newRules });
  };

  const setConditionOneOptions = (newRules, index, value, isEdit = false) => {
    if (!isEdit) {
      newRules.forEach((item, ruleIndex) => {
        if (item['condition-one'].selectedValue?.value === 'amount') {
          amountCount += 1;
          if (index !== ruleIndex) {
            otherAmountConditionIndex = ruleIndex;
          }
        }
      });
    }
  };

  const setConditionTwoOptions = (newRules, index, value) => {
    newRules[index]['condition-two'].selectedValue = value;
  };

  const addNewCondition = () => {
    let newRules = categorizationConditions.rules.slice();

    const previousConditionOneSelectedVal =
      newRules.at(-1)['condition-one'].selectedValue?.value;

    const previousConditionOneSelectedOpt = newRules
      .at(-1)
      ['condition-one'].options.slice();

    let conditionOptions =
      previousConditionOneSelectedVal && categorizationData?.operator === 'and'
        ? previousConditionOneSelectedOpt.filter(
            (item) =>
              item.value !== previousConditionOneSelectedVal ||
              ['contains', 'matches'].includes(previousConditionOneSelectedVal),
          )
        : previousConditionOneSelectedOpt;

    newRules.push({
      name: '',
      'condition-one': {
        options: conditionOptions,
        selectedValue: null,
      },
      'condition-two': {
        selectedValue: null,
      },
    });
    setCategorizationConditions((prevValue) => ({ ...prevValue, rules: newRules }));
  };

  const removeCondition = (index) => {
    let newRules = categorizationConditions.rules.slice();
    let removedItem = newRules.splice(index, 1);
    setSelectedCategorizationTypes((prevValue) => {
      let newArray = prevValue.filter(
        (item) => item !== removedItem[0]?.['condition-one'].selectedValue?.value,
      );
      return newArray;
    });
    setCategorizationConditions((prevValue) => ({ ...prevValue, rules: newRules }));
  };

  const handleDescriptionChange = (value) => {
    setCategorizationData({
      ...categorizationData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    categorizationData?.description,
    250,
    handleDescriptionChange,
  );

  const formValidation = (formData) => {
    const { name, rules } = formData;
    if (!name) {
      return 'Name is required';
    }

    const position = ['first', 'second', 'third', 'fourth'];
    let index = 0;
    for (let rule of rules) {
      if (!rule['condition-one'].selectedValue || !rule['condition-two'].selectedValue)
        return `Please fill all fields in the ${position[index]} condition`;
      index++;
    }
    return true;
  };

  const handleSubmit = () => {
    const { description, name, category, operator } = categorizationData;

    const checkcategorizationData = formValidation({
      ...categorizationData,
      rules: categorizationConditions.rules,
    });
    if (checkcategorizationData !== true) {
      return toastError(checkcategorizationData);
    }

    const conditions = categorizationConditions?.rules?.map((item) => ({
      operator: item['condition-one'].selectedValue.value,
      operand: item['condition-two'].selectedValue,
    }));

    const payload = {
      code: category?.value ?? categoryCode,
      description,
      name,
      operator,
      conditions,
    };

    dispatch(createCategorizationRules(payload));
  };

  const filterCategoryList = (categoryList = []) => {
    const filteredList = categoryList?.filter(
      (item) =>
        item?.code === categoryCode ||
        item?.children?.map((opt) => opt?.code)?.includes(categoryCode),
    );
    const options = flattenCategories(filteredList)?.map((opt) => ({
      label: opt?.name,
      value: opt?.code,
    }));
    setCategorizationData({
      ...categorizationData,
      category: options?.find((item) => item?.value === categoryCode),
    });

    return filteredList;
  };

  return (
    <div className="pt-5 pb-3 mx-auto policy-page-body">
      <div className="approval-header">
        <div className="back-click mb-4" onClick={onHandleCancel}>
          <ArrowLeftOutlined />
          Back
        </div>
      </div>

      <div className="information-wrapper form-wrapper__2 w-100 position-relative">
        <form>
          <div className="d-flex flex-sm-wrap align-items-start justify-content-between">
            <div>
              {' '}
              <h6 className="text-lg">Create categorization rule</h6>
              <p className="text-sm">Define requirements for the rule</p>
            </div>
            <div className="d-flex">
              <CustomButton
                onClick={onHandleCancel}
                className="add-button add-action me-3"
                customClass={true}
              >
                Cancel
              </CustomButton>
              <CustomButton
                className="add-button dark-button"
                customClass={true}
                onClick={handleSubmit}
                loading={createLoading}
              >
                Create
              </CustomButton>
            </div>
          </div>
          <div className="divider"></div>
          <Row>
            <CustomInput
              label="Rule name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              className="mb-3"
              id="name"
              onChange={(event) =>
                setCategorizationData({ ...categorizationData, name: event.target.value })
              }
              value={categorizationData?.name}
              md={8}
            />
            <Col className="mb-3" md={8}>
              <CustomTextarea
                label="Description(Optional)"
                name="description"
                placeholder="Enter description"
                value={text || categorizationData?.description}
                labelClass="text-sm"
                charCount={charCount}
                onChange={handleCharChange}
                rowSize={4}
              />
            </Col>
          </Row>

          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Transactions corresponding to</h6>
            <span>Set how the rule should apply.</span>
          </Row>
          <Row className="mb-3">
            <Col md={8}>
              {[
                {
                  label: 'Any of the following',
                  subtext: 'The rule will apply to any of the conditions set',
                  value: 'or',
                },
                {
                  label: 'All of the following',
                  subtext: 'The rule would apply to all conditions set',
                  value: 'and',
                },
              ].map((item, index) => (
                <div key={index} className="mb-2">
                  <Radio
                    checked={item.value === categorizationData?.operator}
                    onChange={() =>
                      setCategorizationData({
                        ...categorizationData,
                        operator: item.value,
                      })
                    }
                  >
                    <span className="text-sm">{item.label}</span>
                    <br />
                    <span style={{ color: '#79716B' }} className="text-xs">
                      {item.subtext}
                    </span>
                  </Radio>
                </div>
              ))}
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Conditions</h6>
            <span>Set the criteria for the rule.</span>
          </Row>
          <Row className="mb-3">
            <ConditionRowComponent
              rules={categorizationConditions?.rules}
              removeCondition={removeCondition}
              handleConditionChange={handleConditionChange}
              operator={categorizationData?.operator}
            />

            <div
              className="add-button condition-cta mt-2"
              role="button"
              onClick={
                categorizationConditions?.rules.length === 5
                  ? () => toastError("You can't add more than 5 conditions")
                  : () => addNewCondition()
              }
            >
              <PlusDropDownIcon className="me-1" /> <span>Add condition</span>
            </div>
          </Row>
          <div className="divider"></div>
          <Row className="mb-2 policy-rule-styles">
            <h6>Categorise as</h6>
          </Row>
          <Row className="mb-3 mt-3">
            <Col md={8}>
              <CategoryDropdown
                placeholder="Select a category"
                onChange={(value) =>
                  setCategorizationData({ ...categorizationData, category: value })
                }
                value={categorizationData.category}
                name="category"
                categoryFilter={filterCategoryList}
              />
            </Col>
          </Row>
        </form>

        <div className="">
          <div className="divider"></div>

          <div className="d-flex flex-wrap justify-content-end mt-3">
            <CustomButton
              onClick={onHandleCancel}
              className="add-button add-action me-3"
              customClass={true}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className="add-button dark-button"
              customClass={true}
              onClick={handleSubmit}
              loading={createLoading}
            >
              Create
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategorizationRules;
