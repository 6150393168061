import { CreditCardPlus } from 'assets/icons';
import CardsImage from '../../assets/images/emptystate/card-image.png';
import EmptyState from 'components/UI/EmptyState';

import AllowedTo from 'utils/AllowedTo';
import CustomButton from 'components/UI/CustomButton';
import { MultipleCards, SingleCard } from 'assets/icons/empty-state-icons';

const CardsEmptyState = ({ toggleHandler }) => {
  const handleSupport = () => {
    window.open('https://www.bujeti.com/corporate-cards', '_blank');
  };

  const childrenData = [
    {
      title: 'Create a physical card',
      body: 'Initiate individual or grouped payments to vendors, suppliers, or colleagues.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CreditCardPlus />,
    },

    {
      title: 'Create multi-use virtual cards',
      body: 'Plan and schedule payments in advance to ensure timeliness and avoid likely penalties.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <MultipleCards />,
    },
    {
      title: 'Create single-use virtual cards',
      body: "Maintain control and compliance with your organization's policies and guidelines.",
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SingleCard />,
    },
  ];

  const mainData = {
    title: 'View, issue, fund, or deactivate cards as needed.',
    body: 'Generate physical, virtual, and flash cards for your team to spend flexibly and streamline expense tracking on Bujeti',
    image: CardsImage,
    action: () => (
      <AllowedTo scopes={['card-*', 'card-create']}>
        <CustomButton className="empty-add-button w-fit" onClick={toggleHandler}>
          Create a card
        </CustomButton>
      </AllowedTo>
    ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default CardsEmptyState;
