// src/sagas/index.js
// import { Axios } from 'api/axios';
// import axios from 'axios';
import { fileAxios } from 'api/fileAxios';
import axios from 'axios';
import EventEmitter from 'eventemitter3';
import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fileUploadComplete,
  resetUploadState,
  uploadFileCancelled,
  uploadFileFailure,
  uploadFileProgress,
  uploadFileSuccess,
} from 'redux/actions/ProgressUploadAction';
import {
  FILES_UPLOAD_CANCEL,
  FILES_UPLOAD_REQUEST,
  RETRY_FILES_UPLOAD,
} from 'redux/reducers/ProgressUploadReducer';

export const eventEmitter = new EventEmitter();
const taskMap = new Map();

function* uploadFile(file, cancelToken, name, scan) {
  const abortController = new AbortController();
  const Axios = fileAxios(abortController);

  try {
    const addNewFile = (file) => {
      const payload = {
        fileName: file.name,
        fileMime: file.type,
      };

      return Axios.post('/files/url/upload', payload, { cancelToken });
    };

    const signUrl = ({ signedUrl, file }) => {
      return axios.put(signedUrl, file, {
        headers: { 'Content-Type': file?.type },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          eventEmitter.emit('progress', { file, progress, name });
        },
      });
    };

    const { data } = yield call(addNewFile, file);

    const { key, originalFilename, signedUrl, url } = data;

    yield call(signUrl, { signedUrl, file });

    const fileType = file?.type.split('/')[1];

    const assetPayload = {
      key: key,
      name: file?.name,
      type: fileType,
    };

    const callUrl = `/assets${scan ? '?scan=true' : ''}`;
    const getAsset = yield call(Axios.post, callUrl, assetPayload, { cancelToken });

    const {
      data: { asset },
    } = getAsset;

    const assetData = {
      assetCode: asset.code,
      key,
      originalFilename,
      signedUrl,
      url,
      fileType: file?.type,
      fileName: file?.name,
      id: file.id,
      size: file.size,
      ...(scan && { scannedData: asset?.scannedData || {} }),
    };

    return assetData;
  } catch (error) {
    if (axios.isCancel(error)) {
      yield put(uploadFileCancelled(file, name));
      return null;
    } else {
      yield put(uploadFileFailure(file, error, name));
      throw error;
    }
  }
}

function* handleFileUploads(files, name, scan = false) {
  const assetData = [];

  for (const file of files) {
    const cancelTokenSource = axios.CancelToken.source();
    taskMap.set(file.id, cancelTokenSource);

    try {
      const result = yield call(uploadFile, file, cancelTokenSource.token, name, scan);

      if (result) {
        assetData.push(result);
        yield put(uploadFileSuccess([result], name));
      }
    } catch (error) {
      // Handle individual file error
    } finally {
      taskMap.delete(file.id);
    }
  }

  if (assetData.length > 0) {
    yield put(fileUploadComplete({ name }));

    const currentPath = window.location.pathname;
    const isBillReview = currentPath.includes('bills') && currentPath.includes('review');

    if (!scan && !isBillReview) {
      yield delay(200);
      yield put(resetUploadState({ name }));
    }
  }
}

function* handleUploadFileCancel(action) {
  const { id } = action.payload.file;

  const cancelTokenSource = taskMap.get(id);
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Upload cancelled');
    yield put(uploadFileCancelled({ id, name: action.payload.name }));
    taskMap.delete(id);
  }
}

function* watchUploadFiles({ payload }) {
  yield fork(handleFileUploads, payload.files, payload?.name, payload?.scan);

  eventEmitter.on('progress', function* ({ file, progress, name }) {
    yield put(uploadFileProgress(file, progress, name));
  });
}

function* retryUploadFile({ payload }) {
  yield call(handleFileUploads, [payload.file], payload?.name, payload?.scan);
}

export default all([
  takeEvery(FILES_UPLOAD_REQUEST, watchUploadFiles),
  takeEvery(RETRY_FILES_UPLOAD, retryUploadFile),
  takeEvery(FILES_UPLOAD_CANCEL, handleUploadFileCancel),
]);
