import { getFormattedDate } from 'utils/helper';
import './timeline.styles.scss';

const DetailsTimeline = ({ data, action }) => {
  return (
    <div className="timeline-container">
      <ul className="timeline">
        {data?.map((item, uniqueIndex) => {
          const singleItemClass = data?.length === 1 && 'hide-dot';

          return (
            <li className={`timeline-item ${singleItemClass}`} key={uniqueIndex}>
              <div>
                <h6 className="timeline-title">
                  More information request
                  <span className="timeline-rulename" onClick={action}>
                    <>
                      View details
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.8335 14.1663L14.1668 5.83301M14.1668 5.83301H5.8335M14.1668 5.83301V14.1663"
                          stroke="#D28B28"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </>
                  </span>
                </h6>

                <div className="d-flex name-wrapper">
                  {renderTimeLineItems(item, uniqueIndex)}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const renderTimeLineItems = (item, uniqueIndex) => {
  return (
    <div className="d-flex timeline-details" key={uniqueIndex}>
      <span className="timeline-thumbnail thumbnail-small thumbnail-primary text-xs">
        {item?.reviewer?.firstName.split('')[0]}
      </span>
      <div>
        <h6 className="timeline-item-name">
          {item?.reviewer?.firstName} {item?.reviewer?.lastName}
          <span>{getFormattedDate(item?.createdAt)}</span>
        </h6>
        {item?.info && <p className="timeline-item-description">{item?.info}</p>}
      </div>
    </div>
  );
};

export default DetailsTimeline;
