import { EditPencilIcon, TrashIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import Table from 'components/Table';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  deleteCategories,
  exportCategories,
  fetchCategories,
} from 'redux/actions/CategoryAction';
import { buildCategoriesTableData, buildExportCategoriesData } from 'utils/helper';
import { categoryColumns, exportCategoriesColumns } from 'utils/mockData';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';
import CategoryEmptyState from './Components/CategoryEmptyState';
import EditCategory from './EditCategory';
import EditSubcategory from './EditSubcategory';
import SearchLoader from './Components/SearchLoader';
import CategoryHeader from './Components/CategoryHeader';

const Category = ({ handleSelect = () => null }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    fetchCategories: { data: { categories = [], meta = {} } = {}, loading },
    exportCategories: {
      data: { categories: exportData = [] } = {},
      loading: exportLoading,
    },
    deleteCategories: { loading: loadingDelete, success: deleteSuccess },
    updateCategories: { success: updateSuccess },
  } = useSelector(({ categories }) => categories);

  const { page = 1, total, hasMore = false, perPage = 50, nextPage = 2 } = meta;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const [selectedData, setSelectedData] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [type, setType] = useState('');

  const columns = useMemo(() => categoryColumns, [categories]);
  const rows = useMemo(() => buildCategoriesTableData(categories), [categories]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(fetchCategories({ ...filteredQuery }));
      setSelectedData(null);
      setShowDeleteModal(false);
    }
    if (updateSuccess) {
      dispatch(fetchCategories({ ...filteredQuery }));
      setSelectedData(null);
      setShowEditModal(false);
    }
  }, [deleteSuccess, updateSuccess]);

  const handlePreviousPage = (page) => {
    dispatch(fetchCategories({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(fetchCategories({ perPage, page, ...filteredQuery }));
  };

  const actionHandler = (event, type, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    const { code, type: catType } = data;
    setType(catType);
    if (type === 'delete') {
      setSelectedData({ code });

      setShowDeleteModal(true);
    }
    if (type === 'edit') {
      setSelectedData(data.data);

      setShowEditModal(true);
    }
    setIsPopoverOpen(true);
  };

  const handleDeleteClick = () => {
    dispatch(deleteCategories({ id: selectedData?.code, params: filteredQuery }));
  };

  const handleFilter = (query) => {
    const queryPayload = {
      ...filteredQuery,
      ...query,
    };

    setFilteredQuery(queryPayload);

    dispatch(fetchCategories(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([]);
  };

  const handleExport = () => {
    dispatch(exportCategories({ ...filteredQuery, search: debouncedValue ?? undefined }));
  };

  const handleExportPlatform = (platform) => {
    dispatch(exportCategories({ ...filteredQuery, search: debouncedValue ?? undefined }));
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    const title = 'Categories Report';
    const headers = [
      exportCategoriesColumns
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportCategoriesData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save('Categories.pdf');
  };
  const csvData = [
    exportCategoriesColumns
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportCategoriesData(exportData),
  ];

  const TableActions = ({ list: selectedData }) => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', selectedData)}
        >
          <EditPencilIcon /> Edit
        </div>
        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'delete', selectedData)}
        >
          <TrashIcon width="16" height="16" className="me-0" /> Delete
        </div>
      </div>
    );
  };

  const handleRowClick = (row) => {
    const { data, createButton } = row;

    if (createButton) {
      history.push({
        pathname: '/compliances/categories/subcategories/create',
        state: { parentCategory: data?.parent },
      });
    } else {
      history.push({
        pathname: `/compliances/categories/${row.code}/details`,
        state: {
          name: row?.name,
          description: row?.description === '-' ? null : row?.description,
          code: row?.code,
          parent: {
            name: data?.parent?.name,
            description:
              data?.parent?.description === '-' ? null : data?.parent?.description,
            code: data?.parent?.code,
          },
        },
      });
    }
  };

  return (
    <div className="budgets-wrapper position-relative container mb-5">
      <CategoryHeader
        handleEdit={() => history.push('/compliances/categories/multiple/edit')}
        setFilteredQuery={setFilteredQuery}
        filteredQuery={filteredQuery}
        // setDe
      >
        <section className="px-0 category-table">
          <Row className="py-4">
            <Col xs={12} className="spaced-table">
              <Table
                columns={columns}
                data={rows}
                pagination
                hasMore={hasMore}
                exportLoading={exportLoading}
                hasCheckBox={false}
                currentPage={page}
                nextPage={() => handleNextPage(nextPage)}
                previousPage={() => handlePreviousPage(page - 1)}
                totalPage={Math.ceil(total / perPage)}
                onRowClick={handleRowClick}
                popoverAction={TableActions}
                popoverState={isPopoverOpen}
                setPopoverState={setIsPopoverOpen}
                // type="budgets"
                modify={true}
                loading={loading}
                hasAvatar={false}
              />
            </Col>
          </Row>
        </section>
      </CategoryHeader>

      {showEditModal && type === 'category' && (
        <EditCategory selectedData={selectedData} setShowEditModal={setShowEditModal} />
      )}
      {showEditModal && type === 'subcategory' && (
        <EditSubcategory
          selectedData={selectedData}
          setShowEditModal={setShowEditModal}
        />
      )}

      <Modal show={showDeleteModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Delete ${type}`}
          subTitle={`Are you sure you want to delete this ${type}? This action cannot be undone.`}
          onConfirm={handleDeleteClick}
          loading={loadingDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
    </div>
  );
};

export default Category;
