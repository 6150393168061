export const GET_REFERRALS_REQUEST = 'GET_REFERRALS_REQUEST';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_ERROR = 'GET_REFERRALS_ERROR';

export const GET_REFERRALS_WITH_CODE_REQUEST = 'GET_REFERRALS_WITH_CODE_REQUEST';
export const GET_REFERRALS_WITH_CODE_SUCCESS = 'GET_REFERRALS_WITH_CODE_SUCCESS';
export const GET_REFERRALS_WITH_CODE_ERROR = 'GET_REFERRALS_WITH_CODE_ERROR';

export const SEND_REFERRAL_EMAIL_REQUEST = 'SEND_REFERRAL_EMAIL_REQUEST';
export const SEND_REFERRAL_EMAIL_SUCCESS = 'SEND_REFERRAL_EMAIL_SUCCESS';
export const SEND_REFERRAL_EMAIL_ERROR = 'SEND_REFERRAL_EMAIL_ERROR';

export const RESET_BLOCK_REFERRAL = 'RESET_BLOCK_REFERRAL';
export const RESET_FLAGS_REFERRAL = 'RESET_FLAGS_REFERRAL';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getReferrals: { ...block },
  getReferralsWithCode: { ...block },
  sendReferralByEmail: { ...block },
};

export const ReferralsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REFERRALS_REQUEST:
      return { ...state, getReferrals: { ...state.getReferrals, loading: true } };
    case GET_REFERRALS_SUCCESS:
      return {
        ...state,
        getReferrals: {
          ...state.getReferrals,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REFERRALS_ERROR:
      return {
        ...state,
        getReferrals: { ...state.getReferrals, loading: false, error: action.error },
      };

    case SEND_REFERRAL_EMAIL_REQUEST:
      return {
        ...state,
        getReferralsWithCode: { ...state.getReferralsWithCode, loading: true },
      };
    case GET_REFERRALS_WITH_CODE_SUCCESS:
      return {
        ...state,
        getReferralsWithCode: {
          ...state.getReferralsWithCode,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SEND_REFERRAL_EMAIL_ERROR:
      return {
        ...state,
        getReferralsWithCode: {
          ...state.getReferralsWithCode,
          loading: false,
          error: action.error,
        },
      };

    case SEND_REFERRAL_EMAIL_REQUEST:
      return {
        ...state,
        sendReferralByEmail: { ...state.sendReferralByEmail, loading: true },
      };
    case SEND_REFERRAL_EMAIL_SUCCESS:
      return {
        ...state,
        sendReferralByEmail: {
          ...state.sendReferralByEmail,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case SEND_REFERRAL_EMAIL_ERROR:
      return {
        ...state,
        sendReferralByEmail: {
          ...state.sendReferralByEmail,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_REFERRAL:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_REFERRAL:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
