import { getCellProperty } from '@silevis/reactgrid';
import { PlusIcon } from 'assets/icons';

export class CreateButtonCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  isFocusable = () => false;

  getStyle(cell, isInEditMode) {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.2;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  render(cell, isInEditMode, onCellChanged) {
    if (!isInEditMode) {
      return (
        <button
          className="border-0 w-100 h-100 text-start"
          style={{ backgroundColor: 'transparent' }}
          onClick={cell.onCreate}
          onPointerDown={(e) => e.stopPropagation()}
        >
          <PlusIcon height="12" width="12" />{' '}
          <span className="ms-1 text-sm" style={{ color: '#D28B28' }}>
            {cell.text}
          </span>
        </button>
      );
    }
  }
}
