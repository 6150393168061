/* eslint-disable no-console */
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from 'redux/actions/CompaniesAction';

import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import Overview from './Overview';
import './styles.scss';

const Dashboard = () => {
  const {
    user,
    userProfile: { loading: loadingUser },
  } = useSelector(({ auth }) => auth);

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const { permissions, onboardingStatus, companyStatus, isAdmin, userStatus } =
    allPermissions();
  const canViewDash = hasPermission({
    permissions,
    scopes: ['dash-*', 'dash-view', 'dash-edit'],
  });
  const [company, setCompany] = useState({});
  const [isOnboarding, setIsOnboarding] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (companyData && companyData.code) setCompany(companyData);
  }, [companyData]);

  useEffect(() => {
    if (isAdmin && ['pending', 'rejected'].includes(onboardingStatus)) {
      setIsOnboarding(
        companyStatus && ['pending', 'rejected'].includes(onboardingStatus),
      );
      localStorage.setItem(
        'overviewInfo',
        JSON.stringify({
          businessDetails: {},
          addBeneficiaries: {},
          fundBalance: {},
          firstBudget: {},
        }),
      );
    } else if (
      !isAdmin ||
      (canViewDash && !['pending', 'rejected'].includes(onboardingStatus))
    ) {
      setIsOnboarding(companyStatus && userStatus !== 'active');
      localStorage.setItem(
        'overviewInfo',
        JSON.stringify({
          kycInfo: {},
          bankInfo: {},
        }),
      );
    }
  }, [company]);

  useEffect(() => {
    const companyCode = user?.data?.user?.company?.code;
    if (user.hasOwnProperty('data')) {
      setCompany(user?.data.user.company || {});
    }

    if (!companyData?.code && companyCode) {
      dispatch(
        getCompany({ code: companyCode, includeDocuments: true, includeSteps: true }),
      );
    }
  }, [user]);

  return (
    <div className="dashboard-wrapper">
      {/*//TODO: NotificationModal testing view*/}
      {/*<NotificationModal />*/}

      <Container>
        {!isOnboarding && canViewDash ? (
          <AllowedTo scopes={['dash-*', 'dash-view', 'dash-edit']}>
            <Overview company={company} />
          </AllowedTo>
        ) : null}
      </Container>
    </div>
  );
};
export default Dashboard;
