import {
  CREATE_BENEFICIARIES_REQUEST,
  BULK_CREATE_BENEFICIARIES_REQUEST,
  BUILD_BULK_BENEFICIARIES_PAYLOAD,
  GET_BENEFICIARIES_REQUEST,
  UPDATE_BENEFICIARIES_REQUEST,
  GET_INVITED_BENEFICIARIES_REQUEST,
  ADD_BENEFICIARIES_BANK_REQUEST,
  GET_BENEFICIARIES_BANK_REQUEST,
  SEARCH_RECIPIENTS_REQUEST,
  DEFAULT_BENEFICIARIES_BANK_REQUEST,
  RESEND_BENEFICIARY_INVITE_REQUEST,
  DELETE_BENEFICIARIES_BANK_REQUEST,
  DISABLED_BENEFICIARIES_REQUEST,
  DELETE_BENEFICIARIES_REQUEST,
  FILTER_BENEFICIARIES,
  SEARCH_RECIPIENT_LIST_REQUEST,
} from '../reducers/BeneficiariesReducer';

export const getBeneficiaries = (payload) => ({
  type: GET_BENEFICIARIES_REQUEST,
  payload,
});
export const createBeneficiaries = (payload) => ({
  type: CREATE_BENEFICIARIES_REQUEST,
  payload,
});
export const bulkCreateBeneficiaries = (payload) => ({
  type: BULK_CREATE_BENEFICIARIES_REQUEST,
  payload,
});
export const buildBulkBeneficiariesPayload = (payload) => ({
  type: BUILD_BULK_BENEFICIARIES_PAYLOAD,
  payload,
});
export const updateBeneficiaries = (payload) => ({
  type: UPDATE_BENEFICIARIES_REQUEST,
  payload,
});

export const getInvitedBeneficiaryDetails = (payload) => ({
  type: GET_INVITED_BENEFICIARIES_REQUEST,
  payload,
});

export const resendBeneficiaryInvite = (payload) => ({
  type: RESEND_BENEFICIARY_INVITE_REQUEST,
  payload,
});

export const addBeneficiaryBank = (payload) => ({
  type: ADD_BENEFICIARIES_BANK_REQUEST,
  payload,
});

export const defaultBeneficiaryBank = (payload) => ({
  type: DEFAULT_BENEFICIARIES_BANK_REQUEST,
  payload,
});

export const deleteBeneficiaryBank = (payload) => ({
  type: DELETE_BENEFICIARIES_BANK_REQUEST,
  payload,
});

export const getBeneficiaryBank = (payload) => ({
  type: GET_BENEFICIARIES_BANK_REQUEST,
  payload,
});

export const searchRecipients = (payload) => ({
  type: SEARCH_RECIPIENTS_REQUEST,
  payload,
});

export const searchRecipientList = (payload) => ({
  type: SEARCH_RECIPIENT_LIST_REQUEST,
  payload,
});

export const disableBeneficiaryActions = (payload) => ({
  type: DISABLED_BENEFICIARIES_REQUEST,
  payload,
});

export const deleteBeneficiaryActions = () => ({
  type: DELETE_BENEFICIARIES_REQUEST,
});

export const deleteBeneficiary = (payload) => ({
  type: DELETE_BENEFICIARIES_REQUEST,
  payload,
});

export const filterBeneficiaries = (payload) => ({
  type: FILTER_BENEFICIARIES,
  payload,
});
