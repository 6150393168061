export const TOGGLE_REQUEST = 'TOGGLE_REQUEST';
export const TOGGLE_SUCCESS = 'TOGGLE_SUCCESS';
export const TOGGLE_NOTIFICATION_REQUEST = 'TOGGLE_NOTIFICATION_REQUEST';
export const TOGGLE_NOTIFICATION_SUCCESS = 'TOGGLE_NOTIFICATION_SUCCESS';
export const TOGGLE_ONBOARDING_VERIFICATION_SUCCESS =
  'TOGGLE_ONBOARDING_VERIFICATION_SUCCESS';

const initialState = {
  toggle: false,
  toggleNotification: false,
  toggleOnboardingNotification: false,
  isSchedule: false,
  payload: null,
};

export const ToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SUCCESS:
      return {
        ...state,
        toggle: !state.toggle,
        isSchedule: action?.payload?.isSchedule,
        payload: action?.payload,
      };
    case TOGGLE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        toggleNotification: !state.toggleNotification,
      };
    case TOGGLE_ONBOARDING_VERIFICATION_SUCCESS:
      return {
        ...state,
        toggleOnboardingNotification: !state.toggleOnboardingNotification,
      };
    default:
      return state;
  }
};
