import EmptyState from 'components/UI/EmptyState';
import TransactionImage from '../../assets/images/emptystate/transaction-image.png';

import CustomButton from 'components/UI/CustomButton';
import AllowedTo, { allPermissions } from 'utils/AllowedTo';

import { useDispatch } from 'react-redux';
import {
  ApprovalRule,
  SchedulePayment,
  SingleTransaction,
} from 'assets/icons/empty-state-icons';

const ApprovalRuleEmptyStateData = ({ toggleHandler }) => {
  const { isAdmin } = allPermissions();

  const handleSupport = () => {
    window.open('https://www.bujeti.com/bank-payments', '_blank');
  };

  const childrenData = [
    {
      title: 'Make Single or Batch Payments',
      body: 'Initiate individual or grouped payments to vendors, suppliers, or colleagues.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SingleTransaction />,
    },

    {
      title: 'Schedule a Payment',
      body: 'Plan and schedule payments in advance to ensure timeliness and avoid likely penalties.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SchedulePayment />,
    },
    {
      title: 'Create Approval Rules',
      body: "Maintain control and compliance with your organization's policies and guidelines.",
      actionTitle: 'View more',
      action: handleSupport,
      icon: <ApprovalRule />,
    },
  ];

  const mainData = {
    title: 'Review and analyse individual financial transactions with ease.',
    body: 'Manage your financial transactions, make single and batch payments, and set approval rules. See how it works here',
    image: TransactionImage,
    action: () =>
      isAdmin && (
        <AllowedTo scopes={['transaction-*', 'transaction-create']}>
          <CustomButton
            className="approval-empty-add-button"
            style={{ width: 250 }}
            onClick={toggleHandler}
          >
            Create approval rule
          </CustomButton>
        </AllowedTo>
      ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default ApprovalRuleEmptyStateData;
