import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import Modal from 'components/UI/Modal';
import MultipleSelect from 'components/UI/MultipleSelect';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { addSubAccountMembers, getSubAccountMembers } from 'redux/actions/BudgetsAction';
import { getInitials } from 'utils/helper';

const AddMembersModal = ({ closeHandler, isModalOpen, subAccountCode }) => {
  if (!isModalOpen) return <div />;

  const dispatch = useDispatch();

  const {
    getBeneficiaries: { data: { beneficiaries = [] } = {}, loading: beneficiaryLoading },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getSubAccountMembers: { data: { members: membersSubAccount = [] } = {} },
    addSubAccountMembers: {
      loading: addingMembersLoading,
      success: addingMembersSuccess,
    },
  } = useSelector(({ budgets }) => budgets);

  const [subAccountMembers, setSubAccountMembers] = useState([]);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const handleSelectChange = (val) => setValue(val);

  const handleSubmit = () => {
    let transformedMangersList =
      subAccountMembers?.managers?.map((item) => {
        return {
          beneficiary: item.value,
          designation: 'Manager',
        };
      }) || [];

    let transformedMembersList =
      subAccountMembers?.beneficiaries?.map((item) => {
        return {
          beneficiary: item.value,
          designation: 'Member',
        };
      }) || [];

    dispatch(
      addSubAccountMembers({
        code: subAccountCode,
        payload: {
          beneficiaries: [...transformedMangersList, ...transformedMembersList],
        },
      }),
    );
  };

  useEffect(() => {
    if (!beneficiaries.length) dispatch(getBeneficiaries());
  }, []);

  useEffect(() => {
    const beneficiaries = beneficiaryList?.find((option) =>
      option?.label?.toLowerCase()?.includes(debouncedValue?.toLowerCase()),
    );

    if (!beneficiaries && debouncedValue) {
      dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (addingMembersSuccess) {
      dispatch(getSubAccountMembers({ code: subAccountCode }));
      closeHandler();
    }
  }, [addingMembersSuccess]);

  const handleBeneficiarySelect = (value, name) => {
    setSubAccountMembers({
      ...subAccountMembers,
      [name]: value,
    });
  };

  const onMenuClose = () => {
    if (debouncedValue) dispatch(getBeneficiaries());
  };

  const { managers, beneficiaries: members } = subAccountMembers;

  const seletedMangers = managers ? managers?.map((item) => item.value) : [];
  const seletedMembers = members ? members?.map((item) => item.value) : [];

  const subAccountMembersList = membersSubAccount?.map(({ user: { code } }) => code);

  const beneficiaryList = beneficiaries
    .filter(
      ({ code, user: { code: userCode } }) =>
        !seletedMangers?.includes(code) &&
        !seletedMembers?.includes(code) &&
        !subAccountMembersList.includes(userCode),
    )
    .map(({ user, code }) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: code,
    }));

  const formatSelectedOptionLabel = (selectedOption) => {
    if (!selectedOption) {
      return null;
    }
    return (
      <div className="d-flex align-items-center gap-2">
        <div className="avatar__card-initials">
          <h5>{getInitials(null, null, selectedOption.label)}</h5>
        </div>
        <div>{selectedOption.label}</div>
      </div>
    );
  };

  const handleSelectAllBeneficiary = (list, name) => {
    const newList = !beneficiaryList?.length ? [] : list;
    const formerList =
      !beneficiaryList?.length && subAccountMembers?.[name]
        ? []
        : subAccountMembers?.[name] ?? [];
    setSubAccountMembers({
      ...subAccountMembers,
      [name]: [...newList, ...formerList],
    });
  };
  const [isLengthEqualManagers, setIsLengthEqualManagers] = useState(false);

  useEffect(() => {
    setIsLengthEqualManagers(
      beneficiaryList?.length === 0 && !!subAccountMembers?.managers?.length,
    );
  }, [subAccountMembers?.managers?.length, subAccountMembers?.beneficiaries?.length]);

  return (
    <Modal show={isModalOpen} onClose={closeHandler}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={closeHandler}>
            <>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="beneficiaries">
            <h2 className="card-title w-100">Add members</h2>
            <p style={{ color: '#79716B' }}>
              You can add members with different role on this subaccount.
            </p>

            <Row className="mt-3">
              <MultipleSelect
                label="Account managers"
                placeholderText="Select managers"
                name="managers"
                options={beneficiaryList}
                onChange={(value) => handleBeneficiarySelect(value, 'managers')}
                value={subAccountMembers?.managers}
                onInputChange={handleSelectChange}
                extendComponent={
                  <span className="d-flex align-items-center gap-2">
                    {beneficiaryList?.length === 0 ? <MinusOutlined /> : <PlusOutlined />}
                    {isLengthEqualManagers
                      ? 'Unselect all managers'
                      : 'Select all managers'}
                  </span>
                }
                selectAllBudgets={() =>
                  handleSelectAllBeneficiary(beneficiaryList, 'managers')
                }
                isLoading={beneficiaryLoading}
                formatOptionLabel={formatSelectedOptionLabel}
                onMenuClose={onMenuClose}
              />
            </Row>

            {/* <Row className="mt-3">
              <MultipleSelect
                label="Members"
                placeholderText="Select members"
                name="beneficiaries"
                options={beneficiaryList}
                onChange={(value) => handleBeneficiarySelect(value, 'beneficiaries')}
                value={subAccountMembers?.beneficiaries}
                onInputChange={handleSelectChange}
                extendComponent={
                  <span className="d-flex align-items-center gap-2">
                    {isLengthEqualBeneficiaries ? <MinusOutlined /> : <PlusOutlined />}
                    {isLengthEqualBeneficiaries
                      ? 'Unselect all members'
                      : 'Select all members'}
                  </span>
                }
                selectAllBudgets={() =>
                  handleSelectAllBeneficiary(beneficiaryList, 'beneficiaries')
                }
                isLoading={beneficiaryLoading}
                formatOptionLabel={formatSelectedOptionLabel}
              />
            </Row> */}

            <div className="modal-footer border-top">
              <CustomButton
                onClick={closeHandler}
                fullWidth={true}
                className="custom-button ghost-button"
                disabled={addingMembersLoading}
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={handleSubmit}
                fullWidth={true}
                className="custom-button primary-button"
                loading={addingMembersLoading}
                disabled={
                  addingMembersLoading ||
                  (!subAccountMembers?.managers && !subAccountMembers?.beneficiaries)
                }
              >
                Confirm
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AddMembersModal;
