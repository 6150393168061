import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import { encryptAndSaveToLocalStorage } from 'utils/utility';
import {
  CREATE_BULK_VENDORS_ERROR,
  CREATE_BULK_VENDORS_REQUEST,
  CREATE_BULK_VENDORS_SUCCESS,
  CREATE_VENDOR_ERROR,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  DELETE_VENDOR_ERROR,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
  EXPORT_VENDORS_ERROR,
  EXPORT_VENDORS_REQUEST,
  EXPORT_VENDORS_SUCCESS,
  GET_SINGLE_VENDOR_ERROR,
  GET_SINGLE_VENDOR_REQUEST,
  GET_SINGLE_VENDOR_SUCCESS,
  GET_VENDOR_ERROR,
  GET_VENDOR_REQUEST,
  GET_VENDOR_SUCCESS,
  INVITE_VENDOR_ERROR,
  INVITE_VENDOR_REQUEST,
  INVITE_VENDOR_SUCCESS,
  MASS_UPDATE_VENDOR_ERROR,
  MASS_UPDATE_VENDOR_REQUEST,
  MASS_UPDATE_VENDOR_SUCCESS,
  REACTIVATE_VENDOR_ERROR,
  REACTIVATE_VENDOR_REQUEST,
  REACTIVATE_VENDOR_SUCCESS,
  RESEND_VENDOR_INVITE_ERROR,
  RESEND_VENDOR_INVITE_REQUEST,
  RESEND_VENDOR_INVITE_SUCCESS,
  RESET_BLOCK_VENDOR,
  UPDATE_EXTERNAL_VENDOR_ERROR,
  UPDATE_EXTERNAL_VENDOR_REQUEST,
  UPDATE_EXTERNAL_VENDOR_SUCCESS,
  UPDATE_VENDOR_ERROR,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  VENDOR_INFO_ERROR,
  VENDOR_INFO_REQUEST,
  VENDOR_INFO_SUCCESS,
  VERIFY_VENDOR_INVITE_ERROR,
  VERIFY_VENDOR_INVITE_REQUEST,
  VERIFY_VENDOR_INVITE_SUCCESS,
  VERIFY_VENDOR_OTP_ERROR,
  VERIFY_VENDOR_OTP_REQUEST,
  VERIFY_VENDOR_OTP_SUCCESS,
} from '../reducers/VendorsReducer';

async function getVendor(params) {
  // params = {}
  // return await Axios.get('/vendors/', { params });
  return await Axios.get('/vendors', { params });
}

function* handleGetVendor({ payload }) {
  try {
    const response = yield call(getVendor, payload);
    if (response) {
      yield put({
        type: GET_VENDOR_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createVendor(payload) {
  return await Axios.post('/vendors', payload);
}
function* handleCreateVendor({ payload }) {
  try {
    const response = yield call(createVendor, payload);
    if (response) {
      yield put({
        type: CREATE_VENDOR_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteVendor(code) {
  return await Axios.delete(`/vendors/${code}`);
}
function* handleDeleteVendor({ payload }) {
  try {
    const response = yield call(deleteVendor, payload);
    if (response) {
      yield put({
        type: DELETE_VENDOR_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'deleteVendor',
      });

      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateVendor({ payload, code }) {
  return await Axios.put(`/vendors/${code}`, payload);
}
function* handleUpdateVendor({ payload }) {
  try {
    const { code, ...data } = payload;
    Object.keys(payload).map((key) => {
      if (!payload[key]) delete data[key];
    });

    const response = yield call(updateVendor, { payload: data, code });
    if (response) {
      yield put({
        type: UPDATE_VENDOR_SUCCESS,
        data: payload,
      });
      toastSuccess(response.message);

      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleVendor({ id }) {
  return await Axios.get(`/vendors/${id}`);
}
function* handleSingleVendor({ payload }) {
  try {
    const response = yield call(singleVendor, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_VENDOR_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBulkVendors(payload) {
  return await Axios.post('/vendors/bulk', payload);
}
function* handleCreateBulkVendors({ payload }) {
  try {
    const response = yield call(createBulkVendors, payload);
    if (response) {
      yield put({
        type: CREATE_BULK_VENDORS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(200);

      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'createBulkVendors',
      });
      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BULK_VENDORS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function exportVendors(params) {
  return await Axios.get('/vendors/export', { params });
}
function* handleExportVendors({ payload }) {
  try {
    const response = yield call(exportVendors, payload);
    if (response) {
      yield put({
        type: EXPORT_VENDORS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EXPORT_VENDORS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function verifyVendorInvite(params) {
  return await Axios.get('/vendors/invite/verify', { params });
}
function* handleVerifyVendorInvite({ payload }) {
  try {
    const response = yield call(verifyVendorInvite, payload);
    if (response) {
      yield put({
        type: VERIFY_VENDOR_INVITE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_VENDOR_INVITE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function verifyVendorOtp(payload) {
  return await Axios.post('/vendors/verify-otp', payload);
}
function* handleVerifyVendorOtp({ payload }) {
  try {
    const response = yield call(verifyVendorOtp, payload);
    if (response) {
      localStorage.removeItem('app-session');

      yield put({
        type: VERIFY_VENDOR_OTP_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      encryptAndSaveToLocalStorage(
        'vendor-app-session',
        response.data.authentication.token,
      );
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_VENDOR_OTP_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function inviteVendor(payload) {
  const { code, request, ...data } = payload;
  const route = request ? `/vendors/${code}/invite` : '/vendors/invite';
  const payloads = request ? data : payload;

  return await Axios.post(route, payloads);
}
function* handleInviteVendor({ payload }) {
  try {
    const response = yield call(inviteVendor, payload);
    if (response) {
      yield put({
        type: INVITE_VENDOR_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(200);

      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'inviteVendor',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: INVITE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resendVendorInvite(payload) {
  return await Axios.post('/vendors/invite/resend', payload);
}
function* handleResendVendorInvite({ payload }) {
  try {
    const response = yield call(resendVendorInvite, payload);
    if (response) {
      yield put({
        type: RESEND_VENDOR_INVITE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(200);

      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'resendVendorInvite',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RESEND_VENDOR_INVITE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function vendorInfo(payload) {
  const { code, ...field } = payload;
  return await Axios.post(`/vendors/${code}/requests`, field);
}
function* handleVendorInfo({ payload }) {
  try {
    const response = yield call(vendorInfo, payload);
    if (response) {
      yield put({
        type: VENDOR_INFO_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VENDOR_INFO_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateExternalVendor({ payload, code }) {
  return await Axios.put(`/vendors/${code}`, payload);
}
function* handleUpdateExternalVendor({ payload }) {
  try {
    const { code, ...data } = payload;
    Object.keys(payload).map((key) => {
      if (!payload[key]) delete data[key];
    });

    const response = yield call(updateExternalVendor, { payload: data, code });
    if (response) {
      yield put({
        type: UPDATE_EXTERNAL_VENDOR_SUCCESS,
        data: payload,
      });
      toastSuccess(response.message);

      yield delay(200);

      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'updateExternalVendor',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_EXTERNAL_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function massUpdateVendor(payload) {
  return await Axios.put(`/vendors/mass-update`, payload);
}
function* handleMassUpdateVendor({ payload }) {
  try {
    const response = yield call(massUpdateVendor, payload);
    if (response) {
      yield put({
        type: MASS_UPDATE_VENDOR_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'massUpdateVendor',
      });

      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: MASS_UPDATE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reactivateVendor(code) {
  return await Axios.patch(`/vendors/${code}/activate`);
}
function* handleReactivateVendor({ payload }) {
  try {
    const response = yield call(reactivateVendor, payload);
    if (response) {
      yield put({
        type: REACTIVATE_VENDOR_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_VENDOR,
        blockType: 'reactivateVendor',
      });

      yield put({
        type: GET_VENDOR_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REACTIVATE_VENDOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_VENDOR_REQUEST, handleGetVendor),
  takeLatest(DELETE_VENDOR_REQUEST, handleDeleteVendor),
  takeLatest(CREATE_VENDOR_REQUEST, handleCreateVendor),
  takeLatest(UPDATE_VENDOR_REQUEST, handleUpdateVendor),
  takeLatest(UPDATE_EXTERNAL_VENDOR_REQUEST, handleUpdateExternalVendor),
  takeLatest(GET_SINGLE_VENDOR_REQUEST, handleSingleVendor),
  takeLatest(CREATE_BULK_VENDORS_REQUEST, handleCreateBulkVendors),
  takeLatest(EXPORT_VENDORS_REQUEST, handleExportVendors),
  takeLatest(VERIFY_VENDOR_INVITE_REQUEST, handleVerifyVendorInvite),
  takeLatest(VERIFY_VENDOR_OTP_REQUEST, handleVerifyVendorOtp),
  takeLatest(INVITE_VENDOR_REQUEST, handleInviteVendor),
  takeLatest(RESEND_VENDOR_INVITE_REQUEST, handleResendVendorInvite),
  takeLatest(VENDOR_INFO_REQUEST, handleVendorInfo),
  takeLatest(MASS_UPDATE_VENDOR_REQUEST, handleMassUpdateVendor),
  takeLatest(REACTIVATE_VENDOR_REQUEST, handleReactivateVendor),
]);
