import { Axios } from 'api/axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import { toastError, toastSuccess } from 'components/UI/toast';
import {
  DOWNLOAD_ACCOUNT_STATEMENT_ERROR,
  DOWNLOAD_ACCOUNT_STATEMENT_REQUEST,
  DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS,
  GENERATE_ACCOUNT_STATEMENT_ERROR,
  GENERATE_ACCOUNT_STATEMENT_REQUEST,
  GENERATE_ACCOUNT_STATEMENT_SUCCESS,
} from 'redux/reducers/AccountStatementReducer';

async function generatingAccountStatement(params = {}) {
  return await Axios.get(`companies/transactions/statement`, { params });
}

function* handleAccountStatement({ payload }) {
  try {
    const response = yield call(generatingAccountStatement, payload);
    if (response) {
      yield put({ type: GENERATE_ACCOUNT_STATEMENT_SUCCESS, data: response.data });

      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GENERATE_ACCOUNT_STATEMENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function downlaodAccountStatement(params = {}) {
  return await Axios.get(`companies/transactions/export-statement`, { params });
}

function* handleDownloadAccount({ payload }) {
  try {
    const response = yield call(downlaodAccountStatement, payload);
    if (response) {
      yield put({
        type: DOWNLOAD_ACCOUNT_STATEMENT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DOWNLOAD_ACCOUNT_STATEMENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GENERATE_ACCOUNT_STATEMENT_REQUEST, handleAccountStatement),
  takeLatest(DOWNLOAD_ACCOUNT_STATEMENT_REQUEST, handleDownloadAccount),
]);
