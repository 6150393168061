import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import CustomPopover from 'components/UI/Popover';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSingleCategory } from 'redux/actions/CategoryAction';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';
import CategoryApprovalRules from './CategoryApprovalRules';
import CategoryOverview from './CategoryOverview';
import CategoryPolicies from './CategoryPolicies';
import CategoryTransactionTable from './CategoryTransctionTable';
import EditCategory from './EditCategory';
import EditSubcategory from './EditSubcategory';
import CategoryCategorizationRules from './CategoryCategorizationRules';
import SubcategoryTable from './SubcategoryTable';
import { RESET_BLOCK_CATEGORY } from 'redux/reducers/CategoryReducer';

const ViewCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categoryCode } = useParams();

  const location = useLocation();
  const locationArray = location.pathname.split('/');

  const {
    fetchSingleCategory: { data: { categories } = {}, loading },
    updateCategories: { success: updateSuccess },
  } = useSelector(({ categories }) => categories);

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [key, setKey] = useState(
    locationArray[locationArray.length - 1] === 'details'
      ? 'overview'
      : locationArray[locationArray.length - 1],
  );

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  useEffect(() => {
    if (categories?.code !== categoryCode) {
      dispatch({ type: RESET_BLOCK_CATEGORY, blockType: 'fetchSingleCategory' });
      dispatch(fetchSingleCategory(categoryCode));
    }
  }, [categoryCode]);

  useEffect(() => {
    if (updateSuccess) dispatch(fetchSingleCategory(categoryCode));
  }, [updateSuccess]);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => history.push('/compliances/categories/create')}
        >
          Create a category
        </div>
        <div
          onClick={() => history.push('/compliances/categories/multiple/create')}
          className="actionLink"
        >
          Create multiple
        </div>
        <div
          onClick={() => history.push('/compliances/categories/multiple/upload')}
          className="actionLink"
        >
          Upload CSV
        </div>
      </div>
    );
  };

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    history.push({
      pathname: `/compliances/categories/${categoryCode}/${tabKey}`,
      state: {
        categoryCode,
      },
    });
  };

  const RightBar = (
    <>
      <div className="d-flex align-items-center">
        {!categories?.isSubCategory && (
          <div className="ps-3 cursor">
            <div
              className="add-button add-action w-100"
              onClick={() => {
                history.push({
                  pathname: '/compliances/categories/subcategories/create',
                  state: { parentCategory: categories || location?.state },
                });
              }}
            >
              Create a subcategory
            </div>
          </div>
        )}

        <div className="ps-3 cursor onDesktop">
          <CustomPopover
            zIndex="1"
            showPopover={isButtonToggle}
            clickOutside={handleButtonToggle}
            content={<Actions />}
          >
            <CustomButton className="add-button" onClick={handleButtonToggle}>
              Create a category
              <DownArrow />
            </CustomButton>
          </CustomPopover>
        </div>
      </div>
    </>
  );

  // if (loading) return <Loading isPage color="#D28B28" />;

  return (
    <div className="budgets-wrapper position-relative contsiner mb-5">
      <TopBar
        headerText={
          <span className="mt-3 d-block">
            {location?.state?.name || categories?.name}
          </span>
        }
        subText={
          <div
            onClick={
              !(location?.state?.description || categories?.description)
                ? () => setShowEditModal(true)
                : undefined
            }
            className={`${
              !(location?.state?.description || categories?.description) && 'cursor'
            }`}
            style={{
              fontStyle:
                !(location?.state?.description || categories?.description) && 'italic',
              color:
                !(location?.state?.description || categories?.description) && '#79716B',
            }}
          >
            {location?.state?.description ||
              categories?.description ||
              'Add a description to your expense category'}
          </div>
        }
        addButton
        breadcrumbs={[
          { title: 'Categories', action: () => history.push('/compliances/categories') },
          {
            title: location?.state?.parent?.name || categories?.parent?.name,
            action: () =>
              history.push({
                pathname: `/compliances/categories/${
                  location?.state?.parent?.code || categories?.parent?.code
                }/details`,
                state: {
                  name: location?.state?.parent?.name || categories?.parent?.name,
                  description:
                    location?.state?.parent?.description ||
                    categories?.parent?.description,
                  code: location?.state?.parent?.code || categories?.parent?.code,
                },
              }),
          },
          {
            title: location?.state?.name || categories?.name,
            action: () =>
              history.push(
                `/compliances/categories/${
                  location?.state?.code || categories?.code
                }/details`,
              ),
          },
        ].filter((item) => item.title)}
        isRightBar={RightBar}
      />

      <Container className="view-category-table">
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              <Tab
                eventKey="overview"
                title={<div className="d-flex">Overview</div>}
                tabClassName="new-tab"
              >
                <CategoryOverview
                  tabKey={key}
                  categoryData={categories}
                  setShowEditModal={setShowEditModal}
                  loading={loading}
                />
              </Tab>

              <Tab
                eventKey="transactions"
                title={<div className="d-flex">Transactions</div>}
                tabClassName="new-tab"
              >
                <CategoryTransactionTable
                  tabKey={key}
                  queryParams={{
                    category: categoryCode,
                  }}
                  category={categories}
                />
              </Tab>
              {!location?.state?.parent?.name && !categories?.isSubCategory && (
                <Tab
                  eventKey="subcategories"
                  title={
                    <div className="d-flex">
                      Sub categories {location?.state?.parent?.name}
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <SubcategoryTable
                    tabKey={key}
                    queryParams={{
                      parent: categoryCode,
                    }}
                    categoryCode={categoryCode}
                    categoryData={categories}
                  />
                </Tab>
              )}
              <Tab
                eventKey="approval-rules"
                title={<div className="d-flex">Approval rules</div>}
                tabClassName="new-tab"
              >
                <CategoryApprovalRules
                  tabKey={key}
                  categoryCode={categoryCode}
                  categoryData={categories}
                />
              </Tab>
              <Tab
                eventKey="policies"
                title={<div className="d-flex">Policies</div>}
                tabClassName="new-tab"
              >
                <CategoryPolicies
                  tabKey={key}
                  queryParams={{
                    category: categoryCode,
                  }}
                  categoryData={categories}
                />
              </Tab>
              <Tab
                eventKey="categorization-rules"
                title={<div className="d-flex">Categorization rules</div>}
                tabClassName="new-tab"
              >
                <CategoryCategorizationRules
                  tabKey={key}
                  queryParams={{
                    category: categoryCode,
                  }}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>

      {showEditModal && categories?.isSubCategory === false && (
        <EditCategory selectedData={categories} setShowEditModal={setShowEditModal} />
      )}
      {showEditModal && categories?.isSubCategory === true && (
        <EditSubcategory selectedData={categories} setShowEditModal={setShowEditModal} />
      )}
    </div>
  );
};

export default ViewCategory;
