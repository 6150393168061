import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { getBudgets } from 'redux/actions/BudgetsAction';
import { allPermissions } from 'utils/AllowedTo';
import BudgetsCard from '../../Budgets/BudgetsCard';
import NoData from './NoData';

const BudgetsTab = ({ id, type, userName }) => {
  const dispatch = useDispatch();
  const { permissions } = allPermissions();

  const {
    getBudget: { data: { budgets: budgetsCard = [], meta = {} } = {}, loading, success },
  } = useSelector(({ budgets }) => budgets);

  const [budgetStatus, setBudgetStatus] = useState('active');

  const { hasMore, perPage } = meta;

  useEffect(() => {
    dispatch(getBudgets({ status: budgetStatus, [type]: id }));
  }, []);

  // Handle search change after debounce

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(getBudgets({ perPage, status: budgetStatus, [type]: id }));
      }
    }, 1500);
  };

  const budgetList = () => {
    return (
      <>
        {!loading && !budgetsCard.length ? (
          <div className="tabinnerWrapper">
            <NoData
              headerText="No budgets"
              bodyText={`${userName} does not have any budgets yet`}
              withButton={true}
              buttonLabel={
                <span className="d-flex justify-content-center">Show all Invoices</span>
              }
              // onClickHandler={clearLocationState}
            />
          </div>
        ) : (
          <div style={{ maxHeight: '760px' }} className="pt-3 w-100 overflow-y-auto">
            <Container className="px-0 w-100">
              <InfiniteScroll
                pageStart={0}
                loadMore={() => handleMore(perPage + 50)}
                hasMore={hasMore}
                className="w-100"
                // loader={<h4></h4>}
              >
                <Row className="budgets-card-wrapper w-100 ">
                  {budgetsCard?.map((budget, index) => (
                    <BudgetsCard
                      customCardStyles={{
                        title: 'budget-card-title',
                        currency: 'budget-card-currency',
                      }}
                      budget={budget}
                      key={`budgets-${index}`}
                    />
                  ))}
                </Row>
              </InfiniteScroll>
            </Container>
          </div>
        )}
      </>
    );
  };

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  const Loader = () => {
    return (
      <div
        style={{ marginTop: '30px' }}
        className="d-flex justify-content-between flex-wrap"
      >
        {Array.from({ length: 4 }).map((_, index) => {
          return (
            <div
              key={index}
              style={{
                flexBasis: '47%',
                marginBottom: '15px',
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Skeleton.Input active block={true} size={180} style={{ width: '100%' }} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="budgets-wrapper w-100 mt-3">
      {loading ? <Loader /> : budgetList()}
    </div>
  );
};

export default BudgetsTab;
