import { MinusIcon } from 'assets/icons';
import classNames from 'classnames';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCategories } from 'redux/actions/CategoryAction';
import { capitalizeFirstLetter, flattenCategories } from 'utils/helper';

const createOption = (label) => ({
  label,
  value: label,
});

const ConditionRowComponent = ({
  rules,
  removeCondition,
  handleConditionChange,
  disabled = false,
  operator,
}) => {
  return (
    <>
      {rules?.map((rule, index) => {
        const isMultiple =
          rule?.['condition-two']?.selectedValue?.value?.indexOf('any') >= 0;
        return (
          <div className={`${index !== 0 ? 'mt-3' : ''}`} key={index}>
            {index !== 0 && (
              <span className="conditions-label text-uppercase">{operator}</span>
            )}
            <div className={`condition-information-wrapper`}>
              <div
                className={classNames('d-flex flex-grow-1 gap-2', {
                  ['flex-wrap']: isMultiple,
                })}
              >
                <div className={classNames('flex-1', { ['w-50']: isMultiple })}>
                  <CustomSelect
                    name={rule.name}
                    placeholder={'Select'}
                    options={rule?.['condition-one'].options}
                    value={rule?.['condition-one'].selectedValue}
                    defaultValue={rule?.['condition-one'].selectedValue}
                    onChange={(val) => handleConditionChange(val, index, 'one')}
                    isDisabled={disabled}
                  />
                </div>
                <div className={classNames('flex-1', { ['w-50']: isMultiple })}>
                  <CustomInput
                    placeholder="Enter description"
                    text="text"
                    nname={rule.name}
                    onChange={(event) =>
                      handleConditionChange(event?.target?.value, index, 'two')
                    }
                    value={rule?.['condition-two'].selectedValue}
                    isDisabled={disabled}
                  />
                </div>
              </div>
              {!disabled && (
                <div
                  className={`information-icon ms-3 ${
                    rules.length === 1 ? 'invisible' : ''
                  }`}
                >
                  <MinusIcon onClick={() => removeCondition(index, 'rules')} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ConditionRowComponent;
