import { getCellProperty } from '@silevis/reactgrid';
import { KebabIcon } from 'assets/icons';
import CustomPopover from '../Popover';

export class ActionCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  isFocusable = () => false;

  render(cell, isInEditMode, onCellChanged) {
    const tableActions = () => {
      return (
        <div className="actions-dialog">
          {cell.actionList.map((item, idx) => {
            const IconComponent = item.icon;
            return (
              <div
                key={idx}
                className={`actionLink ${item.className}`}
                onClick={item.onClick}
              >
                <IconComponent
                  height={item.height}
                  width={item.width}
                  className={item.iconClassName}
                />{' '}
                {item.text}
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <CustomPopover
        content={tableActions()}
        showPopover={cell.rowId === cell.kebabId}
        clickOutside={(event) => cell.onClick(event, cell.rowId)}
      >
        <div
          onClick={(event) => cell.onClick(event, cell.rowId)}
          className="more-outline cursor"
        >
          <KebabIcon width="16" height="16" />
        </div>
      </CustomPopover>
    );
  }
}
