import { Skeleton } from 'antd';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { update_user_profile } from 'redux/actions/AuthAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { validateNigeriaNumber } from 'utils/helper';

const CompleteProfile = () => {
  const [stateIsSet, setStateIsSet] = useState(false);

  const dispatch = useDispatch();

  const {
    getCountry: {
      data: countryData = {},
      loading: loadingCountry,
      success: countrySuccess,
    },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const {
    updatedUser: { loading: updateLoading },
    user: { data = {} },
    userProfile: { loading },
  } = useSelector(({ auth }) => auth);

  const [kycInfo, setKycInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    country: '',
    address: '',
    state: '',
    city: '',
    localFormat: '',
    internationalFormat: '',
    countryCode: '',
  });

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setKycInfo({
      ...kycInfo,
      internationalFormat,
      localFormat,
      countryCode,
    });
  };

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      if (countrySuccess && !data?.user?.address) setStateIsSet(true);
      return countryData?.map(({ code: value, name: label, alpha: iso }) => ({
        value,
        label,
        iso,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData, data?.user?.address]);

  const generateState = useMemo(() => {
    if (successState) setStateIsSet(true);
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  useEffect(() => {
    if (data?.user && generateCountry?.length) {
      if (data?.user?.address?.country) {
        const country = generateCountry?.find((item) =>
          [item?.label, item.value].includes(data?.user?.address?.country),
        );
        dispatch(getState(country?.value));
      }
    }
  }, [generateCountry?.length, data?.user?.code]);

  useEffect(() => {
    if (
      data?.user?.firstName &&
      (stateIsSet || !data?.user?.address) &&
      !kycInfo?.firstName &&
      !loading
    ) {
      setKycInfo({
        ...kycInfo,
        firstName: data?.user?.firstName || '',
        lastName: data?.user?.lastName || '',
        dob: data?.user?.dob || '',
        localFormat: data?.user?.phoneNumber?.localFormat || '',
        countryCode: data?.user?.phoneNumber?.countryCode || '',

        internationalFormat: data?.user?.phoneNumber?.internationalFormat
          ? `+${data?.user?.phoneNumber?.internationalFormat}`
          : '',
        address: data?.user.address?.street || '',

        country:
          generateCountry?.find((item) =>
            [item?.label, item.value].includes(data?.user?.address?.country),
          ) || '',
        state:
          generateState?.find((item) =>
            [item?.label, item.value].includes(data?.user?.address?.state),
          ) || '',
        city: data?.user?.address?.city || '',
      });
    }
  }, [data?.user?.firstName, stateIsSet, loading]);

  const handleInputChange = ({ target: { name, value } }) => {
    setKycInfo({ ...kycInfo, [name]: value });
  };

  const handleChangeDate = (value) => {
    setKycInfo({
      ...kycInfo,
      dob: dayjs(value).format('YYYY-MM-DD'),
    });
  };

  const handleSubmit = () => {
    if (!kycInfo.firstName) return toastError('Please enter first name');
    if (!kycInfo.lastName) return toastError('Please enter last name');
    if (!kycInfo.dob) return toastError('Please enter date of birth');

    if (
      !isValidPhoneNumber(kycInfo.internationalFormat ?? '') ||
      !validateNigeriaNumber(kycInfo.internationalFormat)
    )
      return toastError('Please enter a valid phone number');
    if (!kycInfo.address) return toastError('Please enter an address');
    if (!kycInfo.city) return toastError('Please enter a city');
    if (!kycInfo.state) return toastError('Please select a state');
    if (!kycInfo.country) return toastError('Please select a country');

    const payload = {
      toastMessage: 'Profile updated successfully',
      firstName: kycInfo.firstName,
      lastName: kycInfo.lastName,
      dob: kycInfo.dob,
      address: {
        country: kycInfo.country.value,
        countryIso: kycInfo.country?.iso,
        street: kycInfo.address,
        state: kycInfo.state.label,
        city: kycInfo.city,
      },
      phoneNumber: {
        countryCode: String(kycInfo.countryCode),
        localFormat: kycInfo.localFormat,
      },
    };

    dispatch(update_user_profile(payload));
  };

  return (
    <Row className="w-100 p-0 m-0">
      <Col className="border py-2 rounded-3 mt-3">
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <div className="px-3 mt-3">
              <Row className="align-items-center gap-md-0 gap-2 mb-2 ">
                <CustomInput
                  label="First Name *"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  onChange={handleInputChange}
                  value={kycInfo.firstName}
                  maxLength="20"
                  md={6}
                />
                <CustomInput
                  label="Last Name *"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  value={kycInfo.lastName}
                  maxLength="20"
                  md={6}
                />
              </Row>

              <Row className="mb-2">
                <CustomDatePicker
                  label="Date of Birth *"
                  onChange={handleChangeDate}
                  peekNextMonth
                  maxDate={addYears(new Date(), -18)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Date of Birth"
                  selected={kycInfo.dob && dayjs(kycInfo.dob).toDate()}
                />
              </Row>

              <Row className="mb-2 align-items-center">
                <CustomPhoneNumberInput
                  label="Phone number"
                  placeholder="Enter your mobile number"
                  onChange={(localFormat, international, countryCode) =>
                    handlePhoneNumberChange(localFormat, international, countryCode)
                  }
                  value={kycInfo.internationalFormat}
                />
              </Row>
              <Row className="mb-2">
                <CustomInput
                  type="text"
                  label="Address *"
                  placeholder="Enter Address"
                  name="address"
                  onChange={handleInputChange}
                  value={kycInfo.address}
                />
              </Row>
              <Row className="mb-2">
                <CustomInput
                  type="text"
                  label="City"
                  placeholder="Enter city"
                  name="city"
                  onChange={handleInputChange}
                  value={kycInfo.city}
                />
              </Row>
              <Row className="mb-2 align-items-center">
                <CustomSelect
                  md={6}
                  label="State / Province"
                  placeholder="Select state"
                  name="state"
                  isLoading={loadingState}
                  onChange={(val) =>
                    setKycInfo({
                      ...kycInfo,
                      state: val,
                    })
                  }
                  value={kycInfo.state}
                  options={generateState}
                />

                <CustomInput
                  label="Postcode"
                  placeholder="Enter your post code"
                  name="postcode"
                  onChange={handleInputChange}
                  value={kycInfo.postcode}
                  md={6}
                />
              </Row>
              <Row className="mb-3">
                <CustomSelect
                  label="Country"
                  name="country"
                  placeholder="Select country"
                  isLoading={loadingCountry}
                  onChange={(val) => {
                    setKycInfo({
                      ...kycInfo,
                      country: val,
                    });
                    getCountryStates(val);
                  }}
                  value={kycInfo.country}
                  options={generateCountry}
                />
              </Row>
            </div>
            <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
              <button type="button" className="btn border sm ms-md-auto mb-md-0 mb-2">
                Cancel
              </button>
              <button
                type="button"
                disabled={updateLoading}
                onClick={handleSubmit}
                className="btn black sm"
              >
                {updateLoading ? (
                  <Loading color="#D28B28" size={18} />
                ) : (
                  'Save and submit'
                )}
              </button>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CompleteProfile;

const LoadingSkeleton = () => {
  return (
    <div className="px-3">
      <div className="pt-2 bg-transparent pb-3">
        {Array(8)
          .fill(1)
          .map((_, index) => (
            <Row className="mb-3 mt-3" key={index}>
              <Skeleton.Input active className="rounded-2 w-100" size={42} />
            </Row>
          ))}
      </div>

      <div className="border-top bg-transparent py-3">
        <Row className="mb-2">
          <Skeleton.Input active className="rounded-2 w-100" size={52} />
        </Row>
      </div>
    </div>
  );
};
