import { ReactComponent as ApprovedTransaction } from 'assets/icons/budget-analytics/approved-transactions.svg';
import { ReactComponent as Invoices } from 'assets/icons/budget-analytics/invoices.svg';
import { ReactComponent as PendingCards } from 'assets/icons/budget-analytics/pending-cards.svg';
import { ReactComponent as PendingRequest } from 'assets/icons/budget-analytics/pending-requests.svg';
import classNames from 'classnames';
import DateRangeFilter from 'components/DateRangeFilter';
import OverlayingIcons from 'components/OverlayingIcons';
import OverviewCard from 'components/OverviewCards';
import TaskAction from 'components/UI/TaskAction';
import AreaChartView from 'components/charts/AreaChart';
import PieChartView from 'components/charts/PieChart';
import { useHistory, useParams } from 'react-router-dom';
import {
  buildChartDataForExpenseHead,
  buildChartDataFromTransaction,
  getFormattedDate,
} from 'utils/helper';
import BarChartView from 'components/charts/BarChart';

const BudgetOverviewAnalytics = ({
  chartData,
  totalSpent,
  currency,
  budgetCode,
  beneficiaries = [],
  budgetOwners = [],
  budget = {},
  handleSelectRange,
  selectedRange,
  isLoading,
  isSuccess,
  stats = {},
}) => {
  const {
    summary = {},
    topSpenders = [],
    vendors: chartVendors = [],
    categories: chartCategories = [],
  } = stats;

  const taskss = [
    summary?.transactions && {
      icon: ApprovedTransaction,
      title: summary?.transactions + ' Transactions to review',
      link: '/transactions/requests?status=pending&budget=' + budgetCode,
    },
    summary?.uncategorizedTransactions && {
      icon: ApprovedTransaction,
      title: summary?.uncategorizedTransactions + ' Uncategorized transactions to review',
      link: '/transactions/requests?status=pending&budget=' + budgetCode,
    },
    summary?.cards && {
      icon: PendingCards,
      title: summary?.cards + ' Card requests pending',
      link: '/cards/requests?status=pending&budget=' + budgetCode,
    },
    summary?.reimbursements && {
      icon: PendingRequest,
      title: summary?.reimbursements + ' Reimbursement requests pending',
      link: '/requests/reimbursements?status=pending&budget=' + budgetCode,
    },
    summary?.invoices && {
      icon: Invoices,
      title: summary?.invoices + ' Invoices to review',
      link: '/receivables/invoices?status=pending&budget=' + budgetCode,
    },
  ].filter((element) => !!element);

  const buildTopSpender = topSpenders.map((item) => ({
    name: `${item?.user?.firstName} ${item?.user?.lastName}`,
    amount: item?.spent / 100,
  }));

  return (
    <div className="budget-analytics mt-4">
      <div className="grid-col-2 gap-4">
        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Pending task</span>
              <span className="pill">
                {summary
                  ? Object.values(summary).reduce(
                      (total, currentValue) => total + Number(currentValue),
                      0,
                    )
                  : 0}
              </span>
            </div>
          }
          isEmpty={!taskss.length}
        >
          {taskss.map((item, index) => {
            const Icon = item.icon;
            return (
              <TaskAction
                key={index}
                icon={Icon}
                title={item.title}
                borderBottom={index !== taskss.length - 1}
                link={item.link}
              />
            );
          })}
        </OverviewCard>

        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2 justify-content-between w-100">
              <div className="d-flex gap-2 align-items-center">
                <span className="text">Budget details</span>
              </div>
              <div className="small-text">
                Created on: {getFormattedDate(budget.created_at)}
              </div>
            </div>
          }
        >
          <div className="grid-col-2">
            <div className="ps-1">
              <UserDetails
                title="Created by"
                users={budget.owner && [{ user: budget.owner }]}
                className="mb-4"
              />
              {budgetOwners && budgetOwners.length ? (
                <UserDetails
                  title="Budget Owners"
                  className="mb-4"
                  users={budgetOwners}
                />
              ) : null}
            </div>
            {beneficiaries && beneficiaries.length ? (
              <div>
                <UserDetails title="Spenders" users={beneficiaries} />
              </div>
            ) : null}
          </div>
        </OverviewCard>
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center insights-title">
        <div>
          <span className="text">Budget insights</span>
          <div className="small-text">
            Get a detailed view of your budget and spending over a given period of time.
          </div>
        </div>

        <div className="mt-md-0 mt-2">
          <DateRangeFilter
            handleSelectRange={handleSelectRange}
            selectedRange={selectedRange}
          />
        </div>
      </div>

      <div className="grid-cols-2 gap-4 mt-4">
        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Top spenders</span>
            </div>
          }
        >
          <BarChartView
            totalSpent={2000}
            currency={currency}
            totalPercentage={100}
            data={buildTopSpender}
            showTotalSpent={false}
          />
        </OverviewCard>

        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Expense breakdown</span>
            </div>
          }
        >
          <AreaChartView
            totalSpent={totalSpent}
            currency={currency}
            totalPercentage={100}
            data={buildChartDataFromTransaction(chartData)}
          />
        </OverviewCard>

        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Top merchants</span>
            </div>
          }
        >
          <PieChartView
            isLoading={isLoading}
            success={isSuccess}
            data={{
              analytics: buildChartDataForExpenseHead(chartVendors, 'vendors'),
            }}
            title="Merchants"
            totalSpent={
              chartVendors.reduce(
                (total, { transactionVolume }) => total + Number(transactionVolume),
                0,
              ) / 100
            }
            currency={currency}
          />
        </OverviewCard>

        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Top categories</span>
            </div>
          }
        >
          <div className="h-100 w-100">
            <PieChartView
              isLoading={isLoading}
              success={isSuccess}
              data={{
                analytics: buildChartDataForExpenseHead(chartCategories, 'categories'),
              }}
              title="Categories"
              totalSpent={
                chartCategories.reduce(
                  (total, { transactionVolume }) => total + Number(transactionVolume),
                  0,
                ) / 100
              }
              currency={currency}
            />
          </div>
        </OverviewCard>
      </div>
    </div>
  );
};

export default BudgetOverviewAnalytics;

const UserDetails = ({ title, users = [], className }) => {
  const allItems = users?.length > 6 ? users.slice(6) : [];
  const firstSix = users?.length < 7 ? users.slice(0, 7) : users.slice(0, 4);
  const { budgetId } = useParams();

  const { push } = useHistory();

  return (
    <div className={classNames('user-list', { [className]: className })}>
      <p>{title}</p>

      {firstSix.map((item, index) => {
        return (
          <div className="gap-3 d-flex align-items-center mt-1" key={index}>
            <OverlayingIcons userList={[item]} />
            <span className={classNames('user-text')}>
              {`${item?.user?.firstName ?? item?.firstName} ${
                item?.user?.lastName ?? item?.lastName
              }`}
            </span>
          </div>
        );
      })}

      {!!allItems.length && (
        <div className="gap-3 d-flex align-items-center mt-1">
          <OverlayingIcons userList={allItems} />
          <span
            className={classNames('user-text text-orange cursor')}
            onClick={() => push(`/expenses/budgets/${budgetId}/beneficiaries`)}
          >
            View all
          </span>
        </div>
      )}
    </div>
  );
};
