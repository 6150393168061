import { toastError } from 'components/UI/toast';

export const formValidation = (formData) => {
  const {
    name,
    description,
    requiresBudget,
    requiresDescription,
    requiresCategory,
    requiresReceipt,
    rules,
  } = formData;
  if (!name) {
    return 'Name is required';
  }

  if (!description) {
    return 'Description is required';
  }

  const isRequirementSelected =
    !requiresBudget && !requiresDescription && !requiresCategory && !requiresReceipt;

  if (isRequirementSelected) {
    return 'At least one requirement is required';
  }

  if (isRequirementSelected) {
    return 'At least one requirement is required';
  }

  const position = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
  let index = 0;
  for (let rule of rules) {
    if (
      !rule['condition-one'].selectedValue ||
      !rule['condition-two'].selectedValue ||
      !rule['condition-three'].selectedValue
    )
      return `Please fill all fields in the ${position[index]} condition`;
    index++;
  }
  return true;
};

export const amountConditionValidation = (conditions) => {
  let amountConditions = conditions.filter((item) => item.trigger === 'amount');

  if (amountConditions.length) {
    let amountConditionsOperator = conditions.map((item) => item.operator);

    if (amountConditions.length == 2) {
      if (amountConditionsOperator[0] === amountConditionsOperator[1]) {
        toastError('Amount conditions are conflicting, please review');
        return false;
      }
      if (
        amountConditionsOperator.includes('gte') &&
        amountConditionsOperator.includes('lte') &&
        amountConditions[0].operands === amountConditions[1].operands
      ) {
        toastError(
          "The values for 'greater than or equal to' and 'less than or equal to' conditions are conflicting",
        );
        return false;
      }
      if (amountConditionsOperator[0] === 'eq') {
        toastError("The 'equal to' condition, cannot be paired with any other condition");
        return false;
      }

      if (
        amountConditionsOperator.includes('gt') &&
        amountConditionsOperator.includes('gte')
      ) {
        toastError(
          "The 'greater than' condition, cannot be paired with 'greater than or equal to'",
        );
        return false;
      }
      if (
        amountConditionsOperator.includes('lt') &&
        amountConditionsOperator.includes('lte')
      ) {
        toastError(
          "The 'less than' condition, cannot be paired with 'less than or equal to'",
        );
        return false;
      }
    }
  }
  return true;
};
