const containerStyle = {
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
  color: '#79716B',
};

const NameBadge = ({ name = '', truncate = true, truncateLength = 25 }) => {
  return (
    <div style={containerStyle}>
      <span className="font-medium item-value">
        {truncate ? name?.substr(0, truncateLength) : name}
      </span>
    </div>
  );
};

export default NameBadge;
