import { useState, useEffect, useContext, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton } from 'antd';
import { fetchplansAction } from 'redux/actions/BillingActions';

import PlanTable from './component/PlanTable';
import './plans.styles.css';
import Loading from 'components/UI/Loading';

const INTERVAL = ['monthly', 'annually'];

const Plans = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('monthly');
  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;

  const {
    fetchPlans: { data: planData, loading: isLoading },
  } = useSelector(({ billing }) => billing);

  useEffect(() => {
    dispatch(fetchplansAction());
  }, []);

  const activePlan = userData?.user?.company?.paymentPlan?.name;

  return (
    <section className="plans-wrapper fade-in">
      <div className="header d-flex flex-column justify-content-center align-items-center">
        <div className="w-100">
          <h1>Plans</h1>
          {isLoading ? (
            <div>
              <Skeleton.Input
                active
                className="col-sm-3 mb-2 d-flex flex-wrap"
                size={16}
                style={{ borderRadius: 4, minWidth: '16rem' }}
              />
            </div>
          ) : (
            <p>
              You are currently on the {activePlan} plan.
              {`${
                activePlan !== 'custom' ? ' For enterprise plans, please contact us.' : ''
              } `}
            </p>
          )}
        </div>

        <div className="plan-type-selector d-flex">
          <>
            {INTERVAL.map((item) => {
              return (
                <div
                  key={item}
                  className={`items ${item === type && 'active-type'}`}
                  onClick={() => (isLoading ? null : setType(item))}
                >
                  {`${item} billing`}
                  {item === 'annually' && <span className="save">save 16%</span>}
                </div>
              );
            })}
          </>
        </div>
      </div>

      <main>
        <PlanTable data={planData} type={type} isLoading={isLoading} />
      </main>
    </section>
  );
};

export default Plans;
