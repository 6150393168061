import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  LayersTwoIcon,
  MinusCircle,
  SettingsIcon2,
  SlashCircleIcon,
  ToggleIcon,
  TrashIcon,
} from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog';
import { BudgetsStatusType, CurrencyType } from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData/index.js';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import { useDebounce } from 'hooks/useDebounce';
import ExpensesEmptyStateData from 'pages/Expenses/expensesEmptystateData';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  activateBudget,
  deactivateBudget,
  getBudgets,
  getSingleBudgets,
  resetBlockBudget,
} from 'redux/actions/BudgetsAction';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildBudgetTableData, capitalizeFirstLetter } from 'utils/helper';
import { budgetColumns } from 'utils/mockData';
import { getQueryParams, updateStatus } from 'utils/utility';
import BudgetModal from './BudgetModal';
import WithdrawFundsModal from './BudgetModal/WithdrawModal';
import BudgetsCard from './BudgetsCard/index.js';
import BudgetAnalytics from './Components/BudgetAnalytics';
import SearchLoader from './Components/SearchLoader';
import PendingOnboardingNotice from './PendingOnboardingNotice';
import LiquidateBudget from './SelectedBudgets/LiquidateBudgetModal';
import './style.less';
import CustomPopover from 'components/UI/Popover';
import { toggleAction } from 'redux/actions/ToggleAction';
import { SendFundsButton } from 'components/UI/CustomButton';
import ConfirmDialog from 'components/ConfirmDialog';
import { Modal } from 'react-bootstrap';

const Budgets = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([...CurrencyType]);
  const { permissions, onboardingStatus } = allPermissions();
  const canViewBudgets = hasPermission({
    permissions,
    scopes: ['budget-view'],
  });

  const canCreateBudgets = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create'],
  });

  const canManage = hasPermission({
    permissions,
    scopes: ['budget-edit', 'budget-*'],
  });
  const {
    updateBudget: { success: updateSuccess, loading: isUpdating },
    withdrawnBudget: { success: withdrawSuccess },
    getBudget: {
      data: { budgets: budgetsCard = [], summary = {}, meta = {} } = {},
      loading,
      success,
    },
    deactivateBudget: { data: act, success: deactivateSuccess, loading: isDeactivating },
    activateBudget: { success: activateSuccess, loading: isActivating },
  } = useSelector(({ budgets }) => budgets);

  const [isAdvance, setIsAdvance] = useState(false);
  const [search, setSearch] = useState('');
  const [activeView, setActiveView] = useState('list');
  const debouncedValue = useDebounce(search, 600);
  const [filteredQuery, setFilteredQuery] = useState({});
  const [actionType, setActionType] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isWithdraw, setWithdraw] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [budgetStatus, setBudgetStatus] = useState('active');
  const [editExpiry, setEditExpiry] = useState(false);
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const { page, total, hasMore, perPage, nextPage } = meta;

  useEffect(() => {
    if (location.state?.isModal) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (deactivateSuccess || activateSuccess || withdrawSuccess || updateSuccess) {
      setDeactivateModal(false);
      setDeleteModal(false);
      setWithdraw(false);
      setSelectedData(null);
      setActionType('');
      dispatch(getBudgets({ status, ...filteredQuery }));
    }
  }, [activateSuccess, deactivateSuccess, withdrawSuccess, updateSuccess]);

  const status = budgetStatus === 'active' ? 'active' : ['inactive', 'pause'];

  const columns = useMemo(() => budgetColumns, [budgetsCard]);

  const rows = useMemo(() => buildBudgetTableData(budgetsCard), [budgetsCard]);

  useEffect(() => {
    if (canViewBudgets && !location?.search) {
      dispatch(getBudgets({ status }));
      if (budgetStatus !== 'active') isFiltered.current = true;
    }
  }, [budgetStatus]);

  const toggleHandler = (budgetCode) => {
    if (actionType === 'edit') setActionType('');
    if (budgetCode) dispatch(getSingleBudgets({ id: budgetCode }));
    setStep(1);
    setIsOpen(!isOpen);
    setEditExpiry(!editExpiry);
  };

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  useEffect(() => {
    if (location?.search && canViewBudgets) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getBudgets({ status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  useEffect(() => {
    if (!budgetsCard.length) {
      dispatch(resetBlockBudget({ blockType: 'getSingleBudget' }));
    }

    return () => {
      if ((filtered || location?.search) && canViewBudgets)
        dispatch(getBudgets({ status }));
    };
  }, [filtered]);

  const handleFilter = (query) => {
    dispatch(getBudgets(query));
    setFilteredQuery(query);
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleBudgetStatus = () => {
    if (actionType === 'activate') dispatch(activateBudget(selectedData?.code));
    if (actionType === 'deactivate')
      dispatch(deactivateBudget({ code: selectedData?.code, action: 'pause' }));
  };

  const actionHandler = (event, action, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    isFiltered.current = true;
    setSelectedData(data);
    setIsPopoverOpen(true);
    setActionType(action);
    if (action === 'withdraw') setWithdraw(!isWithdraw);
    if (action === 'delete') setDeleteModal(!deleteModal);
    if (action === 'deactivate' || action === 'activate')
      setDeactivateModal(!deactivateModal);
    if (action === 'edit') toggleHandler(data?.code);
  };

  const toggleWithdrawHandler = () => {
    setWithdraw(!isWithdraw);
  };

  const toggleActionButton = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };

  const handleDelete = (balance) => {
    const payload = {
      code: selectedData?.code,
      action: 'close',
      settlementBalance: balance ? balance : undefined,
    };
    dispatch(deactivateBudget(payload));
  };

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const deleteToggleHandler = () => setDeleteModal(!deleteModal);

  const PaymentActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const Actions = ({ list: { budgetData } }) => {
    return (
      <div className="actions-dialog" style={{ marginTop: 25 }}>
        {budgetData?.available > 0 && (
          <div
            className="actionLink"
            onClick={(event) => {
              actionHandler(event, 'withdraw', budgetData);
            }}
          >
            <MinusCircle /> Withdraw funds
          </div>
        )}

        <div
          className="actionLink"
          onClick={(event) => {
            actionHandler(
              event,
              `${budgetData?.status === 'active' ? 'deactivate' : 'activate'}`,
              budgetData,
            );
          }}
        >
          <ToggleIcon width="18" height="18" />{' '}
          {budgetData?.status === 'active' ? 'Deactivate budget' : 'Activate budget'}
        </div>
        <div
          className="actionLink"
          onClick={(event) => {
            actionHandler(event, 'edit', budgetData);
          }}
        >
          <SettingsIcon2 width="18" height="18" /> Update budget
        </div>
        <div
          className="actionLink text-danger svg-danger"
          onClick={(event) => {
            actionHandler(event, 'delete', budgetData);
          }}
        >
          <TrashIcon className="me-0" width="18" height="18" /> Delete budget
        </div>
      </div>
    );
  };

  const onHandleToggleView = (option) => {
    setActiveView(option);
  };

  const handlePreviousPage = (page) => {
    dispatch(getBudgets({ perPage, page, status, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getBudgets({ perPage, page, status, ...filteredQuery }));
  };

  const handleRowClick = (row) => {
    const { rowStatus, code } = row;
    if (
      rowStatus === 'deleted' ||
      (['inactive', 'pause'].includes(rowStatus) && !canManage)
    )
      return;
    return history.push(`/expenses/budgets/${code}/overview`);
  };

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue && canViewBudgets) {
      filteredQuery.search = debouncedValue;
      dispatch(getBudgets({ ...filteredQuery, status, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewBudgets) {
      delete filteredQuery.search;
      dispatch(getBudgets({ status, perPage, ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleMore = (perPage) => {
    setTimeout(() => {
      if (!loading) {
        dispatch(getBudgets({ perPage, ...filteredQuery, status }));
        isFiltered.current = true;
      }
    }, 1500);
  };

  const loadingState = loading && !filtered;

  const budgetList = () => {
    return (
      <>
        {!budgetsCard.length && !loading ? (
          <div>
            {isFiltered.current ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no budgets for this filter"
                  bodyText="Alter filter to see budgets"
                  withButton={false}
                />
              </div>
            ) : (
              <>
                {budgetStatus !== 'inactive' ? (
                  <ExpensesEmptyStateData
                    openBudgetModal={toggleHandler}
                    showAction={canCreateBudgets}
                  />
                ) : (
                  <div className="tabinnerWrapper">
                    <NoData
                      headerText="You have no inactive budgets yet"
                      bodyText="Alter filter to see active budgets"
                      withButton={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="pt-4 w-100 px-0 mx-0">
            <Container className="px-0 w-100 mx-0">
              {activeView === 'grid' ? (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => handleMore(perPage + 50)}
                  hasMore={hasMore}
                  className="w-100"
                  // loader={<h4></h4>}
                >
                  <Row className="budgets-card-wrapper w-100 m-0">
                    {budgetsCard.map((budget, index) => (
                      <BudgetsCard budget={budget} key={`budgets-${index}`} />
                    ))}
                  </Row>
                </InfiniteScroll>
              ) : (
                <Row className="spaced-table">
                  <Col xs={12}>
                    <Table
                      isCustomWidth={true}
                      // stringType={false}
                      hasCheckBox={true}
                      columns={columns}
                      data={rows}
                      onRowClick={handleRowClick}
                      pagination
                      hasMore={hasMore}
                      currentPage={page}
                      nextPage={() => handleNextPage(nextPage)}
                      previousPage={() => handlePreviousPage(page - 1)}
                      totalPage={Math.ceil(total / perPage)}
                      modify={true}
                      popoverAction={Actions}
                      popoverState={isPopoverOpen}
                      setPopoverState={setIsPopoverOpen}
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        )}
      </>
    );
  };

  // if (loading && !filtered) return <Loading isPage color="#D28B28" />;

  const show = !!budgetsCard?.length || (filtered && !budgetsCard?.length);

  const Loader = () => {
    return (
      <section className="fade-in">
        <div>
          <BudgetAnalytics loading />
        </div>

        <div className="mt-4">
          <SearchLoader hasExport />
        </div>

        <div className="mt-4 ">
          <Table
            columns={budgetColumns}
            data={buildBudgetTableData(budgetsCard)}
            hasCheckBox={false}
            hasAvatar={false}
            loading
          />
        </div>
      </section>
    );
  };

  return (
    <div className="budgets-wrapper container w-100">
      <TopBar
        headerText="Budgets"
        subText="Manage your budgets: Create, fund and assign to members."
        isRightBar
        dropDownBtn={
          <AllowedTo scopes={['transaction-*', 'transaction-create']}>
            <CustomPopover
              zIndex="1"
              content={<PaymentActions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <SendFundsButton onClick={handleButtonToggle} />
            </CustomPopover>
          </AllowedTo>
        }
        addButton={canCreateBudgets}
        addActionButton={canViewBudgets && 'Create budget'}
        toggleActionButton={toggleActionButton}
      />

      {loadingState ? (
        <Loader />
      ) : (
        <>
          <section className="overview-holder">
            <PendingOnboardingNotice />

            <BudgetAnalytics data={summary} loading={loadingState} />

            <TopBar
              setStatus={setBudgetStatus}
              status={budgetStatus}
              budgetStatus={show}
            />

            <TopBar
              // addOnClick={toggleHandler}
              showFilter={show}
              setSearchVal={setSearch}
              searchVal={search}
              filterData={filterData}
              handleFilterSelect={(updateVal) => {
                setFilterData(updateVal);
              }}
              inputPlaceholder="Search for budgets"
              handleFilterApply={handleFilter}
              withDate
              withOutSearch
              showBarSearch={show}
              exportLoading={true}
              addExport={false}
              toggleView={show}
              handleToggleView={onHandleToggleView}
              activeView={activeView}
            />
            {budgetList()}
          </section>

          <BudgetModal
            editExpiry={editExpiry}
            step={step}
            setStep={setStep}
            isOpen={isOpen}
            budgetCode={selectedData?.code}
            setIsOpen={setIsOpen}
            toggleHandler={toggleHandler}
            isUpdate={actionType === 'edit'}
            setIsAdvance={setIsAdvance}
            isAdvance={isAdvance}
            isFunding={false}
            setIsFunding={() => null}
          />

          {isWithdraw && (
            <WithdrawFundsModal
              handleClose={() => setWithdraw(false)}
              data={selectedData}
            />
          )}

          <Modal show={deactivateModal} centered dialogClassName="custom-dialog">
            <ConfirmDialog
              title={`${capitalizeFirstLetter(actionType)} budget`}
              subTitle={`Are you sure you want to ${actionType} this budget?`}
              onConfirm={handleBudgetStatus}
              loading={isDeactivating || isActivating}
              onCancel={() => setDeactivateModal(false)}
              dialogIcon={
                actionType === 'deactivate' ? (
                  <SlashCircleIcon width="30" height="30" stroke="#d57e78" />
                ) : null
              }
              isDeleteDialog={actionType === 'deactivate'}
              actionBtnText={`${capitalizeFirstLetter(actionType)}`}
            />
          </Modal>

          {deleteModal &&
            (selectedData?.available > 0 ? (
              <LiquidateBudget
                isOpen={deleteModal}
                onCancel={deleteToggleHandler}
                onClick={handleDelete}
                isLoading={isDeactivating}
              />
            ) : (
              <DeleteDialog
                title="Delete budget"
                subTitle=" Are you sure you want to delete this budget? This action can not be undone."
                onCancel={deleteToggleHandler}
                onDelete={handleDelete}
                isLoading={isDeactivating}
              />
            ))}
        </>
      )}
    </div>
  );
};

export default Budgets;
