import BillingAddress from './components/BillingAddress';
import { useHistory } from 'react-router-dom';

const ChangeBillingDetails = () => {
  const history = useHistory();
  const goBack = () => {
    history.push('/settings/billings');
  };

  return (
    <section className="billing-checkout-wrapper position-relative">
      <div className="container">
        <div className="back">
          <span className="d-flex align-items-center gap-2" onClick={goBack}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8334 7.00008H1.16675M1.16675 7.00008L7.00008 12.8334M1.16675 7.00008L7.00008 1.16675"
                stroke="#D28B28"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </span>
        </div>
        <header>
          <h1 className="title">Upgrade to Growth</h1>
          <p className="body">
            For teams that want an all-in-one solution with visibility and control
          </p>
        </header>
        <section className="row mt-4 pt-3">
          <div className="col-sm-12 col-md-8 col-lg-8">
            <BillingAddress />
          </div>
        </section>
      </div>
    </section>
  );
};

export default ChangeBillingDetails;
