import { CsvIcon, DownloadArrowIcon } from 'assets/icons';
import { format } from 'date-fns';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { getFormattedDate } from 'utils/helper';

const DownloadExcelStatement = ({ handleButtonToggle, data }) => {
  const source = data?.name;

  const successfulTransactions = data?.transactions?.reduce((total, transaction) => {
    return transaction.status === 'success' ? total + transaction.amount : total;
  }, 0);

  const generateAccountStatement = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${data?.name} Statement`);
    const currencyFormat = '[$₦-101]#,##0.00';
    const date = new Date();

    // Set header
    worksheet.mergeCells('A1:J1'); // Merge cells to span 3 columns
    const headerRow = worksheet.getRow(1);
    const headerCell = headerRow.getCell(1);
    headerCell.value = `${data?.name} Statement`;
    headerCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B35309' }, // Orange color
    };
    headerCell.font = { bold: true, size: 25 };
    headerCell.font.color = { argb: 'FFFFFF' };
    headerCell.alignment = { horizontal: 'center' };

    // Add account and date
    worksheet.mergeCells('A3:B3'); // Merge cells for account label
    const accountLabelRow = worksheet.getRow(3);
    const accountLabelCell = accountLabelRow.getCell(1);
    accountLabelCell.value = `${data?.name}`;
    accountLabelCell.font = { bold: true, size: 18 };
    accountLabelCell.font.color = { argb: 'B35309' };

    worksheet.mergeCells('A4:B4'); // Merge cells for account value
    const accountValueRow = worksheet.getRow(4);
    const accountValueCell = accountValueRow.getCell(1);
    accountValueCell.font = { size: 12 };
    accountValueCell.value = `Paid by ${data?.payer?.firstName} ${data?.payer?.lastName}`;
    accountValueCell.alignment = { horizontal: 'left' };

    worksheet.mergeCells('D3:E3'); // Merge cells for date label
    const dateLabelRow = worksheet.getRow(3);
    const dateLabelCell = dateLabelRow.getCell(4);
    dateLabelCell.value = 'Date';
    dateLabelCell.font = { bold: true, size: 16 };
    dateLabelCell.font.color = { argb: 'B35309' };

    worksheet.mergeCells('D4:E4'); // Merge cells for date value
    const dateValueRow = worksheet.getRow(4);
    const dateValueCell = dateValueRow.getCell(4);
    dateValueCell.font = { bold: true, size: 12 };
    dateValueCell.value = data?.updated_at
      ? format(new Date(data?.updated_at), 'yyyy-MM-dd')
      : format(date, 'yyyy-MM-dd');

    // Skip two rows
    worksheet.addRow([]);
    worksheet.addRow([]);

    // Set Summary
    worksheet.mergeCells('A7:B7'); // Merge cells to span 3 columns
    const summaryRow = worksheet.getRow(7);
    const summaryCell = summaryRow.getCell(1);
    summaryCell.value = 'Summary';

    summaryCell.font = { bold: true, size: 16 };
    summaryCell.font.color = { argb: '000000' };

    // Add money in/out
    worksheet.mergeCells('A10:B10'); // Merge cells for money in label
    const MoneyInLabelRow = worksheet.getRow(10);
    const MoneyInLabelCell = MoneyInLabelRow.getCell(1);
    MoneyInLabelCell.value = 'Total Amount';
    MoneyInLabelCell.font = { bold: true, size: 16 };
    MoneyInLabelCell.font.color = { argb: '000000' };

    worksheet.mergeCells('A11:B11'); // Merge cells for money in value
    const MoneyInValueRow = worksheet.getRow(11);
    const MoneyInValueCell = MoneyInValueRow.getCell(1);
    MoneyInValueCell.value = data?.amount / 100;
    MoneyInValueCell.numFmt = currencyFormat;
    MoneyInValueCell.font = { size: 12 };
    MoneyInValueCell.alignment = { horizontal: 'left' };

    worksheet.mergeCells('D10:E10'); // Merge cells for money out label
    const MoneyOutLabelRow = worksheet.getRow(10);
    const MoneyOutLabelCell = MoneyOutLabelRow.getCell(4);
    MoneyOutLabelCell.value = 'Total Paid';
    MoneyOutLabelCell.font = { bold: true, size: 16 };
    MoneyOutLabelCell.font.color = { argb: '000000' };

    worksheet.mergeCells('D11:E11'); // Merge cells for money out value
    const MoneyOutValueRow = worksheet.getRow(11);
    const MoneyOutValueCell = MoneyOutValueRow.getCell(4);
    MoneyOutValueCell.value = successfulTransactions / 100;
    MoneyOutValueCell.numFmt = currencyFormat;
    MoneyOutValueCell.font = { size: 12 };
    MoneyOutValueCell.alignment = { horizontal: 'left' };

    worksheet.addRow([]); // Add an empty row after opening balance

    const sheetHeader = [
      'Date',
      'Recipient',
      'Account Number',
      'Bank Name',
      'Paid by',
      'Amount',
      'Source',
      'Category',
      'Description',
      'Status',
    ];

    worksheet.addRow(sheetHeader);

    // Apply fill color to all rows
    const headerFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'B35309' }, // Orange color
    };
    const headerFont = { bold: true, color: { argb: 'FFFFFF' } };
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        if (cell.row === 13) {
          // Check if it is the header row
          cell.fill = headerFill;
          cell.font = headerFont;
        }
      });
      row.height = 20;
    });

    data?.transactions.forEach((item) => {
      const sheetRowData = [
        getFormattedDate(item?.updated_at),
        item?.recipient?.name,
        item?.bank_account?.number,
        item?.bank_account?.bankName,
        item?.payer?.firstName + ' ' + item?.payer?.lastName,
        item?.amount / 100,
        item?.balance?.name,
        item?.category?.name,
        item?.description,
        item?.status,
      ];

      const row = worksheet.addRow(sheetRowData);
      row.height = 20; // Set the custom height for each row
    });

    // Apply currency format to Amount columns
    worksheet.getColumn(3).numFmt = currencyFormat;
    worksheet.getColumn(8).numFmt = currencyFormat;

    // Auto-fit column widths
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 0;
        maxLength = Math.max(maxLength, columnLength);
      });

      column.width = 20;
    });

    // Auto-fit column widths
    worksheet.eachRow((row) => {
      row.commit();
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${data?.name}-batch-statement.xlsx`);
    });
  };

  return (
    <button
      className="btn border"
      onClick={() => {
        generateAccountStatement();
      }}
    >
      <DownloadArrowIcon height="16" width="16" className="me-2" /> Download statement
    </button>
  );
};

export default DownloadExcelStatement;
