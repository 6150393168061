import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import {
  APPROVE_REIMBURSEMENTS_ERROR,
  APPROVE_REIMBURSEMENTS_REQUEST,
  APPROVE_REIMBURSEMENTS_SUCCESS,
  CREATE_REIMBURSEMENTS_ERROR,
  CREATE_REIMBURSEMENTS_REQUEST,
  CREATE_REIMBURSEMENTS_SUCCESS,
  DECLINE_REIMBURSEMENTS_ERROR,
  DECLINE_REIMBURSEMENTS_REQUEST,
  DECLINE_REIMBURSEMENTS_SUCCESS,
  DELETE_REIMBURSEMENTS_ERROR,
  DELETE_REIMBURSEMENTS_REQUEST,
  DELETE_REIMBURSEMENTS_SUCCESS,
  GET_ALL_REIMBURSEMENTS_ASSET,
  GET_ALL_REIMBURSEMENTS_ASSET_ERROR,
  GET_ALL_REIMBURSEMENTS_ASSET_SUCCESS,
  GET_REIMBURSEMENTS_ERROR,
  GET_REIMBURSEMENTS_REQUEST,
  GET_REIMBURSEMENTS_SUCCESS,
  GET_SELECTED_REIMBURSEMENTS_ERROR,
  GET_SELECTED_REIMBURSEMENTS_REQUEST,
  GET_SELECTED_REIMBURSEMENTS_SUCCESS,
  GET_SINGLE_REIMBURSEMENTS_ERROR,
  GET_SINGLE_REIMBURSEMENTS_REQUEST,
  GET_SINGLE_REIMBURSEMENTS_SUCCESS,
  RESET_BLOCK_REIMBURSEMENTS,
  RESET_FLAGS_REIMBURSEMENTS,
  REVIEW_REIMBURSEMENTS_ERROR,
  REVIEW_REIMBURSEMENTS_REQUEST,
  REVIEW_REIMBURSEMENTS_SUCCESS,
  UPDATE_REIMBURSEMENTS_ERROR,
  UPDATE_REIMBURSEMENTS_REQUEST,
  UPDATE_REIMBURSEMENTS_SUCCESS,
} from '../reducers/ReimbursementsReducer';

async function getReimbursement({ payload = {} }) {
  return await Axios.get(`/reimbursements`, { params: payload });
}
function* handleGetReimbursement(payload) {
  try {
    const response = yield call(getReimbursement, payload);
    if (response) {
      yield put({
        type: GET_REIMBURSEMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSelectedReimbursement({ payload = {} }) {
  return await Axios.get(`/reimbursements`, { params: payload });
}
function* handleGetSelectedReimbursement(payload) {
  try {
    const response = yield call(getSelectedReimbursement, payload);
    if (response) {
      yield put({
        type: GET_SELECTED_REIMBURSEMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SELECTED_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createReimbursement(payload) {
  return await Axios.post('/reimbursements', payload);
}
function* handleCreateReimbursement({ payload }) {
  try {
    const response = yield call(createReimbursement, payload);
    if (response) {
      yield put({
        type: CREATE_REIMBURSEMENTS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REIMBURSEMENTS,
        blockType: 'createReimbursement',
      });

      yield put({
        type: GET_REIMBURSEMENTS_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateReimbursement(payload) {
  const { code, ...others } = payload;
  return await Axios.put(`/reimbursements/${code}`, others);
}
function* handleUpdateReimbursement({ payload }) {
  const { showSuccessToast, ...restPayload } = payload;
  try {
    const response = yield call(updateReimbursement, restPayload);
    if (response) {
      yield put({
        type: UPDATE_REIMBURSEMENTS_SUCCESS,
        data: response.data,
      });
      if (showSuccessToast !== false) toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function approveReimbursement(payload) {
  const { code, ...rest } = payload;
  return await Axios.post(`/reimbursements/${code}/approve`, rest);
}
function* handleApproveReimbursement({ payload }) {
  try {
    const response = yield call(approveReimbursement, payload);
    if (response) {
      yield put({
        type: APPROVE_REIMBURSEMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: APPROVE_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function declineReimbursement(payload) {
  return await Axios.post(`/reimbursements/${payload.code}/decline`, {
    note: payload.note,
  });
}
function* handleDeclineReimbursement({ payload }) {
  try {
    const response = yield call(declineReimbursement, payload);
    if (response) {
      yield put({
        type: DECLINE_REIMBURSEMENTS_SUCCESS,
      });

      yield delay(300);
      yield put({
        type: RESET_FLAGS_REIMBURSEMENTS,
        blockType: 'declineReimbursement',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DECLINE_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteReimbursement(payload) {
  return await Axios.delete(`/reimbursements/${payload}`);
}
function* handleDeleteReimbursement({ payload }) {
  try {
    const response = yield call(deleteReimbursement, payload);
    if (response) {
      yield put({
        type: DELETE_REIMBURSEMENTS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_REIMBURSEMENTS,
        blockType: 'deleteReimbursement',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleReimbursement(params) {
  return await Axios.get(`/reimbursements/${params}`);
}
function* handleSingleReimbursement({ params }) {
  try {
    const response = yield call(singleReimbursement, params);
    if (response) {
      yield put({
        type: GET_SINGLE_REIMBURSEMENTS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reviewMultipleReimbursements(payload) {
  return await Axios.post(`/reimbursements/multiple-approval`, payload);
}
function* handleMultipleReimbursements({ payload }) {
  try {
    const response = yield call(reviewMultipleReimbursements, payload);
    if (response) {
      yield put({
        type: REVIEW_REIMBURSEMENTS_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REVIEW_REIMBURSEMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* handleGetReimbursementAsset({ payload }) {
  const assetData = [];

  try {
    for (const item of payload) {
      const getAsset = (item) => {
        const code = item?.code;
        if (code) return Axios.get(`/assets/${code}`);
      };
      const response = yield call(getAsset, item);
      const {
        data: { asset },
      } = response;

      assetData.push({
        ...asset,
      });
    }
    yield put({
      type: GET_ALL_REIMBURSEMENTS_ASSET_SUCCESS,
      data: assetData,
    });
  } catch (error) {
    yield put({
      type: GET_ALL_REIMBURSEMENTS_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_REIMBURSEMENTS_REQUEST, handleGetReimbursement),
  takeLatest(GET_SELECTED_REIMBURSEMENTS_REQUEST, handleGetSelectedReimbursement),
  takeLatest(CREATE_REIMBURSEMENTS_REQUEST, handleCreateReimbursement),
  takeLatest(UPDATE_REIMBURSEMENTS_REQUEST, handleUpdateReimbursement),
  takeLatest(GET_SINGLE_REIMBURSEMENTS_REQUEST, handleSingleReimbursement),
  takeLatest(APPROVE_REIMBURSEMENTS_REQUEST, handleApproveReimbursement),
  takeLatest(DECLINE_REIMBURSEMENTS_REQUEST, handleDeclineReimbursement),
  takeLatest(DELETE_REIMBURSEMENTS_REQUEST, handleDeleteReimbursement),
  takeLatest(GET_ALL_REIMBURSEMENTS_ASSET, handleGetReimbursementAsset),
  takeLatest(REVIEW_REIMBURSEMENTS_REQUEST, handleMultipleReimbursements),
]);
