import { Axios } from 'api/axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';

import { toastError, toastSuccess } from 'components/UI/toast';
import {
  ADD_CONDITION_ERROR,
  ADD_CONDITION_REQUEST,
  ADD_CONDITION_SUCCESS,
  ADD_NEW_APPROVAL_LEVEL_ERROR,
  ADD_NEW_APPROVAL_LEVEL_REQUEST,
  ADD_NEW_APPROVAL_LEVEL_SUCCESS,
  CREATE_APPROVAL_RULE_ERROR,
  CREATE_APPROVAL_RULE_REQUEST,
  CREATE_APPROVAL_RULE_SUCCESS,
  DELETE_APPROVAL_LEVEL_ERROR,
  DELETE_APPROVAL_LEVEL_REQUEST,
  DELETE_APPROVAL_LEVEL_SUCCESS,
  DELETE_APPROVAL_RULE_ERROR,
  DELETE_APPROVAL_RULE_REQUEST,
  DELETE_APPROVAL_RULE_SUCCESS,
  DELETE_CONDITION_ERROR,
  DELETE_CONDITION_REQUEST,
  DELETE_CONDITION_SUCCESS,
  EDIT_APPROVAL_RULE_BY_ID_ERROR,
  EDIT_APPROVAL_RULE_BY_ID_REQUEST,
  EDIT_APPROVAL_RULE_BY_ID_SUCCESS,
  FETCH_APPROVAL_RULES_ERROR,
  FETCH_APPROVAL_RULES_REQUEST,
  FETCH_APPROVAL_RULES_SUCCESS,
  FETCH_APPROVAL_RULE_ERROR,
  FETCH_APPROVAL_RULE_REQUEST,
  FETCH_APPROVAL_RULE_SUCCESS,
  FETCH_APPROVERS_ERROR,
  FETCH_APPROVERS_REQUEST,
  FETCH_APPROVERS_SUCCESS,
  FETCH_PENDING_APPROVALS_ERROR,
  FETCH_PENDING_APPROVALS_REQUEST,
  FETCH_PENDING_APPROVALS_SUCCESS,
  FETCH_PENDING_APPROVAL_ASSET_ERROR,
  FETCH_PENDING_APPROVAL_ASSET_REQUEST,
  FETCH_PENDING_APPROVAL_ASSET_SUCCESS,
  FETCH_RELATED_APPROVALS_ERROR,
  FETCH_RELATED_APPROVALS_REQUEST,
  FETCH_RELATED_APPROVALS_SUCCESS,
  FETCH_SINGLE_APPROVAL_ERROR,
  FETCH_SINGLE_APPROVAL_REQUEST,
  FETCH_SINGLE_APPROVAL_SUCCESS,
  FETCH_TRANSACTION_TYPES_ERROR,
  FETCH_TRANSACTION_TYPES_REQUEST,
  FETCH_TRANSACTION_TYPES_SUCCESS,
  RESET_APPROVAL_LEVEL_RULE,
  RESET_BLOCK_APPROVAL,
  RESET_CREATE_APPROVAL_RULE,
  REVIEW_MULTIPLE_PENDING_REQUEST_ERROR,
  REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST,
  REVIEW_MULTIPLE_PENDING_REQUEST_SUCCESS,
  REVIEW_MULTIPLE_REQUEST_REQUEST,
  REVIEW_REQUEST_ERROR,
  REVIEW_REQUEST_REQUEST,
  REVIEW_REQUEST_SUCCESS,
} from '../reducers/ApprovalReducer';

async function fetchApprovalRules(payload) {
  return await Axios.get('/approvals', { params: payload });
}

function* handleFetchApprovalRules({ payload }) {
  try {
    const response = yield call(fetchApprovalRules, payload);
    if (response) {
      yield put({
        type: FETCH_APPROVAL_RULES_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_CREATE_APPROVAL_RULE,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_APPROVAL_RULES_ERROR,
      error: getSimplifiedError(error),
    });
    yield put({
      type: RESET_CREATE_APPROVAL_RULE,
    });
  }
}

async function fetchSingleApprovalRule({ code }) {
  return await Axios.get(`/approvals/${code}`);
}

function* handleFetchSingleApprovalRule({ code }) {
  try {
    const response = yield call(fetchSingleApprovalRule, { code });
    if (response) {
      yield put({
        type: FETCH_APPROVAL_RULE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_APPROVAL_RULE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSingleApproval({ code }) {
  return await Axios.get(`/approvals/group-requests/${code}`);
}

function* handleFetchSingleApproval({ code }) {
  try {
    const response = yield call(fetchSingleApproval, { code });
    if (response) {
      yield put({
        type: FETCH_SINGLE_APPROVAL_SUCCESS,
        data: {
          ...response.data?.approvalRequest,
          rules: response.data?.relatedApprovalRequests,
        },
      });

      yield put({
        type: FETCH_RELATED_APPROVALS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_APPROVAL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchRelatedApprovals({ code }) {
  return await Axios.get(`/approvals/group-requests/${code}`);
}

function* handleFetchRelatedApprovals({ code }) {
  try {
    const response = yield call(fetchRelatedApprovals, { code });
    if (response) {
      yield put({
        type: FETCH_RELATED_APPROVALS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_RELATED_APPROVALS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchTransactionTypes() {
  return await Axios.get('/approvals/types');
}

function* handleFetchTransactionTypes() {
  try {
    const response = yield call(fetchTransactionTypes);
    if (response) {
      yield put({
        type: FETCH_TRANSACTION_TYPES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_TRANSACTION_TYPES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchApprovers() {
  return await Axios.get('/approvers');
}

function* handleFetchApprovers() {
  try {
    const response = yield call(fetchApprovers);
    if (response) {
      yield put({
        type: FETCH_APPROVERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_APPROVERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createApprovalRule({ payload }) {
  return await Axios.post('/approvals', payload);
}

function* handleCreateApprovalRule({ payload }) {
  try {
    const response = yield call(createApprovalRule, { payload });
    if (response) {
      yield put({
        type: CREATE_APPROVAL_RULE_SUCCESS,
        data: response.data,
      });
      yield put({
        type: FETCH_APPROVAL_RULES_REQUEST,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_APPROVAL_RULE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addConditionToApproval({ payload }) {
  const requestPayload = {
    ...payload,
  };
  delete requestPayload?.code;
  return await Axios.post(`/approvals/${payload.code}/condition`, requestPayload);
}

function* handleAddConditionToApproval({ payload }) {
  try {
    const response = yield call(addConditionToApproval, { payload });
    if (response) {
      yield put({
        type: ADD_CONDITION_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_CONDITION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteApproval({ id }) {
  return await Axios.delete(`/approvals/${id}`);
}

function* handleDeleteApproval({ id }) {
  try {
    const response = yield call(deleteApproval, { id });
    if (response) {
      yield put({
        type: DELETE_APPROVAL_RULE_SUCCESS,
        data: response.data,
      });
      yield put({
        type: FETCH_APPROVAL_RULES_REQUEST,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_APPROVAL_RULE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteCondition({ payload }) {
  const data = {
    conditions: payload.conditions,
  };
  return await Axios.delete(`/approvals/${payload?.code}/conditions`, data);
}

function* handleDeleteCondition({ payload }) {
  try {
    const response = yield call(deleteCondition, { payload });
    if (response) {
      yield put({
        type: DELETE_CONDITION_SUCCESS,
        data: response.data,
      });
      yield put({
        type: FETCH_APPROVAL_RULES_REQUEST,
      });
      toastSuccess('Condition successfully deleted');
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_CONDITION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function editApprovalRule({ payload }) {
  const data = {
    ...payload,
  };
  delete data?.code;
  return await Axios.patch(`/approvals/${payload?.code}`, data);
}
function* handleEditingApproval({ payload }) {
  try {
    const response = yield call(editApprovalRule, { payload });
    if (response) {
      yield put({
        type: EDIT_APPROVAL_RULE_BY_ID_SUCCESS,
        data: response.data,
      });
      // yield put({
      //   type: FETCH_APPROVAL_RULES_REQUEST,
      // });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: EDIT_APPROVAL_RULE_BY_ID_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchPendingApprovals({ payload }) {
  return await Axios.get('/approvals/group-requests', { params: payload });
}

function* handleFetchPendingApprovals({ payload }) {
  try {
    const response = yield call(fetchPendingApprovals, { payload });
    if (response) {
      yield put({
        type: FETCH_PENDING_APPROVALS_SUCCESS,
        data: response?.data?.approvalRequests || [],
        meta: response?.data?.meta || {},
        summary: response?.data?.summary || {},
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_PENDING_APPROVALS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reviewRequest({ payload, code }) {
  return await Axios.post(`approvals/group-requests/${code}/review`, payload);
}

function* handleReviewRequest({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;

    const response = yield call(reviewRequest, { payload, code: getPayload.code });

    if (response) {
      yield put({
        type: REVIEW_REQUEST_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_APPROVAL,
        blockType: 'reviewRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REVIEW_REQUEST_ERROR,
      error: getSimplifiedError(error),
    });
    yield put({
      type: FETCH_PENDING_APPROVALS_REQUEST,
    });
  }
}
async function reviewMultipleRequests({ payload }) {
  return await Axios.post(`approvals/bulk/review`, payload);
}

function* handleReviewMultipleRequests({ payload }) {
  try {
    delete payload.code;

    const response = yield call(reviewMultipleRequests, { payload });

    if (response) {
      yield put({
        type: REVIEW_REQUEST_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_APPROVAL,
        blockType: 'reviewRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REVIEW_REQUEST_ERROR,
      error: getSimplifiedError(error),
    });
    yield put({
      type: FETCH_PENDING_APPROVALS_REQUEST,
    });
  }
}

async function reviewMultiplePendingRequests({ payload }) {
  return await Axios.post(`approvals/multiple-request`, payload);
}

function* handleReviewMultiplePendingRequests({ payload }) {
  try {
    const response = yield call(reviewMultiplePendingRequests, { payload });

    if (response) {
      yield put({
        type: REVIEW_MULTIPLE_PENDING_REQUEST_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REVIEW_MULTIPLE_PENDING_REQUEST_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addNewApproverLevel({ payload }) {
  const data = {
    ...payload,
  };
  delete data?.code;
  return await Axios.put(`/approvals/${payload.code}/approver`, data);
}

function* handleNewApproverLevel({ payload }) {
  try {
    const response = yield call(addNewApproverLevel, { payload });
    if (response) {
      yield put({
        type: ADD_NEW_APPROVAL_LEVEL_SUCCESS,
        data: response.data,
      });
      yield put({
        type: FETCH_APPROVAL_RULES_REQUEST,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_NEW_APPROVAL_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteApprovalLevel({ payload }) {
  const data = {
    ...payload,
  };
  delete data?.code;
  return await Axios.delete(`/approvals/${payload.code}/approver-level`, { data });
}

function* handleDeleteApprovalLevel({ payload }) {
  try {
    const response = yield call(deleteApprovalLevel, { payload });
    if (response) {
      yield put({
        type: DELETE_APPROVAL_LEVEL_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_APPROVAL_LEVEL_RULE,
      });
      yield put({
        type: FETCH_APPROVAL_RULES_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_APPROVAL_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });
    yield put({
      type: RESET_APPROVAL_LEVEL_RULE,
    });
  }
}

function* handleGetPendingApprovalAsset({ payload }) {
  const assetData = [];

  try {
    for (const item of payload) {
      const getAsset = (item) => {
        const code = item?.code;
        if (code) return Axios.get(`/assets/${code}`);
      };
      const response = yield call(getAsset, item);
      const {
        data: { asset },
      } = response;

      assetData.push({
        ...asset,
      });
    }

    yield put({
      type: FETCH_PENDING_APPROVAL_ASSET_SUCCESS,
      data: assetData,
    });
  } catch (error) {
    yield put({
      type: FETCH_PENDING_APPROVAL_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_APPROVAL_RULES_REQUEST, handleFetchApprovalRules),
  takeLatest(FETCH_PENDING_APPROVALS_REQUEST, handleFetchPendingApprovals),
  takeLatest(REVIEW_REQUEST_REQUEST, handleReviewRequest),
  takeLatest(REVIEW_MULTIPLE_REQUEST_REQUEST, handleReviewMultipleRequests),
  takeLatest(
    REVIEW_MULTIPLE_PENDING_REQUEST_REQUEST,
    handleReviewMultiplePendingRequests,
  ),
  takeLatest(FETCH_TRANSACTION_TYPES_REQUEST, handleFetchTransactionTypes),
  takeLatest(FETCH_APPROVERS_REQUEST, handleFetchApprovers),
  takeLatest(CREATE_APPROVAL_RULE_REQUEST, handleCreateApprovalRule),
  takeLatest(DELETE_APPROVAL_RULE_REQUEST, handleDeleteApproval),
  takeLatest(EDIT_APPROVAL_RULE_BY_ID_REQUEST, handleEditingApproval),
  takeLatest(DELETE_CONDITION_REQUEST, handleDeleteCondition),
  takeLatest(ADD_CONDITION_REQUEST, handleAddConditionToApproval),
  takeLatest(ADD_NEW_APPROVAL_LEVEL_REQUEST, handleNewApproverLevel),
  takeLatest(DELETE_APPROVAL_LEVEL_REQUEST, handleDeleteApprovalLevel),
  takeLatest(FETCH_APPROVAL_RULE_REQUEST, handleFetchSingleApprovalRule),
  takeLatest(FETCH_SINGLE_APPROVAL_REQUEST, handleFetchSingleApproval),
  takeLatest(FETCH_PENDING_APPROVAL_ASSET_REQUEST, handleGetPendingApprovalAsset),
  takeLatest(FETCH_RELATED_APPROVALS_REQUEST, handleFetchRelatedApprovals),
]);
