import { Axios } from 'api/axios';
import { toastError } from 'components/UI/toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import {
  GET_VENDOR_APPROVERS_ERROR,
  GET_VENDOR_APPROVERS_REQUEST,
  GET_VENDOR_APPROVERS_SUCCESS,
  GET_VENDOR_PROFILE_ERROR,
  GET_VENDOR_PROFILE_REQUEST,
  GET_VENDOR_PROFILE_SUCCESS,
} from 'redux/reducers/VendorProfileReducer';

async function getVendorProfile({ code }) {
  return await Axios.get(`/vendors/${code}`);
}
function* handleGetVendorProfile({ payload }) {
  try {
    const response = yield call(getVendorProfile, payload);
    if (response) {
      yield put({
        type: GET_VENDOR_PROFILE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_VENDOR_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getVendorApprovers(params) {
  return await Axios.get(`/approvals/vendor-approvers`, { params });
}
function* handleGetVendorApprovers({ payload }) {
  try {
    const response = yield call(getVendorApprovers, payload);
    if (response) {
      yield put({
        type: GET_VENDOR_APPROVERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_VENDOR_APPROVERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_VENDOR_PROFILE_REQUEST, handleGetVendorProfile),
  takeLatest(GET_VENDOR_APPROVERS_REQUEST, handleGetVendorApprovers),
]);
