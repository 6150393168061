import { Checkbox } from 'antd';
import classNames from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import ExchangeRate from 'components/UI/ExchangeRate';
import { toastError } from 'components/UI/toast';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { currencyConversionRate } from 'redux/actions/BudgetsAction';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, recurrenceOption } from 'utils/helper';
import UpdateAllocation from './UpdateAllocation';
import './styles.scss';
import CustomPercentageInput from 'components/UI/CustomPercentageInput';

const NewBudget = ({
  setStep,
  setIsOtherPopoverOpen = () => null,
  budgetInfo,
  setBudgetInfo,
  handleSubmit,
  isUpdate,
  setBeneficiariesAllocation,
  handleClose,
  checkboxVisible,
  setCheckBoxVisible,
  isUpdating,
  editExpiry,
  setSplittingRule,
  setExistingBeneficiaries,
  beneficiariesAllocation,
  setAmountSum,
  splittingRule,
}) => {
  const dispatch = useDispatch();

  const [isBeneficiary, setIsBeneficiary] = useState(false);

  const handleChangeBudgetInfo = (data) => {
    if (data?.details) return;
    const {
      target: { name, value, rawValue },
    } = data;
    setBudgetInfo({ ...budgetInfo, [name]: rawValue ? rawValue : value });
  };

  const handleDateChange = (date, name) => {
    handleChangeBudgetInfo({ target: { name: name, value: date } });
  };

  const { permissions, isAdmin } = allPermissions();
  const canViewEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view'],
  });

  const [startInterval, setStartInterval] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const {
    getStatistics: { data: dataStats = {} },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const {
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getSingleBudget: { data: getSingleBudgetData = {} },
    getCurrencyConversionRate: {
      data: currencyExchange = {},
      loading: loadingConversion,
    },
    createBudget: { error },
  } = useSelector(({ budgets }) => budgets);

  const { beneficiaries = [] } = data;

  useEffect(() => {
    if (!beneficiaries.length && canViewEmployee) dispatch(getBeneficiaries());
  }, []);

  const { rate: exchangeRate = 0 } = currencyExchange;

  const amountToPercentage = (amount, total) => {
    return (Number(amount / total) * 100).toFixed(2);
  };

  useEffect(() => {
    if (Object.keys(getSingleBudgetData).length && isUpdate) {
      const { name, currency, amount, bufferAmount, schedule } =
        getSingleBudgetData ?? {};

      const beneficiaries = getSingleBudgetData?.beneficiaries?.map((item) => ({
        value: item?.user?.beneficiary?.code,
        label: `${item?.user?.firstName} ${item?.user?.lastName}`,
        amount: isNaN(item?.amount) ? 0 : item?.amount / 100,
        isBudgetOwner: false,
      }));

      const owners = getSingleBudgetData?.owners?.map((item) => ({
        value: item?.user?.beneficiary?.code,
        label: `${item?.user?.firstName} ${item?.user?.lastName}`,
        amount: isNaN(item?.amount) ? 0 : item?.amount / 100,
        isBudgetOwner: true,
      }));

      setExistingBeneficiaries(beneficiaries.concat(owners));

      const buffer = Number(bufferAmount) > 0;
      const expiration = !!schedule?.expiry_date;
      const type = recurrenceOption.find(
        (item) => item.value === schedule?.occurrence,
      ) ?? {
        value: 'one-time',
        label: 'Never',
        details: 'One-time budget',
      };
      const inputData = {
        name,
        currency,
        amount: amount / 100,
        type,
        expiryDate: schedule?.expiry_date ?? undefined,
        bufferAmount: !isNaN(getSingleBudgetData?.bufferAmount)
          ? amountToPercentage(getSingleBudgetData?.bufferAmount / 100, amount / 100)
          : '',
        beneficiaries,
        owners,
      };

      setSplittingRule(getSingleBudgetData?.sharingType === 'custom');
      setCheckBoxVisible({ buffer, expiration });
      setBudgetInfo(inputData);
    }
  }, [getSingleBudgetData]);

  useEffect(() => {
    if (budgetInfo?.beneficiaries && budgetInfo?.beneficiaries.length)
      setBeneficiariesAllocation(
        Object.assign(
          {},
          Array(budgetInfo.beneficiaries.length)
            .fill(0)
            .map(() => {
              return (budgetInfo?.amount / budgetInfo?.beneficiaries.length).toFixed(2);
            }),
        ),
      );
  }, [budgetInfo]);

  const handleGetSign = (currency) => {
    !isUpdate && setBudgetInfo({ ...budgetInfo, currency });
  };

  useEffect(() => {
    localStorage.setItem('currency', JSON.stringify(budgetInfo.currency));
  }, [budgetInfo.currency]);

  useEffect(() => {
    const getLocalCurrency = JSON.parse(localStorage.getItem('currency'));
    if (getLocalCurrency) setBudgetInfo({ ...budgetInfo, getLocalCurrency });
  }, []);

  useEffect(() => {
    if (companyData.code) dispatch(getCompanyStats({ id: companyData.code }));
  }, [companyData]);
  const { available: { NGN: available } = {} } = dataStats;

  const interval = useRef(null);
  useEffect(() => {
    interval.current = setInterval(() => {
      if (budgetInfo.amount) {
        if (budgetInfo.currency === 'USD') {
          dispatch(
            currencyConversionRate({
              baseCurrency: budgetInfo.currency.toLowerCase(),
              targetCurrency: 'ngn',
              amount: budgetInfo.amount,
            }),
          );
        }
        // if (budgetInfo.currency === 'NGN') {
        //   dispatch(
        //     currencyConversionRate({
        //       baseCurrency: budgetInfo.currency.toLowerCase(),
        //       targetCurrency: 'usd',
        //       amount: budgetInfo.amount * 100,
        //     }),
        //   );
        // }
      }
    }, 120000);

    return () => {
      clearInterval(interval.current);
    };
  }, [startInterval, error]);

  useEffect(() => {
    if (budgetInfo.amount && !startInterval) {
      if (budgetInfo.currency === 'USD') {
        dispatch(
          currencyConversionRate({
            baseCurrency: budgetInfo.currency.toLowerCase(),
            targetCurrency: 'ngn',
            amount: budgetInfo.amount,
          }),
        );
      }
      setStartInterval(!startInterval);
    }
  }, [budgetInfo.currency, budgetInfo.amount, error]);

  useEffect(() => {
    if (Object.keys(currencyExchange).length && !isUpdate)
      if (budgetInfo.currency === 'NGN') {
        setBudgetInfo({ ...budgetInfo, exchangeRate: undefined });
      } else {
        setBudgetInfo({ ...budgetInfo, exchangeRate: currencyExchange.rate });
      }
  }, [budgetInfo.currency, currencyExchange.rate]);

  const onToggleBuffer = (event, type) => {
    setCheckBoxVisible({
      ...checkboxVisible,
      [type]: event.target.checked,
    });
  };

  const formatOptionLabel = (props) => {
    const { value, label, details, isDisabled } = props;
    return (
      <div className={classNames('format-recurrence-option')}>
        <h1 className={classNames({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={classNames({ ['text-muted']: isDisabled })}>{details}</span>
      </div>
    );
  };

  const closeModal = () => {
    setIsBeneficiary(false);
    handleClose();
  };

  const onHandleSubmit = () => {
    const totalAmount = budgetInfo?.beneficiaries
      ?.concat(budgetInfo?.owners)
      ?.reduce((next, current) => {
        return next + Number(current?.amount);
      }, 0);
    if (totalAmount > Number(budgetInfo?.amount))
      return toastError("Allocated values can't be greater than Budget goal");

    handleSubmit();
  };

  const onNext = () => {
    const { name, amount, type } = budgetInfo;
    if (!name || !amount || !type) {
      return toastError('Provide the neccessary data');
    }
    if (isUpdate) onHandleSubmit();
    else setStep(2);
  };

  useEffect(() => {
    if (Number(budgetInfo?.bufferAmount) > Number(budgetInfo?.amount)) {
      setTimeout(() => {
        toastError("Buffer Amount can't exceed Budget goal");
      }, 500);
    }
  }, [budgetInfo?.bufferAmount]);

  const bufferPercentage = Math.ceil(
    (Number(+budgetInfo?.bufferAmount) / 100) * Number(+budgetInfo.amount),
  );

  const maxSpend = Number(budgetInfo.amount ?? 0) + Number(bufferPercentage ?? 0);

  const reccuringBudgetUpdate = recurrenceOption.filter(
    (item) => item.value !== 'one-time' && isUpdate,
  );
  const title = isUpdate
    ? 'Update budget'
    : isBeneficiary
    ? 'Add members'
    : 'Create new budget';

  const nextDay = dayjs().add(1, 'day').toDate();

  return (
    <div className="information-wrapper">
      <h2 className="card-title w-100">{title}</h2>
      <div className="form-normal">
        <Row className="mb-3 mt-3">
          <CustomInput
            label={`Name ${!isUpdate ? '*' : ''}`}
            placeholder="Enter budget name"
            type="text"
            name="name"
            onChange={handleChangeBudgetInfo}
            value={budgetInfo.name}
            disabled={!isAdmin && getSingleBudgetData?.spent && isUpdate}
          />
        </Row>
        <Row className="mb-3">
          <div className="budget__goal--label">
            {`Budget goal ${!isUpdate ? '*' : ''}`} <br />
          </div>
          <CustomInput
            placeholder="0.00"
            type="number"
            isAmount
            otherCurrency={true}
            name="amount"
            onChange={handleChangeBudgetInfo}
            value={budgetInfo.amount}
            setIsOtherPopoverOpen={setIsOtherPopoverOpen}
            getTypeVal={handleGetSign}
            existAction={budgetInfo.currency || null}
            isUpdate={isUpdate}
          />
          {budgetInfo.currency === 'USD' && budgetInfo.amount && (
            <ExchangeRate
              exchangeRate={exchangeRate}
              amount={budgetInfo.amount}
              loading={loadingConversion}
            />
          )}
        </Row>

        <Row className="mb-2 buffer">
          <div className="buffer-wrapper gap-2">
            <Checkbox
              onChange={(event) => onToggleBuffer(event, 'buffer')}
              checked={checkboxVisible?.buffer}
              disabled={!budgetInfo.amount}
            ></Checkbox>
            <div>
              <h6>Set Buffer</h6>
              <span>
                This is the maximum you want your team to spend from, in this budget
              </span>
            </div>
          </div>
          {checkboxVisible?.buffer && (
            <div className="w-100">
              <Row className="align-items-center d-flex mt-3 w-100">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 7,
                  }}
                  className="col-md-6"
                >
                  <div className="text-xs buffer-limit-text">Buffer %</div>
                  <CustomPercentageInput
                    placeholder="%"
                    name="bufferAmount"
                    onChange={(value) =>
                      handleChangeBudgetInfo({ target: { name: 'bufferAmount', value } })
                    }
                    value={budgetInfo.bufferAmount}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    isUpdate={isUpdate}
                    md={8}
                  />
                </div>

                <div className="col-md-6 w-auto ms-auto">
                  <CurrencyFormat
                    value={bufferPercentage}
                    thousandSeparator={true}
                    displayType="text"
                    prefix={getCurrency(budgetInfo.currency)}
                  />
                </div>
              </Row>

              <span className="buffer-limit-text mt-3">
                Maximum spend limit:{' '}
                <CurrencyFormat
                  value={maxSpend}
                  thousandSeparator={true}
                  displayType="text"
                  prefix={getCurrency(budgetInfo.currency)}
                />
              </span>
            </div>
          )}
        </Row>

        <Row className="mb-3 budget-recurrence">
          <CustomSelect
            label={`Budget recurrence ${!isUpdate ? '*' : ''}`}
            placeholder="Budget type"
            name="type"
            options={isUpdate ? reccuringBudgetUpdate : recurrenceOption}
            formatOptionLabel={formatOptionLabel}
            onChange={(type) => setBudgetInfo({ ...budgetInfo, type })}
            value={budgetInfo?.type}
          />
          {budgetInfo.type.value === 'recurring' && (
            <CustomSelect
              wrapperClass="mt-2"
              placeholder="Budget type"
              name="time"
              options={[
                { value: 'Yearly', label: 'Yearly' },
                { value: 'Monthly', label: 'Monthly' },
                { value: 'Weekly', label: 'Weekly' },
              ]}
              onChange={(time) => setBudgetInfo({ ...budgetInfo, time: time })}
              value={budgetInfo.time}
              disabled={isUpdate}
            />
          )}
        </Row>

        <Row className="mb-4 expiration">
          <div className="buffer-wrapper d-flex gap-2">
            <Checkbox
              onChange={(event) => onToggleBuffer(event, 'expiration')}
              checked={checkboxVisible?.expiration}
            ></Checkbox>
            <div>
              <h6>Expiration</h6>
              <span>
                Delete all funds allocated to beneficiaries, cards, and deactivate the
                budget.
              </span>
            </div>
          </div>
          {checkboxVisible?.expiration && (
            <div className="mt-3 px-0">
              <CustomDatePicker
                label="Expiration Date"
                onChange={(value) => handleDateChange(value, 'expiryDate')}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeCaption="Time"
                name="expiryDate"
                peekNextMonth
                minDate={nextDay}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText={budgetInfo?.expiryDate ?? '2023-07-29'}
                selected={
                  budgetInfo?.expiryDate && dayjs(budgetInfo?.expiryDate).toDate()
                }
                onFocusDatePicker={editExpiry}
              />
            </div>
          )}
        </Row>

        {isUpdate && (
          <div className="mb-5">
            <UpdateAllocation
              beneficiariesAllocation={beneficiariesAllocation}
              budgetInfo={budgetInfo}
              setAmountSum={setAmountSum}
              setBudgetInfo={setBudgetInfo}
              setIsOtherPopoverOpen={setIsOtherPopoverOpen}
              setSplittingRule={setSplittingRule}
              splittingRule={splittingRule}
            />
          </div>
        )}
      </div>

      <div className="modal-footer">
        <CustomButton
          onClick={closeModal}
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={onNext}
          loading={isUpdating}
          fullWidth={true}
          disabled={disableButton || isUpdating}
          className="custom-button primary-button"
        >
          {isUpdate ? 'Update budget' : 'Continue'}
        </CustomButton>
      </div>
    </div>
  );
};
export default NewBudget;
