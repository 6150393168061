import './styles.scss';
import { Skeleton } from 'antd';
import classNames from 'classnames';

const AnalyticsCard = ({
  value,
  caption,
  loading,
  isActive,
  hasExtra = true,
  customClass = '',
  extraText = 'Filter by',
}) => {
  const loadingStyle = {
    height: 20,
    width: '120px',
    minWidth: '120px',
    borderRadius: '4px',
  };

  return (
    <div
      className={classNames('bjt-analytics', {
        loading,
        plain: !hasExtra,
        'is-active': isActive,
        [customClass]: customClass,
      })}
    >
      <div className="d-flex">
        <p className="caption pseudo">{caption}</p>
        {hasExtra && <span className="extra-text"> {extraText} </span>}
        <p className="caption">{caption}</p>
      </div>

      {loading ? (
        <Skeleton.Input active className="mt-1" style={loadingStyle} />
      ) : (
        <p className="value">{value}</p>
      )}
    </div>
  );
};

export default AnalyticsCard;
