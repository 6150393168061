import CustomButton from 'components/UI/CustomButton';
import { Table } from 'react-bootstrap';

const SettlementAccountTable = ({ data, onAddAccount, onDelete }) => {
  return (
    <section className="settlement-table-wrapper">
      <header className="d-flex justify-content-between align-items-center">
        <h1>{`${data?.length} Settlement Account`}</h1>

        <div className="button-wrapper">
          <CustomButton onClick={onAddAccount} className="custom-settlement-button">
            Add Account
          </CustomButton>
        </div>
      </header>
      <section>
        <Table>
          <thead>
            <tr>
              <th style={{ width: 400 }}>Bank Name</th>
              <th>Account Number</th>
              <th>Currency</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((datum) => {
              return (
                <tr key={datum.code}>
                  <td>
                    <div className="user-bank-info">
                      <span>{datum?.bankAccount?.bankName}</span>
                      <span>{datum?.bankAccount?.accountName}</span>
                    </div>
                  </td>
                  <td>
                    <span>{datum?.bankAccount?.number}</span>
                  </td>
                  <td>
                    <span>{datum?.bankAccount?.currency}</span>
                  </td>
                  {/* <td>
                    <div>
                      <span className="remove" onClick={() => onDelete(datum?.code)}>
                        <DeleteIcon /> Remove Bank
                      </span>
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
    </section>
  );
};

export default SettlementAccountTable;

const DeleteIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8125 3.0625H2.1875"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.6875 5.6875V9.1875"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3125 5.6875V9.1875"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9375 3.0625V11.375C10.9375 11.491 10.8914 11.6023 10.8094 11.6844C10.7273 11.7664 10.616 11.8125 10.5 11.8125H3.5C3.38397 11.8125 3.27269 11.7664 3.19064 11.6844C3.10859 11.6023 3.0625 11.491 3.0625 11.375V3.0625"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 3.0625V2.1875C9.1875 1.95544 9.09531 1.73288 8.93122 1.56878C8.76712 1.40469 8.54456 1.3125 8.3125 1.3125H5.6875C5.45544 1.3125 5.23288 1.40469 5.06878 1.56878C4.90469 1.73288 4.8125 1.95544 4.8125 2.1875V3.0625"
        stroke="#F04438"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
