import { useEffect, memo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Table from 'components/Table';
import TopBar from 'components/TopBar';
import BillsEmptyState from '../EmptyState';
import SearchLoader from '../SearchLoader';
import { Col, Row, Container } from 'react-bootstrap';

import { fetchArchivedBills } from 'redux/actions/BillAction';
import { useDebounce } from 'hooks/useDebounce';
import { buildBillsTableData } from 'utils/helper';
import { billColumns } from '../../../../utils/mockData';
import EmptyData from '../EmptyData';

const ArchivedBills = ({ data, loading }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const { meta: { page, total, hasMore, perPage, nextPage } = {}, bills = [] } =
    data || {};

  const archivedColumns = billColumns.slice(0, -3);
  const rows = buildBillsTableData(bills);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const debouncedValue = useDebounce(search, 700);

  const onHandleNextPage = (page) => {
    dispatch(fetchArchivedBills({ perPage, page }));
  };

  const onHandlePreviousPage = (page) => {
    dispatch(fetchArchivedBills({ perPage, page }));
  };

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchArchivedBills({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchArchivedBills({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  return (
    <>
      <Container className="px-0">
        <section>
          {loading ? (
            <SearchLoader />
          ) : (
            <TopBar
              showBarSearch
              searchVal={search}
              setSearchVal={setSearch}
              showFilter={false}
              handleFilterSelect={(updateVal) => {
                setFilterData(updateVal);
              }}
              inputPlaceholder="search"
              withDate
            />
          )}

          {!loading && !bills?.length ? (
            <>{filtered ? <EmptyData /> : <BillsEmptyState />}</>
          ) : (
            <Row className="pt-4 pb-3">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={archivedColumns}
                  pagination
                  data={rows}
                  hasCheckBox={false}
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => onHandleNextPage(nextPage)}
                  previousPage={() => onHandlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage || 1)}
                  loading={loading}
                />
              </Col>
            </Row>
          )}
        </section>
      </Container>
    </>
  );
};

export default memo(ArchivedBills);
