import NoData from 'components/NoData';
import { AntInput } from 'components/UIHooks/AntDesign';
import EmptyState from 'pages/Cash/Overview/components/NoData';
import { useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CashEmptyStateData from '../CashEmptyStateData';
import { AccountCard } from '../components/AccountCard';
import NewAccountModal from '../NewAccountModal';
import { SearchIcon } from 'assets/icons';

const AllAccounts = ({
  openBalance,
  openStatementModal,
  openDetailsModal,
  canViewDash,
}) => {
  const [searchVal, setSearchVal] = useState('');
  const {
    getBalances: { data: balances, loading },
  } = useSelector(({ budgets }) => budgets);

  // useEffect(() => {
  //   if (debouncedValue) isFiltered.current = true;
  // }, [debouncedValue]);

  const [activePopover, setActivePopover] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showAccounts =
    !!balances ||
    !!balances?.balances.some(
      ({ accountType }) => !['linked', 'direct-debit'].includes(accountType),
    );

  const filteredAccount = useMemo(
    () =>
      balances?.balances.filter(
        (item) =>
          item?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
          item?.accountName?.toLowerCase().includes(searchVal.toLowerCase()) ||
          item?.bankName?.toLowerCase().includes(searchVal.toLowerCase()),
      ),
    [searchVal, balances?.balances],
  );

  return (
    <div>
      {!(!canViewDash && !balances?.balances?.length) && (
        <div className="account-header__holder">
          <div className="account-header">All accounts</div>
          <div className="search-input">
            <AntInput
              className="input"
              placeholder="Search"
              prefix={<SearchIcon stroke="#A9A29D" className="searchIcon" />}
              value={searchVal}
              onChange={({ target: { value } }) => setSearchVal(value)}
            />
          </div>
        </div>
      )}

      {showAccounts && (
        <div className="grid grid-cols-2">
          {filteredAccount?.map(
            (
              {
                name,
                balance,
                number,
                currency,
                code,
                bankName,
                accountName,
                routingNumber,
                logo,
                subaccount,
                mandateCode,
                mandateStatus,
              },
              index,
            ) => {
              return (
                <AccountCard
                  key={`account-${index}`}
                  code={code}
                  openBalance={openBalance}
                  onClick={openBalance}
                  openDetailsModal={(event) =>
                    openDetailsModal(event, {
                      name,
                      balance,
                      number,
                      currency,
                      code,
                      bankName,
                      accountName,
                      routingNumber,
                    })
                  }
                  openStatementModal={(event) =>
                    openStatementModal(event, { code, name, accountName })
                  }
                  name={name}
                  balance={balance}
                  number={number}
                  currency={currency}
                  bankName={bankName}
                  logo={logo}
                  accountName={accountName}
                  isSubaccount={subaccount}
                  isDirectDebit={!!mandateCode}
                  isDirectDebitActive={mandateStatus === 'granted'}
                  showOptions={activePopover === code}
                  onToggleOptions={(isOpen) => setActivePopover(isOpen ? code : null)}
                />
              );
            },
          )}
        </div>
      )}

      {!canViewDash && !balances?.balances?.length ? (
        <EmptyState
          headerText="Your accounts will show here."
          buttonLabel="Make an internal transfer"
          bodyText="Find here all your accounts, manage your financial transactions, make secure and hassle-free payments to your vendors or colleagues"
          withButton={false}
        />
      ) : !showAccounts || (!filteredAccount?.length && !searchVal) ? (
        <CashEmptyStateData toggleHandler={() => setIsModalOpen(true)} />
      ) : !filteredAccount?.length && searchVal ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="You have no account for this filter"
            bodyText="Alter filter to see accounts"
            withButton={false}
          />
        </div>
      ) : null}

      <NewAccountModal
        route="accounts"
        isModalOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default AllAccounts;
