import React, { useState } from 'react';
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import Modal from 'components/UI/Modal';
import SelectFunds from './SelectFunds';
import TransferFunds from './TransferFunds';
import FundCard from './FundCard';
import FundDollar from './FundDollar';
import DollarTransfer from './DollarTransfer';
import FundFromExistingAccount from './FundFromExistingAccount';

const FundsModal = ({
  closeHandler,
  isModalOpen,
  setInsideModalClick,
  fundType,
  fundStep,
  setFundStep,
  setMonoAuthCode,
  selectedAccount,
  accounts = [],
  initiatePayment,
  verifyPayment,
  paymentVerified,
  paymentFailed,
  getReauthorizationCode,
  reauthorizationCode,
  destination,
  addFunds,
  isAddingFunds,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);

  const handleBack = () => {
    if (fundStep === 1 || isSuccess) return closeHandler();
    setFundStep(1);
  };

  const linkedAccounts =
    Array.isArray(accounts) && accounts?.length
      ? accounts?.filter((account) => account?.type === 'real')
      : [];
  const depositAccounts =
    Array.isArray(accounts) && accounts?.length
      ? accounts?.filter((account) => account?.type !== 'real')
      : [];

  const renderModal = () => {
    switch (fundStep) {
      case 1:
        return (
          <SelectFunds
            setFundStep={setFundStep}
            closeHandler={closeHandler}
            fundType={fundType}
            isLinked={!!linkedAccounts.length}
            setMonoAuthCode={setMonoAuthCode}
            accounts={accounts}
            initiatePayment={initiatePayment}
            verifyPayment={verifyPayment}
            paymentVerified={paymentVerified}
            paymentFailed={paymentFailed}
            getReauthorizationCode={getReauthorizationCode}
            reauthorizationCode={reauthorizationCode}
            destination={destination}
            addFunds={addFunds}
            isLoading={isAddingFunds}
          />
        );
      case 0:
        return (
          <TransferFunds
            setFundStep={setFundStep}
            closeHandler={closeHandler}
            handleBack={handleBack}
            accounts={depositAccounts}
            account={selectedAccount}
          />
        );
      case 3:
        return (
          <FundCard
            setInsideModalClick={setInsideModalClick}
            setFundStep={setFundStep}
            closeHandler={closeHandler}
          />
        );
      case 4:
        return (
          <FundDollar
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            setInsideModalClick={setInsideModalClick}
            setFundStep={setFundStep}
            closeHandler={closeHandler}
          />
        );
      case 5:
        return (
          <DollarTransfer
            fundType={fundType}
            setFundStep={setFundStep}
            closeHandler={closeHandler}
            handleBack={handleBack}
          />
        );
      case 6:
        return (
          <FundFromExistingAccount
            setFundStep={setFundStep}
            closeHandler={closeHandler}
            handleBack={handleBack}
            fundType={fundType}
            isLinked={!!linkedAccounts.length}
            setMonoAuthCode={setMonoAuthCode}
            accounts={accounts}
            initiatePayment={initiatePayment}
            verifyPayment={verifyPayment}
            paymentVerified={paymentVerified}
            paymentFailed={paymentFailed}
            getReauthorizationCode={getReauthorizationCode}
            reauthorizationCode={reauthorizationCode}
            destination={destination}
            addFunds={addFunds}
            isLoading={isAddingFunds}
          />
        );
    }
  };

  if (!isModalOpen) return <div />;

  return (
    <Modal show={isModalOpen} onClose={closeHandler}>
      <div className="content px-md-0 py-md-0 px-3 py-4">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleBack}>
            {fundStep === 1 || isSuccess ? (
              <>
                <CloseOutlined />
                <span className="ps-1">Close</span>{' '}
              </>
            ) : (
              <>
                <ArrowLeftOutlined />
                <span className="ps-1">Back</span>{' '}
              </>
            )}
          </div>
        </div>
        <div className="card-modal-body">{renderModal()}</div>
      </div>
    </Modal>
  );
};
export default FundsModal;
