import Select, { components } from 'react-select';
import './customRoleSelect.styles.scss';
import { useState } from 'react';
import { theStyle } from '../CustomSelectRadio';
import makeAnimated from 'react-select/animated';
import { Col, Form } from 'react-bootstrap';
import classNames from 'classnames';

const animatedComponents = makeAnimated();

const Menu = (props) => {
  const {
    selectProps: { addAction, extendComponent },
    children,
  } = props;

  return (
    <components.Menu {...props}>
      <div>
        <div>{children}</div>
        {extendComponent && (
          <button className="add-beneficiary" onClick={addAction}>
            {extendComponent}
          </button>
        )}
      </div>
    </components.Menu>
  );
};
const CustomRoleSelect = ({
  label,
  options,
  onChange,
  value,
  placeholder,
  defaultValue,
  showValue = true,
  textStyles,
  md = 12,
  customStyles,
  extendComponent,
  addAction,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useState(value ?? defaultValue);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onChange(selectedOption);
  };

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{ zIndex: 100000000 }}
        className={
          props.label === selectedValue?.label
            ? 'custom-option selected-option'
            : 'custom-option'
        }
      >
        <h1 className={`title ${rest.labelClass}`}>{props?.label}</h1>
        {showValue && (
          <div className={`description ${rest.truncateClass}`}>{props?.value}</div>
        )}
      </div>
    );
  };

  return (
    <Form.Group as={Col} md={md} className={classNames('groupWrapper form-group custom')}>
      {label && <Form.Label className={`form-label`}>{label}</Form.Label>}

      <Select
        components={
          extendComponent
            ? { Menu: (props) => Menu(props), Option: CustomOption, animatedComponents }
            : { animatedComponents, Option: CustomOption }
        }
        addAction={addAction}
        extendComponent={extendComponent}
        styles={theStyle(null, customStyles)}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        classNamePrefix="dropdown-select"
        {...rest}
      />
    </Form.Group>
  );
};

export default CustomRoleSelect;
