import TryAgain from 'components/BusinessCard/Card/Bank/TryAgain';
import React, { useState } from 'react';
import AccountInfo from './AccountInfo';
import { getCurrency } from 'utils/helper';
import CurrencyFormat from 'react-currency-format';

const FundTransfer = ({ closeHandler, setFundStep, handleBack, amount }) => {
  const [tryAgain, setTryAgain] = useState(false);

  const handleBtnClick = () => {
    closeHandler();
  };

  // const clickHandler = () => {
  //   setFundStep(3);
  // };
  return (
    <>
      {tryAgain ? (
        <TryAgain goBackToSelectType={handleBack} />
      ) : (
        <>
          <AccountInfo
            title={
              <div>
                Bank transfer
                <p className="fund__budget-subtext mt-2">
                  Transfer{' '}
                  <span className="fw-bold">
                    exactly{' '}
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={amount}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </span>{' '}
                  from your bank to Bujeti&apos;s account. Your budget balance will be
                  credited afterwards.
                </p>
              </div>
            }
            hideLink
            handleBtnClick={handleBtnClick}
            setTryAgain={setTryAgain}
            accountType="NGN"
            handleBack={handleBack}
          />
          <div className="pt-4">{/* <MoreActions handleClick={clickHandler} /> */}</div>
        </>
      )}
    </>
  );
};

export default FundTransfer;
