import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import Success from 'components/UI/ModalSaving/Success';
import BadgeType from 'components/UI/Table/BadgeType';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSingleBudgetPolicies, linkBudgetPolicy } from 'redux/actions/PoliciesAction';
import { getCurrency, policiesType, sortDays } from 'utils/helper';
import './styles.scss';
import { toastError } from 'components/UI/toast';

const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
const weekends = ['Sat', 'Sun'];

const policyValue = {
  type: '',
  policy: '',
};
const ExistingPolicyModal = ({ isOpen, handleClose }) => {
  if (!isOpen) return <div />;
  const { budgetId } = useParams();

  const dispatch = useDispatch();

  const {
    getPolicyTypes: { data: policyTypes = [] },
    linkBudgetPolicy: { loading, success },
    getPolicies: {
      data: { policies = [] },
    },
  } = useSelector(({ policies }) => policies);

  const [policy, setPolicy] = useState(policyValue);

  const handleChange = ({ name, value }) => {
    setPolicy({ ...policy, [name]: value });
  };

  useEffect(() => {
    if (success) {
      dispatch(getSingleBudgetPolicies({ budget: budgetId }));
      handleClose();
    }
  }, [success]);

  useEffect(() => {
    setPolicy({ ...policy, policy: '' });
  }, [policy.type]);

  const filterPolicyType = policyTypes?.filter(
    (item) => item.code != policiesType.INDIVIDUAL_ALLOCATION,
  );

  // filter selected type
  const selectedType = policies?.filter(
    (item) =>
      item.types.some((type) => type?.code === policy?.type?.value) &&
      item?.status === 'active',
  );

  // filter seleted policy
  const selectedPolicy = policies?.filter((item) => item.code === policy.policy.value);

  const { name, description, maxAmount, period, currency, frequency } =
    selectedPolicy[0] || {};

  // compare array
  const checkWeekends = JSON.stringify(weekends) === JSON.stringify(period);
  const checkWeekdays = JSON.stringify(weekdays) === JSON.stringify(period);

  // find days type if weekends or weekdays
  const days = checkWeekends
    ? { label: 'Weekends', sub: '(Sat-Sun)' }
    : checkWeekdays
    ? { label: 'Weekdays ', sub: '(Mon-Fri)' }
    : '';

  const handleSubmit = () => {
    if (!policy.type) return toastError('Please select policy type');
    if (!policy.policy) return toastError('Please select a policy');

    const payload = {
      code: policy.policy.value,
      budgetCode: budgetId,
    };

    dispatch(linkBudgetPolicy(payload));
  };

  const getPeriod = {
    weekly: 'per week',
    monthly: 'per month',
  };

  const policyForm = () => (
    <div className="beneficiaries">
      <h2 className="policy-title">Use existing policy</h2>

      <Row className="mb-3">
        <CustomSelect
          label="Type"
          placeholder="Select type"
          name="type"
          options={filterPolicyType.map(({ name: label, code: value }) => ({
            label,
            value,
          }))}
          onChange={(val) => handleChange({ name: 'type', value: val })}
          value={policy.type}
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="Policy"
          placeholder="Select existing policy"
          name="policy"
          options={selectedType.map(({ name: label, code: value }) => ({ label, value }))}
          onChange={(val) => handleChange({ name: 'policy', value: val })}
          value={policy.policy}
        />
      </Row>

      {policy.policy && (
        <div className="mb-3 policy-detail-wrapper space-y-4">
          <div className="policy-detail-text">
            <h3>{name}</h3>
            <p>{description}</p>
          </div>

          {maxAmount && (
            <div className="policy-detail-text d-flex align-items-start">
              <h3 className="mr-3">
                {
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={maxAmount / 100}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
              </h3>
              <p>{getPeriod[frequency?.toLowerCase()]}</p>
            </div>
          )}
          {period?.length && (
            <div className="policy-detail-text d-flex align-items-start">
              {checkWeekdays && checkWeekdays && (
                <>
                  <h3 className="mr-3">{days.label}</h3>
                  <p>{days.sub}</p>
                </>
              )}

              {!checkWeekdays &&
                !checkWeekdays &&
                sortDays(period).map((item, i) => (
                  <BadgeType value={item} key={i} isBadge={false} margin />
                ))}
            </div>
          )}
        </div>
      )}

      <div className="modal-footer">
        <CustomButton
          onClick={handleClose}
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          fullWidth={true}
          className="custom-button primary-button"
        >
          Save
        </CustomButton>
      </div>
    </div>
  );
  return (
    <div>
      <Modal show={isOpen} onClose={handleClose}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{loading ? <Saving /> : policyForm()}</div>
        </div>
      </Modal>
    </div>
  );
};
export default ExistingPolicyModal;
