import CreatableSelect from 'react-select/creatable';
import { theStyle } from '../CustomSelectRadio';

const CustomCreatableSelect = ({
  label,
  name,
  placeholder,
  onChange,
  onInputChange,
  options,
  value,
  maxLength,
  rowSize = 5,
  defaultValue,
  marginTop,
  noOptionsMessage = 'No results found',
  ...rest
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      border: '1px solid #e5e5e5',
      outline: 0,
      padding: 7,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #e5e5e5',
      },
      marginTop: marginTop,
    }),
  };

  return (
    <div className="form-group">
      <label className="textareaLabel">{label}</label>
      <CreatableSelect
        isClearable
        placeholder={placeholder}
        // className="creatable-select"
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        // styles={customStyles}
        noOptionsMessage={({}) => noOptionsMessage}
        defaultValue={defaultValue}
        styles={theStyle()}
        {...rest}
      />
    </div>
  );
};

export default CustomCreatableSelect;
