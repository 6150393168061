import React from 'react';
import cs from 'classnames';
import './styles.scss';

const PolicyInfo = ({ fullCursor, titleClass, title, valueClass, value }) => {
  return (
    <div className={cs('mt-4', { ['cursor']: fullCursor })}>
      <div className="d-flex justify-content-between align-items-center">
        <div className={cs('policy-wrapper')}>
          <p className={titleClass}>{title}</p>
          <h3 className={valueClass}>{value}</h3>
        </div>
      </div>
    </div>
  );
};

export default PolicyInfo;
