import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import PanInput from 'components/UI/CustomInput/PanInput';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activatePhysicalCard,
  getMyCardsAction,
  getRequestedCards,
} from 'redux/actions/CardAction';
import { RESET_BLOCK_CARD } from 'redux/reducers/CardReducer';
import ViewDefualtPin from './PhysicalCardSection/ViewDefualtPin';
import './styles.scss';

const PhysicalCardDetails = ({
  handleClose,
  step,
  selectCards,
  setSelectCards,
  setStep,
}) => {
  if (!selectCards) return <div />;

  const [cardDetails, setCardDetails] = useState({});
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);

  const closeHandler = () => {
    // if (step > 1) {
    //   if (step == 2)
    dispatch({ type: RESET_BLOCK_CARD, blockType: 'activatePhysicalCard' });
    setSelectCards(null);
    // }
  };

  const payerCode = query.get('payer');

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const onDone = () => {
    dispatch({ type: RESET_BLOCK_CARD, blockType: 'activatePhysicalCard' });
    handleClose();
    dispatch(getMyCardsAction({ owner: userData?.user?.code, payer: payerCode }));
  };

  const handleChange = ({ target: { name, value, rawValue } }) => {
    return setCardDetails({ ...cardDetails, [name]: rawValue ? rawValue : value });
  };

  const {
    activatePhysicalCard: {
      loading: loadingActivate,
      success: successActivate,
      data: dataActivate,
    },
  } = useSelector(({ card }) => card);

  useEffect(() => {
    if (successActivate) {
      setStep(2);
      dispatch(getRequestedCards());
    }
  }, [successActivate]);

  const showBvn = selectCards?.cardOwner?.hasCardHolder;

  const handleActivateCard = () => {
    if (!cardDetails?.cardPan) return toastError('Please enter card pan');
    if (!cardDetails?.bvn && !showBvn) return toastError('Please enter card holder bvn');
    dispatch(
      activatePhysicalCard({
        cardPan: cardDetails?.cardPan,
        cardRequest: selectCards?.code,
        bvn: cardDetails?.bvn,
      }),
    );
  };

  const claimCard = (
    <div className="content">
      <div className="card-modal-body">
        <section className="card__typeSelector">
          <div className="information-wrapper">
            <h2 className="card-title w-100 border-hidden p-0">Claim physical card</h2>
            <p className="text-sm">
              Claim your card by manually entering the information from the back of your
              physical card in the fields provided below.
            </p>
            {/* </div> */}
            <div className="my-4">
              <PanInput
                placeholder="Card number"
                label={'Card number'}
                onChange={handleChange}
                name="cardPan"
              />
              {!showBvn && (
                <div className="mt-2">
                  <CustomInput
                    label="BVN"
                    placeholder="Enter number"
                    type="text"
                    name="bvn"
                    onChange={handleChange}
                    value={cardDetails?.bvn}
                    maxLength="11"
                  />
                </div>
              )}
            </div>

            <div className="modal-footer mt-3">
              <CustomButton
                onClick={closeHandler}
                disabled={loadingActivate}
                fullWidth={true}
                className="custom-button ghost-button"
              >
                Cancel
              </CustomButton>
              <CustomButton
                fullWidth={true}
                className="custom-button primary-button"
                loading={loadingActivate}
                disabled={loadingActivate}
                onClick={handleActivateCard}
              >
                Confirm
              </CustomButton>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

  const renderCard = () => {
    switch (step) {
      case 1:
        return claimCard;
      case 2:
        return <ViewDefualtPin dataActivate={dataActivate} onDone={onDone} />;

      default:
    }
  };

  return (
    <>
      <Modal show={selectCards} onClose={closeHandler}>
        <div className="card-modal-header px-md-0 py-md-0 px-3 py-4">
          <div className="d-flex align-items-center cursor" onClick={closeHandler}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
        </div>
        <div className="card-modal-body">{renderCard()}</div>
      </Modal>
    </>
  );
};
export default PhysicalCardDetails;
