import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import { useEffect, useState } from 'react';

import { Col, Modal, Row } from 'react-bootstrap';

import { CancelICon } from 'assets/icons';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import useTextCounter from 'hooks/useTextCounter';
import { useDispatch, useSelector } from 'react-redux';
import { updateCategories } from 'redux/actions/CategoryAction';
import ModalContainer from 'components/ModalContainer';
import { Checkbox } from 'antd';
import CustomPercentageInput from 'components/UI/CustomPercentageInput';
import { getCurrency } from 'utils/helper';
import CurrencyFormat from 'react-currency-format';
import { formatNumThousandSeparator } from 'utils/utility';
import ConfirmDialog from 'components/ConfirmDialog';

const EditSubcategory = ({ selectedData, setShowEditModal }) => {
  const initial = {
    description: undefined,
    name: undefined,
    parent: {
      value: undefined,
      label: undefined,
      code: undefined,
    },
    limit: undefined,
    previousLimit: undefined,
    bufferAmount: undefined,
    bufferPercentage: undefined,
  };
  const dispatch = useDispatch();

  const {
    fetchCategories: { data: { categories = [] } = {}, loading: catLoading },
    updateCategories: { loading: updateLoading },
  } = useSelector(({ categories }) => categories);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryData, setSubcategoryData] = useState({ ...initial });
  const [hasBufferAmount, setHasBufferAmount] = useState(false);
  const [bufferAmount, setBufferAmount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (subCategoryData.limit && subCategoryData.bufferPercentage) {
      if (subCategoryData.limit === '0' || subCategoryData.bufferPercentage === '0')
        return setBufferAmount(0);
      setBufferAmount(
        (
          (Number(subCategoryData.limit) * Number(subCategoryData.bufferPercentage)) /
          100
        ).toFixed(2),
      );
    } else {
      setBufferAmount(0);
    }
  }, [subCategoryData.limit, subCategoryData.bufferPercentage]);

  useEffect(() => {
    if (selectedData) {
      setHasBufferAmount(!!selectedData?.bufferAmount);

      const buffer = selectedData?.bufferAmount ? selectedData?.bufferAmount / 100 : 0;

      setBufferAmount(buffer);

      setSubcategoryData({
        code: selectedData?.code,
        description: selectedData?.description ?? undefined,
        name: selectedData.name,
        parent: {
          value: selectedData?.parent?.name,
          label: selectedData?.parent?.name,
          code: selectedData?.parent?.code,
          limitLeft: (selectedData?.parent?.limitLeft ?? 0) / 100,
          limit: (selectedData?.parent?.limit ?? 0) / 100,
        },
        limit: selectedData?.limit ? selectedData?.limit / 100 : undefined,
        previousLimit: selectedData?.limit ? selectedData?.limit / 100 : undefined,
        bufferAmount: buffer,
        bufferPercentage:
          selectedData?.limit && selectedData?.bufferAmount
            ? (selectedData?.bufferAmount * 100) / selectedData?.limit
            : undefined,
      });
    }
  }, [selectedData?.code]);

  const onHandleCancel = () => {
    setShowEditModal(false);
  };

  const handleDescriptionChange = (value) => {
    setSubcategoryData({
      ...subCategoryData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    subCategoryData?.description,
    250,
    handleDescriptionChange,
  );

  const handleSubmit = () => {
    const { name, description, code, limit, previousLimit, parent } = subCategoryData;

    if (!name) return toastError('Please input name');
    if (
      !!parent?.limit &&
      limit > previousLimit &&
      parent?.limitLeft < limit - previousLimit
    ) {
      setShowConfirmModal(true);
      return;
    }

    handleConfirm(name, description, code, limit);
  };

  const handleConfirm = (name, description, code, limit) => {
    const payload = {
      code,
      name,
      description: description ?? undefined,
      limit: limit ? limit * 100 : undefined,
      bufferAmount: bufferAmount * 100,
    };

    dispatch(updateCategories(payload));
  };

  return (
    <>
      <Modal show={open} centered dialogClassName="custom-dialog">
        <ModalContainer
          lg
          loading={updateLoading}
          onCancel={onHandleCancel}
          actionBtnText="Save changes"
          cancelBtn="Discard"
          onConfirm={handleSubmit}
          title="Edit"
          subTitle="Make updates to your sub category details."
        >
          <div className="pb-4 px-0">
            <Row>
              <CustomSelect
                label="Select parent category"
                name="recipient"
                options={categoryList}
                placeholder={'Select'}
                onChange={(val) =>
                  setSubcategoryData({ ...subCategoryData, parent: val })
                }
                className="mb-3"
                value={subCategoryData?.parent}
                isLoading={catLoading}
                isDisabled={true}
                // md={8}
              />
              <CustomInput
                label="Sub category name"
                placeholder="Enter name"
                text="text"
                name="approvalName"
                className="mb-3"
                id="name"
                onChange={(event) =>
                  setSubcategoryData({ ...subCategoryData, name: event.target.value })
                }
                value={subCategoryData?.name}
                // md={8}
              />
              <Col>
                <CustomTextarea
                  label="Description"
                  name="description"
                  placeholder="Enter policy description (e.g. All spend > ₦25,000 requires receipt)"
                  value={text || subCategoryData?.description}
                  charCount={charCount}
                  onChange={handleCharChange}
                  rowSize={4}
                />
              </Col>
            </Row>

            <Row className="mb-2 mt-3">
              <CustomInput
                placeholder="0.00"
                type="number"
                label="Category limit"
                isAmount
                otherCurrency={true}
                className="mb-2"
                name="limit"
                onChange={(event) =>
                  setSubcategoryData({ ...subCategoryData, limit: event.target.rawValue })
                }
                value={subCategoryData.limit}
              />
              {!!subCategoryData?.parent?.limitLeft && (
                <span style={{ color: '#d28b28' }} className="text-xs">
                  {getCurrency('NGN')}
                  {formatNumThousandSeparator(subCategoryData?.parent?.limitLeft)} is left
                  of the parent category limit
                </span>
              )}
            </Row>

            <Col md={10} className="buffer px-0">
              <div className="buffer-wrapper gap-2 mt-2">
                <Checkbox
                  onChange={({ target: { checked } }) => {
                    if (!checked) {
                      setBufferAmount(0);
                      setSubcategoryData({
                        ...subCategoryData,
                        bufferPercentage: undefined,
                      });
                    }
                    setHasBufferAmount(!hasBufferAmount);
                  }}
                  checked={hasBufferAmount}
                ></Checkbox>
                <div>
                  <h6>Set Buffer</h6>
                  <span>
                    This is the maximum you want your team to spend from, in this budget
                  </span>
                </div>
              </div>
              {hasBufferAmount && (
                <div className="w-100">
                  <Row className="align-items-center d-flex mt-3 w-100">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 7,
                      }}
                      className="col-md-8"
                    >
                      <div className="text-xs buffer-limit-text">Buffer %</div>
                      <CustomPercentageInput
                        placeholder="%"
                        md={null}
                        name="bufferAmount"
                        onChange={(value) =>
                          setSubcategoryData({
                            ...subCategoryData,
                            bufferPercentage: value,
                          })
                        }
                        value={subCategoryData.bufferPercentage}
                      />
                    </div>

                    <div className="col-md-4 w-auto">
                      <CurrencyFormat
                        value={bufferAmount}
                        thousandSeparator={true}
                        displayType="text"
                        prefix={getCurrency('NGN')}
                      />
                    </div>
                  </Row>
                </div>
              )}
            </Col>
          </div>
        </ModalContainer>
      </Modal>

      <Modal show={showConfirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`Update subcategory`}
          subTitle={`Are you sure you want to update this subcategory? This action will update the parent category limit to ${getCurrency(
            'NGN',
          )}${formatNumThousandSeparator(
            subCategoryData?.parent?.limit +
              Number(subCategoryData?.limit) -
              subCategoryData?.previousLimit -
              subCategoryData?.parent?.limitLeft,
          )}.`}
          onConfirm={() => {
            const { name, description, code, limit } = subCategoryData;
            handleConfirm(name, description, code, limit);
          }}
          loading={updateLoading}
          onCancel={() => setShowConfirmModal(false)}
          isDeleteDialog={false}
        />
      </Modal>
    </>
  );
};

export default EditSubcategory;
