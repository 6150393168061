import ConfirmDialog from 'components/ConfirmDialog';
import { toastSuccess } from 'components/UI/toast';
import { subYears } from 'date-fns';
import VendorModal from 'pages/Vendor/VendorModal';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getProfileStats } from 'redux/actions/MemberProfileAction';
import { getVendorProfile } from 'redux/actions/VendorProfileAction';
import { deleteVendors, vendorInfo } from 'redux/actions/VendorsAction';
import { RESET_BLOCK_MEMBER_PROFILE } from 'redux/reducers/MemberProfileReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { RESET_BLOCK_VENDOR_PROFILE } from 'redux/reducers/VendorProfileReducer';
import {
  asciiToHex,
  buildVendorsTableData,
  getColor,
  getFormattedDate,
} from 'utils/helper';
import ApproversTable from './Components/ApproversTable';
import ProfileComponent from './Components/ProfileComponent';
import TransactionTable from './Components/TransactionTable';
import dayjs from 'dayjs';
import CreateNewVendor from 'pages/Vendor/CreateNewVendor';
import { PlusIcon } from 'assets/icons';
import { cancelSubscription, getSpend } from 'redux/actions/SpendAction';
import ReceiptsTable from './Components/Receipts';

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { code } = useParams();

  const [basicInformation, setBasicInformation] = useState([]);
  const [userInformation, setUserInformation] = useState({});

  const [chartData, setChartData] = useState(null);

  const [pageLoading, setPageLoading] = useState(true);
  const [isRequest, setIsRequest] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [vendorData, setVendorData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [informationBasedOnUserType, setInformationBasedOnUserType] = useState({
    userType: 'spend',
    tabList: [
      {
        name: 'Transactions',
        key: 'transactions',
      },
      {
        name: 'Receipts',
        key: 'receipts',
      },
      {
        name: 'Seats',
        key: 'seats',
      },
    ],
    pageLoading,
  });

  const {
    getSpend: { loading, data: profileData = {} },
    cancelSubscription: { loading: cancelling, data: cancelResponse = {} },
  } = useSelector(({ spends }) => spends);

  const today = new Date();
  const lastYearStartDate = subYears(today, 1);
  const lastYearEndDate = today;

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_VENDOR_PROFILE, blockType: 'getVendorProfile' });
      dispatch({ type: RESET_BLOCK_MEMBER_PROFILE, blockType: 'getProfileStats' });
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getTransaction' });
      dispatch({ type: RESET_BLOCK_VENDOR_PROFILE, blockType: 'getVendorApprovers' });
    };
  }, []);
  /* 
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(getSpend({ code: id }));
      toastSuccess('Subscription canceled successfully');
      history.push('/cards/subscriptions');
    }
  }, [deleteSuccess]);
 */
  useEffect(() => {
    setInformationBasedOnUserType({
      userType: 'spend',
      tabList: [
        {
          name: 'Transactions',
          key: 'transactions',
          component: (
            <TransactionTable
              id={code}
              type={'spend'}
              userName={profileData?.vendor?.name}
            />
          ),
        },
        {
          name: 'Receipts',
          key: 'receipts',
          component: (
            <ReceiptsTable
              id={code}
              type={'spend'}
              userName={profileData?.vendor?.name}
            />
          ),
        },
        /* {
          name: 'Seats',
          key: 'seats',
          component: (
            <ApproversTable
              id={code}
              type={'spend'}
              userName={profileData?.vendor?.name}
            />
          ),
        }, */
      ],
      pageLoading,
    });
  }, [profileData?.vendor?.name, pageLoading]);

  const cancelTheSubscription = () => {
    dispatch(cancelSubscription(profileData.code));
  };
  useEffect(() => {
    dispatch(getSpend({ code }));
  }, []);

  useEffect(() => {
    setPageLoading(false);
  }, []);

  const additionalInfo = () => {
    handleEditVendor();
    setIsRequest(true);
    // dispatch(vendorInfo({ code: id, fields: ['phoneNumber'] }));
  };

  useEffect(() => {
    const { vendor, plan } = profileData;

    if (vendor) {
      const { street, country, city, state } = vendor?.address ?? {};
      const filteredArray = [street, city, state, country].filter((item) => !!item);
      const companyAddress = !!filteredArray.length ? filteredArray.join(', ') : '-';

      const uniqueCategory = new Set();

      const categories = vendor?.categories
        ?.map((option) => {
          if (!uniqueCategory.has(option.name)) {
            uniqueCategory.add(option.name);
            return {
              code: option.code,
              slug: option.slug,
              name: option.name,
              color: asciiToHex(option.name),
            };
          }
        })
        ?.filter((option) => !!option);

      setUserInformation({
        name: plan.name,
        type: vendor.name,
        subText: categories?.[0]?.name
          ? `${categories?.[0]?.name} ${
              !!(categories.length - 1) ? `+${categories.length - 1}` : ''
            }`
          : '',
        avatar: null,
        code: vendor.code,
        canSendFunds: true,
        canEdit: true,
        canDelete: true,
      });

      setBasicInformation([
        {
          label: 'Status',
          value: plan.status,
          type: 'badge',
          color: getColor(plan.status),
        },
        {
          label: 'Name',
          value: vendor.name,
          type: vendor.name ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add name
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Website',
          value: vendor.website,
          type: vendor.website ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add website
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Email',
          value: vendor?.email ?? '-',
          type: vendor.email ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add email
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Description',
          value: vendor?.description ?? '-',
          type: vendor.description ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add description
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Phone number',
          value: vendor?.phoneNumber ?? '-',
          type: vendor.phoneNumber ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add phone number
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Company address',
          value: companyAddress,
          type: companyAddress !== '-' ? 'text' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add address
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Category',
          value: categories,
          type: vendor?.categories?.length ? 'badge list' : 'action',
          action: {
            label: (
              <span
                className="d-flex align-items-center gap-2"
                style={{ color: '#D28B28' }}
              >
                <PlusIcon width="12" height="12" /> Add category
              </span>
            ),
            onClick: () => additionalInfo(),
          },
        },
        {
          label: 'Added On',
          value: getFormattedDate(plan.created_at),
          type: 'text',
        },
      ]);
    }
  }, [profileData]);

  const handleDateRangeSelection = (from, to) => {};

  const nextBillingDate = profileData?.meta?.nextBillingDate;

  return (
    <ProfileComponent
      basicInformation={basicInformation}
      bankInformation={null}
      informationBasedOnUserType={informationBasedOnUserType}
      chartData={chartData}
      handleDateRangeSelection={handleDateRangeSelection}
      userInformation={userInformation}
      onEdit={null}
      onDelete={() => setIsDelete(true)}
      loading={loading}
      chartLoading={false}
      hideChart={true}
      deleteText="Cancel"
      sendText="Top up card"
      dateShortCut={false}
      onSend={() => {
        console.log('Open Card Top up modal');
      }}
      analytics={[
        {
          title: 'Total payments',
          amount: profileData?.meta?.totalPayments || '-',
        },
        {
          title: 'Paid this year',
          amount: profileData?.meta?.totalAmount || '-',
          currency: profileData.currency,
        },
        {
          title: 'Next payment',
          amount: profileData.amount || '-',
          currency: profileData?.currency,
        },
        {
          title: 'Next payment date',
          amount: nextBillingDate,
        },
      ]}
    >
      {isDelete && (
        <Modal show={isDelete} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title={`Cancel subscription`}
            subTitle="Are you sure you want to cancel this subscription? This action cannot be undone."
            onConfirm={() => {
              dispatch(cancelTheSubscription(profileData.code));
            }}
            loading={cancelling}
            onCancel={() => {
              setIsDelete(null);
            }}
            actionBtnText={'Cancel'}
          />
        </Modal>
      )}
    </ProfileComponent>
  );
};
export default SubscriptionPage;
