export const CREATE_BUDGET_REQUEST = 'CREATE_BUDGET_REQUEST';
export const CREATE_BUDGET_SUCCESS = 'CREATE_BUDGET_SUCCESS';
export const CREATE_BUDGET_ERROR = 'CREATE_BUDGET_ERROR';

export const DELETE_BUDGET_REQUEST = 'DELETE_BUDGET_REQUEST';
export const DELETE_BUDGET_SUCCESS = 'DELETE_BUDGET_SUCCESS';
export const DELETE_BUDGET_ERROR = 'DELETE_BUDGET_ERROR';

export const GET_BUDGET_REQUEST = 'GET_BUDGET_REQUEST';
export const GET_BUDGET_SUCCESS = 'GET_BUDGET_SUCCESS';
export const GET_BUDGET_ERROR = 'GET_BUDGET_ERROR';

export const GET_SELECTED_BUDGET_REQUEST = 'GET_SELECTED_BUDGET_REQUEST';
export const GET_SELECTED_BUDGET_SUCCESS = 'GET_SELECTED_BUDGET_SUCCESS';
export const GET_SELECTED_BUDGET_ERROR = 'GET_SELECTED_BUDGET_ERROR';

export const GET_BUDGET_STATS_REQUEST = 'GET_BUDGET_STATS_REQUEST';
export const GET_BUDGET_STATS_SUCCESS = 'GET_BUDGET_STATS_SUCCESS';
export const GET_BUDGET_STATS_ERROR = 'GET_BUDGET_STATS_ERROR';

export const GET_SINGLE_BUDGET_REQUEST = 'GET_SINGLE_BUDGET_REQUEST';
export const GET_SINGLE_BUDGET_SUCCESS = 'GET_SINGLE_BUDGET_SUCCESS';
export const GET_SINGLE_BUDGET_ERROR = 'GET_SINGLE_BUDGET_ERROR';

export const UPDATE_BUDGET_REQUEST = 'UPDATE_BUDGET_REQUEST';
export const UPDATE_BUDGET_SUCCESS = 'UPDATE_BUDGET_SUCCESS';
export const UPDATE_BUDGET_ERROR = 'UPDATE_BUDGET_ERROR';

export const BUDGET_BENEFICIARIES_REQUEST = 'BUDGET_BENEFICIARIES_REQUEST';
export const BUDGET_BENEFICIARIES_SUCCESS = 'BUDGET_BENEFICIARIES_SUCCESS';
export const BUDGET_BENEFICIARIES_ERROR = 'BUDGET_BENEFICIARIES_ERROR';

export const BUDGET_CARD_REQUEST = 'BUDGET_CARD_REQUEST';
export const BUDGET_CARD_SUCCESS = 'BUDGET_CARD_SUCCESS';
export const BUDGET_CARD_ERROR = 'BUDGET_CARD_ERROR';

export const CREATE_NEW_BENEFICIARIES_REQUEST = 'CREATE_NEW_BENEFICIARIES_REQUEST';
export const CREATE_NEW_BENEFICIARIES_SUCCESS = 'CREATE_NEW_BENEFICIARIES_SUCCESS';
export const CREATE_NEW_BENEFICIARIES_ERROR = 'CREATE_NEW_BENEFICIARIES_ERROR';

export const DELETE_BUDGET_BENEFICIARIES_REQUEST = 'DELETE_BUDGET_BENEFICIARIES_REQUEST';
export const DELETE_BUDGET_BENEFICIARIES_SUCCESS = 'DELETE_BUDGET_BENEFICIARIES_SUCCESS';
export const DELETE_BUDGET_BENEFICIARIES_ERROR = 'DELETE_BUDGET_BENEFICIARIES_ERROR';

export const GET_SINGLE_TRANSACTION_REQUEST = 'GET_SINGLE_TRANSACTION_REQUEST';
export const GET_SINGLE_TRANSACTION_SUCCESS = 'GET_SINGLE_TRANSACTION_SUCCESS';
export const GET_SINGLE_TRANSACTION_ERROR = 'GET_SINGLE_TRANSACTION_ERROR';

export const GET_TRANSFERABLE_BALANCE_REQUEST = 'GET_TRANSFERABLE_BALANCE_REQUEST';
export const GET_TRANSFERABLE_BALANCE_SUCCESS = 'GET_TRANSFERABLE_BALANCE_SUCCESS';
export const GET_TRANSFERABLE_BALANCE_ERROR = 'GET_TRANSFERABLE_BALANCE_ERROR';

export const CURRENCY_CONVERSION_RATE_REQUEST = 'CURRENCY_CONVERSION_RATE_REQUEST';
export const CURRENCY_CONVERSION_RATE_SUCCESS = 'CURRENCY_CONVERSION_RATE_SUCCESS';
export const CURRENCY_CONVERSION_RATE_ERROR = 'CURRENCY_CONVERSION_RATE_ERROR';

export const TOPUP_BUDGET_REQUEST = 'TOPUP_BUDGET_REQUEST';
export const TOPUP_BUDGET_SUCCESS = 'TOPUP_BUDGET_SUCCESS';
export const TOPUP_BUDGET_ERROR = 'TOPUP_BUDGET_ERROR';

export const WITHDRAW_BUDGET_REQUEST = 'WITHDRAW_BUDGET_REQUEST';
export const WITHDRAW_BUDGET_SUCCESS = 'WITHDRAW_BUDGET_SUCCESS';
export const WITHDRAW_BUDGET_ERROR = 'WITHDRAW_BUDGET_ERROR';

export const DEACTIVATE_BUDGET_REQUEST = 'DEACTIVATE_BUDGET_REQUEST';
export const DEACTIVATE_BUDGET_SUCCESS = 'DEACTIVATE_BUDGET_SUCCESS';
export const DEACTIVATE_BUDGET_ERROR = 'DEACTIVATE_BUDGET_ERROR';

export const ACTIVATE_BUDGET_REQUEST = 'ACTIVATE_BUDGET_REQUEST';
export const ACTIVATE_BUDGET_SUCCESS = 'ACTIVATE_BUDGET_SUCCESS';
export const ACTIVATE_BUDGET_ERROR = 'ACTIVATE_BUDGET_ERROR';

export const GET_BUDGET_LEDGER_REQUEST = 'GET_BUDGET_LEDGER_REQUEST';
export const GET_BUDGET_LEDGER_SUCCESS = 'GET_BUDGET_LEDGER_SUCCESS';
export const GET_BUDGET_LEDGER_ERROR = 'GET_BUDGET_LEDGER_ERROR';

export const GET_SUB_ACCOUNT_REQUEST = 'GET_SUB_ACCOUNT_REQUEST';
export const GET_SUB_ACCOUNT_SUCCESS = 'GET_SUB_ACCOUNT_SUCCESS';
export const GET_SUB_ACCOUNT_ERROR = 'GET_SUB_ACCOUNT_ERROR';

export const GET_BALANCES_REQUEST = 'GET_BALANCES_REQUEST';
export const GET_BALANCES_SUCCESS = 'GET_BALANCES_SUCCESS';
export const GET_BALANCES_ERROR = 'GET_BALANCES_ERROR';

export const GET_ACCOUNT_SUBACCOUNTS_REQUEST = 'GET_ACCOUNT_SUBACCOUNTS_REQUEST';
export const GET_ACCOUNT_SUBACCOUNTS_SUCCESS = 'GET_ACCOUNT_SUBACCOUNTS_SUCCESS';
export const GET_ACCOUNT_SUBACCOUNTS_ERROR = 'GET_ACCOUNT_SUBACCOUNTS_ERROR';

export const ADD_MULTIPLE_BENEFICIARIES_REQUEST = 'ADD_MULTIPLE_BENEFICIARIES_REQUEST';
export const ADD_MULTIPLE_BENEFICIARIES_SUCCESS = 'ADD_MULTIPLE_BENEFICIARIES_SUCCESS';
export const ADD_MULTIPLE_BENEFICIARIES_ERROR = 'ADD_MULTIPLE_BENEFICIARIES_ERROR';

export const WITHDRAW_BUDGET_FUNDS_REQUEST = 'WITHDRAW_BUDGET_FUNDS_REQUEST';
export const WITHDRAW_BUDGET_FUNDS_SUCCESS = 'WITHDRAW_BUDGET_FUNDS_SUCCESS';
export const WITHDRAW_BUDGET_FUNDS_ERROR = 'WITHDRAW_BUDGET_FUNDS_ERROR';

export const GET_SINGLE_BALANCE_REQUEST = 'GET_SINGLE_BALANCE_REQUEST';
export const GET_SINGLE_BALANCE_SUCCESS = 'GET_SINGLE_BALANCE_SUCCESS';
export const GET_SINGLE_BALANCE_ERROR = 'GET_SINGLE_BALANCE_ERROR';

export const GET_SINGLE_SUBACCOUNT_REQUEST = 'GET_SINGLE_SUBACCOUNT_REQUEST';
export const GET_SINGLE_SUBACCOUNT_SUCCESS = 'GET_SINGLE_SUBACCOUNT_SUCCESS';
export const GET_SINGLE_SUBACCOUNT_ERROR = 'GET_SINGLE_SUBACCOUNT_ERROR';

export const GET_SUBACCOUNT_MEMBERS_REQUEST = 'GET_SUBACCOUNT_MEMBERS_REQUEST';
export const GET_SUBACCOUNT_MEMBERS_SUCCESS = 'GET_SUBACCOUNT_MEMBERS_SUCCESS';
export const GET_SUBACCOUNT_MEMBERS_ERROR = 'GET_SUBACCOUNT_MEMBERS_ERROR';

export const GET_SUBACCOUNT_TRANSACTIONS_REQUEST = 'GET_SUBACCOUNT_TRANSACTIONS_REQUEST';
export const GET_SUBACCOUNT_TRANSACTIONS_SUCCESS = 'GET_SUBACCOUNT_TRANSACTIONS_SUCCESS';
export const GET_SUBACCOUNT_TRANSACTIONS_ERROR = 'GET_SUBACCOUNT_TRANSACTIONS_ERROR';

export const REMOVE_SUBACCOUNT_MEMBER_REQUEST = 'REMOVE_SUBACCOUNT_MEMBER_REQUEST';
export const REMOVE_SUBACCOUNT_MEMBER_SUCCESS = 'REMOVE_SUBACCOUNT_MEMBER_SUCCESS';
export const REMOVE_SUBACCOUNT_MEMBER_ERROR = 'REMOVE_SUBACCOUNT_MEMBER_ERROR';

export const ADD_SUBACCOUNT_MEMBERS_REQUEST = 'ADD_SUBACCOUNT_MEMBERS_REQUEST';
export const ADD_SUBACCOUNT_MEMBERS_SUCCESS = 'ADD_SUBACCOUNT_MEMBERS_SUCCESS';
export const ADD_SUBACCOUNT_MEMBERS_ERROR = 'ADD_SUBACCOUNT_MEMBERS_ERROR';

export const UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST =
  'UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST';
export const UPDATE_BUDGET_BENEFICIARY_SETTINGS_SUCCESS =
  'UPDATE_BUDGET_BENEFICIARY_SETTINGS_SUCCESS';
export const UPDATE_BUDGET_BENEFICIARY_SETTINGS_ERROR =
  'UPDATE_BUDGET_BENEFICIARY_SETTINGS_ERROR';

export const DOWNLOAD_BUDGET_AUDIT_REQUEST = 'DOWNLOAD_BUDGET_AUDIT_REQUEST';
export const DOWNLOAD_BUDGET_AUDIT_SUCCESS = 'DOWNLOAD_BUDGET_AUDIT_SUCCESS';
export const DOWNLOAD_BUDGET_AUDIT_ERROR = 'DOWNLOAD_BUDGET_AUDIT_ERROR';

export const RESET_BLOCK_BUDGET = 'RESET_BLOCK_BUDGET';

export const RESET_FLAGS_BUDGET = 'RESET_FLAGS_BUDGET';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getBudget: { ...block },
  getSelectedBudget: { ...block },
  createBudget: { ...block },
  updateBudget: { ...block },
  getSingleBudget: { ...block },
  getSingleTransaction: { ...block },
  getBudgetStat: { ...block },
  getBudgetBeneficiaries: { ...block },
  getBudgetCard: { ...block },
  createNewBeneficiaries: { ...block },
  multipleBeneficiaries: { ...block },
  deleteBudgetBeneficiaries: { ...block },
  deleteBudget: { ...block },
  getTransferableBalance: { ...block },
  getCurrencyConversionRate: { ...block },
  topUpBudget: { ...block },
  withdrawBudget: { ...block },
  deactivateBudget: { ...block },
  activateBudget: { ...block },
  getBudgetLedger: { ...block },
  getSubAccount: { ...block },
  getBalances: { ...block },
  getSubAccountMembers: { ...block },
  getSubAccountTransactions: { ...block },
  addSubAccountMembers: { ...block },
  getAccountSubAccounts: { ...block },
  removeSubAccountMember: { ...block },
  withdrawnBudget: { ...block },
  getSingleBalance: { ...block },
  getSingleSubAccount: { ...block },
  updateBudgetBeneficiarySettings: { ...block },
  downloadBudgetAudit: { ...block },
};
//

export const BudgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUDGET_REQUEST:
      return { ...state, getBudget: { ...state.getBudget, loading: true } };
    case GET_BUDGET_SUCCESS:
      return {
        ...state,
        getBudget: {
          ...state.getBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BUDGET_ERROR:
      return {
        ...state,
        getBudget: { ...state.getBudget, loading: false, error: action.error },
      };

    case GET_SELECTED_BUDGET_REQUEST:
      return {
        ...state,
        getSelectedBudget: { ...state.getSelectedBudget, loading: true },
      };
    case GET_SELECTED_BUDGET_SUCCESS:
      return {
        ...state,
        getSelectedBudget: {
          ...state.getSelectedBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SELECTED_BUDGET_ERROR:
      return {
        ...state,
        getSelectedBudget: {
          ...state.getSelectedBudget,
          loading: false,
          error: action.error,
        },
      };

    case GET_BUDGET_STATS_REQUEST:
      return { ...state, getBudgetStat: { ...state.getBudgetStat, loading: true } };
    case GET_BUDGET_STATS_SUCCESS:
      return {
        ...state,
        getBudgetStat: {
          ...state.getBudgetStat,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BUDGET_STATS_ERROR:
      return {
        ...state,
        getBudgetStat: { ...state.getBudgetStat, loading: false, error: action.error },
      };

    case CREATE_BUDGET_REQUEST:
      return { ...state, createBudget: { ...state.createBudget, loading: true } };
    case CREATE_BUDGET_SUCCESS:
      return {
        ...state,
        createBudget: {
          ...state.createBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BUDGET_ERROR:
      return {
        ...state,
        createBudget: { ...state.createBudget, loading: false, error: action.error },
      };

    case DELETE_BUDGET_REQUEST:
      return { ...state, deleteBudget: { ...state.deleteBudget, loading: true } };
    case DELETE_BUDGET_SUCCESS:
      return {
        ...state,
        deleteBudget: {
          ...state.deleteBudget,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_BUDGET_ERROR:
      return {
        ...state,
        deleteBudget: { ...state.deleteBudget, loading: false, error: action.error },
      };

    case UPDATE_BUDGET_REQUEST:
      return {
        ...state,
        updateBudget: { ...state.updateBudget, loading: true },
      };
    case UPDATE_BUDGET_SUCCESS:
      return {
        ...state,
        updateBudget: {
          ...state.updateBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_BUDGET_ERROR:
      return {
        ...state,
        updateBudget: {
          ...state.updateBudget,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BUDGET_REQUEST:
      return { ...state, getSingleBudget: { ...state.getSingleBudget, loading: true } };
    case GET_SINGLE_BUDGET_SUCCESS:
      return {
        ...state,
        getSingleBudget: {
          ...state.getSingleBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case GET_SINGLE_BUDGET_ERROR:
      return {
        ...state,
        getSingleBudget: {
          ...state.getSingleBudget,
          loading: false,
          error: action.error,
        },
      };

    case BUDGET_BENEFICIARIES_REQUEST:
      return {
        ...state,
        getBudgetBeneficiaries: { ...state.getBudgetBeneficiaries, loading: true },
      };
    case BUDGET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        getBudgetBeneficiaries: {
          ...state.getBudgetBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BUDGET_BENEFICIARIES_ERROR:
      return {
        ...state,
        getBudgetBeneficiaries: {
          ...state.getBudgetBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case BUDGET_CARD_REQUEST:
      return { ...state, getBudgetCard: { ...state.getBudgetCard, loading: true } };
    case BUDGET_CARD_SUCCESS:
      return {
        ...state,
        getBudgetCard: {
          ...state.getBudgetCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BUDGET_CARD_ERROR:
      return {
        ...state,
        getBudgetCard: {
          ...state.getBudgetCard,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_NEW_BENEFICIARIES_REQUEST:
      return {
        ...state,
        createNewBeneficiaries: { ...state.createNewBeneficiaries, loading: true },
      };
    case CREATE_NEW_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        createNewBeneficiaries: {
          ...state.createNewBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_NEW_BENEFICIARIES_ERROR:
      return {
        ...state,
        createNewBeneficiaries: {
          ...state.createNewBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case ADD_MULTIPLE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        multipleBeneficiaries: {
          ...state.multipleBeneficiaries,
          loading: true,
        },
      };

    case ADD_MULTIPLE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        multipleBeneficiaries: {
          ...state.multipleBeneficiaries,
          loading: false,
          data: action.data,
          success: true,
          error: '',
        },
      };

    case ADD_MULTIPLE_BENEFICIARIES_REQUEST:
      return {
        ...state,
        multipleBeneficiaries: {
          ...state.multipleBeneficiaries,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case DELETE_BUDGET_BENEFICIARIES_REQUEST:
      return {
        ...state,
        deleteBudgetBeneficiaries: { ...state.deleteBudgetBeneficiaries, loading: true },
      };
    case DELETE_BUDGET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        deleteBudgetBeneficiaries: {
          ...state.deleteBudgetBeneficiaries,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_BUDGET_BENEFICIARIES_ERROR:
      return {
        ...state,
        deleteBudgetBeneficiaries: {
          ...state.deleteBudgetBeneficiaries,
          loading: false,
          error: action.error,
        },
      };

    case GET_TRANSFERABLE_BALANCE_REQUEST:
      return {
        ...state,
        getTransferableBalance: { ...state.getTransferableBalance, loading: true },
      };
    case GET_TRANSFERABLE_BALANCE_SUCCESS:
      return {
        ...state,
        getTransferableBalance: {
          ...state.getTransferableBalance,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_TRANSFERABLE_BALANCE_ERROR:
      return {
        ...state,
        getTransferableBalance: {
          ...state.getTransferableBalance,
          loading: false,
          error: action.error,
        },
      };

    case CURRENCY_CONVERSION_RATE_REQUEST:
      return {
        ...state,
        getCurrencyConversionRate: { ...state.getCurrencyConversionRate, loading: true },
      };
    case CURRENCY_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        getCurrencyConversionRate: {
          ...state.getCurrencyConversionRate,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CURRENCY_CONVERSION_RATE_ERROR:
      return {
        ...state,
        getCurrencyConversionRate: {
          ...state.getCurrencyConversionRate,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_TRANSACTION_REQUEST:
      return {
        ...state,
        getSingleTransaction: { ...state.getSingleTransaction, loading: true },
      };
    case GET_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        getBudgetStat: {
          ...state.getBudgetStat,
          data: {
            ...state.getBudgetStat.data,
            transactions: action.data?.transactions,
          },
        },
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_TRANSACTION_ERROR:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          error: action.error,
        },
      };

    //
    case TOPUP_BUDGET_REQUEST:
      return {
        ...state,
        topUpBudget: { ...state.topUpBudget, loading: true },
      };
    case TOPUP_BUDGET_SUCCESS:
      return {
        ...state,
        topUpBudget: {
          ...state.topUpBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case TOPUP_BUDGET_ERROR:
      return {
        ...state,
        topUpBudget: {
          ...state.topUpBudget,
          loading: false,
          error: action.error,
        },
      };

    //
    case WITHDRAW_BUDGET_REQUEST:
      return {
        ...state,
        withdrawBudget: { ...state.withdrawBudget, loading: true },
      };
    case WITHDRAW_BUDGET_SUCCESS:
      return {
        ...state,
        withdrawBudget: {
          ...state.withdrawBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case WITHDRAW_BUDGET_ERROR:
      return {
        ...state,
        withdrawBudget: {
          ...state.withdrawBudget,
          loading: false,
          error: action.error,
        },
      };

    //
    case DEACTIVATE_BUDGET_REQUEST:
      return {
        ...state,
        deactivateBudget: { ...state.deactivateBudget, loading: true },
      };
    case DEACTIVATE_BUDGET_SUCCESS:
      return {
        ...state,
        deactivateBudget: {
          ...state.deactivateBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DEACTIVATE_BUDGET_ERROR:
      return {
        ...state,
        deactivateBudget: {
          ...state.deactivateBudget,
          loading: false,
          error: action.error,
        },
      };

    //
    case ACTIVATE_BUDGET_REQUEST:
      return {
        ...state,
        activateBudget: { ...state.activateBudget, loading: true },
      };
    case ACTIVATE_BUDGET_SUCCESS:
      return {
        ...state,
        activateBudget: {
          ...state.activateBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ACTIVATE_BUDGET_ERROR:
      return {
        ...state,
        activateBudget: {
          ...state.activateBudget,
          loading: false,
          error: action.error,
        },
      };

    //
    case GET_BUDGET_LEDGER_REQUEST:
      return {
        ...state,
        getBudgetLedger: { ...state.getBudgetLedger, loading: true },
      };
    case GET_BUDGET_LEDGER_SUCCESS:
      return {
        ...state,
        getBudgetLedger: {
          ...state.getBudgetLedger,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BUDGET_LEDGER_ERROR:
      return {
        ...state,
        getBudgetLedger: {
          ...state.getBudgetLedger,
          loading: false,
          error: action.error,
        },
      };

    //
    case GET_SUB_ACCOUNT_REQUEST:
      return {
        ...state,
        getSubAccount: { ...state.getSubAccount, loading: true },
      };
    case GET_SUB_ACCOUNT_SUCCESS:
      return {
        ...state,
        getSubAccount: {
          ...state.getSubAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SUB_ACCOUNT_ERROR:
      return {
        ...state,
        getSubAccount: {
          ...state.getSubAccount,
          loading: false,
          error: action.error,
        },
      };

    //
    case GET_BALANCES_REQUEST:
      return {
        ...state,
        getBalances: { ...state.getBalances, loading: true },
      };
    case GET_BALANCES_SUCCESS:
      return {
        ...state,
        getBalances: {
          ...state.getBalances,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BALANCES_ERROR:
      return {
        ...state,
        getBalances: {
          ...state.getBalances,
          loading: false,
          error: action.error,
        },
      };

    case GET_ACCOUNT_SUBACCOUNTS_REQUEST:
      return {
        ...state,
        getAccountSubAccounts: { ...state.getAccountSubAccounts, loading: true },
      };
    case GET_ACCOUNT_SUBACCOUNTS_SUCCESS:
      return {
        ...state,
        getAccountSubAccounts: {
          ...state.getAccountSubAccounts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ACCOUNT_SUBACCOUNTS_ERROR:
      return {
        ...state,
        getAccountSubAccounts: {
          ...state.getAccountSubAccounts,
          loading: false,
          error: action.error,
        },
      };

    case WITHDRAW_BUDGET_FUNDS_REQUEST:
      return {
        ...state,
        withdrawnBudget: { ...state.withdrawnBudget, loading: true },
      };
    case WITHDRAW_BUDGET_FUNDS_SUCCESS:
      return {
        ...state,
        withdrawnBudget: {
          ...state.withdrawnBudget,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case WITHDRAW_BUDGET_FUNDS_ERROR:
      return {
        ...state,
        withdrawnBudget: {
          ...state.withdrawnBudget,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BALANCE_REQUEST:
      return {
        ...state,
        getSingleBalance: { ...state.getSingleBalance, loading: true },
      };
    case GET_SINGLE_BALANCE_SUCCESS:
      return {
        ...state,
        getSingleBalance: {
          ...state.getSingleBalance,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_BALANCE_ERROR:
      return {
        ...state,
        getSingleBalance: {
          ...state.getSingleBalance,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_SUBACCOUNT_REQUEST:
      return {
        ...state,
        getSingleSubAccount: { ...state.getSingleSubAccount, loading: true },
      };
    case GET_SINGLE_SUBACCOUNT_SUCCESS:
      return {
        ...state,
        getSingleSubAccount: {
          ...state.getSingleSubAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_SUBACCOUNT_ERROR:
      return {
        ...state,
        getSingleSubAccount: {
          ...state.getSingleSubAccount,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_BUDGET_BENEFICIARY_SETTINGS_REQUEST:
      return {
        ...state,
        updateBudgetBeneficiarySettings: {
          ...state.updateBudgetBeneficiarySettings,
          loading: true,
        },
      };
    case UPDATE_BUDGET_BENEFICIARY_SETTINGS_SUCCESS:
      return {
        ...state,
        updateBudgetBeneficiarySettings: {
          ...state.updateBudgetBeneficiarySettings,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_BUDGET_BENEFICIARY_SETTINGS_ERROR:
      return {
        ...state,
        updateBudgetBeneficiarySettings: {
          ...state.updateBudgetBeneficiarySettings,
          loading: false,
          error: action.error,
        },
      };

    case DOWNLOAD_BUDGET_AUDIT_REQUEST:
      return {
        ...state,
        downloadBudgetAudit: {
          ...state.downloadBudgetAudit,
          loading: true,
        },
      };
    case DOWNLOAD_BUDGET_AUDIT_SUCCESS:
      return {
        ...state,
        downloadBudgetAudit: {
          ...state.downloadBudgetAudit,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DOWNLOAD_BUDGET_AUDIT_ERROR:
      return {
        ...state,
        downloadBudgetAudit: {
          ...state.downloadBudgetAudit,
          loading: false,
          error: action.error,
        },
      };

    case GET_SUBACCOUNT_MEMBERS_REQUEST:
      return {
        ...state,
        getSubAccountMembers: { ...state.getSubAccountMembers, loading: true },
      };
    case GET_SUBACCOUNT_MEMBERS_SUCCESS:
      return {
        ...state,
        getSubAccountMembers: {
          ...state.getSubAccountMembers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SUBACCOUNT_MEMBERS_ERROR:
      return {
        ...state,
        getSubAccountMembers: {
          ...state.getSubAccountMembers,
          loading: false,
          error: action.error,
        },
      };

    case GET_SUBACCOUNT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSubAccountTransactions: { ...state.getSubAccountTransactions, loading: true },
      };
    case GET_SUBACCOUNT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSubAccountTransactions: {
          ...state.getSubAccountTransactions,
          loading: false,
          success: true,
          error: '',
          data: action.data,
          pageSource: action.pageSource,
        },
      };
    case GET_SUBACCOUNT_TRANSACTIONS_ERROR:
      return {
        ...state,
        getSubAccountTransactions: {
          ...state.getSubAccountTransactions,
          loading: false,
          error: action.error,
        },
      };

    case ADD_SUBACCOUNT_MEMBERS_REQUEST:
      return {
        ...state,
        addSubAccountMembers: { ...state.addSubAccountMembers, loading: true },
      };
    case ADD_SUBACCOUNT_MEMBERS_SUCCESS:
      return {
        ...state,
        addSubAccountMembers: {
          ...state.addSubAccountMembers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ADD_SUBACCOUNT_MEMBERS_ERROR:
      return {
        ...state,
        addSubAccountMembers: {
          ...state.addSubAccountMembers,
          loading: false,
          error: action.error,
        },
      };

    case REMOVE_SUBACCOUNT_MEMBER_REQUEST:
      return {
        ...state,
        removeSubAccountMember: { ...state.removeSubAccountMember, loading: true },
      };
    case REMOVE_SUBACCOUNT_MEMBER_SUCCESS:
      return {
        ...state,
        removeSubAccountMember: {
          ...state.removeSubAccountMember,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REMOVE_SUBACCOUNT_MEMBER_ERROR:
      return {
        ...state,
        removeSubAccountMember: {
          ...state.removeSubAccountMember,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_BUDGET:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_BUDGET:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
