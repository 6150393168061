import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import CustomButton from 'components/UI/CustomButton';
import { toastSuccess } from 'components/UI/toast';
import copy from 'copy-text-to-clipboard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBankAccount,
  getCompanyStats,
  getDollarAccount,
} from 'redux/actions/CompaniesAction';
import NotificationModal from '../../../NotificationModal/NotificationModal';
import Loading from '../../../UI/Loading';
import '../styles.scss';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const AccountInfo = ({
  toggleHandler,
  setStep = () => null,
  setSelectStep = () => null,
  setActiveBankCard = () => null,
  title,
  hideLink,
  handleBtnClick,
  setTryAgain,
  subtTitle,
  account,
  accountType,
  handleBack,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [fetchingAccountDetails, setFetchingAccountDetails] = useState(true);
  const [fetchingAccountDetailsCounter, setFetchingAccountDetailsCounter] = useState(0);
  const {
    getBankAccount: { loading, data: getBank, error, success: getBankSuccess },
    getStatistics: { success, data: statisticData = {} },
    getDollarAccount: { data: dollarAccount, success: getDollarAccountSuccess },
  } = useSelector(({ companies }) => companies);

  const { permissions } = allPermissions();

  const canViewDash = hasPermission({
    permissions,
    scopes: ['dash-view'],
  });

  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (!success || !statisticData) return;
    const { chart = {} } = statisticData;
    if (chart.Fri?.amount) {
      setStep(4);
      setSelectStep(4);
      setActiveBankCard(4);
      return;
    }
    setShowModal(true);
  }, [success]);

  useEffect(() => {
    if (error) {
      setActiveBankCard(0);
      handleBack();
    }
  }, [error]);

  useEffect(() => {
    if (
      (getBankSuccess && fetchingAccountDetailsCounter < 2) ||
      (getDollarAccountSuccess && fetchingAccountDetailsCounter < 2)
    ) {
      if (
        (getBank === undefined && accountType !== 'USD') ||
        (dollarAccount === undefined && accountType === 'USD')
      ) {
        setFetchingAccountDetailsCounter(fetchingAccountDetailsCounter + 1);
        setTimeout(() => {
          if (accountType !== 'USD') {
            dispatch(getBankAccount({ code: data.user.company.code }));
          } else {
            dispatch(getDollarAccount({ amount: 10000 }));
          }
        }, 5000);
      } else {
        setFetchingAccountDetails(false);
      }
    }

    if (fetchingAccountDetailsCounter > 1) {
      setTryAgain(true);
    }
  }, [getBankSuccess, getDollarAccountSuccess]);

  useEffect(() => {
    if (accountType === 'USD') {
      dispatch(getDollarAccount({ amount: 10000 }));
    } else {
      dispatch(getBankAccount({ code: data.user.company.code }));
    }
  }, []);

  const [initialState, setInitialState] = useState([
    {
      title: 'Bank Name',
      value: '',
    },
    {
      title: 'Account name',
      value: '',
    },
    {
      title: 'Bank code',
      value: '',
    },
    {
      title: 'Account number',
      value: '',
    },
    {
      title: 'Reference ID',
      value: '',
    },
  ]);

  const getNairaAccountDetails = () => {
    const { bankCode, number, accountName, bankName } = account || getBank;
    setInitialState([
      {
        title: 'Bank Name',
        value: bankName,
      },
      {
        title: 'Account name',
        value: accountName,
      },
      {
        title: 'Bank code',
        value: bankCode,
      },
      {
        title: 'Account number',
        value: number,
      },
    ]);
  };

  const getDollarAccountDetails = () => {
    const { account_name, account_number, bank_name, reference, routing_number } =
      dollarAccount;
    setInitialState([
      {
        title: 'Bank Name',
        value: bank_name,
      },
      {
        title: 'Account name',
        value: account_name,
      },
      {
        title: 'Bank code',
        value: routing_number,
      },
      {
        title: 'Account number',
        value: account_number,
      },
      {
        title: 'Reference ID',
        value: reference,
      },
    ]);
  };

  useEffect(() => {
    if (getBank && accountType !== 'USD') {
      getNairaAccountDetails();
    }
    if (dollarAccount && accountType === 'USD') {
      getDollarAccountDetails();
    }
  }, [getBank, dollarAccount]);

  const NairaAccountItems = initialState.filter(
    (item) => !['Reference ID', 'Bank code'].includes(item.title),
  );

  const accountItem = accountType === 'USD' ? initialState : NairaAccountItems;

  const onClickHandler = () => {
    if (handleBtnClick) return handleBtnClick();
    localStorage.setItem('setStep', JSON.stringify(3));
    if (canViewDash) dispatch(getCompanyStats({ id: data.user?.company?.code }));
  };

  const InfoBox = (data) => {
    const { data: value, index } = data;
    // if (!value.value) return <div />;
    return (
      <div className="InfoBox-wrapper">
        <div className="information-title-wrapper">
          <p className="information-title">{value.title}</p>
          <p className="information-value">{value.value}</p>
          {value.title === 'Reference ID' && (
            <p className="ref-id">Add the reference ID when making transfer</p>
          )}
        </div>
        {index > 1 && (
          <span
            className="copy-box"
            onClick={() => {
              copy(value.value);
              toastSuccess(`Copied successfully`);
            }}
          >
            Copy <CopyIcon />
          </span>
        )}
      </div>
    );
  };
  const toggleModal = () => setShowModal((pre) => !pre);
  return (
    <div className="information-wrapper">
      {showModal && (
        <NotificationModal
          handleClose={toggleModal}
          title="Uh oh!"
          message="The money has not reached us yet. Please retry in a couple of minutes."
        />
      )}
      <h2 className="card-title w-100">{title ? title : 'Account details'}</h2>
      {subtTitle && <p>{subtTitle}</p>}
      {fetchingAccountDetails ? (
        <div className="d-flex justify-content-center pt-5">
          <div className="text-center">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="form-normal">
          <div className="pt-1 pb-3">
            <div className="list-wrapper">
              {accountItem.map((data, i) => (
                <InfoBox data={data} index={i} key={i} />
              ))}
            </div>
          </div>
          {
            // TODO check the Prototype after this is clicked (based on API response) a notification will be shown, check
            // the prototype I sent
          }
          <CustomButton className="mb-4" fullWidth={true} onClick={onClickHandler}>
            I&apos;ve sent the money
          </CustomButton>
          {!hideLink && (
            <p className="pt-4 text-center cursor" onClick={toggleHandler}>
              Send later
            </p>
          )}
        </div>
      )}
    </div>
  );
};
export default AccountInfo;
