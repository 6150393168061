import { IndeterminateCheckbox } from 'components/Table';
import React from 'react';

const SelectionType = ({ cell }) => {
  return (
    <div>
      {cell?.Cell((_ref4) => {
        let { row } = _ref4;
        return <IndeterminateCheckbox {...row?.getToggleRowSelectedProps()} />;
      })}
    </div>
  );
};

export default SelectionType;
