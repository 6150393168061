import React from 'react';
import './styles.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const BalanceUI = ({ children, title = 'Available balance:', tooltip }) => {
  return (
    <div className="mb-0 d-flex align-items-center justify-content-center text-secondary position-absolute balance-ui gap-2">
      {tooltip && (
        <span className="mb-0 translate-middle-y">
          <Tooltip
            overlayClassName="tool-tip"
            placement="top"
            title={<div className="text">{tooltip}</div>}
          >
            <QuestionCircleOutlined className="pointer-event cursor" />
          </Tooltip>
        </span>
      )}

      <p>{title}</p>
      <span>{children}</span>
    </div>
  );
};

export default BalanceUI;
