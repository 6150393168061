import { ReactComponent as CloudArrowUpGreen } from 'assets/icons/cloud-upload-icon-green.svg';
import { ReactComponent as CloudArrowUpGrey } from 'assets/icons/cloud-upload-icon-grey.svg';
import ConfirmDialog from 'components/ConfirmDialog';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uploadFileData } from 'redux/actions/AssetsAction';
import { getRoles } from 'redux/actions/RolesAction';

const UploadMemberDialog = ({ setShowUploadModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onDrop = useCallback((_, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      toastError('File size exceeds 5MB limit');
      return;
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { 'text/html': ['.xlsx', '.csv'] },
    maxSize: 5 * 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    if (acceptedFiles.length === 1) {
      dispatch(uploadFileData(acceptedFiles[0]));
      setShowUploadModal(false);
      history.push('/teams/members/upload');
    }
  }, [acceptedFiles]);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  return (
    <div className="upload-member-dialog w-100">
      <ConfirmDialog
        onCancel={() => {
          setShowUploadModal(false);
        }}
      >
        <span className="w-100">
          <CloudArrowUpGreen />
        </span>

        <div className="mt-3 mb-2 w-100 heading-confirm">
          <h5>Upload Template</h5>
        </div>
        <div className="uploadFileBorder mb-4 w-100 py-3">
          <div {...getRootProps({ className: 'dropzone w-100' })}>
            <input {...getInputProps()} id={'filename'} name={'filename'} />
            <div className="uploadFileIcon">
              <CloudArrowUpGrey />
            </div>
            <div className="uploadFileTextBorder">
              <span style={{ color: '#D28B28' }} className="uploadFileText1">
                Select a file
                <span style={{ color: '#586068' }}>&nbsp;or drag and drop</span>
              </span>
              <span className="uploadFileText2">.CSV or .XLSX file</span>
            </div>
          </div>
        </div>
      </ConfirmDialog>
    </div>
  );
};

export default UploadMemberDialog;
