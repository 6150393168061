import { useContext } from 'react';
import { Skeleton } from 'antd';
import { IntegrationsContext } from 'pages/Settings';

const Loader = () => (
  <div className="row">
    {Array.from({ length: 8 }).map((_, i) => (
      <Skeleton.Input
        key={i}
        active
        className="col-sm-3 mb-2 d-flex flex-wrap"
        size={248}
        style={{ borderRadius: 8, minWidth: '100%' }}
      />
    ))}
  </div>
);

const IntegrationOptions = () => {
  const {
    appList,
    onHandleConnectModal,
    fetchingApps,
    openCreateModal,
    openDeleteModal,
  } = useContext(IntegrationsContext);
  return (
    <div className="container integrations__list pl-0 pr-0">
      {fetchingApps ? (
        <Loader />
      ) : (
        <div className="row">
          {appList?.map((app) => {
            const ImgUrl = app?.imageUrl;
            return (
              <div className="col-sm-3 mb-3 d-flex flex-wrap" key={app.code}>
                <div
                  className="items"
                  onClick={app?.connected ? (event) => openCreateModal(event, app) : null}
                >
                  <div>{ImgUrl && <ImgUrl />}</div>
                  <div className="body">
                    <h6>{app.label}</h6>
                    <p>{app.description}</p>
                  </div>
                  <button
                    disabled={app?.status?.toLowerCase() !== 'active'}
                    className={
                      app?.status?.toLowerCase() !== 'active'
                        ? 'is-disabled'
                        : app?.connected
                        ? 'is-disconnect'
                        : ''
                    }
                    onClick={
                      app?.connected
                        ? (event) => openDeleteModal(event)
                        : (event) => onHandleConnectModal(event, app)
                    }
                  >
                    {app?.connected
                      ? 'Disconnect'
                      : app?.status?.toLowerCase() !== 'active'
                      ? 'Coming soon'
                      : 'Connect'}
                  </button>
                  {app?.connected ? <span className="connected">Connected</span> : null}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default IntegrationOptions;
