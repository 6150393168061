import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import React from 'react';

const DeclinedAlert = ({ onClose, reason }) => {
  return (
    <div>
      <div className="confirm-modal-overlay" onClick={onClose} />
      <div className="confirm-modal-wrapper position-relative">
        <div className="confirm-modal-body p-0">
          <div className="px-4 pt-3 w-100">
            <h2 className="text-center mb-2">Reason for decline</h2>
            <p className="text-start rounded p-2 pb-3 border">{reason}</p>
          </div>

          <div className="custom-modal_footer mt-4 d-flex w-100 gap-3">
            <CustomButton fullWidth className="confirm-btn" onClick={onClose}>
              Got it
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclinedAlert;
