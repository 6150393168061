import {
  FILES_PLOAD_CANCELLED,
  FILES_UPLOAD_CANCEL,
  FILES_UPLOAD_COMPLETED,
  FILES_UPLOAD_FAILURE,
  FILES_UPLOAD_PROGRESS,
  FILES_UPLOAD_REQUEST,
  FILES_UPLOAD_SUCCESS,
  REMOVE_FILES,
  RESET_FILES_UPLOAD_STATE,
  RETRY_FILES_UPLOAD,
  RESET_SPECIFIC_FLAG,
} from 'redux/reducers/ProgressUploadReducer';
import { v4 as uuidv4 } from 'uuid';

export const uploadFilesRequest = ({ acceptedFiles, name = 'file', scan = false }) => {
  const files = acceptedFiles.map((file) => {
    const newFile = new File([file], file.name, { type: file.type });
    newFile.id = uuidv4();

    return newFile;
  });
  return {
    type: FILES_UPLOAD_REQUEST,
    payload: { files, name, scan },
  };
};

export const uploadFileProgress = (file, progress) => ({
  type: FILES_UPLOAD_PROGRESS,
  payload: { file, progress },
});

export const uploadFileSuccess = (data, name) => ({
  type: FILES_UPLOAD_SUCCESS,
  payload: data,
  name,
});

export const uploadFileFailure = (file, error, name) => ({
  type: FILES_UPLOAD_FAILURE,
  payload: { file, error, name },
});

export const uploadFileCancel = (file, name) => ({
  type: FILES_UPLOAD_CANCEL,
  payload: { file, name },
});

export const uploadFileCancelled = (file, name) => ({
  type: FILES_PLOAD_CANCELLED,
  payload: { file, name },
});

export const retryUploadFile = (file, name, scan) => ({
  type: RETRY_FILES_UPLOAD,
  payload: { file, name, scan },
});

export const deleteFile = (id) => ({
  type: REMOVE_FILES,
  payload: { id },
});

export const fileUploadComplete = (payload) => ({
  type: FILES_UPLOAD_COMPLETED,
  payload,
});

export const resetUploadState = (payload) => ({
  type: RESET_FILES_UPLOAD_STATE,
  payload,
});

export const resetUploadFlag = (payload) => ({
  type: RESET_SPECIFIC_FLAG,
  payload,
});
