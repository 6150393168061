import MonoConnect from '@mono.co/connect.js';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Success from 'components/UI/ModalSaving/Success';
import { toastError } from 'components/UI/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addFundsToAccount } from 'redux/actions/CompaniesAction';
import { getAvailableBalance } from 'utils/helper';
const FundFromExistingAccount = ({
  handleBack,
  setMonoAuthCode,
  accounts,
  initiatePayment,
  verifyPayment,
  paymentVerified,
  paymentFailed,
  getReauthorizationCode,
  reauthorizationCode,
  destination,
  isLoading,
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [successfulFunding, setSuccessfulFunding] = useState(paymentVerified);
  const [failedFunding, setFailedFunding] = useState(paymentFailed);
  const [account, setAccount] = useState(null);
  const [currency, setCurrency] = useState('NGN');
  const [amount, setAmount] = useState(0);

  const balanceOptions = useMemo(
    () => getAvailableBalance(accounts ?? []).filter((item) => item !== null),
    [],
  );

  const location = useLocation();
  const [searchParams] = useState(new URLSearchParams(location.search));
  const dispatch = useDispatch();
  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onSuccess: ({ code }) => setMonoAuthCode(code),
      key: process.env.MONO_API_KEY || 'test_pk_qg208l2ni4kZ45Irlav4',
    });

    monoInstance.setup();
    return monoInstance;
  }, []);

  const setBankAccount = (account) => {
    const { requiresReauth } = account;
    if (requiresReauth) {
      getReauthorizationCode(account.value);
    } else setAccount(account);
  };

  const addFunds = (payload) => {
    if (!account?.value) return toastError('Please select a bank account');
    if (!amount) return toastError('Please enter an amount');
    if (account.value === destination)
      return toastError('Source cannot be the same as destination');

    dispatch(addFundsToAccount(payload));
  };

  const openPayment = () => {
    const payload = {
      currency,
      amount: amount * 100,
      account: account?.value,
      source: account?.value,
      destination,
    };
    if (account?.isLinked) initiatePayment(payload);
    else addFunds(payload);
  };

  useEffect(() => {
    if (reauthorizationCode) {
      monoConnect.reauthorise(reauthorizationCode);
    }
  }, [reauthorizationCode]);

  useEffect(() => {
    if (searchParams.get('funding') === 'true') {
      const status = searchParams.get('status');
      if (status !== 'successful') {
        setFailed(true);
      } else if (searchParams.get('reference')) {
        setIsVerifying(true);
        verifyPayment(searchParams.get('reference'));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setIsVerifying(false);
    if (paymentFailed) {
      setSuccessfulFunding(false);
      setFailedFunding(true);
    } else if (paymentVerified) {
      setFailedFunding(false);
      setSuccessfulFunding(true);
    }
  }, [paymentFailed, paymentVerified]);

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    return setAmount(rawValue);
  };

  const addFund = () => {
    return (
      <div className="beneficiaries">
        <h2 className="card-title w-100">Add funds</h2>
        <CustomSelect
          label="From which bank account?"
          name="account"
          placeholder="Select a bank account"
          onChange={(account) => setBankAccount(account)}
          value={account}
          options={balanceOptions}
          wrapperClass="mb-2"
        />

        <CustomInput
          label="How much do you want to fund ?"
          isAmount
          placeholder="0.00"
          type="text"
          name="amount"
          className="amount "
          value={amount}
          onChange={handleChange}
        />

        <div className="modal-footer border-top">
          <CustomButton
            onClick={handleBack}
            fullWidth={true}
            className="custom-button ghost-button"
          >
            Cancel
          </CustomButton>
          <CustomButton
            loading={isLoading}
            disabled={isLoading}
            onClick={openPayment}
            fullWidth={true}
            className="custom-button primary-button"
          >
            Continue
          </CustomButton>
        </div>
      </div>
    );
  };

  const VerifyingMessage = () => (
    <Success title="Thank you!" message="Your funding is being processed..." />
  );
  const SuccessMessage = () => (
    <Success title="Successful!" message="Your balance have been funded..." />
  );
  const FailureMessage = () => (
    <Success title="Failed!" message="Your funding was not successful, try again!" />
  );

  return (
    <>
      <div className="card-modal-body">
        {isVerifying
          ? VerifyingMessage()
          : successfulFunding
          ? SuccessMessage()
          : failedFunding
          ? FailureMessage()
          : addFund()}
      </div>
    </>
  );
};
export default FundFromExistingAccount;
