import {
  ChevronCellTemplate,
  getCharFromKey,
  isAlphaNumericKey,
  isKeyPrintable,
  isNavigationKey,
  keyCodes,
} from '@silevis/reactgrid';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { CircleDropdownIcon } from 'assets/icons';
import React from 'react';

export class CustomDropdownTemplate extends ChevronCellTemplate {
  constructor(getInputRef, characterLength) {
    super();
    this.inputRefs = {}; // Create an object to store refs by rowId
    this.getInputRef = getInputRef; // Store the function to forward the ref
    this.characterLength = characterLength;
  }

  render(cell, isInEditMode, onCellChanged) {
    const { rowId } = cell;

    // Create or reuse a ref for this specific row
    if (!this.inputRefs[rowId]) {
      this.inputRefs[rowId] = React.createRef();
    }

    const currentRef = this.inputRefs[rowId];

    // Pass the ref to the parent component
    if (this.getInputRef && currentRef.current) {
      this.getInputRef(rowId, currentRef.current);
    }

    if (!isInEditMode) {
      return (
        <div className="d-flex w-100 justify-content-between">
          <div className="d-flex gap-2">
            <Checkbox className="flex-shrink-0" />{' '}
            <span ref={currentRef} className="flex-grow-0 flex-shrink-1">
              {cell.text}
            </span>{' '}
          </div>
          {cell.hasChildren && (
            <div
              onClick={(e) => {
                cell.onExpand();
              }}
              className="flex-shrink-0"
              role="button"
            >
              <CircleDropdownIcon />
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        style={{ paddingLeft: `calc(${cell.indent}em + 2px)`, width: '95%' }}
        className="d-flex flex-wrap align-items-center gap-2"
      >
        <Checkbox onChanged className="flex-shrink-0" />{' '}
        <input
          className="custom-rg-input"
          ref={(input) => {
            input && input.focus();
          }}
          maxLength={this.characterLength}
          defaultValue={cell.text}
          onChange={(e) =>
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              false,
            )
          }
          onCopy={(e) => e.stopPropagation()}
          onCut={(e) => e.stopPropagation()}
          onPaste={(e) => e.stopPropagation()}
          onBlur={(e) => {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              !this.wasEscKeyPressed,
            );
            this.wasEscKeyPressed = false;
          }}
          onPointerDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
              e.stopPropagation();
          }}
        />
      </div>
    );
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt, key) {
    const char = getCharFromKey(key);

    if (!ctrl && isKeyPrintable(key) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({ ...cell, text: cell.text + char }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
}
