import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';

import {
  FETCH_BENEFICIARY_STATS_ERROR,
  FETCH_BENEFICIARY_STATS_REQUEST,
  FETCH_BENEFICIARY_STATS_SUCCESS,
  FETCH_CATEGORY_STATS_ERROR,
  FETCH_CATEGORY_STATS_REQUEST,
  FETCH_CATEGORY_STATS_SUCCESS,
  FETCH_VENDOR_STATS_ERROR,
  FETCH_VENDOR_STATS_SUCCESS,
  FETCH_VENDOR_STATS_REQUEST,
} from '../reducers/AnalyticsReducer';
import { toastError } from 'components/UI/toast';

async function getCategoryStats({ params }) {
  return await Axios.get(`categories/statistics`, { params });
}

function* handleGetCategoryStats({ payload }) {
  try {
    const response = yield call(getCategoryStats, payload);
    if (response) {
      yield put({ type: FETCH_CATEGORY_STATS_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_CATEGORY_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBeneficiaryStats({ params }) {
  return await Axios.get(`beneficiaries/statistics`, { params });
}

function* handleGetBeneficiaryStats({ payload }) {
  try {
    const response = yield call(getBeneficiaryStats, payload);
    if (response) {
      yield put({ type: FETCH_BENEFICIARY_STATS_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_BENEFICIARY_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getVendorStats({ params }) {
  return await Axios.get(`vendors/statistics`, { params });
}

function* handleGetVendorStats({ payload }) {
  try {
    const response = yield call(getVendorStats, payload);
    if (response) {
      yield put({ type: FETCH_VENDOR_STATS_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_VENDOR_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(FETCH_CATEGORY_STATS_REQUEST, handleGetCategoryStats),
  takeLatest(FETCH_BENEFICIARY_STATS_REQUEST, handleGetBeneficiaryStats),
  takeLatest(FETCH_VENDOR_STATS_REQUEST, handleGetVendorStats),
]);
