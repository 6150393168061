import { BankFilled, FileDoneOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { NigeriaCrest, UnitedStateCrest, ArrowCircleRight } from 'assets/icons';
import BanksIcons from 'assets/icons/banks';
import CurrencyFormat from 'react-currency-format';
import { FormattedCurrency, getCurrency } from 'utils/helper';
import ImgCard from 'components/UI/ImgCard';
import CustomPopover from 'components/UI/Popover';
import { DotsVertical } from 'assets/icons';
import { Skeleton } from 'antd';
import bankIcon from 'assets/icons/bank-icon2.svg';

import NigeriaFlag from 'assets/icons/nigeria-crest.png';
import UsaFlag from 'assets/icons/usa-crest.png';

const CURRENCY_TITLE = {
  NGN: 'NGN balance',
  USD: 'USD balance',
};

export const getIcon = (currency = 'NGN', size = '40') => {
  const FLAGS = {
    NGN: NigeriaFlag,
    USD: UsaFlag,
  };

  const flagSrc = FLAGS[currency];
  if (!flagSrc) return null;

  return (
    <img
      src={flagSrc}
      height={size}
      width={size}
      style={{ borderRadius: '50%' }}
      alt={`${currency} flag`}
    />
  );
};

export const ICONS = {
  NGN: <NigeriaCrest width="40" height="40" />,
  USD: <UnitedStateCrest width="40" height="40" />,
};

export const AccountCard = ({
  code,
  name,
  balance,
  number,
  currency,
  bankName,
  accountName,
  openBalance,
  openDetailsModal,
  openStatementModal,
  logo,
  isSubaccount = false,
  isDirectDebit = false,
  isDirectDebitActive = false,
  showOptions,
  onToggleOptions,
}) => {
  const maskValue = (value) => {
    const lastFourDigits = value?.toString()?.slice(-4) || '•••';
    return `••• ${lastFourDigits}`;
  };

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() =>
            openBalance(code, {
              name,
              balance,
              number,
              currency,
              bankName,
              accountName,
              code,
              isSubaccount,
            })
          }
        >
          <UnorderedListOutlined />
          View transfers
        </div>

        <div
          className="actionLink"
          onClick={(e) => {
            onToggleOptions(false);
            openDetailsModal(e);
          }}
        >
          <BankFilled />
          See account details
        </div>

        <div
          className="actionLink"
          onClick={(e) => {
            onToggleOptions(false);
            openStatementModal(e);
          }}
        >
          <FileDoneOutlined />
          Generate account Statement
        </div>
      </div>
    );
  };

  const Badge = () => (
    <div className="account-actions d-flex flex-wrap gap-1 align-items-center">
      {isDirectDebit && (
        <Tooltip
          placement="bottom"
          title={`Direct debit is ${isDirectDebitActive ? 'active' : 'not active'}`}
          key={'isDirectDebit'}
          color={'#000'}
        >
          <span className="account-badge">
            <span className="dd-dot"></span>
            <span> Direct Debit</span>
          </span>
        </Tooltip>
      )}
    </div>
  );

  return (
    <div
      className="account position-relative"
      onClick={() =>
        openBalance(code, {
          name,
          balance,
          number,
          currency,
          bankName,
          accountName,
          code,
          isSubaccount,
        })
      }
    >
      <div className="d-flex align-items-center gap-3 w-100">
        <ImgCard size="normal" fullWidth={false} initials={bankName?.charAt(0)}>
          <img
            onError={handleImgError}
            className="account-logo"
            src={BanksIcons[bankName?.toLowerCase()] ?? (logo || bankIcon)}
          />
        </ImgCard>

        <section className="card-info">
          <article>
            <div className="account-name">
              <span className="name">{name}</span>
              <Badge />

              <span className="icon-slide">
                <ArrowCircleRight />
              </span>
            </div>

            <div className="d-flex align-items-center gap-1">
              <span className="account-number">{`${bankName || '•••'} ${maskValue(
                number,
              )}`}</span>
              <span className="position-relative" style={{ bottom: 1 }}>
                {getIcon(currency, 12)}
              </span>
            </div>
          </article>

          <div className="account-balance d-flex gap-2 align-items-center">
            <CurrencyFormat
              prefix={getCurrency(currency)}
              value={balance / 100}
              displayType="text"
              isNumericString
              thousandSeparator={true}
              style={{ paddingTop: 4 }}
            />
          </div>
        </section>
      </div>

      <div className="position-absolute" style={{ right: 10 }}>
        <CustomPopover
          zIndex="1"
          content={<Actions />}
          showPopover={showOptions}
          clickOutside={() => onToggleOptions(false)}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onToggleOptions(true);
            }}
          >
            <DotsVertical />
          </div>
        </CustomPopover>
      </div>
    </div>
  );
};

export const CurrencyBalance = ({ loading, balance = { NGN: '', USD: '' } }) => {
  return (
    <>
      {Object.entries(balance)?.map(([key, value]) => (
        <div
          key={'account-summary' + key}
          className="d-flex align-items-center justify-content-between gap-2 font-bold mt-2"
          style={{
            backgroundColor: '#F5F5F4',
            padding: 20,
            width: 349,
            borderRadius: 10,
            height: 92,
            boxShadow: '0px 1px 2px 0px #1C19170D',
          }}
        >
          <div>
            <p
              style={{
                marginBottom: 0,
                color: '#79716B',
                fontWeight: 500,
                lineHeight: '20px',
                fontSize: '0.875rem',
              }}
            >
              {CURRENCY_TITLE[key]}
            </p>

            {loading ? (
              <Skeleton.Input
                active
                size={24}
                style={{ borderRadius: 4, minWidth: '10rem', marginTop: 4 }}
              />
            ) : (
              <FormattedCurrency
                value={value}
                prefix={getCurrency(key)}
                style={{
                  fontSize: 24,
                  color: '#1C1917',
                  fontWeight: 600,
                  lineHeight: '32px',
                }}
              />
            )}
          </div>

          <div className="balance-crest">{ICONS[key]}</div>
        </div>
      ))}
    </>
  );
};
