import React from 'react';
import { Spin } from 'antd';
import cs from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.scss';

const Loading = ({ size = 55, color = '#BFFF0B', isPage, className }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size, color }} spin />;

  return (
    <div
      className={cs(
        'd-flex align-items-center justify-content-center',
        {
          ['isPage']: isPage,
        },
        { [className]: className },
      )}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export const FullPageLoader = () => {
  return (
    <div className="loader-holder">
      <span className="loader">
        <span></span>
        <span></span>
      </span>
    </div>
  );
};

export default Loading;
