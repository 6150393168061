import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Get percentage of base
 * @param {Array} permissions
 * @param {Array} scopes
 * @returns {boolean} ture or false
 */
export const hasPermission = ({ permissions = [], scopes = [] }) => {
  const scopesMap = {};
  scopes?.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions?.some((permission) => scopesMap[permission]);
};

export const returnPermissions = ({ permissions = [], scopes = [] }) => {
  const scopesMap = {};
  scopes?.forEach((scope, index) => {
    scopesMap[scope] = index + 1; // Assigning index + 1 as priority
  });

  const matchedPermissions = permissions
    ?.filter((permission) => scopesMap[permission])
    .sort((a, b) => scopesMap[a] - scopesMap[b]); // Sorting based on scope order

  return matchedPermissions || [];
};

export const allPermissions = () => {
  const { user: { data } = {} } = useSelector(({ auth }) => auth);

  const { getRoles: { data: role, loading } = {} } = useSelector(({ roles }) => roles);

  const PERMISSIONS =
    role?.reduce((permissionsObj, { name, permissions }) => {
      permissionsObj[name] = permissions.map(({ name: permName }) => permName);
      return permissionsObj;
    }, {}) || [];

  const permissions = PERMISSIONS[data?.role?.name];
  const onboardingStatus = data?.user?.company?.onboardingStatus;
  const companyStatus = data?.user?.company?.status;
  const userStatus = data?.user?.status;
  const userRole = data?.user?.role?.toLowerCase();
  const isAdmin = hasPermission({ permissions, scopes: ['org-*'] });
  const beneficiaryCode = !isAdmin ? data?.user?.beneficiary : undefined;
  const isPremium =
    data?.user?.company?.paymentPlan?.name !== 'start' &&
    !data?.user?.company?.paymentPlan?.isFreeTrial;
  const isPermittedToPayBill = isAdmin || userRole === 'manager';

  return {
    permissions,
    onboardingStatus,
    userStatus,
    companyStatus,
    isAdmin,
    beneficiaryCode,
    userRole,
    isPremium,
    loading,
    isPermittedToPayBill,
  };
};

/**
 * Get percentage of base
 * @param {ChildNode} children
 * @param {Array} scopes
 * @returns {ChildNode}
 */
const AllowedTo = ({ children, scopes = [] }) => {
  const { permissions } = allPermissions();

  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted) return undefined;

  return <>{children}</>;
};

export default AllowedTo;
