import '../styles.scss';
import { ReactComponent as Error } from '../../../../assets/icons/error.svg';
import CustomButton from 'components/UI/CustomButton';
// import * as fs from 'fs';

const TryAgain = ({ goBackToSelectType }) => {
  return (
    <>
      <div className="d-flex justify-content-center pt-5">
        <div className="text-center" style={{ marginTop: '50%', marginBottom: '40px' }}>
          <Error style={{ height: '50px', width: 'auto' }} />
          <div className="onboardingSavedHeader" style={{ fontSize: '24px' }}>
            Sorry, it’s taking longer than expected
          </div>
          <div className="savingText">Please try again later</div>
        </div>
      </div>
      <div>
        <CustomButton
          onClick={goBackToSelectType}
          fullWidth={true}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          className="allSetButton"
          withoutBg={true}
        >
          Close
        </CustomButton>
      </div>
    </>
  );
};
export default TryAgain;
