import { useEffect, memo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Table from 'components/Table';
import BillDetail from '../BillDetail';
import TopBar from 'components/TopBar';
import BillsEmptyState from '../EmptyState';
import SearchLoader from '../SearchLoader';
import { Col, Row, Container } from 'react-bootstrap';

import { fetchDraftBills } from 'redux/actions/BillAction';

import { useDebounce } from 'hooks/useDebounce';
import { buildBillsTableData } from 'utils/helper';
import { billColumns } from '../../../../utils/mockData';
import { InvoiceStatusType } from 'components/FilterModal/FilterHelper';
import EmptyData from '../EmptyData';

const DraftBills = ({
  data,
  loading,
  handleDelete,
  isAdmin,
  BillActions,
  isPopoverOpen,
  setIsPopoverOpen,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [activeRow, setActiveRow] = useState({});
  const [isDrawer, setIsDrawer] = useState(false);
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filterData, setFilterData] = useState([...InvoiceStatusType]);

  const { meta: { page, total, hasMore, perPage, nextPage } = {}, bills = [] } =
    data || {};

  const rows = buildBillsTableData(bills, isAdmin);

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const debouncedValue = useDebounce(search, 700);

  const onHandleNextPage = (page) => {
    dispatch(fetchDraftBills({ perPage, page }));
  };

  const onHandlePreviousPage = (page) => {
    dispatch(fetchDraftBills({ perPage, page }));
  };

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchDraftBills({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchDraftBills({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleEdit = (code) => {
    if (code)
      history.push({
        pathname: `/expenses/bills/review/${code}`,
        search: '?isEdit=true',
      });
  };

  const handleRowClick = (row) => {
    setActiveRow(row);

    history.push({
      pathname: `/expenses/bills/review/${row?.code}`,
      search: '?isEdit=true',
    });
  };

  const handleAction = (event, action, data) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);

    if (action === 'delete') {
      handleDelete(data?.code);
    }
  };

  return (
    <>
      <Container className="px-0">
        <section>
          {loading ? (
            <SearchLoader />
          ) : (
            <TopBar
              showBarSearch
              searchVal={search}
              setSearchVal={setSearch}
              showFilter={false}
              handleFilterSelect={(updateVal) => {
                setFilterData(updateVal);
              }}
              filterData={filterData}
              inputPlaceholder="search"
              withDate
            />
          )}

          {!loading && !bills?.length ? (
            <>{filtered ? <EmptyData /> : <BillsEmptyState />}</>
          ) : (
            <Row className="pt-4 pb-3">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={billColumns}
                  pagination
                  data={rows}
                  onRowClick={handleRowClick}
                  hasCheckBox={false}
                  popoverAction={BillActions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => onHandleNextPage(nextPage)}
                  previousPage={() => onHandlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage || 1)}
                  loading={loading}
                />
              </Col>
            </Row>
          )}
        </section>
      </Container>

      <BillDetail
        isOpen={isDrawer}
        // handleDelete={sortDelete}
        // actionHandler={actionHandler}
        handleClose={() => setIsDrawer(false)}
        code={activeRow?.code}
      />
    </>
  );
};

export default memo(DraftBills);
