import CashList from 'assets/images/emptystate/cash-list.png';
import CashView from 'assets/images/emptystate/cash-view.png';
import EmptyState from 'components/UI/EmptyState';

import CustomButton from 'components/UI/CustomButton';
import AllowedTo from 'utils/AllowedTo';

import {
  CollectPayment,
  ConnectBankAccount,
  PaymentVendor,
} from 'assets/icons/empty-state-icons';

const CashEmptyStateData = ({ toggleHandler, view = 'list', disabled = false }) => {
  const handleSupport = () => {
    window.open('https://www.bujeti.com', '_blank');
  };

  const handleSupportPayment = () => {
    window.open('https://www.bujeti.com/bank-payments', '_blank');
  };
  const childrenData = [
    {
      title: 'Connect your bank account',
      body: 'Connect your primary bank account to Bujeti and create multiple sub-accounts.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <ConnectBankAccount />,
    },

    {
      title: 'Make payments to your vendors',
      body: 'Make secure and hassle-free payments to your vendors or colleagues',
      actionTitle: 'View more',
      action: handleSupportPayment,
      icon: <PaymentVendor />,
    },
    {
      title: view === 'list' ? 'Add a dollar account' : 'Add a subaccount',
      body: "Maintain control and compliance with your organization's policies and guidelines.",
      actionTitle: 'View more',
      action: handleSupport,
      icon: <CollectPayment />,
    },
  ];

  const mainData = {
    title: 'Review and analyse individual financial transactions with ease.',
    body: 'Manage your financial transactions, make single and batch payments, and set approval rules. See how it works here',
    image: view === 'list' ? CashList : CashView,
    action: () => (
      <AllowedTo scopes={['transaction-*', 'transaction-create']}>
        <CustomButton
          className="approval-empty-add-button"
          style={{ width: 250 }}
          onClick={toggleHandler}
          disabled={disabled}
        >
          {view === 'list' ? 'Add new account' : 'Send funds'}
        </CustomButton>
      </AllowedTo>
    ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default CashEmptyStateData;
