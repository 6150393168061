import {
  Document,
  Image,
  Link,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from 'components/UIHooks/JsPDF';

import { convertNaNToZero } from 'utils/helper';
import dayjs from 'dayjs';
import LogoIcon from '../../../assets/icons/Vector.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    padding: '10px 10px 60px',
  },

  header: {
    width: '100%',
    backgroundColor: '#fbf5ec',
    borderRadius: 12,
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalContainer: {
    borderTop: `1px solid #1C1917`,
    width: '242px',
    marginTop: 100,
    marginBottom: 80,
  },
  invoiceTitle: {
    fontWeight: 800,
    fontSize: 10,
    color: '#1C1917',
  },
  invoiceSubTitle: {
    fontWeight: 600,
    fontSize: 10,
    color: '#57534E',
  },
  dFlex: {
    display: 'flex',
  },
  topSectionContainer: {
    padding: '20px 50px 69px 50px',
  },
  flexCol: {
    flexDirection: 'column',
  },
  flexRow: {
    flexDirection: 'row',
  },

  noMargin: {
    marginBottom: 0,
  },
  title: {
    color: '#000',
    fontSize: '12px',
    marginBottom: 5,
  },
  image: {
    width: 150,
    height: 150,
  },
  rightAlign: {
    float: 'right',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  mt4: {
    paddingTop: 20,
  },
  mt3: {
    paddingTop: 10,
  },
  mt1: {
    paddingTop: 4,
  },
  p16: {
    padding: 16,
  },
  px2: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  px20: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  lightText: {
    color: '#586068',
    fontSize: '10px',
    fontWeight: 200,
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#EAECF0',
    marginTop: 50,
    marginBottom: 34,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerText: {
    color: '#121722',
    fontSize: 12,
    fontWeight: 400,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    width: '35%', // Adjust the width as needed
    margin: 5,
    fontSize: 10,
  },
  alignLeft: {
    textAlign: 'left',
  },

  borderB: { borderBottom: '0.5px', borderColor: '#1C1917' },
  main: {
    position: 'relative',
  },
  footerStyles: {
    marginTop: 'auto',
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    right: 40,
  },
  headerConst: {
    textAlign: 'center',
    marginBottom: 10,
  },
  imageContainer: {
    width: 60,
    height: 30,
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  link: {
    color: '#D28B28',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const FormatAmount = (amount) => {
  return Number(amount).toLocaleString();
};

const handleNumberFormatting = (currency = 'NGN', value) => {
  return `${currency} ${FormatAmount(convertNaNToZero(value / 100).toFixed(2))}`;
};

const InvoicePdf = ({ data }) => {
  const isInstalment = !!data?.installments?.length && data?.amountRemaining;

  return (
    <Document style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
      <Page size="A4" style={styles.page}>
        <Header data={data} />
        <Main style={{ flex: 1 }}>
          <View>
            {isInstalment ? (
              <View
                style={[
                  styles.dFlex,
                  styles.flexCol,
                  { margin: '0 18px', marginTop: 18, alignItems: 'flex-end' },
                ]}
              >
                <View>
                  <Text
                    style={{
                      color: '#79716b',
                      fontSize: 10,
                      fontWeight: 700,
                    }}
                  >
                    Balance due{' '}
                  </Text>
                </View>

                <Text
                  style={{
                    marginTop: 6,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#1c1917',
                  }}
                >
                  {handleNumberFormatting(data?.currency, data?.amountRemaining)}
                </Text>
              </View>
            ) : (
              <span></span>
            )}
          </View>

          <View>
            <View>
              <View style={{ paddingTop: 44 }}>
                <Table data={data} />
              </View>
              <View style={styles.rightAlign}>
                <TotalContainer data={data} />
              </View>
            </View>
          </View>
        </Main>

        <View style={styles.footer}>
          <PdfFooter data={data} />
          <View style={[styles.dFlex, styles.center]}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Text
                style={[styles.footerText, styles.invoiceSubTitle, { marginTop: '5px' }]}
              >
                Powered by{' '}
                <Link style={styles.link} src="https://www.bujeti.com">
                  <Text>Bujeti.com</Text>
                </Link>{' '}
                - Take control of your finances today!
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Header = ({ data }) => {
  return (
    <View style={styles.headerConst}>
      <View
        style={[
          styles.dFlex,
          styles.flexRow,
          styles.spaceBetween,
          styles.px2,
          styles.p16,
        ]}
      >
        <View>
          <HeaderInfo title="Invoice" value={data?.code} css={styles.rightAlign} />
        </View>

        <Image
          src={
            data?.company?.logo
              ? `data:${data?.type};base64,${data?.base64Data}`
              : LogoIcon
          }
          style={{
            height: data?.company?.logo ? data?.newHeight : '40px',
          }}
          alt="logo"
        />
      </View>
      <View style={[styles.header]}>
        <View style={[styles.dFlex, styles.flexRow, styles.spaceBetween]}>
          <View style={{ maxWidth: '180px' }}>
            {data?.title && (
              <InvoiceInfo
                title="Title/Purchase order"
                value={data?.title}
                css={{ marginBottom: '10px' }}
              />
            )}

            <InvoiceInfo
              title="Issue date"
              value={dayjs(data?.created_at).format('DD MMM, YYYY')}
            />
            <InvoiceInfo
              title="Due Date"
              value={
                data?.due_date ? dayjs(data?.due_date).format('DD MMM, YYYY') : 'N/A'
              }
              css={{ marginTop: '10px' }}
            />
          </View>

          <View style={{ maxWidth: '180px' }}>
            <Text style={[styles.invoiceTitle]}>From</Text>
            <Text style={[styles.invoiceSubTitle, styles.mt1]}>
              {data?.company?.name}
            </Text>
            <Text style={[styles.lightText, styles.mt1]}>
              {data?.company?.contactEmail}
            </Text>
            <Text style={[styles.lightText, styles.mt1]}>
              {data?.company?.contact_phone}
            </Text>
          </View>

          <View style={{ maxWidth: '180px' }}>
            <Text style={[styles.invoiceTitle]}>Billed to</Text>
            <Text style={[styles.invoiceSubTitle, styles.mt1]}>
              {data?.customer?.name}
            </Text>
            <Text style={[styles.lightText, styles.mt1]}>{data?.customer?.email}</Text>
            <Text style={[styles.lightText, styles.mt1]}>
              {data?.customer?.phoneNumber?.localFormat}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const InvoiceInfo = ({ title, value, css }) => {
  return (
    <View style={[css]}>
      <Text style={[styles.invoiceTitle]}>{title}</Text>
      <Text style={[styles.invoiceSubTitle, styles.mt1]}>{value}</Text>
    </View>
  );
};

const PdfFooter = ({ data }) => {
  const selectBank =
    data?.customer?.bankAccounts.length && data?.customer?.bankAccounts[0];
  const BankAccount = typeof selectBank === 'object' && selectBank !== null;
  return (
    <View
      style={[
        styles.dFlex,
        styles.flexRow,
        styles.spaceBetween,
        styles.px2,
        styles.footerStyles,
      ]}
    >
      {data?.description && (
        <View style={{ maxWidth: 220 }}>
          <Text style={{ fontSize: 10, color: '#1C1917', marginBottom: 3 }}>
            Notes / payment terms:
          </Text>
          <Text style={{ fontSize: 10, color: '#57534E' }}>{data?.description}</Text>
        </View>
      )}
      {(data?.bankDetails || BankAccount) && (
        <View style={{ width: 220 }}>
          <Text style={{ fontSize: 10, color: '#1C1917', marginBottom: 3 }}>
            Bank Details:
          </Text>
          {BankAccount ? (
            <>
              <Text style={{ fontSize: 10, color: '#57534E', marginBottom: 3 }}>
                Account Name: {selectBank.accountName}
              </Text>
              <Text style={{ fontSize: 10, color: '#57534E', marginBottom: 3 }}>
                Bank Name: {selectBank.bankName}
              </Text>
              <Text style={{ fontSize: 10, color: '#57534E' }}>
                Account Number: {selectBank.number}
              </Text>
            </>
          ) : (
            <Text style={{ fontSize: 10, color: '#57534E' }}>{data?.bankDetails}</Text>
          )}
        </View>
      )}
    </View>
  );
};

const HeaderInfo = ({ title, value }) => {
  return (
    <View>
      <Text
        style={[
          styles.invoiceTitle,
          {
            fontSize: 28,
            margin: '0px',
            fontWeight: 800,
            lineHeight: '0px',
            color: '#1C1917',
          },
        ]}
      >
        {title}
      </Text>
      <Text style={([styles.invoiceSubTitle], { fontSize: '10px' })}>{value}</Text>
    </View>
  );
};

const FlexWrapper = ({ children }) => {
  return (
    <View style={[styles.dFlex, styles.flexRow, styles.spaceBetween, styles.mt3]}>
      {children}
    </View>
  );
};

const Table = ({ data }) => {
  const isRecurring = data?.code?.split('_')[0] === 'sci';
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.borderB]}>
        <View style={[styles.tableCell, styles.alignLeft, { width: '50%' }]}>
          <Text style={[styles.invoiceTitle]}>Item</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft]}>
          <Text style={[styles.invoiceTitle]}>Qty</Text>
        </View>

        <View style={[styles.tableCell, { width: '35%' }]}>
          <Text style={[styles.invoiceTitle]}>Unit Price</Text>
        </View>

        <View style={[styles.tableCell, { width: '25%', textAlign: 'right' }]}>
          <Text style={[styles.invoiceTitle]}>Amount excl. Tax</Text>
        </View>
      </View>

      {data?.invoiceProducts?.map((datum, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCell, styles.alignLeft, { width: '50%' }]}>
            <Text>{datum?.product?.name}</Text>
          </View>

          <View style={[styles.tableCell, styles.alignLeft]}>
            <Text>{datum?.quantity}</Text>
          </View>

          <View style={[styles.tableCell, styles.alignLeft, { width: '35%' }]}>
            <Text>{handleNumberFormatting(data?.currency, datum?.product?.price)}</Text>
          </View>

          <View style={[styles.tableCell, { width: '25%', textAlign: 'right' }]}>
            {isRecurring ? (
              <Text>
                {handleNumberFormatting(
                  data?.currency,
                  datum?.unitPrice * datum?.quantity,
                )}
              </Text>
            ) : (
              <Text>{handleNumberFormatting(data?.currency, datum?.amount)}</Text>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const TotalContainer = ({ data }) => {
  const isRecurring = data?.code?.split('_')[0] === 'sci';

  const subTotal = data?.invoiceProducts?.reduce((accumulator, currentValue) => {
    if (isRecurring) {
      return accumulator + currentValue?.unitPrice * currentValue?.quantity;
    } else {
      return accumulator + currentValue?.amount;
    }
  }, 0);

  const handleAmount = (payment) => {
    const { type, percentage, amount } = payment;
    if (type === 'percentage') {
      return FormatAmount((((percentage / 100) * data.amount) / 100).toFixed(2));
    }

    return FormatAmount(amount / 100);
  };

  const discount =
    data?.discount_type === 'percentage'
      ? (data?.discount / 100) * subTotal
      : data?.discount;

  return (
    <View style={styles.totalContainer}>
      <FlexWrapper>
        <Text style={styles.invoiceSubTitle}>Subtotal excl. Tax</Text>
        <Text style={styles.invoiceSubTitle}>
          {`${data?.currency}${FormatAmount(convertNaNToZero(subTotal / 100))}`}
        </Text>
      </FlexWrapper>

      <FlexWrapper>
        <Text style={styles.invoiceSubTitle}>
          Discount{' '}
          {data?.discount_type === 'percentage' && <Text> ({data?.discount}%)</Text>}
        </Text>

        <Text style={styles.invoiceSubTitle}>
          <Text>- </Text>
          {handleNumberFormatting(data?.currency, discount)}
        </Text>
      </FlexWrapper>

      <View style={{ paddingBottom: 6 }}>
        <FlexWrapper>
          <Text style={styles.invoiceSubTitle}>
            VAT <Text>({data?.vat}%)</Text>{' '}
          </Text>
          <Text style={styles.invoiceSubTitle}>
            {handleNumberFormatting(data?.currency, data?.vatAmount ?? 0)}
          </Text>
        </FlexWrapper>
      </View>

      <View style={{ borderTop: `1px solid #1C1917` }}> </View>

      <FlexWrapper>
        <Text
          style={[
            styles.invoiceSubTitle,
            { fontSize: 10, color: '#1C1917', fontWeight: 800 },
          ]}
        >
          Total incl. Tax
        </Text>

        <Text
          style={[
            styles.invoiceSubTitle,
            { fontSize: '10px', color: '#1C1917', fontWeight: 600 },
          ]}
        >
          {handleNumberFormatting(data?.currency, data?.amount)}
        </Text>
      </FlexWrapper>

      <View style={{ marginTop: 16 }}>
        {data?.installments?.map((payment, index) => (
          <View key={index} style={{ marginBottom: 4 }}>
            <FlexWrapper>
              <View>
                <Text
                  style={[
                    styles.invoiceSubTitle,
                    { fontSize: 10, color: '#1C1917', fontWeight: 500 },
                  ]}
                >
                  Payment {index + 1}
                </Text>

                <>
                  {payment?.status === 'paid' ? (
                    <Text
                      style={[
                        styles.invoiceSubTitle,
                        { fontSize: 10, color: '#79716B', marginTop: 4 },
                      ]}
                    >
                      Paid on {dayjs(payment?.paid_on).format('DD MMM, YYYY')}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.invoiceSubTitle,
                        { fontSize: 10, color: '#79716B', marginTop: 4 },
                      ]}
                    >
                      Due on {dayjs(payment?.due_date).format('DD MMM, YYYY')}
                    </Text>
                  )}
                </>
              </View>

              <Text
                style={[
                  styles.invoiceSubTitle,
                  { fontSize: 10, color: '#57534E', fontWeight: 500 },
                ]}
              >
                {`${data?.currency} ${handleAmount(payment)}`}
              </Text>
            </FlexWrapper>
          </View>
        ))}
      </View>
    </View>
  );
};

const Main = ({ children }) => {
  return <View style={[styles.px20, styles.main]}>{children}</View>;
};

export const PDF = () => (
  <PDFViewer style={{ width: '1024px', height: '1024px' }}>
    <InvoicePdf />
  </PDFViewer>
);

export default InvoicePdf;
