import {
  DeleteUserIcon,
  DisableUserIcon,
  EditPencilIcon,
  EnableUserIcon,
  MessageIcon,
} from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import DeleteDialog from 'components/DeleteDialog';
import Table from 'components/Table';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchApprovalRules } from 'redux/actions/ApprovalAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildBeneficiariesTableData } from 'utils/helper';
import { columnsBeneficiaries } from 'utils/mockData';
import {
  deleteBeneficiary,
  getBeneficiaries,
  resendBeneficiaryInvite,
  updateBeneficiaries,
} from '../../../redux/actions/BeneficiariesAction';
import BeneficiariesTableModal from './BeneficiariesTableModal';

const BeneficiariesTable = ({ beneficiaries = [], meta, filteredQuery }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectBeneficiaries, setSelectBeneficiaries] = useState(null);
  const [beneficiariesTableData, setBeneficiariesTableData] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const { page, total, hasMore, perPage, nextPage } = meta;

  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);

  const { permissions } = allPermissions();
  const canEdit = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-edit', 'employee-create'],
  });

  const {
    updateBeneficiaries: { loading: isUpdating, success: isUpdatingSuccess },
    deleteBeneficiaries: { loading: isDeleting, success: isDeletedSuccess },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const handleRowClick = (row) => {
    if (row.status.value.toLowerCase() === 'deleted') return;
    history.push(`/teams/members/profile/${row.code}`);
  };

  useEffect(() => {
    if (beneficiaries.length) {
      setBeneficiariesTableData(buildBeneficiariesTableData(beneficiaries));
    }
  }, [beneficiaries]);

  const handlePreviousPage = (page) => {
    dispatch(
      getBeneficiaries({ perPage, page, ...filteredQuery, excludeInactiveBudgets: true }),
    );
  };

  const handleNextPage = (page) => {
    dispatch(
      getBeneficiaries({ perPage, page, ...filteredQuery, excludeInactiveBudgets: true }),
    );
  };

  const actionHandler = (event, type, value) => {
    const { code } = value;
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    if (type) setType(type.toLowerCase());
    if (type === 'invite') return dispatch(resendBeneficiaryInvite(code));
    if (type === 'active') return setConfirmModal(true);
    if (type === 'inactive' || type === 'deleted') return setDeleteModal(true);
    if (type === 'edit') return setSelectBeneficiaries(value);
  };

  const tableColumn = columnsBeneficiaries.filter((item) =>
    canEdit ? item : item.Header !== 'Actions',
  );

  const closeModal = () => {
    setIsPopoverOpen(false);
    setDeleteModal(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    if (isDeletedSuccess || isUpdatingSuccess) {
      closeModal();
      dispatch(fetchApprovalRules());
    }
  }, [isDeletedSuccess, isUpdatingSuccess]);

  const Actions = ({ list }) => {
    const status = list?.status?.value.toLowerCase();
    const actionType =
      status === 'inactive' ? 'active' : status === 'active' && 'inactive';

    const isMe = data.user?.code === list?.user?.code;
    return (
      <div className="actions-dialog">
        {!['deleted'].includes(status)}
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'edit', list)}
        >
          <EditPencilIcon className="mr-4" /> Edit Member
        </div>

        {['invited', 'pending'].includes(status) && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'invite', list)}
          >
            <MessageIcon className="mr-4" /> Resend Invite
          </div>
        )}

        {!['invited', 'deleted', 'pending'].includes(status) && !isMe && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, actionType, list)}
          >
            {status === 'active' ? (
              <>
                <DisableUserIcon className="mr-4" /> Disable Member
              </>
            ) : (
              <>
                <EnableUserIcon className="mr-4" /> Enable Member
              </>
            )}
          </div>
        )}

        {!['deleted'].includes(status) && !isMe && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={(event) => actionHandler(event, 'deleted', list)}
          >
            <DeleteUserIcon className="mr-4" /> Delete Member
          </div>
        )}
      </div>
    );
  };

  const handleDisable = () => {
    const code = selectedOption?.code;
    if (type === 'deleted') {
      dispatch(deleteBeneficiary({ code }));
    } else if (type === 'inactive' || type === 'active') {
      const payload = { code, status: type };
      dispatch(updateBeneficiaries(payload));
    } else if (type === 'invite') {
      dispatch(resendBeneficiaryInvite({ code }));
    }
  };

  useEffect(() => {
    if (isUpdatingSuccess) {
      setIsPopoverOpen(false);
    }
  }, [isUpdatingSuccess]);

  return (
    <div>
      <Container className="px-0">
        <Row className="py-4">
          <Col xs={12} className="mb-4 spaced-table">
            <Table
              columns={tableColumn}
              data={beneficiariesTableData}
              pagination
              hasMore={hasMore}
              hasCheckBox={false}
              currentPage={page}
              nextPage={() => handleNextPage(nextPage)}
              previousPage={() => handlePreviousPage(page - 1)}
              totalPage={Math.ceil(total / perPage)}
              onRowClick={handleRowClick}
              onClick={(row) => setSelectBeneficiaries(row)}
              type="budgets"
              popoverAction={Actions}
              popoverState={isPopoverOpen}
              setPopoverState={setIsPopoverOpen}
            />
          </Col>
        </Row>
      </Container>

      <BeneficiariesTableModal
        selectBeneficiaries={selectBeneficiaries}
        setSelectBeneficiaries={setSelectBeneficiaries}
      />
      {deleteModal && (
        <DeleteDialog
          title={`${
            type === 'deleted' ? 'Delete member' : type === 'inactive' && 'Disable member'
          }`}
          subTitle={`Are you sure you want to ${
            type === 'deleted' ? 'delete' : type === 'inactive' && 'disable'
          } this member?`}
          onCancel={closeModal}
          onDelete={handleDisable}
          deleteText={`${
            type === 'deleted' ? 'Delete' : type === 'inactive' && 'Disable'
          } `}
          isLoading={isUpdating || isDeleting}
        />
      )}

      {confirmModal && (
        <Modal show={true} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title="Enable this member"
            subTitle="Are you sure you want to enable this member?"
            onConfirm={handleDisable}
            onCancel={closeModal}
            isDeleteDialog={false}
            loading={isUpdating}
          />
        </Modal>
      )}
    </div>
  );
};
export default BeneficiariesTable;
