import { CloseOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import '../styles.scss';

const LinkAccountModal = ({ isOpen, handleClose, isLoading }) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();

  const {
    addBeneficiaryBank: { loading, success },
    getBeneficiaryBank: { data = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  const getLocalData = JSON.parse(localStorage.getItem('overviewInfo')) || {};
  const [getLocalStorage, setGetLocalStorage] = useState();
  const [kycBank, setKycBank] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
    checked: false,
  });
  const [isAccountSet, setIsAccountSet] = useState(false);

  const handleSubmit = () => {
    if (!kycBank.bankName) return toastError('Please select a bank');
    if (!kycBank.accountName) return toastError('Please enter your account name');
    if (!kycBank.accountNumber) return toastError('Please enter your account number');

    const payload = {
      bankCode: kycBank.bankName.value,
      bankName: kycBank.bankName.label,
      accountName: kycBank.accountName,
      number: kycBank.accountNumber,
      defaultBank: kycBank.checked,
    };
    dispatch(addBeneficiaryBank(payload));
  };

  const handleChangeCheckBox = (checked) => {
    setKycBank({ ...kycBank, checked });
  };

  useEffect(() => {
    if (getLocalStorage)
      setGetLocalStorage({
        ...getLocalStorage,
        bankInfo: {
          ...getLocalStorage.bankInfo,
          ...kycBank,
        },
      });
  }, [kycBank]);

  useEffect(() => {
    if (getLocalStorage) {
      localStorage.setItem('overviewInfo', JSON.stringify(getLocalStorage));
    }
  }, [getLocalStorage]);

  useEffect(() => {
    setGetLocalStorage(getLocalData);
    if (getLocalData) {
      if (Object.keys(getLocalData).length > 0) {
        if (Object.keys(getLocalData?.bankInfo).length > 0)
          setKycBank(getLocalData?.bankInfo);
      }
    }
  }, []);

  const handleChange = ({ target: { name, value, validity } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setKycBank({ ...kycBank, [name]: value });
    return setKycBank({ ...kycBank, [name]: value });
  };

  // const kycSuccess = () => {
  //   if (loading) {
  //     return <Saving message="Saving account information, please wait..." />;
  //   } else if (success && !loading) {
  //     return (
  //       <Success
  //         title="New account added!"
  //         message="Your account has been added successfully"
  //       />
  //     );
  //   }
  // };

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [success]);

  useEffect(() => {
    if (kycBank.accountNumber.length === 10 && kycBank.bankName.value) {
      const { accountNumber, bankName } = kycBank;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }
    if (kycBank.accountNumber.length < 10) {
      setIsAccountSet(false);
      setKycBank({ ...kycBank, accountName: undefined });
    }
  }, [kycBank.accountNumber, kycBank.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setKycBank({ ...kycBank, accountName: accName.account_name });
      setIsAccountSet(true);
    }
    if (accountNameError) setKycBank({ ...kycBank, accountName: undefined });
    if (accountNameLoading) setKycBank({ ...kycBank, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  const bankInputs = () => {
    return (
      <div className="information-wrapper">
        <h2 className="card-title w-100">Bank information</h2>
        <div className="form-normal">
          <Row className="mb-3  mt-3">
            <CustomSelect
              label="Bank"
              name="bankName"
              placeholder="Select Bank"
              onChange={(val) => setKycBank({ ...kycBank, bankName: val })}
              value={kycBank.bankName}
              options={allBanks}
              onMenuClose={onMenuCloseBanks}
              onInputChange={handleGetBankOnChange}
              isDisabled={loadingBanks && !bankValuedebounced}
              isLoading={loadingBanks && !bankValuedebounced}
            />
          </Row>

          <Row className="mb-3">
            <div className={classNames('relative', { ['mb-5']: visible })}>
              <CustomInput
                type="text"
                label="Account number"
                placeholder="Enter number"
                name="accountNumber"
                onChange={handleChange}
                value={kycBank.accountNumber}
                maxLength="10"
                pattern="[0-9]*"
              />
              <AccountName
                setValue={(value) => setKycBank({ ...kycBank, accountName: value })}
                visible={visible}
                accountNameError={accountNameError}
                accountNameLoading={accountNameLoading}
              />
            </div>
          </Row>

          <Row className="mb-3">
            <CustomInput
              type="text"
              label="Account name"
              placeholder="Add account name"
              name="accountName"
              onChange={handleChange}
              value={kycBank.accountName}
              maxLength="50"
              pattern="[a-zA-Z -]*"
              disabled
            />
          </Row>
          <Row className="mb-5">
            <Checkbox
              className="table-checkbox"
              onChange={({ target: { checked } }) => handleChangeCheckBox(checked)}
            >
              Make this my primary bank account
            </Checkbox>
          </Row>
        </div>

        <div className="modal-footer mt-3">
          <CustomButton
            onClick={handleClose}
            disabled={loading}
            fullWidth={true}
            className="custom-button ghost-button"
          >
            Cancel
          </CustomButton>
          <CustomButton
            fullWidth={true}
            className="custom-button primary-button"
            onClick={handleSubmit}
            disabled={loading || !isAccountSet}
            loading={loading}
          >
            Save
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal show={isOpen} onClose={handleClose}>
        <div className="content px-md-0 py-md-0 px-3 py-4">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">
            {loading ? (
              <Saving message="Saving account information, please wait..." />
            ) : (
              bankInputs()
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default LinkAccountModal;
