import ItemInfo from 'components/TransactionModal/itemInfo';
import pdfImage from 'assets/images/pdfIcon.png';
import xlsxIcon from 'assets/images/xlsxIcon.png';
import mp4Icon from 'assets/images/mp4IconL.png';
import mp3Icon from 'assets/images/mp3IconL.png';
import FileUpload from 'components/UI/FileUpload';
import { useEffect } from 'react';
import classNames from 'classnames';
import { transactionAcceptedFiles, transactionFileSupported } from 'utils/helper';

const getImageSource = (item) => {
  if (item?.name.includes('pdf')) return pdfImage;
  if (item?.name.includes('mp4')) return mp4Icon;
  if (item?.name.includes('mp3')) return mp3Icon;
  if (item?.name.includes('csv')) return xlsxIcon;
  if (item?.name.includes('xlsx')) return xlsxIcon;
  return item?.url;
};

/**

 * @param {array} receiptAssets: array of object consisting of name, url
 * @param {function} viewImgUrl: a callback function to open the receipt viewer
 * @returns
 */
const NewReceiptUpload = ({
  receiptAssets,
  viewImgUrl,
  removeFile,
  addMore,
  onChange,
  loading,
}) => {
  useEffect(() => {
    if (receiptAssets?.length) viewImgUrl(receiptAssets[0], 'receipt');
  }, [receiptAssets?.length]);

  return (
    <div className={classNames('receipt-asset-wrapper')}>
      {receiptAssets?.map((item, index) => (
        <div
          style={{ marginBottom: '-12px' }}
          className={
            item?.name?.includes('pdf') || item?.name?.includes('xlsx')
              ? 'receipt-asset pdf-small lg'
              : 'receipt-asset lg'
          }
          key={index}
          onClick={() => viewImgUrl(item, 'receipt')}
        >
          <img src={getImageSource(item)} alt={item?.name} className="lg" />
        </div>
      ))}
      <div style={{ marginTop: '-16px', marginBottom: '-12px' }}>
        <FileUpload
          name="file"
          onChange={onChange}
          multiple
          removeFile={removeFile}
          roundSize="lg"
          wrapperClass="new-receipt"
          isRounded={true}
          uploadingFile={loading}
          supportType={transactionFileSupported}
          acceptedFile={transactionAcceptedFiles}
        />
      </div>
    </div>
  );
};

export default NewReceiptUpload;
