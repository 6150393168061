import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useEffect, useRef, useState } from 'react';
import { toastError } from 'components/UI/toast';

import JSZip from 'jszip';
import { format } from 'date-fns';
import { Tooltip } from 'antd';
import { Controller, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon-borderless.svg';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

import classNames from 'classnames';
import './assetViewer.styles.scss';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';

const AssetViewer = ({ data, selectedAsset, singleData, containerClass = '' }) => {
  if (!data?.length || !selectedAsset) return <div />;
  const sliderRef = useRef(null);
  const [controlledSwiper, setControlledSwiper] = useState(0);
  const [active, setActive] = useState({});
  const [loading, setloading] = useState(false);

  let list = data;

  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    modules: [Navigation, Controller],
    effect: 'fade',
    navigation: {
      nextEl: '.image-swiper-button-next',
      prevEl: '.image-swiper-button-prev',
      disabledClass: 'swiper-button-disabled',
    },
  };

  // useEffect(() => {
  //   if (data?.length) {
  //     setList(data);
  //   }
  // }, [data]);

  useEffect(() => {
    if (list && selectedAsset) {
      setActive(selectedAsset);
      const index = findItemByIndex(list, selectedAsset?.code, 'code');
      setControlledSwiper(index);
      sliderRef.current.swiper.slideTo(index);
    }
  }, [list, selectedAsset]);

  const findItemByIndex = (arrayList, itemToFind, keyToUse) => {
    return arrayList?.findIndex((item) => item[keyToUse] === itemToFind);
  };

  const handlePrev = () => {
    if (!sliderRef.current) return;
    if (controlledSwiper <= 0) {
      setActive(list[controlledSwiper]);
      sliderRef.current.swiper.slideTo(0);
      return;
    } else {
      setControlledSwiper(controlledSwiper - 1);
      sliderRef.current.swiper.slideTo(controlledSwiper);
      setActive(list[controlledSwiper]);
    }
  };

  const handleNext = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideTo(controlledSwiper);
    setActive(list[controlledSwiper]);
    const nextFileIndex = controlledSwiper >= list.length - 1 ? 0 : controlledSwiper + 1;
    setControlledSwiper(nextFileIndex);
  };

  const updateActiveSlide = () => {
    sliderRef.current.swiper.slideTo(controlledSwiper);
    setActive(list[controlledSwiper]);
    setControlledSwiper(controlledSwiper + 1);
  };

  const handleDownload = async () => {
    try {
      setloading(true);
      if (list.length > 1) {
        await handleMultiples();
      } else {
        await handleSingle();
      }
    } catch (_err) {
      toastError('Download failed, Please try again!');
    } finally {
      setloading(false);
    }
  };

  const handleSingle = async () => {
    try {
      const response = await fetch(`${active?.url}`, { cache: 'no-store' });
      const blobUrl = URL.createObjectURL(await response.blob());

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = active?.name;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (err) {
      throw err;
    }
  };

  const handleMultiples = async () => {
    try {
      const responses = await Promise.all(
        list.map(async ({ url, name }) => {
          const response = await fetch(url, { cache: 'no-store' });
          return { name, blob: response.blob() };
        }),
      );

      const zip = new JSZip();
      responses.forEach(({ name, blob }) => zip.file(name, blob));
      const content = await zip.generateAsync({ type: 'blob' });

      const blobUrl = URL.createObjectURL(content);
      const link = document.createElement('a');
      const formattedDate = format(new Date(singleData?.created_at), 'dd/MM/yyyy');
      const fileName = `${singleData?.type}-${singleData?.user?.firstName}-${singleData?.user?.lastName}-${formattedDate}`;

      link.href = blobUrl;
      link.download = `${fileName}.zip`;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (err) {
      throw err;
    }
  };

  return (
    <section className="asset-viewer">
      <header className="head-region">
        <h6>{active?.name} </h6>
        <Tooltip
          placement="right"
          title={`Download file${list.length > 1 ? 's' : ''}`}
          key="download file"
          color={'#000'}
        >
          <DownloadIcon className="download-icon" onClick={handleDownload} />
        </Tooltip>
      </header>
      <div
        className={classNames('asset-container', { [containerClass]: containerClass })}
      >
        <Swiper
          {...params}
          controller={{ control: controlledSwiper }}
          onSlideChange={(e) => setControlledSwiper(e.realIndex)}
          slidesPerView={1}
          onSwiper={(swiper) => console.log()}
          ref={sliderRef}
        >
          {list.map((datum) => {
            const uniqueKey = `${datum?.code}-${datum?.name}`;

            return (
              <SwiperSlide key={uniqueKey}>
                {datum?.name?.includes('pdf') ? (
                  <Worker
                    key={`worker-${uniqueKey}`}
                    workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"
                  >
                    <Viewer fileUrl={active?.url} plugins={[]} />
                  </Worker>
                ) : datum?.name?.includes('xls') || datum?.name?.includes('xlsx') ? (
                  <DocViewer
                    key={`doc-${uniqueKey}`}
                    className="doc-viewer"
                    pluginRenderers={DocViewerRenderers}
                    prefetchMethod="GET"
                    documents={[
                      {
                        uri: active?.url,
                      },
                    ]}
                  />
                ) : datum?.name?.includes('mp4') ? (
                  <video
                    key={`video-${uniqueKey}`}
                    src={active?.url}
                    controls
                    autoPlay
                    style={{ width: '100%' }}
                  />
                ) : datum?.name?.includes('mp3') ? (
                  <audio
                    key={`audio-${uniqueKey}`}
                    src={active?.url}
                    controls
                    autoPlay
                    style={{ width: '100%' }}
                  />
                ) : (
                  <img key={`img-${uniqueKey}`} src={active?.url} />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      {list.length > 1 && !loading && (
        <div className="navigation">
          <div className="navigation-postion">
            <span
              className="image-swiper-button-prev swiper-button-disabled"
              onClick={() => handlePrev()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 15L7.5 10L12.5 5"
                  stroke="#414240"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>

            <span
              className="image-swiper-button-next swiper-button-disabled"
              onClick={() => handleNext()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#414240"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      )}

      {/* Full Page Loader */}
      {loading && (
        <div className="loader-holder">
          <span className="loader">
            <span></span>
            <span></span>
          </span>
          <p className="display-text">{`Downloading ${
            list.length > 1 ? 'all files' : 'file'
          }`}</p>
        </div>
      )}
    </section>
  );
};

export default AssetViewer;
