import { all, put, takeEvery } from 'redux-saga/effects';
import { AUTOMATE_REQUEST, AUTOMATE_SUCCESS } from 'redux/reducers/AutomationReducer';

function* automate(payload) {
  yield put({
    type: AUTOMATE_SUCCESS,
    payload,
  });
}

export default all([takeEvery(AUTOMATE_REQUEST, automate)]);
