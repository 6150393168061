import React, { useEffect, useState } from 'react';
import { ReactComponent as AlertTrangle } from 'assets/icons/alert-triangle.svg';
import { Container } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';

const PendingMandateNotice = ({
  status,
  onClick,
  showButton = true,
  mandate,
  isLinked,
  isReadyForDebit,
}) => {
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    setShowBanner(
      !['granted'].includes(status) || (['granted'].includes(status) && !isReadyForDebit),
    );
  }, []);

  const statusText = {
    nullTitle: 'Activate Direct Debit',
    linkTitle: 'Link your account',
    linkDesc: `Link your account to sync your bank transaction data.`,
    nullDesc: `To start making payments directly from your account, kindly enable direct debit.`,
    pendingTitle: 'Account verification in progress',
    pendingDesc: `Your mandate is currently undergoing verification. We'll notify you as soon as your mandate is verified and ready to use for direct debit.
   <br /> NB: Verification usually takes about ${
     mandate === 'emandate' ? '20 hours.' : '3 business working days.'
   }`,
  };

  return (
    <>
      {showBanner && (
        <Container className="px-0 mt-4">
          <div className="budget__notice">
            <div className="alert-triangle-container">
              <AlertTrangle />
            </div>
            <div>
              <h4>
                {status === null
                  ? statusText.nullTitle
                  : status === 'pending' || (status === 'granted' && !isReadyForDebit)
                  ? statusText.pendingTitle
                  : status === 'granted' && !isLinked
                  ? statusText.linkTitle
                  : null}
              </h4>
              <p>
                {status === null
                  ? statusText.nullDesc
                  : status === 'pending' || (status === 'granted' && !isReadyForDebit)
                  ? HTMLReactParser(statusText.pendingDesc)
                  : status === 'granted' && !isLinked
                  ? statusText.linkDesc
                  : null}{' '}
                {status === null && (
                  <>
                    <br />

                    <a
                      href="https://www.bujeti.com/bank-sync"
                      target="_blank"
                      className="link link-underline-dark text-sm"
                      rel="noreferrer"
                    >
                      <span className="color-grey-900 ">Learn more</span>
                    </a>
                  </>
                )}
              </p>
              {showButton &&
                (status !== 'pending' || (status === 'granted' && !isLinked)) && (
                  <div className="add-button add-action bg-white" onClick={onClick}>
                    {status === 'granted' && !isLinked
                      ? 'Link account'
                      : 'Enable direct debit'}
                  </div>
                )}
            </div>
            {/* <CloseOutlined className="ms-auto cursor" onClick={removeStatus} /> */}
          </div>
        </Container>
      )}
    </>
  );
};

export default PendingMandateNotice;
