import { CopyIcon, SendIcon } from 'assets/icons';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import ImgCard from 'components/UI/ImgCard';
import Loading from 'components/UI/Loading';
import MetricCard from 'components/UI/MetricCard';
import { toastError, toastSuccess } from 'components/UI/toast';
import copy from 'copy-text-to-clipboard';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReferrals,
  getReferralsWithCode,
  sendReferralByEmail,
} from 'redux/actions/ReferralsAction';
import { buildReferralTableData } from 'utils/helper';
import { ReferralColumn } from 'utils/mockData';
import { ReactComponent as Facebook } from '../../assets/icons/facebook-icon.svg';
import { ReactComponent as GrayEarn } from '../../assets/icons/gray-earn.svg';
import { ReactComponent as GrayGift } from '../../assets/icons/gray-gift.svg';
import { ReactComponent as GrayLink } from '../../assets/icons/gray-link.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin-icon.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter-icon.svg';
import './index.scss';

const Referrals = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({ email: '' });
  const {
    getReferrals: { data: { referralCode, pendingPoints } = {}, loading },
    getReferralsWithCode: { data: { referrals = [], meta } = {} },
    sendReferralByEmail: { loading: loadingSend, success: successSend },
  } = useSelector(({ referrals }) => referrals);

  const { hasMore, nextPage, page, total, perPage } = meta || {};

  const {
    getCompany: { data: companyData = {}, loading: loadingCompany },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    if (!referralCode?.code) dispatch(getReferrals());
  }, []);

  useEffect(() => {
    if (referralCode?.code) dispatch(getReferralsWithCode({ code: referralCode?.code }));
  }, [referralCode?.code]);

  const handleChange = ({ target: { name, value } }) => {
    setInputs({ ...inputs, [name]: value });
  };
  const sendEmail = () => {
    if (!inputs.email) return toastError('Please enter an email');
    const payload = {
      email: inputs.email,
    };
    dispatch(sendReferralByEmail(payload));
  };

  const handlePreviousPage = (page) => {
    dispatch(getReferralsWithCode({ code: referralCode?.code, perPage, page }));
  };

  const handleNextPage = (page) => {
    dispatch(getReferralsWithCode({ code: referralCode?.code, perPage, page }));
  };

  useEffect(() => {
    if (successSend) {
      setInputs({ email: '' });
      dispatch(getReferralsWithCode({ code: referralCode?.code, perPage, page }));
    }
  }, [successSend]);

  const shareUrl = `https://www.bujeti.com/join/${referralCode?.code}`;
  const title = 'Join bujeti for free';

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl,
    )}`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  // Function to open Twitter share dialog
  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      shareUrl,
    )}&text=${encodeURIComponent(title)}`;
    window.open(url, '_blank', 'width=600,height=300');
  };

  // Function to open LinkedIn share dialog
  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      shareUrl,
    )}`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  const loadingState = loading || loadingCompany;

  // if (loading || loadingCompany) return <Loading isPage color="#D28B28" />;
  return (
    <div className="position-relative container">
      <TopBar
        headerText="Refer & earn rewards"
        subText="Invite your business connections to Bujeti and enjoy exclusive benefits. Love our expense management dashboard? Share it and get rewarded!"
        isRightBar
      />
      <section className="px-0 py-3">
        <div className="d-flex w-100 flex-wrap gap-3">
          <div className="referral__metric-card">
            <MetricCard
              loading={loadingState}
              label="Referred customers"
              value={companyData?.referrer ?? 0}
            />
          </div>
          <div className="referral__metric-card">
            <MetricCard
              loading={loadingState}
              label="Total earned credits"
              value={companyData?.rewardPoints ?? 0}
            />
          </div>
          <div className="referral__metric-card">
            <MetricCard
              loading={loadingState}
              label="Pending credits"
              value={pendingPoints ?? 0}
            />
          </div>
        </div>

        <div>
          <div className="referral__how">
            <h6>Here&apos;s how it works</h6>
            <p>
              It&apos;s as simple as 1, 2, 3. You&apos;ll unlock your reward when all
              steps are successfully completed.
            </p>
          </div>
          <div className="referral__how-list">
            <div className="space">
              <ImgCard
                avatar={<GrayLink />}
                label="Share your referral link"
                value="Get your unique link and share it via email, social media, or in person."
              />
              <ImgCard
                avatar={<GrayGift />}
                label="Earn rewards together"
                value="When your friends sign up using your link and complete their first transaction, you both earn credits for exclusive rewards."
              />
              <ImgCard
                avatar={<GrayEarn />}
                label="Refer more, earn more"
                value="No limits here. Refer as many friends as you can for more rewards."
              />
            </div>
            <div className="referral__share">
              <div className="share-link">
                <p className="m-0">Share link</p>
                <div className="forms mt-2">
                  <div className="d-flex flex-grow-1">
                    <CustomInput
                      placeholder={`bujeti.com/join/${referralCode?.code}`}
                      type="text"
                      name="name"
                      value={`bujeti.com/join/${referralCode?.code}`}
                      disabled
                      className="share-input"
                    />
                  </div>
                  <div className="d-flex flex-grow-0 flex-row align-items-center gap-2">
                    <CustomButton
                      onClick={() => {
                        toastSuccess('Copied');
                        copy(shareUrl);
                      }}
                      className="add-button d-flex gap-1 align-items-center justify-content-center"
                    >
                      <CopyIcon stroke="#fff" width="16" height="16" />
                      Copy
                    </CustomButton>

                    <div className="add-button add-action svg" onClick={shareOnFacebook}>
                      <Facebook />
                    </div>
                    <div className="add-button add-action svg" onClick={shareOnLinkedIn}>
                      <Linkedin />
                    </div>
                    <div className="add-button add-action svg" onClick={shareOnTwitter}>
                      <Twitter />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-3">
                <div className="divider">
                  <span className="divider-text">or</span>
                </div>
              </div>
              <div className="share-link">
                <p className="m-0">Invite by mail</p>
                <div className="forms mt-2">
                  <div className="d-flex flex-grow-1">
                    <CustomInput
                      placeholder="Enter emails, separated by a comma"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={inputs.email}
                      className="share-input d-flex flex-grow-1"
                    />
                  </div>
                  <div className="d-flex flex-grow-0 flex-row align-items-center gap-2">
                    <CustomButton
                      onClick={sendEmail}
                      loading={loadingSend}
                      disabled={loadingSend}
                      className="add-button d-flex gap-1 align-items-center justify-content-center"
                    >
                      <SendIcon stroke="#fff" width="16" height="16" />
                      Send
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {referrals?.length ? (
            <div className="referral__table-wrapper">
              <div className="referral__table-header">
                <div className="header-text">
                  <h5>Referral history</h5>
                </div>
              </div>

              <div className="referral__table-body">
                <Table
                  withoutBorder
                  columns={ReferralColumn}
                  data={buildReferralTableData(referrals)}
                  pagination
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  className="table-wrapper__border"
                />
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default Referrals;
