import {
  ADD_TEAM_MEMBER_REQUEST,
  CREATE_TEAMS_REQUEST,
  DELETE_TEAM_REQUEST,
  TEAM_OVERVIEW_REQUEST,
  GET_TEAMS_REQUEST,
  TEAM_MEMBER_STATUS_REQUEST,
  UPDATE_TEAM_REQUEST,
  GET_TEAM_MEMBERS_REQUEST,
} from 'redux/reducers/TeamsReducer';

export const createTeam = (payload) => ({ type: CREATE_TEAMS_REQUEST, payload });
export const getTeams = (payload) => ({ type: GET_TEAMS_REQUEST, payload });
export const teamOverview = (payload) => ({ type: TEAM_OVERVIEW_REQUEST, payload });
export const deleteTeam = (payload) => ({ type: DELETE_TEAM_REQUEST, payload });
export const updateTeam = (payload) => ({ type: UPDATE_TEAM_REQUEST, payload });
export const addTeamMember = (payload) => ({ type: ADD_TEAM_MEMBER_REQUEST, payload });
export const teamMemberStatus = (payload) => ({
  type: TEAM_MEMBER_STATUS_REQUEST,
  payload,
});
export const getTeamMembers = (payload) => ({ type: GET_TEAM_MEMBERS_REQUEST, payload });
