import { CalenderIcon } from 'assets/icons';
import CustomPopover from 'components/UI/Popover';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subQuarters,
  subYears,
} from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectDateRangeMoneyOut, selectDateRnage } from 'redux/actions/OverviewAction';
import Range from './Range';
import './styles.scss';

const DateRangeFilter = ({
  handleSelectRange = () => null,
  selectedRange = '',
  trigger = '',
  zIndex = '1',
  today = true,
  sevenDays = true,
  last30Days = true,
  lastMonth = false,
  thisMonth = false,
  lastQuarter = false,
  thisQuarter = false,
  lastSemester = false,
  thisYear = false,
  lastYear = false,
  customRange = true,
  numberOfMonths = 1,
  customClass = '',
  ...rest
}) => {
  const dispatch = useDispatch();
  const [isRangePopoverOpen, setIsRangePopoverOpen] = useState(false);
  const handleToggleRangePopover = () => {
    setIsRangePopoverOpen(!isRangePopoverOpen);
  };
  useEffect(() => {
    isRangePopoverOpen && setIsRangePopoverOpen(!isRangePopoverOpen);
  }, [selectedRange]);

  const handleOptionClick = (val, date) => () => {
    setIsRangePopoverOpen(!isRangePopoverOpen);

    // Add days to the given date
    Date.prototype.addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    const today = new Date();

    const dispatchAction = (payload) => {
      if (trigger === 'payout') {
        dispatch(selectDateRangeMoneyOut(payload));
      } else {
        dispatch(selectDateRnage(payload));
      }
    };

    switch (val) {
      case 'Today':
        dispatchAction({ val, range: [{ startDate: today, endDate: today }] });
        return handleSelectRange(val, [{ startDate: today, endDate: today }]);

      case 'Last 7 days':
        dispatchAction({
          val,
          range: [{ startDate: today.addDays(-7), endDate: today }],
        });
        return handleSelectRange(val, [{ startDate: today.addDays(-7), endDate: today }]);

      case 'Last 30 days':
        dispatchAction({
          val,
          range: [{ startDate: today.addDays(-30), endDate: today }],
        });
        return handleSelectRange(val, [
          { startDate: today.addDays(-30), endDate: today },
        ]);

      case 'This Month':
        const startMonth = startOfMonth(today);
        const endMonth = endOfMonth(today);
        dispatchAction({
          val,
          range: [{ startDate: startMonth, endDate: endMonth }],
        });
        return handleSelectRange(val, [{ startDate: startMonth, endDate: endMonth }]);

      case 'Last Month':
        const lastMonth = new Date(`${today.getFullYear()}-${today.getMonth()}-01`);
        const endLastMonthDate = endOfMonth(lastMonth);
        dispatchAction({
          val,
          range: [{ startDate: lastMonth, endDate: endLastMonthDate }],
        });
        return handleSelectRange(val, [
          { startDate: lastMonth, endDate: endLastMonthDate },
        ]);

      case 'This Quarter':
        const thisQuarterStarts = startOfQuarter(today);
        const thisQuarterEnds = endOfQuarter(today);
        dispatchAction({
          val,
          range: [{ startDate: thisQuarterStarts, endDate: thisQuarterEnds }],
        });
        return handleSelectRange(val, [
          { startDate: thisQuarterStarts, endDate: thisQuarterEnds },
        ]);

      case 'Last Quarter':
        const lastQuarterDate = subQuarters(today, 1);
        dispatchAction({
          val,
          range: [
            {
              startDate: startOfQuarter(lastQuarterDate),
              endDate: endOfQuarter(lastQuarterDate),
            },
          ],
        });
        return handleSelectRange(val, [
          {
            startDate: startOfQuarter(lastQuarterDate),
            endDate: endOfQuarter(lastQuarterDate),
          },
        ]);

      case 'Last Semester':
        const twoQuarterAway = subQuarters(today, 2);
        const oneQuarterAway = subQuarters(today, 1);
        dispatchAction({
          val,
          range: [
            {
              startDate: startOfQuarter(twoQuarterAway),
              endDate: endOfQuarter(oneQuarterAway),
            },
          ],
        });
        return handleSelectRange(val, [
          {
            startDate: startOfQuarter(twoQuarterAway),
            endDate: endOfQuarter(oneQuarterAway),
          },
        ]);

      case 'This Year':
        const startYear = startOfYear(today);
        const endYear = endOfYear(today);
        dispatchAction({
          val,
          range: [{ startDate: startYear, endDate: endYear }],
        });
        return handleSelectRange(val, [{ startDate: startYear, endDate: endYear }]);

      case 'Last Year':
        const lastYearStartDate = subYears(today, 1);
        const lastYearEndDate = today;
        dispatchAction({
          val,
          range: [{ startDate: lastYearStartDate, endDate: lastYearEndDate }],
        });
        return handleSelectRange(val, [
          { startDate: lastYearStartDate, endDate: lastYearEndDate },
        ]);

      default:
        dispatchAction({ val, range: date });
        return handleSelectRange(val, date);
    }
  };

  return (
    <CustomPopover
      zIndex={zIndex}
      content={
        <Range
          handleOptionClick={handleOptionClick}
          handleToggleRangePopover={handleToggleRangePopover}
          periodOptions={{
            today,
            sevenDays,
            last30Days,
            thisMonth,
            lastMonth,
            thisQuarter,
            lastQuarter,
            lastSemester,
            thisYear,
            lastYear,
            customRange,
          }}
          numberOfMonths={numberOfMonths}
          {...rest}
        />
      }
      showPopover={isRangePopoverOpen}
    >
      <div
        onClick={handleToggleRangePopover}
        className={`cursor custom-date-picker add-custom add-button ${
          customClass ? customClass : ''
        }`}
      >
        <CalenderIcon className="ps-1" /> {selectedRange}
      </div>
    </CustomPopover>
  );
};
export default DateRangeFilter;
