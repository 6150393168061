import { Tooltip } from 'antd';
import { getCurrency, percentageValue } from 'utils/helper';
import { formatNumThousandSeparator } from 'utils/utility';

const LineStatusType = ({ value }) => {
  const goal = (value?.amount || 0) / 100;
  const spentValue = (value?.spent || 0) / 100;
  const bufferValue = (value?.bufferAmount || 0) / 100;

  const spent = percentageValue(goal, spentValue);
  let buffer = percentageValue(bufferValue, spentValue - goal);
  buffer = isFinite(buffer) && buffer > 0 ? buffer : 0;

  return (
    <>
      <Tooltip
        title={
          <div>
            <div className={`${goal <= spentValue ? 'mb-2' : ''}`}>
              <p className="m-0">
                <span style={{ color: '#44403C', fontWeight: '600' }}>Goal -</span>{' '}
                {spent}%
              </p>
              {getCurrency(value?.currency)}
              {formatNumThousandSeparator(spentValue)} / {''}
              {getCurrency(value?.currency)}
              {formatNumThousandSeparator(goal || 0)}
            </div>
            {goal <= spentValue && (
              <div>
                <p className="m-0">
                  <span style={{ color: '#44403C', fontWeight: '600' }}>Buffer -</span>{' '}
                  {buffer}%
                </p>
                {getCurrency(value?.currency)}
                {formatNumThousandSeparator(spentValue - goal)} / {''}
                {getCurrency(value?.currency)}
                {formatNumThousandSeparator(bufferValue || 0)}
              </div>
            )}
          </div>
        }
        placement="bottom"
        color="white"
        overlayInnerStyle={{ color: '#79716B' }}
      >
        <div className="table-lines">
          <div
            className="disbursed-line text-center"
            style={{ width: `${buffer + spent}%` }}
          >
            {spent - buffer > 20 && (
              <span className="text-xs" style={{ color: '#44403C' }}>
                {spent}%
              </span>
            )}
          </div>
          {goal <= spentValue && (
            <div className={'spent-line text-center'} style={{ width: `${buffer}%` }}>
              {buffer > 20 && (
                <span className="text-xs" style={{ color: '#44403C' }}>
                  {buffer}%
                </span>
              )}
            </div>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default LineStatusType;
