import { useState } from 'react';

/**
 * @param {string} initialValue: the initial value of the charac
 * @param {number} maxLength: the total number of length expected to be typed
 * @param {function} onTextChange: a callback function to update the parent state
 */
const useTextCounter = (initialValue = '', maxLength, onTextChange) => {
  const [text, setText] = useState(initialValue);
  const [charCount, setCharCount] = useState(initialValue?.length);

  const handleCharChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= maxLength) {
      setText(newValue);
      setCharCount(newValue.length);
      if (onTextChange) {
        onTextChange(newValue);
      }
    }
  };

  return { text, charCount, handleCharChange };
};

export default useTextCounter;
