import { type } from '@testing-library/user-event/dist/type';

export const SELECTED_TABLE_ROWS = 'SELECTED_TABLE_ROWS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  selectedTableRows: { shouldClearRows: false, selectedRows: [], type: null },
};

export const TableReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_TABLE_ROWS:
      return {
        ...state,
        selectedTableRows: {
          ...state.selectedTableRows,
          shouldClearRows:
            action?.payload?.shouldClearRows ?? state.selectedTableRows.shouldClearRows,
          selectedRows:
            action?.payload?.selectedRows ?? state.selectedTableRows.selectedRows,
          type: action?.payload?.type ?? state.selectedTableRows.type,
        },
      };

    default:
      return state;
  }
};
