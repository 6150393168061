import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSwitch from 'components/UI/CustomSwitch/inedx';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { fetchCategories } from 'redux/actions/CategoryAction';
import { getVendors } from 'redux/actions/VendorsAction';

import { fetchTransactionTypes } from 'redux/actions/ApprovalAction';

import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { categoryOptions, transactionTypeObj } from './components/ConditionData';

import { uploadProgress as updateProgress } from 'redux/actions/AssetsAction';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Avatar, CancelICon, PlusDropDownIcon } from 'assets/icons';
import CustomTextarea from 'components/UI/CustomTextarea';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { editPolicy } from 'redux/actions/PoliciesAction';
import { RESET_BLOCK_POLICIES } from 'redux/reducers/PoliciesReducer';
import { capitalizeFirstLetter, getInitials } from 'utils/helper';
import '../Approvals/components/styles.scss';
import ConditionRowComponent from './components/ConditionRowComponent';
import FileUpload from './components/FileUpload';
import { amountConditionValidation, formValidation } from './components/FormValidation';

const EditPolicy = ({ setShowEditModal }) => {
  const dispatch = useDispatch();

  const {
    getBeneficiaries: { data: { beneficiaries = [] } = {}, loading: beneficiaryLoading },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    fetchTransactionTypes: {
      data: transactionTypes = [],
      loading: isFetchingTransactionTypes,
    },
  } = useSelector(({ approval }) => approval);

  const {
    editPolicy: { loading, success },
    fetchSinglePolicy: { data: singlePolicy },
  } = useSelector(({ policies }) => policies);

  const {
    fetchCategories: { data: categoryData, success: isCatSuccess },
    createCategories: { loading: isCatLoading },
  } = useSelector(({ categories }) => categories);

  const {
    getBudget: { data: budgetData },
    getBalances: { data: { balances: accountData } = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getVendor: { loading: vendorLoading, data: vendorData },
  } = useSelector(({ vendors }) => vendors);

  const { uploadProgress } = useSelector(({ assets }) => assets);

  const [policyData, setPolicyData] = useState({});
  const [selectedTransactionTypes, setSelectedTransactionTypes] = useState([]);
  const [value, setValue] = useState({ budget: '' });
  const [exemptedUsers, setExemptedUsers] = useState([]);
  const [conditionsToAdd, setConditionsToAdd] = useState([]);
  const [conditionsToRemove, setConditionsToRemove] = useState([]);
  const [conditionsToUpdate, setConditionsToUpdate] = useState([]);
  const [conditionThreeOptions, setConditionThreeOptionsState] = useState({});
  const [isLengthEqualUsers, setIsLengthEqualUsers] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [policyConditions, setPolicyConditions] = useState({
    rules: [
      {
        name: '',
        'condition-one': {
          options: categoryOptions,
          selectedValue: null,
        },
        'condition-two': {
          options: [],
          selectedValue: null,
        },
        'condition-three': {
          options: [],
          selectedValue: null,
        },
      },
    ],
  });

  const history = useHistory();

  const debouncedValue = useDebounce(value.users, 200);
  const debouncedBudget = useDebounce(value.budget, 200);

  const handleDescriptionChange = (value) => {
    setPolicyData({
      ...policyData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    policyData?.description,
    250,
    handleDescriptionChange,
  );

  const getInputChange = (val, type) => {
    setValue((prevValue) => ({ ...prevValue, [type]: val }));
  };

  const transformData = (data, type) => {
    return data?.map((item) => {
      return {
        value: type === 'type' ? item.feature_name : item?.name,
        label: item?.name,
        code: type === 'type' ? item.feature_name : item?.code,
      };
    });
  };

  useEffect(() => {
    if (singlePolicy) {
      const {
        description,
        name,
        requiresBudget,
        requiresCategory,
        requiresDescription,
        requiresReceipt,
        strict,
        status,
        conditions,
        documents,
      } = singlePolicy;

      if (conditions.length) {
        let index = 0;
        const rules = [];

        const selectedConditions = [];

        for (let condition of conditions) {
          rules.push({
            name: '',
            code: condition?.code,
            'condition-one': {
              options: categoryOptions.filter(
                (item) => !selectedConditions.includes(item.value),
              ),
              selectedValue: {
                value: condition.trigger,
                label: capitalizeFirstLetter(condition.trigger),
              },
            },
            'condition-two': {
              options: transactionTypeObj[condition.trigger].secondOption.slice(),
              selectedValue: transactionTypeObj[condition.trigger].secondOption.find(
                (item) => item.value === condition.operator,
              ),
            },
            'condition-three': {
              options: [],
              selectedValue: null,
            },
          });

          let conditionOperands = condition.operands;

          rules[index]['condition-three'].selectedValue = ['any', 'not:any'].includes(
            condition.operator,
          )
            ? conditionOperands.map((item) => ({
                value: item[condition.trigger]?.name,
                label: item[condition.trigger]?.name,
                code: item[condition.trigger]?.code,
              }))
            : condition.trigger !== 'amount'
            ? {
                value:
                  conditionOperands?.[0][condition.trigger][
                    condition.trigger === 'type' ? 'feature_name' : 'name'
                  ],
                label: conditionOperands?.[0][condition.trigger].name,
                code: conditionOperands?.[0][condition.trigger][
                  condition.trigger === 'type' ? 'feature_name' : 'code'
                ],
              }
            : { value: conditionOperands?.[0].operand / 100 };

          setConditionOneOptions(rules, index, { value: condition.trigger }, true);
          setConditionTwoOptions(rules, index, { value: condition.operator });

          selectedConditions.push(condition.trigger);
          index++;
        }

        setPolicyConditions({ rules });
      } else {
        setConditionsToAdd([policyConditions?.rules?.length - 1]);
      }

      setPolicyData({
        ...policyData,
        description,
        name,
        status: status === 'active',
        requiresBudget,
        requiresCategory,
        requiresDescription,
        requiresReceipt,
        violations: strict ? 'block' : 'flag',
      });

      const uploadedFiles = {};

      for (let i = 0; i < documents.length; i++) {
        uploadedFiles[i] = {
          property: i,
          data: 0,
          loading: false,
          isExistingFile: true,
          name: documents[i].asset.name,
          assetCode: documents[i]?.asset?.code,
          created_at: documents[i].asset.created_at,
        };
      }
      dispatch(updateProgress({ progress: uploadedFiles }));
    }
  }, [singlePolicy]);

  const stableConditionThreeOptions = useMemo(
    () => Object.values(conditionThreeOptions),
    [conditionThreeOptions],
  );

  useEffect(() => {
    if (stableConditionThreeOptions)
      if (
        Object.keys(stableConditionThreeOptions).length === policyConditions.rules.length
      ) {
        let newRules = policyConditions.rules.slice();
        for (let item of Object.values(stableConditionThreeOptions)) {
          if (newRules[item.index])
            newRules[item.index]['condition-three'].options = item.options;
          else continue;
        }
        setPolicyConditions({ ...policyConditions, rules: newRules });
      }
  }, [stableConditionThreeOptions, policyConditions.rules.length]);

  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_BLOCK_POLICIES,
        blockType: 'fetchSinglePolicy',
      });
    };
  }, []);

  useEffect(() => {
    if (singlePolicy) {
      const exceptions = singlePolicy?.exceptions?.map((item) => item.user.code);
      if (beneficiaries.length) {
        setExemptedUsers(
          beneficiaries
            .filter(({ code, role, user: { code: userCode } }) =>
              exceptions?.includes(userCode),
            )
            .map(({ code, user: { code: userCode, firstName, lastName } }) => ({
              label: `${firstName} ${lastName}`,
              value: userCode,
            })),
        );
      }
    }
  }, [beneficiaries.length, singlePolicy]);

  useEffect(() => {
    if (transactionTypes.length) {
      setConditionThreeOptions(currentIndex, transactionTypes, 'type');
    }
  }, [transactionTypes.length]);

  useEffect(() => {
    if (budgetData) {
      setConditionThreeOptions(currentIndex, budgetData?.budgets, 'budget');
    }
  }, [budgetData?.budgets]);

  useEffect(() => {
    const budgetListed = budgetData?.budgets?.find((option) =>
      option?.name?.toLowerCase()?.includes(debouncedBudget.toLowerCase()),
    );
    if (!budgetListed && debouncedBudget) {
      dispatch(getBudgets({ status: 'active', search: debouncedBudget?.toLowerCase() }));
    }
  }, [debouncedBudget]);

  useEffect(() => {
    if (vendorData) {
      setConditionThreeOptions(currentIndex, vendorData?.vendors, 'vendor');
    }
  }, [vendorData]);

  useEffect(() => {
    if (accountData) {
      setConditionThreeOptions(currentIndex, accountData, 'account');
    }
  }, [accountData]);

  useEffect(() => {
    if (!beneficiaries.length) dispatch(getBeneficiaries());
  }, []);

  useEffect(() => {
    const beneficiaries = beneficiaryList?.find((option) =>
      option?.label?.toLowerCase()?.includes(debouncedValue?.toLowerCase()),
    );

    if (!beneficiaries && debouncedValue) {
      dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  useEffect(() => {
    setIsLengthEqualUsers(beneficiaryList?.length === 0 && !!exemptedUsers?.length);
  }, [exemptedUsers?.length]);

  const onHandleCancel = () => {
    setShowEditModal(false);
  };

  useEffect(() => {
    let newRules = policyConditions.rules.slice();
    for (let i = 0; i < newRules.length; i++) {
      if (i !== currentIndex) {
        newRules[i]['condition-one'].options = categoryOptions.filter(
          (item) =>
            !selectedTransactionTypes.includes(item.value) ||
            item.value === newRules[i]['condition-one'].selectedValue?.value,
        );
      }
    }
    setPolicyConditions((prevValues) => ({ ...prevValues, rules: newRules }));
  }, [selectedTransactionTypes, currentIndex]);

  const handleBeneficiarySelect = (value, name) => {
    setExemptedUsers(value);
  };

  const onMenuClose = () => {
    if (debouncedValue) dispatch(getBeneficiaries());
  };

  const seletedExemptedUsers = exemptedUsers?.map((item) => item.value);

  const beneficiaryList = beneficiaries
    .filter(
      ({ code, role, user: { code: userCode } }) =>
        !seletedExemptedUsers?.includes(userCode),
    )
    .map(({ code, user: { code: userCode, firstName, lastName } }) => ({
      label: `${firstName} ${lastName}`,
      value: userCode,
    }));

  const formatSelectedOptionLabel = (selectedOption) => {
    if (!selectedOption) {
      return null;
    }
    return (
      <div className="d-flex align-items-center gap-2">
        <div className="avatar__card-initials">
          <h5>{getInitials(null, null, selectedOption.label)}</h5>
        </div>
        <div>{selectedOption.label}</div>
      </div>
    );
  };

  const onHandleAmountChange = (event, index) => {
    const { value, rawValue } = event.target;
    let newRules = policyConditions.rules.slice();
    let selectedRule = newRules[index];
    selectedRule[`condition-three`].selectedValue = { value: rawValue ?? value };

    if (!conditionsToAdd.includes(index) && !conditionsToUpdate.includes(index)) {
      setConditionsToUpdate([...conditionsToUpdate, index]);
    }

    setPolicyConditions({ ...policyConditions, rules: newRules });
  };

  const handleSelectAllBeneficiary = (list, name) => {
    const newList = !beneficiaryList?.length ? [] : list;
    const formerList = !beneficiaryList?.length && exemptedUsers ? [] : exemptedUsers;
    setExemptedUsers([...newList, ...formerList]);
  };

  const getThirdConditionOptions = (conditionOne, index, isEdit) => {
    if (isEdit) {
      setCurrentIndex(null);
      setConditionThreeOptionsState((prevValue) => ({
        ...prevValue,
        [conditionOne]: { index, options: [] },
      }));
    } else if (conditionOne !== 'amount') {
      setCurrentIndex(index);
    }
    switch (conditionOne) {
      case 'type':
        if (!transactionTypes.length) dispatch(fetchTransactionTypes());
        else setConditionThreeOptions(index, transactionTypes, 'type');
        break;
      case 'vendor':
        if (!vendorData) dispatch(getVendors());
        else setConditionThreeOptions(index, vendorData?.vendors, 'vendor');
        break;
      case 'account':
        if (!accountData) return dispatch(getBalances());
        else setConditionThreeOptions(index, accountData, 'account');
        break;
      case 'budget':
        if (!budgetData) return dispatch(getBudgets());
        else setConditionThreeOptions(index, budgetData?.budgets, 'budget');
        break;
      case 'category':
        if (!categoryData) return dispatch(fetchCategories());
        break;
      default:
        return;
    }
  };

  const handleConditionChange = (value, index, level) => {
    setCurrentIndex(index);
    let newRules = policyConditions.rules.slice();
    let selectedRule = newRules[index];
    selectedRule[`condition-${level}`].selectedValue = value;

    if (!conditionsToAdd.includes(index) && !conditionsToUpdate.includes(index)) {
      setConditionsToUpdate([...conditionsToUpdate, index]);
    }

    switch (level) {
      case 'one':
        setSelectedTransactionTypes((prevValue) => {
          const newArray = prevValue.slice();
          newArray[index] = value.value;
          return newArray;
        });
        setConditionOneOptions(newRules, index, value);
        break;
      case 'two':
        if (selectedRule[`condition-one`].selectedValue?.value === 'type') {
          if (value.value === 'not:is') {
            selectedRule[`condition-three`].options = selectedRule[
              `condition-three`
            ].options.filter((item) => item.value !== 'all');
          } else {
            selectedRule[`condition-three`].options = transformData(
              transactionTypes,
              'type',
            );
          }
        }
        setConditionTwoOptions(newRules, index, value);
        break;
    }

    setPolicyConditions({ ...policyConditions, rules: newRules });
  };

  const getAmountConditions = (
    newRules,
    secondConditionOptions,
    otherAmountConditionIndex,
  ) => {
    return secondConditionOptions.filter((operator) => {
      switch (newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value) {
        case 'eq':
          return (
            newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value !==
              operator.value && operator.value === 'eq'
          );
        case 'gt':
          return (
            newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value !==
              operator.value && ['lt', 'lte'].includes(operator.value)
          );
        case 'lt':
          return (
            newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value !==
              operator.value && ['gt', 'gte'].includes(operator.value)
          );
        case 'gte':
          return (
            newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value !==
              operator.value && ['lt', 'lte'].includes(operator.value)
          );
        case 'lte':
          return (
            newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value !==
              operator.value && ['gt', 'gte'].includes(operator.value)
          );
        default:
          return (
            newRules[otherAmountConditionIndex]['condition-two'].selectedValue.value !==
            operator.value
          );
      }
    });
  };

  const setConditionOneOptions = (newRules, index, value, isEdit = false) => {
    if (!isEdit) {
      if (newRules[index]['condition-three'].selectedValue)
        newRules[index]['condition-three'].selectedValue = null;

      let secondConditionOptions = transactionTypeObj[value.value].secondOption.slice();

      let amountCount = 0;

      let otherAmountConditionIndex;

      newRules.forEach((item, ruleIndex) => {
        if (item['condition-one'].selectedValue?.value === 'amount') {
          amountCount += 1;
          if (index !== ruleIndex) {
            otherAmountConditionIndex = ruleIndex;
          }
        }
      });

      if (
        newRules[index]['condition-one'].selectedValue.value === 'amount' &&
        otherAmountConditionIndex
      ) {
        if (index < otherAmountConditionIndex) {
          const newSecondConditionOptions = getAmountConditions(
            newRules,
            secondConditionOptions,
            otherAmountConditionIndex,
          );
          newRules[index]['condition-two'].options = secondConditionOptions;
          newRules[otherAmountConditionIndex]['condition-two'].options =
            newSecondConditionOptions;
        } else {
          const newSecondConditionOptions = getAmountConditions(
            newRules,
            secondConditionOptions,
            otherAmountConditionIndex,
          );
          newRules[index]['condition-two'].options = newSecondConditionOptions;
        }
        newRules[index]['condition-two'].selectedValue =
          newRules[index]['condition-two'].options[0];
        return;
      }

      newRules[index]['condition-two'].options = secondConditionOptions;
      newRules[index]['condition-two'].selectedValue = secondConditionOptions[0];
    }

    getThirdConditionOptions(value.value, index, isEdit);
  };

  const setConditionTwoOptions = (newRules, index, value) => {
    let otherAmountConditionIndex;
    let selectedConditionOneValue = newRules[index]['condition-one'].selectedValue.value;

    newRules.forEach((item, ruleIndex) => {
      if (item['condition-one'].selectedValue?.value === 'amount') {
        if (index !== ruleIndex) {
          otherAmountConditionIndex = ruleIndex;
        }
      }
    });

    // if (!otherAmountConditionIndex && value.value !== 'eq') {
    //   setSelectedTransactionTypes((prevValue) => {
    //     let newArray = prevValue.filter((item) => item !== 'amount');
    //     return newArray;
    //   });
    // }

    let secondConditionOptions =
      transactionTypeObj[selectedConditionOneValue].secondOption.slice();

    if (otherAmountConditionIndex && index < otherAmountConditionIndex) {
      const newSecondConditionOptions = getAmountConditions(
        newRules,
        secondConditionOptions,
        index,
      );
      newRules[otherAmountConditionIndex]['condition-two'].options =
        newSecondConditionOptions;
    }
  };

  const setConditionThreeOptions = (index, options, type) => {
    let newRules = policyConditions.rules.slice();
    let selectedRule = newRules[index];
    if (
      selectedRule?.['condition-one']?.selectedValue?.value !== type &&
      currentIndex === null
    ) {
      setConditionThreeOptionsState((prevValue) => {
        if (prevValue[type]?.index !== undefined) {
          return {
            ...prevValue,
            [type]: { ...prevValue[type], options: transformData(options, type) },
          };
        } else {
          return prevValue;
        }
      });
      return;
    }
    selectedRule[`condition-three`].options = transformData(options, type);
    setPolicyConditions((prevValues) => ({ ...prevValues, rules: newRules }));
  };

  const addNewCondition = () => {
    let newRules = policyConditions.rules.slice();

    const previousConditionOneSelectedVal =
      newRules.at(-1)['condition-one'].selectedValue?.value;

    const previousConditionOneSelectedOpt = newRules
      .at(-1)
      ['condition-one'].options.slice();

    let conditionOptions = previousConditionOneSelectedVal
      ? previousConditionOneSelectedOpt.filter(
          (item) => item.value !== previousConditionOneSelectedVal,
        )
      : previousConditionOneSelectedOpt;

    setConditionsToAdd([...conditionsToAdd, newRules.length]);

    newRules.push({
      name: '',
      'condition-one': {
        options: conditionOptions,
        selectedValue: null,
      },
      'condition-two': {
        options: [],
        selectedValue: null,
      },
      'condition-three': {
        options: [],
        selectedValue: null,
      },
    });

    setPolicyConditions((prevValue) => ({ ...prevValue, rules: newRules }));
  };

  const removeCondition = (index) => {
    let newRules = policyConditions.rules.slice();
    let removedItem = newRules.splice(index, 1);

    if (conditionsToAdd.includes(index)) {
      setConditionsToAdd(
        conditionsToAdd
          .filter((item) => item !== index)
          .map((item) => (item > index ? item - 1 : item)),
      );
      setConditionsToUpdate(
        conditionsToUpdate.map((item) => (item > index ? item - 1 : item)),
      );
    }

    if (conditionsToUpdate.includes(index)) {
      setConditionsToUpdate(
        conditionsToUpdate
          .filter((item) => item !== index)
          .map((item) => (item > index ? item - 1 : item)),
      );
      setConditionsToAdd(conditionsToAdd.map((item) => (item > index ? item - 1 : item)));
    }

    setConditionsToRemove(
      [...conditionsToRemove, removedItem[0]?.code].filter((item) => !!item),
    );

    setSelectedTransactionTypes((prevValue) => {
      let newArray = prevValue.filter(
        (item) => item !== removedItem[0]?.['condition-one'].selectedValue?.value,
      );
      return newArray;
    });
    setPolicyConditions((prevValue) => ({ ...prevValue, rules: newRules }));
  };

  const handleSubmit = () => {
    const {
      description,
      name,
      requiresBudget,
      requiresDescription,
      requiresCategory,
      requiresReceipt,
      status,
    } = policyData;

    const rules = policyConditions?.rules;

    const checkPolicyData = formValidation({
      ...policyData,
      rules: rules,
    });
    if (checkPolicyData !== true) {
      return toastError(checkPolicyData);
    }

    const formattedConditionsToAdd = conditionsToAdd.map((item) => ({
      trigger: rules[item]['condition-one'].selectedValue.value,
      operator: rules[item]['condition-two'].selectedValue.value,
      operands:
        rules[item]['condition-one'].selectedValue.value === 'amount'
          ? (Number(rules[item]['condition-three'].selectedValue.value) * 100).toString()
          : Array.isArray(rules[item]['condition-three'].selectedValue)
          ? rules[item]['condition-three'].selectedValue.map((item1) => item1?.code)
          : [rules[item]['condition-three'].selectedValue?.code],
    }));

    const formattedConditionsToUpdate = conditionsToUpdate.map((item) => ({
      condition: rules[item].code,
      trigger: rules[item]['condition-one'].selectedValue.value,
      operator: rules[item]['condition-two'].selectedValue.value,
      operands:
        rules[item]['condition-one'].selectedValue.value === 'amount'
          ? (Number(rules[item]['condition-three'].selectedValue.value) * 100).toString()
          : Array.isArray(rules[item]['condition-three'].selectedValue)
          ? rules[item]['condition-three'].selectedValue.map((item1) => item1?.code)
          : [rules[item]['condition-three'].selectedValue?.code],
    }));

    if (
      !amountConditionValidation(
        [formattedConditionsToAdd].concat(formattedConditionsToUpdate),
      )
    )
      return;

    const documents = Object.values(uploadProgress).map((item) => item.assetCode);

    const policyStatus = !!status ? 'active' : 'inactive';
    const strict = policyData.violations === 'block';
    const payload = {
      code: singlePolicy?.code,
      description: description === singlePolicy?.description ? undefined : description,
      name: name === singlePolicy?.name ? undefined : name,
      requiresBudget:
        requiresBudget === singlePolicy?.requiresBudget ? undefined : requiresBudget,
      requiresCategory:
        requiresCategory === singlePolicy?.requiresCategory
          ? undefined
          : requiresCategory,
      requiresDescription:
        requiresDescription === singlePolicy?.requiresDescription
          ? undefined
          : requiresDescription,
      requiresReceipt:
        requiresReceipt === singlePolicy?.requiresReceipt ? undefined : requiresReceipt,
      strict: strict === singlePolicy?.strict ? undefined : strict,
      status: policyStatus === singlePolicy?.status ? undefined : policyStatus,
      exceptions: exemptedUsers.map((item) => item.value),
      documents,
      conditionsToAdd: formattedConditionsToAdd.length
        ? formattedConditionsToAdd
        : undefined,
      conditionsToUpdate: formattedConditionsToUpdate,
      conditionsToRemove: conditionsToRemove.length ? conditionsToRemove : undefined,
    };

    dispatch(editPolicy(payload));
  };

  return (
    <Modal
      show={true}
      scrollable={true}
      centered
      contentClassName="custom-content"
      dialogClassName="custom-dialog"
    >
      <div className="policy-form overview-holder form-wrapper__2 position-relative">
        <div style={{ top: '0px' }} className="position-sticky bg-white z-3">
          <div className="d-flex flex-sm-wrap align-items-start justify-content-between pt-4 px-4">
            <div>
              {' '}
              <h6 className="text-lg">Edit {singlePolicy?.name}</h6>
              <p className="text-sm mb-0">
                Define submission requirements and enforcement policies.
              </p>
            </div>
            <div className="d-flex">
              <CancelICon width="11" height="11" onClick={onHandleCancel} />
            </div>
          </div>
          <div className="divider"></div>
        </div>

        <div style={{ width: '100%', overflowY: 'auto' }} className="pb-4 px-4">
          <Row className="mb-4">
            <CustomInput
              label="Policy name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              className="mb-3"
              id="name"
              onChange={(event) =>
                setPolicyData({ ...policyData, name: event.target.value })
              }
              value={policyData?.name}
              md={10}
            />
            <Col md={10}>
              <CustomTextarea
                label="Description"
                name="description"
                placeholder="Enter policy description (e.g. All spend > ₦25,000 requires receipt)"
                value={text || policyData?.description}
                labelClass="text-sm"
                charCount={charCount}
                onChange={handleCharChange}
                rowSize={4}
              />
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Conditions</h6>
            <span>Set the criteria for policy-compliant expenses.</span>
          </Row>
          <Row className="mb-3">
            <ConditionRowComponent
              rules={policyConditions?.rules}
              getInputChange={getInputChange}
              removeCondition={removeCondition}
              getAmount={onHandleAmountChange}
              handleConditionChange={handleConditionChange}
            />

            <div
              className="add-button condition-cta mt-2"
              role="button"
              onClick={
                policyConditions?.rules.length === 6
                  ? () => toastError("You can't add more than 6 conditions")
                  : () => addNewCondition()
              }
            >
              <PlusDropDownIcon className="me-1" /> <span>Add condition</span>
            </div>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Requirements</h6>
            <span>Request details for matched transactions.</span>
          </Row>
          <Row className="mb-3">
            <Col md={10}>
              {[
                {
                  label: 'Require receipt',
                  property: 'requiresReceipt',
                  value: policyData?.requiresReceipt,
                },
                {
                  label: 'Require description',
                  property: 'requiresDescription',
                  value: policyData?.requiresDescription,
                },
                {
                  label: 'Require category',
                  property: 'requiresCategory',
                  value: policyData?.requiresCategory,
                },
                {
                  label: 'Require budget',
                  property: 'requiresBudget',
                  value: policyData?.requiresBudget,
                },
              ].map((item, index) => (
                <div key={index} className="mb-2">
                  <Checkbox
                    onChange={() =>
                      setPolicyData({ ...policyData, [item.property]: !item.value })
                    }
                    checked={item.value}
                  >
                    {item.label}
                  </Checkbox>
                </div>
              ))}
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Policy violation actions</h6>
            <span>
              Set how the system should handle expenses that violate the policy.
            </span>
          </Row>
          <Row className="mb-3">
            <Col md={10}>
              {[
                {
                  label: 'Block submission',
                  subtext:
                    'Expenses violating the policy cannot be submitted by the user',
                  value: 'block',
                },
                {
                  label: 'Allow and flag as Out-of-Policy',
                  subtext:
                    'Expenses violating the policy cannot be submitted by the user',
                  value: 'flag',
                },
              ].map((item, index) => (
                <div key={index} className="mb-2">
                  <Radio
                    defaultChecked={false}
                    checked={item.value === policyData?.violations}
                    onChange={() =>
                      setPolicyData({ ...policyData, violations: item.value })
                    }
                  >
                    <span className="text-sm">{item.label}</span>
                    <br />
                    <span style={{ color: '#79716B' }} className="text-xs">
                      {item.subtext}
                    </span>
                  </Radio>
                </div>
              ))}
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Exceptions</h6>
            <span>Exclude certain users from the rule.</span>
          </Row>
          <Row className="mb-3">
            <Col md={10}>
              <MultipleSelect
                label="Users"
                placeholder={
                  <span className="aside-placeholder">
                    <Avatar /> <span className="ms-1 text-sm">Select a user ...</span>
                  </span>
                }
                name="managers"
                options={beneficiaryList}
                onChange={(value) => handleBeneficiarySelect(value, 'users')}
                value={exemptedUsers}
                onInputChange={(value) => getInputChange(value, 'users')}
                extendComponent={
                  <span className="d-flex align-items-center gap-2">
                    {beneficiaryList?.length === 0 ? <MinusOutlined /> : <PlusOutlined />}
                    {isLengthEqualUsers ? 'Unselect all managers' : 'Select all managers'}
                  </span>
                }
                selectAllBudgets={() =>
                  handleSelectAllBeneficiary(beneficiaryList, 'users')
                }
                isLoading={false}
                formatOptionLabel={formatSelectedOptionLabel}
                onMenuClose={onMenuClose}
              />
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Related documents</h6>
          </Row>
          <Row className="mb-3">
            <Col md={10}>
              <FileUpload multiple isRounded={true} showUploadedFiles={true} />
            </Col>
          </Row>
        </div>

        <div style={{ bottom: '0px' }} className="position-sticky bg-white">
          <div className="divider"></div>

          <div className="d-flex justify-content-between mt-3 pb-4 px-4">
            <div className="d-flex align-items-center gap-2">
              <CustomSwitch
                onChange={(status) => setPolicyData({ ...policyData, status })}
                checked={policyData?.status}
                fullWidth={false}
              />
              <span className="text-sm flex-grow-1"> Enable this policy</span>
            </div>
            <div className="d-flex gap-2">
              <CustomButton
                onClick={onHandleCancel}
                className="add-button add-action me-3"
                customClass={true}
              >
                Discard
              </CustomButton>
              <CustomButton
                className="add-button dark-button"
                customClass={true}
                onClick={handleSubmit}
                loading={loading}
              >
                Save changes
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditPolicy;
