import './custom.styles.scss';
import classNames from 'classnames';

const CustomDrawer = ({ show, children, customClass = '' }) => {
  return (
    // <section className={show ? 'show-drawer custom-drawer' : 'custom-drawer'}>
    <section
      className={classNames('custom-drawer', { 'show-drawer': show }, customClass)}
    >
      <div className="drawer-content-wrapper">{children}</div>
    </section>
  );
};

export default CustomDrawer;
