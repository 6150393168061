import {
  GENERATE_ACCOUNT_STATEMENT_REQUEST,
  DOWNLOAD_ACCOUNT_STATEMENT_REQUEST,
} from 'redux/reducers/AccountStatementReducer';

export const generateAccountStatements = (payload) => ({
  type: GENERATE_ACCOUNT_STATEMENT_REQUEST,
  payload,
});

export const downlaodAccountStatements = (payload) => ({
  type: DOWNLOAD_ACCOUNT_STATEMENT_REQUEST,
  payload,
});
