import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSelectedTransaction } from 'redux/actions/TransactionsAction';
import { buildTransactionTableData } from 'utils/helper';
import { columns } from 'utils/mockData';
import {
  CurrencyType,
  TransactionStatusType,
} from '../../../components/FilterModal/FilterHelper';

const TeamTransaction = () => {
  const { teamsId } = useParams();
  const dispatch = useDispatch();

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    getSelectedTransaction: { data: { transactions = [], meta = {} } = {}, loading },
  } = useSelector(({ transaction }) => transaction);
  const { page, total, hasMore, perPage, nextPage } = meta;

  const [isFiltered, setIsFiltered] = useState(false);
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatusType,
    ...CurrencyType,
  ]);
  const [isFillData, setIsFillData] = useState(false);

  const [search, setSearch] = useState('');

  const debouncedValue = useDebounce(search, 600);
  useEffect(() => {
    if (debouncedValue)
      dispatch(getSelectedTransaction({ team: teamsId, search: debouncedValue }));
    if (!debouncedValue && isFiltered) {
      dispatch(getSelectedTransaction({ team: teamsId }));
    }
    if (debouncedValue) setIsFiltered(true);
  }, [debouncedValue]);

  useEffect(() => {
    if (budgetData?.budgets?.length && transactions.length) {
      if (!isFillData) {
        setIsFillData(true);
        const data = [];
        budgetData?.budgets.forEach((b) => {
          const { code, name } = b;
          data.push({
            value: code,
            label: name,
            isSelected: false,
          });
        });

        const allTransactions = transactions?.map((t) => {
          return {
            value: t.payer ? t.payer.code : null,
            label: t.payer ? `${t.payer.firstName} ${t.payer.lastName}` : 'N/A',
            isSelected: false,
          };
        });
        const uniqueArray = allTransactions.filter(
          (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i,
        );
        // setFilterData([...filterData, { title: 'Payer', list: uniqueArray }]);

        setFilterData([
          ...filterData,
          { title: 'Budget', list: data },
          { title: 'Payer', list: uniqueArray },
        ]);
      }
    }
  }, [transactions]);

  const handleFilterApply = (query) => {
    dispatch(getSelectedTransaction({ team: teamsId, ...query }));
    setFilteredQuery({ team: teamsId, ...query });
    setIsFiltered(!!Object.keys(query).length);
  };

  const handlePreviousPage = (page) => {
    dispatch(getSelectedTransaction({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getSelectedTransaction({ perPage, page, ...filteredQuery }));
  };
  if (loading && !isFiltered) return <Loading isPage color="#D28B28" />;

  return (
    <div>
      <PendingOnboardingNotice />

      <TopBar
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilterApply}
        withDate
        setSearchVal={setSearch}
        searchVal={search}
        dateTitle="Transaction Date"
        withOutSearch
        showBarSearch={transactions?.length || (isFiltered && !transactions?.length)}
        showFilter={transactions?.length || (isFiltered && !transactions?.length)}
      />
      {!transactions.length ? (
        <div className="tabinnerWrapper">
          {isFiltered ? (
            <NoData
              headerText="You have no team transactions for this filter"
              bodyText="Alter the filter to see your transactions"
              withButton={false}
            />
          ) : (
            <NoData
              headerText="No team transactions yet"
              bodyText="Your team transactions will be listed here"
              withButton={false}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row>
              <Col md={12} className="mb-3">
                <Table
                  columns={columns}
                  data={buildTransactionTableData(transactions)}
                  pagination
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};
export default TeamTransaction;
