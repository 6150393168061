import {
  endOfMonth,
  startOfMonth,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subYears,
  startOfYear,
  endOfYear,
} from 'date-fns';
import { getFromAndTo } from 'utils/utility';

// moment.locale('fr', {
//   months:
//     'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
//       '_',
//     ),
//   monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split(
//     '_',
//   ),
//   monthsParseExact: true,
//   weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
//   weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
//   weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
//   weekdaysParseExact: true,
//   longDateFormat: {
//     LT: 'HH:mm',
//     LTS: 'HH:mm:ss',
//     L: 'DD/MM/YYYY',
//     LL: 'D MMMM YYYY',
//     LLL: 'D MMMM YYYY HH:mm',
//     LLLL: 'dddd D MMMM YYYY HH:mm',
//   },
//   calendar: {
//     sameDay: '[Aujourd’hui à] LT',
//     nextDay: '[Demain à] LT',
//     nextWeek: 'dddd [à] LT',
//     lastDay: '[Hier à] LT',
//     lastWeek: 'dddd [dernier à] LT',
//     sameElse: 'L',
//   },
//   relativeTime: {
//     future: 'dans %s',
//     past: 'il y a %s',
//     s: 'quelques secondes',
//     m: 'une minute',
//     mm: '%d minutes',
//     h: 'une heure',
//     hh: '%d heures',
//     d: 'un jour',
//     dd: '%d jours',
//     M: 'un mois',
//     MM: '%d mois',
//     y: 'un an',
//     yy: '%d ans',
//   },
//   dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
//   ordinal: function (number) {
//     return number + (number === 1 ? 'er' : 'e');
//   },
//   meridiemParse: /PD|MD/,
//   isPM: function (input) {
//     return input.charAt(0) === 'M';
//   },
//   // In case the meridiem units are not separated around 12, then implement
//   // this function (look at locale/id.js for an example).
//   // meridiemHour : function (hour, meridiem) {
//   //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
//   // },
//   meridiem: function (hours, minutes, isLower) {
//     return hours < 12 ? 'PD' : 'MD';
//   },
//   week: {
//     dow: 1, // Monday is the first day of the week.
//     doy: 4, // Used to determine first week of the year.
//   },
// });

// const getDateCalendar = (date, format = 'MMMM YYYY') => {
//   const localLocale = moment(date);
//   localLocale.locale('fr');
//   return localLocale.format(format);
// };

// export { getDateCalendar };

export const checkForEmptyValue = (payload) => {
  let error = [];
  for (const key in payload) {
    if (Array.isArray(payload[key])) {
      payload[key].forEach((item) => {
        for (const itemKey in item) {
          if (Array.isArray(item[itemKey])) {
            if (item[itemKey].length == 0) {
              error.push(true);
            } else {
              error.push(false);
            }
          } else if (item[itemKey] !== '') {
            error.push(false);
          } else if (item[itemKey] === '') {
            error.push(true);
          }
        }
      });
    } else if (payload[key] !== '') {
      continue;
    }
  }
  return error.includes(true);
};

export const obfuscateEmail = (email = '') => {
  let [alias, domain] = email?.split('.')[0].split('@');
  return alias
    ?.substring(0, Math.min(alias.length, 3))
    .concat('•••@')
    .concat(`•••${domain?.substring(6, 3).concat('.com')}`);
};

export const returnSliceString = (stringValue, length) => {
  if (stringValue?.length > length) {
    return stringValue?.slice(0, length) + '...';
  }
  return stringValue;
};

export const MAXLENGTH = {
  requestDescription: 500,
  reimbursementDescription: 500,
  singlePaymentDescription: 1000,
  invoiceNoteLength: 100,
};

export function isNotEmpty(value) {
  if (value === '' || value === undefined) {
    return false;
  }

  if (typeof value === 'object') {
    return Object.values(value).some(isNotEmpty);
  }

  return true;
}

export function validateObjectData(obj) {
  const emptyKeys = Object.keys(obj).filter((key) => !obj[key]);

  if (emptyKeys.length > 0) {
    return {
      isValid: false,
      message: `Please fill in the values for ${emptyKeys.join(', ')}.`,
    };
  }
  // All keys have values, validation passed
  return { isValid: true, message: null };
}

export function validateFormTable(obj, unRequiredFields = []) {
  const formKeys = Object.keys(obj).filter((key) => key !== 'id');
  const emptyKeys = formKeys.filter(
    (key) => !unRequiredFields.includes(key) && !obj[key],
  );

  const unTouchedRow =
    formKeys.length === emptyKeys.length + unRequiredFields.length ? obj.id : null;

  if (
    formKeys.length !== emptyKeys.length + unRequiredFields.length &&
    emptyKeys.length > 0
  ) {
    return {
      isValid: false,
      message: `Please fill in the values for ${emptyKeys.join(', ')}.`,
      unTouchedRow,
    };
  }
  // All keys have values, validation passed
  return { isValid: true, message: null, unTouchedRow };
}

export const getDateRangeFromTitle = (title, date) => {
  Date.prototype.addDays = function (days) {
    // Add days to given date
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  const today = new Date();

  let range;

  switch (title) {
    case 'Today':
      range = getFromAndTo([{ startDate: today, endDate: today }]);
      break;
    case 'Last 7 days':
      range = getFromAndTo([{ startDate: today.addDays(-7), endDate: today }]);
      break;
    case 'Last 30 days':
      range = getFromAndTo([{ startDate: today.addDays(-30), endDate: today }]);
      break;
    case 'This Month':
      const startMonth = startOfMonth(today);
      const endMonth = endOfMonth(today);
      range = getFromAndTo([{ startDate: startMonth, endDate: endMonth }]);
      break;
    case 'Last Month':
      const lastMonth = new Date(`${today.getFullYear()}-${today.getMonth()}-01`);
      const endLastMonthDate = endOfMonth(lastMonth);
      range = getFromAndTo([{ startDate: lastMonth, endDate: endLastMonthDate }]);
      break;
    case 'This Quarter':
      const thisQuarterStarts = startOfQuarter(today);
      const thisQuarterEnds = endOfQuarter(today);
      range = getFromAndTo([{ startDate: thisQuarterStarts, endDate: thisQuarterEnds }]);
      break;
    case 'Last Quarter':
      const lastQuarterDate = subQuarters(today, 1);
      range = getFromAndTo([
        {
          startDate: startOfQuarter(lastQuarterDate),
          endDate: endOfQuarter(lastQuarterDate),
        },
      ]);
      break;
    case 'Last Semester':
      const twoQuarterAway = subQuarters(today, 2);
      const oneQuarterAway = subQuarters(today, 1);
      range = getFromAndTo([
        {
          startDate: startOfQuarter(twoQuarterAway),
          endDate: endOfQuarter(oneQuarterAway),
        },
      ]);
      break;
    case 'This Year':
      const startYear = startOfYear(today);
      const endYear = endOfYear(today);
      range = getFromAndTo([{ startDate: startYear, endDate: endYear }]);
      break;
    case 'Last 12 months':
      const lastYearStartDate = subYears(today, 1);
      const lastYearEndDate = today;
      range = getFromAndTo([{ startDate: lastYearStartDate, endDate: lastYearEndDate }]);
      break;
    default:
      range = getFromAndTo(date);
  }

  return { from: range?.from, to: range?.to };
};
