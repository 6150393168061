import { CloseOutlined } from '@ant-design/icons';
import Modal from 'components/UI/Modal';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';

import { useDispatch, useSelector } from 'react-redux';

import TransactionInfo from 'components/TransactionModal/TransactionInfo';
import Loading from 'components/UI/Loading';
import CurrencyFormat from 'react-currency-format';
import { fetchAllCustomerAction } from 'redux/actions/InvoiceAction';
import { getCurrency, getFormattedDate, getInternationalFormat } from 'utils/helper';

const CustomerModal = ({ isOpen, toggleHandler, selected }) => {
  if (!isOpen) return <div />;

  const dispatch = useDispatch();
  const {
    customer: { loading, success, data },
  } = useSelector(({ invoices }) => invoices);

  useEffect(() => {
    if (isOpen && selected) {
      dispatch(fetchAllCustomerAction({ code: selected }));
    }
  }, [isOpen, selected]);
  const FormContent = () => (
    <div className="card-wrapper">
      <h2 className="card-title w-100">Customer details</h2>
      {loading && (
        <section className="mt-4 pt-4">
          <Loading size={40} color="#d28b28" />
        </section>
      )}
      {!loading && success && (
        <section className="body">
          <h6 className="title">Customer information</h6>
          <Row className="mb-2">
            <Col col={6}>
              <TransactionInfo title="Full name" value={data?.customer?.name} />
            </Col>
            <Col col={6}>
              <TransactionInfo
                title="Date created"
                value={`${getFormattedDate(data?.customer?.created_at)}`}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col col={6}>
              <TransactionInfo
                title="Email address"
                renderProp={() => (
                  <a href={`mailto:${data?.customer.email}`} className="tel-style">
                    {data?.customer?.email}
                  </a>
                )}
              />
            </Col>
            <Col col={6}>
              <TransactionInfo
                title="Phone number"
                renderProp={() => (
                  <a
                    href={`tel:${getInternationalFormat(
                      data?.customer?.phoneNumber?.countryCode,
                      data?.customer.phoneNumber?.localFormat,
                    )}`}
                    className="tel-style"
                  >
                    {getInternationalFormat(
                      data?.customer?.phoneNumber?.countryCode,
                      data?.customer.phoneNumber?.localFormat,
                    )}
                  </a>
                )}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col col={6}>
              <TransactionInfo
                title="Billing address"
                value={data?.customer?.address?.street ?? '-'}
              />
            </Col>
            <Col col={6}>
              <TransactionInfo
                title="Country"
                value={`${data?.customer?.address?.country ?? '-'}`}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col col={6}>
              <TransactionInfo
                title="State"
                value={data?.customer?.address?.state ?? '-'}
              />
            </Col>
            <Col col={6}>
              <TransactionInfo
                title="City"
                value={`${data?.customer?.address?.city ?? '-'}`}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col col={6}>
              <TransactionInfo
                title="Postal code"
                value={data?.customer?.address?.postalCode ?? '-'}
              />
            </Col>
            <Col col={6}>
              <TransactionInfo
                title="Tin"
                value={`${data?.customer?.taxIdentifcationNumber ?? '-'}`}
              />
            </Col>
          </Row>
          <hr className="divider" />
          <h6 className="title">Activity summary</h6>

          {data?.transactions_volume?.map((transaction, i) => {
            return (
              <Row className="mb-2" key={i}>
                <Col col={6}>
                  <TransactionInfo
                    title={`${transaction?.currency} transaction count`}
                    value={`${transaction?.count}`}
                  />
                </Col>
                <Col col={6}>
                  <TransactionInfo
                    title={`Total collected in ${transaction?.currency}`}
                    value={
                      <CurrencyFormat
                        prefix={getCurrency(transaction?.currency)}
                        value={transaction?.amount ? transaction?.amount / 100 : 0}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                  />
                </Col>
              </Row>
            );
          })}

          {/* <Row className="mb-2">
            <Col col={6}>
              <TransactionInfo
                title="Last Activity"
                value={
                  <CurrencyFormat
                    prefix={getCurrency(data?.customer?.invoice[0]?.currency)}
                    value={
                      data?.customer?.invoice[0]?.amount
                        ? data?.customer?.invoice[0]?.amount / 100
                        : 0
                    }
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
              />
            </Col>
          </Row> */}
        </section>
      )}
    </div>
  );

  return (
    <div>
      <Modal show={isOpen} onClose={toggleHandler}>
        <div className="content">
          <div className="card-modal-header">
            <>
              <div className="d-flex align-items-center cursor" onClick={toggleHandler}>
                <CloseOutlined />
                <span className="ps-1">Cancel</span>
              </div>
            </>
          </div>
          <div className="card-modal-body">{FormContent()}</div>
        </div>
      </Modal>
    </div>
  );
};
export default CustomerModal;
