import { BarChartOutlined, LineChartOutlined } from '@ant-design/icons';
import cs from 'classnames';
import AreaChartView from 'components/charts/AreaChart';
import { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'components/UIHooks/Rechart';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/arrowUp-green.svg';
import { data, noData } from '../mock';
import TotalAmount from './TotalAmount';
import './styles.scss';

const LineChartView = ({
  hideXAxis = false,
  chart = data,
  size,
  totalPercentage,
  totalSpent,
  amountType = 'Total spent',
  widthFilter,
  chartWrapperClass,
  currency = 'NGN',
  noDataPlaceholder,
}) => {
  const [activeChart, setActiveChart] = useState(1);

  return (
    <div className={cs('chartWrapper', { [chartWrapperClass]: chartWrapperClass })}>
      <div className="chart-header">
        <TotalAmount
          type={totalSpent > 0 && amountType}
          title={
            totalSpent > 0 && (
              <>
                <CurrencyFormat
                  prefix={getCurrency(currency)}
                  value={numFormatter(totalSpent / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
                <span className="arrow-wrapper-percentage active ms-2">
                  <ArrowUp className="mr-3" /> {totalPercentage}%
                </span>
              </>
            )
          }
          customClass="mt-4"
          subTitle={noDataPlaceholder && noDataPlaceholder}
        />
        <div className="chartActon">
          {widthFilter && widthFilter}
          <button
            disabled={!chart.length}
            className={cs('chartActon-link', {
              ['active']: activeChart === 1 && chart.length > 0,
            })}
            onClick={() => setActiveChart(1)}
          >
            <LineChartOutlined className="pe-1" /> Line
          </button>

          <button
            disabled={!chart.length}
            className={cs('chartActon-link', {
              ['active']: activeChart === 2 && chart.length > 0,
            })}
            onClick={() => setActiveChart(2)}
          >
            <BarChartOutlined className="pe-1" /> Bar
          </button>
        </div>
      </div>

      {activeChart === 1 && (
        <AreaChartView
          data={chart}
          currency={currency}
          heightSize={500}
          showTotalSpent={false}
        />
      )}

      {activeChart === 2 && (
        <div className={cs({ ['light']: hideXAxis })}>
          <ResponsiveContainer width="100%" height={size === 'small' ? 100 : 200}>
            <BarChart width={150} height={40} data={chart.length > 0 ? chart : noData}>
              {!hideXAxis && <XAxis dataKey="name" />}
              <Bar dataKey="spent" fill="#D28B28" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
export default LineChartView;
