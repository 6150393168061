import { Skeleton } from 'antd';
import Table from 'react-bootstrap/Table';

const TableLoading = ({ columns }) => {
  return (
    <div className="table-wrapper cursor table-wrapper__border">
      <Table>
        <thead>
          <tr>
            {columns.map((column, i) => (
              <th key={i}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody className="border-top-0">
          {Array.from({ length: 10 }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((_, i) => (
                <td key={i}>
                  <Skeleton.Input
                    active
                    className={`mt-1 ${
                      i === columns.length - 1 ? 'd-flex justify-content-center' : ''
                    }`}
                    style={{
                      borderRadius: 4,
                      height: i === columns.length - 1 ? 14 : 12,
                      width: i === columns.length - 1 ? '10px' : '100px',
                      minWidth: i === columns.length - 1 ? '10px' : '100px',
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableLoading;
