import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getSingleSubAccount as getSingleBalance,
  getSubAccountMembers,
  getSubAccountTransactions,
} from 'redux/actions/BudgetsAction';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import MemberTable from './Components/MemberTable';
import TransactionTable from './Components/TransactionTable';
import SelectedSubAccountHeader from './SelectedSubAccountHeader';

const SubAccountView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const locationArray = location.pathname.split('/');
  const { subAccountCode } = useParams();

  const { isAdmin, permissions } = allPermissions();

  const [openSwapModal, setOpenSwapModal] = useState(false);
  const [statementModal, setStatementModal] = useState(false);

  const {
    getSingleSubAccount: {
      data: singleBalance,
      loading: singleBalanceLoading,
      success: singleBalanceSuccess,
    },
  } = useSelector(({ budgets }) => budgets);

  const {
    getSubAccountMembers: {
      data: { meta: { total: totalSubAccountMemebers } = {} } = {},
    },
    getSubAccountTransactions: {
      data: { meta: { total: totalTransactions } = {} } = {},
      pageSource,
      loading: transactionLoading,
    },
  } = useSelector(({ budgets }) => budgets);

  const toggleStatementModal = () => {
    setStatementModal((prevValue) => !prevValue);
  };

  // date range selector
  const { selectedDateRange } = useSelector(({ overview }) => overview);

  const currentLocation = locationArray[locationArray.length - 1];

  const [key, setKey] = useState(
    currentLocation === subAccountCode ? 'transactions' : currentLocation,
  );

  const canViewDash = hasPermission({
    permissions,
    scopes: ['org-*', 'dash-*', 'dash-view', 'dash-edit'],
  });

  useEffect(() => {
    const samePage = singleBalance?.code === subAccountCode;

    if (!samePage || pageSource !== 'subaccounts') {
      dispatch(
        getSubAccountTransactions({ code: subAccountCode, pageSource: 'subaccounts' }),
      );
    }

    if (!samePage) {
      dispatch(getSingleBalance(subAccountCode));
      dispatch(getSubAccountMembers({ code: subAccountCode }));
    }

    // return () => {
    //   dispatch({ type: RESET_FLAGS_BUDGET, blockType: 'getSingleSubAccount' });
    // };
    // dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
  }, []);

  useEffect(() => {
    if (singleBalanceSuccess) {
      if (singleBalance?.subaccount === false) {
        dispatch({ type: RESET_BLOCK_BUDGET, blockType: 'getSingleSubAccount' });
        history.push('/404');
      }
    }
  }, [singleBalanceSuccess]);

  if (singleBalanceLoading) return <Loading isPage color="#D28B28" />;

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    history.push(`/accounts/subaccounts/${subAccountCode}/${tabKey}`);
  };

  const handleSwapping = () => {
    setOpenSwapModal((prevValue) => !prevValue);
  };

  if (singleBalanceLoading) return <Loading isPage color="#D28B28" />;

  return (
    <div className="page-wrapper pb-3">
      <div className="w-100">
        <Container>
          <SelectedSubAccountHeader
            data={singleBalance}
            handleSwapping={handleSwapping}
            openSwapModal={openSwapModal}
            canViewDash={canViewDash}
            permissionForOwnersAndManagers={
              ['owner', 'manager'].includes(
                singleBalance?.loggedInMember?.designation?.toLowerCase(),
              ) || isAdmin
            }
            statementModal={statementModal}
            setStatementModal={setStatementModal}
          />
          <Row>
            <Col xs={12} className="mt-4">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={handleSelect}
                className="mb-3"
              >
                <Tab
                  eventKey="transactions"
                  title={
                    <div className="d-flex">
                      Transactions{' '}
                      <div className="count">
                        <span className="m-auto">
                          {transactionLoading ? 0 : totalTransactions || 0}
                        </span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <TransactionTable
                    accountCode={subAccountCode}
                    handleSwapping={handleSwapping}
                    permissionForOwnersAndManagers={
                      ['owner', 'manager'].includes(
                        singleBalance?.loggedInMember?.designation?.toLowerCase(),
                      ) || isAdmin
                    }
                    pageSource={'subaccounts'}
                    openStatementModal={toggleStatementModal}
                  />
                </Tab>
                <Tab
                  eventKey="members"
                  title={
                    <div className="d-flex">
                      Members{' '}
                      <div className="count">
                        <span className="m-auto">{totalSubAccountMemebers || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <MemberTable
                    permissionForOwnersAndManagers={
                      ['owner', 'manager'].includes(
                        singleBalance?.loggedInMember?.designation?.toLowerCase(),
                      ) || isAdmin
                    }
                    subAccountCode={subAccountCode}
                    designation={singleBalance?.loggedInMember?.designation?.toLowerCase()}
                  />
                </Tab>
                {/* {hasPermission({
                  permissions,
                  scopes: ['card-*', 'card-view'],
                }) && (
                  <Tab
                    eventKey="cards"
                    title={
                      <div className="d-flex">
                        Cards{' '}
                        <div className="count">
                          <span className="m-auto">{0}</span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  ></Tab>
                )} */}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default SubAccountView;
