import { RolesType, StatusType } from 'components/FilterModal/FilterHelper';
import Loading from 'components/UI/Loading';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { RESET_BLOCK_ACCOUNT_STATEMENT } from 'redux/reducers/AccountStatementReducer';
import NoData from '../../components/NoData';
import TopBar from '../../components/TopBar';
import './style.less';
import Table from 'components/Table';
import { columnsAccountStatement } from 'utils/mockData';
import { buildStatementTableData } from 'utils/helper';
import {
  downlaodAccountStatements,
  generateAccountStatements,
} from 'redux/actions/AccountStatementAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const AccountStatementTable = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([...StatusType, ...RolesType]);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const querys = {
    source: searchParams.get('source'),
    type: searchParams.get('type'),
    startDate: searchParams.get('startDate'),
    endDate: searchParams.get('endDate'),
  };

  const [filteredQuery, setFilteredQuery] = useState({ ...querys });
  const { generateStatement: { loading, success, data: statementData = [] } = {} } =
    useSelector(({ accountStatement }) => accountStatement);

  const { permissions } = allPermissions();

  const canExport = hasPermission({
    permissions,
    scopes: ['statement-export', 'statement-*'],
  });

  useEffect(() => {
    if (success) {
      if (canExport) dispatch(downlaodAccountStatements(filteredQuery));
    }
  }, [success, statementData]);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleFilter = (query) => {
    dispatch(getBeneficiaries(query));
    setFilteredQuery(query);
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleBack = () => {
    dispatch({ type: RESET_BLOCK_ACCOUNT_STATEMENT, blockType: 'generateStatement' });
    history.push({
      pathname: '/',
    });
  };

  useEffect(() => {
    if (!filteredQuery.source)
      return history.push({
        pathname: '/',
      });
    if (!statementData.length) dispatch(generateAccountStatements(filteredQuery));

    return () => {
      dispatch({ type: RESET_BLOCK_ACCOUNT_STATEMENT, blockType: 'generateStatement' });
    };
  }, []);

  // const { page, total, hasMore, perPage, nextPage } = meta;

  // const handlePreviousPage = (page) => {
  //   dispatch(generateAccountStatements({ perPage, page, ...filteredQuery }));
  // };

  // const handleNextPage = (page) => {
  //   dispatch(generateAccountStatements({ perPage, page, ...filteredQuery }));
  // };

  return (
    <div className="beneficiaries-wrapper">
      <TopBar
        addOnClick={toggleHandler}
        filterData={filterData}
        inputPlaceholder="Search by name or email"
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withDate
        addExport={false}
      />
      {!!statementData?.length ? (
        <Container className="px-0">
          <Row>
            {loading ? (
              <Loading />
            ) : (
              <Col xs={12} className="mb-4">
                <Table
                  columns={columnsAccountStatement}
                  data={buildStatementTableData(statementData)}
                  // pagination
                  // hasMore={hasMore}
                  // currentPage={page}
                  // nextPage={() => handleNextPage(nextPage)}
                  // previousPage={() => handlePreviousPage(page - 1)}
                  // totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <div className="tabinnerWrapper">
          {filtered ? (
            <NoData
              headerText="You have no statements for this filter"
              bodyText="Alter filter to see statements..."
              withButton={false}
            />
          ) : (
            <NoData
              headerText="You have no statement yet"
              bodyText={
                'You can go back to the overview page to generate an account statement'
              }
              buttonLabel={'Generate Statement'}
              withButton={true}
              onClickHandler={handleBack}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AccountStatementTable;
