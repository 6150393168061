import { ReactComponent as GrayBgCalender } from 'assets/icons/background-calender.svg';
import { CancelICon } from 'assets/icons/index';
import classNames from 'classnames';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomSelect from 'components/UI/CustomSelect';
import { getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { recurrencePaymentOption } from 'utils/helper';
import './styles.scss';
import { toastError } from 'components/UI/toast';

const initailState = {
  startDate: undefined,
  repeat: '',
  endDate: undefined,
  schedule: true,
};

const SchedulePayment = ({ onClose, loading, setSchedule, extraChild }) => {
  const [paymentData, setPaymentData] = useState(initailState);

  // handle input state changes
  const handleChange = ({ target: { name, value, validity, rawValue } }) => {
    if (name === 'accountNumber')
      return (
        validity.valid &&
        setPaymentData((prevPaymentData) => ({ ...prevPaymentData, [name]: value }))
      );
    return setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [name]: rawValue ? rawValue : value,
    }));
  };

  const isNotOneTime =
    paymentData?.repeat?.value && paymentData?.repeat?.value !== 'one-time';

  // handle submit
  const handleSubmit = () => {
    if (paymentData.schedule) {
      if (!paymentData.startDate) return toastError('Please enter a start date');
      if (!paymentData.repeat) return toastError('Please select a repeat pattern');
      if (!paymentData.endDate && isNotOneTime)
        return toastError('Please enter a end date');

      if (paymentData.endDate) {
        const isEndDateBeforeStartDate = dayjs(paymentData.endDate).isBefore(
          dayjs(paymentData.startDate),
        );
        if (isEndDateBeforeStartDate)
          return toastError('Start date should be ahead of end date!');
      }
    }

    const startDateObject = dayjs(paymentData.startDate);
    const endDateObject = dayjs(paymentData.endDate);

    const minutes = startDateObject.format('mm');
    const hours = startDateObject.format('HH');
    const dayOfMonth = startDateObject.format('DD');
    const month = startDateObject.format('MMMM').toLowerCase();
    const dayOfWeek = startDateObject.format('dddd').toLowerCase();
    const startDateFormatted = startDateObject.format('YYYY-MM-DD');
    const startTimestamp = startDateObject.format('HH:mm:ss');

    const endDateFormatted = endDateObject.format('YYYY-MM-DD');
    const endTimestamp = endDateObject.format('HH:mm:ss');

    const scheduleTransaction = {
      schedule: isNotOneTime
        ? paymentData?.repeat?.value
        : {
            minutes,
            hours,
            dayOfMonth,
            month,
            dayOfWeek,
          },
      startDate: {
        date: startDateFormatted,
        timestamp: startTimestamp,
      },
      expiryDate: isNotOneTime
        ? {
            date: endDateFormatted,
            timestamp: endTimestamp,
          }
        : undefined,

      recurring: isNotOneTime,
    };

    setSchedule({ schedule: scheduleTransaction });
  };
  //

  const formatOptionLabel = (props) => {
    const { value, label, details, isDisabled } = props;
    return (
      <div className={classNames('format-recurrence-option')}>
        <h1 className={classNames({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={classNames({ ['text-muted']: isDisabled })}>{details}</span>
      </div>
    );
  };

  useEffect(() => {
    if (paymentData.startDate)
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        repeat: recurrencePaymentOption(paymentData.startDate)[0],
      }));
  }, [paymentData.startDate]);

  const handleDateChange = (date, name) => {
    const currentHours = getHours(new Date());
    const currentMinutes = getMinutes(new Date());
    const value = setMinutes(setHours(date, currentHours), currentMinutes + 5);

    if (!paymentData[name]) {
      handleChange({ target: { name: name, value } });
    } else {
      handleChange({ target: { name: name, value: date } });
    }
  };

  return (
    <div>
      <div className="mandate-modal-overlay" onClick={onClose} />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body invoice payment-schedule">
          <i onClick={onClose} className="cancel-icon">
            <CancelICon width="10" height="10" />
          </i>
          <div className="w-100">
            <div className="mt-0 w-100 mandate-wrapper ">
              <div className="p-4">
                <h4 className="text-start header">Schedule payment</h4>
              </div>

              <div className="payment__schedule-wrapper rounded-0 px-4">
                <div className="d-flex justify-content-between align-items-start w-100">
                  <div className="d-flex gap-3 align-items-center">
                    <GrayBgCalender />
                    <div className="schedule-label">
                      <h6>Schedule</h6>
                      <p>or set up recurring payment</p>
                    </div>
                  </div>
                </div>

                <div className="form-normal w-100 mx-0 px-0">
                  <div className="mb-2 mx-0 px-0">
                    <CustomDatePicker
                      wrapperClass="px-0 mx-0"
                      label="Date"
                      onChange={(value) => handleDateChange(value, 'startDate')}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      showTimeSelect
                      timeCaption="Time"
                      name="startDate"
                      peekNextMonth
                      minDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Start date"
                      selected={
                        paymentData.startDate && dayjs(paymentData.startDate).toDate()
                      }
                    />
                  </div>

                  <div className="mt-3 mx-0 px-0">
                    <CustomSelect
                      label="Repeats"
                      name="repeat"
                      placeholder="Repeats"
                      isDisabled={!paymentData.startDate}
                      options={recurrencePaymentOption(paymentData.startDate)}
                      formatOptionLabel={formatOptionLabel}
                      onChange={(repeat) =>
                        setPaymentData((prevPaymentData) => ({
                          ...prevPaymentData,
                          repeat,
                        }))
                      }
                      value={paymentData.repeat}
                      wrapperClass="px-0 mx-0"
                    />
                  </div>
                  {isNotOneTime && (
                    <div className="my-2 mx-0 px-0">
                      <CustomDatePicker
                        label="End date"
                        onChange={(value) => handleDateChange(value, 'endDate')}
                        disabled={paymentData.repeat.value === 'one-time'}
                        timeInputLabel="Time:"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showTimeSelect
                        timeCaption="Time"
                        name="endDate"
                        peekNextMonth
                        minDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="End date"
                        selected={
                          paymentData.endDate && dayjs(paymentData.endDate).toDate()
                        }
                      />
                    </div>
                  )}

                  {extraChild}
                </div>
              </div>

              <div className="px-4 py-3 d-flex justify-content-end gap-3 ms-auto w-50">
                <button disabled={loading} className="cancel-button " onClick={onClose}>
                  Cancel
                </button>

                <button
                  className="confirm-button"
                  // loading={loading}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulePayment;
