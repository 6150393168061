import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import {
  GET_MEMBER_PROFILE_ERROR,
  GET_MEMBER_PROFILE_REQUEST,
  GET_MEMBER_PROFILE_SUCCESS,
  GET_PROFILE_STATS_ERROR,
  GET_PROFILE_STATS_REQUEST,
  GET_PROFILE_STATS_SUCCESS,
  GET_SINGLE_USER_ERROR,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
} from 'redux/reducers/MemberProfileReducer';

async function getMemberProfile({ code }) {
  return await Axios.get(`/beneficiaries/${code}`);
}
function* handleGetMemberProfile({ payload }) {
  try {
    const response = yield call(getMemberProfile, payload);
    if (response) {
      yield put({
        type: GET_MEMBER_PROFILE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_MEMBER_PROFILE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleUser(params) {
  return await Axios.get(`/users`, { params });
}
function* handleGetSingleUser({ payload }) {
  try {
    const response = yield call(getSingleUser, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_USER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_USER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getProfileStats(params) {
  return await Axios.get(`/transactions/profile-stats`, { params });
}
function* handleGetProfileStats({ payload }) {
  try {
    const response = yield call(getProfileStats, payload);
    if (response) {
      yield put({
        type: GET_PROFILE_STATS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_PROFILE_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_MEMBER_PROFILE_REQUEST, handleGetMemberProfile),
  takeLatest(GET_SINGLE_USER_REQUEST, handleGetSingleUser),
  takeLatest(GET_PROFILE_STATS_REQUEST, handleGetProfileStats),
]);
