import NoData from 'components/NoData';
import Loading from 'components/UI/Loading';
import { AntInput } from 'components/UIHooks/AntDesign';
import { useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CashEmptyStateData from '../CashEmptyStateData';
import { AccountCard } from '../components/AccountCard';
import NewAccountModal from '../NewAccountModal';

import { SearchIcon } from 'assets/icons';

const BujetiAccounts = ({
  openBalance,
  openStatementModal,
  openDetailsModal,
  type = 'accounts',
}) => {
  const [searchVal, setSearchVal] = useState('');
  const dispatch = useDispatch();

  let {
    getBalances: { data: balances, loading },
  } = useSelector(({ budgets }) => budgets);

  const [activePopover, setActivePopover] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showAccounts =
    !!balances ||
    !!balances?.balances.some(({ accountType, subaccount }) => {
      if (type === 'subaccounts')
        return !['linked', 'direct-debit'].includes(accountType) && subaccount;
      return !['linked', 'direct-debit'].includes(accountType);
    });

  const filteredAccount = useMemo(
    () =>
      balances?.balances
        .filter(({ accountType, subaccount }) => {
          if (type === 'subaccounts')
            return !['linked', 'direct-debit'].includes(accountType) && subaccount;
          return !['linked', 'direct-debit'].includes(accountType);
        })
        .filter(
          (item) =>
            item?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
            item?.accountName?.toLowerCase().includes(searchVal.toLowerCase()) ||
            item?.bankName?.toLowerCase().includes(searchVal.toLowerCase()),
        ),
    [searchVal, balances?.balances],
  );

  if (loading) return <Loading isPage color="#D28B28" />;
  return (
    <div>
      <div className="account-header__holder">
        <div className="account-header text-capitalize">{type}</div>
        <div className="search-input">
          <AntInput
            className="input"
            placeholder="Search"
            prefix={<SearchIcon stroke="#A9A29D" className="searchIcon" />}
            value={searchVal}
            onChange={({ target: { value } }) => setSearchVal(value)}
          />
        </div>
      </div>

      {showAccounts && (
        <div className="grid grid-cols-2">
          {filteredAccount?.map(
            (
              {
                name,
                balance,
                number,
                currency,
                code,
                bankName,
                accountName,
                routingNumber,
                logo,
                subaccount,
                mandateCode,
                mandateStatus,
              },
              index,
            ) => {
              return (
                <AccountCard
                  key={`account-${index}`}
                  code={code}
                  openBalance={openBalance}
                  openDetailsModal={(event) =>
                    openDetailsModal(event, {
                      name,
                      balance,
                      number,
                      currency,
                      code,
                      bankName,
                      accountName,
                      routingNumber,
                    })
                  }
                  openStatementModal={(event) =>
                    openStatementModal(event, { code, name, accountName })
                  }
                  name={name}
                  balance={balance}
                  number={number}
                  currency={currency}
                  bankName={bankName}
                  accountName={accountName}
                  logo={logo}
                  isSubaccount={subaccount}
                  isDirectDebit={!!mandateCode}
                  isDirectDebitActive={mandateStatus === 'granted'}
                  showOptions={activePopover === code}
                  onToggleOptions={(isOpen) => setActivePopover(isOpen ? code : null)}
                />
              );
            },
          )}
        </div>
      )}

      {!showAccounts || (!filteredAccount?.length && !searchVal) ? (
        <CashEmptyStateData toggleHandler={() => setIsModalOpen(true)} />
      ) : !filteredAccount?.length && searchVal ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="You have no account for this filter"
            bodyText="Alter filter to see accounts"
            withButton={false}
          />
        </div>
      ) : null}

      <NewAccountModal
        route="accounts"
        isModalOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default BujetiAccounts;
