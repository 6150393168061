import {
  GET_NOTIFICATIONS_REQUEST,
  MARK_NOTIFICATIONS_READ_REQUEST,
  MARK_ALL_NOTIFICATIONS_READ_REQUEST,
} from '../reducers/NotificationsReducer';

export const getNotification = (payload) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  payload,
});
export const markNotificationAsRead = (id) => ({
  type: MARK_NOTIFICATIONS_READ_REQUEST,
  id,
});

export const markAllNotificationAsRead = () => ({
  type: MARK_ALL_NOTIFICATIONS_READ_REQUEST,
});
