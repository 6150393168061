import { CopyIcon } from 'assets/icons';
import classNames from 'classnames';
import { toastSuccess } from 'components/UI/toast';
import './styles.scss';

const LabeledInfo = ({ label, value, className, labelClass, fullCursor }) => {
  const copyAll = async (str) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(str);
      toastSuccess('Copied successfully');
    }
  };
  return (
    <div className={classNames('mt-1', { ['cursor']: fullCursor })}>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className={classNames('items-wrapper__transfer align-items-start', {
            [className]: className,
          })}
        >
          <div className="title__transfer" style={{ width: '100%' }}>
            <span
              className={classNames({ [labelClass]: labelClass })}
              style={{ display: 'block', color: '#A9A29D', fontSize: 12 }}
            >
              {label}
            </span>
            <div style={{ fontSize: 14 }} className="d-flex justify-content-between">
              <span>{value}</span>
              <span
                className="copy-box cursor"
                onClick={() => {
                  copyAll(value);
                }}
              >
                <CopyIcon stroke="#79716B" width="20" height="20" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabeledInfo;
