import {
  CurrencyType,
  ReimbursementStatusType,
} from 'components/FilterModal/FilterHelper';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import RequestEmptyStateData from 'pages/Requests/requestEmptystateData';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { deleteRequests, getRequests } from 'redux/actions/RequestsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { employeeColumnsRequest } from 'utils/mockData';
import { getQueryParams, updateStatus } from 'utils/utility';
import { Skeleton } from 'antd';
import {
  buildEmployeeRequestTableData,
  capitalizeFirstLetter,
} from '../../../utils/helper';
import RequesterModal from '../RequesterModal';
import RequestFund from '../RequesterModal/RequestFunds';
import '../style.less';
import ConfirmDialog from 'components/ConfirmDialog';
import { TrashIcon } from 'assets/icons';

const EmployeeRequest = ({
  setIsOtherPopoverOpen,
  isOpen,
  toggleHandler,
  requestCode,
}) => {
  const [selectRequest, setSelectRequest] = useState(null);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([
    ...ReimbursementStatusType,
    ...CurrencyType,
  ]);
  const location = useLocation();
  const [search, setSearch] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const debouncedValue = useDebounce(search, 600);
  const {
    getRequest: { data: { meta = {} } = {}, data = {}, loading, success },
    deleteRequest: { loading: deleteLoading, success: deleteSuccess },
  } = useSelector(({ requests }) => requests);
  const [filteredQuery, setFilteredQuery] = useState({});

  const {
    downloadAsset: { data: { asset } = {}, success: assetSuccess },
  } = useSelector(({ assets }) => assets);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (assetSuccess) {
      window.open(asset.url, 'Download');
    }
  }, [assetSuccess]);

  const { permissions } = allPermissions();
  const canView = hasPermission({
    permissions,
    scopes: ['request-*', 'request-view'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['request-*', 'request-create'],
  });

  const { page, total, hasMore, perPage, nextPage } = meta;
  const { fundRequests: requests = [] } = data;
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const rows = buildEmployeeRequestTableData(requests);

  useEffect(() => {
    if (requests.length > 0) {
      if (requestCode) {
        const element = rows.find(({ requestData: { code } }) => code === requestCode);
        setSelectRequest(element);
      }
    }
  }, [requests]);

  useEffect(() => {
    if (!requests.length && canView && !location?.search)
      dispatch(getRequests({ view_as: 'reviewer' }));
    return () => {
      if ((filtered || location?.search) && canView)
        dispatch(getRequests({ view_as: 'reviewer' }));
    };
  }, [filtered]);

  useEffect(() => {
    if (debouncedValue && canView) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getRequests({ ...filteredQuery, search: debouncedValue, view_as: 'reviewer' }),
      );
    }
    if (!debouncedValue && filtered && canView) {
      delete filteredQuery.search;
      dispatch(getRequests({ ...filteredQuery, view_as: 'reviewer' }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  useEffect(() => {
    if (location?.search && canView) {
      const status = getQueryParams(location?.search, 'status');
      if (status) {
        dispatch(getRequests({ status, view_as: 'reviewer' }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, [location]);

  useEffect(() => {
    if (deleteSuccess) {
      closeModal();
      isFiltered.current = true;
      dispatch(getRequests({ ...filteredQuery, view_as: 'reviewer' }));
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  const handleRowClick = (row) => {
    setSelectRequest(row);
  };

  const handleFilter = (query) => {
    isFiltered.current = !!Object.keys(query).length;
    const { status: s, currency: c, from: f, to: t } = query;
    const status = s ? s.toString() : undefined;
    const currency = c ? c.toString() : undefined;
    const to = t ? t.toString() : undefined;
    const from = f ? f.toString() : undefined;

    if ((!from && to) || (from && !to)) {
      return toastError('To filter by date, please set from and to date');
    }

    setFilteredQuery({
      status,
      currency,
      from,
      to,
    });

    dispatch(
      getRequests({
        status,
        currency,
        from,
        to,
        view_as: 'reviewer',
      }),
    );
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    if (type === 'delete') return setConfirmModal(true);
  };

  const Actions = ({ list: selectedData }) => {
    const status = selectedData?.status?.value ?? '';
    return (
      <div className="actions-dialog">
        {!['pending', 'draft'].includes(status) && (
          <div
            className="actionLink"
            onClick={(event) => {
              setIsPopoverOpen(false);
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            No options available
          </div>
        )}

        {user?.user?.code === selectedData?.requestData?.user?.code &&
          ['pending', 'draft'].includes(status) && (
            <div
              className="actionLink svg-danger text-danger"
              onClick={(event) => {
                actionHandler(event, 'delete', {
                  code: selectedData?.requestData?.code,
                });
              }}
            >
              <TrashIcon stroke="#79716B" width={16} height={16} className="mr-4" />
              Delete request
            </div>
          )}
      </div>
    );
  };

  const handlePreviousPage = (page) => {
    dispatch(getRequests({ perPage, page, view_as: 'reviewer', ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getRequests({ perPage, page, view_as: 'reviewer', ...filteredQuery }));
  };

  useEffect(() => {
    dispatch(getBeneficiaryBank());
  }, []);

  const handleConfirm = () => {
    const code = selectedOption?.code;
    if (type === 'delete') {
      if (code) dispatch(deleteRequests(code));
    }
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  if (loading)
    return (
      <section className="pt-3 fade-in">
        <div className="d-flex">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '16rem' }}
          />
          <div className="ms-auto">
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '7rem' }}
            />
          </div>
        </div>

        <div className="mt-4">
          <Table
            columns={employeeColumnsRequest}
            data={buildEmployeeRequestTableData(requests)}
            pagination
            hasCheckBox={false}
            loading
          />
        </div>
      </section>
    );

  return (
    <div className="requests-wrapper">
      <TopBar
        showFilter
        searchVal={search}
        showBarSearch
        setSearchVal={setSearch}
        inputPlaceholder="Search by name and reason"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterApply={handleFilter}
        withOutSearch
        withDate
      />
      <PendingOnboardingNotice />

      {!requests.length ? (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no requests for this filter"
                bodyText="Alter the filter to see your request"
                withButton={false}
              />
            </div>
          ) : (
            <RequestEmptyStateData
              openRequestModal={toggleHandler}
              showAction={canCreate}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4">
              <Col xs={12}>
                <Table
                  columns={employeeColumnsRequest}
                  data={buildEmployeeRequestTableData(requests)}
                  onRowClick={handleRowClick}
                  pagination
                  hasCheckBox={loading ? false : true}
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  loading={loading}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      {selectRequest && (
        <RequesterModal
          setSelectRequest={setSelectRequest}
          selectRequest={selectRequest}
        />
      )}
      <RequestFund
        isOpen={isOpen}
        toggleHandler={toggleHandler}
        setIsOtherPopoverOpen={setIsOtherPopoverOpen}
      />

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} request`}
          subTitle={`Are you sure you want to ${type} this request?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          isDeleteDialog={type === 'delete'}
          actionBtnText="Confirm"
          loading={deleteLoading}
        />
      </Modal>
    </div>
  );
};

export default EmployeeRequest;
