import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

const TooltipContent = ({ children, tooltip }) => {
  return (
    <div className="d-flex align-items-center text-secondary gap-2">
      {children}

      <span className="mb-0">
        <Tooltip
          overlayClassName="tool-tip"
          placement="top"
          title={<div className="text">{tooltip}</div>}
        >
          <QuestionCircleOutlined
            className="pointer-event cursor"
            style={{ fontSize: '16px' }}
          />
        </Tooltip>
      </span>
    </div>
  );
};

export default TooltipContent;
