import { DotChartOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import DateRangeFilter from 'components/DateRangeFilter';
import OverviewCard from 'components/OverviewCards';
import AreaChartView from 'components/charts/AreaChart';
import BarChartView from 'components/charts/BarChart';
import { buildCategoryChartFromTransaction } from 'utils/helper';

const CategoryOverviewAnalytics = ({
  chartData,
  totalSpent,
  currency = 'NGN',
  handleSelectRange,
  selectedRange,
  stats = {},
  categoryData,
  loading,
}) => {
  const { summary = {}, topSpenders = [], topSubCategories = [] } = stats;

  const buildTopSpender = topSpenders.map((item) => ({
    name: `${item?.User?.firstName} ${item?.User?.lastName}`,
    amount: item?.transactionVolume / 100,
  }));

  const buildTopSubCategories = topSubCategories.map((item) => ({
    name: `${item?.Category?.name}`,
    amount: item?.transactionVolume / 100,
  }));

  const chartloading = () => {
    return (
      <Skeleton.Node active style={{ borderRadius: 12, width: '100%', height: '300px' }}>
        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
      </Skeleton.Node>
    );
  };

  return (
    <div className="budget-analytics mt-4">
      <div className="d-flex flex-wrap justify-content-between align-items-center insights-title">
        <div>
          <span className="text">Insights</span>
          <div className="small-text">
            Get a detailed view of your category and spending over a given period of time.
          </div>
        </div>

        <div>
          <DateRangeFilter
            handleSelectRange={handleSelectRange}
            selectedRange={selectedRange}
            thisYear={true}
          />
        </div>
      </div>

      <div className="grid-col-2 gap-4 mt-4">
        {categoryData?.isSubCategory === false && (
          <OverviewCard
            title={
              <div className="d-flex align-items-center gap-2">
                <span className="text">Top sub categories</span>
              </div>
            }
          >
            {loading ? (
              chartloading()
            ) : (
              <BarChartView
                totalSpent={totalSpent}
                currency={currency}
                totalPercentage={100}
                data={buildTopSubCategories}
                showTotalSpent={false}
              />
            )}
          </OverviewCard>
        )}

        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Expense breakdown</span>
            </div>
          }
        >
          {loading ? (
            chartloading()
          ) : (
            <AreaChartView
              totalSpent={totalSpent}
              currency={'NGN'}
              totalPercentage={100}
              data={buildCategoryChartFromTransaction(chartData)}
            />
          )}
        </OverviewCard>

        <OverviewCard
          title={
            <div className="d-flex align-items-center gap-2">
              <span className="text">Top spenders</span>
            </div>
          }
        >
          {loading ? (
            chartloading()
          ) : (
            <BarChartView
              totalSpent={totalSpent}
              currency={currency}
              totalPercentage={100}
              data={buildTopSpender}
              showTotalSpent={false}
            />
          )}
        </OverviewCard>
      </div>
    </div>
  );
};

export default CategoryOverviewAnalytics;
