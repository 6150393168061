import { PlusDropDownIcon } from 'assets/icons';
import TopBar from 'components/TopBar';
import { ActiveButton } from 'components/UI/CustomButton';
import { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getBudgets } from '../../redux/actions/BudgetsAction';
import { RESET_FLAGS_CARD } from '../../redux/reducers/OverviewReducer';
import CardTable from './CardTable';
import CardsModal from './CardsFormModal';
import MyCardsTable from './MyCardsTable';
import RequestedCardTable from './RequestedCardTable';

import { getSpendStatistics } from 'redux/actions/SpendAction';

const Cards = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);

  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);

  const { permissions, isAdmin } = allPermissions();

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-view'],
  });

  const canCreateCard = hasPermission({
    permissions,
    scopes: ['card-create', 'card-edit'],
  });

  const handleKeySelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey != 'cards') {
      history.push(`/cards/${tabKey}`);
    } else {
      history.push(`/cards`, { disableFade: true });
    }
  };

  const {
    getCard: { data: { meta = {}, cards: cardList = [] } = {}, loading },
    getMyCards: { data: myCardsData, loading: myCardsLoading },
    getRequestedCards: { data: { meta: cardRequestsMeta = {} } = {} },
  } = useSelector(({ card }) => card);
  const {
    getSpends: spendData,
    getSpendStatistics: { data: spendStatistics, loading: statsLoading },
  } = useSelector(({ spends }) => spends);
  const { meta: spendMeta = {} } = spendData;

  const {
    getBudget: { data: { budgets: budgetList = [] } = {} },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    if (location.state?.isModal) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  const toggleHandler = () => {
    if (!isOtherPopoverOpen) return setIsOpen(!isOpen);
    dispatch({ type: RESET_FLAGS_CARD, blockType: 'createCard' });
  };

  useEffect(() => {
    //to get all Budgets data API
    if (!budgetList?.length && canViewBudget) dispatch(getBudgets());
    if (!Object.entries(spendStatistics).length) dispatch(getSpendStatistics());
  }, []);

  const handleOtherPopover = (val) => {
    setIsOtherPopoverOpen(val);
  };

  const AnalyticsData = [
    {
      title: 'Total subscriptions',
      amount: spendStatistics?.total || '-',
    },
    {
      title: 'Paid this year',
      amount: spendStatistics?.totalAmount || '-',
      currency: spendStatistics?.totalAmount ? 'NGN' : null,
    },
    {
      title: 'To pay this month',
      amount: spendStatistics?.thisMonth || '-',
      currency: spendStatistics?.thisMonth ? 'NGN' : null,
    },
    {
      title: 'Next billing date',
      amount: spendStatistics?.nextBillingDate || '-',
    },
  ];

  return (
    <div className="container">
      <TopBar
        isRightBar
        headerText="Cards"
        subText="Manage your Naira and Dollar cards: assign them to team members, teams, and vendors."
        dropDownBtn={
          canCreateCard ? (
            <ActiveButton
              position="left"
              text="Create a card"
              onClick={toggleHandler}
              icon={<PlusDropDownIcon stroke="#fff" />}
              className="add-button d-flex align-items-center justify-content-center gap-2 px-3"
            />
          ) : null
        }
        addButton
      />

      <section className="overview-holder">
        <Row>
          <Col xs={12} className="mt-3">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleKeySelect}
              className="mb-2"
            >
              {hasPermission({
                permissions,
                scopes: ['card-*', 'card-view'],
              }) && (
                <Tab
                  eventKey="cards"
                  title={
                    <div className="d-flex">
                      My cards
                      <div className="count">
                        <span>{myCardsData?.meta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <MyCardsTable toggleHandler={toggleHandler} />
                  </div>
                </Tab>
              )}
              {isAdmin && (
                <Tab
                  eventKey="all-cards"
                  title={
                    <div className="d-flex">
                      All cards
                      <div className="count">
                        <span>{meta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <CardTable toggleHandler={toggleHandler} />
                  </div>
                </Tab>
              )}
              {hasPermission({
                permissions,
                scopes: ['card-*', 'card-view'],
              }) && (
                <Tab
                  eventKey="requested-cards"
                  title={
                    <div className="d-flex">
                      Requested
                      <div className="count">
                        <span>{cardRequestsMeta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <div className="fade-in">
                    <RequestedCardTable toggleHandler={toggleHandler} />
                  </div>
                </Tab>
              )}
              {/* {isAdmin && (
                <Tab
                  eventKey="subscriptions"
                  title={
                    <div className="d-flex align-items-center">
                      Subscriptions
                      <span className="new-tag text-xs">Beta</span>
                      <div className="count">
                        <span>{spendMeta?.total || 0}</span>
                      </div>
                    </div>
                  }
                  tabClassName="new-tab"
                >
                  <>
                    <section className="sub-analytics__holder mt-4">
                      {AnalyticsData.map(({ title, amount, currency }, i) => (
                        <div className="sub-analytics__stats" key={'item-' + i}>
                          <AnalyticsCard
                            caption={title}
                            hasExtra={false}
                            loading={statsLoading}
                            value={
                              currency ? (
                                <FormattedCurrency
                                  value={amount}
                                  prefix={getCurrency(currency)}
                                />
                              ) : (
                                <span> {amount} </span>
                              )
                            }
                          />
                        </div>
                      ))}
                    </section>

                    <SpendTable toggleHandler={toggleHandler} />
                  </>
                </Tab>
              )} */}
            </Tabs>
          </Col>
        </Row>
      </section>

      <CardsModal
        IsOtherPopoverOpen={handleOtherPopover}
        isOpen={isOpen}
        toggleHandler={toggleHandler}
      />
    </div>
  );
};
export default Cards;
