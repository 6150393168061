import { Col, Form, Input, Row, Spin } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import React from 'react';

const defaultLabel = [
  {
    name: 'email',
    label: 'Your work email',
    placeholder: 'Enter email',
  },
  {
    name: 'password',
    label: 'Choose a password',
    placeholder: 'Enter password',
  },
  {
    name: 'confirm_password',
    label: 'Confirm password',
    placeholder: 'Enter password',
  },
  {
    name: 'code',
    label: 'Verification code',
    placeholder: 'Enter code',
  },
];

const AuthForm = ({
  initialValues,
  errorMsg,
  hideEmail,
  hidePassword,
  confirmPassword,
  hideOPTCode = true,
  submitText = 'Sign in',
  labels = defaultLabel,
  formGutter = [8, 12],
}) => {
  const [form] = Form.useForm();
  const [submited, setSubmited] = React.useState(false);

  const getLabel = (name) => labels.find((item) => item.name === name);
  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        form={form}
        initialValues={initialValues}
        wrapperCol={{ span: 24 }}
        requiredMark="optional"
        className="form-design"
      >
        <Row gutter={formGutter}>
          <Spin spinning={submited}>
            <Col xs={24} hidden={hideEmail}>
              <Form.Item
                name="email"
                label={getLabel('email')?.label}
                rules={[rules.email]}
              >
                <Input placeholder={getLabel('email')?.placeholder} size="large" />
              </Form.Item>
            </Col>

            <Col xs={24} hidden={hidePassword}>
              <Form.Item
                name="password"
                label={getLabel('password')?.label}
                rules={[rules.password]}
              >
                <Input.Password
                  placeholder={getLabel('password')?.placeholder}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col xs={24} hidden={!confirmPassword}>
              <Form.Item
                name="confirm_password"
                rules={[rules.confirm_password, rules.verifyPassword]}
                hasFeedback
                dependencies={['password']}
                label={getLabel('confirm_password')?.label}
                hidden={!confirmPassword}
              >
                <Input.Password
                  placeholder={getLabel('confirm_password')?.placeholder}
                  size="large"
                />
              </Form.Item>
            </Col>

            {!hideOPTCode && (
              <Col xs={24}>
                <Form.Item
                  name="code"
                  label={getLabel('code')?.label}
                  rules={[rules.verifyPassword]}
                >
                  <Input.Password
                    placeholder={getLabel('code')?.placeholder}
                    size="large"
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} className="pt-2">
              <Form.Item noStyle>
                <CustomButton
                  loading={submited}
                  className="submit-button mt-4"
                  size="large"
                  htmlType="submit"
                >
                  {submitText}
                </CustomButton>
              </Form.Item>
            </Col>
          </Spin>
        </Row>
      </Form>

      {errorMsg && <h4 className="error-message">{errorMsg}</h4>}
    </>
  );
};

export default AuthForm;

const rules = {
  email: {
    required: true,
    message: 'Enter email or phone number',
  },
  password: {
    required: true,
    message: 'Please input your password!',
  },
  confirm_password: {
    required: true,
    message: 'Please input your password!',
  },
  code: {
    required: true,
    message: 'Please input code!',
  },
  emailType: {
    type: 'email',
  },
  verifyPassword: (props) => {
    const { getFieldValue } = props;

    return {
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('The two passwords that you entered do not match!'),
        );
      },
    };
  },
};
