import React from 'react';
import classNames from 'classnames';
const TotalAmount = ({
  type,
  title,
  subTitle,
  widthLink,
  handleClick,
  onClick,
  customClass,
}) => {
  return (
    <div
      className={classNames(`total-amount-wrapper`, {
        [customClass]: customClass,
      })}
      onClick={onClick}
    >
      <p className="card-type-title">{type}</p>
      <h2 className="card-title-amount">{title}</h2>
      <p className="card-disabled-title">{subTitle}</p>
      <span className="card-disabled-link" onClick={handleClick}>
        {widthLink}
      </span>
    </div>
  );
};
export default TotalAmount;
