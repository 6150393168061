import { PlusOutlined } from '@ant-design/icons';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import Loading from 'components/UI/Loading';
import 'jspdf-autotable';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccountSubAccounts } from 'redux/actions/BudgetsAction';
import { buildSubAccountsTableData } from 'utils/helper';
import { subAccountsColumns } from 'utils/mockData';

const SubAccountsTable = ({ balanceCode, parentAccount, toggleNewAccountModal }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const history = useHistory();

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const {
    getTransfers: { loading },
  } = useSelector(({ transaction }) => transaction);

  const {
    getAccountSubAccounts: { data: { balances: subAccounts = [] } = {} },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    dispatch(getAccountSubAccounts({ balanceCode }));
  }, []);

  const toggleHandler = () => {
    return history.push('/expenses');
  };

  const handleRowClick = (row) => {
    history.push(`/accounts/subaccounts/${row?.subaccountData?.code}`);
  };

  const rows = buildSubAccountsTableData(subAccounts);

  const show = !!subAccounts?.length || (filtered && !subAccounts?.length);
  if (loading && !filtered) return <Loading isPage color="#D28B28" />;

  return (
    <>
      <TopBar
        addOnClick={toggleNewAccountModal}
        searchVal={search}
        setSearchVal={setSearch}
        showBarSearch={show}
        inputPlaceholder="Search"
        addIcon={
          !(
            ['linked', 'direct-debit'].includes(parentAccount?.accountType) &&
            parentAccount?.mandateStatus !== 'granted'
          )
        }
        customAddButton={
          <button onClick={toggleNewAccountModal} className="add-button add-action">
            <PlusOutlined
              style={{
                verticalAlign: 0,
                fontSize: 14,
                paddingLeft: 5,
              }}
            />
            <span className="ms-1">Add subaccount</span>
          </button>
        }
      />

      {!subAccounts.length ? (
        <div className="innerWrapper">
          {isFiltered.current ? (
            <NoData
              headerText={`You have no operations for these criteria`}
              bodyText="Alter filter to see operations"
              withButton={false}
            />
          ) : (
            <NoData
              headerText="No operations made yet"
              bodyText="Your subaccounts will be listed here"
              onClickHandler={toggleHandler}
              withButton={false}
            />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="pt-4 pb-3">
              <Col xs={12}>
                <Table
                  columns={subAccountsColumns}
                  data={rows}
                  onRowClick={handleRowClick}
                  hasCheckBox={false}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default SubAccountsTable;
