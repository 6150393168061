/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from 'redux/actions/CompaniesAction';

import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import Overview from './OverviewTab';
import './styles.scss';
import { useLocation, useHistory } from 'react-router-dom';
import SubHeader from './OverviewTab/components/SubHeader';
import ReportOverview from 'pages/Reports/Overview';

const Dashboard = () => {
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const history = useHistory();
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSwapModal, setOpenSwapModal] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const {
    user,
    userProfile: { loading: loadingUser },
  } = useSelector(({ auth }) => auth);

  const {
    getCompany: { data: companyData = {} },
    switchCompany: { loading: switching },

    getStatistics: { loading, success },
    getPaymentLink: { loading: loadingPaymentLink },
    verifyPaymentReference: { loading: verifyingPayment },
    getAccountReauthorizationCode: { loading: fetchingReauthenticationCode },
  } = useSelector(({ companies }) => companies);

  const { companyStatus, permissions } = allPermissions();

  const [company, setCompany] = useState({});
  const [accounts, setAccounts] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) setHasLoaded(true);
  }, [success]);

  useEffect(() => {
    if (companyData && companyData.code) setCompany(companyData);
    setAccounts(companyData?.accounts);
  }, [companyData]);

  useEffect(() => {
    const companyCode = user?.data?.user?.company?.code;
    if (user.hasOwnProperty('data')) {
      setCompany(user?.data?.user?.company || {});
    }

    if (!companyData?.code && companyCode) {
      dispatch(
        getCompany({ code: companyCode, includeDocuments: true, includeSteps: true }),
      );
    }
  }, [user]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    history.push(`/${tabKey}`);
  };

  const handleSwapping = () => {
    setOpenSwapModal((prevValue) => !prevValue);
  };

  const loadingState =
    loading || loadingPaymentLink || verifyingPayment || fetchingReauthenticationCode;

  const isLoading = !companyStatus || loadingUser || switching;

  return (
    <div className="dashboard-wrapper">
      <Container>
        <div className="page-wrapper pb-5">
          <div className="w-100">
            <SubHeader
              handleSwap={handleSwapping}
              openModal={() => setIsModalOpen(true)}
            />

            <Tabs
              id="contolled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              {hasPermission({
                permissions,
                scopes: ['dash-*', 'dash-view', 'dash-edit'],
              }) && (
                <Tab
                  eventKey=""
                  title={<div className="d-flex">Overview</div>}
                  tabClassName="new-tab"
                >
                  <div
                    className={(loadingState || isLoading) && !hasLoaded ? 'fade-in' : ''}
                  >
                    <Overview
                      company={company}
                      hasLoaded={hasLoaded}
                      isLoading={isLoading}
                      accounts={accounts}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      openSwapModal={openSwapModal}
                      setOpenSwapModal={setOpenSwapModal}
                    />
                  </div>
                </Tab>
              )}

              {hasPermission({
                permissions,
                scopes: ['dash-*', 'dash-view', 'dash-edit'],
              }) && (
                <Tab
                  eventKey="reports"
                  title={<div className="d-flex">Reports</div>}
                  tabClassName="new-tab"
                >
                  <div
                    className={(loadingState || isLoading) && !hasLoaded ? 'fade-in' : ''}
                  >
                    <ReportOverview
                      hasLoaded={hasLoaded}
                      company={company}
                      isLoading={isLoading}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Dashboard;
