import { useEffect, useState } from 'react';
import { CheckIcon, DeleteIcon, EditIcon } from 'assets/icons';
import './categorySelect.scss';
import { useDispatch, useSelector } from 'react-redux';

import {
  createCategories,
  updateCategories,
  deleteCategories,
} from 'redux/actions/CategoryAction';
import { UPDATE_CATEGORY_ERROR } from 'redux/reducers/CategoryReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import OutsideClickHandler from 'react-outside-click-handler';

/**
 * @param {string} selectedValue - the value that has been selected
 * @param {Function} onHandleChange - a callback function to handle the onchange value
 * @param {Boolean} disabled - a boolean value to disbaled the select
 * @param {Array} options - the select options in an array of object
 * @param {String} labelName - the name of the label
 * @param {String}  placeholder - the placeholder for the input
 * @param {Function} onHandleDelete - a callback function to extract the name of the select category and the code
 * @param {Boolean} hideLabel - a boolean to show or hide the label
 */

const CategorySelect = ({
  labelName,
  placeholder,
  options,
  getSelected,
  onHandleDelete,
  hideLabel,
  hideActions,
  getDropdownState,
  defaultValue,
  categoryClasses,
  disabled,
  showActions = true,
  resetCategoryValue,
  shouldResetValue = false,
}) => {
  const getDefaultSelection = () => {
    return (
      options.find((option) => {
        return option.code === defaultValue;
      }) || { code: '', name: '' }
    );
  };

  const [editOption, setEditOption] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [editData, setEditData] = useState('');
  const [currentSelection, setCurrentSelection] = useState({ code: '', name: '' });
  const [newCategory, setNewCategory] = useState('');
  const [isCreatable, setIsCreateable] = useState(false);
  const { isAdmin } = allPermissions();
  const [listedOptions, setListedOptions] = useState(options);
  const dispatch = useDispatch();
  const {
    createCategories: { data, loading, success },
  } = useSelector(({ categories }) => categories);

  const onHandleEditable = (code) => {
    setSelectedOption(code);
    setEditOption(!editOption);
  };

  const onHandleToggle = () => {
    setShowSelect(!showSelect);
  };

  const resetCategory = () => {
    setCurrentSelection({ code: '', name: '' });
  };

  useEffect(() => {
    if (shouldResetValue) {
      resetCategoryValue();
      resetCategory();
    }
  }, [shouldResetValue]);

  useEffect(() => {
    if (getDropdownState === undefined) return undefined;
    getDropdownState(showSelect);
  }, [showSelect]);

  const onHandleSubmitEdit = (e, option) => {
    setEditOption(false);
    const data = {
      name: editData,
      code: option?.code,
    };
    if (!editData) {
      return null;
    }
    dispatch(updateCategories(data));
  };

  const onHandleOptionEdit = (e) => {
    setEditData(e.target.value);
  };

  const onShowDeleteModal = (option) => {
    setShowSelect(false);
    onHandleDelete(option.name, option.code);
  };

  const onHandleSelected = (code, name, label, value) => {
    setCurrentSelection({ code, name });
    setShowSelect(false);
    getSelected(code, name, label, value);
  };

  const onHandleChange = (e) => {
    const foundOption = options.find((option) =>
      option.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );

    if (!e.target.value.length || (foundOption && !!e.target.value.length)) {
      setIsCreateable(false);
    } else {
      setIsCreateable(true);
      setNewCategory(e.target.value);
    }

    setCurrentSelection({
      ...currentSelection,
      name: e.target.value,
      code: foundOption?.code,
    });
  };

  const onHandleCreateCategory = () => {
    const data = {
      name: currentSelection?.name,
    };
    dispatch(createCategories(data));
    setShowSelect(false);
  };

  useEffect(() => {
    if (data) {
      // setSelectedCategoryId(data?.category?.code);
      setSelectedOption(data?.category?.name);
      onHandleSelected(data?.category?.code, data?.category?.name);
      dispatch({ type: UPDATE_CATEGORY_ERROR, blockType: 'createCategories' });
      setIsCreateable(false);
    }
  }, [data]);

  useEffect(() => {
    if (defaultValue && options.length) {
      setCurrentSelection(getDefaultSelection());
    }
  }, [options, defaultValue]);

  const sortedOptions = [...options].sort((a, b) => {
    if (a.code === currentSelection?.code) return -1;
    if (b.code === currentSelection?.code) return 1;
    return 0;
  });

  return (
    <section className={`select-wrapper ${categoryClasses}`}>
      {!hideLabel && <label htmlFor={labelName}>{labelName}</label>}
      <div className="selectInput-wrapper">
        <input
          type="text"
          onChange={onHandleChange}
          className="form-control"
          placeholder={placeholder}
          value={currentSelection.name}
          onFocus={() => setShowSelect(true)}
          onBlur={() => (selecting ? null : setShowSelect(false))}
          disabled={disabled || loading}
        />
        {loading && <span className="loading-style">loading...</span>}
        <svg
          width="12"
          height="6"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onHandleToggle}
        >
          <path
            d="M1 1L7 7L13 1"
            stroke="#BDBDBD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {showSelect && (
        <section
          className="select"
          onMouseLeave={() => setSelecting(false)}
          onMouseOver={() => setSelecting(true)}
        >
          {isCreatable && isAdmin && (
            <div className="options newCategory-bg" onClick={onHandleCreateCategory}>
              <span>Create: {newCategory && newCategory}</span>
            </div>
          )}

          {options &&
            sortedOptions.map((option) => {
              const isSelected =
                selectedOption === option.code || currentSelection?.code === option.code;

              return (
                <div
                  className={`options ${isSelected ? 'selected' : ''}`}
                  key={option.code}
                >
                  {editOption && isSelected ? (
                    <input
                      type="text"
                      name="edit"
                      placeholder={option.name}
                      value={editData}
                      onChange={onHandleOptionEdit}
                    />
                  ) : (
                    <span
                      className="d-flex flex-grow-1"
                      onClick={() => onHandleSelected(option.code, option.name)}
                    >
                      {option.name}
                    </span>
                  )}
                  {isAdmin && showActions && (
                    <div className="option-action">
                      {editOption && isSelected ? (
                        <CheckIcon onClick={(e) => onHandleSubmitEdit(e, option)} />
                      ) : (
                        <EditIcon onClick={() => onHandleEditable(option.code)} />
                      )}
                      <DeleteIcon onClick={() => onShowDeleteModal(option)} />
                    </div>
                  )}
                </div>
              );
            })}
        </section>
      )}
    </section>
  );
};

export default CategorySelect;
