import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  budgetBeneficiariesAPI,
  budgetCard,
  getBudgetLedger,
  getBudgetStats,
  getBudgets,
  getSingleBudgets,
} from 'redux/actions/BudgetsAction';
import {
  getPolicies,
  getPolicyTypes,
  getSingleBudgetPolicies,
} from 'redux/actions/PoliciesAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getFromAndTo } from 'utils/utility';
import BudgetBeneficiaries from './BudgetBeneficiaries';
import BudgetCard from './BudgetCard';
import BudgetOverview from './BudgetOverview';
import BudgetPolicy from './BudgetPolicy';
import BudgetTransaction from './BudgetTransaction';
import SelectedBudgetsHeader from './SelectedBudgetsHeader';

const SelectedBudgets = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const locationArray = location.pathname.split('/');

  const { budgetId } = useParams();
  const { permissions } = allPermissions();
  const canViewPolicy = hasPermission({
    permissions,
    scopes: ['policy-view'],
  });
  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-view'],
  });
  const canViewEmployee = hasPermission({
    permissions,
    scopes: ['employee-view'],
  });

  const canViewCard = hasPermission({
    permissions,
    scopes: ['card-view'],
  });

  const {
    getSingleBudget: { data = {}, loading },
    getBudgetStat: { data: statsData = {}, loading: statsLoading },
    getBudgetCard: { data: budgetCardData = {} },
    createNewBeneficiaries: { success: createSuccess },
    deleteBudgetBeneficiaries: { success: deleteSuccess },
    getBudgetLedger: { data: { budgetLedgers = [] } = {} },
    getBudgetBeneficiaries: { data: { beneficiaries = [] } = {} },
    getBudget: { data: { budgets = [] } = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    createPolicy: { success: policySuccess },
    getPolicies: { data: policies = [] } = {},
    getSingleBudgetPolicies: { data: { singlePolicies = [] } = {} },
    getPolicyTypes: { data: policyTypes = [] } = {},
  } = useSelector(({ policies }) => policies);

  // date range selector
  const { selectedDateRange } = useSelector(({ overview }) => overview);
  const { from, to } = getFromAndTo(selectedDateRange.range);

  const currentLocation = locationArray[locationArray.length - 1];

  const [key, setKey] = useState(currentLocation);

  //Clean up
  useEffect(() => {
    const samePage = data?.code === budgetId;

    if (budgetId) {
      //Get Overview Data
      if (!data?.code || !samePage) dispatch(getSingleBudgets({ id: budgetId }));
      if (!statsData?.transactions || !samePage)
        dispatch(getBudgetStats({ id: budgetId, params: { from, to } }));

      //Get Card Data
      if (canViewCard && (!budgetCardData?.cards?.length || !samePage))
        dispatch(budgetCard({ budget: budgetId }));

      //Get Transacitons Data

      if (!budgetLedgers?.length || !samePage) {
        dispatch(getBudgetLedger({ budget: budgetId }));
      }

      //Get Beneficiary Data
      if (canViewEmployee && (!beneficiaries.length || !samePage))
        dispatch(budgetBeneficiariesAPI({ budget: budgetId }));

      if (canViewBudget && (!budgets.length || !samePage)) dispatch(getBudgets());
    }

    return () => {
      dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
    };
  }, [budgetId]);

  useEffect(() => {
    if (budgetId) {
      if (
        hasPermission({
          permissions,
          scopes: ['employee-*', 'employee-create', 'employee-edit', 'employee-delete'],
        })
      )
        if (createSuccess || deleteSuccess)
          dispatch(budgetBeneficiariesAPI({ budget: budgetId }));
    }
  }, [budgetId, createSuccess, deleteSuccess, policySuccess]);

  if (loading || statsLoading) return <Loading isPage color="#D28B28" />;

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    history.push(`/expenses/budgets/${budgetId}/${tabKey}`);
  };

  return (
    <div className="selected-budgets-wrapper">
      <SelectedBudgetsHeader />
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-3"
            >
              <Tab eventKey="overview" title="Overview" tabClassName="new-tab">
                <section
                  className="overview-holder px-1"
                  style={{ height: `calc(100vh - 14.5rem)` }}
                >
                  <BudgetOverview />
                </section>
              </Tab>
              {hasPermission({
                permissions,
                scopes: ['employee-*', 'employee-view'],
              }) && (
                <Tab eventKey="beneficiaries" title="Members" tabClassName="new-tab">
                  <section
                    className="overview-holder px-1"
                    style={{ height: `calc(100vh - 14.5rem)` }}
                  >
                    <BudgetBeneficiaries />
                  </section>
                </Tab>
              )}
              {hasPermission({
                permissions,
                scopes: ['card-*', 'card-view'],
              }) && (
                <Tab eventKey="cards" title="Cards" tabClassName="new-tab">
                  <section
                    className="overview-holder px-1"
                    style={{ height: `calc(100vh - 14.5rem)` }}
                  >
                    <BudgetCard />
                  </section>
                </Tab>
              )}

              <Tab eventKey="transactions" title="Transactions" tabClassName="new-tab">
                <section
                  className="overview-holder px-1"
                  style={{ height: `calc(100vh - 14.5rem)` }}
                >
                  <BudgetTransaction />
                </section>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SelectedBudgets;
