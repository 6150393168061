/* eslint-disable no-console */
import { ArrowLeft, BujetiFullLogo, HeadPhoneIcon } from 'assets/icons';
import createVitrine from 'assets/images/create-vitrine.png';
import CustomButton from 'components/UI/CustomButton';
import CustomSelect from 'components/UI/CustomSelect';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { addEntity, switchCompany } from 'redux/actions/CompaniesAction';
import { findCompanyName } from 'redux/actions/UtilsActions';
import '../style.less';
import './styles.scss';

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [hasReferralCode, sethasReferralCode] = useState(false);
  const [value, setValue] = useState('');
  const [entityDetails, setEntityDetails] = React.useState({
    country: '',
    name: '',
  });
  const [newOptions, setNewOptions] = useState([]);
  const debouncedValue = useDebounce(value, 500);

  const {
    findCompanyName: {
      data: companyName,
      loading: loadingCompanyName,
      success: successCompanyName,
      error,
    } = {},
  } = useSelector(({ utils }) => utils);

  const {
    addEntity: { loading: creating, success: entityCreated, data: createdEntity },
    switchCompany: { success: switchCompleted },
  } = useSelector(({ companies }) => companies);

  const handleSelectChange = (val) => {
    setValue(val);
  };

  const mappedDoc = companyName?.map((item) => item.name);
  const companiesList = useMemo(() => {
    return companyName?.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [successCompanyName, error, mappedDoc]);
  const availableOptions = (companiesList || []).concat(newOptions);

  const handleNoOptionsMessage = () => {
    const name = entityDetails.name;
    if (name && name?.trim() !== '' && name?.length > 4) {
      return loadingCompanyName
        ? 'Searching please wait...'
        : 'No company found. Press Enter to continue.';
    }
    return 'Type to search...';
  };

  useEffect(() => {
    const companylists = companyName?.find((option) =>
      option.name.toLowerCase().includes(debouncedValue?.toLowerCase()),
    );
    if (!companylists && debouncedValue?.length > 4)
      dispatch(findCompanyName({ search: debouncedValue?.toLowerCase() }));
  }, [debouncedValue]);

  useEffect(() => {
    if (entityCreated) {
      dispatch(switchCompany({ code: createdEntity.code }));
    }
    if (switchCompleted) {
      history.push('/');
    }
  }, [entityCreated, switchCompleted]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!(entityDetails.country && entityDetails.country.value))
      return toastError('Please select a country');
    if (!(entityDetails.name && entityDetails.name.value))
      return toastError('Please enter your company name');

    dispatch(
      addEntity({ name: entityDetails.name?.label, parent: searchParams.company }),
    );
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    const name = entityDetails.name;
    if (event.key === 'Enter' && (name || name.value)?.trim() !== '') {
      // Create a new option and update the options list
      const newOption = { label: entityDetails.name, value: entityDetails.name };
      setNewOptions([newOption]);
      setEntityDetails({ ...entityDetails, name: newOption });
      setValue('');
    }
  };

  const toggleReferralInput = () => sethasReferralCode(!hasReferralCode);

  const authForm = (
    <section className="form-wrapper">
      <h2 className="header">Find your new entity</h2>
      <Form>
        <div className="inner-holder">
          <Row className="mb-3">
            <CustomSelect
              classNamePrefix="multi-entity"
              label="Country"
              name="country"
              isCurrency
              placeholder="Select country"
              onChange={(val) => setEntityDetails({ ...entityDetails, country: val })}
              value={entityDetails.country}
              options={[{ value: 'NGA', label: 'Nigeria' }]}
              isDisabled={false}
            />
          </Row>

          <Row className="mb-3">
            <CustomSelect
              classNamePrefix="multi-entity"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              noOptionsMessage={handleNoOptionsMessage}
              isClearable
              // noOptionsMessage={() => null}
              label="Search by company name or registration number"
              name="name"
              placeholder="Company's legal name"
              onInputChange={handleSelectChange}
              onChange={(val) => setEntityDetails({ ...entityDetails, name: val })}
              value={entityDetails.name}
              onKeyDown={handleKeyDown}
              options={availableOptions}
              isLoading={loadingCompanyName && !debouncedValue}
              isDisabled={loadingCompanyName && !debouncedValue}
            />
          </Row>
        </div>

        <div className="mt-4 d-flex justify-content-end">
          <CustomButton
            type="button"
            onClick={handleSubmit}
            className="submit-btn"
            fullWidth={false}
            loading={false}
            disabled={creating || !(entityDetails.name && entityDetails.country)}
          >
            Get started
          </CustomButton>
        </div>
      </Form>
    </section>
  );

  return (
    <section>
      <header className="region-container entity-header">
        <BujetiFullLogo />
        <div className="contact">
          <span>Need help?</span>
          <a href="mailto:info@bujeti.com">
            <button className="contact-btn">
              <HeadPhoneIcon />
              <span> Contact us</span>
            </button>
          </a>
        </div>
      </header>

      <div className="region-container mt-5 pt-2">
        <h4 className="d-flex back-action">
          <ArrowLeft color="#D28B28" />
          <Link to={'/'}> Back to dashboard</Link>
        </h4>

        <div>{authForm}</div>
      </div>
    </section>
  );
};

export default HomePage;
