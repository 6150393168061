export const CALCULATE_FEES_REQUEST = 'CALCULATE_FEES_REQUEST';
export const CALCULATE_FEES_SUCCESS = 'CALCULATE_FEES_SUCCESS';
export const CALCULATE_FEES_ERROR = 'CALCULATE_FEES_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  calculateFees: { ...block },
};

export const FeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALCULATE_FEES_REQUEST:
      return {
        ...state,
        calculateFees: {
          ...state.calculateFees,
          loading: true,
        },
      };
    case CALCULATE_FEES_SUCCESS:
      return {
        ...state,
        calculateFees: {
          ...state.calculateFees,
          success: true,
          loading: false,
          error: '',
          data: action.data,
        },
      };
    case CALCULATE_FEES_ERROR:
      return {
        ...state,
        calculateFees: {
          ...state.calculateFees,
          success: false,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
