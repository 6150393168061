import LineStatus from 'components/LineStatus';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, getPercentage, percentageValue } from 'utils/helper';
import { getFromAndTo, numFormatter } from 'utils/utility';
import './styles.scss';

import TooltipContent from 'components/UI/tooltip-content';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCategory, getCategoryStatistics } from 'redux/actions/CategoryAction';
import { selectDateRnageTopbar } from 'redux/actions/OverviewAction';
import CategoryOverviewAnalytics from './CategoryOverviewAnalytics';
import EditCategory from './EditCategory';
import EditSubcategory from './EditSubcategory';
import { getDateRangeFromTitle } from 'utils';
import { Skeleton } from 'antd';

const CategoryOverview = ({ categoryData, tabKey, setShowEditModal, loading }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });
  const { selectedDateRangeTopbar } = useSelector(({ overview }) => overview);

  const {
    updateCategories: { success: updateSuccess },
  } = useSelector(({ categories }) => categories);

  const {
    getCategoryStatistics: { data: stats = {} },
  } = useSelector(({ categories }) => categories);

  const [selectedRange, setSelectedRange] = useState('This Year');

  useEffect(() => {
    const { from, to } = getDateRangeFromTitle(selectedRange);
    if (categoryData?.code && tabKey === 'overview') {
      dispatch(
        getCategoryStatistics({
          code: categoryData.code,
          from,
          to,
        }),
      );
    }
  }, [categoryData?.code]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(fetchSingleCategory(categoryData?.code));
      setShowEditModal(false);
    }
  }, [updateSuccess]);

  const handleSelectRange = (title, date) => {
    setSelectedRange(title);
    const { from, to } = getFromAndTo(date);
    const payload = {
      code: categoryData.code,
      from,
      to,
    };
    dispatch(getCategoryStatistics(payload));
  };

  const toggleHandler = () => {
    setStep(1);
    setIsOpen(!isOpen);
  };

  const { limit = 0, spent = 0, available = 0, bufferAmount } = categoryData || {};
  const overSpent = spent - limit;

  const { expensesBreakdown } = stats;

  const totalSpent = Object.values(expensesBreakdown || {}).reduce((acc, next) => {
    return acc + next.totalAmount;
  }, 0);

  // if (loading || statsLoading) return <Loading isPage color="#D28B28" />;

  return (
    <Container
      className="pt-1 overview-holder shallow"
      style={{ height: `calc(100vh - 14rem)` }}
    >
      <div className="pb-4 pt-2">
        {canManage && (
          <Col xs={12} md={3} lg={2} className="budget-overview-amount">
            <p>Category limit</p>
            {loading ? (
              <Skeleton.Input
                active
                style={{
                  marginTop: '8px',
                  height: 20,
                  width: '150px',
                  minWidth: '100px',
                }}
              />
            ) : (
              <div className="d-flex budget-goal">
                <h6 className="goal-amount">
                  {limit === 0 || !limit ? (
                    'Unlimited'
                  ) : (
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={numFormatter(limit / 100)}
                      displayType="text"
                      thousandSeparator={true}
                      className="text-dark"
                      isNumericString
                    />
                  )}
                </h6>

                {bufferAmount > 0 && (
                  <>
                    <span className="distance-between">+</span>
                    <h6 className="bufferamount">
                      <CurrencyFormat
                        prefix={getCurrency('NGN')}
                        value={numFormatter(bufferAmount / 100)}
                        displayType="text"
                        thousandSeparator={true}
                        className="text-dark"
                        isNumericString
                      />
                      {'  '}
                      buffer
                    </h6>
                  </>
                )}
                <div className="cursor" onClick={() => setShowEditModal(true)}>
                  Edit
                </div>
              </div>
            )}
          </Col>
        )}
      </div>
      <Row>
        <Col xs={12}>
          <LineStatus
            active={percentageValue(limit, limit ? spent : 0)}
            // pending={percentageValue(limit, available)}
            exceeded={available < 0}
          />
        </Col>
      </Row>

      <Row className="pt-4">
        <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
          <p className="mt-1">
            <span className="dots active-line" />
            Spent
          </p>

          {loading ? (
            <Skeleton.Input
              active
              style={{
                marginLeft: '0.95rem',
                height: 20,
                width: '80px',
                minWidth: '60px',
              }}
            />
          ) : (
            <h2 className="px-3">
              <CurrencyFormat
                prefix={getCurrency('NGN')}
                value={numFormatter(spent / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
                className="currency-amount"
              />
            </h2>
          )}
        </Col>
        {!!limit && (
          <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
            <p className="mt-1">
              <span className="dots available-dot" />
              Available
            </p>

            {loading ? (
              <Skeleton.Input
                active
                style={{
                  marginLeft: '0.95rem',
                  height: 20,
                  width: '80px',
                  minWidth: '60px',
                }}
              />
            ) : (
              <h2 className="px-3">
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={numFormatter(available < 0 ? 0 : available / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  className="text-dark currency-amount"
                  isNumericString
                />
              </h2>
            )}
          </Col>
        )}

        {overSpent > 0 && (
          <Col xs={12} md={3} lg={2} className="budget-overview-amount mb-4">
            <TooltipContent tooltip="This is the amount you have overspent">
              <p className="mt-1">
                <span className="dots overdraft" />
                Overspent
              </p>
            </TooltipContent>
            {loading ? (
              <Skeleton.Input
                active
                style={{
                  marginLeft: '0.95rem',
                  height: 20,
                  width: '80px',
                  minWidth: '60px',
                }}
              />
            ) : (
              <h2 className="px-3">
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={numFormatter(overSpent / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                  className="currency-amount"
                />
              </h2>
            )}
          </Col>
        )}
      </Row>

      <Col lg={12} className="chartSide p-2 border-top">
        <Row>
          <Col xs={12} className="p-0">
            <CategoryOverviewAnalytics
              chartData={expensesBreakdown}
              totalSpent={`${totalSpent}`}
              currency={'NGN'}
              totalPercentage={getPercentage(limit, spent)}
              stats={stats || {}}
              selectedRange={selectedRange}
              handleSelectRange={handleSelectRange}
              categoryData={categoryData}
              loading={loading}
            />
          </Col>
        </Row>
      </Col>
    </Container>
  );
};
export default CategoryOverview;
