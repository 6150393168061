export const VERIFY_BVN_REQUEST = 'VERIFY_BVN_REQUEST';
export const VERIFY_BVN_SUCCESS = 'VERIFY_BVN_SUCCESS';
export const VERIFY_BVN_ERROR = 'VERIFY_BVN_ERROR';

export const VERIFY_BVN_OTP_REQUEST = 'VERIFY_BVN_OTP_REQUEST';
export const VERIFY_BVN_OTP_SUCCESS = 'VERIFY_BVN_OTP_SUCCESS';
export const VERIFY_BVN_OTP_ERROR = 'VERIFY_BVN_OTP_ERROR';

export const RESET_BLOCK_BVN = 'RESET_BLOCK_BVN';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  verifyBvn: { ...block },
  verifyBvnOTP: { ...block },
};

export const BvnRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_BVN_REQUEST:
      return {
        ...state,
        verifyBvn: {
          ...state.verifyBvn,
          loading: true,
        },
      };
    case VERIFY_BVN_SUCCESS:
      return {
        ...state,
        verifyBvn: {
          ...state.verifyBvn,
          loading: false,
          success: true,
          data: action.data,
          responseCode: action.data.responseCode,
        },
      };

    case VERIFY_BVN_ERROR:
      return {
        ...state,
        verifyBvn: {
          ...state.verifyBvn,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case VERIFY_BVN_OTP_REQUEST:
      return {
        ...state,
        verifyBvnOTP: {
          ...state.verifyBvnOTP,
          loading: true,
        },
      };
    case VERIFY_BVN_OTP_SUCCESS:
      return {
        ...state,
        verifyBvnOTP: {
          ...state.verifyBvnOTP,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case VERIFY_BVN_OTP_ERROR:
      return {
        ...state,
        verifyBvnOTP: {
          ...state.verifyBvnOTP,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_BVN:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
