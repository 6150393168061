import { CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import CustomTextarea from 'components/UI/CustomTextarea';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import { createVendors, updateVendors } from '../../redux/actions/VendorsAction';
import { useDebounce } from 'hooks/useDebounce';
import { RESET_BLOCK_VENDOR } from 'redux/reducers/VendorsReducer';

const vendorInitial = {
  name: null,
  email: null,
  city: null,
  description: null,
  taxIdentificationNumber: null,
  country: null,
  state: null,
  street: null,
  taxWithHolding: null,
  accountNumber: '',
  accountName: '',
  bankName: '',
  currency: 'NGN',
};
const VendorModal = ({ isOpen, toggleHandler, data = null, isUpdate }) => {
  if (!isOpen) return <div />;

  const dispatch = useDispatch();

  const {
    getVendor: { loading },
    createVendor: { loading: creatingVendor, success: successVendors },
    updateVendor: { loading: updatingVendor, success: successUpdateVendors },
  } = useSelector(({ vendors }) => vendors);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };
  const getCategories = () => {
    const { categories } = data || {};
    return {
      isLoading: false,
      options: [],
      ...(categories && { value: categories.code, label: categories.name }),
    };
  };

  const [vendorDetails, setVendorDetails] = useState(vendorInitial);

  const [countries, setCountries] = useState([]);

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    dispatch(getCountries());
    if (!banksData) dispatch(getAllBanks());
  }, []);

  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    if (data) {
      const countryDetails = countries.find(
        (country) =>
          country.value === companyData?.address?.country ||
          country.label === companyData?.address?.country,
      );

      if (countryDetails) {
        dispatch(getState(countryDetails?.value));
      }
    }
  }, [countries]);

  useEffect(() => {
    if (data) {
      const countryDetails = countries.find(
        (country) =>
          country.value === companyData?.address?.country ||
          country.label === companyData?.address?.country,
      );

      setVendorDetails({
        ...vendorDetails,
        ...data,
        category: data.categories.code
          ? { value: data.categories.code, label: data.categories.value }
          : undefined,
        country: countryDetails,
        state: stateData?.find((item) => item.value === data.address?.state),
        accountNumber: data?.bankAccounts?.[0]?.number ?? '',
        bankName:
          allBanks?.find((item) => item.value === data?.bankAccounts?.[0]?.bankCode) ??
          '',
      });
    }
  }, [countries, stateData]);

  useEffect(() => {
    if (successState) {
      const stateOptions = states.states?.map((value) => ({
        label: value,
        value: value,
      }));

      setStateData(stateOptions);
    }
  }, [successState]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  //Everything that has to do with categories

  //ends here
  const handleClose = () => toggleHandler();

  useEffect(() => {
    if (countryData.length > 0) {
      const countryOptions = countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));

      setCountries(countryOptions);
    }
  }, [countryData]);

  useEffect(() => {
    if (successVendors || successUpdateVendors) {
      handleClose();
      dispatch({ type: RESET_BLOCK_VENDOR, blockType: 'createVendor' });
      dispatch({ type: RESET_BLOCK_VENDOR, blockType: 'updateVendor' });
    }
  }, [successVendors, successUpdateVendors]);

  const handleChangeVendorDetails = ({ target: { name, value, validity, rawValue } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setVendorDetails({ ...vendorDetails, [name]: value });
    return setVendorDetails({ ...vendorDetails, [name]: rawValue ? rawValue : value });
  };

  const createNewVendor = () => {
    if (!vendorDetails.name) return toastError('Please enter name');
    if (!vendorDetails.description) return toastError('Please enter description');
    if (!vendorDetails.country) return toastError('Please select country');
    if (!vendorDetails.city) return toastError('Please enter city');
    if (!vendorDetails.state) return toastError('Please select state');
    if (!vendorDetails.street) return toastError('Please enter address');
    if (vendorDetails.taxWithHolding) {
      if (!(vendorDetails.taxWithHolding > 0 && vendorDetails.taxWithHolding <= 100))
        return toastError('Please enter a number between 1 and 100');
    }
    if (!vendorDetails.bankName.value) return toastError('Please select a bank');
    if (vendorDetails.accountNumber) {
      if (vendorDetails.accountNumber.length < 10)
        return toastError('Account number must be 10 digits');
    }
    const hasAccount =
      vendorDetails.bankName.value &&
      vendorDetails.accountName &&
      vendorDetails.accountNumber;

    const payload = {
      name: vendorDetails.name,
      email: vendorDetails.email,
      address: {
        country: vendorDetails.country.value,
        city: vendorDetails.city,
        stateOrProvince: vendorDetails.state.value,
        street: vendorDetails.street,
      },
      ...(hasAccount && {
        bankAccount: {
          bankName: vendorDetails.bankName.label,
          bankCode: vendorDetails.bankName.value,
          accountName: vendorDetails.accountName,
          number: vendorDetails.accountNumber,
          currency: vendorDetails.currency,
        },
      }),
      description: vendorDetails.description,
      taxIdentificationNumber: vendorDetails.taxIdentificationNumber || undefined,
      taxWithHolding: vendorDetails.taxWithHolding || undefined,
      categories:
        (vendorDetails?.category?.value && [vendorDetails?.category?.value]) || null,
    };
    if (isUpdate) {
      dispatch(updateVendors({ ...payload, code: data.code }));
    } else dispatch(createVendors(payload));
  };

  useEffect(() => {
    if (vendorDetails.accountNumber.length === 10 && vendorDetails.bankName.value) {
      const { accountNumber, bankName } = vendorDetails;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }
    if (vendorDetails.accountNumber.length < 10) {
      setVendorDetails({ ...vendorDetails, accountName: undefined });
    }
  }, [vendorDetails.accountNumber, vendorDetails.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setVendorDetails({ ...vendorDetails, accountName: accName.account_name });
    }
    if (accountNameError) setVendorDetails({ ...vendorDetails, accountName: undefined });
    if (accountNameLoading)
      setVendorDetails({ ...vendorDetails, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  return (
    <div>
      <Modal show={isOpen} onClose={toggleHandler}>
        <div className="content">
          <div className="card-modal-header">
            <>
              <div className="d-flex align-items-center cursor" onClick={handleClose}>
                <CloseOutlined />
                <span className="ps-1">Cancel</span>
              </div>
            </>
          </div>

          <div className="card-modal-body">
            <div className="beneficiaries">
              <h2 className="card-title w-100">
                {isUpdate ? 'Update' : 'Create new'} vendor
              </h2>

              {loading ? (
                <Loading />
              ) : (
                <>
                  <Row className="mb-3">
                    <Row className="mb-3">
                      <CustomInput
                        label="Vendor name *"
                        placeholder="Enter vendor name"
                        type="text"
                        name="name"
                        onChange={handleChangeVendorDetails}
                        value={vendorDetails.name}
                        maxLength="100"
                      />
                    </Row>
                    <Row className="mb-3">
                      <CustomTextarea
                        label="Description *"
                        name="description"
                        placeholder="Enter a description of the vendor"
                        onChange={handleChangeVendorDetails}
                        value={vendorDetails.description}
                        maxLength="200"
                        rowSize={2}
                      />
                    </Row>
                    <Row className="mb-3">
                      <CustomInput
                        label="Street *"
                        placeholder="90/60 Ajose Adeogun Street"
                        type="text"
                        name="street"
                        onChange={handleChangeVendorDetails}
                        value={vendorDetails.street}
                        maxLength="100"
                      />
                    </Row>
                    <Row className="mb-3">
                      <CustomSelect
                        label="Country *"
                        name="country"
                        placeholder="Select country"
                        // onChange={(val) => setVendorDetails({ ...vendorDetails, country: val })}
                        onChange={(val) => {
                          setVendorDetails({ ...vendorDetails, country: val });
                          getCountryStates(val);
                        }}
                        // value={vendorDetails.country}
                        options={countries}
                        value={vendorDetails?.country}
                      />
                    </Row>
                    <Row className="align-items-center">
                      <CustomSelect
                        isDisabled={!vendorDetails.country?.label}
                        label="State *"
                        name="state"
                        placeholder="State/Region"
                        // onChange={handleChangeVendorDetails}
                        onChange={(val) =>
                          setVendorDetails({ ...vendorDetails, state: val })
                        }
                        value={vendorDetails?.state}
                        options={stateData}
                        md={6}
                      />
                      <CustomInput
                        label="City *"
                        placeholder="Enter name"
                        type="text"
                        name="city"
                        onChange={handleChangeVendorDetails}
                        // onChange={handleChangeBusinessAddress}
                        value={vendorDetails.city}
                        maxLength="50"
                        md={6}
                      />
                    </Row>
                  </Row>
                  <Row className="mb-3">
                    <CustomInput
                      label="Email"
                      placeholder="Enter email"
                      type="email"
                      name="email"
                      onChange={handleChangeVendorDetails}
                      value={vendorDetails.email}
                    />
                  </Row>
                  <Row className="mb-3">
                    <CategoryDropdown
                      label="Expense category"
                      placeholder="Select category"
                      onChange={(value) =>
                        handleChangeVendorDetails({
                          target: { name: 'category', value },
                        })
                      }
                      value={vendorDetails.category}
                      name="category"
                    />
                  </Row>
                  <Row className="mb-3">
                    <CustomInput
                      label="Tax identification number"
                      placeholder="Enter tax identification number"
                      type="text"
                      name="taxIdentificationNumber"
                      onChange={handleChangeVendorDetails}
                      value={vendorDetails.taxIdentificationNumber}
                    />
                  </Row>
                  <Row className="mb-3">
                    <CustomInput
                      label="Tax withholding percentage"
                      placeholder="5%"
                      type="number"
                      isAmount
                      maxLength="5"
                      name="taxWithHolding"
                      onChange={handleChangeVendorDetails}
                      value={vendorDetails.taxWithHolding}
                      useCurrency={false}
                    />
                  </Row>
                  <Row className="mb-3 ">
                    <CustomSelect
                      label="Bank"
                      name="bankName"
                      placeholder="Select Bank"
                      onChange={(val) =>
                        setVendorDetails({ ...vendorDetails, bankName: val })
                      }
                      value={vendorDetails.bankName}
                      onMenuClose={onMenuCloseBanks}
                      options={allBanks}
                      onInputChange={handleGetBankOnChange}
                      isDisabled={loadingBanks && !bankValuedebounced}
                      isLoading={loadingBanks && !bankValuedebounced}
                    />
                  </Row>

                  <Row className="mb-3">
                    <div className={classNames('relative', { ['mb-5']: visible })}>
                      <CustomInput
                        type="text"
                        label="Account number"
                        placeholder="Enter number"
                        name="accountNumber"
                        onChange={handleChangeVendorDetails}
                        value={vendorDetails.accountNumber}
                        maxLength="10"
                        pattern="[0-9]*"
                      />
                      <AccountName
                        setValue={(value) =>
                          setVendorDetails({ ...vendorDetails, accountName: value })
                        }
                        visible={visible}
                        accountNameError={accountNameError}
                        accountNameLoading={accountNameLoading}
                      />
                    </div>
                  </Row>

                  <Row className="mb-5">
                    <CustomInput
                      type="text"
                      label="Account name"
                      placeholder="Add account name"
                      name="accountName"
                      onChange={handleChangeVendorDetails}
                      value={vendorDetails.accountName}
                      maxLength="50"
                      pattern="[a-zA-Z -]*"
                      disabled
                    />
                  </Row>
                  <div className="modal-footer mt-3">
                    <CustomButton
                      onClick={toggleHandler}
                      disabled={creatingVendor || updatingVendor}
                      fullWidth={true}
                      className="custom-button ghost-button"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      fullWidth={true}
                      className="custom-button primary-button"
                      loading={creatingVendor || updatingVendor}
                      disabled={creatingVendor || updatingVendor}
                      onClick={createNewVendor}
                    >
                      {!isUpdate ? 'Create' : 'Update'} vendor
                    </CustomButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default VendorModal;
