import {
  getCharFromKey,
  isAlphaNumericKey,
  isKeyPrintable,
  isNavigationKey,
  keyCodes,
  TextCellTemplate,
} from '@silevis/reactgrid';
import React from 'react';

export class CustomTextCellTemplate extends TextCellTemplate {
  constructor(getInputRef, characterLength) {
    super();
    this.inputRefs = {}; // Create an object to store refs by rowId
    this.getInputRef = getInputRef; // Store the function to forward the ref
    this.characterLength = characterLength;
  }

  isFocusable = (cell) => {
    if (cell.nonEditable) return false;
    return true;
  };

  render(cell, isInEditMode, onCellChanged) {
    const { rowId } = cell;

    // Create or reuse a ref for this specific row
    if (!this.inputRefs[rowId]) {
      this.inputRefs[rowId] = React.createRef();
    }

    const currentRef = this.inputRefs[rowId];

    // Pass the ref to the parent component
    if (this.getInputRef && currentRef.current) {
      this.getInputRef(rowId, currentRef.current);
    }

    if (!isInEditMode) {
      return (
        <span ref={currentRef} className="d-block">
          {cell.text}
        </span>
      );
    }
    return (
      <div className="w-100">
        <textarea
          className="custom-rg-input h-100"
          ref={(textarea) => {
            textarea && textarea.focus();
            textarea &&
              textarea.setSelectionRange(textarea.value.length, textarea.value.length);
          }}
          maxLength={this.characterLength}
          defaultValue={cell.text}
          onChange={(e) =>
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                text: e.currentTarget.value,
              }),
              false,
            )
          }
          onBlur={(e) => {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              !this.wasEscKeyPressed,
            );
            this.wasEscKeyPressed = false;
          }}
          onCopy={(e) => e.stopPropagation()}
          onCut={(e) => e.stopPropagation()}
          onPaste={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
              e.stopPropagation();
          }}
        />
      </div>
    );
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt, key) {
    const char = getCharFromKey(key);

    if (!ctrl && isKeyPrintable(key) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: cell.text === '-' ? char : cell.text + char,
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
}
