import React, { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import FilterList from './FilterList';
import AccordionCard from '../UI/AccordionCard';
import CustomButton from '../UI/CustomButton';
import './styles.less';
import CustomDate from '../UI/CustomDate';
import { toastError } from 'components/UI/toast';
import { getFromAndTo } from 'utils/utility';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { selectDateRnageTopbar } from 'redux/actions/OverviewAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';

const FilterModal = ({
  filterData = [],
  inputPlaceholder = 'Search ',
  handleFilterSelect,
  handleFilterApply,
  handleToggleFilterPopover,
  withDate,
  dateTitle,
  withOutSearch,
  clearFilters = null,
  setSelectedRange,
}) => {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState('0');
  const [searchVal, setSearchVal] = useState('');
  const [date, setDate] = useState({
    from: '',
    to: '',
  });
  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  const handleChange = (value, checked, type, title) => {
    if (type === 'Checkbox') {
      const updateData = filterData.map((list) => {
        const filterType = list?.type;

        if (list.title === title) {
          const fromIndex = list.list.map((object) => object.value).indexOf(value);
          const toIndex = 0;
          const element = list.list.splice(fromIndex, 1)[0];
          list.list.splice(toIndex, 0, element);
        }

        if (list.type === 'slider') return { ...list };

        return {
          ...list,
          list: list.list?.map((list) => {
            if (list.value === value) {
              return {
                ...list,
                isSelected: checked,
              };
            } else {
              if (filterType === 'radio') return { ...list, isSelected: false };
              return { ...list };
            }
          }),
        };
      });
      //
      handleFilterSelect(updateData, { value, checked });
    }
    if (type === 'Slider') {
      const setFilter = filterData.map((list) => {
        if (list.type === 'slider') {
          return {
            ...list,
            value: value,
          };
        }
        return { ...list };
      });
      handleFilterSelect(setFilter, {});
    }
  };

  const handleApply = () => {
    const updateData = filterData.map((list) => {
      if (list.type === 'slider') return {};
      return {
        ...list,
        list: list.list?.filter((list) => list.isSelected === true),
      };
    });
    const query = {};
    updateData.map((data) => {
      if (data?.list?.length > 0) {
        query[data.title?.toLowerCase()] =
          data?.type === 'radio' ? data.list?.[0]?.value : data.list.map((l) => l.value);
      }
    });
    if (searchVal) {
      query.search = searchVal;
    }
    if (withDate) {
      if ((date.from && !date.to) || (!date.from && date.to))
        return toastError('Pick a date range of from - to');
      if (date.from) query.from = date.from;
      if (date.to) query.to = date.to;
      if (date.from && date.to) {
        const rangeDate =
          date.from === date.to
            ? dayjs(date.from).format('DD MMM, YYYY')
            : `${dayjs(date.from).format('DD MMM, YYYY')} - ${dayjs(date.to).format(
                'DD MMM, YYYY',
              )}`;
        setSelectedRange(rangeDate);
      } else {
        setSelectedRange('Last 12 months');
      }
    }

    handleFilterApply(query);
    handleToggleFilterPopover();
  };

  const handleDateChange = ({ target: { value, name } }) => {
    let composedDate = name === 'from' ? `${value} 00:00:00` : `${value} 23:59:59`;
    setDate({ ...date, [name]: composedDate });
  };
  return (
    <div className="actions-dialog pb-3 set-height-filter position-relative">
      <h3 className="filter-title">Filter</h3>
      {!withOutSearch && (
        <div className="search-wrapper pb-2">
          <Input
            placeholder={inputPlaceholder}
            prefix={<SearchOutlined className="searchIcon" />}
            value={searchVal}
            onChange={({ target: { value } }) => setSearchVal(value)}
          />
        </div>
      )}
      {clearFilters && (
        <div className="clear-filter-title" onClick={clearFilters}>
          Clear
        </div>
      )}

      <Accordion defaultActiveKey="0" activeKey={activeKey}>
        {filterData.map((data, i) => (
          <AccordionCard
            key={i}
            eventKey={i.toString()}
            activeKey={activeKey}
            title={data.title}
            handleClick={handleSelect}
            isFilter
            contextClass="ps-2 pe-4"
            wrapperClass="pb-1 pt-2"
          >
            <FilterList data={data} handleChange={handleChange} />
          </AccordionCard>
        ))}
        {withDate && (
          <AccordionCard
            eventKey={filterData.length.toString()}
            activeKey={activeKey}
            title={dateTitle ?? 'Creation date'}
            handleClick={handleSelect}
            isFilter
            contextClass="ps-2 pe-4"
            wrapperClass="pb-1 pt-2"
          >
            <div className="filter-action-list pb-0">
              <Row className="mb-3">
                <Col md={6}>
                  <CustomDate label="From" name="from" onChange={handleDateChange} />
                </Col>
                <Col md={6}>
                  <CustomDate label="To" name="to" onChange={handleDateChange} />
                </Col>
              </Row>
            </div>
          </AccordionCard>
        )}
      </Accordion>

      <div className="px-3 mt-2">
        <CustomButton className="apply-btn add-button" onClick={handleApply}>
          Apply
        </CustomButton>
      </div>
    </div>
  );
};
export default FilterModal;
