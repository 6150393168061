import React from 'react';
import cs from 'classnames';
import './styles.scss';

const AccountName = ({
  accountNameLoading,
  accountName,
  accountNameError,
  setValue,
  visible,
}) => {
  return (
    <div className={cs('account-dropdown', { ['account-visible']: visible })}>
      {accountNameLoading && (
        <div className="account-list">
          <div>
            <button type="button" className="account-button disabled">
              <div className="d-inline-flex align-items-center">
                <div className="spinner-3"></div>
                <span>Please wait</span>
              </div>
            </button>
          </div>
        </div>
      )}
      {(accountName || (accountNameError && !accountNameLoading)) && (
        <div className="account-list">
          <div>
            <button
              type="button"
              onClick={() => setValue(accountName)}
              className={cs('account-button', { ['disabled']: accountNameError })}
            >
              <div className="d-inline-flex align-items-center">
                <div
                  className={cs({
                    ['text-danger']: accountNameError,
                    ['fw-bold']: !accountNameError,
                  })}
                >
                  {accountNameError
                    ? 'Unable to validate bank account, please try again'
                    : accountName}
                </div>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountName;
