import { CloseOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createRoles, editPermissions } from 'redux/actions/RolesAction';

import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomTextarea from 'components/UI/CustomTextarea';
import Modal from 'components/UI/Modal';
import { RESET_BLOCK_ROLES } from 'redux/reducers/RolesReducer';
import { capitalizeFirstLetter } from 'utils/helper';

/**
 *
 * @param {Record<string, boolean>} rolePermissions
 * @param {string} name
 * @param {boolean} selected
 */
function getNewRolePermissions(rolePermissions, name, selected) {
  const [parentName, subName] = name.split('-');
  const isWildCard = subName === '*';
  const groupPrefix = `${parentName}-`;

  const newRolePermissions = _.mapValues(rolePermissions, (value, permissionName) => {
    // Keep value if not in same group
    if (!permissionName.startsWith(groupPrefix)) return value;

    // Return toggle state if permission matches or selection is wildcard
    if (isWildCard || permissionName === name) return selected;

    // Turn off wildcard if current selection is false
    const permissionIsWildCard = permissionName.includes('-*');
    if (permissionIsWildCard && !selected) return false;

    return value;
  });

  // Turn on wildcard if others in group are selected
  if (selected && !isWildCard) {
    const groupIsSelected =
      Object.keys(
        _.pickBy(
          newRolePermissions,
          (value, permissionName) => !value && permissionName.startsWith(groupPrefix),
        ),
      ).length === 1;
    if (groupIsSelected) newRolePermissions[`${parentName}-*`] = true;
  }

  return newRolePermissions;
}

/**
 *
 * @param {{name: string, code: string}[]} allPermissions
 * @param {Record<string, boolean>} rolePermissions
 * @returns {string[]}
 */
function getCreatePermissionPayload(allPermissions, rolePermissions) {
  const indexedPermissions = _.keyBy(allPermissions, 'name');

  return _.chain(rolePermissions)
    .mapValues((value, permissionName) => {
      if (!value) return false;
      return indexedPermissions[permissionName]?.code;
    })
    .filter(Boolean)
    .value();
}

/**
 *
 * @param {[string, {name: string, code: string}[]][]} permissionGroups
 * @param {Record<string, boolean>} rolePermissions
 * @param {{name: string, code: string}[]} initialPermissions
 */
function getEditPermissionPayload(permissionGroups, rolePermissions, initialPermissions) {
  const permissionsToEdit = [];
  const initialRolePermissions = _.mapValues(rolePermissions, () => false);
  initialPermissions.forEach(
    (permission) => (initialRolePermissions[permission?.name] = true),
  );

  permissionGroups.forEach(([groupKey, permissions]) => {
    const indexedPermissions = _.keyBy(permissions, 'name');
    const wildCardKey = `${groupKey}-*`;

    const wildCardIsInitiallySelected = !!initialRolePermissions[wildCardKey];
    const wildCardIsCurrentlySelected = !!rolePermissions[wildCardKey];

    if (wildCardIsInitiallySelected && !wildCardIsCurrentlySelected) {
      permissionsToEdit.push(indexedPermissions[wildCardKey]);
      Object.entries(rolePermissions).forEach(([permissionName, isSelected]) => {
        if (!isSelected) permissionsToEdit.push(indexedPermissions[permissionName]);
      });

      //
    } else if (!wildCardIsInitiallySelected && wildCardIsCurrentlySelected) {
      permissionsToEdit.push(indexedPermissions[wildCardKey]);
      Object.entries(rolePermissions).forEach(([permissionName, isSelected]) => {
        if (
          isSelected &&
          initialRolePermissions[permissionName] !== rolePermissions[permissionName]
        )
          permissionsToEdit.push(indexedPermissions[permissionName]);
      });
    } else if (!wildCardIsInitiallySelected && !wildCardIsCurrentlySelected) {
      permissions.forEach((permission) => {
        if (
          initialRolePermissions[permission?.name] !== rolePermissions[permission?.name]
        ) {
          permissionsToEdit.push(permission);
        }
      });
    } else {
    }
  });

  return _.chain(permissionsToEdit)
    .compact(permissionsToEdit)
    .map((permission) => permission?.code)
    .uniq()
    .value();
}

export const PermissionsModal = ({
  isOpen, // Not needed if using conditional rendering
  toggleHandler,
  selectedRole,
  editPermissionList,
  setEditPermissions,
  showConfirmationModal,
  confirmationModal,
  setDuplicatePermissions,
  duplicatePermissionList,
  isDuplicate,
}) => {
  if (!isOpen) return <div />;

  const dispatch = useDispatch();
  const {
    getRoles: { data: rolesData = [], loading: isFetchingRoles, success: isRolesFetched },
    editPermissions: { loading: isEditingPermission, success: isPermissionEdited },
    createRole: { loading: isCreating, success: isCreatingSuccess },
  } = useSelector(({ roles }) => roles);

  const allPermissions = rolesData[0]?.permissions ?? [];

  const [roleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  // Map of permissions as keys and true/false as value
  const [rolePermissions, setRolePermissions] = useState({});

  const onClose = () => {
    if (editPermissionList.length && !confirmationModal) {
      return showConfirmationModal();
    }
    if (editPermissionList.length) return;
    return toggleHandler();
  };

  // Update state when props change
  useEffect(() => {
    const {
      permissions = [],
      name = '',
      description = '',
    } = selectedRole?.singleData ?? {};

    const newRolePermissions = _.fromPairs(
      allPermissions.map((permission) => [permission?.name, false]),
    );

    permissions.forEach((permission) => (newRolePermissions[permission.name] = true));
    if (!isDuplicate) {
      setRoleName(name);
      setDescription(description);
    }
    setRolePermissions(newRolePermissions);
  }, [selectedRole?.singleData]);

  const permissionGroups = useMemo(() => {
    // Sort to make org permission group and wildcard permissions at top
    const sortedPermissions = allPermissions.slice(0).sort((permission1, permission2) => {
      if (permission1?.name.startsWith('org')) return -1;
      if (permission2?.name.startsWith('org')) return 1;

      if (permission1?.name === 'access-full') return -1;
      if (permission2?.name === 'access-limited') return 1;

      if (permission1?.name.includes('-*')) return -1;
      if (permission2?.name.includes('-*')) return 1;

      return 0;
    });

    const groups = Object.entries(
      _.groupBy(sortedPermissions, (permission) => permission?.name?.split('-')[0]),
    );
    const selectedPermissionGroups = _.groupBy(
      (selectedRole?.singleData?.permissions,
      (selectedPermissions) => selectedPermissions.split('-')[0]),
    );

    // Sort to make most selected permissions at top
    groups.sort(([groupKey1], [groupKey2]) => {
      const selectedPermissionCount1 = selectedPermissionGroups[groupKey1]?.length ?? 0;
      const selectedPermissionCount2 = selectedPermissionGroups[groupKey2]?.length ?? 0;

      return selectedPermissionCount2 - selectedPermissionCount1;
    });

    return groups;
  }, [allPermissions]);

  const setPermission = (name, selected) => {
    setRolePermissions(getNewRolePermissions(rolePermissions, name, selected));
    update(name, selected);
  };

  const update = (name, selected) => {
    const current = editPermissionList.length
      ? editPermissionList
      : duplicatePermissionList;
    const index = current.findIndex((item) => item === name);
    if (index > -1) {
      current.splice(index, 1);
    } else if (selected) {
      current.push(name);
    }
    if (editPermissionList.length) {
      setEditPermissions(current);
    } else {
      setDuplicatePermissions(current);
    }
  };

  const onSubmit = () => {
    const isCreate = !selectedRole;
    if (isCreate || isDuplicate) {
      dispatch(
        createRoles({
          name: roleName,
          permissions: getCreatePermissionPayload(allPermissions, rolePermissions),
          description,
        }),
      );
    } else {
      dispatch(
        editPermissions({
          name: roleName,
          code: selectedRole?.code,
          description,
          permissions: getEditPermissionPayload(
            permissionGroups,
            rolePermissions,
            selectedRole?.singleData?.permissions ?? [],
          ),
        }),
      );
    }
    setEditPermissions([]);
  };

  useEffect(() => {
    if (!isCreating && isCreatingSuccess) {
      onClose();
      dispatch({ type: RESET_BLOCK_ROLES, blockType: 'createRole' });
    }
    if (!isEditingPermission && isPermissionEdited) {
      onClose();
      dispatch({ type: RESET_BLOCK_ROLES, blockType: 'editPermissions' });
    }
  }, [isCreating, isCreatingSuccess, isEditingPermission, isPermissionEdited]);

  const isDefaultRole = selectedRole?.singleData?.type === 'default' && !isDuplicate;

  return (
    <Modal show={isOpen} onClose={() => onClose()}>
      <div className="content px-md-0 py-md-0 px-3 py-4">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={() => onClose()}>
            <CloseOutlined />
            <span className="ps-1">Cancel</span>
          </div>
        </div>

        <div className="card-modal-body">
          <div className="beneficiaries">
            <h2 className="card-title w-100">
              {selectedRole?.code?.length && !isDuplicate
                ? `Edit ${selectedRole?.name?.value} role`
                : 'Create custom role'}
            </h2>
            <div className="custom-roles">
              <Row>
                <CustomInput
                  label="Role Name"
                  placeholder="Enter role name"
                  type="text"
                  name="role"
                  onChange={(e) => setRoleName(e.target.value)}
                  value={roleName}
                  disabled={isDefaultRole}
                />
              </Row>
              <Row>
                <CustomTextarea
                  label="Role Description"
                  placeholder="Describe the role"
                  type="text"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  rowSize={2}
                  maxLength={50}
                  disabled={isDefaultRole}
                />
              </Row>
              <div className="mt-4 permissions">
                {permissionGroups.map(([groupKey, permissions]) => (
                  <div className="mb-4" key={groupKey}>
                    <h1>{groupKey} management</h1>

                    {permissions.map(({ name, description }) => (
                      <section
                        className="d-flex justify-content-between align-center permissions-list"
                        key={name}
                      >
                        <p>{capitalizeFirstLetter(description.replace('Can ', ''))}</p>
                        <Switch
                          checked={!!rolePermissions[name]}
                          onChange={(checked) => setPermission(name, checked)}
                          disabled={isDefaultRole}
                        />
                      </section>
                    ))}
                  </div>
                ))}
              </div>
            </div>

            {!isDefaultRole && (
              <div className="modal-footer mt-3">
                <CustomButton
                  onClick={onClose}
                  fullWidth={true}
                  className="custom-button ghost-button"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  onClick={onSubmit}
                  fullWidth={true}
                  className="custom-button primary-button"
                  loading={isCreating || isEditingPermission}
                  disabled={isCreating || isEditingPermission}
                >
                  Confirm
                </CustomButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
