import { default as US } from '../icons/united-states-crest.svg';
import { default as PSBank } from '../images/9PSB-logo.png';
import { default as Providus } from '../images/providus-bank-icon.png';
import { default as Zenith } from '../icons/nigeria-logos/zenith_bank/zenith_bank.svg';
import { default as Access } from '../images/access-bank-icon.png';
import { default as Paystack } from '../icons/nigeria-logos/paystack/paystack.svg';
import { default as GTB } from '../icons/nigeria-logos/guaranty_trust_bank/guaranty_trust_bank.svg';
import { default as fidelity_bank } from '../icons/nigeria-logos/fidelity_bank/fidelity_bank.svg';

const BanksIcons = {
  'providus bank': Providus,
  '9 payment solutions bank': PSBank,
  '9 payment service bank': PSBank,
  '9psb': PSBank,
  ninepsb: PSBank,
  'zenith bank': Zenith,
  'access bank': Access,
  gtbank: GTB,
  paystack: Paystack,
  'fidelity bank': fidelity_bank,
  'first internet bank of indiana': US,
};

export default BanksIcons;
