import { ArrowUpRightIcon } from 'assets/icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './styles.scss';

const ItemsDetails = ({
  title,
  value,
  className,
  titleClass,
  valueClass,
  fullCursor,
  isLink,
  withAction,
}) => {
  return (
    <div className={classNames('mt-1', { ['cursor']: fullCursor })}>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className={classNames('items-wrapper__transfer align-items-start', {
            [className]: className,
          })}
        >
          <div className={classNames({ [titleClass]: titleClass }, 'title__transfer')}>
            {title}
          </div>

          {isLink && !withAction && (
            <Link
              to={isLink}
              target="_blank"
              className="value__transfer text-decoration-none"
            >
              <span
                className={classNames({ [valueClass]: valueClass }, 'value__transfer')}
              >
                {value}
              </span>
              <ArrowUpRightIcon stroke="#1c1917" />
            </Link>
          )}
          {isLink && withAction && (
            <>
              <Link
                to={isLink}
                target="_blank"
                className="value__transfer text-decoration-none d-flex align-items-center gap-2"
              >
                <span
                  className={classNames({ [valueClass]: valueClass }, 'value__transfer')}
                >
                  {value}
                </span>
                <ArrowUpRightIcon stroke="#1c1917" />
              </Link>
            </>
          )}
          {!isLink && !withAction && (
            <div
              className={classNames({ [valueClass]: valueClass }, 'ps-4 value__transfer')}
            >
              {value}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemsDetails;
