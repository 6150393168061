import Table from 'components/Table';
import 'jspdf-autotable';
import { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorApprovers } from 'redux/actions/VendorProfileAction';
import { vendorApproversColumns } from 'utils/mockData';
import { buildApproversTableData } from '../../../utils/helper';
import NoData from './NoData';
import TableLoading from './TableLoading';

const ApproversTable = ({ id, type, userName }) => {
  const dispatch = useDispatch();

  const {
    getVendorApprovers: { data: { approvers = [] } = {}, loading },
  } = useSelector(({ vendorProfile }) => vendorProfile);
  // const { page, total, hasMore, perPage, nextPage } = meta;

  const rows = useMemo(() => buildApproversTableData(approvers), [approvers]);

  const handlePagination = (page) => {
    dispatch(getVendorApprovers({ [type]: id }));
  };

  const tableColumn = useMemo(() => vendorApproversColumns, [approvers]);

  useEffect(() => {
    dispatch(getVendorApprovers({ [type]: id }));
  }, []);

  // if (loading) return <Loading size={45} className={'loading-state'} color="#D28B28" />;

  return (
    <>
      {!loading && !approvers.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No approvers"
            bodyText={`${userName} does not have any approvers yet`}
            withButton={true}
            buttonLabel={
              <span className="d-flex justify-content-center">Show all Invoices</span>
            }
            // onClickHandler={clearLocationState}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={rows}
                  // pagination
                  // hasMore={hasMore}
                  hasCheckBox={false}
                  // currentPage={page}
                  // nextPage={() => handlePagination(nextPage)}
                  // previousPage={() => handlePagination(page - 1)}
                  // totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default ApproversTable;
