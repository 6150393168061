import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomSelect from 'components/UI/CustomSelect';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  downlaodAccountStatements,
  generateAccountStatements,
} from 'redux/actions/AccountStatementAction';
import { getBalances } from 'redux/actions/BudgetsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

const StatementModal = ({ closeHandler, isModalOpen }) => {
  if (!isModalOpen) return <div />;

  const handleBack = () => {
    return closeHandler();
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    generateStatement: { loading, success },
  } = useSelector(({ accountStatement }) => accountStatement);

  const {
    getBalances: {
      data: { budgets = [] } = {},
      loading: loadingBalances,
      success: successBalance,
    },
  } = useSelector(({ budgets }) => budgets);

  const { permissions } = allPermissions();
  const canDownload = hasPermission({
    permissions,
    scopes: ['statement-*', 'statement-export'],
  });

  const [data, setData] = useState({
    source: '',
    type: '',
    start_date: '',
    end_date: '',
    statusType: '',
  });

  const onChange = (val, type) => {
    setData({
      ...data,
      [type]: ['end_date', 'start_date'].includes(type) ? format(val, 'yyyy-MM-dd') : val,
    });
  };

  const handleSubmit = () => {
    const { source, type, start_date, end_date } = data;

    if (!source) {
      toastError('Please select a statement source');
      return;
    }

    if (!type) {
      toastError('Please select a transaction type');
      return;
    }

    if (!start_date && end_date) {
      toastError('Please select a start date');
      return;
    }

    const isEndDateBeforeStartDate = dayjs(end_date).isBefore(dayjs(start_date));

    if (isEndDateBeforeStartDate)
      return toastError('Start date should be ahead of end date!');

    const payload = {
      source: source?.value,
      type: type?.value,
      startDate: start_date || undefined,
      endDate: end_date || undefined,
    };

    dispatch(generateAccountStatements(payload));
  };

  useEffect(() => {
    if (success) {
      const { source, type, start_date, end_date } = data;
      const payload = {
        source: source?.value,
        type: type?.value,
        startDate: start_date || undefined,
        endDate: end_date || undefined,
      };
      if (canDownload) dispatch(downlaodAccountStatements(payload));

      const queryParams = new URLSearchParams(location.search);

      queryParams.set('source', source?.value);
      queryParams.set('type', type?.value);

      if (start_date && end_date) {
        queryParams.set('startDate', start_date);
        queryParams.set('endDate', end_date);
      }

      history.push({
        pathname: '/account-statement',
        search: queryParams.toString(),
      });
    }
  }, [success]);

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const listBudgets = useMemo(() => {
    const allBudgets = budgets?.map((item) => ({
      label: item.name,
      value: item.code,
    }));

    const otherSource = [
      { label: 'All accounts', value: 'general' },
      { label: 'Main balance', value: 'balance' },
      {
        label: 'Budgets',
        options: allBudgets,
      },
      // { label: 'Budgets', value: 'budgets', isDisabled: true },
    ];

    return otherSource.concat(allBudgets);
  }, [successBalance]);

  return (
    <Modal show={isModalOpen} onClose={closeHandler}>
      <div className="content px-md-0 py-md-0 px-3 py-4">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleBack}>
            <>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="fundsTitle mb-1">
            <h2 className="card-title w-100">Generate Account Statement</h2>
          </div>

          <CustomSelect
            label="Select source"
            placeholder="Source"
            isDisabled={loadingBalances}
            isLoading={loadingBalances}
            className="mb-2"
            name="source"
            options={listBudgets}
            onChange={(val) => onChange(val, 'source')}
            value={data?.source}
          />
          <CustomSelect
            label="Select Transaction type"
            placeholder="Transaction type"
            isDisabled={false}
            name="type"
            options={[
              { value: 'all', label: 'All' },
              { value: 'credit', label: 'Credit' },
              { value: 'debit', label: 'Debit' },
            ]}
            onChange={(val) => onChange(val, 'type')}
            value={data?.type}
          />
          <div className="d-flex gap-4 my-2">
            <div>
              <CustomDatePicker
                label="Start Date"
                placeholderText="Select date"
                peekNextMonth
                maxDate={new Date()}
                onChange={(val) => onChange(val, 'start_date')}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showIcon={false}
                name="start_date"
                selected={data?.start_date && dayjs(data?.start_date).toDate()}
              />
            </div>
            <div>
              <CustomDatePicker
                label="End Date"
                placeholderText="Select date"
                name="end_date"
                peekNextMonth
                minDate={data?.start_date ? dayjs(data.start_date).toDate() : null}
                maxDate={new Date()}
                onChange={(val) => onChange(val, 'end_date')}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={data?.end_date && dayjs(data?.end_date).toDate()}
                showIcon={false}
              />
            </div>
          </div>

          <CustomButton
            fullWidth
            className="mb-4 mt-4"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <Loading color="#fff" size={18} /> : 'Submit'}
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};
export default StatementModal;
