import {
  SIGNUP_REQUEST,
  LOGIN_REQUEST,
  RESET_BLOCK_AUTH,
  RESET_FLAGS_AUTH,
  LOGOUT,
  GET_USER_PROFILE_REQUEST,
  OTP_REQUEST,
  RESET_PASSWORD_REQUEST,
  NEW_PASSWORD_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  SET_BENEFICIARY_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  VERIFY_INVITED_USER_REQUEST,
  SET_INVITED_USER_PASSWORD_REQUEST,
  RESEND_VERIFICATION_CODE_REQUEST,
  RESET_STATE,
} from '../reducers/AuthReducer';

export const signup = (payload) => ({ type: SIGNUP_REQUEST, payload });

export const verifyOTP = (payload) => ({ type: OTP_REQUEST, payload });

export const login = (payload) => ({ type: LOGIN_REQUEST, payload });

export const setBeneficiaryPassword = (payload) => ({
  type: SET_BENEFICIARY_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordAction = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const newPassword = (payload) => ({ type: NEW_PASSWORD_REQUEST, payload });

export const updateUserPasswordAction = (payload) => ({
  type: UPDATE_USER_PASSWORD_REQUEST,
  payload,
});

export const get_user_profile = () => ({ type: GET_USER_PROFILE_REQUEST });

export const update_user_profile = (payload) => ({
  type: UPDATE_USER_PROFILE_REQUEST,
  payload,
});

export const resendVerificationCode = (payload) => ({
  type: RESEND_VERIFICATION_CODE_REQUEST,
  payload,
});

export const resetBlockAuth = (payload) => ({
  type: RESET_BLOCK_AUTH,
  payload,
});

export const resetFlagsAuth = (payload) => ({
  type: RESET_FLAGS_AUTH,
  payload,
});

export const logout = (sessionExpired = false) => ({
  type: LOGOUT,
  sessionExpired,
});

export const resetState = () => ({
  type: RESET_STATE,
});

//Demo
export const verifyInvitedUserAction = (payload) => ({
  type: VERIFY_INVITED_USER_REQUEST,
  payload,
});

export const setInvitedUserPasswordAction = (payload) => ({
  type: SET_INVITED_USER_PASSWORD_REQUEST,
  payload,
});
