import { ArrowLeftOutlined } from '@ant-design/icons';
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import Layout, { OverflowWrapLayout } from 'pages/Authentication/Layout';
import { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { verifyVendorInvite, verifyVendorOtp } from 'redux/actions/VendorsAction';
import Vslide from 'assets/images/slider/v_slider.png';
import { toastError } from 'components/UI/toast';

const steps = [
  { name: 'Security', isActive: true, completed: false },
  { name: 'Vendor details', isActive: false, completed: false },
  { name: 'Payment details', isActive: false, completed: false },
  { name: 'Contact information', isActive: false, completed: false },
];

const contents = [
  {
    title: 'Impactful title',
    desc: 'Powerful, self-serve product and growth analytics to help you convert.',
    imgSrc: Vslide,
  },
  {
    title: 'Another Title',
    desc: 'Additional description goes here. Make sure to tailor it to your audience.',
    imgSrc: Vslide,
  },
  {
    title: 'Final Slide',
    desc: 'Your last piece of information should be impactful and memorable.',
    imgSrc: Vslide,
  },
];

const VerifyVendorOTP = () => {
  const dispatch = useDispatch();
  const {
    verifyVendorOtp: { success, loading, data },
  } = useSelector(({ vendors }) => vendors);
  const { getRoles: { loading: loadingRoles } = {} } = useSelector(({ roles }) => roles);
  const { code: hash } = useParams();
  const { push } = useHistory();

  const [code, setCode] = useState('');
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCode(value.replace(/\W+/g, ''));
  };

  useEffect(() => {
    if (!hash) window.open('https://www.bujeti.com/', '_self');
    if (hash) {
      dispatch(verifyVendorInvite({ hash }));
    }
  }, []);

  useEffect(() => {
    if (code.length === 6) handleSubmit();
  }, [code]);

  const handleSubmit = (event) => {
    if (!code) return toastError('Please enter otp');
    dispatch(verifyVendorOtp({ otp: code, hash }));
  };

  useEffect(() => {
    if (success) {
      push({
        pathname: '/complete-profile',
        state: { vendor: data.vendor },
      });
    }
  }, [success]);

  const handleResendCode = () => {
    // const updateEmail = location.state?.authData;
    // dispatch(resendVerificationCode({ email: updateEmail }));
  };

  return (
    <Layout menus={steps} slider={contents}>
      <AuthFormHeader
        title="Enter your verification code"
        subtitle={`We just sent a 6-digit code to your email address. Please check your inbox and enter the code to proceed`}
      />
      <OverflowWrapLayout>
        <Form noValidate validated={false} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <CustomInput
              label="Verification code"
              error="enter valid code"
              placeholder="Enter code"
              value={code}
              required
              name="code"
              onChange={handleChange}
              autoComplete="off"
            />
          </Row>
          <CustomButton
            type="button"
            fullWidth
            disabled={loading || loadingRoles}
            loading={loading || loadingRoles}
          >
            Verify email
          </CustomButton>
          <h4 className="secondary-action">
            <span className="text-sm"> Didn’t receive the email? </span>
            <span className="link-tag text-sm cursor" onClick={handleResendCode}>
              Resend code
            </span>
          </h4>
        </Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default VerifyVendorOTP;
