import {
  FETCH_INVOICES_REQUEST,
  FETCH_INVOICE_REQUEST,
  CREATE_INVOICE_REQUEST,
  SEARCH_INVOICE_CUSTOMERS_REQUEST,
  SHARE_INVOICE_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMER_REQUEST,
  DELETE_INVOICE_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  MARK_AS_PAID_REQUEST,
  UPDATE_INVOICE_REQUEST,
  UPDATE_SCHEDULED_INVOICE_REQUEST,
  SCAN_INVOICE_REQUEST,
  FETCH_SCHEDULED_INVOICE_REQUEST,
  FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST,
  DELETE_SCHEDULED_INVOICE_REQUEST,
  CREATE_BULK_CUSTOMER_REQUEST,
} from '../reducers/InvoiceReducer';

export const fetchAllInvoices = (payload) => ({
  type: FETCH_INVOICES_REQUEST,
  payload,
});

export const fetchSingleInvoice = (payload) => ({
  type: FETCH_INVOICE_REQUEST,
  payload,
});

export const fetchScheduledInvoice = (payload) => ({
  type: FETCH_SCHEDULED_INVOICE_REQUEST,
  payload,
});

export const fetchSingleScheduledInvoice = (payload) => ({
  type: FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST,
  payload,
});

export const deleteScheduledInvoice = (payload) => ({
  type: DELETE_SCHEDULED_INVOICE_REQUEST,
  payload,
});

export const createInvoiceAction = (payload) => ({
  type: CREATE_INVOICE_REQUEST,
  payload,
});

export const searchInvoiceCustomers = (payload) => ({
  type: SEARCH_INVOICE_CUSTOMERS_REQUEST,
  payload,
});

export const shareInvoiceAction = (payload) => ({
  type: SHARE_INVOICE_REQUEST,
  payload,
});

export const createInvoiceCustomer = (payload) => ({
  type: CREATE_CUSTOMER_REQUEST,
  payload,
});

export const fetchAllCustomersAction = (payload) => ({
  type: FETCH_CUSTOMERS_REQUEST,
  payload,
});

export const fetchAllCustomerAction = (payload) => ({
  type: FETCH_CUSTOMER_REQUEST,
  payload,
});

export const deleteInvoice = (payload) => ({
  type: DELETE_INVOICE_REQUEST,
  payload,
});

export const deleteCustomer = (payload) => ({
  type: DELETE_CUSTOMER_REQUEST,
  payload,
});

export const markInvoiceAsPaid = (code, payload) => ({
  type: MARK_AS_PAID_REQUEST,
  payload: { code, ...payload },
});

export const updateInvoice = (payload) => ({
  type: UPDATE_INVOICE_REQUEST,
  payload,
});

export const updateScheduledInvoice = (payload) => ({
  type: UPDATE_SCHEDULED_INVOICE_REQUEST,
  payload,
});

export const scanFile = (payload) => ({
  type: SCAN_INVOICE_REQUEST,
  payload,
});

export const createBulkCustomers = (payload) => ({
  type: CREATE_BULK_CUSTOMER_REQUEST,
  payload,
});
