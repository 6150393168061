import {
  GET_ROLES_REQUEST,
  CREATE_ROLES_REQUEST,
  DELETE_ROLE_REQUEST,
  EDIT_ROLE_NAME_REQUEST,
  EDIT_PERMISSIONS_REQUEST,
} from 'redux/reducers/RolesReducer';

export const getRoles = (payload) => ({
  type: GET_ROLES_REQUEST,
  payload,
});

export const createRoles = (payload) => ({
  type: CREATE_ROLES_REQUEST,
  payload,
});

export const deleteRoleByCode = (payload) => ({
  type: DELETE_ROLE_REQUEST,
  payload,
});

export const editRoleName = (payload) => ({
  type: EDIT_ROLE_NAME_REQUEST,
  payload,
});

export const editPermissions = (payload) => ({
  type: EDIT_PERMISSIONS_REQUEST,
  payload,
});
