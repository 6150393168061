import React, { useState } from 'react';
import { CancelICon } from 'assets/icons/index';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import dayjs from 'dayjs';
import { set, setHours, setMinutes, isSameDay, format } from 'date-fns';

const ScheduleInvoice = ({ onClose, loading, handleCreate }) => {
  const [schedule, setSchedule] = useState({ date: '', time: '' });

  const currentTime = new Date();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  // Set the minimum/maximum time to the current time
  const minTime = isSameDay(schedule?.date, currentTime)
    ? setHours(setMinutes(new Date(), currentMinutes + 5), currentHours)
    : null;
  const maxTime = isSameDay(schedule?.date, currentTime)
    ? setHours(setMinutes(new Date(), 59), 23)
    : null;

  // handle submit
  const hanleSubmit = () => {
    const date = schedule.date;
    const time = schedule.time;

    // Extract hours and minutes from the time
    const hours = time.getHours();
    const minutes = time.getMinutes();

    // Combine the date and time
    const combinedDateTime = set(date, { hours, minutes });

    // Format the combined datetime
    const formattedDateTime = format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

    handleCreate(false, formattedDateTime);
  };

  return (
    <div>
      <div className="mandate-modal-overlay" onClick={onClose} />
      <div className="mandate-modal-wrapper position-relative">
        <div className="mandate-modal-body invoice schedule">
          <i onClick={onClose} className="cancel-icon">
            <CancelICon width="10" height="10" />
          </i>
          <div className="w-100">
            <div className="mt-0 w-100 mandate-wrapper">
              <div className="p-4">
                <h4 className="text-start header">Schedule invoice</h4>
                <p className="text-start text-xs desc">
                  When would you like to send the invoice?
                </p>
              </div>

              <div className="py-3 px-4 date-holder">
                <div className="mb-3 d-flex align-items-center gap-3">
                  <div>
                    <p className="date-label">Date</p>

                    <CustomDatePicker
                      id="invoice-date"
                      wrapperClass="px-0 mx-0"
                      onChange={(value) => setSchedule({ ...schedule, date: value })}
                      dateFormat="MMMM d, yyyy"
                      name="date"
                      peekNextMonth
                      minDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="MM / DD / YYYY"
                      selected={schedule?.date && dayjs(schedule?.date).toDate()}
                    />
                  </div>

                  <div>
                    <p className="pb-4"></p>

                    <CustomDatePicker
                      id="invoice-date"
                      wrapperClass="px-0 mx-0"
                      onChange={(value) => setSchedule({ ...schedule, time: value })}
                      showTimeSelect
                      showTimeSelectOnly
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      timeFormat="h:mm aa"
                      name="time"
                      timeIntervals={15}
                      isClock
                      minTime={minTime}
                      maxTime={maxTime}
                      disabled={!schedule?.date}
                      dropdownMode="select"
                      placeholderText="9:00  AM"
                      selected={schedule?.time && dayjs(schedule?.time).toDate()}
                    />
                  </div>
                </div>
              </div>

              <div className="px-4 py-3 d-flex justify-content-end gap-3 ms-auto w-50">
                <button disabled={loading} className="cancel-button " onClick={onClose}>
                  Cancel
                </button>

                <button
                  className="confirm-button"
                  loading={loading}
                  onClick={hanleSubmit}
                  disabled={loading || !schedule.time}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleInvoice;
