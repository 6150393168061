/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Overview from './Overview/view';
import './styles.scss';

const Cash = () => {
  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const [company, setCompany] = useState({});

  useEffect(() => {
    if (companyData && companyData.code) setCompany(companyData);
  }, [companyData]);
  return (
    <div className="dashboard-wrapper">
      {/*//TODO: NotificationModal testing view*/}
      {/*<NotificationModal />*/}

      <Container>
        <Overview company={company} />
      </Container>
    </div>
  );
};
export default Cash;
