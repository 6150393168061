import React, { useEffect, useState } from 'react';
import PasswordLabel from './PasswordLabel';
import './styles.scss';
import { Form } from 'react-bootstrap';
import { HintAlertIcon } from 'assets/icons';

const CheckPasswordStrength = ({ password, validate, validated }) => {
  const [passwordStrength, setPasswordStrength] = useState({});

  const passwordStrengths = (password) => {
    const checkLength = password.length > 7;
    const checkLowerCase = !!password.match(/[a-z]+/);
    const checkUppercase = !!password.match(/[A-Z]+/);
    const checkNumber = !!password.match(/[0-9]+/);
    const checkCharacter = !!password.match(/[!@#$%^&*~_+-]+/);

    const validatePassword =
      checkLowerCase && checkUppercase && checkNumber && checkCharacter && checkLength;
    validate(validatePassword);

    return { checkLowerCase, checkUppercase, checkNumber, checkCharacter, checkLength };
  };

  const { checkLowerCase, checkUppercase, checkNumber, checkCharacter, checkLength } =
    passwordStrength;

  useEffect(() => {
    setPasswordStrength(passwordStrengths(password));
  }, [password]);

  return (
    <div className="password-strength-wrapper space-y-4">
      {password && !validated ? (
        <div className="flex flex-row gap-2 mt-2">
          {!validated ? (
            <Form.Text className="text-muted gap-1 d-flex align-items-center text-xs">
              <HintAlertIcon />
              <span className="text-xs">
                Must contain 1 uppercase letter, 1 number, min. 8 characters.
              </span>
            </Form.Text>
          ) : null}

          {/* <PasswordLabel checker={checkLength} label="Contains at least 8 characters" />
          <PasswordLabel
            checker={checkCharacter}
            label="Must have at least one symbol (!@#$%^&*~_+-)"
          />
          <PasswordLabel
            checker={checkLowerCase}
            label="Must have at least one lowercase letter"
          />
          <PasswordLabel
            checker={checkUppercase}
            label="Must have at least one uppercase letter"
          />
          <PasswordLabel checker={checkNumber} label="Must have at least one number" /> */}
        </div>
      ) : null}
    </div>
  );
};

export default CheckPasswordStrength;
