import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { RESET_SPECIFIC_FLAG } from 'redux/reducers/ProgressUploadReducer';

import {
  CREATE_BILL_REQUEST,
  CREATE_BILL_SUCCESS,
  CREATE_BILL_ERROR,
  FETCH_BILLS_REQUEST,
  FETCH_BILLS_SUCCESS,
  FETCH_BILLS_ERROR,
  FETCH_SINGLE_BILL_REQUEST,
  FETCH_SINGLE_BILL_SUCCESS,
  FETCH_SINGLE_BILL_ERROR,
  UPDATE_BILL_REQUEST,
  UPDATE_BILL_SUCCESS,
  UPDATE_BILL_ERROR,
  RESET_FLAGS_BILL,
  PAY_BILL_SUCCESS,
  PAY_BILL_ERROR,
  PAY_BILL_REQUEST,
  DRAFT_BILLS_REQUEST,
  DRAFT_BILLS_SUCCESS,
  DRAFT_BILLS_ERROR,
  FETCH_SCHEDULED_BILLS_REQUEST,
  FETCH_SCHEDULED_BILLS_SUCCESS,
  FETCH_SCHEDULED_BILLS_ERROR,
  FETCH_SINGLE_SCHEDULED_BILL_REQUEST,
  FETCH_SINGLE_SCHEDULED_BILL_SUCCESS,
  FETCH_SINGLE_SCHEDULED_BILL_ERROR,
  DELETE_BILL_REQUEST,
  DELETE_BILL_SUCCESS,
  DELETE_BILL_ERROR,
  DELETE_SCHEDULED_BILL_REQUEST,
  DELETE_SCHEDULED_BILL_SUCCESS,
  DELETE_SCHEDULED_BILL_ERROR,
  FETCH_ARCHIVED_BILLS_REQUEST,
  FETCH_ARCHIVED_BILLS_SUCCESS,
  FETCH_ARCHIVED_BILLS_ERROR,
  MARK_BILL_AS_PAID_REQUEST,
  MARK_BILL_AS_PAID_SUCCESS,
  MARK_BILL_AS_PAID_ERROR,
  UPDATE_SCHEDULED_BILL_REQUEST,
  UPDATE_SCHEDULED_BILL_SUCCESS,
  UPDATE_SCHEDULED_BILL_ERROR,
} from '../reducers/BillReducer';

async function createBill(payload) {
  return await Axios.post('/bills', payload);
}

function* handleCreateBill({ payload }) {
  try {
    const response = yield call(createBill, payload);
    if (response) {
      yield put({
        type: CREATE_BILL_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_BILLS_REQUEST,
      });

      yield put({
        type: RESET_SPECIFIC_FLAG,
        payload: 'status',
      });

      // toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBill(payload) {
  const { code, ...data } = payload;
  return await Axios.put(`/bills/${code}`, data);
}

function* handleUpdateBill({ payload }) {
  try {
    const response = yield call(updateBill, payload);
    if (response) {
      yield put({
        type: UPDATE_BILL_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_BILLS_REQUEST,
      });

      yield put({
        type: DRAFT_BILLS_REQUEST,
      });

      yield put({
        type: FETCH_SCHEDULED_BILLS_REQUEST,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_FLAGS_BILL,
        blockType: 'updateBill',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateScheduledBill(payload) {
  const { code, ...data } = payload;
  return await Axios.patch(`/scheduled-bills/${code}`, data);
}

function* handleUpdateScheduledBill({ payload }) {
  try {
    const response = yield call(updateScheduledBill, payload);
    if (response) {
      yield put({
        type: UPDATE_SCHEDULED_BILL_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_BILLS_REQUEST,
      });

      yield put({
        type: FETCH_SCHEDULED_BILLS_REQUEST,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_FLAGS_BILL,
        blockType: 'updateScheduledBill',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_SCHEDULED_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchBills({ payload = {} }) {
  return await Axios.get('/bills', { params: payload });
}

function* handleFetchBills(payload) {
  try {
    const response = yield call(fetchBills, payload);
    if (response) {
      yield put({
        type: FETCH_BILLS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_BILLS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchScheduledBills({ payload = {} }) {
  return await Axios.get('/scheduled-bills', { params: payload });
}

function* handleFetchScheduledBills(payload) {
  try {
    const response = yield call(fetchScheduledBills, payload);
    if (response) {
      yield put({
        type: FETCH_SCHEDULED_BILLS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SCHEDULED_BILLS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchDraftBills({ payload = {} }) {
  return await Axios.get('/bills?status[]=draft', { params: payload });
}

function* handleFetchDraftBills(payload) {
  try {
    const response = yield call(fetchDraftBills, payload);
    if (response) {
      yield put({
        type: DRAFT_BILLS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }

    yield put({
      type: DRAFT_BILLS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchArchivedBills({ payload = {} }) {
  return await Axios.get('/bills?status[]=deleted', { params: payload });
}

function* handleFetchArchivedBills(payload) {
  try {
    const response = yield call(fetchArchivedBills, payload);
    if (response) {
      yield put({
        type: FETCH_ARCHIVED_BILLS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }

    yield put({
      type: FETCH_ARCHIVED_BILLS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSingleBill({ payload }) {
  return await Axios.get(`/bills/${payload.code}`);
}

function* handleFetchSingleBill({ payload }) {
  try {
    const response = yield call(fetchSingleBill, { payload });
    if (response) {
      yield put({
        type: FETCH_SINGLE_BILL_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fetchSingleScheduledBill({ payload }) {
  return await Axios.get(`/scheduled-bills/${payload.code}`);
}

function* handleFetchSingleScheduledBill({ payload }) {
  try {
    const response = yield call(fetchSingleScheduledBill, { payload });
    if (response) {
      yield put({
        type: FETCH_SINGLE_SCHEDULED_BILL_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FETCH_SINGLE_SCHEDULED_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function payBill({ payload }) {
  const { code, ...data } = payload;
  return await Axios.post(`/bills/${code}/pay`, data);
}

function* handleBillPayment({ payload }) {
  try {
    const response = yield call(payBill, { payload });
    if (response) {
      yield put({
        type: PAY_BILL_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_BILLS_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: PAY_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function markAsPaid({ payload }) {
  return await Axios.patch(`/bills/${payload.code}/mark-as-paid`);
}

function* handleMarkAsPaid({ payload }) {
  try {
    const response = yield call(markAsPaid, { payload });
    if (response) {
      yield put({
        type: MARK_BILL_AS_PAID_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_BILLS_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: MARK_BILL_AS_PAID_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteScheduledBill(code) {
  return await Axios.delete(`/scheduled-bills/${code}`);
}

function* handleDeleteScheduledBill({ payload }) {
  try {
    const response = yield call(deleteScheduledBill, payload);
    if (response) {
      yield put({
        type: DELETE_SCHEDULED_BILL_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_ARCHIVED_BILLS_REQUEST,
      });

      yield put({
        type: FETCH_SCHEDULED_BILLS_REQUEST,
      });

      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_SCHEDULED_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteBill(code) {
  return await Axios.delete(`/bills/${code}`);
}

function* handleDeleteBill({ payload }) {
  try {
    const response = yield call(deleteBill, payload);
    if (response) {
      yield put({
        type: DELETE_BILL_SUCCESS,
        data: response.data,
      });

      yield put({
        type: FETCH_ARCHIVED_BILLS_REQUEST,
      });

      yield put({
        type: FETCH_BILLS_REQUEST,
      });

      yield put({
        type: DRAFT_BILLS_REQUEST,
      });

      yield put({
        type: FETCH_SCHEDULED_BILLS_REQUEST,
      });

      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_BILL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(CREATE_BILL_REQUEST, handleCreateBill),
  takeLatest(FETCH_BILLS_REQUEST, handleFetchBills),
  takeLatest(FETCH_SINGLE_BILL_REQUEST, handleFetchSingleBill),
  takeLatest(UPDATE_BILL_REQUEST, handleUpdateBill),
  takeLatest(UPDATE_SCHEDULED_BILL_REQUEST, handleUpdateScheduledBill),
  takeLatest(PAY_BILL_REQUEST, handleBillPayment),
  takeLatest(DRAFT_BILLS_REQUEST, handleFetchDraftBills),
  takeLatest(FETCH_SCHEDULED_BILLS_REQUEST, handleFetchScheduledBills),
  takeLatest(FETCH_SINGLE_SCHEDULED_BILL_REQUEST, handleFetchSingleScheduledBill),
  takeLatest(DELETE_SCHEDULED_BILL_REQUEST, handleDeleteScheduledBill),
  takeLatest(DELETE_BILL_REQUEST, handleDeleteBill),
  takeLatest(FETCH_ARCHIVED_BILLS_REQUEST, handleFetchArchivedBills),
  takeLatest(MARK_BILL_AS_PAID_REQUEST, handleMarkAsPaid),
]);
