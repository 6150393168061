export const GET_REIMBURSEMENTS_REQUEST = 'GET_REIMBURSEMENTS_REQUEST';
export const GET_REIMBURSEMENTS_SUCCESS = 'GET_REIMBURSEMENTS_SUCCESS';
export const GET_REIMBURSEMENTS_ERROR = 'GET_REIMBURSEMENTS_ERROR';

export const GET_SELECTED_REIMBURSEMENTS_REQUEST = 'GET_SELECTED_REIMBURSEMENTS_REQUEST';
export const GET_SELECTED_REIMBURSEMENTS_SUCCESS = 'GET_SELECTED_REIMBURSEMENTS_SUCCESS';
export const GET_SELECTED_REIMBURSEMENTS_ERROR = 'GET_SELECTED_REIMBURSEMENTS_ERROR';

export const CREATE_REIMBURSEMENTS_REQUEST = 'CREATE_REIMBURSEMENTS_REQUEST';
export const CREATE_REIMBURSEMENTS_SUCCESS = 'CREATE_REIMBURSEMENTS_SUCCESS';
export const CREATE_REIMBURSEMENTS_ERROR = 'CREATE_REIMBURSEMENTS_ERROR';

export const UPDATE_REIMBURSEMENTS_REQUEST = 'UPDATE_REIMBURSEMENTS_REQUEST';
export const UPDATE_REIMBURSEMENTS_SUCCESS = 'UPDATE_REIMBURSEMENTS_SUCCESS';
export const UPDATE_REIMBURSEMENTS_ERROR = 'UPDATE_REIMBURSEMENTS_ERROR';

export const DELETE_REIMBURSEMENTS_REQUEST = 'DELETE_REIMBURSEMENTS_REQUEST';
export const DELETE_REIMBURSEMENTS_SUCCESS = 'DELETE_REIMBURSEMENTS_SUCCESS';
export const DELETE_REIMBURSEMENTS_ERROR = 'DELETE_REIMBURSEMENTS_ERROR';

export const DECLINE_REIMBURSEMENTS_REQUEST = 'DECLINE_REIMBURSEMENTS_REQUEST';
export const DECLINE_REIMBURSEMENTS_SUCCESS = 'DECLINE_REIMBURSEMENTS_SUCCESS';
export const DECLINE_REIMBURSEMENTS_ERROR = 'DECLINE_REIMBURSEMENTS_ERROR';

export const APPROVE_REIMBURSEMENTS_REQUEST = 'APPROVE_REIMBURSEMENTS_REQUEST';
export const APPROVE_REIMBURSEMENTS_SUCCESS = 'APPROVE_REIMBURSEMENTS_SUCCESS';
export const APPROVE_REIMBURSEMENTS_ERROR = 'APPROVE_REIMBURSEMENTS_ERROR';

export const GET_SINGLE_REIMBURSEMENTS_REQUEST = 'GET_SINGLE_REIMBURSEMENTS_REQUEST';
export const GET_SINGLE_REIMBURSEMENTS_SUCCESS = 'GET_SINGLE_REIMBURSEMENTS_SUCCESS';
export const GET_SINGLE_REIMBURSEMENTS_ERROR = 'GET_SINGLE_REIMBURSEMENTS_ERROR';

export const REVIEW_REIMBURSEMENTS_REQUEST = 'REVIEW_REIMBURSEMENTS_REQUEST';
export const REVIEW_REIMBURSEMENTS_SUCCESS = 'REVIEW_REIMBURSEMENTS_SUCCESS';
export const REVIEW_REIMBURSEMENTS_ERROR = 'REVIEW_REIMBURSEMENTS_ERROR';

export const GET_ALL_REIMBURSEMENTS_ASSET = 'GET_ALL_REIMBURSEMENTS_ASSET';
export const GET_ALL_REIMBURSEMENTS_ASSET_SUCCESS =
  'GET_ALL_REIMBURSEMENTS_ASSET_SUCCESS';
export const GET_ALL_REIMBURSEMENTS_ASSET_ERROR = 'GET_ALL_REIMBURSEMENTS_ASSET_ERROR';

export const RESET_BLOCK_REIMBURSEMENTS = 'RESET_BLOCK_REIMBURSEMENTS';

export const RESET_FLAGS_REIMBURSEMENTS = 'RESET_FLAGS_REIMBURSEMENTS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getReimbursement: { ...block },
  getSelectedReimbursement: { ...block },
  approveReimbursement: { ...block },
  declineReimbursement: { ...block },
  deleteReimbursement: { ...block },
  createReimbursement: { ...block },
  updateReimbursement: { ...block },
  getSingleReimbursement: { ...block },
  reimbursementReceipts: { ...block },
  reviewMultipleReimbursements: { ...block },
};

export const ReimbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REIMBURSEMENTS_REQUEST:
      return { ...state, getReimbursement: { ...state.getReimbursement, loading: true } };
    case GET_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        getReimbursement: {
          ...state.getReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        getReimbursement: {
          ...state.getReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case GET_SELECTED_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        getSelectedReimbursement: { ...state.getSelectedReimbursement, loading: true },
      };
    case GET_SELECTED_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        getSelectedReimbursement: {
          ...state.getSelectedReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SELECTED_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        getSelectedReimbursement: {
          ...state.getSelectedReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        createReimbursement: { ...state.createReimbursement, loading: true },
      };
    case CREATE_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        createReimbursement: {
          ...state.createReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        createReimbursement: {
          ...state.createReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        updateReimbursement: { ...state.updateReimbursement, loading: true },
      };
    case UPDATE_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        updateReimbursement: {
          ...state.updateReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        updateReimbursement: {
          ...state.updateReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case APPROVE_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        approveReimbursement: { ...state.approveReimbursement, loading: true },
      };
    case APPROVE_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        approveReimbursement: {
          ...state.approveReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case APPROVE_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        approveReimbursement: {
          ...state.approveReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case DECLINE_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        declineReimbursement: { ...state.declineReimbursement, loading: true },
      };
    case DECLINE_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        declineReimbursement: {
          ...state.declineReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DECLINE_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        declineReimbursement: {
          ...state.declineReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        deleteReimbursement: { ...state.deleteReimbursement, loading: true },
      };
    case DELETE_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        deleteReimbursement: {
          ...state.deleteReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case DELETE_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        deleteReimbursement: {
          ...state.deleteReimbursement,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        getSingleReimbursement: { ...state.getSingleReimbursement, loading: true },
      };
    case GET_SINGLE_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        getSingleReimbursement: {
          ...state.getSingleReimbursement,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        getSingleReimbursement: {
          ...state.getSingleReimbursement,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_REIMBURSEMENTS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_REIMBURSEMENTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    case GET_ALL_REIMBURSEMENTS_ASSET:
      return {
        ...state,
        reimbursementReceipts: { ...state.reimbursementReceipts, loading: true },
      };
    case GET_ALL_REIMBURSEMENTS_ASSET_SUCCESS:
      return {
        ...state,
        reimbursementReceipts: {
          ...state.reimbursementReceipts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ALL_REIMBURSEMENTS_ASSET_ERROR:
      return {
        ...state,
        reimbursementReceipts: {
          ...state.reimbursementReceipts,
          loading: false,
          error: action.error,
        },
      };

    case REVIEW_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        reviewMultipleReimbursements: {
          ...state.reviewMultipleReimbursements,
          loading: true,
        },
      };
    case REVIEW_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        reviewMultipleReimbursements: {
          ...state.reviewMultipleReimbursements,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REVIEW_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        reviewMultipleReimbursements: {
          ...state.reviewMultipleReimbursements,
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
