import {
  ArrowRight,
  BankNoteO1Icon,
  CalendarPlusIcon,
  LayersTwoIcon,
} from 'assets/icons';
import { useState } from 'react';

import { SendFundsButton } from 'components/UI/CustomButton';
import EmptyState from 'components/UI/EmptyState';
import TransactionImage from '../../assets/images/emptystate/transaction-image.png';

import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';

import {
  ApprovalRule,
  SchedulePayment,
  SingleTransaction,
} from 'assets/icons/empty-state-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';
import { useMediaQuery } from 'react-responsive';

const TransactionEmptyStateData = ({ handleSelect, selectedCategoryData = null }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };
  const { permissions } = allPermissions();
  const canCreateTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-create'],
  });

  const handleSupport = () => {
    window.open('https://www.bujeti.com/bank-payments', '_blank');
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction({ selectedCategoryData }));
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>

        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  const childrenData = [
    {
      title: 'Make Single or Batch Payments',
      body: 'Initiate individual or grouped payments to vendors, suppliers, or colleagues.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SingleTransaction />,
    },

    {
      title: 'Schedule a Payment',
      body: 'Plan and schedule payments in advance to ensure timeliness and avoid likely penalties.',
      actionTitle: 'View more',
      action: handleSupport,
      icon: <SchedulePayment />,
    },
    {
      title: 'Create Approval Rules',
      body: "Maintain control and compliance with your organization's policies and guidelines.",
      actionTitle: 'View more',
      action: handleSupport,
      icon: <ApprovalRule />,
    },
  ];

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const mainData = {
    title: 'Review and analyse individual financial transactions with ease.',
    body: 'Manage your financial transactions, make single and batch payments, and set approval rules. See how it works here',
    image: TransactionImage,
    action: () =>
      canCreateTransaction && (
        <AllowedTo scopes={['transaction-*', 'transaction-create']}>
          <div className="w-fit">
            <CustomPopover
              zIndex="1"
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
              content={<Actions />}
              placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
              id={isTabletOrMobile ? 'invoice-schedule' : ''}
            >
              <SendFundsButton customClass="w-fit" onClick={handleButtonToggle} />
            </CustomPopover>
          </div>
        </AllowedTo>
      ),
  };

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default TransactionEmptyStateData;
