import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow-left.svg';
import CustomButton from 'components/UI/CustomButton';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import {
  budgetBeneficiariesAPI,
  budgetCard,
  getBalances,
  getBudgets,
} from 'redux/actions/BudgetsAction';
import { createCard, getCard, getMccs, getMyCardsAction } from 'redux/actions/CardAction';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import {
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
  sortDays,
} from 'utils/helper';
import CustomInput from '../../../components/UI/CustomInput';
import { RESET_BLOCK_CARD } from '../../../redux/reducers/CardReducer';
import CardsTableModal from '../CardsTableModal';
import './styles.scss';

import ExchangeRate from 'components/UI/ExchangeRate';
import { currencyConversionRate } from 'redux/actions/BudgetsAction';

import { Switch } from 'antd';
import { ChevronDown, ChevronUp, Globe, ListMark, Phone } from 'assets/icons';
import PolicyInfo from 'components/PolicyDetailModal/PolicyInfo';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import MultipleSelect from 'components/UI/MultipleSelect';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import CurrencyFormat from 'react-currency-format';
import { getPolicies } from 'redux/actions/PoliciesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import flashCard from '../../../assets/images/card/flash-card.png';
import physicalCard from '../../../assets/images/card/physical-card.png';
import virtualCard from '../../../assets/images/card/virtual-card.png';
import InviteMembers from './InviteMembers';
import RequestPhysicalCard from './RequestPhysicalCard';

const CARD_TYPE_OPTIONS = [
  {
    id: 'physical',
    value: 'physical',
    label: 'Physical',
    title: 'For all payments and cash withdrawals',
    description: 'Available within 5 working days and valid for 4 years.',
    image: physicalCard,
  },
  {
    id: 'virtual',
    value: 'virtual',
    label: 'Multi-use virtual',
    title: 'For instant and recurring payments',
    description: 'Available and usable immediately. Valid for 2 years.',
    image: virtualCard,
  },
  {
    id: 'flash',
    value: 'flash',
    label: 'Single-use virtual',
    title: 'One-time payment or temporary use',
    description:
      'Available and usable immediately. Custom validity from 1 day to 1 year.',
    image: flashCard,
  },
];

const defaultInput = {
  name: '',
  type: { value: '', label: '' },
  amount: '',
  currency: '',
  assignBudget: '',
  beneficiary: '',
};

export const cardSettingData = [
  {
    image: <Globe />,
    label: 'Online transactions',
    value: 'onlineTransaction',
    isChecked: true,
  },
  {
    image: <Phone />,
    label: 'Contactless transactions',
    value: 'contactlessTransaction',
    isChecked: true,
  },
];

const CardsModal = ({
  isOpen,
  toggleHandler,
  selectedBudget = {},
  budget = null,
  seletedBeneficiary = null,
  onSave = () => null,
  IsOtherPopoverOpen = () => null,
}) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const { teamsId } = useParams();
  const { permissions, beneficiaryCode } = allPermissions();

  const canViewBeneficiary = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view'],
  });

  const canViewPolicy = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-view'],
  });

  const canCreateMember = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-create'],
  });

  const [cardTypeSelected, setCardTypeSelected] = useState(false);
  const [physicalCardSteps, setPhysicalCardSteps] = useState(1);
  const [step, setStep] = useState(1);
  const [cardSetting, setCardSetting] = useState(cardSettingData);
  const [showAdvance, setShowAdvance] = useState(false);
  const [value, setValue] = useState({ beneficiary: '', vendors: '' });
  const handleSelectChange = (val, name) => setValue({ ...value, [name]: val });
  const debouncedVendorValue = useDebounce(value.vendors, 200);
  const debouncedBeneficiaryValue = useDebounce(value.beneficiary, 200);

  const [card, setCard] = useState({
    name: '',
    type: { value: '', label: '' },
    amount: '',
    currency: '',
    assignBudget: '',
    beneficiary: '',
  });
  const [dollarWallet, setDollarWallet] = useState({
    amount: null,
    currency: 'USD',
  });

  const {
    getBeneficiaries: { data = {}, loading: loadingBeneficiaries },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const {
    getBudgetBeneficiaries: {
      data: budgetBeneficiaries = {},
      loading: fetchingBudgetBeneficiaries,
    },
    getCurrencyConversionRate: {
      data: currencyExchange = {},
      loading: loadingConversion,
      success: isConverted,
    },
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);
  const {
    getVendor: { data: vendorData, success: vendorSuccess },
  } = useSelector(({ vendors }) => vendors);

  const { getPolicies: { data: { policies = [] } = {} } = {} } = useSelector(
    ({ policies }) => policies,
  );

  const { beneficiaries = [] } = budgetId ? budgetBeneficiaries : data;
  const [selectCards, setSelectCards] = useState(null);

  const {
    createCard: { data: { data: createCardData } = {}, loading, success },
    physicalCardRequest: { success: successPhysicalCard },
    getMccs: { data: mccsData, loading: loadingMccs, success: successMccs },
  } = useSelector(({ card }) => card);
  const { budgets = [] } = budget ?? budgetData;

  const existing = seletedBeneficiary && seletedBeneficiary?.map(({ user }) => user.code);

  const teamsBeneficiaries =
    seletedBeneficiary &&
    beneficiaries
      .filter(({ status, user }) => status === 'active' && existing?.includes(user.code))
      .map(({ user }) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.code,
      }));

  /**
   * @param {Array} data: is an array of object gotten from the redux store
   * @returns
   */
  const genereateBeneficiary = (data) => {
    const _tempData = data;
    return _tempData
      .filter(
        ({ status, budgets }) =>
          status === 'active' &&
          budgets.some((budget) => budget.code === card?.assignBudget?.value),
      )
      .map((beneficiary) => ({
        value: beneficiary.code,
        label: `${beneficiary.user.firstName} ${beneficiary.user.lastName}`,
      }));
  };
  const activeBeneficiary = teamsId
    ? teamsBeneficiaries
    : genereateBeneficiary(beneficiaries);

  const notOnBudgetPage =
    !beneficiaries.length &&
    hasPermission({
      permissions,
      scopes: ['budget-*'],
    }) &&
    !budgetId &&
    !budget;

  useEffect(() => {
    if (notOnBudgetPage) if (!budgets.length) dispatch(getBudgets());
  }, []);

  const handleChange = ({ name, value }) => {
    setCard({
      ...card,
      [name]: value,
    });

    if (debouncedVendorValue) dispatch(getMccs());
  };

  const setIsChecked = (value, checked) => {
    const updatedData = cardSetting.map((item) => {
      if (item.value === value) {
        return { ...item, isChecked: checked };
      }
      return item;
    });

    setCardSetting(updatedData);
  };

  useEffect(() => {
    dispatch({ type: RESET_BLOCK_BUDGET, blockType: 'getTransferableBalance' });
    if (!data.beneficiaries && canViewBeneficiary) dispatch(getBeneficiaries());
  }, []);

  const checkBalance = card?.assignBudget?.amount - card?.amount < 0;

  const handleSubmit = () => {
    if (!card?.type?.value) return toastError('Please select a type');
    if (!card.name && card.type.value !== 'physical')
      return toastError('Please enter card name');
    if (!card.amount) return toastError('Please enter an amount');
    if (+card.amount <= 0) return toastError('Please enter a valid amount');
    if (+card.amount < 10 && card.currency === 'USD')
      return toastError('Please enter a minimum amount of $10');
    if (
      currencyExchange?.amount / 100 > card?.assignBudget?.amount &&
      card?.currency === 'USD'
    )
      return toastError('Budget amount exceeded');
    if (!card.assignBudget?.value) return toastError('Please specify a budget');
    if (checkBalance)
      return toastError('Budget balance is low please topup or change the budget');
    const isValid = !!card?.type?.value && !!card?.assignBudget?.value;
    const name = card?.type?.value === 'physical' ? 'Physical Card' : card.name;

    const marchants = card?.vendors?.map((item) => item.value);

    const settings = cardSetting.reduce(
      (settings, setting) => {
        settings[setting.value] = setting.isChecked;
        return settings;
      },
      {
        expenseCategory: card?.category?.value ? card?.category?.value : undefined,
        allowedMerchants: marchants,
        spendingLimitPolicy: card?.policy?.code ? card?.policy?.code : undefined,
      },
    );

    if (isValid) {
      const payload = {
        name,
        budget: card?.assignBudget?.value,
        amount: card.amount * 100,
        type: card.type.value,
        beneficiary: beneficiaryCode ?? card.beneficiary?.value,
        currency: card.currency,
        settings: showAdvance ? settings : undefined,
      };
      dispatch(createCard(payload));
    }
  };

  const handleChangeAmount = (data) => {
    const {
      target: { name, value, rawValue },
    } = data;
    setCard({ ...card, [name]: rawValue ? rawValue : value });
    setDollarWallet({ ...dollarWallet, amount: rawValue ? rawValue : value });
  };

  const handleGetSign = (currency) => {
    setCard({ ...card, currency });
  };

  useEffect(() => {
    if (card.currency === 'USD' && Number(dollarWallet.amount) > 0) {
      dispatch(
        currencyConversionRate({
          baseCurrency: 'ngn',
          targetCurrency: card?.currency?.toLocaleLowerCase(),
          amount: card?.amount,
        }),
      );
    }
  }, [dollarWallet]);

  const onHandleCloseModal = () => {
    if (success) {
      dispatch({ type: RESET_BLOCK_CARD, blockType: 'createCard' });
      if (budgetId) {
        dispatch(budgetCard({ budget: budgetId }));
      } else {
        dispatch(getCard());
        dispatch(getMyCardsAction({ owner: userData?.user?.code }));
      }
      toggleHandler();
    } else {
      toggleHandler();
    }
  };

  const handleClose = () => {
    toggleHandler();
  };

  useEffect(() => {
    if (success) {
      onHandleCloseModal();
      dispatch({
        type: RESET_BLOCK_BUDGET,
        blockType: 'getCurrencyConversionRate',
      });
    }
  }, [success]);

  const setSelectCard = (option) => {
    setCard({
      ...defaultInput,
      type: { value: option.id, label: option.label },
    });
  };

  const onGoNext = () => {
    setCardTypeSelected(true);
  };

  const goBackToSelector = () => {
    if (physicalCardSteps === 3) return setPhysicalCardSteps(1);
    if (step > 1) {
      if (step === 2)
        dispatch({ type: RESET_BLOCK_CARD, blockType: 'activatePhysicalCard' });
      return setStep(step - 1);
    }
    if (card.type?.value === 'physical' && [2, 3].includes(physicalCardSteps))
      return setPhysicalCardSteps(1);
    return setCardTypeSelected(false);
  };

  useEffect(() => {
    const beneficiaries = activeBeneficiary?.find((option) =>
      option.label.toLowerCase().includes(debouncedBeneficiaryValue.toLowerCase()),
    );
    if (!beneficiaries && debouncedBeneficiaryValue) {
      if (budgetId) {
        dispatch(
          budgetBeneficiariesAPI({
            budget: budgetId,
            search: debouncedBeneficiaryValue?.toLowerCase(),
          }),
        );
      } else {
        dispatch(getBeneficiaries({ search: debouncedBeneficiaryValue?.toLowerCase() }));
      }
    }
  }, [debouncedBeneficiaryValue]);

  //
  useEffect(() => {
    dispatch(getBalances());
    if (canViewPolicy) dispatch(getPolicies({ type: 'Spending limits' }));
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balance');
      setBudgetList((prevOptions) => [...budget]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);
    const filteredBudgets = budgetList.filter(
      (item) =>
        (item.value !== 'balance' && card.type.value === 'physical') ||
        ['virtual', 'flash'].includes(card.type.value),
    );

    let filteredOption = filteredBudgets.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  useEffect(() => {
    if (selectedBudget?.value) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const assignBudget = budget.find((item) => item.value === selectedBudget?.value);

      setCard({
        ...card,
        assignBudget,
        beneficiary: { label: `${userData?.user.firstName} ${userData?.user.lastName}` },
        firstName: userData?.user.firstName,
        lastName: userData?.user.lastName,
      });
    } else {
      setCard({
        ...card,
        beneficiary: { label: `${userData?.user.firstName} ${userData?.user.lastName}` },
        firstName: userData?.user.firstName,
        lastName: userData?.user.lastName,
      });
    }
  }, [selectedBudget?.value, cardTypeSelected, balances?.budgets]);

  useEffect(() => {
    if (!mccsData?.mccs?.length) dispatch(getMccs());
  }, []);

  const generatedMccs = useMemo(() => {
    return mccsData?.mccs?.map(({ code: value, edited_description: label }) => ({
      label,
      value,
    }));
  }, [successMccs]);

  useEffect(() => {
    const mccsList = generatedMccs?.find((option) =>
      option.label.toLowerCase().includes(debouncedVendorValue.toLowerCase()),
    );
    if (!mccsList && debouncedVendorValue) {
      dispatch(getMccs({ search: debouncedVendorValue?.toLowerCase() }));
    }
  }, [debouncedVendorValue]);

  // filter selected type
  const selectedType = policies?.filter(
    (item) =>
      item.types.some((type) => type?.name.toLowerCase() === 'spending limits') &&
      item?.status === 'active',
  );

  const selectedPolicy = selectedType?.find((item) => item?.code === card?.policy?.code);

  const cardForm = () => (
    <div className="beneficiaries">
      <h2 className="card-title w-100">Create a {card.type?.label.toLowerCase()} card</h2>

      {card.type?.value !== 'physical' && (
        <>
          <Row className="mb-3 mt-3">
            <CustomInput
              label="Card Name"
              placeholder="Enter card's name"
              type="text"
              name="name"
              onChange={({ target: { name, value } }) => handleChange({ name, value })}
              value={card.name}
              maxLength="50"
            />
          </Row>
        </>
      )}

      <Row className="mb-3">
        <CustomSelectRadio
          label="Assign Budget"
          placeholder="Assign a Budget"
          name="assignBudget"
          isDisabled={Object.keys(selectedBudget).length > 0 || loadingBalances}
          onChange={(val) => handleChange({ name: 'assignBudget', value: val })}
          value={card.assignBudget}
          isLoading={loadingBalances}
          loadOptions={loadOptions}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Amount"
          placeholder="0.00"
          type="number"
          isAmount
          otherCurrency={true}
          name="amount"
          onChange={handleChangeAmount}
          value={card.amount}
          setIsOtherPopoverOpen={IsOtherPopoverOpen}
          getTypeVal={handleGetSign}
          editCurrency={true}
        />
      </Row>
      {card.currency === 'USD' && card.amount && (
        <ExchangeRate
          exchangeRate={currencyExchange.rate ? currencyExchange.rate : 0}
          amount={card.amount}
          loading={loadingConversion}
        />
      )}

      {hasPermission({
        permissions,
        scopes: ['employee-*', 'employee-edit', 'employee-create'],
      }) && (
        <Row className="mb-3 ">
          <CustomSelect
            label="Card holder"
            placeholderText="Member"
            name="beneficiary"
            options={activeBeneficiary}
            onChange={(value) => {
              setCard({ ...card, beneficiary: value });

              if (debouncedBeneficiaryValue) dispatch(getBeneficiaries());
            }}
            value={card.beneficiary}
            onInputChange={(value) => handleSelectChange(value, 'beneficiary')}
            isDisabled={
              ((fetchingBudgetBeneficiaries || loadingBeneficiaries) &&
                !debouncedBeneficiaryValue) ||
              !card?.assignBudget?.value
            }
            isLoading={fetchingBudgetBeneficiaries || loadingBeneficiaries}
          />

          {canCreateMember && (
            <div className="text-sm text-gray mt-2" style={{ color: '#57534E' }}>
              if you can&apos;t find some of your colleagues,{' '}
              <span
                onClick={() => setPhysicalCardSteps(3)}
                className="cursor"
                style={{
                  color: '#D28B28',
                  textDecoration: 'underline',
                  textDecorationColor: '#D28B28',
                }}
              >
                invite them to Bujeti
              </span>
            </div>
          )}
        </Row>
      )}

      <div className="mt-4 border-top advance__setting--cards">
        <div className="advance__setting-heading">
          <h3 className="d-flex justify-content-between align-items-center">
            Advanced card settings{' '}
            <span className="cursor" onClick={() => setShowAdvance(!showAdvance)}>
              {showAdvance ? <ChevronUp /> : <ChevronDown />}
            </span>
          </h3>
          <p>Selected fields will be applied to all transactions made on this card.</p>
        </div>
        {showAdvance && (
          <>
            <div className="advance__setting--payment">
              <div className="heading">Payment methods</div>
              {cardSetting.map(({ image, value, label, isChecked }) => (
                <section
                  className="d-flex justify-content-between align-items-center mb-3"
                  key={label}
                >
                  <div className="d-flex justify-content-between align-items-center payment-methods">
                    <div className="icon-wrap">
                      <span>{image}</span>
                    </div>
                    <div>{label}</div>
                  </div>

                  <Switch
                    checked={isChecked}
                    onChange={(checked) => setIsChecked(value, checked)}
                  />
                </section>
              ))}
            </div>
            {canViewPolicy && (
              <Row className="mb-3">
                <CustomRoleSelect
                  isClearable
                  label="Spending limit"
                  placeholder="Select existing spending limit"
                  name="policy"
                  options={selectedType.map(
                    ({ name: label, code, description: value }) => ({
                      label,
                      value,
                      code,
                    }),
                  )}
                  onChange={(val) => handleChange({ name: 'policy', value: val })}
                  value={card.policy}
                />

                <div>
                  {selectedPolicy?.maxAmount && (
                    <PolicyInfo
                      title="Amount"
                      value={
                        <CurrencyFormat
                          prefix={getCurrency(selectedPolicy?.currency)}
                          value={selectedPolicy?.maxAmount / 100}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      }
                    />
                  )}
                  {selectedPolicy?.frequency && (
                    <PolicyInfo
                      title="Frequency"
                      value={
                        <div className="d-flex flex-wrap ">
                          <BadgeType
                            value={selectedPolicy?.frequency}
                            isBadge={false}
                            margin
                          />
                        </div>
                      }
                      // value={sortDays(selectPolicy.period).join(', ')}
                    />
                  )}
                  {selectedPolicy?.period?.length && (
                    <PolicyInfo
                      title="Period"
                      value={
                        <div className="d-flex flex-wrap ">
                          {sortDays(selectedPolicy?.period).map((item, index) => (
                            <BadgeType value={item} key={index} isBadge={false} margin />
                          ))}
                        </div>
                      }
                      // value={sortDays(selectPolicy.period).join(', ')}
                    />
                  )}
                </div>
              </Row>
            )}

            <Row className="mb-3">
              <CategoryDropdown
                label={
                  <div className="advance__setting--label">
                    <div>Expense category</div>
                    <p>
                      All transaction will automatically fit into your chosen category.
                    </p>
                  </div>
                }
                placeholder={`Select specific spending category`}
                onChange={(value) => handleChange({ name: 'category', value: value })}
                value={card.category}
                name="category"
              />
            </Row>
            <Row className="mb-5">
              <MultipleSelect
                label={
                  <div className="advance__setting--label">
                    <div>Merchants or Vendors</div>
                    <p>
                      Selected merchants or vendors&apos; transactions are always
                      permitted.
                    </p>
                  </div>
                }
                name="vendors"
                placeholder={`Select specific vendors (e.g., "Office Depot")`}
                value={card.vendors}
                onChange={(value) => handleChange({ name: 'vendors', value: value })}
                options={generatedMccs}
                onInputChange={(value) => handleSelectChange(value, 'vendors')}
                isDisabled={loadingMccs && !debouncedVendorValue}
                isLoading={loadingMccs}
              />
            </Row>
          </>
        )}
      </div>

      <div className="modal-footer mt-3">
        <CustomButton
          onClick={onHandleCloseModal}
          disabled={loading || loadingConversion}
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          loading={loading || loadingConversion}
          disabled={loading || loadingConversion}
          onClick={handleSubmit}
        >
          Create card
        </CustomButton>
      </div>
    </div>
  );

  // const ceateCardSuccess = () => {
  //   if (loading || isSuccess) {
  //     return <Saving message="Your card is being created, please wait..." />;
  //   }
  // };

  return (
    <div>
      <Modal show={isOpen} onClose={onHandleCloseModal}>
        <div className="content px-md-0 py-md-0 px-3 py-4">
          <div className="card-modal-header">
            {!successPhysicalCard ? (
              <div
                className="d-flex align-items-center cursor"
                onClick={cardTypeSelected ? goBackToSelector : onHandleCloseModal}
              >
                {cardTypeSelected ? (
                  <LeftIcon className="cursor" onClick={goBackToSelector} />
                ) : (
                  <CloseOutlined />
                )}
                <span className="ps-1">{cardTypeSelected ? 'Back' : 'Cancel'}</span>
              </div>
            ) : null}
          </div>
          <div className="card-modal-body">
            {loading ? (
              <Saving message="Your card is being created, please wait..." />
            ) : !cardTypeSelected ? (
              <CardTypeSelector
                card={card}
                setSelectCard={setSelectCard}
                onHandleCloseModal={onHandleCloseModal}
                onGoNext={onGoNext}
              />
            ) : card.type?.value === 'physical' ? (
              <>
                <RequestPhysicalCard
                  beneficiaryList={activeBeneficiary}
                  card={card}
                  setCard={setCard}
                  assignBudget={selectedBudget}
                  loadingBalances={loadingBalances}
                  loadOptions={loadOptions}
                  setPhysicalCardSteps={setPhysicalCardSteps}
                  physicalCardSteps={physicalCardSteps}
                  toggleHandler={toggleHandler}
                />
              </>
            ) : physicalCardSteps === 3 ? (
              <InviteMembers setStep={setPhysicalCardSteps} />
            ) : (
              cardForm()
            )}
          </div>
        </div>
      </Modal>

      <CardsTableModal
        selectCards={selectCards}
        setSelectCards={setSelectCards}
        toggleHandler={handleClose}
      />
    </div>
  );
};

const CardTypeSelector = ({ card, setSelectCard, onHandleCloseModal, onGoNext }) => {
  return (
    <section className="card__typeSelector information-wrapper">
      <section className="d-flex card__typeSelector--header gap-2">
        <div className="info">
          <h6>Choose the card you want</h6>
          <p>Our cards can be used for withdrawals, purchases and online payments.</p>
        </div>
      </section>
      <section className="card__types">
        {CARD_TYPE_OPTIONS?.map((option) => {
          return (
            <section
              key={option.id}
              className={
                option.id === card?.type?.value
                  ? 'card__types--items active-card'
                  : 'card__types--items'
              }
              onClick={() => setSelectCard(option)}
            >
              <header
                className={`${
                  card?.type?.value === option?.id && 'header-active'
                } d-flex align-items-center`}
              >
                <h6>{option.label}</h6>

                <span
                  className={card?.type?.value === option.id ? 'radio isActive' : 'radio'}
                >
                  {card?.type?.value === option?.id && <ListMark />}
                </span>
              </header>
              <body className="d-flex">
                <img src={option?.image} alt={`${option.label} card`} />
                <div>
                  <h6>{option.title}</h6>
                  <p>{option.description}</p>
                </div>
              </body>
            </section>
          );
        })}
      </section>

      <div className="modal-footer mt-3">
        <CustomButton
          onClick={onHandleCloseModal}
          fullWidth={true}
          className="custom-button ghost-button"
        >
          Cancel
        </CustomButton>
        <CustomButton
          fullWidth={true}
          className="custom-button primary-button"
          onClick={onGoNext}
        >
          Create card
        </CustomButton>
      </div>
    </section>
  );
};
export default CardsModal;
