import { Axios } from 'api/axios';
import { toastError } from 'components/UI/toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getSimplifiedError } from 'utils/error';
import {
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
} from '../reducers/ProfileReducer';

async function fileUploadAPI(payload) {
  return await Axios.post('/files/url/upload', payload);
}
function* handleFileUpload({ payload }) {
  try {
    const response = yield call(fileUploadAPI, payload.fileName);
    if (response.data) {
      yield put({
        type: FILE_UPLOAD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FILE_UPLOAD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([takeLatest(FILE_UPLOAD_REQUEST, handleFileUpload)]);
