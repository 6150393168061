import { all, put, takeEvery } from 'redux-saga/effects';
import {
  TOGGLE_NOTIFICATION_REQUEST,
  TOGGLE_NOTIFICATION_SUCCESS,
  TOGGLE_REQUEST,
  TOGGLE_SUCCESS,
} from 'redux/reducers/ToggleReducer';

function* toggle(payload) {
  yield put({
    type: TOGGLE_SUCCESS,
    payload,
  });
}

function* toggleNotification(payload) {
  yield put({
    type: TOGGLE_NOTIFICATION_SUCCESS,
    payload,
  });
}

export default all([
  takeEvery(TOGGLE_REQUEST, toggle),
  takeEvery(TOGGLE_NOTIFICATION_REQUEST, toggleNotification),
]);
