import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Saving from 'components/UI/ModalSaving/Saving';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createBeneficiaries, getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right.svg';

const INITIAL = {
  firstName: '',
  lastName: '',
  role: { value: '', label: 'Select Member Role' },
  manager: { value: null, label: 'Select the manager' },
  email: '',
};
const NewBeneficairy = ({ setStep }) => {
  const dispatch = useDispatch();
  const {
    createBeneficiaries: { loading, success },
    getBeneficiaries: { data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const [form, setForm] = useState(INITIAL);
  const [beneficiaryAdded, setbeneficiaryAdded] = useState(false);
  const [otherUsers, setOtherUsers] = useState([]);

  const handleAdd = () => {
    if (!form.firstName) return toastError('Please enter member first name');
    if (!form.lastName) return toastError('Please enter member last name');
    if (!form.role.value) return toastError('Please select the member role');
    if (!form.email) return toastError('Please enter member email');
    const isValid = !!form.firstName && !!form.email && !!form.role.value;

    if (isValid) {
      const payload = {
        firstName: form.firstName,
        lastName: form.lastName,
        role: form.role.value,
        email: form.email,
        manager: form.manager.value,
      };

      dispatch(createBeneficiaries(payload));
    }
    setbeneficiaryAdded(true);
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };
  const handleSubmit = () => {
    if (!form.firstName) return toastError('Please enter member first name');
    if (!form.lastName) return toastError('Please enter member last name');
    if (!form.role.value) return toastError('Please select the member role');
    if (!form.email) return toastError('Please enter member email');
    const isValid = !!form.firstName && !!form.email && !!form.role.value;
    if (isValid) {
      const payload = {
        firstName: form.firstName,
        lastName: form.lastName,
        role: form.role.value,
        email: form.email,
        manager: form.manager.value,
      };

      dispatch(createBeneficiaries(payload));
    }
  };

  useEffect(() => {
    if (success) {
      if (!beneficiaryAdded) setStep(1);
      if (beneficiaryAdded) setForm(INITIAL);
      dispatch(getBeneficiaries());
    }
  }, [success]);

  useEffect(() => {
    if (beneficiariesData) {
      const { beneficiaries = [] } = beneficiariesData;
      const usersValueLabel = beneficiaries.map(({ code, firstName, lastName }) => ({
        value: code,
        label: `${firstName} ${lastName}`,
      }));
      setOtherUsers(usersValueLabel);
    }
  }, [beneficiariesData]);

  return (
    <div className="beneficiaries">
      <h2 className="card-title w-100">Create new member</h2>

      {loading ? (
        <Saving />
      ) : (
        <>
          <Row className="mb-3 mt-4">
            <CustomInput
              label="First Name *"
              placeholder="Enter name"
              type="text"
              name="firstName"
              onChange={handleChange}
              value={form.firstName}
            />
          </Row>
          <Row className="mb-3 mt-4">
            <CustomInput
              label="Last Name *"
              placeholder="Enter name"
              type="text"
              name="lastName"
              onChange={handleChange}
              value={form.lastName}
            />
          </Row>
          <Row className="mb-3">
            <CustomSelect
              label="Role *"
              placeholderText="Member Roles"
              name="beneficiary"
              options={[
                { value: 'employee', label: 'Employee' },
                { value: 'admin', label: 'Admin' },
                // { value: 'manager', label: 'Manager ' },
              ]}
              onChange={(val) => setForm({ ...form, role: val })}
              value={form.role}
            />
          </Row>
          <Row className="mb-3">
            <CustomInput
              label="Email *"
              placeholder="Enter email"
              type="email"
              name="email"
              onChange={handleChange}
              value={form.email}
            />
          </Row>
          <Row className="mb-3">
            <CustomSelect
              label="Manager"
              placeholderText="Select the manager"
              name="manager"
              options={otherUsers}
              onChange={(val) => setForm({ ...form, role: val })}
              value={form.role}
            />
          </Row>
          <Row className="mb-3">
            <div className="d-flex align-items-center">
              <span className="cursor" onClick={handleAdd}>
                <Plus className="ms-1" /> <span className="ps-2">Add another</span>
              </span>
            </div>
          </Row>
          <CustomButton
            className="mt-3"
            onClick={handleSubmit}
            disabled={loading}
            fullWidth={true}
          >
            Save and Continue <ArrowRight />
          </CustomButton>
        </>
      )}
    </div>
  );
};
export default NewBeneficairy;
