export const GET_MEMBER_PROFILE_REQUEST = 'GET_MEMBER_PROFILE_REQUEST';
export const GET_MEMBER_PROFILE_SUCCESS = 'GET_MEMBER_PROFILE_SUCCESS';
export const GET_MEMBER_PROFILE_ERROR = 'GET_MEMBER_PROFILE_ERROR';

export const GET_SINGLE_USER_REQUEST = 'GET_SINGLE_USER_REQUEST';
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS';
export const GET_SINGLE_USER_ERROR = 'GET_SINGLE_USER_ERROR';

export const GET_PROFILE_STATS_REQUEST = 'GET_PROFILE_STATS_REQUEST';
export const GET_PROFILE_STATS_SUCCESS = 'GET_PROFILE_STATS_SUCCESS';
export const GET_PROFILE_STATS_ERROR = 'GET_PROFILE_STATS_ERROR';

export const RESET_BLOCK_MEMBER_PROFILE = 'RESET_BLOCK_MEMBER_PROFILE';

export const RESET_FLAGS_MEMBER_PROFILE = 'RESET_FLAGS_MEMBER_PROFILE';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getMemberProfile: { ...block },
  getSingleUser: { ...block },
  getProfileStats: { ...block },
};

export const MemberProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_PROFILE_REQUEST:
      return {
        ...state,
        getMemberProfile: { ...state.getMemberProfile, loading: true },
      };
    case GET_MEMBER_PROFILE_SUCCESS:
      return {
        ...state,
        getMemberProfile: {
          ...state.getMemberProfile,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_MEMBER_PROFILE_ERROR:
      return {
        ...state,
        getMemberProfile: {
          ...state.getMemberProfile,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_USER_REQUEST:
      return {
        ...state,
        getSingleUser: { ...state.getSingleUser, loading: true },
      };
    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_USER_ERROR:
      return {
        ...state,
        getSingleUser: {
          ...state.getSingleUser,
          loading: false,
          error: action.error,
        },
      };

    case GET_PROFILE_STATS_REQUEST:
      return {
        ...state,
        getProfileStats: { ...state.getProfileStats, loading: true },
      };
    case GET_PROFILE_STATS_SUCCESS:
      return {
        ...state,
        getProfileStats: {
          ...state.getProfileStats,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_PROFILE_STATS_ERROR:
      return {
        ...state,
        getProfileStats: {
          ...state.getProfileStats,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_MEMBER_PROFILE:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };
    //reset only flags(block)
    case RESET_FLAGS_MEMBER_PROFILE:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
