import {
  BULK_VERIFY_BANK_ACCOUNTS_REQUEST,
  CREATE_BATCH_PAYMENTS_REQUEST,
  CREATE_PAYMENTS_REQUEST,
  GET_ALL_BANKS_REQUEST,
  GET_BATCH_PAYMENTS_REQUEST,
  GET_DIRECT_DEBIT_BANKS_REQUEST,
  VERIFY_BANK_ACCOUNT_REQUEST,
  LOG_EXPENSE_REQUEST,
  PAY_NOW_ACTION_REQUEST,
  CREATE_BULK_PAYMENT_REQUEST,
  GET_SINGLE_BATCH_PAYMENT_REQUEST,
  UPDATE_BATCH_PAYMENT_ASSET_REQUEST,
} from '../reducers/PaymentReducer';

export const createPayments = (payload) => ({ type: CREATE_PAYMENTS_REQUEST, payload });
export const createBatchPayment = (payload) => ({
  type: CREATE_BATCH_PAYMENTS_REQUEST,
  payload,
});
export const createBulkPayment = (payload) => ({
  type: CREATE_BULK_PAYMENT_REQUEST,
  payload,
});
export const getBatchPayment = (payload) => ({
  type: GET_BATCH_PAYMENTS_REQUEST,
  payload,
});
export const verifyBankAccount = (payload) => ({
  type: VERIFY_BANK_ACCOUNT_REQUEST,
  payload,
});

export const bulkVerifyBankAccounts = (payload) => ({
  type: BULK_VERIFY_BANK_ACCOUNTS_REQUEST,
  payload,
});

export const getAllBanks = (payload) => ({
  type: GET_ALL_BANKS_REQUEST,
  payload,
});

export const getDirectDebitBanks = (payload) => ({
  type: GET_DIRECT_DEBIT_BANKS_REQUEST,
  payload,
});

export const logExpense = (payload) => ({
  type: LOG_EXPENSE_REQUEST,
  payload,
});

export const payNowAction = (payload) => ({
  type: PAY_NOW_ACTION_REQUEST,
  payload,
});

export const getSingleBatchPayment = (payload) => ({
  type: GET_SINGLE_BATCH_PAYMENT_REQUEST,
  payload,
});

export const updateBatchPaymentAsset = (payload) => ({
  type: UPDATE_BATCH_PAYMENT_ASSET_REQUEST,
  payload,
});
