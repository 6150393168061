import NoData from 'components/NoData';

const EmptyData = ({ clearLocationState }) => {
  return (
    <div className="bill-empty-holder">
      <NoData
        headerText="You have no bill for this filter"
        bodyText="There are no bills for this filter, use the button below to show all your available bills"
        withButton={true}
        buttonLabel={
          <span className="d-flex justify-content-center">Show all bills</span>
        }
        onClickHandler={clearLocationState}
      />
    </div>
  );
};

export default EmptyData;
