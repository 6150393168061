import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_TEAM_MEMBER_ERROR,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_SUCCESS,
  CREATE_TEAMS_ERROR,
  CREATE_TEAMS_REQUEST,
  CREATE_TEAMS_SUCCESS,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  GET_TEAMS_ERROR,
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAM_MEMBERS_ERROR,
  GET_TEAM_MEMBERS_REQUEST,
  GET_TEAM_MEMBERS_SUCCESS,
  RESET_BLOCK_TEAMS,
  TEAM_MEMBER_STATUS_ERROR,
  TEAM_MEMBER_STATUS_REQUEST,
  TEAM_MEMBER_STATUS_SUCCESS,
  TEAM_OVERVIEW_ERROR,
  TEAM_OVERVIEW_REQUEST,
  TEAM_OVERVIEW_SUCCESS,
  UPDATE_TEAM_ERROR,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
} from 'redux/reducers/TeamsReducer';
import { getSimplifiedError } from 'utils/error';

async function teamOverview(code) {
  return await Axios.get(`/teams/${code}/overview`);
}
function* handleTeamOverview({ payload }) {
  try {
    const response = yield call(teamOverview, payload);
    if (response) {
      yield put({
        type: TEAM_OVERVIEW_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: TEAM_OVERVIEW_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createTeam(payload) {
  return await Axios.post(`/teams`, payload);
}
function* handleCreateTeam({ payload }) {
  try {
    const response = yield call(createTeam, payload);
    if (response) {
      yield put({
        type: CREATE_TEAMS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TEAMS,
        blockType: 'createTeam',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_TEAMS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getTeams(params) {
  return await Axios.get(`/teams`, { params });
}
function* handleGetTeams({ payload }) {
  try {
    const response = yield call(getTeams, payload);
    if (response) {
      yield put({
        type: GET_TEAMS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_TEAMS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteTeam(code) {
  return await Axios.delete(`/teams/${code}`);
}
function* handleDeleteTeam({ payload }) {
  try {
    const response = yield call(deleteTeam, payload);
    if (response) {
      yield put({
        type: DELETE_TEAM_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TEAMS,
        blockType: 'deleteTeam',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_TEAM_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateTeam({ payload, code }) {
  return await Axios.patch(`/teams/${code}`, payload);
}
function* handleUpdateTeam({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(updateTeam, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: UPDATE_TEAM_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TEAMS,
        blockType: 'updateTeam',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_TEAM_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addTeamMember({ payload, code }) {
  return await Axios.patch(`/teams/${code}/add-member`, payload);
}
function* handleAddTeamMemberTeam({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(addTeamMember, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: ADD_TEAM_MEMBER_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TEAMS,
        blockType: 'addTeamMember',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_TEAM_MEMBER_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function teamMemberStatus({ payload, code, teamMember }) {
  return await Axios.put(`/teams/${code}/members/${teamMember}`, payload);
}
function* handleTeamMemberStatus({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    delete payload.teamMember;

    const response = yield call(teamMemberStatus, {
      payload,
      code: getPayload.code,
      teamMember: getPayload.teamMember,
    });
    if (response) {
      yield put({
        type: TEAM_MEMBER_STATUS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_TEAMS,
        blockType: 'teamMemberStatus',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: TEAM_MEMBER_STATUS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getTeamMembers({ payload, code }) {
  return await Axios.get(`/teams/${code}/members`, { params: payload });
}
function* handleAddTeamMembers({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;

    const response = yield call(getTeamMembers, {
      payload,
      code: getPayload.code,
    });
    if (response) {
      yield put({
        type: GET_TEAM_MEMBERS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_TEAM_MEMBERS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(CREATE_TEAMS_REQUEST, handleCreateTeam),
  takeLatest(ADD_TEAM_MEMBER_REQUEST, handleAddTeamMemberTeam),
  takeLatest(TEAM_MEMBER_STATUS_REQUEST, handleTeamMemberStatus),
  takeLatest(GET_TEAM_MEMBERS_REQUEST, handleAddTeamMembers),
  takeLatest(GET_TEAMS_REQUEST, handleGetTeams),
  takeLatest(TEAM_OVERVIEW_REQUEST, handleTeamOverview),
  takeLatest(DELETE_TEAM_REQUEST, handleDeleteTeam),
  takeLatest(UPDATE_TEAM_REQUEST, handleUpdateTeam),
]);
