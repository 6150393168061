import React from 'react';
import './style.scss';
const IconWrap = ({ icon, bg = '#FBF5EC' }) => {
  return (
    <div style={{ background: bg }} className="icon-wrap">
      {icon}
    </div>
  );
};

export default IconWrap;
