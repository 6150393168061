import classNames from 'classnames';
import Cleave from 'cleave.js/react';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { getCard } from 'utils/helper';

const PanInput = ({ label, md = 12, wrapperClass = '', xs, ...res }) => {
  const [cardType, setCardType] = useState('');
  return (
    <Form.Group
      as={Col}
      md={md}
      xs={xs}
      className={classNames('groupWrapper position-relative', {
        [wrapperClass]: wrapperClass,
      })}
    >
      <label className="textareaLabel">{label}</label>
      <div
        className={classNames('datepicker-div w-100', { ['disabled']: res?.disabled })}
      >
        <div className="ms-3" style={{ width: '40px', height: '28px' }}>
          {getCard(cardType || 'verve')}
        </div>
        <Cleave
          className={classNames('datepicker-form-control', {
            ['disabled']: res?.disabled,
          })}
          options={{
            onCreditCardTypeChanged: function (type) {
              setCardType(type);
            },
          }}
          maxLength={19}
          {...res}
          autoComplete="off"
        />
      </div>
    </Form.Group>
  );
};

export default PanInput;
