import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import OverviewCard from 'components/OverviewCards';
import MetricCard from 'components/UI/MetricCard';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AreaChartView from '../../../components/charts/AreaChart';
import SubHeader from './components/SubHeader';

import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';
import useSegmentVisibility from 'hooks/useSegmentVisibility';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { buildChartDataFromTransaction, getCurrency } from 'utils/helper';
import { getDateRange, getFromAndTo, numFormatter } from 'utils/utility';

import { Skeleton } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';

import CustomPills from 'components/UI/Pills';
import BarChartMultiple from 'components/charts/BarChartMultiple';
import {
  getBudgetsReport,
  getCardsReport,
  getCashFlowReport,
  getCategoriesReport,
  getSpendersReport,
  getTeamsReport,
  getVendorsReport,
} from 'redux/actions/ReportsAction';
import ReportTable from './components/ReportTable';

import RevenueCard from 'components/UI/RevenueCard';
import classNames from 'classnames';

const ReportOverview = ({ company, hasLoaded, isLoading }) => {
  // TransactionsAction top header
  const dispatch = useDispatch();
  const [chartView, setChartView] = useState({
    cardsComparison: 'mtm',
    budgetsComparison: 'mtm',
    teamsComparison: 'mtm',
    vendorsComparison: 'mtm',
    categoriesComparison: 'mtm',
    spendersComparison: 'mtm',
    budgetsView: 'bar',
    teamsView: 'bar',
    cardsView: 'bar',
    vendorsView: 'bar',
    spendersView: 'bar',
    categoriesView: 'bar',
  });

  const oldChartView = usePrevious({ chartView, setChartView });

  const {
    getStatistics: { data, loading },
    getPaymentLink: { loading: loadingPaymentLink },
    verifyPaymentReference: { loading: verifyingPayment },
    getAccountReauthorizationCode: { loading: fetchingReauthenticationCode },
  } = useSelector(({ companies }) => companies);

  const {
    getCategoriesReport: { data: { categories = [], period, previousPeriod } = {} },
    getVendorsReport: { data: { vendors = [] } = {} },
    getTeamsReport: { data: { teams = [] } = {} },
    getBudgetsReport: { data: { budgets = [] } = {} },
    getCardsReport: { data: { cards = [] } = {} },
    getSpendersReport: { data: { spenders = [] } = {} },
    getCashFlowReport: {
      data: cashFlow = {
        summary: {
          credit: { amount: 0, previousPeriod: 0 },
          debit: { amount: 0, previousPeriod: 0 },
        },
        operations: {
          credit: [],
          debit: [],
        },
        operationCounts: {},
      },
    },
  } = useSelector(({ reports }) => reports);

  const {
    user: { data: { user: { firstName, lastName } = {} } = {} },
  } = useSelector(({ auth }) => auth);

  const defaultCurrency = 'NGN';

  const { selectedDateRange } = useSelector(({ overview }) => overview);
  const [chartData, setChartData] = useState({
    chart: [],
    totalSpent: 0,
    totalPercentage: 0,
  });
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  const handleRange = (val) => {
    switch (val) {
      case 1:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        return;
      case 2:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        return;
      case 3:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        return;
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
    };
  }, []);

  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
    const { startDate, endDate } = selectedDateRange?.range[0];
    setDateRange({
      from: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
      to: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
    });
  }, [selectedDateRange]);

  useEffect(() => {
    if (company.code && dateRange?.from !== null) {
      const { from, to } = dateRange;
      dispatch(
        getCompanyStats({
          id: company.code,
          params: { from, to },
        }),
      );
      fetchDataReport();
    }
  }, [company.code, dateRange]);

  const createDatePayload = (date) => {
    let { from, to, startDate, endDate } = date
      ? getDateRange(date).range[0]
      : getFromAndTo(selectedDateRange?.range);
    return {
      params: {
        from: startDate || from,
        to: endDate || to,
        period: date || selectedDateRange.val,
      },
    };
  };

  const fetchDataReport = () => {
    const payload = createDatePayload();
    dispatch(getCardsReport(payload));
    dispatch(getCategoriesReport(payload));
    dispatch(getVendorsReport(payload));
    dispatch(getTeamsReport(payload));
    dispatch(getSpendersReport(payload));
    dispatch(getCashFlowReport(payload));
    dispatch(getBudgetsReport(payload));
  };

  const onDateRangeChange = ({ from, to }) => {
    setDateRange({ from, to });
  };

  useEffect(() => {
    fetchDataReport();
  }, []);

  useEffect(() => {
    if (!chartView) {
      return;
    }
    const payload = createDatePayload();
    if (chartView?.groupByBudgets !== oldChartView?.chartView.groupByBudgets) {
      return dispatch(
        getBudgetsReport({
          ...payload,
          params: { ...payload.params, groupBy: chartView?.groupByBudgets?.value },
        }),
      );
    }
    if (chartView?.groupBySpenders !== oldChartView?.chartView.groupBySpenders) {
      return dispatch(
        getSpendersReport({
          ...payload,
          params: { ...payload.params, groupBy: chartView?.groupBySpenders?.value },
        }),
      );
    }
    if (chartView?.groupByVendors !== oldChartView?.chartView.groupByVendors) {
      return dispatch(
        getVendorsReport({
          ...payload,
          params: { ...payload.params, groupBy: chartView?.groupByVendors?.value },
        }),
      );
    }
    if (chartView?.groupByCategories !== oldChartView?.chartView.groupByCategories) {
      return dispatch(
        getCategoriesReport({
          ...payload,
          params: { ...payload.params, groupBy: chartView?.groupByCategories?.value },
        }),
      );
    }
    if (chartView?.groupByTeams !== oldChartView?.chartView.groupByTeams) {
      return dispatch(
        getTeamsReport({
          ...payload,
          params: { ...payload.params, groupBy: chartView?.groupByTeams?.value },
        }),
      );
    }
    if (chartView?.groupByCards !== oldChartView?.chartView.groupByCards) {
      return dispatch(
        getCardsReport({
          ...payload,
          params: { ...payload.params, groupBy: chartView?.groupByCards?.value },
        }),
      );
    }
  }, [chartView]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (data) {
      const { disbursed, available, topSpenders, chart, transactions, totalSpent } = data;
      setChartData({
        chart: buildChartDataFromTransaction(chart[defaultCurrency] || []),
        totalSpent: (totalSpent && totalSpent[defaultCurrency]) || [],
        totalPercentage: 0,
      });
    }
  }, [data, defaultCurrency]);

  const loadingState =
    loading ||
    loadingPaymentLink ||
    verifyingPayment ||
    fetchingReauthenticationCode ||
    isLoading;

  const getTotal = (data) => {
    return data.reduce((total, { amount }) => {
      return total + Number(amount);
    }, 0);
  };

  const getPnL = (revenue, expenses) => {
    return Number(revenue || 0) - Number(expenses || 0);
  };

  const pnl = getPnL(cashFlow?.summary?.reveunue, data?.totalSpent[defaultCurrency]);
  const visibleSegments = useSegmentVisibility(4);

  return (
    <div className="page-wrapper ">
      <div className="w-100">
        {visibleSegments >= 1 && (
          <section className={loadingState && !hasLoaded ? 'fade-in' : ''}>
            <SubHeader
              loading={loadingState && !hasLoaded}
              onDateRangeChange={onDateRangeChange}
              style={{ paddingBottom: 'Opx !important' }}
            />
          </section>
        )}

        <section className="overview-holder" style={{ height: `calc(100vh - 16rem)` }}>
          {visibleSegments >= 2 && (
            <div
              className={
                loadingState && !hasLoaded
                  ? 'd-flex w-100 flex-wrap fade-in'
                  : 'd-flex w-100 flex-wrap'
              }
            >
              <div className="metric-card">
                <MetricCard
                  loading={loadingState && !hasLoaded}
                  label="Total transactions"
                  value={cashFlow?.operationCounts?.transactions?.selectedPeriod?.total}
                />
              </div>
              <div className="metric-card">
                <MetricCard
                  loading={loadingState && !hasLoaded}
                  label="Reimbursement requests"
                  value={
                    cashFlow?.operationCounts?.reimbursements?.selectedPeriod?.received
                  }
                />
              </div>
              <div className="metric-card">
                <MetricCard
                  loading={loadingState && !hasLoaded}
                  label="Transactions requests"
                  value={cashFlow?.operationCounts?.requests?.selectedPeriod?.received}
                />
              </div>
              <div className="metric-card">
                <MetricCard
                  loading={loadingState && !hasLoaded}
                  label="Invoices count"
                  value={cashFlow?.operationCounts?.invoices?.selectedPeriod?.sent}
                />
              </div>
            </div>
          )}

          <Row>
            <div className="budget-analytics">
              {visibleSegments >= 3 && (
                <section
                  className={classNames(
                    'py-3 mb-2 d-flex flex-md-nowrap flex-wrap',
                    loadingState && !hasLoaded ? 'fade-in' : '',
                  )}
                >
                  <Col className="col-md-2 col-sm-12" style={{ paddingRight: 15 }}>
                    <div className="pb-2">
                      <RevenueCard
                        loading={loadingState && !hasLoaded}
                        label="Revenue"
                        variable
                        variation={36}
                        value={
                          <CurrencyFormat
                            prefix={getCurrency('NGN')}
                            value={numFormatter(
                              (cashFlow?.summary?.reveunue || 0) / 100,
                              true,
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                            style={{ fontSize: 24 }}
                          />
                        }
                      />
                    </div>

                    <div className="pb-2">
                      <RevenueCard
                        loading={loadingState && !hasLoaded}
                        label="Profit&Loss"
                        variable
                        value={
                          <CurrencyFormat
                            prefix={getCurrency('NGN')}
                            value={numFormatter(Math.abs(pnl) / 100, true)}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                            style={{ fontSize: 24 }}
                          />
                        }
                        variation={pnl / 100}
                      />
                    </div>

                    <div className="pb-2">
                      <RevenueCard
                        loading={loadingState && !hasLoaded}
                        label="Opening balance"
                        variation={-6}
                        value={
                          <CurrencyFormat
                            prefix={getCurrency('NGN')}
                            value={numFormatter(
                              (cashFlow?.summary?.openingBalance || 0) / 100,
                              true,
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                            style={{ fontSize: 24 }}
                          />
                        }
                      />
                    </div>

                    <div>
                      <RevenueCard
                        label="Closing balance"
                        loading={loadingState && !hasLoaded}
                        variation={36}
                        value={
                          <CurrencyFormat
                            prefix={getCurrency('NGN')}
                            value={numFormatter(
                              (cashFlow?.summary?.closingBalance || 0) / 100,
                              true,
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                            style={{ fontSize: 24 }}
                          />
                        }
                      />
                    </div>
                  </Col>

                  <Col className="col-md-10 col-sm-12">
                    <OverviewCard
                      title={
                        <>
                          <div className="d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <div style={{ display: 'inline-grid', display: 'grid' }}>
                                <span className="text">Expenses trend</span>
                                <span className="small-text">
                                  All expenses from your cash accounts, budgets,
                                  reimbursements, etc...
                                </span>
                              </div>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                              {loadingState && !hasLoaded ? (
                                Array.from({ length: 2 }).map((_, i) => (
                                  <Skeleton.Button
                                    key={i}
                                    active
                                    shape="square"
                                    style={{
                                      borderRadius: 8,
                                      height: '32px',
                                      width: '4rem',
                                    }}
                                  />
                                ))
                              ) : (
                                <>
                                  <CustomPills
                                    setStatus={(value) =>
                                      setChartView({
                                        ...chartView,
                                        expensesComparison: value,
                                      })
                                    }
                                    status={chartView.expensesComparison}
                                    labels={[
                                      {
                                        label: 'MTM',
                                        value: 'mtm',
                                        tooltip: 'Month to month',
                                      },
                                      {
                                        label: 'YTY',
                                        value: 'yty',
                                        tooltip: 'Year to year',
                                      },
                                    ]}
                                  />

                                  <CustomPills
                                    setStatus={(value) =>
                                      setChartView({ ...chartView, expensesView: value })
                                    }
                                    status={chartView.expensesView}
                                    labels={[
                                      {
                                        label: <BarChartOutlined />,
                                        value: 'bar',
                                        tooltip: 'Bars chart only',
                                      },
                                      {
                                        label: <TableOutlined />,
                                        value: 'table',
                                        tooltip: 'Table list only',
                                      },
                                    ]}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      }
                      isEmpty={!chartData?.chart.length && !loadingState}
                      placeHolderText={'No expense made within the period'}
                      customClass="barred"
                    >
                      {loadingState && !hasLoaded ? (
                        <Skeleton.Node
                          active
                          style={{ borderRadius: 12, width: '100%', height: '350px' }}
                        >
                          <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                        </Skeleton.Node>
                      ) : (
                        <AreaChartView
                          totalSpent={data?.totalSpent[defaultCurrency]}
                          currency={defaultCurrency}
                          totalPercentage={100}
                          data={buildChartDataFromTransaction(chartData?.chart)}
                          extra={{ period, previousPeriod }}
                        />
                      )}
                    </OverviewCard>
                  </Col>
                </section>
              )}

              {visibleSegments >= 4 && (
                <div
                  className={classNames(
                    'grid-col-2 gap-4 pt-0',
                    loadingState && !hasLoaded ? 'fade-in' : '',
                  )}
                >
                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ display: 'inline-grid', display: 'grid' }}>
                            <span className="text">Payouts expenses</span>
                            <span className="small-text">
                              All expenses from your cash accounts
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!cashFlow?.operations?.debit.length && !loadingState}
                    placeHolderText={'No pay out within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <AreaChartView
                        totalSpent={cashFlow.summary?.debit?.amount}
                        currency={defaultCurrency}
                        totalPercentage={100}
                        identifier="payouts"
                        data={buildChartDataFromTransaction(cashFlow?.operations?.debit)}
                      />
                    )}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <div style={{ display: 'inline-grid', display: 'grid' }}>
                            <span className="text">Payins reports</span>
                            <span className="small-text">
                              All credits or payments to your cash accounts
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!cashFlow?.operations?.credit.length && !loadingState}
                    placeHolderText={'No pay in within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <AreaChartView
                        totalSpent={cashFlow.summary?.credit?.amount}
                        currency={defaultCurrency}
                        totalPercentage={100}
                        color={'#12B76A'}
                        identifier="payins"
                        data={buildChartDataFromTransaction(cashFlow?.operations?.credit)}
                      />
                    )}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <div style={{ display: 'inline-grid', display: 'grid' }}>
                              <span className="text">Budgets Reports</span>
                              <span className="small-text">
                                All expenses from your budgets
                              </span>
                            </div>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            {loadingState && !hasLoaded ? (
                              Array.from({ length: 2 }).map((_, i) => (
                                <Skeleton.Button
                                  key={i}
                                  active
                                  shape="square"
                                  style={{
                                    borderRadius: 8,
                                    height: '32px',
                                    width: '4rem',
                                  }}
                                />
                              ))
                            ) : (
                              <>
                                {/* <CustomPills
                                  setStatus={(value) =>
                                    setChartView({
                                      ...chartView,
                                      budgetsComparison: value,
                                    })
                                  }
                                  status={chartView.budgetsComparison}
                                  labels={[
                                    {
                                      label: 'MTM',
                                      value: 'mtm',
                                      tooltip: 'Month to month',
                                    },
                                    {
                                      label: 'YTY',
                                      value: 'yty',
                                      tooltip: 'Year to year',
                                    },
                                  ]}
                                /> */}
                                <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, budgetsView: value })
                                  }
                                  status={chartView.budgetsView}
                                  labels={[
                                    {
                                      label: <BarChartOutlined />,
                                      value: 'bar',
                                      tooltip: 'Bars chart only',
                                    },
                                    {
                                      label: <TableOutlined />,
                                      value: 'table',
                                      tooltip: 'Table list only',
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!budgets.length && !loadingState}
                    placeHolderText={'No budgeted transactions within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <>
                        {chartView.budgetsView !== 'table' ? (
                          <BarChartMultiple
                            totalSpent={getTotal(budgets)}
                            currency={defaultCurrency}
                            totalPercentage={100}
                            data={budgets}
                            showTotalSpent={true}
                            extra={{ period, previousPeriod }}
                            groupBy={chartView?.groupByBudgets?.value}
                            compare={chartView.budgetsComparison}
                          />
                        ) : null}
                        {chartView.budgetsView !== 'bar' ? (
                          <ReportTable data={budgets} totalReport={getTotal(budgets)} />
                        ) : null}
                      </>
                    )}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <div style={{ display: 'inline-grid', display: 'grid' }}>
                              <span className="text">Spenders expenses</span>
                              <span className="small-text">
                                All expenses by users/employees
                              </span>
                            </div>
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            {/*                         <div style={{ width: 150, height: 32 }}>
                          <CustomSelect
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable
                            customStyles={customSelectStyle}
                            name="groupBy"
                            placeholder="Group By"
                            noOptionsMessage={() => null}
                            options={[
                              { label: 'By Categories', value: 'category' },
                              { label: 'By Vendors', value: 'vendor' },
                              { label: 'By Budgets', value: 'budget' },
                            ]}
                            value={chartView.groupBySpenders}
                            onChange={(value) =>
                              handleGroupBySelect({
                                ...chartView,
                                groupBySpenders: value,
                              })
                            }
                          />
                        </div> */}

                            {loadingState && !hasLoaded ? (
                              Array.from({ length: 2 }).map((_, i) => (
                                <Skeleton.Button
                                  key={i}
                                  active
                                  shape="square"
                                  style={{
                                    borderRadius: 8,
                                    height: '32px',
                                    width: '4rem',
                                  }}
                                />
                              ))
                            ) : (
                              <>
                                {/* <CustomPills
                                  setStatus={(value) =>
                                    setChartView({
                                      ...chartView,
                                      spendersComparison: value,
                                    })
                                  }
                                  status={chartView.spendersComparison}
                                  labels={[
                                    {
                                      label: 'MTM',
                                      value: 'mtm',
                                      tooltip: 'Month to month',
                                    },
                                    {
                                      label: 'YTY',
                                      value: 'yty',
                                      tooltip: 'Year to year',
                                    },
                                  ]}
                                /> */}
                                <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, spendersView: value })
                                  }
                                  status={chartView.spendersView}
                                  labels={[
                                    {
                                      label: <BarChartOutlined />,
                                      value: 'bar',
                                      tooltip: 'Bars chart only',
                                    },
                                    {
                                      label: <TableOutlined />,
                                      value: 'table',
                                      tooltip: 'Table list only',
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!spenders.length && !loadingState}
                    placeHolderText={'No transactions within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <div>
                        {chartView.spendersView !== 'table' ? (
                          <BarChartMultiple
                            totalSpent={getTotal(spenders)}
                            currency={defaultCurrency}
                            totalPercentage={100}
                            data={spenders}
                            showTotalSpent={true}
                            extra={{ period, previousPeriod }}
                            groupBy={chartView?.groupBySpenders?.value}
                            compare={chartView.spendersComparison}
                          />
                        ) : null}
                      </div>
                    )}
                    {chartView.spendersView !== 'bar' ? (
                      <ReportTable
                        data={spenders}
                        totalReport={getTotal(spenders)}
                      ></ReportTable>
                    ) : null}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <div style={{ display: 'inline-grid', display: 'grid' }}>
                              <span className="text">Teams expenses</span>
                              <span className="small-text">All expenses by teams</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {/* <div style={{ width: 150, height: 32 }}>
                          <CustomSelect
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable
                            customStyles={customSelectStyle}
                            name="groupBy"
                            placeholder="Group By"
                            noOptionsMessage={() => null}
                            options={[
                              { label: 'By Categories', value: 'category' },
                              { label: 'By Vendors', value: 'vendor' },
                              { label: 'By Budgets', value: 'budget' },
                            ]}
                            value={chartView.groupByTeams}
                            onChange={(value) =>
                              handleGroupBySelect({ ...chartView, groupByTeams: value })
                            }
                          />
                        </div> */}
                            {loadingState && !hasLoaded ? (
                              Array.from({ length: 2 }).map((_, i) => (
                                <Skeleton.Button
                                  key={i}
                                  active
                                  shape="square"
                                  style={{
                                    borderRadius: 8,
                                    height: '32px',
                                    width: '4rem',
                                  }}
                                />
                              ))
                            ) : (
                              <>
                                {/* <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, teamsComparison: value })
                                  }
                                  status={chartView.teamsComparison}
                                  labels={[
                                    {
                                      label: 'MTM',
                                      value: 'mtm',
                                      tooltip: 'Month to month',
                                    },
                                    {
                                      label: 'YTY',
                                      value: 'yty',
                                      tooltip: 'Year to year',
                                    },
                                  ]}
                                /> */}
                                <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, teamsView: value })
                                  }
                                  status={chartView.teamsView}
                                  labels={[
                                    {
                                      label: <BarChartOutlined />,
                                      value: 'bar',
                                      tooltip: 'Bars chart only',
                                    },
                                    {
                                      label: <TableOutlined />,
                                      value: 'table',
                                      tooltip: 'Table list only',
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!teams.length && !loadingState}
                    placeHolderText={'No team payments within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <>
                        {chartView.teamsView !== 'table' ? (
                          <BarChartMultiple
                            totalSpent={getTotal(teams)}
                            currency={defaultCurrency}
                            totalPercentage={100}
                            data={teams}
                            showTotalSpent={true}
                            extra={{ period, previousPeriod }}
                            groupBy={chartView?.groupByTeams?.value}
                            compare={chartView.teamsComparison}
                          />
                        ) : null}
                        {chartView.teamsView !== 'bar' ? (
                          <ReportTable
                            data={teams}
                            totalReport={getTotal(teams)}
                          ></ReportTable>
                        ) : null}
                      </>
                    )}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <div style={{ display: 'inline-grid', display: 'grid' }}>
                              <span className="text">Expenses heads</span>
                              <span className="small-text">
                                All expenses by categories
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {/* <div style={{ width: 150, height: 32 }}>
                          <CustomSelect
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable
                            customStyles={customSelectStyle}
                            name="groupBy"
                            placeholder="Group By"
                            noOptionsMessage={() => null}
                            options={[
                              { label: 'By Vendors', value: 'vendor' },
                              { label: 'By Budgets', value: 'budget' },
                            ]}
                            value={chartView.groupByCategories}
                            onChange={(value) =>
                              handleGroupBySelect({
                                ...chartView,
                                groupByCategories: value,
                              })
                            }
                          />
                        </div> */}
                            {loadingState && !hasLoaded ? (
                              Array.from({ length: 2 }).map((_, i) => (
                                <Skeleton.Button
                                  key={i}
                                  active
                                  shape="square"
                                  style={{
                                    borderRadius: 8,
                                    height: '32px',
                                    width: '4rem',
                                  }}
                                />
                              ))
                            ) : (
                              <>
                                {/* <CustomPills
                                  setStatus={(value) =>
                                    setChartView({
                                      ...chartView,
                                      categoriesComparison: value,
                                    })
                                  }
                                  status={chartView.categoriesComparison}
                                  labels={[
                                    {
                                      label: 'MTM',
                                      value: 'mtm',
                                      tooltip: 'Month to month',
                                    },
                                    {
                                      label: 'YTY',
                                      value: 'yty',
                                      tooltip: 'Year to year',
                                    },
                                  ]}
                                /> */}
                                <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, categoriesView: value })
                                  }
                                  status={chartView.categoriesView}
                                  labels={[
                                    {
                                      label: <BarChartOutlined />,
                                      value: 'bar',
                                      tooltip: 'Bars chart only',
                                    },
                                    {
                                      label: <TableOutlined />,
                                      value: 'table',
                                      tooltip: 'Table list only',
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!categories.length && !loadingState}
                    placeHolderText={'No cateorized transactions within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <>
                        {chartView.categoriesView !== 'table' ? (
                          <BarChartMultiple
                            totalSpent={getTotal(categories)}
                            currency={defaultCurrency}
                            totalPercentage={100}
                            data={categories}
                            showTotalSpent={true}
                            extra={{ period, previousPeriod }}
                            groupBy={chartView?.groupByCategories?.value}
                            compare={chartView.categoriesComparison}
                          />
                        ) : null}
                        {chartView.categoriesView !== 'bar' ? (
                          <ReportTable
                            data={categories}
                            totalReport={getTotal(categories)}
                          ></ReportTable>
                        ) : null}
                      </>
                    )}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <div style={{ display: 'inline-grid', display: 'grid' }}>
                              <span className="text">Vendors payments</span>
                              <span className="small-text">
                                All expenses from your cash accounts
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {/* <div style={{ width: 150, height: 32 }}>
                          <CustomSelect
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable
                            customStyles={customSelectStyle}
                            name="groupBy"
                            placeholder="Group By"
                            noOptionsMessage={() => null}
                            options={[
                              { label: 'By Categories', value: 'category' },
                              { label: 'By Budgets', value: 'budget' },
                            ]}
                            value={chartView.groupByVendors}
                            onChange={(value) =>
                              handleGroupBySelect({ ...chartView, groupByVendors: value })
                            }
                          />
                        </div> */}
                            {loadingState && !hasLoaded ? (
                              Array.from({ length: 2 }).map((_, i) => (
                                <Skeleton.Button
                                  key={i}
                                  active
                                  shape="square"
                                  style={{
                                    borderRadius: 8,
                                    height: '32px',
                                    width: '4rem',
                                  }}
                                />
                              ))
                            ) : (
                              <>
                                {/* <CustomPills
                                  setStatus={(value) =>
                                    setChartView({
                                      ...chartView,
                                      vendorsComparison: value,
                                    })
                                  }
                                  status={chartView.vendorsComparison}
                                  labels={[
                                    {
                                      label: 'MTM',
                                      value: 'mtm',
                                      tooltip: 'Month to month',
                                    },
                                    {
                                      label: 'YTY',
                                      value: 'yty',
                                      tooltip: 'Year to year',
                                    },
                                  ]}
                                /> */}
                                <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, vendorsView: value })
                                  }
                                  status={chartView.vendorsView}
                                  labels={[
                                    {
                                      label: <BarChartOutlined />,
                                      value: 'bar',
                                      tooltip: 'Bars chart only',
                                    },
                                    {
                                      label: <TableOutlined />,
                                      value: 'table',
                                      tooltip: 'Table list only',
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!vendors.length && !loadingState}
                    placeHolderText={'No vendors payments within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <>
                        {chartView.vendorsView !== 'table' ? (
                          <BarChartMultiple
                            totalSpent={getTotal(vendors)}
                            currency={defaultCurrency}
                            totalPercentage={100}
                            data={vendors}
                            showTotalSpent={true}
                            extra={{ period, previousPeriod }}
                            groupBy={chartView?.groupByVendors?.value}
                            compare={chartView.vendorsComparison}
                          />
                        ) : null}
                        {chartView.vendorsView !== 'bar' ? (
                          <ReportTable
                            data={vendors}
                            totalReport={getTotal(vendors)}
                          ></ReportTable>
                        ) : null}
                      </>
                    )}
                  </OverviewCard>

                  <OverviewCard
                    title={
                      <>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <div style={{ display: 'inline-grid', display: 'grid' }}>
                              <span className="text">Cards expenses</span>
                              <span className="small-text">
                                All of your business cards expenses
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            {/* <div style={{ width: 150, height: 32 }}>
                          <CustomSelect
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable
                            customStyles={customSelectStyle}
                            name="groupBy"
                            placeholder="Group By"
                            noOptionsMessage={() => null}
                            options={[
                              { label: 'By Categories', value: 'category' },
                              { label: 'By Vendors', value: 'vendor' },
                              { label: 'By Budgets', value: 'budget' },
                            ]}
                            value={chartView.groupByCards}
                            onChange={(value) =>
                              handleGroupBySelect({ ...chartView, groupByCards: value })
                            }
                          />
                        </div> */}
                            {loadingState && !hasLoaded ? (
                              Array.from({ length: 2 }).map((_, i) => (
                                <Skeleton.Button
                                  key={i}
                                  active
                                  shape="square"
                                  style={{
                                    borderRadius: 8,
                                    height: '32px',
                                    width: '4rem',
                                  }}
                                />
                              ))
                            ) : (
                              <>
                                {/* <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, cardsComparison: value })
                                  }
                                  status={chartView.cardsComparison}
                                  labels={[
                                    {
                                      label: 'MTM',
                                      value: 'mtm',
                                      tooltip: 'Month to month',
                                    },
                                    {
                                      label: 'YTY',
                                      value: 'yty',
                                      tooltip: 'Year to year',
                                    },
                                  ]}
                                /> */}
                                <CustomPills
                                  setStatus={(value) =>
                                    setChartView({ ...chartView, cardsView: value })
                                  }
                                  status={chartView.cardsView}
                                  labels={[
                                    {
                                      label: <BarChartOutlined />,
                                      value: 'bar',
                                      tooltip: 'Bars chart only',
                                    },
                                    {
                                      label: <TableOutlined />,
                                      value: 'table',
                                      tooltip: 'Table list only',
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    }
                    isEmpty={!cards.length && !loadingState}
                    placeHolderText={'No cards transactions within the period'}
                  >
                    {loadingState && !hasLoaded ? (
                      <Skeleton.Node
                        active
                        style={{ borderRadius: 12, width: '100%', height: '350px' }}
                      >
                        <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                      </Skeleton.Node>
                    ) : (
                      <>
                        {chartView.cardsView !== 'table' ? (
                          <BarChartMultiple
                            totalSpent={getTotal(cards)}
                            currency={defaultCurrency}
                            totalPercentage={100}
                            data={cards}
                            showTotalSpent={true}
                            extra={{ period, previousPeriod }}
                            groupBy={chartView?.groupByCards?.value}
                            compare={chartView.cardsComparison}
                          />
                        ) : null}
                        {chartView.cardsView !== 'bar' ? (
                          <ReportTable
                            data={cards}
                            totalReport={getTotal(cards)}
                          ></ReportTable>
                        ) : null}
                      </>
                    )}
                  </OverviewCard>
                </div>
              )}
            </div>
          </Row>
        </section>
      </div>
    </div>
  );
};
export default ReportOverview;
