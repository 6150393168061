import { ReactComponent as Trash } from 'assets/icons/trashcan.svg';
import AssetViewer from 'components/AssetViewer';
import CustomDrawer from 'components/UI/CustomDrawer';
import DrawerHeader from 'components/UI/CustomDrawer/components/DrawerHeader';
import Loading from 'components/UI/Loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  deleteReimbursements,
  getReimbursements,
  getSingleReimbursement,
} from 'redux/actions/ReimbursementsAction';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import DeclineReimbursement from './DeclineReimbursement';
import ReimbursementDetails from './ReimbursementDetails';
import DeleteDialog from 'components/DeleteDialog';
import { XcloseIcon } from 'assets/icons';

const ReimbursementModal = ({ selectReimbursement, setSelectReimbursement }) => {
  const [step, setStep] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { reimbursementCode } = useParams();
  const history = useHistory();

  const [assetViewer, setAssetViewer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [hideHeader, setHideHeader] = useState(false);
  const [canMakePayment, setCanMakePayment] = useState(false);

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);
  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const {
    reimbursementsData: {
      code,
      status,
      approvalRequest = {},
      user: reimbursementUser,
    } = {},
  } = selectReimbursement || {};

  const createByMe = user?.code === reimbursementUser?.code;

  const {
    approveReimbursement: {
      loading: loadingApprove,
      success: successApprove,
      error: errorApprove,
    },
    declineReimbursement: {
      loading: loadingDecline,
      success: successDecline,
      error: errorDecline,
    },
    deleteReimbursement: { loading: loadingDelete, success: successDelete },
    getSingleReimbursement: {
      data: singleData = {},
      loading: singleLoading,
      success: successData,
    },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    reviewRequest: { loading: loadingApproval, success: successApproval },
  } = useSelector(({ approval }) => approval);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const { approvals: completedApprovals = [] } = approvalRequest || {};

  useEffect(() => {
    if (!singleLoading && selectReimbursement?.reimbursementsData?.code) {
      dispatch(getSingleReimbursement(selectReimbursement?.reimbursementsData?.code));
    }
  }, [selectReimbursement]);

  useEffect(() => {
    if (successData && ['approved'].includes(singleData?.data?.status))
      setCanMakePayment(true);
  }, [successData, singleData]);

  const toggleHandler = () => {
    setStep(step - 1);
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const setReceiptList = (list) => {
    setReceipts(list);
  };

  const handleClose = () => {
    setSelectedAsset(null);
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'reimbursementReceipts' });
    if (reimbursementCode) history.push('/requests/reimbursements');
    if (isLoaded) {
      dispatch(getReimbursements());
    }
    if (step === 2 && !isLoaded) {
      toggleHandler();
    } else {
      setSelectReimbursement(null);
      setAssetViewer(false);
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'getSingleReimbursement' });
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'approveReimbursement' });
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'declineReimbursement' });
    }
    toggleAssetViewer(false);
    setIsLoaded(false);
  };

  const paymentSuccess = !loadingPayNow && successPayNow;

  useEffect(() => {
    if (paymentSuccess) {
      setIsLoaded(true);
      handleClose();
      dispatch(getReimbursements());
    }
  }, [successPayNow, loadingPayNow]);

  useEffect(() => {
    if (successDelete) {
      handleClose();
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'deleteReimbursement' });
      dispatch(getReimbursements());
    }
  }, [successDelete]);

  const approveSuccess = !loadingApprove && successApprove;
  const declineSuccess = !loadingDecline && successDecline;

  const approveError = !loadingApprove && !!errorApprove;
  const declineError = !loadingDecline && errorDecline;

  useEffect(() => {
    if (approveSuccess || declineSuccess || successApproval) {
      setIsLoaded(true);
      dispatch(getSingleReimbursement(selectReimbursement?.reimbursementsData?.code));
    }
    if (approveError || declineError) {
      setIsLoaded(false);
      setSelectReimbursement(selectReimbursement);
    }
  }, [
    loadingApprove,
    successApprove,
    errorApprove,
    loadingDecline,
    successDecline,
    errorDecline,
    successApproval,
  ]);

  const onHandleHeader = (value) => {
    setHideHeader(value);
  };

  if (!selectReimbursement) return <div />;
  const renderCard = () => {
    switch (step) {
      case 1:
        return (
          <ReimbursementDetails
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            setAssetViewer={handleAssetViewer}
            selectReimbursement={selectReimbursement}
            handleClose={handleClose}
            singleData={singleData?.data}
            getReceiptList={setReceiptList}
            hideHeader={() => setHideHeader(true)}
            canMakePayment={canMakePayment}
            setCanMakePayment={setCanMakePayment}
          />
        );
      case 2:
        return (
          <DeclineReimbursement
            setStep={setStep}
            setIsLoading={setIsLoaded}
            isLoaded={isLoaded}
            selectReimbursement={selectReimbursement}
            setSelectReimbursement={setSelectReimbursement}
            hideHeader={onHandleHeader}
          />
        );
      default:
        return;
    }
  };

  const attemptDelete = () => {
    setDeleting(!deleting);
  };

  const handleDelete = () => {
    dispatch(deleteReimbursements(code));
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          onClick={() => {
            handleTogglePopover();
            attemptDelete();
          }}
          className="actionLink"
        >
          <Trash className="mr-4" /> <span>Delete request</span>
        </div>
      </div>
    );
  };

  const recipientName = selectReimbursement?.vendor;

  const recipientCode = selectReimbursement?.reimbursementsData?.vendor?.code;

  // This is for the asset viewer to handle multple asset download
  const assetSingleData = {
    ...selectReimbursement?.reimbursementsData,
    type: 'Reimbursement',
  };

  return (
    <>
      <CustomDrawer
        show={(selectReimbursement && selectReimbursement) || (step === 2 && handleClose)}
      >
        {assetViewer && (
          <AssetViewer
            toggleVisibility={toggleAssetViewer}
            data={receipts}
            selectedAsset={selectedAsset}
            singleData={assetSingleData}
          />
        )}
        <div className="content">
          {singleLoading && !canMakePayment ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <Loading color="#d2b28b" size={32} />
            </div>
          ) : (
            <>
              {step === 2 && isLoaded && (
                <div className="card-modal-header d-flex flex-column">
                  <div className="d-flex flex-column w-full">
                    <div className="cursor" onClick={handleClose}>
                      <XcloseIcon stroke="#57534e" />
                      <span className="ps-1 text-sm color-grey-600">Close</span>
                    </div>
                  </div>
                </div>
              )}

              {!hideHeader && (
                <DrawerHeader
                  handleClose={handleClose}
                  status={singleData?.data?.reimbursement?.status}
                  recipientLink={`/expenses/vendors/profile/${recipientCode}`}
                  receipient={recipientName}
                  title="Reimbursment details"
                  moneyData={{
                    currency: selectReimbursement?.reimbursementsData?.currency,
                    amount: selectReimbursement?.reimbursementsData?.amount,
                  }}
                  popoverData={
                    status !== 'deleted' &&
                    createByMe && {
                      action: <Actions />,
                      isPopoverOpen,
                      handleTogglePopover,
                    }
                  }
                />
              )}

              {renderCard()}
            </>
          )}
        </div>
      </CustomDrawer>
      {deleting && (
        <DeleteDialog
          title={`Delete reqeust`}
          subTitle="Are you sure you want to delete this request? This action cannot be undone"
          onCancel={() => setDeleting(false)}
          onDelete={handleDelete}
          styles={{ width: 400 }}
          isLoading={loadingDelete}
        />
      )}
    </>
  );
};

export default ReimbursementModal;
