import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';

import {
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATIONS_READ_REQUEST,
  MARK_NOTIFICATIONS_READ_SUCCESS,
  MARK_NOTIFICATIONS_READ_ERROR,
  MARK_ALL_NOTIFICATIONS_READ_REQUEST,
  MARK_ALL_NOTIFICATIONS_READ_ERROR,
  MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
  RESET_BLOCK_NOTIFICATION,
} from '../reducers/NotificationsReducer';

import { toastError } from 'components/UI/toast';

async function getNotification(params) {
  return await Axios.get(`/notifications`, { params });
}

function* handleGetNotification({ payload }) {
  try {
    const response = yield call(getNotification, payload);
    if (response) {
      yield put({ type: GET_NOTIFICATIONS_SUCCESS, data: response.data });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_NOTIFICATIONS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function markNotificationAsRead({ id }) {
  return await Axios.patch(`/notifications/${id}`);
}

function* handleMarkNotification(id) {
  try {
    const response = yield call(markNotificationAsRead, id);
    if (response) {
      yield put({
        type: MARK_NOTIFICATIONS_READ_SUCCESS,
        data: response.data,
      });

      yield delay(200);

      yield put({
        type: RESET_BLOCK_NOTIFICATION,
        blockType: 'markNotificationAsRead',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
      yield put({
        type: MARK_NOTIFICATIONS_READ_ERROR,
        error: getSimplifiedError(error),
      });
    }
  }
}

async function markAllNotificationAsRead() {
  return await Axios.patch(`/notifications`);
}

function* handleMarkAllNotification() {
  try {
    const response = yield call(markAllNotificationAsRead);
    if (response) {
      yield put({
        type: MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
      });

      yield delay(200);

      yield put({
        type: RESET_BLOCK_NOTIFICATION,
        blockType: 'markAllNotificationAsRead',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
      yield put({
        type: MARK_ALL_NOTIFICATIONS_READ_ERROR,
        error: getSimplifiedError(error),
      });
    }
  }
}

// async function approveNotification({ id }) {
//   return await Axios.post(`/reimbursements/${id}/approve`);
// }

// function* handleApproval(id) {
//   try {
//     const response = yield call(approveNotification, id);
//     if (response) {
//       yield put({});
//     }
//   } catch (error) {}
// }

export default all([
  takeLatest(GET_NOTIFICATIONS_REQUEST, handleGetNotification),
  takeLatest(MARK_NOTIFICATIONS_READ_REQUEST, handleMarkNotification),
  takeLatest(MARK_ALL_NOTIFICATIONS_READ_REQUEST, handleMarkAllNotification),
]);
