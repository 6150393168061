import React from 'react';
import { DateRangePicker } from 'react-date-range';

const DatePicker = ({ handleDateSelect, state, setState, numberOfMonths, ...rest }) => {
  return (
    <div>
      <DateRangePicker
        onChange={(item) => setState([item.selection])}
        months={numberOfMonths}
        ranges={state}
        direction="horizontal"
        rangeColors={['#d28b28']}
        {...rest}
      />
      <div className="text-center d-flex justify-content-end pe-5">
        <p className="save-btn" onClick={handleDateSelect}>
          Filter
        </p>
      </div>
    </div>
  );
};
export default DatePicker;
