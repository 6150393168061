import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import dayjs from 'dayjs';
import DeleteDialog from 'components/DeleteDialog';
import { subYears, format } from 'date-fns';

import { Modal } from 'react-bootstrap';

import { getCustomerTransaction } from 'redux/actions/TransactionsAction';
import { fetchAllCustomerAction, deleteCustomer } from 'redux/actions/InvoiceAction';

import { getColor, getFormattedDate } from 'utils/helper';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

import ProfileComponent from './Components/ProfileComponent';
import InvoicesTable from './Components/InvoicesTable';
import CustomerTransactionTable from './Components/CustomerTransactionTable';

const CustomerProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: customerId } = useParams();

  const {
    customer: { loading: customerLoading, data: customerData },
    deleteCustomer: { loading: deleteLoading, success: deleteSuccess },
  } = useSelector(({ invoices }) => invoices);

  const {
    getCustomerTransaction: {
      data: { meta = {}, transaction = [], chart = {} } = {},
      loading: transactionLoading,
    },
  } = useSelector(({ transaction }) => transaction);
  const { perPage } = meta;
  const pageLoading = customerLoading || transactionLoading;

  const [id, setId] = useState(null);
  const [basicInformation, setBasicInformation] = useState([]);
  const [userInformation, setUserInformation] = useState({});
  const [informationBasedOnUserType, setInformationBasedOnUserType] = useState({
    userType: 'Customer',
    tabList: [
      {
        name: 'Transaction',
        key: 'transactions',
      },
      {
        name: 'Invoices',
        key: 'invoices',
      },
    ],
    pageLoading,
  });

  const [chartData, setChartData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [from, setFrom] = useState(format(subYears(new Date(), 1), 'yyyy-MM-dd'));
  const [to, setTo] = useState(format(new Date(), 'yyyy-MM-dd'));

  useEffect(() => {
    if (!deleteSuccess) {
      fetchCustomerTransaction();
      dispatch(fetchAllCustomerAction({ code: customerId }));
    }
  }, []);

  const fetchCustomerTransaction = (fromDate = from, toDate = to, page) => {
    const params = `from=${fromDate}&to=${toDate}${
      page ? `&page=${page}&perPage=${perPage}` : ''
    }`;
    dispatch(getCustomerTransaction({ code: customerId, params }));
  };

  const handlePagination = (page) => {
    fetchCustomerTransaction(from, to, page);
  };

  useEffect(() => {
    const customer = customerData?.customer || {};

    const { street, country, city, state } = customer?.address ?? {};
    const filteredArray = [street, city, state, country].filter((item) => !!item);
    const address = !!filteredArray.length ? filteredArray.join(', ') : '-';

    if (customer) {
      setId(customer?.code);

      setUserInformation({
        name: customer?.name,
        type: 'Customer',
        subText: customer?.email,
        avatar: null,
        code: customer?.code,
        canSendFunds: customer?.status === 'active' ? true : false,
        canEdit: customer?.status !== 'deleted' ? true : false,
        canDelete: customer?.status !== 'deleted' ? true : false,
      });

      setBasicInformation([
        {
          label: 'Status',
          value: customer?.status,
          type: 'badge',
          color: getColor(customer?.status),
        },
        {
          label: 'Name',
          value: customer?.name,
          type: 'text',
        },
        { label: 'Email', value: customer?.email ?? '-', type: 'text' },
        {
          label: 'Phone number',
          value: customer?.phoneNumber?.internationalFormat
            ? `+${customer.phoneNumber.internationalFormat}`
            : customer?.phoneNumber?.localFormat
            ? customer.phoneNumber.localFormat
            : '-',
          type: 'text',
        },

        { label: 'Address', value: address, type: 'text' },

        {
          label: 'Added On',
          value: getFormattedDate(customer?.created_at),
          type: 'text',
        },
      ]);
    }
  }, [customerData]);

  useEffect(() => {
    const customer = customerData?.customer || {};

    if (id && customer?.name) {
      setInformationBasedOnUserType({
        userType: 'customer',
        tabList: [
          {
            name: 'Transactions',
            key: 'transactions',
            component: (
              <CustomerTransactionTable
                id={id}
                type={'payer'}
                userName={customer?.name}
                transactions={transaction}
                meta={meta}
                loading={pageLoading}
                handlePagination={handlePagination}
              />
            ),
          },
          {
            name: 'Invoices',
            key: 'invoices',
            component: <InvoicesTable id={id} type={'user'} userName={customer?.name} />,
          },
        ],
        pageLoading,
      });
    }
  }, [customerData?.customer?.name, id, pageLoading]);

  useEffect(() => {
    if (chart) {
      let data = Object.values(chart?.data ?? {})?.map((option) => {
        const year = option?.year?.toString() ?? '';

        return {
          name: `${option?.month} ${year.substring(year.length - 2, year.length)}`,
          spent: option?.amount / 100,
        };
      });

      setChartData({ totalSpent: chart?.total, data });
    }
  }, [chart?.data]);

  const handleDateRangeSelection = (fromDate, toDate) => {
    const formattedFrom = dayjs(fromDate).format('YYYY-MM-DD');
    const formattedTo = dayjs(toDate).format('YYYY-MM-DD');
    setFrom(formattedFrom);
    setTo(formattedTo);
    fetchCustomerTransaction(formattedFrom, formattedTo);
  };

  const { permissions } = allPermissions();

  const canDeleteCustomer = hasPermission({
    permissions,
    scopes: ['customer-*', 'customer-delete'],
  });

  const handleDelete = () => {
    if (!canDeleteCustomer) return;
    dispatch(deleteCustomer(customerData?.customer?.code));
  };

  const handleEdit = () => {};

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteModal(false);
      history.push('/receivables/customers');
    }
  }, [deleteSuccess]);

  const billCustomer = () => {
    const customer = customerData?.customer || {};

    const payload = {
      label: customer?.name,
      value: customer,
    };

    history.push({
      pathname: '/receivables/invoices/create',
      state: { customerPayload: payload },
    });
  };

  return (
    <ProfileComponent
      isCustomer
      billCustomer={billCustomer}
      basicInformation={basicInformation}
      informationBasedOnUserType={informationBasedOnUserType}
      chartData={chartData}
      handleDateRangeSelection={handleDateRangeSelection}
      userInformation={userInformation}
      onEdit={handleEdit}
      onDelete={() => {
        setDeleteModal(true);
      }}
      deleteText={'Archive'}
      sendText={'Bill'}
      loading={customerLoading}
      chartLoading={pageLoading}
    >
      <Modal show={deleteModal} centered dialogClassName="custom-dialog">
        <DeleteDialog
          title="Archive customer"
          subTitle="Are you sure you want archive this customer?"
          onCancel={() => {
            setDeleteModal(false);
          }}
          onDelete={handleDelete}
          deleteText="Archive"
          isLoading={deleteLoading}
        />
      </Modal>
    </ProfileComponent>
  );
};
export default CustomerProfile;
