import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch } from 'react-redux';
import DatePicker from './DatePicker';

const Range = ({
  handleOptionClick,
  handleToggleRangePopover,
  periodOptions = {
    today: true,
    sevenDays: true,
    last30Days: true,
    customRange: true,
    thisMonth: false,
    lastMonth: false,
    thisQuarter: false,
    lastQuarter: false,
    lastSemester: false,
    thisYear: false,
    lastYear: false,
  },
  numberOfMonths,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [isRangeCustomPopover, setIsRangeCustomPopover] = useState(false);

  const handleToggleRangeCustomPopover = () => {
    setIsRangeCustomPopover(!isRangeCustomPopover);
  };
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const handleDateSelect = () => {
    const rangeDate =
      dateState[0].startDate === dateState[dateState.length - 1].endDate
        ? dayjs(dateState[0].startDate).format('DD MMM, YYYY')
        : `${dayjs(dateState[0].startDate).format('DD MMM, YYYY')} - ${dayjs(
            dateState[dateState.length - 1].endDate,
          ).format('DD MMM, YYYY')}`;
    handleOptionClick(rangeDate, dateState)();
    handleToggleRangeCustomPopover();
  };
  return (
    <OutsideClickHandler onOutsideClick={handleToggleRangePopover}>
      <div className="actions-dialog">
        {isRangeCustomPopover ? (
          <div>
            <div className="d-flex align-items-center justify-content-between pt-2">
              <ArrowLeftOutlined
                className="px-3 cursor"
                onClick={handleToggleRangeCustomPopover}
              />
              <CloseOutlined
                className="px-3 cursor"
                onClick={handleToggleRangeCustomPopover}
              />
            </div>
            <DatePicker
              handleDateSelect={handleDateSelect}
              state={dateState}
              setState={setDateState}
              numberOfMonths={numberOfMonths}
              {...rest}
            />
          </div>
        ) : (
          <>
            {periodOptions.today && (
              <div className="actionLink" onClick={handleOptionClick('Today')}>
                Today
              </div>
            )}
            {periodOptions.sevenDays && (
              <div className="actionLink" onClick={handleOptionClick('Last 7 days')}>
                Last 7 days
              </div>
            )}
            {periodOptions.last30Days && (
              <div className="actionLink" onClick={handleOptionClick('Last 30 days')}>
                Last 30 days
              </div>
            )}
            {periodOptions.thisMonth && (
              <div className="actionLink" onClick={handleOptionClick('This Month')}>
                This Month
              </div>
            )}
            {periodOptions.lastMonth && (
              <div className="actionLink" onClick={handleOptionClick('Last Month')}>
                Last Month
              </div>
            )}
            {periodOptions.thisQuarter && (
              <div className="actionLink" onClick={handleOptionClick('This Quarter')}>
                This Quarter
              </div>
            )}
            {periodOptions.lastQuarter && (
              <div className="actionLink" onClick={handleOptionClick('Last Quarter')}>
                Last Quarter
              </div>
            )}
            {periodOptions.lastSemester && (
              <div className="actionLink" onClick={handleOptionClick('Last Semester')}>
                Last 2 Quarters
              </div>
            )}
            {periodOptions.thisYear && (
              <div className="actionLink" onClick={handleOptionClick('This Year')}>
                This Year
              </div>
            )}
            {periodOptions.lastYear && (
              <div className="actionLink" onClick={handleOptionClick('Last Year')}>
                Last 12 months
              </div>
            )}
            {periodOptions.customRange && (
              <div className="actionLink" onClick={handleToggleRangeCustomPopover}>
                Custom date range
              </div>
            )}
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Range;
