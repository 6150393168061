import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import Modal from 'components/UI/Modal';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import AllowedTo from 'utils/AllowedTo';
import { getCurrency, sortDays } from 'utils/helper';
import PolicyInfo from './PolicyInfo';

const PolicyDetailsModal = ({
  selectPolicy,
  setSelectPolicy,
  editPolicy,
  deletePolicy,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);
  const handleClose = () => {
    if (!isPopoverOpen) setSelectPolicy(null);
  };

  const Actions = () => {
    if (!isPopoverOpen) return <div />;
    return (
      <div className="actions-dialog">
        <AllowedTo scopes={['org-*', 'policy-edit', 'policy-*']}>
          <div
            onClick={() => {
              editPolicy(selectPolicy);
              handleTogglePopover();
            }}
            className="actionLink"
          >
            <EditOutlined />
            <span className="ps-1">Edit Policy</span>
          </div>
        </AllowedTo>
        <AllowedTo scopes={['org-*', 'policy-delete', 'policy-*']}>
          <div
            onClick={() => {
              deletePolicy(selectPolicy);
              handleTogglePopover();
            }}
            className="actionLink"
          >
            <DeleteOutlined />
            <span className="ps-1">Delete Policy</span>
          </div>{' '}
        </AllowedTo>
      </div>
    );
  };

  const isPolicy = selectPolicy?.policyData?.status != 'deleted';
  const policyType = selectPolicy?.types?.length
    ? selectPolicy?.nameAndType?.subValue
    : selectPolicy?.type?.name;

  if (!selectPolicy) return <div />;

  return (
    <Modal show={selectPolicy} onClose={handleClose}>
      <div className="content">
        <div className="card-modal-header">
          <div className="d-flex align-items-center cursor" onClick={handleClose}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
          {isPolicy && (
            <AllowedTo
              scopes={['policy-edit', 'policy-*', 'policy-create', 'policy-delete']}
            >
              <div>
                <CustomPopover
                  content={<Actions />}
                  showPopover={isPopoverOpen}
                  clickOutside={handleTogglePopover}
                >
                  <div onClick={handleTogglePopover} className="cursor">
                    <MoreOutlined />
                  </div>
                </CustomPopover>
              </div>
            </AllowedTo>
          )}
        </div>

        <div className="card-modal-body">
          <div className="information-wrapper">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h2 className="card-title w-100">Policy details</h2>
            </div>
            <PolicyInfo
              title="Status"
              value={<BadgeType value={selectPolicy.status} />}
            />
            <PolicyInfo title="Policy name" value={selectPolicy?.name} />
            <PolicyInfo
              title="Description"
              value={selectPolicy?.policyData?.description}
            />
            <PolicyInfo title="Type" value={policyType} />
            {selectPolicy?.maxAmount && (
              <PolicyInfo
                title="Amount"
                value={
                  <CurrencyFormat
                    prefix={getCurrency(selectPolicy?.currency)}
                    value={selectPolicy?.maxAmount / 100}
                    displayType="text"
                    thousandSeparator={true}
                  />
                }
              />
            )}
            {selectPolicy?.frequency && (
              <PolicyInfo
                title="Frequency"
                value={
                  <div className="d-flex flex-wrap ">
                    <BadgeType value={selectPolicy?.frequency} isBadge={false} margin />
                  </div>
                }
                // value={sortDays(selectPolicy.period).join(', ')}
              />
            )}
            {selectPolicy?.period?.length && (
              <PolicyInfo
                title="Period"
                value={
                  <div className="d-flex flex-wrap ">
                    {sortDays(selectPolicy?.period).map((item, index) => (
                      <BadgeType value={item} key={index} isBadge={false} margin />
                    ))}
                  </div>
                }
                // value={sortDays(selectPolicy.period).join(', ')}
              />
            )}

            <PolicyInfo
              title="Assigned budget(s)"
              value={
                <div className="d-flex flex-wrap">
                  {selectPolicy?.budget.length
                    ? selectPolicy?.budget.map((item, index) => (
                        <BadgeType
                          value={item?.name}
                          key={index}
                          isBadge={false}
                          margin
                        />
                      ))
                    : 'N/A'}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PolicyDetailsModal;
