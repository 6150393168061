import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import DeleteDialog from 'components/DeleteDialog';
import PolicyInfo from 'components/PolicyDetailModal/PolicyInfo';
import CustomButton from 'components/UI/CustomButton';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import CategorySelect from 'components/UI/CustomSelect/CategorySelect';
import MultipleSelect from 'components/UI/MultipleSelect';
import BadgeType from 'components/UI/Table/BadgeType';
import { useDebounce } from 'hooks/useDebounce';
import useExpenseCategories from 'hooks/useExpenseCategory';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { getMccs, getSingleCard, updateCardSettings } from 'redux/actions/CardAction';
import { getPolicies } from 'redux/actions/PoliciesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, sortDays } from 'utils/helper';
import { cardSettingData } from './RequestPhysicalCard';
import { useHistory } from 'react-router-dom';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';

const EditCardSettings = ({ closeHandler, cardDetails }) => {
  const { push } = useHistory();
  const {
    updateCardSettings: { loading, success },
    getMccs: { data: mccsData, loading: loadingMccs, success: successMccs },
  } = useSelector(({ card }) => card);
  const { getPolicies: { data: { policies = [] } = {} } = {} } = useSelector(
    ({ policies }) => policies,
  );

  const { permissions } = allPermissions();

  const canViewPolicy = hasPermission({
    permissions,
    scopes: ['policy-*', 'policy-view'],
  });

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const handleSelectChange = (val) => setValue(val);

  const isPhysicalCard = cardDetails.card.type === 'physical';

  const cardsettings = isPhysicalCard
    ? cardSettingData
    : cardSettingData.slice(2, 4).map((item) => ({
        ...item,
        isChecked: false,
      }));

  // cardSettingData
  const [cardSetting, setCardSetting] = useState(cardsettings);
  const dispatch = useDispatch();

  const [card, setCard] = useState({
    name: '',
    type: { value: '', label: '' },
    amount: '',
  });

  const handleChange = ({ name, value }) => {
    setCard({
      ...card,
      [name]: value,
    });
  };

  const onMenuClose = () => {
    if (debouncedValue) dispatch(getMccs());
  };

  const setIsChecked = (value, checked) => {
    const updatedData = cardSetting.map((item) => {
      if (item.value === value) {
        return { ...item, isChecked: checked };
      }
      return item;
    });

    setCardSetting(updatedData);
  };

  useEffect(() => {
    if (!mccsData?.mccs?.length) dispatch(getMccs());
    if (canViewPolicy) dispatch(getPolicies({ type: 'Spending limits' }));
  }, []);

  const generatedMccs = useMemo(() => {
    return mccsData?.mccs?.map(({ code: value, edited_description: label }) => ({
      label,
      value,
    }));
  }, [successMccs]);

  useEffect(() => {
    const mccsList = generatedMccs?.find((option) =>
      option.label.toLowerCase().includes(debouncedValue.toLowerCase()),
    );
    if (!mccsList && debouncedValue) {
      dispatch(getMccs({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  const cardDetailss = {
    onlineTransaction: cardDetails.card?.onlineTransaction,
    contactlessTransaction: cardDetails.card?.contactlessTransaction,
    atmWithdrawals: cardDetails.card?.atmWithdrawals,
    posTransaction: cardDetails.card?.posTransaction,
  };

  useEffect(() => {
    const updatedCardSetting = cardsettings.map((item) => {
      if (cardDetailss.hasOwnProperty(item.value)) {
        return {
          ...item,
          isChecked: cardDetailss[item.value],
        };
      }
      return item;
    });

    setCardSetting(updatedCardSetting);
    const cardMerchants = cardDetails?.card?.allowedMerchants?.map((item) => ({
      value: item.code,
      label: item.edited_description,
    }));

    setCard({
      vendors: cardMerchants,
      category: cardDetails?.card?.expenseCategory?.code
        ? {
            value: cardDetails?.card?.expenseCategory?.code,
            label: cardDetails?.card?.expenseCategory?.name,
          }
        : undefined,
      policy: cardDetails?.card?.spendingLimitPolicy?.code
        ? {
            value: cardDetails?.card?.spendingLimitPolicy?.description,
            label: cardDetails?.card?.spendingLimitPolicy?.name,
            code: cardDetails?.card?.spendingLimitPolicy?.code,
          }
        : undefined,
    });
  }, [cardDetails]);

  useEffect(() => {
    if (success) {
      dispatch(getSingleCard({ id: cardDetails?.card.code }));
      closeHandler();
    }
  }, [success]);

  const handleUpdate = () => {
    const marchants = card?.vendors?.map((item) => item.value);
    const settings = cardSetting.reduce(
      (settings, setting) => {
        settings[setting.value] = setting.isChecked;
        return settings;
      },
      {
        expenseCategory: card?.category?.value ? card?.category?.value : undefined,
        allowedMerchants: marchants,
        spendingLimitPolicy: card?.policy?.code ? card?.policy?.code : undefined,
      },
    );

    const payload = {
      code: cardDetails.card.code,
      settings,
    };
    dispatch(updateCardSettings(payload));
  };

  // filter selected type
  const selectedType = policies?.filter(
    (item) =>
      item.types.some((type) => type?.name.toLowerCase() === 'spending limits') &&
      item?.status === 'active',
  );

  const selectedPolicy = selectedType?.find((item) => item?.code === card?.policy?.code);

  const createPolicy = () => {
    push('/compliances/policies/create');
  };
  return (
    <div className="content px-md-0 py-md-0 px-3 py-4">
      <div className="card-modal-header">
        <div className="d-flex align-items-center cursor" onClick={closeHandler}>
          <ArrowLeftOutlined />
          <span className="ps-1">Back</span>
        </div>
      </div>

      <div className="card-modal-body">
        <div className="information-wrapper">
          {/* <h2 className="card-title w-100">Advanced card settings</h2> */}

          <div className="advance__setting--cards">
            <div className="advance__setting-heading">
              <h3 className="d-flex justify-content-between align-items-center">
                Advanced card settings
              </h3>
              <p>
                Selected fields will be applied to all transactions made on this card.
              </p>
            </div>

            <div className="advance__setting--payment">
              <div className="heading">Payment methods</div>
              {cardSetting.map(({ image, value, label, isChecked }) => (
                <section
                  className="d-flex justify-content-between align-items-center mb-3"
                  key={label}
                >
                  <div className="d-flex justify-content-between align-items-center payment-methods">
                    <div className="icon-wrap">
                      <span>{image}</span>
                    </div>
                    <div>{label}</div>
                  </div>

                  <Switch
                    checked={isChecked}
                    onChange={(checked) => setIsChecked(value, checked)}
                  />
                </section>
              ))}
            </div>
            {canViewPolicy && (
              <Row className="mb-3">
                <CustomRoleSelect
                  isClearable
                  label="Spending limit"
                  placeholder="Select existing spending limit"
                  name="policy"
                  options={selectedType.map(
                    ({ name: label, code, description: value }) => ({
                      label,
                      value,
                      code,
                    }),
                  )}
                  onChange={(val) => handleChange({ name: 'policy', value: val })}
                  value={card.policy}
                  extendComponent={
                    <>
                      <PlusOutlined /> &nbsp; Create new policy
                    </>
                  }
                  addAction={createPolicy}
                />

                <div>
                  {selectedPolicy?.maxAmount && (
                    <PolicyInfo
                      title="Amount"
                      value={
                        <CurrencyFormat
                          prefix={getCurrency(selectedPolicy?.currency)}
                          value={selectedPolicy?.maxAmount / 100}
                          displayType="text"
                          thousandSeparator={true}
                        />
                      }
                    />
                  )}
                  {selectedPolicy?.frequency && (
                    <PolicyInfo
                      title="Frequency"
                      value={
                        <div className="d-flex flex-wrap ">
                          <BadgeType
                            value={selectedPolicy?.frequency}
                            isBadge={false}
                            margin
                          />
                        </div>
                      }
                      // value={sortDays(selectPolicy.period).join(', ')}
                    />
                  )}
                  {selectedPolicy?.period?.length && (
                    <PolicyInfo
                      title="Period"
                      value={
                        <div className="d-flex flex-wrap ">
                          {sortDays(selectedPolicy?.period).map((item, index) => (
                            <BadgeType value={item} key={index} isBadge={false} margin />
                          ))}
                        </div>
                      }
                    />
                  )}
                </div>
              </Row>
            )}

            <Row className="mb-3">
              <CategoryDropdown
                label={
                  <div className="advance__setting--label">
                    <div>Expense category</div>
                    <p>
                      All transaction will automatically fit into your chosen category.
                    </p>
                  </div>
                }
                placeholder={`Select specific spending category`}
                onChange={(value) => handleChange({ name: 'category', value: value })}
                value={card.category}
                name="category"
              />
            </Row>
            <Row className="mb-5">
              <MultipleSelect
                onMenuClose={onMenuClose}
                label={
                  <div className="advance__setting--label">
                    <div>Merchants or Vendors</div>
                    <p>
                      Selected merchants or vendors&apos; transactions are always
                      permitted.
                    </p>
                  </div>
                }
                name="vendors"
                placeholder={`Select specific vendors (e.g., "Office Depot")`}
                value={card.vendors}
                onChange={(value) => handleChange({ name: 'vendors', value: value })}
                options={generatedMccs}
                onInputChange={handleSelectChange}
                isDisabled={loadingMccs && !debouncedValue}
                isLoading={loadingMccs}
              />
            </Row>
          </div>

          <div className="modal-footer mt-3">
            <CustomButton
              onClick={closeHandler}
              disabled={loading}
              fullWidth={true}
              className="custom-button ghost-button"
            >
              Cancel
            </CustomButton>
            <CustomButton
              fullWidth={true}
              className="custom-button primary-button"
              loading={loading}
              disabled={loading}
              onClick={handleUpdate}
            >
              Update settings
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCardSettings;
