import { useRef, useState } from 'react';

const useIsFiltered = () => {
  const isFiltered = useRef(false);
  const [filteredQuery, setFilteredQuery] = useState({});

  const { current: filtered } = isFiltered;
  return { filtered, isFiltered, filteredQuery, setFilteredQuery };
};

export default useIsFiltered;
