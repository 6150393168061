import { Button } from 'react-bootstrap';
import cs from 'classnames';
import Loading from 'components/UI/Loading';
const CustomButton = ({
  withoutBg = false,
  className,
  customClass,
  fullWidth = false,
  children,
  loading,
  backgroundColor = '',
  ...props
}) => {
  return (
    <Button
      {...props}
      className={cs({ [className]: className }, { ['width-full']: fullWidth }, 'text-sm')}
    >
      {loading ? <Loading size={18} /> : children}
    </Button>
  );
};
export default CustomButton;
