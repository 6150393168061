import React, { useEffect, useState } from 'react';
import { AuthFormHeader, AuthFormWrapper } from 'components/Auth';
import { ReactComponent as AppLogo } from 'assets/logos/appLogo.svg';
import { Form, Row } from 'react-bootstrap';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import '../style.less';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { newPassword } from 'redux/actions/AuthAction';
import CheckPasswordStrength from 'components/UI/PasswordStrength';
import { toastError } from 'components/UI/toast';

const ResetPassword = () => {
  const history = useHistory();
  const { hashVal } = useParams();
  const {
    newPassword: { error, success, loading },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  const [validatePassword, setValidatePassword] = useState(false);
  const [authData, setAuthData] = React.useState({
    password: '',
    confirmPassword: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();
    if (
      form.checkValidity() === false ||
      authData.password !== authData.confirmPassword
    ) {
      toastError('Password not match');
      return;
    }
    if (!validatePassword) return toastError('Please enter a strong password');
    dispatch(
      newPassword({
        hash: hashVal,
        confirmPassword: authData.confirmPassword,
        newPassword: authData.password,
      }),
    );
    // history.push('/login');
  };

  useEffect(() => {
    if (error) {
      toastError(error);
      history.push('/reset-request');
    }
    if (success) {
      history.push('/login');
    }
  }, [success]);
  return (
    <div className="page-wrapper grey">
      <div className="page-content">
        <div className="auth-header">
          <AppLogo className="logo" />
        </div>
        <div className="page-content with-vit">
          <AuthFormWrapper>
            <div className="text-center">
              <AuthFormHeader title="Reset your password" />
              <div className="subHeader">
                {'Choose a new password for your Bujeti account'}
              </div>
            </div>
            <Form
              className="mt-3"
              noValidate
              validated={validatePassword}
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <CustomInput
                  label="Your new password"
                  error="enter valid password"
                  placeholder="Enter password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={authData.password}
                  maxlength={70}
                  required
                />
              </Row>
              <Row className="mb-3">
                <CustomInput
                  label="Confirm new password"
                  error="Confirm password does not match"
                  placeholder="Enter password"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={authData.confirmPassword}
                  maxlength={70}
                  required
                />
              </Row>

              <Row className="mb-3">
                <CheckPasswordStrength
                  validated={validatePassword}
                  password={authData.password}
                  validate={setValidatePassword}
                />
              </Row>

              <CustomButton
                className={'buttonStyle'}
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Reset password
              </CustomButton>
            </Form>
          </AuthFormWrapper>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
