import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import logger from 'redux-logger';
import { createBrowserHistory } from 'history'; // eslint-disable-line
import createRootReducer from './reducers';
import { sagas } from './sagas';
import persistReducer from 'redux-persist/es/persistReducer';
import { getPersistConfig } from 'redux-deep-persist';
import storage from 'redux-persist/lib/storage';

export const history = createBrowserHistory();

const enhancers = [];
const sagaMiddleWare = createSagaMiddleware();

const persistConfig = getPersistConfig({
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    'auth.user',
    'bills.payload',
    'roles.getRoles',
    'upload.assetData',
    'payments.getAllBanks',
    'beneficiaries.getBeneficiaryBank',
  ],
  rootReducer: createRootReducer(history),
});

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const middleware = [sagaMiddleWare, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line
  middleware.push(logger);

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

  sagaMiddleWare.run(sagas);

  return store;
}
