import cs from 'classnames';
import { Col, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { theStyle } from '../CustomSelectRadio';
import { ReactComponent as NigeriaCrest } from '../../../assets/icons/nigeria-crest.svg';
import { ReactComponent as UsaCrest } from '../../../assets/icons/united-states-crest.svg';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Menu = (props) => {
  const {
    selectProps: { onclickExtended, extendComponent },
    children,
  } = props;

  return (
    <components.Menu {...props}>
      <div>
        <div>{children}</div>
        {extendComponent && (
          <button
            type="button"
            className="add-beneficiary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onclickExtended();
            }}
          >
            {extendComponent}
          </button>
        )}
      </div>
    </components.Menu>
  );
};

const Option = (props) => {
  const { children } = props;
  return <components.Option {...props}>{children}</components.Option>;
};

const formatGroupLabel = (data) => (
  <div className="py-1">
    <span>{data.label}</span>
  </div>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <>
      <span className="pr-5">
        {props.data.value === 'NGN' || props.data.value === 'NGA' ? (
          <NigeriaCrest />
        ) : (
          <UsaCrest />
        )}
      </span>
      <span className="ml-2 pt-2" style={{ position: 'relative', top: 1.5 }}>
        {' '}
        {props.data.label}
      </span>
    </>
  </components.SingleValue>
);

const formatCountryOptionLabel = (selectedOption) => {
  if (!selectedOption) {
    return null;
  }
  return (
    <div className="d-flex align-items-center gap-2">
      <NigeriaCrest />
      <div>{selectedOption.label}</div>
    </div>
  );
};

const CustomSelect = ({
  label = '',
  closeMenuOnSelect = true,
  md = 12,
  wrapperClass = '',
  createable = false,
  formatOptionLabel,
  classNamePrefix,
  isCurrency,
  customStyles = null,
  onclickExtended = null,
  components: otherComponents,
  hasError = false,
  extendComponent,
  customOption,
  addAction,
  isCountry,
  ...res
}) => {
  return (
    <Form.Group
      as={Col}
      md={md}
      className={cs('groupWrapper form-group custom', { [wrapperClass]: wrapperClass })}
    >
      {label && (
        <Form.Label className={`${res.labelClassNmaes} form-label`}>{label}</Form.Label>
      )}

      {createable ? (
        <CreatableSelect
          isClearable
          closeMenuOnSelect={closeMenuOnSelect}
          maxMenuHeight={210}
          styles={theStyle(null, customStyles)}
          classNamePrefix="dropdown-select"
          // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          components={
            onclickExtended
              ? {
                  ...otherComponents,
                  Menu: (props) => Menu(props),
                  ...{ Option: customOption ?? Option },
                  animatedComponents,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : {
                  ...otherComponents,
                  animatedComponents,
                  ...{ Option: customOption ?? Option },
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
          }
          extendComponent={extendComponent}
          onclickExtended={onclickExtended}
          {...res}
        />
      ) : (
        <Select
          closeMenuOnSelect={closeMenuOnSelect}
          styles={theStyle(hasError, customStyles)}
          maxMenuHeight={210}
          components={
            extendComponent
              ? {
                  ...otherComponents,
                  Menu: (props) => Menu(props),
                  ...(isCurrency
                    ? { Option: SingleValue }
                    : { Option: customOption ?? Option }),
                  animatedComponents,
                }
              : {
                  ...otherComponents,
                  animatedComponents,
                  ...(isCurrency
                    ? { Option: SingleValue }
                    : { Option: customOption ?? Option }),
                }
          }
          onclickExtended={onclickExtended}
          extendComponent={extendComponent}
          formatOptionLabel={isCountry ? formatCountryOptionLabel : formatOptionLabel}
          formatGroupLabel={formatGroupLabel}
          classNamePrefix="dropdown-select"
          menuPlacement="auto"
          {...res}
        />
      )}
    </Form.Group>
  );
};
export default CustomSelect;
