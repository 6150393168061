import React, { useEffect, useState } from 'react';
import './styles.scss';
import PhoneInput, {
  formatPhoneNumber,
  getCountryCallingCode,
  parsePhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import cs from 'classnames';
import { removeSpaces } from 'utils/helper';
import { Col, Form } from 'react-bootstrap';

const CustomPhoneNumberInput = ({
  label,
  placeholder,
  onChange = () => null,
  disabled = false,
  value,
  md = 12,
  wrapperClass,
  ...rest
}) => {
  const [phoneCountryIso, setPhoneCountryIso] = useState('NG');
  const international = parsePhoneNumber(formatPhoneNumberIntl(value));
  const countryCode =
    value && isValidPhoneNumber(value)
      ? getCountryCallingCode(phoneCountryIso)
      : undefined;

  const [onChangeValue, setOnChangeValue] = useState();

  useEffect(() => {
    if (international?.country) {
      setPhoneCountryIso(international?.country);
    }
  }, [value]);

  useEffect(() => {
    if (countryCode && !onChangeValue) {
      if (!value) return onChange(undefined);

      return;
    }

    return onChange(
      removeSpaces(formatPhoneNumber(onChangeValue)),
      onChangeValue,
      countryCode,
    );
  }, [onChangeValue, countryCode]);

  return (
    <Form.Group
      as={Col}
      md={md}
      className={cs('groupWrapper customer-Phone__input-holder customer-Phone__input', {
        [wrapperClass]: wrapperClass,
      })}
    >
      {label && <label>{label}</label>}
      <div className={cs('PhoneInputDiv', { ['disabled']: disabled })}>
        <PhoneInput
          international={false}
          className={cs({ ['disabled']: disabled })}
          onCountryChange={(value) => setPhoneCountryIso(value)}
          placeholder={placeholder}
          defaultCountry="NG"
          value={value}
          onChange={setOnChangeValue}
          limitMaxLength={true}
          addInternationalOption={false}
          countryCallingCodeEditable={false}
          {...rest}
        />
      </div>
    </Form.Group>
  );
};
export default CustomPhoneNumberInput;
