import React, { useState } from 'react';
import './styles.scss';
import { Checkbox } from 'antd';
import Slider from 'rc-slider';
import numeral from 'numeral';

const FilterList = ({ data, handleChange }) => {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState([8000, 40000]);

  const sortCheckbox = data?.list?.sort((a, b) => {
    if (a.isSelected !== b.isSelected) {
      return a.isSelected ? -1 : 1;
    }
    return a.label.localeCompare(b.label);
  });
  return (
    <div className="filter-action-list pb-0">
      {data['type'] === 'slider' ? (
        <div className="slider-wrapper">
          <div className="px-1">
            <Slider
              range
              allowCross={false}
              value={amount}
              draggableTrack
              onChange={(val) => {
                handleChange(val, true, 'Slider');
                setAmount(val);
              }}
              max={50000}
            />
          </div>
          <div className="amount-wrapper">
            <span>
              {/* {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(amount[0])} */}
              {numeral(amount[0]).format('0,0.00')}
            </span>
            <span>
              {/* {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(amount[1])} */}
              {numeral(amount[1]).format('0,0.00')}
            </span>
          </div>
        </div>
      ) : (
        <>
          {sortCheckbox?.map((list, index) => {
            if (index < 3 || show) {
              return (
                <div key={index} className="mb-1">
                  <Checkbox
                    key={index}
                    onChange={({ target: { checked } }) =>
                      handleChange(list.value, checked, 'Checkbox', data.title)
                    }
                    checked={list.isSelected}
                    disabled={list.disabled}
                  >
                    {list.label}
                  </Checkbox>
                </div>
              );
            }
          })}
          {data?.list?.length >= 4 && !show && (
            <span
              className="show-all-font"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setShow(!show);
              }}
            >
              Show all...
            </span>
          )}
        </>
      )}
    </div>
  );
};
export default FilterList;
