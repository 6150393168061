import React from 'react';
import './styles.scss';
import { metal3d } from 'assets/images';

const QuickActionBanner = ({
  onClick,
  title,
  description,
  actionName = 'Upload certificate',
  hideLearn = false,
}) => {
  return (
    <div className="quickaction_banner-wrapper">
      <img src={metal3d} alt="metal" className="image-banner" />
      <div className="banner-text">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="actions pt-2">
        <button onClick={onClick} className="black btn">
          {actionName}
        </button>
        {hideLearn && (
          <button
            className="btn-border btn"
            onClick={() => window.open('https://www.bujeti.com', '_blank')}
          >
            Learn more
          </button>
        )}
      </div>
    </div>
  );
};

export default QuickActionBanner;
