import { SearchIcon } from 'assets/icons';
import { useState } from 'react';
import Select, { components } from 'react-select';
import './customRoleSelect.styles.scss';

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  selectProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseLeave = () => setIsActive(false);
  const { closeMenu } = selectProps;

  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    // onMouseUp,
    onMouseLeave,
    style,
  };

  const handleClick = () => {
    closeMenu();
  };

  const { data } = rest;

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div onClick={handleClick} className="h-full w-full d-flex align-items-center">
        <img
          src={data?.img}
          style={{
            padding: 3,
            width: 35,
            height: 35,
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: '100%',
            borderColor: '#E7E5E4',
            color: '#44403C',
            marginRight: 10,
          }}
          alt={data?.img}
        />
        <div style={{ display: 'grid' }}>
          <span className="account-name">{data?.label}</span>
        </div>
      </div>
    </components.Option>
  );
};

const CustomDropdownIndicator = () => (
  <div
    style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)' }}
  >
    <SearchIcon stroke="#A9A29D" />
  </div>
);
const CustomBanksSelect = ({ label, ...rest }) => {
  const theStyle = (error) => {
    const style = {
      control: (base, state) => ({
        ...base,
        //   border: state.isFocused ? '#000' : base.color,
        // This line disable the blue border
        postion: 'relative',
        display: 'flex',
        minHeight: '45px',
        padding: '3px 4px 3px 24px',
        borderRadius: '6px',
        borderColor: state.isFocused ? '#000' : error ? 'rgb(220 38 38 /100)' : '#D1D3CF',
        ring: state.isFocused ? '#000' : '#000',
        color: state.isSelected ? '#000' : base.color,
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(208, 213, 221, .7)' : 0,
        '&:hover': {
          borderColor: state.isFocused ? '#000' : null,
          ring: state.isFocused ? '#000' : null,
        },

        '*': {
          boxShadow: 'none !important',
          borderColor: '#000',
        },
      }),
      option: (base, state) => ({
        ...base,
        color: state.isSelected ? '#FFF' : '#6B7280',
        backgroundColor: state.isSelected ? '#000' : '#F7F8F7',
        borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
        display: 'flex',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '&:hover': {
          color: '#6B7280',
          backgroundColor: 'red',
        },
      }),

      indicatorSeparator: () => ({ display: 'none' }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          textAlign: 'start',
        };
      },
    };

    return style;
  };

  return (
    <div className="w-100 custom-wrapper">
      {label && <label htmlFor="role">{label}</label>}
      <Select
        components={{
          DropdownIndicator: CustomDropdownIndicator,
          Option: (props) => (
            <InputOption
              {...props}
              selectProps={{
                closeMenu: props.selectProps.onMenuClose,
              }}
            />
          ),
        }}
        styles={theStyle()}
        {...rest}
      />
    </div>
  );
};

export default CustomBanksSelect;
