export const OPEN_CONNECT_REQUEST = 'OPEN_CONNECT_REQUEST';
export const OPEN_CONNECT_SUCCESS = 'OPEN_CONNECT_SUCCESS';

const initialState = {
  open: false,
  reauthenticationCode: null,
};

export const MonoAccountLinkingReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONNECT_SUCCESS:
      return {
        ...state,
        open: !state.open,
        reauthenticationCode:
          action?.reauthenticationCode || action?.payload?.payload?.reauthenticationCode,
      };
    default:
      return state;
  }
};
