import React from 'react';
import { Container, Row } from 'react-bootstrap';

import './styles.scss';
import CustomButton from 'pages/Profile/Components/CustomButton';
import { SaveIcon } from 'assets/icons';

const NoData = ({
  headerText,
  bodyText,
  onClickHandler,
  buttonLabel,
  withButton = true,
}) => {
  return (
    <>
      <Container className="no-data">
        <Row>
          <SaveIcon />
          <span style={{ fontSize: '16px' }} className="textHeader">
            {headerText}
          </span>
        </Row>
        <Row>
          <span className="subHeaderText text-sm">{bodyText && bodyText}</span>
        </Row>
        {withButton && (
          <Row className="mt-4">
            <CustomButton
              style={{ width: 'fit-content' }}
              className="dark-outline-button px-3 mx-auto"
              onClick={onClickHandler}
            >
              {buttonLabel}
            </CustomButton>
          </Row>
        )}
      </Container>
    </>
  );
};
export default NoData;
