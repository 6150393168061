import {
  GET_MEMBER_PROFILE_REQUEST,
  GET_PROFILE_STATS_REQUEST,
  GET_SINGLE_USER_REQUEST,
} from 'redux/reducers/MemberProfileReducer';

export const getMemberProfile = (payload) => ({
  type: GET_MEMBER_PROFILE_REQUEST,
  payload,
});

export const getSingleUser = (payload) => ({
  type: GET_SINGLE_USER_REQUEST,
  payload,
});

export const getProfileStats = (payload) => ({
  type: GET_PROFILE_STATS_REQUEST,
  payload,
});
