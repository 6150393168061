import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import Saving from 'components/UI/ModalSaving/Saving';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTeamMember } from 'redux/actions/TeamsAction';
import './styles.scss';

const teamValue = {
  name: '',
  managers: '',
  members: '',
  budgets: '',
  status: '',
  teamCode: '',
  role: '',
};

const SelectedTeamsModal = ({ isOpen, handleClose, Update }) => {
  if (!isOpen) return <div />;
  const dispatch = useDispatch();
  const { teamsId } = useParams();

  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    addTeamMember: { success, loading },
    updateTeam: { success: updateSuccess, loading: updateLoading },
    teamOverview: { data: { team: teamData = {} } = {} },
  } = useSelector(({ teams }) => teams);

  const {
    getRoles: { data: rolesData = [] },
  } = useSelector(({ roles }) => roles);

  const filteredRoles = rolesData
    .slice(0, 3)
    .filter(({ name }) => name != 'Admin')
    .map((role) => ({
      value: role.code,
      label: role.name === 'Employee' ? 'Member' : role.name,
    }));

  const { members = [] } = teamData;

  const existing = members.map(({ user }) => user.code);

  const {
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const { beneficiaries = [] } = data;

  const [team, setTeam] = useState(teamValue);
  const activeBeneficiaries = beneficiaries
    .filter(
      ({ status, user }) =>
        ['active', 'invited', 'inactive'].includes(status) &&
        !existing.includes(user.code),
    )
    .map(({ user }) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.code,
    }));

  const handleChange = ({ name, value }) => {
    setTeam({ ...team, [name]: value });
  };

  const handleSubmit = () => {
    if (!team.members) return toastError('Please select member(s)');
    if (!team.role) return toastError('Please select a role');

    const role = team.role.value;
    const members = team.members.map((item) => item.value);

    const payload = {
      role,
      members, // An array of Members of the team.... User codes
      code: teamsId,
    };

    dispatch(addTeamMember(payload));
  };

  useEffect(() => {
    if (success || updateSuccess) handleClose();
  }, [success, updateSuccess]);

  const title = Update ? 'Update Team Member' : 'Add Team Member(s)';
  const teamForm = () => (
    <div className="beneficiaries">
      <h2 className="team-title">{title}</h2>

      <>
        <Row className="mb-3">
          <MultipleSelect
            placeholder="Select Member(s)"
            label="Member(s)"
            name="member"
            options={activeBeneficiaries}
            onChange={(val) => handleChange({ name: 'members', value: val })}
            value={team.members}
          />
        </Row>

        <Row className="mb-3">
          <CustomSelect
            placeholder="Select Role"
            label="Role(s)"
            name="role"
            options={filteredRoles}
            onChange={(val) => handleChange({ name: 'role', value: val })}
            value={team.role}
          />
        </Row>

        {Update && (
          <>
            <Row className="mb-3 ">
              <CustomInput
                label="Team name *"
                placeholder="Enter team name"
                type="text"
                name="name"
                onChange={({ target: { name, value } }) => handleChange({ name, value })}
                value={team.name}
                maxLength="50"
              />
            </Row>
            <Row className="mb-3">
              <MultipleSelect
                placeholder="Select Manager(s)"
                label="Manager(s)"
                name="managers"
                options={activeBeneficiaries}
                onChange={(val) => handleChange({ name: 'managers', value: val })}
                value={team.managers}
              />
            </Row>

            <Row className="mb-3">
              <MultipleSelect
                label="Team Member(s)"
                placeholder="Select Member(s)"
                name="members"
                options={activeBeneficiaries}
                onChange={(val) => handleChange({ name: 'members', value: val })}
                value={team.members}
              />
            </Row>
            <Row className="mb-3">
              <CustomSelect
                label="Status"
                placeholder="Set Status"
                name="status"
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'pause', label: 'Pause' },
                ]}
                onChange={(val) => handleChange({ name: 'status', value: val })}
                value={team.status}
              />
            </Row>
          </>
        )}
      </>

      <CustomButton className="mt-3" onClick={handleSubmit} fullWidth={true}>
        {Update ? 'Save Changes' : 'Save'}
      </CustomButton>
      <CustomButton withoutBg className="mt-3" onClick={handleClose} fullWidth={true}>
        Cancel
      </CustomButton>
    </div>
  );

  const isLoading = loading || updateLoading;

  return (
    <div>
      <Modal show={isOpen} onClose={handleClose}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{isLoading ? <Saving /> : teamForm()}</div>
        </div>
      </Modal>
    </div>
  );
};
export default SelectedTeamsModal;
