export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const APPROVE_TRANSACTION_REQUEST = 'APPROVE_TRANSACTION_REQUEST';
export const APPROVE_TRANSACTION_SUCCESS = 'APPROVE_TRANSACTION_SUCCESS';
export const APPROVE_TRANSACTION_ERROR = 'APPROVE_TRANSACTION_ERROR';

export const REQUEST_TRANSACTION_DETAILS_REQUEST = 'REQUEST_TRANSACTION_DETAILS_REQUEST';
export const REQUEST_TRANSACTION_DETAILS_SUCCESS = 'REQUEST_TRANSACTION_DETAILS_SUCCESS';
export const REQUEST_TRANSACTION_DETAILS_ERROR = 'REQUEST_TRANSACTION_DETAILS_ERROR';

export const GET_TRANSFERS_REQUEST = 'GET_TRANSFERS_REQUEST';
export const GET_TRANSFERS_SUCCESS = 'GET_TRANSFERS_SUCCESS';
export const GET_TRANSFERS_ERROR = 'GET_TRANSFERS_ERROR';

export const GET_PAYOUTS_REQUEST = 'GET_PAYOUTS_REQUEST';
export const GET_PAYOUTS_SUCCESS = 'GET_PAYOUTS_SUCCESS';
export const GET_PAYOUTS_ERROR = 'GET_PAYOUTS_ERROR';

export const CANCEL_TRANSACTION_REQUEST = 'CANCEL_TRANSACTION_REQUEST';
export const CANCEL_TRANSACTION_SUCCESS = 'CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_TRANSACTION_ERROR = 'CANCEL_TRANSACTION_ERROR';

export const GET_CUSTOMER_TRANSACTION_REQUEST = 'GET_CUSTOMER_TRANSACTION_REQUEST';
export const GET_CUSTOMER_TRANSACTION_SUCCESS = 'GET_CUSTOMER_TRANSACTION_SUCCESS';
export const GET_CUSTOMER_TRANSACTION_ERROR = 'GET_CUSTOMER_TRANSACTION_ERROR';

export const RETRY_TRANSACTION_REQUEST = 'RETRY_TRANSACTION_REQUEST';
export const RETRY_TRANSACTION_SUCCESS = 'RETRY_TRANSACTION_SUCCESS';
export const RETRY_TRANSACTION_ERROR = 'RETRY_TRANSACTION_ERROR';

export const GET_SELECTED_TRANSACTIONS_REQUEST = 'GET_SELECTED_TRANSACTIONS_REQUEST';
export const GET_SELECTED_TRANSACTIONS_SUCCESS = 'GET_SELECTED_TRANSACTIONS_SUCCESS';
export const GET_SELECTED_TRANSACTIONS_ERROR = 'GET_SELECTED_TRANSACTIONS_ERROR';

export const GET_SINGLE_TRANSACTIONS_REQUEST = 'GET_SINGLE_TRANSACTIONS_REQUEST';
export const GET_SINGLE_TRANSACTIONS_SUCCESS = 'GET_SINGLE_TRANSACTIONS_SUCCESS';
export const GET_SINGLE_TRANSACTIONS_ERROR = 'GET_SINGLE_TRANSACTIONS_ERROR';

export const EXPORT_TRANSACTIONS_REQUEST = 'EXPORT_TRANSACTIONS_REQUEST';
export const EXPORT_TRANSACTIONS_SUCCESS = 'EXPORT_TRANSACTIONS_SUCCESS';
export const EXPORT_TRANSACTIONS_ERROR = 'EXPORT_TRANSACTIONS_ERROR';

export const EXPORT_BATCH_TRANSACTIONS_REQUEST = 'EXPORT_BATCH_TRANSACTIONS_REQUEST';
export const EXPORT_BATCH_TRANSACTIONS_SUCCESS = 'EXPORT_BATCH_TRANSACTIONS_SUCCESS';
export const EXPORT_BATCH_TRANSACTIONS_ERROR = 'EXPORT_BATCH_TRANSACTIONS_ERROR';

export const EXPORT_TRANSFERS_REQUEST = 'EXPORT_TRANSFERS_REQUEST';
export const EXPORT_TRANSFERS_SUCCESS = 'EXPORT_TRANSFERS_SUCCESS';
export const EXPORT_TRANSFERS_ERROR = 'EXPORT_TRANSFERS_ERROR';

export const GET_SINGLE_TRANSFERS_REQUEST = 'GET_SINGLE_TRANSFERS_REQUEST';
export const GET_SINGLE_TRANSFERS_ERROR = 'GET_SINGLE_TRANSFERS_ERROR';
export const GET_SINGLE_TRANSFERS_SUCCESS = 'GET_SINGLE_TRANSFERS_SUCCESS';

export const EDIT_TRANSACTIONS_BY_ID_REQUEST = 'EDIT_TRANSACTIONS_BY_ID_REQUEST';
export const EDIT_TRANSACTIONS_BY_ID_SUCCESS = 'EDIT_TRANSACTIONS_BY_ID_SUCCESS';
export const EDIT_TRANSACTIONS_BY_ID_ERROR = 'EDIT_TRANSACTIONS_BY_ID_ERROR';

export const EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST =
  'EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST';
export const EDIT_BATCH_TRANSACTIONS_BY_ID_SUCCESS =
  'EDIT_BATCH_TRANSACTIONS_BY_ID_SUCCESS';
export const EDIT_BATCH_TRANSACTIONS_BY_ID_ERROR = 'EDIT_BATCH_TRANSACTIONS_BY_ID_ERROR';

export const GET_FAILED_TRANSACTIONS_REQUEST = 'GET_FAILED_TRANSACTIONS_REQUEST';
export const GET_FAILED_TRANSACTIONS_SUCCESS = 'GET_FAILED_TRANSACTIONS_SUCCESS';
export const GET_FAILED_TRANSACTIONS_ERROR = 'GET_FAILED_TRANSACTIONS_ERROR';

export const BULK_RETRY_TRANSACTIONS_REQUEST = 'BULK_RETRY_TRANSACTIONS_REQUEST';
export const BULK_RETRY_TRANSACTIONS_SUCCESS = 'BULK_RETRY_TRANSACTIONS_SUCCESS';
export const BULK_RETRY_TRANSACTIONS_ERROR = 'BULK_RETRY_TRANSACTIONS_ERROR';

export const GET_ALL_TRANSACTION_ASSET = 'GET_ALL_TRANSACTION_ASSET';
export const GET_ALL_TRANSACTION_ASSET_SUCCESS = 'GET_ALL_TRANSACTION_ASSET_SUCCESS';
export const GET_ALL_TRANSACTION_ASSET_ERROR = 'GET_ALL_TRANSACTION_ASSET_ERROR';

export const GET_SCHEDULED_TRANSACTIONS_REQUEST = 'GET_SCHEDULED_TRANSACTIONS_REQUEST';
export const GET_SCHEDULED_TRANSACTIONS_SUCCESS = 'GET_SCHEDULED_TRANSACTIONS_SUCCESS';
export const GET_SCHEDULED_TRANSACTIONS_ERROR = 'GET_SCHEDULED_TRANSACTIONS_ERROR';

export const MASS_ASSIGN_TRANSACTION_REQUEST = 'MASS_ASSIGN_TRANSACTION_REQUEST';
export const MASS_ASSIGN_TRANSACTION_SUCCESS = 'MASS_ASSIGN_TRANSACTION_SUCCESS';
export const MASS_ASSIGN_TRANSACTION_ERROR = 'MASS_ASSIGN_TRANSACTION_ERROR';

export const GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST =
  'GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST';
export const GET_SINGLE_SCHEDULED_TRANSACTIONS_SUCCESS =
  'GET_SINGLE_SCHEDULED_TRANSACTIONS_SUCCESS';
export const GET_SINGLE_SCHEDULED_TRANSACTIONS_ERROR =
  'GET_SINGLE_SCHEDULED_TRANSACTIONS_ERROR';

export const UPDATE_SCHEDULED_TRANSACTIONS_REQUEST =
  'UPDATE_SCHEDULED_TRANSACTIONS_REQUEST';
export const UPDATE_SCHEDULED_TRANSACTIONS_SUCCESS =
  'UPDATE_SCHEDULED_TRANSACTIONS_SUCCESS';
export const UPDATE_SCHEDULED_TRANSACTIONS_ERROR = 'UPDATE_SCHEDULED_TRANSACTIONS_ERROR';

export const BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST =
  'BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST';
export const BULK_CANCEL_SCHEDULED_TRANSACTIONS_SUCCESS =
  'BULK_CANCEL_SCHEDULED_TRANSACTIONS_SUCCESS';
export const BULK_CANCEL_SCHEDULED_TRANSACTIONS_ERROR =
  'BULK_CANCEL_SCHEDULED_TRANSACTIONS_ERROR';

export const UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST =
  'UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST';
export const UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_SUCCESS =
  'UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_SUCCESS';
export const UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_ERROR =
  'UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_ERROR';

export const REQUERY_TRANSACTIONS_REQUEST = 'REQUERY_TRANSACTIONS_REQUEST';
export const REQUERY_TRANSACTIONS_SUCCESS = 'REQUERY_TRANSACTIONS_SUCCESS';
export const REQUERY_TRANSACTIONS_ERROR = 'REQUERY_TRANSACTIONS_ERROR';

export const RESET_BLOCK_TRANSACTIONS = 'RESET_BLOCK_TRANSACTIONS';

export const RESET_FLAGS_TRANSACTIONS = 'RESET_FLAGS_TRANSACTIONS';
export const RESET_FLAGS_TRANSFERS = 'RESET_FLAGS_TRANSFERS';
export const RESET_FLAGS_PAYOUTS = 'RESET_FLAGS_PAYOUTS';
export const SHOULD_CLEAR_SELECTED_TRANSACTION_ROWS = 'SHOULD_CLEAR_TRANSACTION_ROWS';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getTransaction: { ...block },
  approveTransaction: { ...block },
  requestTransactionDetails: { ...block },
  getScheduledTransaction: { ...block },
  getSingleScheduledTransaction: { ...block },
  updateScheduledTransaction: { ...block },
  bulkCancelScheduledTransaction: { ...block },
  updateScheduledTransactionReceipt: { ...block },
  getTransfers: { ...block },
  getPayouts: { ...block },
  retryTransaction: { ...block },
  cancelTransaction: { ...block },
  getSelectedTransaction: { ...block },
  createTransaction: { ...block },
  updateTransaction: { ...block },
  getSingleTransaction: { ...block },
  exportTransaction: { ...block },
  exportBatchTransaction: { ...block },
  editTransactionById: { ...block },
  failedTransactions: { ...block },
  bulkRetryTransactions: { ...block },
  transactionReceipts: { ...block },
  requeryTransaction: { ...block },
  editBatchTransactionById: { ...block },
  massAssignTransaction: { ...block },
  getCustomerTransaction: { ...block },
};

export const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return { ...state, getTransaction: { ...state.getTransaction, loading: true } };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getTransaction: {
          ...state.getTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        getTransaction: { ...state.getTransaction, loading: false, error: action.error },
      };

    case APPROVE_TRANSACTION_REQUEST:
      return {
        ...state,
        approveTransaction: { ...state.approveTransaction, loading: true },
      };
    case APPROVE_TRANSACTION_SUCCESS:
      return {
        ...state,
        approveTransaction: {
          ...state.approveTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case APPROVE_TRANSACTION_ERROR:
      return {
        ...state,
        approveTransaction: {
          ...state.approveTransaction,
          loading: false,
          error: action.error,
        },
      };

    case REQUEST_TRANSACTION_DETAILS_REQUEST:
      return {
        ...state,
        requestTransactionDetails: { ...state.requestTransactionDetails, loading: true },
      };
    case REQUEST_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        requestTransactionDetails: {
          ...state.requestTransactionDetails,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REQUEST_TRANSACTION_DETAILS_ERROR:
      return {
        ...state,
        requestTransactionDetails: {
          ...state.requestTransactionDetails,
          loading: false,
          error: action.error,
        },
      };

    case GET_CUSTOMER_TRANSACTION_REQUEST:
      return {
        ...state,
        getCustomerTransaction: { ...state.getCustomerTransaction, loading: true },
      };
    case GET_CUSTOMER_TRANSACTION_SUCCESS:
      return {
        ...state,
        getCustomerTransaction: {
          ...state.getCustomerTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_CUSTOMER_TRANSACTION_ERROR:
      return {
        ...state,
        getCustomerTransaction: {
          ...state.getCustomerTransaction,
          loading: false,
          error: action.error,
        },
      };

    case MASS_ASSIGN_TRANSACTION_REQUEST:
      return {
        ...state,
        massAssignTransaction: { ...state.massAssignTransaction, loading: true },
      };
    case MASS_ASSIGN_TRANSACTION_SUCCESS:
      return {
        ...state,
        massAssignTransaction: {
          ...state.massAssignTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case MASS_ASSIGN_TRANSACTION_ERROR:
      return {
        ...state,
        massAssignTransaction: {
          ...state.massAssignTransaction,
          loading: false,
          error: action.error,
        },
      };

    case REQUERY_TRANSACTIONS_REQUEST:
      return {
        ...state,
        requeryTransaction: { ...state.requeryTransaction, loading: true },
      };
    case REQUERY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        requeryTransaction: {
          ...state.requeryTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REQUERY_TRANSACTIONS_ERROR:
      return {
        ...state,
        requeryTransaction: {
          ...state.requeryTransaction,
          loading: false,
          error: action.error,
        },
      };

    case GET_SCHEDULED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getScheduledTransaction: { ...state.getScheduledTransaction, loading: true },
      };
    case GET_SCHEDULED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getScheduledTransaction: {
          ...state.getScheduledTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SCHEDULED_TRANSACTIONS_ERROR:
      return {
        ...state,
        getScheduledTransaction: {
          ...state.getScheduledTransaction,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_SCHEDULED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSingleScheduledTransaction: {
          ...state.getSingleScheduledTransaction,
          loading: true,
        },
      };
    case GET_SINGLE_SCHEDULED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSingleScheduledTransaction: {
          ...state.getSingleScheduledTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_SCHEDULED_TRANSACTIONS_ERROR:
      return {
        ...state,
        getSingleScheduledTransaction: {
          ...state.getSingleScheduledTransaction,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_SCHEDULED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        updateScheduledTransaction: {
          ...state.updateScheduledTransaction,
          loading: true,
        },
      };
    case UPDATE_SCHEDULED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        updateScheduledTransaction: {
          ...state.updateScheduledTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_SCHEDULED_TRANSACTIONS_ERROR:
      return {
        ...state,
        updateScheduledTransaction: {
          ...state.updateScheduledTransaction,
          loading: false,
          error: action.error,
        },
      };

    case BULK_CANCEL_SCHEDULED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        bulkCancelScheduledTransaction: {
          ...state.bulkCancelScheduledTransaction,
          loading: true,
        },
      };
    case BULK_CANCEL_SCHEDULED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        bulkCancelScheduledTransaction: {
          ...state.bulkCancelScheduledTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BULK_CANCEL_SCHEDULED_TRANSACTIONS_ERROR:
      return {
        ...state,
        bulkCancelScheduledTransaction: {
          ...state.bulkCancelScheduledTransaction,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_REQUEST:
      return {
        ...state,
        updateScheduledTransactionReceipt: {
          ...state.updateScheduledTransactionReceipt,
          loading: true,
        },
      };
    case UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_SUCCESS:
      return {
        ...state,
        updateScheduledTransactionReceipt: {
          ...state.updateScheduledTransactionReceipt,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_SCHEDULED_TRANSACTIONS_RECEIPT_ERROR:
      return {
        ...state,
        updateScheduledTransactionReceipt: {
          ...state.updateScheduledTransactionReceipt,
          loading: false,
          error: action.error,
        },
      };

    case GET_TRANSFERS_REQUEST:
      return { ...state, getTransfers: { ...state.getTransfers, loading: true } };

    case GET_TRANSFERS_SUCCESS:
      return {
        ...state,
        getTransfers: {
          ...state.getTransfers,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };

    case GET_TRANSFERS_ERROR:
      return {
        ...state,
        getTransfers: { ...state.getTransfers, loading: false, error: action.error },
      };

    case GET_PAYOUTS_REQUEST:
      return { ...state, getPayouts: { ...state.getPayouts, loading: true } };
    case GET_PAYOUTS_SUCCESS:
      return {
        ...state,
        getPayouts: {
          ...state.getPayouts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_PAYOUTS_ERROR:
      return {
        ...state,
        getPayouts: { ...state.getPayouts, loading: false, error: action.error },
      };

    case GET_FAILED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        failedTransactions: { ...state.failedTransactions, loading: true },
      };
    case GET_FAILED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        failedTransactions: {
          ...state.failedTransactions,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_FAILED_TRANSACTIONS_ERROR:
      return {
        ...state,
        failedTransactions: {
          ...state.failedTransactions,
          loading: false,
          error: action.error,
        },
      };

    case CANCEL_TRANSACTION_REQUEST:
      return {
        ...state,
        cancelTransaction: { ...state.cancelTransaction, loading: true },
      };
    case CANCEL_TRANSACTION_SUCCESS:
      return {
        ...state,
        cancelTransaction: {
          ...state.cancelTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CANCEL_TRANSACTION_ERROR:
      return {
        ...state,
        cancelTransaction: {
          ...state.cancelTransaction,
          loading: false,
          error: action.error,
        },
      };

    case RETRY_TRANSACTION_REQUEST:
      return { ...state, retryTransaction: { ...state.retryTransaction, loading: true } };
    case RETRY_TRANSACTION_SUCCESS:
      return {
        ...state,
        retryTransaction: {
          ...state.retryTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case RETRY_TRANSACTION_ERROR:
      return {
        ...state,
        retryTransaction: {
          ...state.retryTransaction,
          loading: false,
          error: action.error,
        },
      };

    case BULK_RETRY_TRANSACTIONS_REQUEST:
      return {
        ...state,
        bulkRetryTransactions: { ...state.bulkRetryTransactions, loading: true },
      };
    case BULK_RETRY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        bulkRetryTransactions: {
          ...state.bulkRetryTransactions,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BULK_RETRY_TRANSACTIONS_ERROR:
      return {
        ...state,
        bulkRetryTransactions: {
          ...state.bulkRetryTransactions,
          loading: false,
          error: action.error,
        },
      };

    case GET_SELECTED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSelectedTransaction: { ...state.getSelectedTransaction, loading: true },
      };
    case GET_SELECTED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSelectedTransaction: {
          ...state.getSelectedTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SELECTED_TRANSACTIONS_ERROR:
      return {
        ...state,
        getSelectedTransaction: {
          ...state.getSelectedTransaction,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_TRANSACTIONS_REQUEST:
      return {
        ...state,
        getSingleTransaction: { ...state.getSingleTransaction, loading: true },
      };
    case GET_SINGLE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_TRANSACTIONS_ERROR:
      return {
        ...state,
        getSingleTransaction: {
          ...state.getSingleTransaction,
          loading: false,
          error: action.error,
        },
      };

    case EXPORT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        exportTransaction: { ...state.exportTransaction, loading: true },
      };
    case EXPORT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        exportTransaction: {
          ...state.exportTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EXPORT_TRANSACTIONS_ERROR:
      return {
        ...state,
        exportTransaction: {
          ...state.exportTransaction,
          loading: false,
          error: action.error,
        },
      };

    case EXPORT_BATCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        exportBatchTransaction: { ...state.exportBatchTransaction, loading: true },
      };
    case EXPORT_BATCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        exportBatchTransaction: {
          ...state.exportBatchTransaction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EXPORT_BATCH_TRANSACTIONS_ERROR:
      return {
        ...state,
        exportBatchTransaction: {
          ...state.exportBatchTransaction,
          loading: false,
          error: action.error,
        },
      };

    //
    case RESET_BLOCK_TRANSACTIONS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_TRANSACTIONS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    case RESET_FLAGS_PAYOUTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    case RESET_FLAGS_TRANSFERS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    case EDIT_TRANSACTIONS_BY_ID_REQUEST:
      return {
        ...state,
        editTransactionById: { ...state.editTransactionById, loading: true },
      };
    case EDIT_TRANSACTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        editTransactionById: {
          ...state.editTransactionById,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EDIT_TRANSACTIONS_BY_ID_ERROR:
      return {
        ...state,
        editTransactionById: {
          ...state.editTransactionById,
          loading: false,
          error: action.error,
        },
      };

    case EDIT_BATCH_TRANSACTIONS_BY_ID_REQUEST:
      return {
        ...state,
        editBatchTransactionById: { ...state.editBatchTransactionById, loading: true },
      };
    case EDIT_BATCH_TRANSACTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        editBatchTransactionById: {
          ...state.editBatchTransactionById,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EDIT_BATCH_TRANSACTIONS_BY_ID_ERROR:
      return {
        ...state,
        editBatchTransactionById: {
          ...state.editBatchTransactionById,
          loading: false,
          error: action.error,
        },
      };

    case GET_ALL_TRANSACTION_ASSET:
      return {
        ...state,
        transactionReceipts: { ...state.transactionReceipts, loading: true },
      };
    case GET_ALL_TRANSACTION_ASSET_SUCCESS:
      return {
        ...state,
        transactionReceipts: {
          ...state.transactionReceipts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ALL_TRANSACTION_ASSET_ERROR:
      return {
        ...state,
        transactionReceipts: {
          ...state.transactionReceipts,
          loading: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
