import { Table } from 'react-bootstrap';
import { getCurrency, getFormattedDate } from 'utils/helper';

const RetryFailedTransactionTable = ({ data, getSelected, checkAll, handleCheckAll }) => {
  const isAllSelected = data?.length === data?.filter((datum) => datum.isChecked).length;

  return (
    <Table className="retry-table">
      <thead>
        <tr>
          <th>
            <input
              className="failed-checkbox"
              id="selectAll"
              type="checkbox"
              checked={checkAll && isAllSelected}
              onChange={(event) => handleCheckAll(event)}
            />
          </th>
          <th>Paid To</th>
          <th>Amount</th>
          <th>Paid By</th>
          <th>Budget</th>
          <th>Paid on</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((datum) => {
          return (
            <tr key={datum.code}>
              <td>
                <input
                  type="checkbox"
                  className="failed-checkbox"
                  checked={datum?.isChecked}
                  onChange={(event) => getSelected(event, datum.code)}
                />
              </td>
              <td>{getRecipientName(datum?.recipient)}</td>
              <td>{`${getCurrency(datum?.currency)} ${datum?.amount / 100}`}</td>
              <td>{`${datum?.payer?.firstName} ${datum?.payer?.lastName}`}</td>
              <td>{datum?.budget?.name}</td>
              <td>{getFormattedDate(datum?.created_at)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const getRecipientName = (recipient) => {
  if (!recipient?.name && recipient?.firstName)
    return `${recipient?.firstName} ${recipient?.lastName}`;
  return recipient?.name;
};

export default RetryFailedTransactionTable;
