import {
  ADD_BILLING_ADDRESS_REQUEST,
  ADD_PAYMENT_METHOD_REQUEST,
  CANCEL_PLAN_REQUEST,
  DELETE_PAYMENT_METHOD_REQUEST,
  FETCH_BILLING_HISTORY_REQUEST,
  FETCH_PLANS_REQUEST,
  GENERATE_RECEIPT_REQUEST,
  GET_BILLING_ADDRESS_REQUEST,
  GET_COMPANY_SUBSCRIPTION_REQUEST,
  GET_PAYMENT_METHOD_REQUEST,
  SUBSCRIBE_TO_PLAN_REQUEST,
  UPDATE_SUBSCRIPTION_REQUEST,
  VIEW_PLAN_REQUEST,
} from '../reducers/BillingReducer';

export const fetchplansAction = () => ({
  type: FETCH_PLANS_REQUEST,
});

export const fetchBillingHistoryAction = () => ({
  type: FETCH_BILLING_HISTORY_REQUEST,
});

export const viewPlan = (payload) => ({
  type: VIEW_PLAN_REQUEST,
  payload,
});

export const subscribeToPlan = (payload) => ({
  type: SUBSCRIBE_TO_PLAN_REQUEST,
  payload,
});

export const getCompanySubscription = () => ({
  type: GET_COMPANY_SUBSCRIPTION_REQUEST,
});

export const updateSubscription = (payload) => ({
  type: UPDATE_SUBSCRIPTION_REQUEST,
  payload,
});

export const cancelPlan = (payload) => ({
  type: CANCEL_PLAN_REQUEST,
  payload,
});

export const generateReceipt = (payload) => ({
  type: GENERATE_RECEIPT_REQUEST,
  payload,
});

export const addPaymentMethod = (payload) => ({
  type: ADD_PAYMENT_METHOD_REQUEST,
  payload,
});

export const getPaymentMethod = (payload) => ({
  type: GET_PAYMENT_METHOD_REQUEST,
  payload,
});

export const deletePaymentMethod = (payload) => ({
  type: DELETE_PAYMENT_METHOD_REQUEST,
  payload,
});

export const addBillingAddress = (payload) => ({
  type: ADD_BILLING_ADDRESS_REQUEST,
  payload,
});

export const getBillingAddress = (payload) => ({
  type: GET_BILLING_ADDRESS_REQUEST,
  payload,
});
