import * as React from 'react';

const AddIcon = (props) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={40} height={40} rx={20} fill="#000" />
    <path
      d="M20 15v10M14.666 20h10.667"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddIcon;
