import { EyeIcon, PDFIcon, StatusIcon } from 'assets/icons';
import React from 'react';
import { capitalizeFirstLetter, getColor, truncateText } from 'utils/helper';

const statusColor = { verifying: '#885A1A', verified: '#12B76A', unverified: '#885A1A' };
const ViewFile = ({ fileName, status, fileUrl, label }) => {
  const handleOnClick = () => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };
  return (
    <div className="fileUpload groupWrapper">
      <label className="uploadFileLabel">{label}</label>
      <div
        onClick={handleOnClick}
        className="py-3 px-3 cursor rounded-4 d-flex justify-content-between align-items-center uploaded-file-container"
      >
        <div className="d-flex">
          <PDFIcon />
          <div className="ms-3">
            <p className="mb-1 p-0 m-0 uploaded-file-container-name">
              {truncateText(fileName, 0, 30)}
            </p>
            <p className="uploaded-file-container-details gap-1 d-flex align-items-center p-0 m-0">
              <span className="d-flex align-items-center gap-1">
                <StatusIcon fill={statusColor[status?.toLowerCase()]} />
                {capitalizeFirstLetter(status)}
              </span>{' '}
            </p>
          </div>
        </div>
        <div>
          <EyeIcon width="20" height="20" />
        </div>
      </div>
    </div>
  );
};

export default ViewFile;
