import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_REFERRALS_ERROR,
  GET_REFERRALS_REQUEST,
  GET_REFERRALS_SUCCESS,
  GET_REFERRALS_WITH_CODE_ERROR,
  GET_REFERRALS_WITH_CODE_REQUEST,
  GET_REFERRALS_WITH_CODE_SUCCESS,
  SEND_REFERRAL_EMAIL_ERROR,
  SEND_REFERRAL_EMAIL_REQUEST,
  SEND_REFERRAL_EMAIL_SUCCESS,
} from 'redux/reducers/ReferralsReducer';
import { getSimplifiedError } from 'utils/error';

async function getReferralsWithCode({ code, params }) {
  return await Axios.get(`/referrals/${code}`, { params });
}
function* handleGetReferralsWithCode({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;
    const response = yield call(getReferralsWithCode, {
      params: payload,
      code: getPayload.code,
    });
    if (response) {
      yield put({
        type: GET_REFERRALS_WITH_CODE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REFERRALS_WITH_CODE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getReferrals() {
  return await Axios.get(`/referrals`);
}
function* handleGetReferrals() {
  try {
    const response = yield call(getReferrals);
    if (response) {
      yield put({
        type: GET_REFERRALS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REFERRALS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function sendReferralByEmail(payload) {
  return await Axios.post(`/referrals/invite`, payload);
}
function* handleSendReferralByEmail({ payload }) {
  try {
    const response = yield call(sendReferralByEmail, payload);
    if (response) {
      yield put({
        type: SEND_REFERRAL_EMAIL_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SEND_REFERRAL_EMAIL_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_REFERRALS_REQUEST, handleGetReferrals),
  takeLatest(GET_REFERRALS_WITH_CODE_REQUEST, handleGetReferralsWithCode),
  takeLatest(SEND_REFERRAL_EMAIL_REQUEST, handleSendReferralByEmail),
]);
