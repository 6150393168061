import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { ReactComponent as CreditCard } from 'assets/icons/CreditCard.svg';
import { ReactComponent as ReceiptIcon } from 'assets/icons/Receipt.svg';
import { ReactComponent as Resend } from 'assets/icons/resend.svg';
import classNames from 'classnames';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import RoleParagraphs from 'components/UI/CustomRoleSelect/RoleParagraphTruncateor';
import CustomSelect from 'components/UI/CustomSelect';
import Modal from 'components/UI/Modal';
import MultipleSelect from 'components/UI/MultipleSelect';
import CustomPopover from 'components/UI/Popover';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteBeneficiary,
  resendBeneficiaryInvite,
  updateBeneficiaries,
} from 'redux/actions/BeneficiariesAction';
import {
  deleteBudgetBeneficiary,
  getSingleBudgets,
  updateBudgetBeneficiarySettings,
} from 'redux/actions/BudgetsAction';
import { getRoles } from 'redux/actions/RolesAction';
import AllowedTo, { allPermissions, hasPermission } from 'utils/AllowedTo';
import { capitalizeFirstLetter } from 'utils/helper';

const BeneficiariesTableModal = ({
  isDisable,
  selectBeneficiaries,
  setSelectBeneficiaries,
  selectedBudget = {},
  isBudgetBeneficiary,
}) => {
  const [modalForm, setModalForm] = useState({
    name: null,
    status: {},
    role: {},
    email: '',
    budgets: '',
    totalSpent: 0,
    code: '',
    currency: 'NGN',
    amount: '',
    isBudgetOwner: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getBudget: { data: budgetData = {} },
    deleteBudgetBeneficiaries: { loading: isLoading },
    updateBudgetBeneficiarySettings: {
      loading: loadingBudgetSetting,
      success: successBudgetSetting,
    },
  } = useSelector(({ budgets }) => budgets);

  const {
    updateBeneficiaries: { loading, success },
    getBeneficiaries: { data: beneficiariesData = {} },
    deleteBeneficiaries: { loading: isDeleting, error: isError, success: deleteSuccess },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { beneficiaries = [] } = beneficiariesData;

  const otherUsers = beneficiaries.map(
    ({ user: { code, role, firstName, lastName } }) => ({
      value: role?.name,
      code,
      label: `${firstName} ${lastName}`,
    }),
  );

  const { budgets = [] } = budgetData;

  const {
    getRoles: { data: rolesData = [], loading: isFetchingRoles, success: isRolesFetched },
  } = useSelector(({ roles }) => roles);

  const { permissions } = allPermissions();
  const canEdit = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-edit'],
  });

  const [roleOptions, setRoleOptions] = useState([]);
  // const [otherUsers, setOtherUsers] = useState([]);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleSave = () => {
    const payload = {
      status: modalForm.status.value,
      role: modalForm.role.code,
      manager: modalForm.manager && modalForm.manager.code,
      code: modalForm.code,
      budgets: modalForm.budgets.map((b) => {
        return b.value;
      }),
    };

    const settingsPayload = {
      amount: modalForm.amount * 100,
      isBudgetOwner: modalForm.isBudgetOwner?.code === 'owner',
      code: modalForm.budgetCode,
      bnfCode: modalForm.code,
    };

    if (
      modalForm.status.value !== selectBeneficiaries?.status.value.toLowerCase() &&
      modalForm.status.value === 'deleted'
    ) {
      if (selectedBudget.value) {
        dispatch(
          deleteBudgetBeneficiary({
            budgetsCode: selectedBudget?.value,
            beneficiariesCode: selectBeneficiaries?.code,
          }),
        );
      } else {
        const payload = { code: selectBeneficiaries?.code };
        dispatch(deleteBeneficiary(payload));
      }
    } else {
      dispatch(updateBeneficiaries(payload));
    }
    if (isBudgetBeneficiary) dispatch(updateBudgetBeneficiarySettings(settingsPayload));
  };
  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);
  const closeHandler = () => {
    if (!isPopoverOpen) setSelectBeneficiaries(null);
  };
  const getSelectedStates = (val) => {
    switch (val) {
      case 'Active':
        return 'active';
      case 'Invited':
        return 'invited';
      case 'Deleted':
        return 'deleted';
      case 'Inactive':
        return 'inactive';
    }
  };

  const rolesOptionMapper = (data) => {
    return setRoleOptions(
      data?.map((datum) => {
        return {
          label: datum?.name,
          value: datum?.description ?? <RoleParagraphs datum={datum} />,
          code: datum?.code,
        };
      }),
    );
  };

  useEffect(() => {
    if (!isFetchingRoles && !rolesData?.length) return dispatch(getRoles());
    return rolesOptionMapper(rolesData);
  }, [rolesData, isFetchingRoles]);

  const ResendInvite = () => {
    dispatch(resendBeneficiaryInvite(selectBeneficiaries?.code));
  };
  useEffect(() => {
    if (selectBeneficiaries) {
      const { name, assignedBudget, budgets = [], user = {} } = selectBeneficiaries;
      const budgetsArray = budgets
        .filter((item) => ['active'].includes(item.status.toLowerCase()))
        .map((i) => {
          return {
            value: i.code,
            label: i.name,
          };
        });

      const init = {
        name,
        email: user?.email,
        status: {
          value: getSelectedStates(selectBeneficiaries.status.value),
          label: capitalizeFirstLetter(selectBeneficiaries.status.value),
        },
        role: {
          value: selectBeneficiaries.beneficiaryData.user?.role?.code,
          label: capitalizeFirstLetter(
            selectBeneficiaries.beneficiaryData.user?.role?.name,
          ),
        },
        code: selectBeneficiaries.beneficiaryData.code,
        budgets: [...budgetsArray],
      };

      if (isBudgetBeneficiary) {
        init.amount = selectBeneficiaries?.budgets[0]?.amount / 100;
        init.currency = 'NGN';
        init.isBudgetOwner = selectBeneficiaries?.budgets[0]?.isBudgetOwner;
        init.budgetCode = selectBeneficiaries?.budgets[0]?.code;
      }

      if (selectedBudget.value) init.budgets = [selectedBudget];
      setModalForm(init);
    }
  }, [selectBeneficiaries]);

  useEffect(() => {
    if (isError.length || deleteSuccess || success || successBudgetSetting) {
      if (modalForm.budgetCode) dispatch(getSingleBudgets({ id: modalForm.budgetCode }));
      return setSelectBeneficiaries(null);
    }
  }, [isError, deleteSuccess, success, successBudgetSetting]);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <AllowedTo scopes={['transaction-*', 'transaction-view']}>
          <div
            onClick={() => {
              history.push(`/transactions?search=${selectBeneficiaries?.user?.code}`);
              handleTogglePopover();
            }}
            className="actionLink"
          >
            <ReceiptIcon />
            <span className="ps-1">View transactions</span>
          </div>
        </AllowedTo>
        <AllowedTo scopes={['card-*', 'card-view']}>
          <div
            onClick={() => {
              history.push(`/cards?payer=${selectBeneficiaries?.code}`);
              handleTogglePopover();
            }}
            className="actionLink"
          >
            <CreditCard />
            <span className="ps-1">View cards</span>
          </div>
        </AllowedTo>
        {['invited', 'pending'].includes(
          selectBeneficiaries?.status?.value.toLowerCase(),
        ) &&
          canEdit && (
            <div
              onClick={() => {
                ResendInvite();
                handleTogglePopover();
              }}
              className="actionLink"
            >
              <Resend />
              <span className="ps-1">Resend invite</span>
            </div>
          )}
      </div>
    );
  };

  //i was going to write a function that iterates this options, and check if the status is same with the selected one,
  //then disable the neccessary optins
  let options = [
    {
      value: 'active',
      label: 'Active',
      isDisabled: ['invited', 'active', 'deleted'].includes(
        selectBeneficiaries?.status?.value.toLowerCase(),
      ),
    },

    {
      value: 'deleted',
      label: 'Deleted',
      isDisabled: ['deleted'].includes(selectBeneficiaries?.status?.value.toLowerCase()),
    },
    {
      value: 'inactive',
      label: 'Inactive',
      isDisabled: ['inactive', 'invited', 'deleted'].includes(
        selectBeneficiaries?.status?.value.toLowerCase(),
      ),
    },
  ];

  const handleChange = (data) => {
    const {
      target: { name, value, rawValue },
    } = data;
    setModalForm({ ...modalForm, [name]: rawValue ? rawValue : value });
  };

  const handleGetSign = (currency) => {
    !isDisable && setModalForm({ ...modalForm, currency });
  };

  const budgetMemeberRole = [
    {
      label: 'Member',
      value: (
        <div className="mt-2 owners-tooltip">
          <h6>As a budget member you can:</h6>
          <span>Request funding, view budget history, and make payment.</span>
        </div>
      ),
      code: 'member',
    },
    {
      label: 'Owner',
      value: (
        <div className="owners-tooltip  mt-2">
          <h6>The budget owner manages the budget and can:</h6>
          <span>
            Request funding, approve/decline payments, view budget history, manage members
            added to budget.
          </span>
          <h6 className="mt-2">The budget owner cannot:</h6>
          <span>
            Delete the budget, create policies, delete budget history, add a budget owner,
            delete budget creator.
          </span>
        </div>
      ),
      code: 'owner',
    },
  ];

  if (!selectBeneficiaries) return <div />;
  return (
    <Modal show={selectBeneficiaries} onClose={closeHandler}>
      <div className="content px-md-0 py-md-0 px-3 py-4">
        <div className="card-modal-header d-flex flex-row justify-space-between align-items-center">
          <div className="d-flex align-items-center cursor" onClick={closeHandler}>
            <CloseOutlined />
            <span className="ps-1">Close</span>
          </div>
          <div>
            <CustomPopover
              content={<Actions />}
              showPopover={isPopoverOpen}
              clickOutside={handleTogglePopover}
            >
              <div onClick={handleTogglePopover} className="cursor">
                <MoreOutlined />
              </div>
            </CustomPopover>
          </div>
        </div>
        <div className="card-modal-body">
          <div className="information-wrapper">
            <h2 className="card-title w-100">{selectBeneficiaries?.name?.value}</h2>
            <Row className="mb-3">
              <CustomInput
                label="Name"
                placeholder="Enter member name"
                type="text"
                name="name"
                value={modalForm.name?.value}
                onChange={({ target: { value } }) =>
                  setModalForm({ ...modalForm, name: value })
                }
                required
                defaultValue={selectBeneficiaries?.name}
                disabled={modalForm.name || !canEdit}
              />
            </Row>
            <Row className="mb-3 mt-3">
              <CustomSelect
                label="Status"
                name="status"
                defaultValue={{
                  value: selectBeneficiaries?.status?.value,
                  label: capitalizeFirstLetter(selectBeneficiaries?.status?.value),
                }}
                options={options}
                onChange={(val) => setModalForm({ ...modalForm, status: val })}
                isDisabled={!canEdit}
              />
            </Row>
            <Row className="mb-3">
              <CustomInput
                label="Email address"
                placeholder="Enter email address"
                type="email"
                name="email"
                value={modalForm.email}
                onChange={({ target: { value } }) =>
                  setModalForm({ ...modalForm, email: value })
                }
                required
                defaultValue={selectBeneficiaries?.email}
                disabled={modalForm.email || !canEdit}
              />
            </Row>

            {!isBudgetBeneficiary ? (
              <Row className="mb-3">
                <CustomRoleSelect
                  label="Their role"
                  options={roleOptions}
                  placeholder="Member roles"
                  name="beneficiary"
                  onChange={(val) => setModalForm({ ...modalForm, role: val })}
                  defaultValue={{
                    value: selectBeneficiaries?.role?.value,
                    label: capitalizeFirstLetter(selectBeneficiaries?.role?.value),
                  }}
                  isDisabled={!canEdit}
                  truncateClass={'limit-paragraph'}
                />
              </Row>
            ) : (
              <Row className="mb-3">
                <CustomRoleSelect
                  label="Their role"
                  options={budgetMemeberRole}
                  placeholder="Member roles"
                  name="beneficiary"
                  onChange={(val) => setModalForm({ ...modalForm, isBudgetOwner: val })}
                  defaultValue={{
                    value: modalForm?.isBudgetOwner ? 'Owner' : 'Member',
                    label: capitalizeFirstLetter(
                      modalForm?.isBudgetOwner ? 'Owner' : 'Member',
                    ),
                  }}
                  isDisabled={!canEdit}
                  truncateClass={'limit-paragraph'}
                />
              </Row>
            )}

            <Row className="mb-3">
              <CustomRoleSelect
                label="Line manager"
                options={otherUsers}
                placeholder="Select a manager"
                name="manager"
                onChange={(val) => setModalForm({ ...modalForm, manager: val })}
                defaultValue={{
                  value: selectBeneficiaries?.manager?.code,
                  label: capitalizeFirstLetter(selectBeneficiaries?.manager?.value),
                }}
                isDisabled={!canEdit}
              />
            </Row>
            <Row className={classNames(isBudgetBeneficiary ? 'mb-3' : 'mb-5')}>
              <MultipleSelect
                isDisabled={isDisable || !canEdit}
                placeholder="Budgets"
                label="Budgets"
                name="budgets"
                options={budgets
                  .filter((item) => ['active'].includes(item.status.toLowerCase()))
                  .map((bgt) => ({
                    value: bgt.code,
                    label: bgt.name,
                  }))}
                onChange={(val) => setModalForm({ ...modalForm, budgets: val })}
                value={modalForm.budgets}
                defaultValue={selectedBudget}
              />
            </Row>

            {isBudgetBeneficiary && (
              <>
                <Row className="mb-5">
                  <CustomInput
                    placeholder="0.00"
                    type="number"
                    label="Spending limit"
                    isAmount
                    otherCurrency={true}
                    name="amount"
                    onChange={handleChange}
                    value={modalForm.amount}
                    getTypeVal={handleGetSign}
                    existAction={modalForm.currency || null}
                    isUpdate={isDisable}
                  />
                </Row>
                {/* <div className="d-flex align-items-start gap-2 w-100 my-3 ">
                  <Checkbox
                    onChange={(event) =>
                      setModalForm({ ...modalForm, isBudgetOwner: event.target.checked })
                    }
                    checked={modalForm?.isBudgetOwner}
                    style={{ marginTop: 6 }}
                  ></Checkbox>
                  <div className="d-flex flex-column w-100 fs-6">
                    <TooltipContent
                      tooltip={
                        <div className="p-2 owners-tooltip">
                          <h6>The budget owner manages the budget and can:</h6>
                          <span>
                            Request funding, approve/decline payments, view budget
                            history, manage members added to budget.
                          </span>
                          <h6 className="mt-2">The budget owner cannot:</h6>
                          <span>
                            Delete the budget, create policies, delete budget history, add
                            a budget owner, delete budget creator.
                          </span>
                        </div>
                      }
                    >
                      <span className="mt-1">Make them a budget owner</span>
                    </TooltipContent>
                  </div>
                </div> */}
              </>
            )}

            <div className="modal-footer mt-3">
              {canEdit && (
                <>
                  <CustomButton
                    onClick={closeHandler}
                    fullWidth={true}
                    className="custom-button ghost-button"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    onClick={handleSave}
                    fullWidth={true}
                    className="custom-button primary-button"
                    loading={loading || isDeleting || isLoading || loadingBudgetSetting}
                    disabled={loading || isDeleting || isLoading || loadingBudgetSetting}
                  >
                    Save
                  </CustomButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default BeneficiariesTableModal;
