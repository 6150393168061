import React from 'react';
import { getFormattedDate } from 'utils/helper';
import { TRIGGER_STATE } from './PendingApprovalModal';

export const approvalState = ({ approvalData, relatedData, user: userData }) => {
  const triggerMap = {
    reimbursement: approvalData?.reimbursement,
    batchTransactions: approvalData?.batchTransactions,
    invoice: approvalData?.invoice,
  };

  let triggerType = 'transaction';
  for (const [type, value] of Object.entries(triggerMap)) {
    if (value !== null) {
      triggerType = type;
      break;
    }
  }

  const dataInfo = approvalData[triggerType] ?? {};

  const approvers = [];
  relatedData?.relatedApprovalRequests?.forEach((relations) => {
    const rule = relations.rule;
    relations.approvalStages.forEach((stages) => {
      if ([1, 'all'].includes(stages.threshold)) {
        approvers.push({
          rule,
          status: 'pending',
          message: '',
          type: stages.threshold,
          approvers: stages?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        stages.approvers.forEach((item) => {
          approvers.push({
            rule,
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });

    if (relations.approvals.length) {
      relations.approvals.forEach((item) => {
        approvers.push({
          rule,
          user: item?.approver?.user,
          date: getFormattedDate(item?.updated_at),
          status: item?.status,
          message: item?.reason ?? '',
        });
      });
    }
  });

  let alreadyApprovedUser = null;
  approvers.forEach((item) => {
    if (item.status === 'pending') {
      item.approvers.forEach((approver) => {
        if (approver?.user?.code === userData?.user?.code) {
          alreadyApprovedUser = false;
        }
      });
    }
    if (item.status === 'approved' && item?.user.code === userData?.user?.code) {
      alreadyApprovedUser = true;
    }
  });

  const approvalRequestUser = dataInfo[TRIGGER_STATE[triggerType]];
  const hasManager =
    userData?.user?.code === approvalRequestUser?.code && userData?.user?.manager?.code;

  let yourTurnToApprove = null;

  (approvers.length &&
    approvers.forEach((stage) => {
      stage?.approvers?.forEach((item) => {
        if (
          stage.status === 'pending' &&
          item?.user?.code === userData?.user?.code &&
          alreadyApprovedUser === false
        ) {
          yourTurnToApprove = true;
        }
      });
    })) ||
    (approvalData?.status === 'pending' && !approvalData?.code && !hasManager);

  const checkConditions = (approvers) => {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === userData?.user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === userData?.user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  };

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  return { canApprovePay, yourTurnToApprove };
};
