import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import CustomInput from 'components/UI/CustomInput';
import MultipleSelect from 'components/UI/MultipleSelect';
import TooltipContent from 'components/UI/tooltip-content';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, getInitials } from 'utils/helper';
import './styles.scss';
const UpdateAllocation = ({
  setIsOtherPopoverOpen,
  budgetInfo,
  setBudgetInfo,
  beneficiariesAllocation,
  setAmountSum,
  splittingRule,
  setSplittingRule,
}) => {
  const dispatch = useDispatch();
  const { permissions } = allPermissions();
  const canViewEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view'],
  });
  const {
    getBeneficiaries: { data = {}, loading: beneficiaryLoading },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { beneficiaries = [] } = data;

  const { owners, beneficiaries: members } = budgetInfo;

  const seletedOwners = owners ? owners?.map((item) => item.value) : [];
  const seletedMembers = members ? members?.map((item) => item.value) : [];

  const beneficiaryList = beneficiaries
    .filter(
      ({ code }) => !seletedOwners?.includes(code) && !seletedMembers?.includes(code),
    )
    .map(({ user, code }) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: code,
    }));

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const handleSelectChange = (val) => setValue(val);

  useEffect(() => {
    if (!beneficiaries.length && canViewEmployee) dispatch(getBeneficiaries());
  }, []);

  useEffect(() => {
    if (Object.keys(beneficiariesAllocation).length > 0) {
      setAmountSum(
        Object.keys(beneficiariesAllocation).reduce(
          (sum, key) => sum + parseFloat(beneficiariesAllocation[key] || 0),
          0,
        ),
      );
    }
  }, [beneficiariesAllocation]);

  useEffect(() => {
    const beneficiaries = beneficiaryList?.find((option) =>
      option?.label?.toLowerCase()?.includes(debouncedValue?.toLowerCase()),
    );

    if (!beneficiaries && debouncedValue) {
      dispatch(getBeneficiaries({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  const handleBeneficiarySelect = (value, name) => {
    setBudgetInfo({
      ...budgetInfo,
      [name]: value,
    });
  };

  const onMenuClose = () => {
    if (debouncedValue) dispatch(getBeneficiaries());
  };

  const handleSelectAllBeneficiary = (list, name) => {
    const newList =
      budgetInfo?.beneficiaries?.length === beneficiaryList?.length ? [] : list;
    setBudgetInfo({
      ...budgetInfo,
      [name]: newList,
    });
  };

  const handleGetSign = (currency) => {
    setBudgetInfo({ ...budgetInfo, currency });
  };

  const onChangeAmount = (event, index) => {
    const { value, rawValue } = event.target;
    const data = budgetInfo?.beneficiaries;
    data[index].amount = rawValue ?? value;
    setBudgetInfo({
      ...budgetInfo,
      beneficiaries: data,
    });
  };

  const onChangeOwnersAmount = (event, index) => {
    const { value, rawValue } = event.target;
    const data = budgetInfo?.owners;
    data[index].amount = rawValue ?? value;
    setBudgetInfo({
      ...budgetInfo,
      owners: data,
    });
  };

  useEffect(() => {
    if (!splittingRule && budgetInfo?.beneficiaries.length) {
      const newBeneficiary = budgetInfo?.beneficiaries?.map((item) => {
        return {
          value: item?.value,
          label: item?.label,
        };
      });
      setBudgetInfo({ ...budgetInfo, beneficiaries: newBeneficiary });
    }
  }, [splittingRule]);

  const isLengthEqual = budgetInfo?.beneficiaries?.length === beneficiaryList?.length;

  const formatSelectedOptionLabel = (selectedOption) => {
    if (!selectedOption) {
      return null;
    }
    return (
      <div className="d-flex align-items-center gap-2">
        <div className="avatar__card-initials">
          <h5>{getInitials(null, null, selectedOption.label)}</h5>
        </div>
        <div>{selectedOption.label}</div>
      </div>
    );
  };

  return (
    <div>
      <Row className="mt-3">
        <MultipleSelect
          label={
            <TooltipContent
              tooltip={
                <div className="p-2 owners-tooltip">
                  <h6>The budget owner manages the budget and can:</h6>
                  <span>
                    Request funding, approve/decline payments, view budget history, manage
                    members added to budget.
                  </span>
                  <h6 className="mt-2">The budget owner cannot:</h6>
                  <span>
                    Delete the budget, create policies, delete budget history, add a
                    budget owner, delete budget creator.
                  </span>
                </div>
              }
            >
              <span className="mt-1">Owners</span>
            </TooltipContent>
          }
          placeholderText="Select owners"
          name="owners"
          options={beneficiaryList}
          onChange={(value) => handleBeneficiarySelect(value, 'owners')}
          value={budgetInfo?.owners}
          onInputChange={handleSelectChange}
          extendComponent={
            <span className="d-flex align-items-center gap-2">
              {isLengthEqual ? <MinusOutlined /> : <PlusOutlined />}
              {isLengthEqual ? 'Unselect all owners' : 'Select all owners'}
            </span>
          }
          selectAllBudgets={() => handleSelectAllBeneficiary(beneficiaryList, 'owners')}
          isLoading={beneficiaryLoading}
          formatOptionLabel={formatSelectedOptionLabel}
          onMenuClose={onMenuClose}
        />
      </Row>
      <Row className="mt-3">
        <MultipleSelect
          label={
            <TooltipContent
              tooltip={
                <div className="p-2 owners-tooltip">
                  <h6>As a budget member you can:</h6>
                  <span>Request funding, view budget history, and make payment.</span>
                </div>
              }
            >
              <span className="mt-1">Members</span>
            </TooltipContent>
          }
          placeholderText="Select members"
          name="benficiary"
          options={beneficiaryList}
          onChange={(value) => handleBeneficiarySelect(value, 'beneficiaries')}
          value={budgetInfo?.beneficiaries}
          onInputChange={handleSelectChange}
          extendComponent={
            <span className="d-flex align-items-center gap-2">
              {isLengthEqual ? <MinusOutlined /> : <PlusOutlined />}
              {isLengthEqual ? 'Unselect all members' : 'Select all members'}
            </span>
          }
          selectAllBudgets={() =>
            handleSelectAllBeneficiary(beneficiaryList, 'beneficiaries')
          }
          isLoading={beneficiaryLoading}
          formatOptionLabel={formatSelectedOptionLabel}
          onMenuClose={onMenuClose}
        />
      </Row>
      <Row className="mt-4 splitting-rules">
        <div>
          <div className="mb-3">
            <h6>Split Budget</h6>
            <span>
              Define the budget allocation and indicate how much your beneficiaries will
              be able to use.
            </span>
          </div>
          <Checkbox
            checked={splittingRule}
            onChange={(event) => setSplittingRule(event.target.checked)}
          >
            Set splitting rules
          </Checkbox>
        </div>
      </Row>
      <Row className="mt-3 split-users">
        {splittingRule && (
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span>Available to assign (Budget goal)</span>
            <span className="amount">
              <CurrencyFormat
                value={Number(budgetInfo?.amount ?? 0)}
                thousandSeparator={true}
                displayType="text"
                prefix={getCurrency('NGN')}
              />
            </span>
          </div>
        )}
        {splittingRule && (
          <>
            {budgetInfo?.owners?.map((item, index) => {
              return (
                <div className="mb-2" key={item.value}>
                  <CustomInput
                    placeholder="0.00"
                    type="number"
                    isAmount
                    label={item?.label}
                    otherCurrency={true}
                    name="amount"
                    onChange={(event) => onChangeOwnersAmount(event, index)}
                    value={item.amount}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    getTypeVal={handleGetSign}
                    existAction={budgetInfo.currency || null}
                    // isUpdate={isUpdate}
                  />
                </div>
              );
            })}

            {budgetInfo?.beneficiaries?.map((item, index) => {
              return (
                <div className="mb-2" key={item.value}>
                  <CustomInput
                    placeholder="0.00"
                    type="number"
                    isAmount
                    label={item?.label}
                    otherCurrency={true}
                    name="amount"
                    onChange={(event) => onChangeAmount(event, index)}
                    value={item.amount}
                    setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                    getTypeVal={handleGetSign}
                    existAction={budgetInfo.currency || null}
                    // isUpdate={isUpdate}
                  />
                </div>
              );
            })}
          </>
        )}
      </Row>
    </div>
  );
};
export default UpdateAllocation;
