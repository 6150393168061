/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from 'redux/actions/CompaniesAction';

import Overview from './Overview';
import './styles.scss';

const Cash = () => {
  const { user } = useSelector(({ auth }) => auth);

  const {
    getCompany: { data: companyData = {}, success, loading },
  } = useSelector(({ companies }) => companies);

  const [company, setCompany] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (companyData && companyData.code) setCompany(companyData);
  }, [companyData]);

  useEffect(() => {
    const companyCode = user?.data?.user?.company?.code;

    if (!companyData?.code && companyCode) {
      dispatch(
        getCompany({ code: companyCode, includeDocuments: true, includeSteps: true }),
      );
    }
  }, [user]);

  return (
    <div className="dashboard-wrapper">
      {/*//TODO: NotificationModal testing view*/}
      {/*<NotificationModal />*/}

      <Container>
        <Overview company={company} />
      </Container>
    </div>
  );
};
export default Cash;
