import React from 'react';
import cs from 'classnames';
import { Col, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import './styles.scss';
import { theStyle } from '../CustomSelectRadio';

const animatedComponents = makeAnimated();

const Menu = (props, label) => {
  const {
    selectProps: { addBeneficiary, extendComponent, selectAllBudgets },
    children,
  } = props;

  let styles = ['budgets', 'beneficiary', 'beneficiaries', 'managers'].includes(
    label?.toLowerCase(),
  )
    ? 'd-flex flex-column-reverse'
    : '';
  let btnClassName = ['budgets', 'beneficiary', 'beneficiaries', 'managers'].includes(
    label?.toLowerCase(),
  )
    ? 'select-all-budgets '
    : 'add-beneficiary';
  return (
    <components.Menu {...props}>
      <div className={styles}>
        <div>{children}</div>
        {extendComponent && (
          <button
            className={btnClassName}
            onClick={
              ['budgets', 'beneficiary', 'beneficiaries', 'owners', 'managers'].includes(
                label?.toLowerCase(),
              )
                ? selectAllBudgets
                : addBeneficiary
            }
          >
            {extendComponent}
          </button>
        )}
      </div>
    </components.Menu>
  );
};

const Option = (props) => {
  const { children } = props;
  return <components.Option {...props}>{children}</components.Option>;
};

const MultipleSelect = ({
  addBeneficiary = () => null,
  selectAllBudgets,
  closeMenuOnSelect = false,
  isMulti = true,
  md = 12,
  label = '',
  wrapperClass = '',
  defaultValue,
  extendComponent,
  formatOptionLabel,
  name,
  ...res
}) => {
  return (
    <div>
      <Form.Group
        as={Col}
        md={md}
        className={cs('groupWrapper form-group custom', { [wrapperClass]: wrapperClass })}
      >
        {label && <Form.Label className="form-label">{label}</Form.Label>}
        <Select
          defaultValue={defaultValue}
          closeMenuOnSelect={closeMenuOnSelect}
          components={
            extendComponent
              ? { Menu: (props) => Menu(props, name), Option, animatedComponents }
              : { animatedComponents }
          }
          addBeneficiary={addBeneficiary}
          classNamePrefix="dropdown-select"
          selectAllBudgets={selectAllBudgets}
          extendComponent={extendComponent}
          isMulti={isMulti}
          styles={theStyle()}
          maxMenuHeight={210}
          formatOptionLabel={formatOptionLabel}
          {...res}
        />
      </Form.Group>
    </div>
  );
};
export default MultipleSelect;
