import Table from 'components/Table';
import 'jspdf-autotable';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { customerTransactionColumns } from 'utils/mockData';
import { buildCustomerTransactionTableData } from '../../../utils/helper';
import NoData from './NoData';

import TableLoading from './TableLoading';

const CustomerTransactionTable = ({
  userName,
  meta = {},
  transactions = [],
  loading,
  handlePagination,
}) => {
  const { page, total, hasMore, perPage, nextPage } = meta;

  const rows = useMemo(
    () => buildCustomerTransactionTableData(transactions),
    [transactions],
  );

  return (
    <>
      {!loading && !transactions?.length ? (
        <div className="tabinnerWrapper">
          <NoData
            headerText="No transaction"
            bodyText={`${userName} does not have any transaction yet`}
            withButton={true}
            buttonLabel={
              <span className="d-flex justify-content-center">Show all Transactions</span>
            }
            // onClickHandler={clearLocationState}
          />
        </div>
      ) : (
        <Container className="px-0">
          {loading ? (
            <TableLoading />
          ) : (
            <Row className="py-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={customerTransactionColumns}
                  data={rows}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handlePagination(nextPage)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
};

export default CustomerTransactionTable;
