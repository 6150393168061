/* eslint-disable no-console */
import { Checkbox } from 'antd';
import { ArrowRight } from 'assets/icons';
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CheckPasswordStrength from 'components/UI/PasswordStrength';
import { toastError } from 'components/UI/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  setBeneficiaryPassword,
  setInvitedUserPasswordAction,
  signup,
  verifyInvitedUserAction,
} from 'redux/actions/AuthAction';
import { getInvitedBeneficiaryDetails } from 'redux/actions/BeneficiariesAction';
import { validateNigeriaNumber } from 'utils/helper';
import { sliderContents, stepsObject } from '../config';
import Layout, { OverflowWrapLayout } from '../Layout';

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [validatePassword, setValidatePassword] = useState(false);
  const [hasReferralCode, sethasReferralCode] = useState(false);
  const [userHash, setUserHash] = useState('');
  const [userToken, setUserToken] = useState('');
  const [authData, setAuthData] = React.useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    internationalFormat: '',
    localFormat: '',
    countryCode: '',
    email: '',
    password: '',
    confirm_password: '',
    referral: undefined,
    agreement: false,
  });

  const {
    signup: { success, loading },
    verifyInvitedUser: { data: invitedUser },
    login: { loading: loadingBeneficiaryLogin },
  } = useSelector(({ auth }) => auth);

  const {
    getInvitedBeneficiaryDetails: { data },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  useEffect(() => {
    if (success) {
      history.push({
        pathname: '/verification',
        state: { authData: authData.email },
      });
    }
  }, [success]);

  const hashID = searchParams.get('hash');
  const tokenID = searchParams.get('token');
  const referralCode = searchParams.get('referralCode');

  useEffect(() => {
    if (hashID) dispatch(getInvitedBeneficiaryDetails(hashID));
    if (tokenID) dispatch(verifyInvitedUserAction(tokenID));
    if (referralCode) {
      setAuthData({ ...authData, referral: referralCode });
      sethasReferralCode(true);
    }
  }, []);

  useMemo(() => {
    if (data) {
      setAuthData({
        ...authData,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      });
      setUserHash(hashID);
    }
    if (invitedUser) {
      setUserToken(tokenID);
    }
  }, [data, invitedUser]);

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setAuthData({ ...authData, internationalFormat, localFormat, countryCode });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!authData.firstName) return toastError('Please enter your first name');
    if (!authData.lastName) return toastError('Please enter your last name');
    if (
      !isValidPhoneNumber(String(authData.internationalFormat)) ||
      !validateNigeriaNumber(String(authData.internationalFormat))
    )
      return toastError('Please enter a valid phone number');
    if (!authData.email) return toastError('Please enter your email');
    if (!authData.password) return toastError('Please enter your password');
    if (!authData.confirm_password) return toastError('Please enter confirm password');
    if (!validatePassword) return toastError('Please enter a strong password');
    if (authData.password !== authData.confirm_password) {
      return toastError('Your password does not match');
    }
    if (!authData.agreement) return toastError('Please accept terms and conditions');

    if (hashID && userHash) {
      dispatch(
        setBeneficiaryPassword({
          hash: userHash,
          password: authData.password,
          confirm_password: authData.confirm_password,
        }),
      );
      return false;
    } else if (tokenID && userToken) {
      dispatch(
        setInvitedUserPasswordAction({
          token: userHash,
          email: authData.email,
          password: authData.password,
          confirm_password: authData.confirm_password,
          ...(authData.referral && { referral: authData.referral }),
        }),
      );
      return false;
    } else {
      const { internationalFormat, countryCode, localFormat, agreement, ...rest } =
        authData;
      const payload = {
        ...rest,
        phoneNumber: { countryCode, localFormat },
      };
      dispatch(signup(payload));
      return false;
    }
  };

  const toggleReferralInput = () => sethasReferralCode(!hasReferralCode);

  return (
    <Layout slider={sliderContents} menus={stepsObject('sign-up')}>
      <AuthFormHeader
        title="Create your account"
        subtitle="Sign up now to begin your 14-days free trial and unlock the full potential of Bujeti's features."
      />
      <OverflowWrapLayout>
        <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
          <div>
            <Row className="mb-3">
              <CustomInput
                label="First name"
                error="enter valid first name"
                placeholder="Enter name"
                value={authData.firstName}
                required
                name="firstName"
                onChange={handleChange}
                md={6}
              />
              <CustomInput
                label="Last name"
                error="enter valid last name"
                placeholder="Enter name"
                value={authData.lastName}
                required
                name="lastName"
                onChange={handleChange}
                md={6}
              />
            </Row>
            <Row className="mb-3">
              <CustomPhoneNumberInput
                label="Phone number"
                placeholder="Enter number"
                onChange={(localFormat, international, countryCode) =>
                  handlePhoneNumberChange(localFormat, international, countryCode)
                }
                value={authData.internationalFormat}
              />
            </Row>
            <Row className="mb-2">
              <CustomInput
                label="Work email?"
                error="enter valid email"
                placeholder="Enter email"
                value={authData.email}
                type="email"
                required
                name="email"
                onChange={handleChange}
              />
            </Row>
            <Row className="mb-2">
              <CustomInput
                label="Password"
                error="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                placeholder="Enter password"
                type="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                value={authData.password}
                onChange={handleChange}
                name="password"
                maxlength={70}
                required
              />

              <CheckPasswordStrength
                password={authData.password}
                validated={validatePassword}
                validate={setValidatePassword}
              />
            </Row>

            {/* <Row className="mb-2"></Row> */}

            <Row className="mb-2">
              <CustomInput
                label="Confirm password"
                placeholder="Confirm password"
                type="password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={handleChange}
                value={authData.confirm_password}
                name="confirm_password"
                maxlength={70}
                required
              />
            </Row>

            {hasReferralCode && (
              <Row className="mb-2">
                <CustomInput
                  label="Referral code (Optional)"
                  placeholder="INSIGHTS17"
                  type="text"
                  onChange={handleChange}
                  value={authData.referral}
                  name="referral"
                  maxlength={10}
                  disabled={!!referralCode}
                />
              </Row>
            )}

            {!data && (
              <div className="d-flex align-items-start align-istems-center gap-2 w-100 mt-3 ">
                <Checkbox
                  checked={hasReferralCode}
                  onClick={toggleReferralInput}
                  id="refcode"
                  name="refcode"
                ></Checkbox>
                <label htmlFor="refcode" className="d-flex w-100 text-xs text-gray">
                  Have a referral code?
                </label>
              </div>
            )}

            <div className="d-flex align-items-start align-items-center gap-2 w-100 mb-3 mt-2">
              <Checkbox
                onChange={(event) =>
                  handleChange({
                    target: { name: 'agreement', value: event.target.checked },
                  })
                }
                id="agreement"
                name="agreement"
                // checked={authData?.rememberMe}
              ></Checkbox>
              <label htmlFor="agreement" className="w-100 text-xs text-gray">
                <div className="d-flex flex-row gap-1 flex-wrap">
                  I agree to Bujeti&apos;s{' '}
                  <a className="link-tag" href="https://www.bujeti.com/terms-of-use">
                    Terms of Use
                  </a>{' '}
                  and consent to Bujeti&apos;s{' '}
                  <a href="https://www.bujeti.com/privacy" className="link-tag">
                    Privacy Policy
                  </a>
                </div>
              </label>
            </div>
            <CustomButton
              type="submit"
              fullWidth
              loading={loading || loadingBeneficiaryLogin}
              disabled={loading || loadingBeneficiaryLogin}
            >
              Get started <ArrowRight color="#fff" className="ms-2" />
            </CustomButton>
          </div>

          <h3 className="back-line mt-4">
            <Link
              to="/login"
              className=" gap-2 d-flex align-items-center justify-content-center"
            >
              <div className="d-flex flex-row gap-1 flex-wrap">
                Already have an account?{' '}
                <span href="" className="link-tag">
                  Sign In <ArrowRight className="ms-2" color="#D28B28" />
                </span>
              </div>
            </Link>
          </h3>
        </Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default Register;
