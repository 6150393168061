import { DownloadIcon } from 'assets/icons';
import { PDFDownloadLink } from 'components/UIHooks/JsPDF';
import React, { useMemo } from 'react';
import BillingInvoicePdf from './BillingInvoicePdf';

const MemoizedInvoicePdf = React.memo(BillingInvoicePdf);

const DownloadPdfComponent = ({ invoiceSelected, companyData, pdfDownloadRef }) => {
  const documentData = useMemo(() => {
    return (
      <MemoizedInvoicePdf
        data={
          invoiceSelected && {
            ...invoiceSelected,
            company: { ...companyData },
          }
        }
      />
    );
  }, [invoiceSelected, companyData]);

  return (
    <>
      <div className="actionLink" style={{ display: 'none' }}>
        <PDFDownloadLink
          document={documentData}
          fileName={`Billing Invoice for ${companyData?.name}.pdf`}
          style={{
            textDecoration: 'none',
            color: '#212529',
          }}
        >
          <span ref={pdfDownloadRef} onClick={(event) => event?.stopPropagation()}>
            <DownloadIcon
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Download PDF
          </span>
        </PDFDownloadLink>
      </div>
    </>
  );
};

export default DownloadPdfComponent;
