import { MoreOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { memo, useState } from 'react';
import { ReactComponent as Edit } from 'assets/icons/Edit.svg';
import { ReactComponent as Trash } from 'assets/icons/trashcan.svg';
import cs from 'classnames';
import { DownloadArrowIcon, DownloadCloudIcon, DownloadIcon } from 'assets/icons';
import { ShareIcon } from '../../../assets/icons/index';
import CustomPopover from '../Popover';
import './styles.scss';

const ModifyTable = ({
  value,
  onDeleteClick,
  customClass,
  onEditClick,
  onShareClick,
  onCheckboxClick,
  onDownloadClick,
  withDownload = false,
  modify,
  withModify = true,
  withEdit = false,
  withDelete = false,
  withPopover = false,
  withShare = false,
  withCheckbox = false,
  custom = null,
  popoverAction: ListActions,
  setKebabId,
  kebabId,
  index,
}) => {
  const handleDeleteClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    onDeleteClick();
  };

  const handleEditClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    onEditClick();
  };

  const handleDownloadClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    onDownloadClick();
  };

  const handleShareClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    onShareClick();
  };

  const handleCheckboxClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    onCheckboxClick();
  };

  const handleTogglePopover = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (kebabId === index) return setKebabId('');

    setKebabId(index);
    // } else {
    //   setSelectedId(index);
    // }
  };

  const isDeletedStatus = value?.status?.value?.toLowerCase() === 'deleted';

  return (
    <>
      {withModify && (
        <span
          className={cs('d-flex align-items-center justify-content-center', {
            'disabled bg-transparent relative': !modify,
          })}
        >
          {withPopover && modify && (
            <CustomPopover
              content={<ListActions list={value && value} />}
              showPopover={index === kebabId}
              clickOutside={isDeletedStatus ? null : handleTogglePopover}
            >
              <div
                onClick={isDeletedStatus ? null : handleTogglePopover}
                className="more-outline cursor"
              >
                <MoreOutlined style={{ fontSize: '110%' }} />
              </div>
            </CustomPopover>
          )}
          {withDelete && onDeleteClick && (
            <>
              {custom ? (
                <span className={customClass()} onClick={handleDeleteClick}>
                  {custom}
                </span>
              ) : (
                <Trash className="mr-8" onClick={handleDeleteClick} />
              )}
            </>
          )}
          {withEdit && withEdit && <Edit onClick={handleEditClick} />}
          {withDownload && onDownloadClick && (
            <DownloadArrowIcon onClick={handleDownloadClick} />
          )}

          {withShare && (
            <span onClick={handleShareClick}>
              <ShareIcon />
            </span>
          )}
          {withCheckbox && (
            <Checkbox className="table-checkbox" onChange={handleCheckboxClick} />
          )}
        </span>
      )}
    </>
  );
};

export default memo(ModifyTable);
