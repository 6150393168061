import { MinusIcon } from 'assets/icons';
import classNames from 'classnames';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { capitalizeFirstLetter, flattenCategories } from 'utils/helper';

const createOption = (label) => ({
  label,
  value: label,
});

const ConditionRowComponent = ({
  rules,
  removeCondition,
  getAmount,
  getInputChange,
  handleConditionChange,
  disabled = false,
}) => {
  const {
    createCategories: { data: { category } = {}, loading, success },
    fetchCategories: { success: isCatSuccess, data: { categories: fetchCat = [] } = {} },
  } = useSelector(({ categories }) => categories);

  const {
    getBudget: { loading: loadingBudget },
  } = useSelector(({ budgets }) => budgets);

  const [newCategory, setNewCategory] = useState({
    val: { value: '', label: '', code: '' },
    index: '',
    type: '',
  });

  const [createable, setCreateable] = useState({
    isLoading: false,
    options: [],
    value: undefined,
  });
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const data = flattenCategories(fetchCat);
    const opt = data?.map(({ name: label, name: value, code }) => ({
      label,
      value,
      code,
    }));

    if (opt) setOptions(opt);
  }, [isCatSuccess]);

  useEffect(() => {
    setCreateable({ ...createable, options: options });
  }, [options]);

  // Handle Createable
  const handleCreate = (inputValue, index, type) => {
    setCreateable({ isLoading: true });
    setTimeout(() => {
      const { options } = createable;
      const newOption = createOption(inputValue);

      setCreateable({
        ...createable,
        isLoading: false,
      });
      const data = {
        name: inputValue,
      };
      dispatch(createCategories(data));

      setNewCategory({ val: { value: inputValue, label: inputValue }, index, type });
    }, 500);
  };

  const getSelectedCategory = (value, index, type) => {
    if (!value)
      return (
        setCreateable({ ...createable, value: undefined }),
        setNewCategory({ val: { value: value?.value, label: value?.label }, index, type })
      );

    setCreateable({ ...createable, value });
    handleConditionChange(value, index, type);
  };

  const handleActionType = (val, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        val = createable.options.filter((v) => v.isFixed);
        break;
    }
  };

  useEffect(() => {
    if (success) {
      dispatch(fetchCategories());

      const currentConditionRow = rules?.[newCategory.index];

      const isMultiSelect = ['any', 'not:any'].includes(
        currentConditionRow?.['condition-two']?.selectedValue?.value,
      );

      const conditionThree = currentConditionRow?.['condition-three']?.selectedValue;

      const newValue = isMultiSelect
        ? [
            ...(conditionThree
              ? Array.isArray(conditionThree)
                ? conditionThree
                : [conditionThree]
              : []),
            { ...newCategory.val, code: category.code },
          ]
        : { ...newCategory.val, code: category.code };

      handleConditionChange(newValue, newCategory.index, newCategory.type);
      setCreateable({
        ...createable,
        value: newValue,
        options: [...options, { ...newCategory.val, code: category.code }],
      });
      dispatch({ type: 'RESET_BLOCK_CATEGORY', blockType: 'createCategories' });
    }
  }, [success]);

  return (
    <>
      <span className="conditions-label">{capitalizeFirstLetter(rules?.[0]?.type)}</span>
      {rules?.map((rule, index) => {
        const isMultiple =
          rule?.['condition-two']?.selectedValue?.value?.indexOf('any') >= 0;
        return (
          <div
            className={`condition-information-wrapper ${index !== 0 ? 'mt-3' : ''}`}
            key={index}
          >
            <div
              className={classNames('d-flex flex-grow-1 gap-2', {
                ['flex-wrap']: isMultiple,
              })}
            >
              <div className={classNames('flex-1', { ['w-50']: isMultiple })}>
                <CustomSelect
                  name={rule.name}
                  placeholder={'Select'}
                  options={rule?.['condition-one'].options}
                  value={rule?.['condition-one'].selectedValue}
                  defaultValue={rule?.['condition-one'].selectedValue}
                  onChange={(val) => handleConditionChange(val, index, 'one')}
                  isDisabled={
                    disabled ||
                    (location?.state?.trigger &&
                      location?.state?.trigger ===
                        rule?.['condition-one'].selectedValue?.value)
                  }
                />
              </div>
              <div className={classNames('flex-1', { ['w-50']: isMultiple })}>
                <CustomSelect
                  name={rule.name}
                  placeholder="condition"
                  options={rule?.['condition-two'].options}
                  value={rule?.['condition-two'].selectedValue}
                  defaultValue={rule?.['condition-two'].selectedValue}
                  onChange={(val) => handleConditionChange(val, index, 'two')}
                  isDisabled={disabled}
                />
              </div>
              <div
                className={classNames('approval-amount', isMultiple ? 'w-100' : 'flex-1')}
              >
                {rule['condition-one'].selectedValue?.value === 'amount' ? (
                  <CustomInput
                    name={rule?.name}
                    placeholder="0.00"
                    type="number"
                    value={rule?.['condition-three']?.selectedValue?.value}
                    isAmount
                    onChange={(event) => getAmount(event, index)}
                    disabled={disabled}
                  />
                ) : rule['condition-one'].selectedValue?.value === 'category' ? (
                  <CustomSelect
                    name="condition"
                    createable
                    isMulti={isMultiple}
                    placeholder="condition"
                    defaultValue={rule?.['condition-three'].selectedValue}
                    noOptionsMessage={() => null}
                    isDisabled={
                      createable.isLoading ||
                      disabled ||
                      (location?.state?.trigger &&
                        !isMultiple &&
                        location?.state?.operands?.[index]?.[location?.state?.trigger]
                          ?.name === rule?.['condition-three'].selectedValue?.value)
                    }
                    isLoading={createable.isLoading}
                    options={createable.options ?? rule?.['condition-three'].options}
                    value={createable.value ?? rule?.['condition-three'].selectedValue}
                    onCreateOption={(val) => handleCreate(val, index, 'three')}
                    onChange={(val, actionMeta) => {
                      handleActionType(val, actionMeta);
                      getSelectedCategory(val, index, 'three');
                    }}
                  />
                ) : (
                  <CustomSelect
                    isMulti={isMultiple}
                    name="condition"
                    placeholder="condition"
                    options={rule?.['condition-three'].options}
                    value={rule?.['condition-three'].selectedValue}
                    defaultValue={rule?.['condition-three'].selectedValue}
                    onChange={(val) => handleConditionChange(val, index, 'three')}
                    onInputChange={(value) =>
                      getInputChange(value, rule['condition-one'].selectedValue?.value)
                    }
                    isLoading={loadingBudget}
                    isDisabled={disabled}
                  />
                )}
              </div>
            </div>
            {!disabled && (
              <div
                className={`information-icon ms-3 ${
                  rules.length === 1 ? 'invisible' : ''
                }`}
              >
                <MinusIcon onClick={() => removeCondition(index, 'rules')} />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ConditionRowComponent;
