import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import NotificationModal from 'components/NotificationModal/NotificationModal';
import CustomButton from 'components/UI/CustomButton';
import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import copy from 'copy-text-to-clipboard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSubAccount } from 'redux/actions/BudgetsAction';
import { RESET_BLOCK_BUDGET } from 'redux/reducers/BudgetsReducer';
import '../styles.scss';

const AccountInfo = ({
  toggleHandler,
  title,
  hideLink,
  handleBtnClick,
  setTryAgain,
  subtTitle,
  accountType,
}) => {
  const dispatch = useDispatch();
  const { budgetId } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [fetchingAccountDetails, setFetchingAccountDetails] = useState(true);
  const [fetchingAccountDetailsCounter, setFetchingAccountDetailsCounter] = useState(0);
  const {
    getSubAccount: { data: getBank, success: getBankSuccess },
    createBudget: { data: budgetData },
  } = useSelector(({ budgets }) => budgets);

  useEffect(() => {
    if (getBankSuccess && fetchingAccountDetailsCounter < 2) {
      if (getBank === undefined) {
        setFetchingAccountDetailsCounter(fetchingAccountDetailsCounter + 1);
        setTimeout(() => {
          dispatch(getSubAccount(budgetId ?? budgetData?.code));
        }, 5000);
      } else {
        setFetchingAccountDetails(false);
      }
    }

    if (fetchingAccountDetailsCounter > 1) {
      setTryAgain(true);
    }
  }, [getBankSuccess]);

  useEffect(() => {
    dispatch(getSubAccount(budgetId ?? budgetData?.code));
  }, []);

  const [initialState, setInitialState] = useState([
    {
      title: 'Bank Name',
      value: '',
    },
    {
      title: 'Account name',
      value: '',
    },
    {
      title: 'Account number',
      value: '',
    },
    {
      title: 'Reference ID',
      value: '',
    },
  ]);

  const getNairaAccountDetails = () => {
    const { bankCode, number, accountName, bankName } = getBank;
    setInitialState([
      {
        title: 'Bank Name',
        value: bankName,
      },
      {
        title: 'Account name',
        value: accountName,
      },
      {
        title: 'Account number',
        value: number,
      },
    ]);
  };

  useEffect(() => {
    if (getBank) {
      getNairaAccountDetails();
    }
    return () => {
      dispatch({ type: RESET_BLOCK_BUDGET, blockType: 'getSubAccount' });
    };
  }, [getBank]);

  const NairaAccountItems = initialState.filter((item) => item.title !== 'Reference ID');

  const accountItem = accountType === 'USD' ? initialState : NairaAccountItems;

  const onClickHandler = () => {
    if (handleBtnClick) return handleBtnClick();
  };

  const InfoBox = (data) => {
    const { data: value, index } = data;
    // if (!value.value) return <div />;
    return (
      <div className="budget__payment--InfoBox-wrapper">
        <div className="information-title-wrapper">
          <p className="information-title">{value.title}</p>
          <p className="information-value">{value.value}</p>
          {value.title === 'Reference ID' && (
            <p className="ref-id">Add the reference ID when making transfer</p>
          )}
        </div>
        {index > 1 && (
          <span
            className="copy-box"
            onClick={() => {
              copy(value.value);
              toastSuccess(`copy: ${value.value}`);
            }}
          >
            Copy <CopyIcon />
          </span>
        )}
      </div>
    );
  };
  const toggleModal = () => setShowModal((pre) => !pre);
  return (
    <div className="information-wrapper">
      {showModal && (
        <NotificationModal
          handleClose={toggleModal}
          title="Uh oh!"
          message="The money has not reached us yet. Please retry in a couple of minutes."
        />
      )}
      <h2 className="card-title w-100">{title ? title : 'Account details'}</h2>
      {subtTitle && <p>{subtTitle}</p>}
      {fetchingAccountDetails ? (
        <div className="d-flex justify-content-center pt-5">
          <div className="text-center">
            <Loading />
          </div>
        </div>
      ) : (
        <div className="form-normal">
          <div className="pt-4 pb-3">
            <div className="list-wrapper preview-card__transfer px-3">
              <p className="information-value fs-5">Bank details</p>
              <hr />
              {accountItem.map((data, index) => (
                <InfoBox data={data} index={index} key={index} />
              ))}
            </div>
          </div>
          {
            // TODO check the Prototype after this is clicked (based on API response) a notification will be shown, check
            // the prototype I sent
          }
          <CustomButton className="mb-4" fullWidth={true} onClick={onClickHandler}>
            I&apos;ve sent the money
          </CustomButton>
          {!hideLink && (
            <p className="pt-4 text-center cursor" onClick={toggleHandler}>
              Send later
            </p>
          )}
        </div>
      )}
    </div>
  );
};
export default AccountInfo;
