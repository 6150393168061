import { Radio } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSwitch from 'components/UI/CustomSwitch/inedx';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { CancelICon, PlusDropDownIcon } from 'assets/icons';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import CustomTextarea from 'components/UI/CustomTextarea';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { updateCategorizationRule } from 'redux/actions/CategoryAction';
import { RESET_BLOCK_POLICIES } from 'redux/reducers/PoliciesReducer';
import '../Approvals/components/styles.scss';
import ConditionRowComponent from './Components/ConditionRowComponent';

const EditCategorizationRule = ({ setShowEditModal, singleCategorizationRule }) => {
  const dispatch = useDispatch();

  const {
    getBeneficiaries: { data: { beneficiaries = [] } = {}, loading: beneficiaryLoading },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    updateCategorizationRule: { loading, success },
    // fetchSingleCategorizationRule: { data: singleCategorizationRule },
    fetchCategories: { data: categoryData, success: isCatSuccess },
    createCategories: { loading: isCatLoading },
  } = useSelector(({ categories }) => categories);

  const categorizationOptions = [
    {
      value: 'startsWith',
      label: 'Starts with',
    },
    {
      value: 'endsWith',
      label: 'Ends with',
    },
    {
      value: 'contains',
      label: 'Contains',
    },
    {
      value: 'matches',
      label: 'Regular expression',
    },
  ];

  const [categorizationData, setCategorizationData] = useState({});
  const [selectedCategorizationTypes, setSelectedCategorizationTypes] = useState([]);
  const [value, setValue] = useState({ budget: '' });
  const [conditionsToAdd, setConditionsToAdd] = useState([]);
  const [conditionsToRemove, setConditionsToRemove] = useState([]);
  const [conditionsToUpdate, setConditionsToUpdate] = useState([]);
  const [conditionThreeOptions, setConditionThreeOptionsState] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [categorizationConditions, setCategorizationConditions] = useState({
    rules: [
      {
        name: '',
        'condition-one': {
          options: categorizationOptions,
          selectedValue: null,
        },
        'condition-two': {
          selectedValue: null,
        },
      },
    ],
  });

  const history = useHistory();

  const debouncedValue = useDebounce(value.users, 200);

  const handleDescriptionChange = (value) => {
    setCategorizationData({
      ...categorizationData,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    categorizationData?.description,
    250,
    handleDescriptionChange,
  );

  useEffect(() => {
    let newRules = categorizationConditions.rules.slice();
    if (categorizationData?.operator === 'or') {
      for (let i = 0; i < newRules.length; i++) {
        newRules[i]['condition-one'].options = categorizationOptions;
      }
    } else {
      for (let i = 0; i < newRules.length; i++) {
        newRules[i]['condition-one'].options = categorizationOptions.filter(
          (item) =>
            !selectedCategorizationTypes.slice(0, i).includes(item.value) ||
            item.value === newRules[i]['condition-one'].selectedValue?.value,
        );
      }
    }
    setCategorizationConditions((prevValues) => ({ ...prevValues, rules: newRules }));
  }, [categorizationData?.operator]);

  useEffect(() => {
    if (singleCategorizationRule) {
      const { description, name, operator, status, conditions } =
        singleCategorizationRule;

      if (conditions.length) {
        let index = 0;
        const rules = [];

        const selectedConditions = [];

        for (let condition of conditions) {
          rules.push({
            name: '',
            code: condition?.code,
            'condition-one': {
              options: categorizationOptions.filter(
                (item) => !selectedConditions.includes(item.value) || operator === 'or',
              ),
              selectedValue: {
                value: condition.operator,
                label: categorizationOptions.find(
                  (item) => item.value === condition.operator,
                )?.label,
              },
            },
            'condition-two': {
              selectedValue: condition.operand,
            },
          });

          setConditionOneOptions(rules, index, { value: condition.operator }, true);
          setConditionTwoOptions(rules, index, condition.operand);

          if (!['contains', 'matches'].includes(condition.operator))
            selectedConditions.push(condition.operator);
          index++;
        }

        setSelectedCategorizationTypes(selectedConditions);

        setCategorizationConditions({ rules });
      } else {
        setConditionsToAdd([categorizationConditions?.rules?.length - 1]);
      }

      setCategorizationData({
        ...categorizationData,
        description,
        name,
        status: status === 'active',
        operator,
        category: {
          label: singleCategorizationRule?.category?.name,
          value: singleCategorizationRule?.category?.code,
        },
      });
    }
  }, [singleCategorizationRule]);

  useEffect(() => {
    return () => {
      dispatch({
        type: RESET_BLOCK_POLICIES,
        blockType: 'fetchSingleCategorizationRule',
      });
    };
  }, []);

  const onHandleCancel = () => {
    setShowEditModal(false);
  };

  // useEffect(() => {
  //   let newRules = categorizationConditions.rules.slice();
  //   for (let i = 0; i < newRules.length; i++) {
  //     if (i !== currentIndex) {
  //       newRules[i]['condition-one'].options = categorizationOptions.filter(
  //         (item) =>
  //           !selectedCategorizationTypes.includes(item.value) ||
  //           item.value === newRules[i]['condition-one'].selectedValue?.value,
  //       );
  //     }
  //   }
  //   setCategorizationConditions((prevValues) => ({ ...prevValues, rules: newRules }));
  // }, [selectedCategorizationTypes, currentIndex]);

  const handleConditionChange = (value, index, level) => {
    setCurrentIndex(index);
    let newRules = categorizationConditions.rules.slice();
    let selectedRule = newRules[index];
    selectedRule[`condition-${level}`].selectedValue = value;

    if (!conditionsToAdd.includes(index) && !conditionsToUpdate.includes(index)) {
      setConditionsToUpdate([...conditionsToUpdate, index]);
    }

    switch (level) {
      case 'one':
        setSelectedCategorizationTypes((prevValue) => {
          const newArray = prevValue.slice();
          if (!['contains', 'matches'].includes(value.value)) newArray.push(value.value);
          return newArray;
        });
        setConditionOneOptions(newRules, index, value);
        break;
      case 'two':
        setConditionTwoOptions(newRules, index, value);
        break;
    }

    setCategorizationConditions({ ...categorizationConditions, rules: newRules });
  };

  const setConditionOneOptions = (newRules, index, value, isEdit = false) => {
    if (!isEdit) {
      newRules.forEach((item, ruleIndex) => {
        if (item['condition-one'].selectedValue?.value === 'amount') {
          amountCount += 1;
          if (index !== ruleIndex) {
            otherAmountConditionIndex = ruleIndex;
          }
        }
      });
    }
  };

  const setConditionTwoOptions = (newRules, index, value) => {
    newRules[index]['condition-two'].selectedValue = value;
  };

  const addNewCondition = () => {
    let newRules = categorizationConditions.rules.slice();

    const previousConditionOneSelectedVal =
      newRules.at(-1)['condition-one'].selectedValue?.value;

    const previousConditionOneSelectedOpt = newRules
      .at(-1)
      ['condition-one'].options.slice();

    let conditionOptions =
      previousConditionOneSelectedVal && categorizationData?.operator === 'and'
        ? previousConditionOneSelectedOpt.filter(
            (item) =>
              item.value !== previousConditionOneSelectedVal ||
              ['contains', 'matches'].includes(previousConditionOneSelectedVal),
          )
        : previousConditionOneSelectedOpt;

    setConditionsToAdd([...conditionsToAdd, newRules.length]);

    newRules.push({
      name: '',
      'condition-one': {
        options: conditionOptions,
        selectedValue: null,
      },
      'condition-two': {
        selectedValue: null,
      },
    });
    setCategorizationConditions((prevValue) => ({ ...prevValue, rules: newRules }));
  };

  const removeCondition = (index) => {
    let newRules = categorizationConditions.rules.slice();
    let removedItem = newRules.splice(index, 1);

    if (conditionsToAdd.includes(index)) {
      setConditionsToAdd(
        conditionsToAdd
          .filter((item) => item !== index)
          .map((item) => (item > index ? item - 1 : item)),
      );
      setConditionsToUpdate(
        conditionsToUpdate.map((item) => (item > index ? item - 1 : item)),
      );
    }

    if (conditionsToUpdate.includes(index)) {
      setConditionsToUpdate(
        conditionsToUpdate
          .filter((item) => item !== index)
          .map((item) => (item > index ? item - 1 : item)),
      );
      setConditionsToAdd(conditionsToAdd.map((item) => (item > index ? item - 1 : item)));
    }

    setConditionsToRemove(
      [...conditionsToRemove, removedItem[0]?.code].filter((item) => !!item),
    );

    setSelectedCategorizationTypes((prevValue) => {
      let newArray = prevValue.filter(
        (item) => item !== removedItem[0]?.['condition-one'].selectedValue?.value,
      );
      return newArray;
    });
    setCategorizationConditions((prevValue) => ({ ...prevValue, rules: newRules }));
  };

  const formValidation = (formData) => {
    const { name, rules } = formData;
    if (!name) {
      return 'Name is required';
    }

    const position = ['first', 'second', 'third', 'fourth'];
    let index = 0;
    for (let rule of rules) {
      if (!rule['condition-one'].selectedValue || !rule['condition-two'].selectedValue)
        return `Please fill all fields in the ${position[index]} condition`;
      index++;
    }
    return true;
  };

  const handleSubmit = () => {
    const { description, name, operator, status, category } = categorizationData;

    const rules = categorizationConditions?.rules;

    const checkcategorizationData = formValidation({
      ...categorizationData,
      rules: rules,
    });
    if (checkcategorizationData !== true) {
      return toastError(checkcategorizationData);
    }

    const formattedConditionsToAdd = conditionsToAdd.map((item) => ({
      operator: rules[item]['condition-one'].selectedValue.value,
      operand: rules[item]['condition-two'].selectedValue,
    }));

    const formattedConditionsToUpdate = conditionsToUpdate.map((item) => ({
      condition: rules[item].code,
      operator: rules[item]['condition-one'].selectedValue.value,
      operand: rules[item]['condition-two'].selectedValue,
    }));

    const policyStatus = !!status ? 'active' : 'inactive';
    const payload = {
      code: singleCategorizationRule?.code,
      categoryCode: category?.value,
      description:
        description === singleCategorizationRule?.description ? undefined : description,
      name: name === singleCategorizationRule?.name ? undefined : name,
      operator,
      status:
        policyStatus === singleCategorizationRule?.status ? undefined : policyStatus,
      conditionsToAdd: formattedConditionsToAdd.length
        ? formattedConditionsToAdd
        : undefined,
      conditionsToUpdate: formattedConditionsToUpdate.length
        ? formattedConditionsToUpdate
        : undefined,
      conditionsToDelete: conditionsToRemove.length ? conditionsToRemove : undefined,
    };

    dispatch(updateCategorizationRule(payload));
  };

  const filterCategoryList = (categoryList = []) => {
    const filteredList = categoryList?.filter(
      (item) =>
        item?.code === singleCategorizationRule?.category?.code ||
        item?.children
          ?.map((opt) => opt?.code)
          ?.includes(singleCategorizationRule?.category?.code),
    );

    return filteredList;
  };

  return (
    <Modal
      show={true}
      scrollable={true}
      centered
      contentClassName="custom-content"
      dialogClassName="custom-dialog"
    >
      <div className="policy-form form-wrapper__2 position-relative">
        <div style={{ top: '0px' }} className="position-sticky bg-white z-3">
          <div className="d-flex flex-sm-wrap align-items-start justify-content-between pt-4 px-4">
            <div>
              {' '}
              <h6 className="text-lg">Edit {singleCategorizationRule?.name}</h6>
              <p className="text-sm mb-0">Define requirements for the rule.</p>
            </div>
            <div className="d-flex">
              <CancelICon width="11" height="11" onClick={onHandleCancel} />
            </div>
          </div>
          <div className="divider"></div>
        </div>

        <div style={{ width: '100%', overflowY: 'auto' }} className="pb-4 px-4">
          <Row className="mb-4">
            <CustomInput
              label="Rule name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              className="mb-3"
              id="name"
              onChange={(event) =>
                setCategorizationData({ ...categorizationData, name: event.target.value })
              }
              value={categorizationData?.name}
              md={11}
            />
            <Col className="mb-3" md={11}>
              <CustomTextarea
                label="Description(Optional)"
                name="description"
                placeholder="Enter description"
                value={text || categorizationData?.description}
                labelClass="text-sm"
                charCount={charCount}
                onChange={handleCharChange}
                rowSize={4}
              />
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Transactions corresponding to</h6>
            <span>Set how the rule should apply.</span>
          </Row>
          <Row className="mb-3">
            <Col md={11}>
              {[
                {
                  label: 'Any of the following',
                  subtext: 'The rule will apply to any of the conditions set',
                  value: 'or',
                },
                {
                  label: 'All of the following',
                  subtext: 'The rule would apply to all conditions set',
                  value: 'and',
                },
              ].map((item, index) => (
                <div key={index} className="mb-2">
                  <Radio
                    checked={item.value === categorizationData?.operator}
                    onChange={() =>
                      setCategorizationData({
                        ...categorizationData,
                        operator: item.value,
                      })
                    }
                  >
                    <span className="text-sm">{item.label}</span>
                    <br />
                    <span style={{ color: '#79716B' }} className="text-xs">
                      {item.subtext}
                    </span>
                  </Radio>
                </div>
              ))}
            </Col>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3 policy-rule-styles">
            <h6>Conditions</h6>
            <span>Set the criteria for the rule.</span>
          </Row>
          <Row className="mb-3">
            <ConditionRowComponent
              rules={categorizationConditions?.rules}
              removeCondition={removeCondition}
              handleConditionChange={handleConditionChange}
              operator={categorizationData?.operator}
            />

            <div
              className="add-button condition-cta mt-2"
              role="button"
              onClick={
                categorizationConditions?.rules.length === 5
                  ? () => toastError("You can't add more than 5 conditions")
                  : () => addNewCondition()
              }
            >
              <PlusDropDownIcon className="me-1" /> <span>Add condition</span>
            </div>
          </Row>
          <div className="divider"></div>
          <Row className="mb-2 policy-rule-styles">
            <h6>Categorise as</h6>
          </Row>
          <Row className="mb-3 mt-3">
            <Col md={11}>
              <CategoryDropdown
                placeholder="Select a category"
                onChange={(value) =>
                  setCategorizationData({ ...categorizationData, category: value })
                }
                value={categorizationData.category}
                name="category"
                categoryFilter={filterCategoryList}
              />
            </Col>
          </Row>
        </div>

        <div style={{ bottom: '0px' }} className="position-sticky bg-white">
          <div className="divider"></div>

          <div className="d-flex justify-content-between mt-3 pb-4 px-4">
            <div className="d-flex align-items-center gap-2">
              <CustomSwitch
                onChange={(status) =>
                  setCategorizationData({ ...categorizationData, status })
                }
                checked={categorizationData?.status}
                fullWidth={false}
              />
              <span className="text-sm flex-grow-1">Enable this category rule</span>
            </div>
            <div className="d-flex gap-2">
              <CustomButton
                onClick={onHandleCancel}
                className="add-button add-action me-3"
                customClass={true}
              >
                Discard
              </CustomButton>
              <CustomButton
                className="add-button dark-button"
                customClass={true}
                onClick={handleSubmit}
                loading={loading}
              >
                Save changes
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditCategorizationRule;
