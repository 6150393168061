import {
  GET_REFERRALS_REQUEST,
  GET_REFERRALS_WITH_CODE_REQUEST,
  SEND_REFERRAL_EMAIL_REQUEST,
} from 'redux/reducers/ReferralsReducer';

export const getReferrals = () => ({
  type: GET_REFERRALS_REQUEST,
});

export const sendReferralByEmail = (payload) => ({
  type: SEND_REFERRAL_EMAIL_REQUEST,
  payload,
});

export const getReferralsWithCode = (payload) => ({
  type: GET_REFERRALS_WITH_CODE_REQUEST,
  payload,
});
