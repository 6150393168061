import { ChevronRight } from 'assets/icons';
import React, { Fragment } from 'react';

const completed = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#12B76A"
    />
    <path
      d="M15.1 7.45336L8.72556 13.8202L4.89999 9.99973L6.17488 8.72654L8.72556 11.2738L13.8251 6.18018L15.1 7.45336Z"
      fill="white"
    />
  </svg>
);

const SetupStage = ({ menus = [] }) => {
  return (
    <div className="d-flex align-items-center gap-2 onboarding__step-wrapper">
      {menus?.map((item, index) => (
        <Fragment key={index}>
          <>{item.completed && <div>{completed}</div>}</>
          {item.isActive && !item.completed ? (
            <>
              <div className="d-flex align-items-center gap-2 text-sm">
                <span className="onboarding__step-number active">{index + 1}</span>
                {item.name}
              </div>
              {index < menus?.length - 1 && <ChevronRight stroke="#D7D3D0" />}
            </>
          ) : !item.isActive && !item.completed ? (
            <div className="d-flex align-items-center gap-2 text-sm">
              <span className="onboarding__step-number inactive">{index + 1}</span>
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

export default SetupStage;
