import { Skeleton } from 'antd';

const SearchLoader = ({ hasFilter = true, hasExport = false, hasAddbtn = false }) => {
  return (
    <div className="d-flex pt-3 pb-2">
      <Skeleton.Input
        active
        style={{
          borderRadius: 8,
          height: 40,
          width: '260px',
          minWidth: '260px',
        }}
      />

      <div className="ms-auto d-flex gap-3">
        {hasFilter && (
          <Skeleton.Input
            active
            style={{
              borderRadius: 8,
              height: 40,
              width: '100px',
              minWidth: '100px',
            }}
          />
        )}
        {hasExport && (
          <Skeleton.Input
            active
            style={{
              borderRadius: 8,
              height: 40,
              width: '100px',
              minWidth: '100px',
            }}
          />
        )}
        {hasAddbtn && (
          <Skeleton.Input
            active
            style={{
              borderRadius: 8,
              height: 40,
              width: '130px',
              minWidth: '100px',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SearchLoader;
