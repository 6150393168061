import { Axios } from '../api/axios';

export const loadImage = async (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (...args) => reject(args);
    img.src = src;
  });

export const fileReader = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsBinaryString(file);
  });
};

export const addNewFile = (payload) => {
  return Axios.post('/files/url/upload', payload);
};
