export const CREATE_VENDOR_REQUEST = 'CREATE_VENDOR_REQUEST';
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_ERROR = 'CREATE_VENDOR_ERROR';

export const CREATE_BULK_VENDORS_REQUEST = 'CREATE_BULK_VENDORS_REQUEST';
export const CREATE_BULK_VENDORS_SUCCESS = 'CREATE_BULK_VENDORS_SUCCESS';
export const CREATE_BULK_VENDORS_ERROR = 'CREATE_BULK_VENDORS_ERROR';

export const DELETE_VENDOR_REQUEST = 'DELETE_VENDOR_REQUEST';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_ERROR = 'DELETE_VENDOR_ERROR';

export const GET_VENDOR_REQUEST = 'GET_VENDOR_REQUEST';
export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR_ERROR = 'GET_VENDOR_ERROR';

export const GET_SINGLE_VENDOR_REQUEST = 'GET_SINGLE_VENDOR_REQUEST';
export const GET_SINGLE_VENDOR_SUCCESS = 'GET_SINGLE_VENDOR_SUCCESS';
export const GET_SINGLE_VENDOR_ERROR = 'GET_SINGLE_VENDOR_ERROR';

export const UPDATE_VENDOR_REQUEST = 'UPDATE_VENDOR_REQUEST';
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_ERROR = 'UPDATE_VENDOR_ERROR';

export const UPDATE_EXTERNAL_VENDOR_REQUEST = 'UPDATE_EXTERNAL_VENDOR_REQUEST';
export const UPDATE_EXTERNAL_VENDOR_SUCCESS = 'UPDATE_EXTERNAL_VENDOR_SUCCESS';
export const UPDATE_EXTERNAL_VENDOR_ERROR = 'UPDATE_EXTERNAL_VENDOR_ERROR';

export const EXPORT_VENDORS_REQUEST = 'EXPORT_VENDORS_REQUEST';
export const EXPORT_VENDORS_SUCCESS = 'EXPORT_VENDORS_SUCCESS';
export const EXPORT_VENDORS_ERROR = 'EXPORT_VENDORS_ERROR';

export const VERIFY_VENDOR_INVITE_REQUEST = 'VERIFY_VENDOR_INVITE_REQUEST';
export const VERIFY_VENDOR_INVITE_SUCCESS = 'VERIFY_VENDOR_INVITE_SUCCESS';
export const VERIFY_VENDOR_INVITE_ERROR = 'VERIFY_VENDOR_INVITE_ERROR';

export const VERIFY_VENDOR_OTP_REQUEST = 'VERIFY_VENDOR_OTP_REQUEST';
export const VERIFY_VENDOR_OTP_SUCCESS = 'VERIFY_VENDOR_OTP_SUCCESS';
export const VERIFY_VENDOR_OTP_ERROR = 'VERIFY_VENDOR_OTP_ERROR';

export const INVITE_VENDOR_REQUEST = 'INVITE_VENDOR_REQUEST';
export const INVITE_VENDOR_SUCCESS = 'INVITE_VENDOR_SUCCESS';
export const INVITE_VENDOR_ERROR = 'INVITE_VENDOR_ERROR';

export const RESEND_VENDOR_INVITE_REQUEST = 'RESEND_VENDOR_INVITE_REQUEST';
export const RESEND_VENDOR_INVITE_SUCCESS = 'RESEND_VENDOR_INVITE_SUCCESS';
export const RESEND_VENDOR_INVITE_ERROR = 'RESEND_VENDOR_INVITE_ERROR';

export const VENDOR_INFO_REQUEST = 'VENDOR_INFO_REQUEST';
export const VENDOR_INFO_SUCCESS = 'VENDOR_INFO_SUCCESS';
export const VENDOR_INFO_ERROR = 'VENDOR_INFO_ERROR';

export const MASS_UPDATE_VENDOR_REQUEST = 'MASS_UPDATE_VENDOR_REQUEST';
export const MASS_UPDATE_VENDOR_SUCCESS = 'MASS_UPDATE_VENDOR_SUCCESS';
export const MASS_UPDATE_VENDOR_ERROR = 'MASS_UPDATE_VENDOR_ERROR';

export const REACTIVATE_VENDOR_REQUEST = 'REACTIVATE_VENDOR_REQUEST';
export const REACTIVATE_VENDOR_SUCCESS = 'REACTIVATE_VENDOR_SUCCESS';
export const REACTIVATE_VENDOR_ERROR = 'REACTIVATE_VENDOR_ERROR';

export const RESET_BLOCK_VENDOR = 'RESET_BLOCK_VENDOR';

export const RESET_FLAGS_VENDOR = 'RESET_FLAGS_VENDOR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getVendor: { ...block },
  createVendor: { ...block },
  updateVendor: { ...block },
  getSingleVendor: { ...block },
  deleteVendor: { ...block },
  createBulkVendors: { ...block },
  exportVendors: { ...block },
  verifyVendorInvite: { ...block },
  verifyVendorOtp: { ...block },
  inviteVendor: { ...block },
  resendVendorInvite: { ...block },
  vendorInfo: { ...block },
  updateExternalVendor: { ...block },
  massUpdateVendor: { ...block },
  reactivateVendor: { ...block },
};

export const VendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDOR_REQUEST:
      return { ...state, getVendor: { ...state.getVendor, loading: true } };
    case GET_VENDOR_SUCCESS:
      return {
        ...state,
        getVendor: {
          ...state.getVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_VENDOR_ERROR:
      return {
        ...state,
        getVendor: { ...state.getVendor, loading: false, error: action.error },
      };

    case CREATE_VENDOR_REQUEST:
      return { ...state, createVendor: { ...state.createVendor, loading: true } };
    case CREATE_VENDOR_SUCCESS:
      return {
        ...state,
        createVendor: {
          ...state.createVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_VENDOR_ERROR:
      return {
        ...state,
        createVendor: { ...state.createVendor, loading: false, error: action.error },
      };

    case CREATE_BULK_VENDORS_REQUEST:
      return {
        ...state,
        createBulkVendors: { ...state.createBulkVendors, loading: true },
      };
    case CREATE_BULK_VENDORS_SUCCESS:
      return {
        ...state,
        createBulkVendors: {
          ...state.createBulkVendors,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BULK_VENDORS_ERROR:
      return {
        ...state,
        createBulkVendors: {
          ...state.createBulkVendors,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_VENDOR_REQUEST:
      return { ...state, updateVendor: { ...state.updateVendor, loading: true } };
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        updateVendor: {
          ...state.updateVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_VENDOR_ERROR:
      return {
        ...state,
        updateVendor: { ...state.updateVendor, loading: false, error: action.error },
      };

    case DELETE_VENDOR_REQUEST:
      return { ...state, deleteVendor: { ...state.deleteVendor, loading: true } };
    case DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        deleteVendor: {
          ...state.deleteVendor,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_VENDOR_ERROR:
      return {
        ...state,
        deleteVendor: { ...state.deleteVendor, loading: false, error: action.error },
      };

    case UPDATE_VENDOR_REQUEST:
      return {
        ...state,
        updateVendor: { ...state.updateVendor, loading: true },
      };
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        updateVendor: {
          ...state.updateVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_VENDOR_ERROR:
      return {
        ...state,
        updateVendor: {
          ...state.updateVendor,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_EXTERNAL_VENDOR_REQUEST:
      return {
        ...state,
        updateExternalVendor: { ...state.updateExternalVendor, loading: true },
      };
    case UPDATE_EXTERNAL_VENDOR_SUCCESS:
      return {
        ...state,
        updateExternalVendor: {
          ...state.updateExternalVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_EXTERNAL_VENDOR_ERROR:
      return {
        ...state,
        updateExternalVendor: {
          ...state.updateExternalVendor,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_VENDOR_REQUEST:
      return { ...state, getSingleVendor: { ...state.getSingleVendor, loading: true } };
    case GET_SINGLE_VENDOR_SUCCESS:
      return {
        ...state,
        getSingleVendor: {
          ...state.getSingleVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_VENDOR_ERROR:
      return {
        ...state,
        getSingleVendor: {
          ...state.getSingleVendor,
          loading: false,
          error: action.error,
        },
      };

    case EXPORT_VENDORS_REQUEST:
      return { ...state, exportVendors: { ...state.exportVendors, loading: true } };
    case EXPORT_VENDORS_SUCCESS:
      return {
        ...state,
        exportVendors: {
          ...state.exportVendors,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case EXPORT_VENDORS_ERROR:
      return {
        ...state,
        exportVendors: {
          ...state.exportVendors,
          loading: false,
          error: action.error,
        },
      };

    case VERIFY_VENDOR_INVITE_REQUEST:
      return {
        ...state,
        verifyVendorInvite: { ...state.verifyVendorInvite, loading: true },
      };
    case VERIFY_VENDOR_INVITE_SUCCESS:
      return {
        ...state,
        verifyVendorInvite: {
          ...state.verifyVendorInvite,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VERIFY_VENDOR_INVITE_ERROR:
      return {
        ...state,
        verifyVendorInvite: {
          ...state.verifyVendorInvite,
          loading: false,
          error: action.error,
        },
      };

    case VERIFY_VENDOR_OTP_REQUEST:
      return { ...state, verifyVendorOtp: { ...state.verifyVendorOtp, loading: true } };
    case VERIFY_VENDOR_OTP_SUCCESS:
      return {
        ...state,
        verifyVendorOtp: {
          ...state.verifyVendorOtp,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VERIFY_VENDOR_OTP_ERROR:
      return {
        ...state,
        verifyVendorOtp: {
          ...state.verifyVendorOtp,
          loading: false,
          error: action.error,
        },
      };

    case INVITE_VENDOR_REQUEST:
      return { ...state, inviteVendor: { ...state.inviteVendor, loading: true } };
    case INVITE_VENDOR_SUCCESS:
      return {
        ...state,
        inviteVendor: {
          ...state.inviteVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case INVITE_VENDOR_ERROR:
      return {
        ...state,
        inviteVendor: {
          ...state.inviteVendor,
          loading: false,
          error: action.error,
        },
      };

    case RESEND_VENDOR_INVITE_REQUEST:
      return {
        ...state,
        resendVendorInvite: { ...state.resendVendorInvite, loading: true },
      };
    case RESEND_VENDOR_INVITE_SUCCESS:
      return {
        ...state,
        resendVendorInvite: {
          ...state.resendVendorInvite,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case RESEND_VENDOR_INVITE_ERROR:
      return {
        ...state,
        resendVendorInvite: {
          ...state.resendVendorInvite,
          loading: false,
          error: action.error,
        },
      };

    case VENDOR_INFO_REQUEST:
      return { ...state, vendorInfo: { ...state.vendorInfo, loading: true } };
    case VENDOR_INFO_SUCCESS:
      return {
        ...state,
        vendorInfo: {
          ...state.vendorInfo,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VENDOR_INFO_ERROR:
      return {
        ...state,
        vendorInfo: {
          ...state.vendorInfo,
          loading: false,
          error: action.error,
        },
      };

    case MASS_UPDATE_VENDOR_REQUEST:
      return { ...state, massUpdateVendor: { ...state.massUpdateVendor, loading: true } };
    case MASS_UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        massUpdateVendor: {
          ...state.massUpdateVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case MASS_UPDATE_VENDOR_ERROR:
      return {
        ...state,
        massUpdateVendor: {
          ...state.massUpdateVendor,
          loading: false,
          error: action.error,
        },
      };

    case REACTIVATE_VENDOR_REQUEST:
      return { ...state, reactivateVendor: { ...state.reactivateVendor, loading: true } };
    case REACTIVATE_VENDOR_SUCCESS:
      return {
        ...state,
        reactivateVendor: {
          ...state.reactivateVendor,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REACTIVATE_VENDOR_ERROR:
      return {
        ...state,
        reactivateVendor: {
          ...state.reactivateVendor,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_VENDOR:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_VENDOR:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
