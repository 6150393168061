import React from 'react';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/successIcon.svg';

const Success = ({ title, message }) => {
  return (
    <div className="d-flex justify-content-center pt-5">
      <div className="text-center" style={{ marginTop: '50%' }}>
        <SuccessIcon />

        <h1 className="fs-3 mt-3">{title}</h1>
        <p className="fs-6">{message}</p>
      </div>
    </div>
  );
};

export default Success;
