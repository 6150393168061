import { IntegrationsContext } from 'pages/Settings';
import { useContext } from 'react';
import ConnectionDescription from './ConnectionDescription';
import Connector from './Connector';

const IntegrationConnectProcess = () => {
  const { onHandleGoBack, selectedIntegration } = useContext(IntegrationsContext);

  const ProccessRenderer = (step) => {
    switch (step) {
      case 'view':
        return <ConnectionDescription />;
      case 'create':
        return <Connector />;
      default:
        return <ConnectionDescription />;
    }
  };

  return (
    <section className="details__wrapper">
      <div className="container">
        <div className="row">
          <span className="goBack" onClick={() => onHandleGoBack('list')}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8334 6.99999H1.16675M1.16675 6.99999L7.00008 12.8333M1.16675 6.99999L7.00008 1.16666"
                stroke="#D28B28"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </span>
          <section className="d-flex items-center justify-content-center">
            {ProccessRenderer(selectedIntegration.step)}
          </section>
        </div>
      </div>
    </section>
  );
};

export default IntegrationConnectProcess;
