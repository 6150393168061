import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { resetUploadState } from 'redux/actions/ProgressUploadAction';
import { fetchSingleBill, updateBill } from 'redux/actions/BillAction';
import { getBalances } from 'redux/actions/BudgetsAction';

import cs from 'classnames';
import { Checkbox, Switch, Skeleton } from 'antd';
import CustomPopover from 'components/UI/Popover';
import CustomInput from 'components/UI/CustomInput';
import CurrencyFormat from 'react-currency-format';
import CustomSelect from 'components/UI/CustomSelect';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomButton from 'components/UI/CustomButton';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import ServerFileUpload from 'components/UI/FileUpload/ServerFileUpload';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { allPermissions } from 'utils/AllowedTo';

import {
  ArrowLeft02,
  CalendarIcon,
  ChevronDown,
  SendIcon,
  BankNote,
  BookClosed,
} from 'assets/icons/index';

import dayjs from 'dayjs';
import { addDays } from 'date-fns';
import classNames from 'classnames';
import {
  convertNaNToZero,
  getCurrency,
  recurrenceOption,
  groupSourceOptions,
  getAvailableBalance,
  bankLabelAliaser,
} from 'utils/helper';

const SkeletalLoader = () => {
  return (
    // RHS
    <div className="rhs">
      <Skeleton.Input className="mt-1" active style={{ borderRadius: 4, height: 24 }} />
      <section className="mt-4 pt-2 details-holder review ">
        <Skeleton.Input
          className="my-2 pb-1"
          active
          style={{ borderRadius: 4, height: 20 }}
        />

        {Array.from({ length: 4 }).map((_, i) => (
          <div className="mb-2 d-flex" key={i}>
            <Skeleton.Input
              active
              style={{ borderRadius: 4, height: 12, minWidth: '100px', width: '100px' }}
            />

            <div className="ms-auto">
              <Skeleton.Input
                active
                style={{ borderRadius: 4, height: 12, minWidth: '100px', width: '100px' }}
              />
            </div>
          </div>
        ))}

        <Skeleton.Input
          active
          className="mb-2"
          style={{ borderRadius: 4, height: 12, minWidth: '100px', width: '100px' }}
        />

        {Array.from({ length: 2 }).map((_, i) => (
          <div className={`d-flex ${i === 2 ? 'mb-0' : 'mb-2'}`} key={i}>
            <Skeleton.Input
              active
              style={{ borderRadius: 4, height: 12, minWidth: '100px', width: '100px' }}
            />

            <div className="ms-auto">
              <Skeleton.Input
                active
                style={{ borderRadius: 4, height: 12, minWidth: '100px', width: '100px' }}
              />
            </div>
          </div>
        ))}
      </section>

      <section>
        {Array.from({ length: 3 }).map((_, i) => (
          <div className="mt-4" key={i}>
            <Skeleton.Input active style={{ borderRadius: 8, height: 10 }} />

            <div className="w-100 bill-skeleton">
              <Skeleton.Input
                active
                style={{ borderRadius: 8, height: 44, width: 'inherit' }}
              />
            </div>
          </div>
        ))}
        <div className="w-100 bill-skeleton">
          <Skeleton.Input
            className="mt-3"
            active
            style={{ borderRadius: 8, height: 44, width: 'inherit' }}
          />
        </div>
      </section>
    </div>
  );
};

const ReviewBill = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { code = '' } = useParams();
  const params = new URLSearchParams(location.search);
  const isEdit = params.get('isEdit') === 'true';

  const sectionRef = useRef(null);

  const billRecurrence = [
    {
      value: 'one-time',
      label: 'Never',
      details: 'One-time bill',
    },
    ...recurrenceOption.slice(1),
  ];

  const [loadPage, setLoadPage] = useState(0);
  const [partial, setPartial] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [saveClick, setSaveClick] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);
  const [isFileUpdate, setIsFileUpdate] = useState(false);
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [payload, setPayload] = useState({
    category: null,
    method: '',
    minAmountDue: '',
    source: null,
  });
  const [scheduleData, setScheduleData] = useState({
    startDate: '',
    expiryDate: '',
    repeats: billRecurrence[0],
  });

  const {
    fetchSingleBill: { loading, success: billSuccess, data: bill },
    updateBill: { loading: isUpdating, success: updateSuccess },
  } = useSelector(({ bills }) => bills);

  const { isPermittedToPayBill } = allPermissions();

  const { assetData = [], status = {}, files = [] } = useSelector(({ upload }) => upload);
  const { url = '', fileName = '', fileType = '' } = assetData[0] || {};

  const activeFile = files[0]?.id || '';
  const fileStatus = status[activeFile];

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const products = bill?.billProducts || [];

  useEffect(() => {
    if (code) {
      dispatch(fetchSingleBill({ code }));
    }
  }, [code]);

  useEffect(() => {
    if (fileStatus === 'success') {
      setIsFileUpdate(true);
      dispatch(updateBill({ uploads: [assetData[0]?.assetCode], code }));
    }
  }, [fileStatus]);

  useEffect(() => {
    if (isSchedule && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSchedule]);

  useEffect(() => {
    dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  useEffect(() => {
    if (updateSuccess && !isFileUpdate) {
      history.push('/expenses/bills');
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (bill) {
      const { category = {}, paymentMethod: method = '' } = bill;
      const { code = '', bankAccount = {} } = bill[method] || {};

      setPayload({
        ...payload,
        category: category?.name
          ? { label: category?.name, value: category?.code }
          : null,
        method,
        source: method
          ? {
              type: method,
              value: code,
              label: method
                ? bankLabelAliaser({
                    ...bill[method],
                    bankName: bankAccount?.bankName,
                    bankCode: bankAccount?.bankCode,
                  })
                : null,
            }
          : null,
      });
    }
  }, [bill, billSuccess]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balance');
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  const updatePayload = (data) => {
    setPayload({
      ...payload,
      [data.target]: data.value,
    });
  };

  const updateSchedule = (data) => {
    setScheduleData({
      ...scheduleData,
      [data.target]: data.value,
    });
  };

  const loadOptions = (search) => {
    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
    };
  };

  const uploadText = (
    <span style={{ color: '#1C1917' }}>
      <span style={{ color: '#D28B28' }}>Choose a file</span> or drag & drop it here
    </span>
  );

  const formatOptionLabel = (props) => {
    const { label, details, isDisabled } = props;
    return (
      <div className={classNames('invoice-recurrence-option')}>
        <h1 className={classNames({ ['text-muted']: isDisabled })}>{label}</h1>
        <span className={classNames({ ['text-muted ']: isDisabled })}>{details}</span>
      </div>
    );
  };

  const routeBack = () => {
    if (isEdit) {
      history.push('/expenses/bills');
    } else {
      history.push(`/expenses/bills/create?isEdit=${code}`);
    }
  };

  const methodAliaser = (data) => {
    if (data?.type === 'budget') {
      return 'budget';
    }
    return data?.value?.startsWith('bnk_') ? 'directDebit' : 'balance';
  };

  const isOnetime = scheduleData.repeats.value === 'one-time';

  const aliaseSchedule = () => {
    const startDate = scheduleData.startDate;

    const minutes = dayjs(startDate).format('mm');
    const hours = dayjs(startDate).format('HH');
    const dayOfMonth = dayjs(startDate).format('DD');
    const month = dayjs(startDate).format('MMMM').toLowerCase();
    const dayOfWeek = dayjs(startDate).format('dddd').toLowerCase();

    return {
      minutes,
      hours,
      dayOfMonth,
      month,
      dayOfWeek,
    };
  };

  const handleUpdateBill = (sendForApproval = false, isDraft = false) => {
    const { source, category, minAmountDue } = payload;
    let scheduledBill;

    if (isSchedule) {
      scheduledBill = {
        recurring: !isOnetime,
        schedule: isOnetime ? aliaseSchedule() : scheduleData.repeats?.value,

        startDate: {
          date: dayjs(scheduleData.startDate)?.format('YYYY-MM-DD'),
          timestamp: dayjs(scheduleData.startDate)?.format('HH:mm:ss'),
        },

        expiryDate: !isOnetime
          ? {
              date: dayjs(scheduleData?.expiryDate)?.format('YYYY-MM-DD'),
              timestamp: dayjs(scheduleData?.expiryDate)?.format('HH:mm:ss'),
            }
          : undefined,
      };
    }

    const finalPayload = {
      isDraft,

      sendForApproval: sendForApproval,
      ...(!isDraft && { status: 'pending' }),
      ...(category && { category: category?.value }),
      ...(source && {
        method: methodAliaser(source),
        [methodAliaser(source)]: source?.value,
      }),

      ...(partial &&
        minAmountDue && { minAmountDue: +minAmountDue.replace(/,/g, '') * 100 }),

      ...(isSchedule && { scheduledBill }),
    };

    dispatch(updateBill({ ...finalPayload, code }));
  };

  const handleToggle = (value) => {
    setIsSchedule(value);

    if (!value) {
      setScheduleData({
        startDate: '',
        expiryDate: '',
        repeats: billRecurrence[0],
      });
    }
  };

  const validateSchedule = (shouldValidate = false) => {
    if (isSchedule || shouldValidate) {
      const errors = {
        source: !payload.source,
        category: !payload.category,
        ...(isSchedule && { startDate: !scheduleData.startDate }),
        ...(isSchedule && !isOnetime && { expiryDate: !scheduleData.expiryDate }),
      };

      return Object.values(errors).some((error) => error);
    }
    return false;
  };

  const handleClick = (sendForApproval = false, isDraft = false) => {
    setIsButtonToggle(false);
    setIsTriggered(true);

    const hasValidationErrors = validateSchedule();
    if (hasValidationErrors) return;

    const payloadChanges = Object.values(payload).some((value) => !!value);
    const scheduleChanges = scheduleData.startDate || scheduleData.expiryDate;

    if (payloadChanges || scheduleChanges) {
      handleUpdateBill(sendForApproval, isDraft);
    } else {
      history.push('/expenses/bills');
    }
  };

  const handleSave = () => {
    setIsFileUpdate(false);
    setIsTriggered(true);

    const hasValidationErrors = validateSchedule(true);
    if (hasValidationErrors) {
      setSaveClick(true);
      return;
    }
    handleUpdateBill(true);
  };

  const cancelAction = () => {
    dispatch(resetUploadState());
    history.push('/expenses/bills');
  };

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div className="actionLink bill-action-link" onClick={() => handleClick(true)}>
          <SendIcon stroke="#79716B" />
          Save and send for approval
        </div>

        {isPermittedToPayBill && (
          <div className="actionLink bill-action-link" onClick={() => handleClick()}>
            <BankNote />
            Save and pay
          </div>
        )}

        <div
          className="actionLink bill-action-link"
          onClick={() => handleClick(false, true)}
        >
          <BookClosed />
          Save as draft
        </div>
      </div>
    );
  };

  return (
    <section className="create-holder">
      <div className="lhs">
        <div
          className={cs('drag-region', {
            ['has-file']: fileStatus,
            ['no-bg']: url,
          })}
        >
          {url ? (
            <>
              {fileType.includes('pdf') ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer className="check" fileUrl={url} />
                </Worker>
              ) : (
                <div className="ocr-image">
                  <img src={url} alt={fileName} />
                </div>
              )}
            </>
          ) : (
            <ServerFileUpload
              scan
              containerClass="bill-upload__container"
              uploadText={uploadText}
              supportType="JPEG, PNG, and PDF formats, up to 10 MB."
            />
          )}
        </div>
      </div>

      {/* RHS */}
      {loading ? (
        <SkeletalLoader />
      ) : (
        <div className="rhs">
          <span className="back-action" onClick={routeBack}>
            <ArrowLeft02 />
            Back
          </span>

          <section className="mt-3 review-details__holder px-1">
            <h2 className="section-header">Review details</h2>
            <section className="mt-4 pt-2">
              <div className="details-holder review">
                <p className="header">Vendor details</p>

                <div className="sub-detail">
                  <span className="caption">Name</span>
                  <span className="value">{bill?.vendor?.name}</span>
                </div>

                <div className="sub-detail">
                  <span className="caption">Unique identifier/reference</span>
                  <span className="value">{bill?.reference}</span>
                </div>

                <div className="sub-detail">
                  <span className="caption">Creation date</span>
                  <span className="value">
                    {dayjs(bill?.created_at).format('MMM D, YYYY')}
                  </span>
                </div>

                <div className="sub-detail">
                  <span className="caption">Due date</span>
                  <span className="value">
                    {dayjs(bill?.dueDate).format('MMM D, YYYY')}
                  </span>
                </div>

                <span className="sub-header" style={{ marginBottom: 12 }}>
                  Line items
                </span>

                <section className="line-items__holder">
                  {products.map((product, index) => (
                    <div
                      key={index}
                      className={`sub-detail ${
                        index === products.length - 1 ? 'last' : ''
                      }`}
                    >
                      <span className="caption item">
                        {product?.product?.name}
                        <span className="item-holder">{`Qty ${product?.quantity}`}</span>
                      </span>
                      <span className="value">
                        <CurrencyFormat
                          style={{ overflow: 'hidden' }}
                          prefix={getCurrency('NGN')}
                          value={convertNaNToZero(product?.product?.price / 100)}
                          displayType="text"
                          thousandSeparator={true}
                          isNumericString
                        />
                      </span>
                    </div>
                  ))}
                </section>

                <div className="sub-detail mt-2">
                  <span className="caption" style={{ fontWeight: 600 }}>
                    Amount
                  </span>
                  <span className="value">
                    <CurrencyFormat
                      style={{ overflow: 'hidden' }}
                      prefix={getCurrency('NGN')}
                      value={convertNaNToZero(bill?.amount / 100)}
                      displayType="text"
                      thousandSeparator={true}
                      isNumericString
                    />
                  </span>
                </div>

                <div className="sub-detail last">
                  <span className="caption" style={{ fontWeight: 600 }}>
                    Balance due
                  </span>
                  <span className="value">
                    <CurrencyFormat
                      style={{ overflow: 'hidden' }}
                      prefix={getCurrency('NGN')}
                      value={convertNaNToZero(bill?.amountRemaining / 100)}
                      displayType="text"
                      thousandSeparator={true}
                      isNumericString
                    />
                  </span>
                </div>
              </div>
            </section>

            <div ref={sectionRef}>
              <form className="mt-4 form-holder no-scroll">
                <div className="form-item">
                  <CategoryDropdown
                    hasError={
                      (isSchedule || saveClick) && isTriggered && !payload.category
                    }
                    label="Category"
                    placeholder="Select category"
                    onChange={(data) =>
                      updatePayload({ target: 'category', value: data })
                    }
                    value={payload.category}
                    name="category"
                  />
                </div>

                <div className="form-item">
                  <CustomSelectRadio
                    hasError={(isSchedule || saveClick) && isTriggered && !payload.source}
                    label="Source of funds"
                    placeholder="Select source of funds"
                    onChange={(data) => updatePayload({ target: 'source', value: data })}
                    value={payload.source}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </div>
                <div className="mt-4">
                  <Checkbox
                    className="colored"
                    checked={partial}
                    onChange={(e) => setPartial(e.target.checked)}
                  >
                    Make partial payment
                  </Checkbox>

                  <div className="ms-4 ps-1">
                    <CustomInput
                      className="mt-2"
                      name="unitPrice"
                      type="number"
                      placeholder="₦0.00"
                      isAmount
                      value={payload.minAmountDue}
                      otherCurrency={false}
                      useCurrency={false}
                      disabled={!partial}
                      onChange={(e) =>
                        updatePayload({ target: 'minAmountDue', value: e.target.value })
                      }
                    />

                    {partial && (
                      <span
                        className="pt-3"
                        style={{ color: '#79716B', fontSize: '12px' }}
                      >
                        {' '}
                        You can set the minimum amount for the first payment.
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-5">
                  <div className="details-holder padded">
                    <section className=" d-flex">
                      <div className="d-flex gap-3">
                        <div className="recurring-holder">
                          <CalendarIcon />
                        </div>

                        <div>
                          <span className="recurring-text">Schedule</span>
                          <span className="recurring-text alt">
                            Enter your details below
                          </span>
                        </div>
                      </div>

                      <div className="ms-auto">
                        <Switch
                          checked={isSchedule}
                          className="invoice-switch"
                          onChange={(value) => handleToggle(value)}
                        />
                      </div>
                    </section>

                    {isSchedule && (
                      <div className="mt-2 fade-in-up">
                        <CustomDatePicker
                          hasError={isTriggered && !scheduleData.startDate}
                          id="invoice-date"
                          label="Date "
                          onChange={(value) =>
                            updateSchedule({ target: 'startDate', value })
                          }
                          peekNextMonth
                          showMonthDropdown
                          minDate={addDays(dayjs(new Date()).toDate(), 1)}
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="DD / MM / YYYY"
                          selected={
                            scheduleData.startDate &&
                            dayjs(scheduleData.startDate).toDate()
                          }
                        />
                        <div className="grid-col-2 mt-2">
                          <CustomSelect
                            hasError={isTriggered && !scheduleData.repeats}
                            classNamePrefix="invoice-select"
                            label="Repeats"
                            placeholder="Select"
                            name="schedule"
                            options={billRecurrence}
                            formatOptionLabel={formatOptionLabel}
                            onChange={(value) =>
                              updateSchedule({ target: 'repeats', value })
                            }
                            value={scheduleData.repeats}
                          />

                          <CustomDatePicker
                            disabled={isOnetime}
                            hasError={
                              isTriggered && !scheduleData.expiryDate && !isOnetime
                            }
                            id="invoice-date"
                            label="End date"
                            onChange={(value) =>
                              updateSchedule({ target: 'expiryDate', value })
                            }
                            peekNextMonth
                            showMonthDropdown
                            minDate={addDays(dayjs(scheduleData.startDate).toDate(), 1)}
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="DD / MM / YYYY"
                            selected={
                              scheduleData.expiryDate &&
                              dayjs(scheduleData.expiryDate).toDate()
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </section>

          <div className="d-flex gap-3 justify-content-end mt-4">
            <button
              className={
                'add-custom add-button request-btn d-flex align-items-center gap-2'
              }
              style={{ padding: '10px 16px', borderRadius: 8 }}
              onClick={cancelAction}
            >
              <span className="ms-1" style={{ fontWeight: 500 }}>
                Cancel
              </span>
            </button>

            {isSchedule ? (
              <CustomButton
                style={{ width: 92 }}
                onClick={handleSave}
                loading={isUpdating}
                disabled={loading || isUpdating}
                className="dropdown-btn action-btn is-plain"
              >
                Save
              </CustomButton>
            ) : (
              <div className="d-flex">
                <CustomButton
                  onClick={handleSave}
                  loading={isUpdating}
                  disabled={loading || isUpdating}
                  className={`${isUpdating && 'is-loading'} dropdown-btn action-btn`}
                >
                  Save
                </CustomButton>

                {!isSchedule && (
                  <CustomPopover
                    zIndex="1"
                    content={<Actions />}
                    showPopover={isButtonToggle}
                    placement="top-start"
                    customClass="multi-entity bill-action"
                    clickOutside={() => setIsButtonToggle(false)}
                  >
                    <button
                      className="dropdown-btn"
                      onClick={() => setIsButtonToggle(!isButtonToggle)}
                    >
                      <ChevronDown
                        color="#ffffff"
                        className={`toggle-icon ${isButtonToggle && 'is-toggled'}`}
                      />
                    </button>
                  </CustomPopover>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default ReviewBill;
