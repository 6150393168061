import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import Modal from 'components/UI/Modal';
import { toastSuccess } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { camelCaseToNormalCase } from 'utils/helper';

const EditModal = ({
  isOpen,
  handleClose,
  selectedRow,
  setTableData = {},
  tableData = [],
  tableColumns = [],
}) => {
  if (!isOpen) return <div />;

  const [batch, setBatch] = useState({});

  let tableJson = tableData;
  const inputValues = Object.entries(selectedRow)
    .filter(([key]) => key !== 'modify')
    .map(([key, value]) => ({
      label: key,
      value,
    }));

  useEffect(() => {
    if (selectedRow) {
      setBatch(selectedRow);
    }
  }, [selectedRow]);

  const handleChange = ({ name, value }) => {
    setBatch({ ...batch, [name]: value });
  };

  const handleSubmit = () => {
    const update = tableJson.map((item) => {
      if (item.id === batch.id) {
        return {
          ...batch,
        };
      } else {
        return item;
      }
    });
    setTableData(update);
    handleClose();
    toastSuccess('Record updated');
  };

  const editRowForm = () => (
    <div className="beneficiaries">
      <h2 className="batch-title">Edit a row</h2>

      {inputValues.map(({ label, value }, index) => {
        if (label === 'id') return;
        return (
          <Row className="mb-3" key={index}>
            <CustomInput
              label={
                tableColumns.find(
                  (item) => item.accessor.toLowerCase() === label.toLowerCase(),
                )?.Header
              }
              placeholder="Enter batch name"
              type="text"
              name={label}
              defaultValue={value}
              onChange={({ target: { name, value } }) => handleChange({ name, value })}
              value={batch[value]}
            />
          </Row>
        );
      })}

      <CustomButton className="mt-3" onClick={handleSubmit} fullWidth={true}>
        Save
      </CustomButton>
      <CustomButton withoutBg className="mt-3" onClick={handleClose} fullWidth={true}>
        Cancel
      </CustomButton>
    </div>
  );

  return (
    <div>
      <Modal show={isOpen} onClose={handleClose}>
        <div className="content">
          <div className="card-modal-header">
            <div className="d-flex align-items-center cursor" onClick={handleClose}>
              <CloseOutlined />
              <span className="ps-1">Close</span>
            </div>
          </div>
          <div className="card-modal-body">{editRowForm()}</div>
        </div>
      </Modal>
    </div>
  );
};
export default EditModal;
