import numeral from 'numeral';
import React from 'react';

import './exchange.styles.scss';

const ExchangeRate = ({ exchangeRate, amount, loading }) => {
  return (
    <div className="mt-4 mb-2 text-secondary" style={{ lineHeight: 0 }}>
      <div className="fw-light-bold d-flex align-items-center mb-2 text-gold">
        <p className="mr-3">Exchange Rate:</p>
        {loading ? <p className="spinner-3"></p> : <p>{`₦${exchangeRate}/$1`}</p>}
      </div>
      <div className="d-flex align-items-center">
        <p className="mr-3">Amount In Naira:</p>
        {loading ? (
          <p className="spinner-3"></p>
        ) : (
          <p>₦{numeral(exchangeRate * amount).format('0,0.00')}</p>
        )}
      </div>
    </div>
  );
};

export default ExchangeRate;
