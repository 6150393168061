import {
  TOGGLE_NOTIFICATION_REQUEST,
  TOGGLE_ONBOARDING_VERIFICATION_SUCCESS,
  TOGGLE_REQUEST,
} from 'redux/reducers/ToggleReducer';

export const toggleAction = (payload) => ({
  type: TOGGLE_REQUEST,
  payload,
});

export const toggleNotificationAction = (payload) => ({
  type: TOGGLE_NOTIFICATION_REQUEST,
  payload,
});

export const toggleOnboardingNotification = (payload) => ({
  type: TOGGLE_ONBOARDING_VERIFICATION_SUCCESS,
  payload,
});
