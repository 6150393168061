import { numFormatter } from 'utils/utility';
import { getCurrency } from 'utils/helper';
import CurrencyFormat from 'react-currency-format';

import AnalyticsCard from 'components/UI/AnalyticsCard';
import { Col, Row } from 'react-bootstrap';

const FormattedCurrency = ({ value, prefix }) => {
  const formattedValue = numFormatter(value / 100);

  return (
    <CurrencyFormat
      prefix={prefix}
      value={formattedValue}
      displayType="text"
      thousandSeparator={true}
      isNumericString
      renderText={(formattedValue) => {
        const [integerPart, decimalPart] = formattedValue.split('.');
        return (
          <span>
            {integerPart}
            {decimalPart && <span style={{ color: '#A9A29D' }}>.{decimalPart}</span>}
          </span>
        );
      }}
    />
  );
};

const BillAnalytics = ({ data, loading, activeTags, handleClick }) => {
  const SUMMARY_KEYS = {
    Overdue: 'totalOverdue',
    Pending: 'totalPending',
    Approved: 'totalApproved',
    Draft: 'totalDraft',
    Paid: 'totalPaid',
  };

  const dataCenter = Object.entries(SUMMARY_KEYS).map(([target, key]) => ({
    target,
    value: data?.summary?.[key]?.['NGN'] || 0,
  }));

  return (
    <section className="procurement-analytics-region bill">
      <Row>
        {dataCenter.map((data, i) => (
          <Col key={i} className="mb-2 w-20" onClick={() => handleClick(data)}>
            <AnalyticsCard
              loading={loading}
              caption={data.target}
              isActive={activeTags.includes(data.target.toLowerCase())}
              value={<FormattedCurrency value={data.value} prefix={getCurrency('NGN')} />}
            />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default BillAnalytics;
