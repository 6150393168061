import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import { StatusIcon, AlertCircleIconSolid } from 'assets/icons';

const SuccessToast = ({ message, closeToast }) => {
  return (
    <div className="toast-wrap">
      <div className="icon-wrap">
        <StatusIcon width="20" height="20" fill="#12B76A" className="success-icon" />
      </div>
      <div className="text-wrap">
        <h5 className="text success">{message}</h5>
      </div>
    </div>
  );
};

const WarningToast = ({ message, closeToast }) => {
  return (
    <div className="toast-wrap">
      <div className="icon-wrap">
        <ExclamationCircleOutlined className="other-icons" />
      </div>
      <div className="text-wrap">
        <h5 className="text warning">{message}</h5>
      </div>
    </div>
  );
};

const ErrorToast = ({ message, closeToast }) => {
  return (
    <div className="toast-wrap">
      <div className="icon-wrap">
        <AlertCircleIconSolid />
      </div>
      <div className="text-wrap">
        <h5 className="text error">{message}</h5>
      </div>
    </div>
  );
};

export const toastSuccess = (message) =>
  toast.success(<SuccessToast message={message} closeToast={toast.dismiss} />, {
    closeButton: false,
    icon: false,
    className: 'success__toast_custom',
    toastId: message,
  });
export const toastWarning = (message) =>
  toast.warning(<WarningToast message={message} closeToast={toast.dismiss} />, {
    closeButton: false,
    icon: false,
    className: 'warning__toast_custom',
    toastId: message,
  });
export const toastError = (message) =>
  toast.error(<ErrorToast message={message} closeToast={toast.dismiss} />, {
    closeButton: false,
    icon: false,
    className: 'error__toast_custom',
    toastId: message,
  });
