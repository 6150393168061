import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { PlusOutlined } from '@ant-design/icons';

import NoData from 'components/NoData';
import Loading from 'components/UI/Loading';

import './styles.css';
import AddBankAccount from './components/AddBankAccount';

import { fetchAllSettlementAccounts } from 'redux/actions/SettlementAccount';
import SettlementAccountTable from './components/SettlementAccountTable';
import DeleteModal from './components/DeleteModal';
import { toast } from 'react-toastify';
import {
  FETCH_SETTLEMENT_ACCOUNTS_REQUEST,
  RESET_FLAGS_SETTLEMENT,
} from '../../redux/reducers/SettlementAccount';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { toastError } from 'components/UI/toast';

const SettlementAccount = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['org-*', 'settlement-*', 'settlement-edit', 'settlement-create'],
  });

  useEffect(() => {
    if (!canManage) {
      toastError("You don't have permission to view this page");
      setTimeout(() => {
        window.location.replace('/');
      }, 1000);
    }
  }, [canManage]);

  const {
    fetchSettlementAccounts: { loading, success, data },
    verifySettlementAccountOtp: { loading: isVerifyingOtp, success: isOtpVerified },
  } = useSelector(({ settlementAccount }) => settlementAccount);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
    if (!isVerifyingOtp && isOtpVerified) {
      dispatch({ type: FETCH_SETTLEMENT_ACCOUNTS_REQUEST });
      dispatch({ type: RESET_FLAGS_SETTLEMENT, blockType: 'verifySettlementAccountOtp' });
    }
  };

  useEffect(() => {
    dispatch(fetchAllSettlementAccounts());
  }, []);

  const showDeleteModal = (code) => {
    setSelectedCode(code);
    setShow(true);
  };

  if (loading) return <Loading color="#D28B28" isPage={true} />;

  return (
    <section className="settlement-wrapper">
      <Container className="px-0">
        <Row className="pt-4">
          <Col xs={12}>
            {data?.length === 0 ? (
              <div className="settlement-empty-state">
                <NoData
                  headerText="You have not added a bank account yet"
                  buttonLabel={
                    <span className="d-flex justify-content-center">
                      <PlusOutlined className="mr-3" /> Add Bank Account
                    </span>
                  }
                  bodyText="Add a bank account to start collecting your payments"
                  onClickHandler={toggleHandler}
                  withButton
                />
              </div>
            ) : (
              <SettlementAccountTable
                data={data && data}
                onAddAccount={() => setIsOpen(true)}
                onDelete={showDeleteModal}
              />
            )}
          </Col>
        </Row>
      </Container>

      <AddBankAccount isOpen={isOpen} handleClose={toggleHandler} />
      {/* <DeleteModal
        show={show}
        hide={() => setShow(false)}
        code={selectedCode && selectedCode}
      /> */}
    </section>
  );
};

export default SettlementAccount;
